/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const caretRight = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path
      fill="#8C8C8C"
      fillRule="evenodd"
      d="M14 8.54A4.54 4.54 0 0118.54 4l14.919 15.332L38 24l-4.541 4.665L18.54 44A4.54 4.54 0 0114 39.46v-.128L28.918 24 14 8.667V8.54z"
    />
  </svg>
);

export default caretRight;
