const Loading = () => {
  return (
    <>
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          background: 'rgba(0, 0, 0, 0.5)',
          zIndex: 1000,
        }}
      >
        <div
          style={{
            color: 'white', // Makes text color white for high contrast
            fontSize: '24px', // Increases font size
            textShadow: '0 0 8px rgba(0, 0, 0, 0.5)', // Adds a dark text shadow for better legibility on varying backgrounds
          }}
        >
          Loading...
        </div>
      </div>
    </>
  );
};

export default Loading;
