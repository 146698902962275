import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { MdAdd } from 'react-icons/md';

import Card from '../../../components/Card';
import DraggableArea from '../../../components/DraggableArea';
import DraggableItem from '../../../components/DraggableItem';
import ConfirmModal from '../../modals/ConfirmModal';
import SectionItem from '../components/SectionItem';
import SectionListModal, { IModalSectionItem } from '../modals/SectionListModal';
import { FEED_BUILDER_FIELD_KEYS } from '../validates/feedBuilderSchema';

const FeedSectionItemsPresenter = () => {
  const { setValue, watch } = useFormContext();

  const [feedItems] = watch([FEED_BUILDER_FIELD_KEYS.FEED_ITEMS]);
  // local state
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [showSectionListModal, setShowSectionListModal] = useState<boolean>(false);
  const [selectedItems, setSelectedItems] = useState<IModalSectionItem[]>([]);

  const [activeMultipleSelection, setActiveMultipleSelection] = useState<boolean>(false);
  const [deleteSingleItem, setDeleteSingleItem] = useState<IModalSectionItem | null>(null);
  const [deleteMultipleItem, setDeleteMultipleItem] = useState<IModalSectionItem[]>([]);

  useEffect(() => {
    if (feedItems && feedItems.length > 0) {
      const dataToSet = feedItems.map((item: IModalSectionItem) => ({ id: item.id, note: item.note, sectionId: item._id }));
      setSelectedItems(dataToSet);
    }
  }, [feedItems]);

  useEffect(() => {
    if ((feedItems || []).length !== (selectedItems || []).length) setValue(FEED_BUILDER_FIELD_KEYS.FEED_ITEMS, selectedItems);
  }, [feedItems, selectedItems, setValue]);

  const onToggleItem = useCallback(
    (toggleItem: IModalSectionItem) => {
      let newDeleteMultipleItem: IModalSectionItem[] = [];
      if (deleteMultipleItem.find((item) => item.id === toggleItem.id)) {
        newDeleteMultipleItem = deleteMultipleItem.filter((item) => item.id !== toggleItem.id);
      } else {
        newDeleteMultipleItem = [...deleteMultipleItem, toggleItem];
      }
      setDeleteMultipleItem(newDeleteMultipleItem);
    },
    [deleteMultipleItem],
  );

  const onDragItem = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const newSwappedItems = [...selectedItems];
      const tmpItem = selectedItems[hoverIndex];
      newSwappedItems[hoverIndex] = selectedItems[dragIndex];
      newSwappedItems[dragIndex] = tmpItem;
      setSelectedItems(newSwappedItems);
      setValue(FEED_BUILDER_FIELD_KEYS.FEED_ITEMS, newSwappedItems);
    },
    [selectedItems, setValue],
  );

  const onRenderDraggableItem = useCallback(
    (draggableItem: IModalSectionItem, index: number) => {
      const uniqueId = draggableItem.id;
      return (
        <DraggableItem key={uniqueId} index={index} id={uniqueId} onDragItem={onDragItem} className="h-[50px] !p-0 !mb-0 !border-b-0 last:!border-b-[1px]">
          <SectionItem
            activeMultipleSelection={activeMultipleSelection}
            deleteMultipleItem={deleteMultipleItem}
            itemData={draggableItem}
            onToggleItem={onToggleItem}
            onDeleteSectionItem={(item) => {
              setDeleteSingleItem(item);
              setShowConfirmModal(true);
            }}
          />
        </DraggableItem>
      );
    },
    [activeMultipleSelection, deleteMultipleItem, onDragItem, onToggleItem],
  );

  const itemsToDelete = useMemo(() => {
    if (activeMultipleSelection) {
      return deleteMultipleItem.map((item) => ({ note: item?.note, id: item?.id }));
    }
    return [{ note: deleteSingleItem?.note, id: deleteSingleItem?.id }];
  }, [activeMultipleSelection, deleteMultipleItem, deleteSingleItem?.id, deleteSingleItem?.note]);

  const deleteItemFromSelectedList = useCallback(() => {
    const idToDelete = itemsToDelete.map((item) => item.id);
    const selectedListToSet = selectedItems.filter((item) => !idToDelete.includes(item.id));
    setSelectedItems(selectedListToSet);
  }, [itemsToDelete, selectedItems]);

  return (
    <>
      {showConfirmModal && (
        <ConfirmModal
          onClose={() => setShowConfirmModal(false)}
          onConFirm={() => {
            deleteItemFromSelectedList();
            setShowConfirmModal(false);
          }}
          title="Remove Section"
          subTitle="Confirm to Remove this section"
          messages={itemsToDelete.map((item) => item.note || (item.id as string))}
        />
      )}
      {showSectionListModal && <SectionListModal initialItems={selectedItems} onClose={() => setShowSectionListModal(false)} onConfirm={(items) => setSelectedItems(items)} />}
      <Card className="space-y-4">
        <div className="flex items-center justify-between">
          <div className="text-22">Sections</div>
          <div className="flex space-x-12">
            {activeMultipleSelection ? (
              <>
                <button
                  className="btn btn-outline"
                  onClick={() => {
                    setActiveMultipleSelection(false);
                    setDeleteMultipleItem([]);
                  }}
                >
                  cancel
                </button>
                <button className="btn btn-error" onClick={() => setShowConfirmModal(true)} disabled={deleteMultipleItem.length === 0}>
                  delete selected
                </button>
              </>
            ) : (
              <>
                {selectedItems.length > 0 && (
                  <button className="btn btn-outline" onClick={() => setActiveMultipleSelection(true)}>
                    Select Sections
                  </button>
                )}
                <button className="btn btn-error" onClick={() => setShowSectionListModal(true)}>
                  <span>
                    <MdAdd size={22} />
                  </span>
                  Add Section
                </button>
              </>
            )}
          </div>
        </div>
        <div className="flex w-full pt-6">
          <ul className="px-6 border-[1px] border-systemGrays04LabelQuaternary bg-systemGrays04LabelQuaternary">
            {selectedItems.map((_, index) => (
              <li className="h-[50px] flex justify-center items-center" key={index}>
                {index + 1}
              </li>
            ))}
          </ul>

          <DraggableArea className="w-full" draggableItems={selectedItems} onRenderDraggableItem={onRenderDraggableItem} />
        </div>
      </Card>
    </>
  );
};

export default FeedSectionItemsPresenter;
