import { useCallback } from 'react';

import { usePintoSectionLazyQuery } from '../core/graphql/operation-hooks';

const useQueryPintoSection = () => {
  const [queryPintoSection, { data: dataPintoSection, loading: isLoadingPintoSection }] = usePintoSectionLazyQuery();

  const onQueryPintoSection = useCallback(
    async (id: string) => {
      queryPintoSection({ variables: { id } });
    },
    [queryPintoSection],
  );
  return {
    onQueryPintoSection,
    dataPintoSection,
    isLoadingPintoSection,
  };
};

export default useQueryPintoSection;
