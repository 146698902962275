export interface IPropsLibraryTable {
  children: JSX.Element | JSX.Element[];
}

const LibraryTable = (props: IPropsLibraryTable) => (
  <div>
    <table className="table w-full">
      <thead>
        <tr>
          <th className="!rounded-bl-0">Feed Preference</th>
          <th>Last Update</th>
          <th>Publish</th>
          <th className="!rounded-br-0">Actions</th>
        </tr>
      </thead>
      <tbody>{props.children}</tbody>
    </table>
  </div>
);

export default LibraryTable;
