import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { FeedPref, PopupType } from '../../../core/graphql/types';

import PopupFeedPref from './BadgePopupFeedPref';

export type IPropsPopupTableItem = {
  pref: FeedPref;
  _id?: string;
  type?: PopupType;
  isActivated?: boolean;
  updatedAt?: string;
  activeItemsCount?: number;
  totalItemsCount?: number;
  isLoadingMutateUpdatePopup: boolean;
  isLoadingMutateCreatePopup?: boolean;
  onTogglePublish?: () => void;
  onCreatePopup?: () => void;
};

const PopupTableItem = (props: IPropsPopupTableItem): JSX.Element => (
  <tr className="hover">
    <td>{props.type || PopupType.Startup}</td>
    <th>
      <PopupFeedPref pref={props.pref} />
    </th>
    <td>{props.activeItemsCount || '-'}</td>
    <td>{props.totalItemsCount || '-'}</td>
    <td>{props?.updatedAt || '-'}</td>
    <td>
      {props._id && (
        <input
          type="checkbox"
          className={classNames('toggle', {
            ['bg-colorsBrandRed01Primary border-colorsBrandRed01Primary']: !props.isActivated,
            ['bg-colorsBrandTurquoise01Primary border-colorsBrandTurquoise01Primary']: props.isActivated,
          })}
          checked={!!props?.isActivated}
          onChange={props.onTogglePublish}
        />
      )}
      {!props._id && '-'}
    </td>
    <td>
      {props._id && (
        <div className="m-auto">
          <Link className="underline" to={`/suggestProducts/popUp/edit?popupId=${props._id}`}>
            Edit
          </Link>
        </div>
      )}
      {!props._id && (
        <div className="m-auto">
          {props.isLoadingMutateCreatePopup ? (
            <div>...loading</div>
          ) : (
            <a className="underline" onClick={props.onCreatePopup}>
              Edit
            </a>
          )}
        </div>
      )}
    </td>
  </tr>
);

export default PopupTableItem;
