import { useEffect, useCallback } from 'react';

import { useAllCategoriesLazyQuery } from '../core/graphql/operation-hooks';

const useQueryAllCategories = () => {
  const [queryAllCategories, { data, loading }] = useAllCategoriesLazyQuery({ fetchPolicy: 'cache-first' });

  const onFetchQuery = useCallback(() => {
    queryAllCategories();
  }, [queryAllCategories]);

  useEffect(() => {
    onFetchQuery();
  }, [onFetchQuery]);

  return {
    queryAllCategories: onFetchQuery,
    allCategories: data,
    isLoadingAllCategories: loading,
  };
};

export default useQueryAllCategories;
