import { useCallback } from 'react';

import { usePintoSubscriptionEbooksLazyQuery } from '../core/graphql/operation-hooks';
import { EbooksFilter } from '../core/graphql/types';

const INITIAL_LIMIT = 10;

const useQueryPintoSubscriptionEbooks = () => {
  const [queryPintoSubscriptionEbooks, { data, variables, loading }] = usePintoSubscriptionEbooksLazyQuery({ fetchPolicy: 'network-only' });

  const fetchPintoSubscriptionEbooks = useCallback(
    (filter?: EbooksFilter) => {
      queryPintoSubscriptionEbooks({
        variables: {
          filter: {
            ...filter,
            limit: INITIAL_LIMIT,
          },
        },
      });
    },
    [queryPintoSubscriptionEbooks],
  );

  const onFetchMorePintoSubscriptionEbooks = useCallback(() => {
    if (loading || !data?.ebooks?.pageInfo.hasNextPage) return;
    fetchPintoSubscriptionEbooks?.({
      ...variables?.filter,
      afterCursor: data?.ebooks?.pageInfo.endCursor,
    });
  }, [loading, data?.ebooks?.pageInfo.hasNextPage, data?.ebooks?.pageInfo.endCursor, fetchPintoSubscriptionEbooks, variables?.filter]);

  return {
    fetchPintoSubscriptionEbooks,
    onFetchMorePintoSubscriptionEbooks,
    subscriptionEbooks: data?.ebooks,
    isLoadingPintoSubscriptionEbooks: loading,
  };
};

export default useQueryPintoSubscriptionEbooks;
