import FeedCoverItem from './FeedCoverItem';

export interface IPropsEbookCoverSectionItem {
  __typename: 'Ebook';
  _id: string;
  coverImage?: string;
  title: string;
  placeholderBackgroundColor: string;
  categoryTag: { name: string };
  contentRating: { _id: string; name: string };
  user: {
    displayName: string;
  };
  authorName?: string;
  translatorName?: string;
  discountPriceTierDetail?: {
    discount: number;
  };
}

const contentRatingNoNC = '1';
const getBookCategory = (categoryTag: { name: string }, contentRating: { _id: string; name: string }) => {
  if (!categoryTag) {
    return 'ไม่มีหมวดหมู่';
  }
  return `${categoryTag.name}${contentRating._id === contentRatingNoNC ? ` · ${contentRating.name}` : ''}`;
};

const getDiscount = (discountPriceTierDetail: IPropsEbookCoverSectionItem['discountPriceTierDetail']) => {
  return discountPriceTierDetail?.discount ? `${-discountPriceTierDetail?.discount * 100}%` : undefined;
};

const getBookCoverInformation = (props: IPropsEbookCoverSectionItem) => {
  return {
    placeholderColor: props.placeholderBackgroundColor || '',
    imageUrl: props.coverImage || '',
    alt: props.title,
    badgeLabel: getDiscount(props.discountPriceTierDetail),
    title: props.title,
    text1: (props.user?.displayName ?? '') + (props.authorName ? ' · ' + props.authorName : '') + (props.translatorName ? ' · ' + props.translatorName : ''),
    text2: getBookCategory(props.categoryTag, props.contentRating),
  };
};

const EbookCoverSectionItem = (props: IPropsEbookCoverSectionItem): JSX.Element => {
  return <FeedCoverItem {...getBookCoverInformation(props)} />;
};

export default EbookCoverSectionItem;
