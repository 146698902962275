import classNames from 'classnames';
import { HTMLInputTypeAttribute } from 'react';
import { FieldValues, UseFormRegister } from 'react-hook-form';

export interface IPropsInput {
  className?: string;
  inputClassName?: string;
  helperText?: string;
  isInvalid?: boolean;
  label?: string;
  subLabel?: string;
  name: string;
  placeholder?: string;
  register?: UseFormRegister<FieldValues>;
  required?: boolean;
  type?: HTMLInputTypeAttribute;
  min?: string;
  max?: string;
  disabled?: boolean;
  step?: string | number;
  maxLength?: number;
}

function Input(props: IPropsInput) {
  const { type, className, helperText, isInvalid, label, subLabel, disabled, name, placeholder, register, required, inputClassName, min, max, step, maxLength } = props;

  const renderHelperText = () => {
    if (Array.isArray(helperText)) {
      return (
        <ul className="list-disc pl-5 space-y-1">
          {helperText.map((item, index) => (
            <li key={index} className="label-text-alt font-tt text-colorsBrandRed01Primary">
              {item}
            </li>
          ))}
        </ul>
      );
    } else {
      return (
        <span
          className={classNames('label-text-alt font-tt', {
            'text-systemGrays02LabelSecondary': !isInvalid,
            'text-colorsBrandRed01Primary': isInvalid,
          })}
        >
          {helperText}
        </span>
      );
    }
  };

  return (
    <div className={classNames('form-control', className)}>
      {label && (
        <label className={classNames('label', { 'pb-0': !!subLabel })}>
          <span className={classNames('label-text text-systemGrays02LabelSecondary', { 'text-colorsBrandRed01Primary': isInvalid })}>
            {label} {required && <span className="text-colorsBrandRed01Primary">*</span>}
          </span>
        </label>
      )}

      {subLabel && (
        <label className="pt-0 label">
          <span className="label-text-alt text-systemGrays02LabelSecondary font-tt">{subLabel}</span>
        </label>
      )}

      <input
        disabled={disabled}
        type={type || 'text'}
        min={min}
        max={max}
        maxLength={maxLength}
        {...register?.(name)}
        placeholder={placeholder}
        className={classNames(
          'input input-bordered w-full font-tt text-systemGrays02LabelSecondary font-semibold',
          {
            '!input-error': isInvalid,
          },
          inputClassName,
        )}
        step={step}
      />
      {helperText && <label className="pt-4 label">{renderHelperText()}</label>}
    </div>
  );
}

export default Input;
