/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const clock = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path
      fill="#8C8C8C"
      fillRule="evenodd"
      d="M24 46C11.85 46 2 36.15 2 24S11.85 2 24 2s22 9.85 22 22-9.85 22-22 22zm0-6c8.837 0 16-7.163 16-16S32.837 8 24 8 8 15.163 8 24s7.163 16 16 16zm-.995-28h.717c1.116 0 2.005.893 2.005 1.994v11.322l6.658 4.871a2.135 2.135 0 01.523 2.859l-.18.276a1.948 1.948 0 01-2.742.551L21 27.737V13.993c0-1.102.898-1.993 2.005-1.993z"
    />
  </svg>
);

export default clock;
