import Card from '../../../components/Card';
import { EbookWritingType } from '../../../core/graphql/types';

const PINTO_BOOK_BASE_URL = process.env.REACT_APP_PINTO_BOOK_URL;

export interface ICategoryItem {
  id: string;
  name: string;
}

export interface ITagItem {
  id: string;
  name: string;
}

interface IPropsAboutDetailPresenter {
  productSetId?: string;
  productSetName?: string;
  categories?: ICategoryItem[];
  originalLanguage?: string;
  translatedLanguage?: string;
  tags?: ITagItem[];
  writingType?: EbookWritingType;
  subscriptionTitle?: string;
}

function AboutDetailPresenter(props: IPropsAboutDetailPresenter) {
  const { productSetId, productSetName, categories, originalLanguage, translatedLanguage, tags, writingType, subscriptionTitle } = props;
  return (
    <Card className="flex flex-col space-y-8">
      <div className="text-22">About</div>
      <div className="flex space-x-24">
        <div className="flex w-[200px] font-tt">Product Set</div>
        {productSetId ? (
          <a target="_blank" className="link text-colorsBrandRed01Primary" href={`${PINTO_BOOK_BASE_URL}/product-set/${productSetId}`} rel="noreferrer">
            {productSetName}
          </a>
        ) : (
          <div className="text-colorsBrandWarmBlack02LabelSecondary">-</div>
        )}
      </div>
      <div className="flex space-x-24">
        <div className="flex w-[200px] font-tt">Subscription Title</div>
        {subscriptionTitle ? <div>{subscriptionTitle}</div> : <div className="text-colorsBrandWarmBlack02LabelSecondary">-</div>}
      </div>
      <div className="flex space-x-24">
        <div className="flex w-[200px] font-tt">Categories</div>
        <ul className="flex-1 space-x-4">
          {categories && categories.length > 0 ? (
            categories?.map((cat) => (
              <li key={cat.id} className="badge badge-outline">
                {cat.name}
              </li>
            ))
          ) : (
            <div className="text-colorsBrandWarmBlack02LabelSecondary">-</div>
          )}
        </ul>
      </div>
      {writingType && (
        <div className="flex space-x-24">
          <div className="flex w-[200px] font-tt">Cartoon Type</div>
          <div>{writingType || '-'}</div>
        </div>
      )}
      <div className="flex space-x-24">
        <div className="flex w-[200px] font-tt">Original Language</div>
        <div>{originalLanguage || '-'}</div>
      </div>
      <div className="flex space-x-24">
        <div className="flex w-[200px] font-tt">Translated Language</div>
        <div>{translatedLanguage || '-'}</div>
      </div>
      <div className="flex space-x-24">
        <div className="flex w-[200px] font-tt">Tags</div>
        <ul className="flex-1 space-x-4">
          {tags && tags.length > 0 ? (
            tags?.map((tag) => (
              <li key={tag.id} className="badge badge-outline">
                #{tag.name}
              </li>
            ))
          ) : (
            <div className="text-colorsBrandWarmBlack02LabelSecondary">-</div>
          )}
        </ul>
      </div>
    </Card>
  );
}

export default AboutDetailPresenter;
