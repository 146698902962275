import { FeedPref } from '../../../core/graphql/types';

export interface IPropsPopupFeedPref {
  pref: FeedPref;
}

const PopupFeedPref = ({ pref }: IPropsPopupFeedPref) => {
  switch (pref) {
    case FeedPref.Default:
      return <div className="leading-normal badge badge-outline">{pref}</div>;
    case FeedPref.Male:
      return <div className="leading-normal badge badge-info">{pref}</div>;
    case FeedPref.Female:
      return <div className="leading-normal badge badge-error">{pref}</div>;
    case FeedPref.Y:
      return <div className="leading-normal badge badge-success">{pref}</div>;
    default:
      return <div className="leading-normal badge badge-outline">{pref}</div>;
  }
};

export default PopupFeedPref;
