/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const hamburger = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path
      fill="#8C8C8C"
      fillRule="evenodd"
      d="M5.242 39a4.244 4.244 0 01-.036-5.963L5.242 33h37.516l.036.037A4.244 4.244 0 0142.758 39H5.242zm0-12a4.244 4.244 0 01-.036-5.963L5.242 21h37.516l.036.037A4.244 4.244 0 0142.758 27H5.242zm0-12a4.244 4.244 0 01-.036-5.963L5.242 9h37.516l.036.037A4.244 4.244 0 0142.758 15H5.242z"
    />
  </svg>
);

export default hamburger;
