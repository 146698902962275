/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const coin = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path
      fill="#8C8C8C"
      fillRule="evenodd"
      d="M42 9c0 2.761-8.059 5-18 5S6 11.761 6 9s8.059-5 18-5 18 2.239 18 5zm-18 8.82c4.102 0 8.222-.449 11.72-1.327 1.638-.41 3.539-1.198 5.703-2.362a1.1 1.1 0 01.521-.13c.583 0 1.056.447 1.056 1v2.492C43 21.087 34.494 24 24 24c-10.492 0-19-2.913-19-6.507V15a.96.96 0 01.137-.493c.288-.48.932-.65 1.44-.377 2.164 1.165 4.066 1.952 5.703 2.363 3.5.878 7.618 1.328 11.72 1.328zm0 10c4.102 0 8.222-.45 11.72-1.327 1.638-.41 3.539-1.198 5.703-2.362a1.1 1.1 0 01.521-.13c.583 0 1.056.447 1.056 1v2.492C43 31.087 34.494 34 24 34c-10.492 0-19-2.913-19-6.507V25a.96.96 0 01.137-.493c.288-.48.932-.65 1.44-.377 2.164 1.165 4.066 1.952 5.703 2.363 3.5.877 7.618 1.327 11.72 1.327zm0 10c4.102 0 8.222-.449 11.72-1.327 1.638-.41 3.539-1.198 5.703-2.362a1.1 1.1 0 01.521-.13c.583 0 1.056.447 1.056 1v2.492C43 41.086 34.494 44 24 44c-10.492 0-19-2.914-19-6.507V35a.96.96 0 01.138-.493c.287-.48.932-.65 1.439-.376 2.164 1.164 4.066 1.951 5.703 2.362 3.5.878 7.618 1.326 11.72 1.326z"
    />
  </svg>
);

export default coin;
