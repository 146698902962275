import { Control, FieldValues, UseFormRegister } from 'react-hook-form';

import ArraysInput from '../../../components/form/ArraysInput';
import Input from '../../../components/form/Input';
import { SortType } from '../../../core/graphql/types';
import { IConfigItemType, IProduct_SET_SORT_BY, SORT_BY_PRODUCT_SET_OPTIONS } from '../validates/sectionBuilderSchema';

import DropdownSelectNested from './DropdownSelectNested';
import SelectPintoCategoryWrapper from './SelectPintoCategory';

//TODO: continue implements
export interface IPropsQueryProductSetItem {
  type: IConfigItemType.QueryProductSet;
  categoryId: number;
  anyUserIds?: string[];
  whitelistIds?: string[];
  blacklistIds?: string[];
  sortType?: SortType;
  sortBy?: IProduct_SET_SORT_BY;
  limit?: number;
}
interface IPropsQueryProductSetSectionItem {
  index: number;
  itemData: IPropsQueryProductSetItem;
  control: Control<FieldValues, any>;
  register: UseFormRegister<FieldValues>;
}

const QueryProductSetSectionItem = (props: IPropsQueryProductSetSectionItem) => {
  const { index, itemData, register, control } = props;

  return (
    <div className="flex justify-between space-x-16">
      <div className="w-full collapse">
        <input type="checkbox" className="peer" />
        <div className=" collapse-title bg-systemGrays08FillQuaternary text-systemGrays01LabelPrimary peer-checked:bg-systemGrays08FillQuaternary peer-checked:text-systemGrays01LabelPrimary flex justify-between after:content-['+'] peer-checked:after:content-['-'] ease-out duration-300">
          Query Product Set
        </div>
        <div className="collapse-content text-systemGrays01LabelPrimary peer-checked:bg-systemGrays08FillQuaternary peer-checked:text-systemGrays01LabelPrimary">
          <div className="space-y-8">
            <Input
              type="number"
              register={register}
              label="limit"
              name={`configItems.${index}.limit`}
              max="100"
              min="0"
              helperText="Input number of item to show, limit at 100 items"
              placeholder="limit"
            />

            <DropdownSelectNested
              label="SortBy"
              required
              options={SORT_BY_PRODUCT_SET_OPTIONS}
              prefixName={`configItems.${index}`}
              name={[`configItems.${index}.sortBy`, `configItems.${index}.sortType`]}
            />
            <SelectPintoCategoryWrapper name={`configItems.${index}.categoryId`} categoryId={itemData?.categoryId} />

            <div>
              <ArraysInput control={control} name={`configItems.${index}.anyUserIds`} label="UserIds" helperText="Input User ID" placeholder="userIds" />
              <ArraysInput control={control} name={`configItems.${index}.whitelistIds`} label="whitelistIds" helperText="Input ebook ID" placeholder="whitelistIds" />
              <ArraysInput control={control} name={`configItems.${index}.blacklistIds`} label="blacklistIds" helperText="Input ebook ID" placeholder="blacklistIds" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QueryProductSetSectionItem;
