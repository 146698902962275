import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import ToastComponent, { IToastType } from '../components/Toast/ToastComponent';
import { IModalNames } from '../core/context/modalReducer';
import { useCreateAndSubmitReviewEbookMutation } from '../core/graphql/operation-hooks';
import { CreateAndSubmitReviewEbookInput } from '../core/graphql/types';
import useModal from '../core/hook/context/useModal';

export enum CREATE_AND_SUBMIT_REVIEW_EBOOK_RETURN_STATUS {
  SUCCESS = 'success',
  ERROR = 'error',
  DUPLICATE_PRODUCT_SET = 'product_set_item_has_duplicate_vol',
}

const useMutationCreateAndSubmitReviewEbook = () => {
  const [mutateCreateAndSubmitReviewEbook, { loading }] = useCreateAndSubmitReviewEbookMutation({ errorPolicy: 'all' });
  const { openModal, removeModal } = useModal();
  const router = useHistory();

  const createAndSubmitReviewEbook = useCallback(
    async (input: CreateAndSubmitReviewEbookInput) => {
      const res = await mutateCreateAndSubmitReviewEbook({ variables: { input } });

      if (res.errors) {
        const [createAndSubmitReviewEbookError] = res.errors;
        const createAndSubmitReviewEbookErrorMessage = createAndSubmitReviewEbookError.message;
        switch (createAndSubmitReviewEbookErrorMessage) {
          case 'product_set_item_has_duplicate_vol':
            return CREATE_AND_SUBMIT_REVIEW_EBOOK_RETURN_STATUS.DUPLICATE_PRODUCT_SET;
          default:
            ToastComponent({ label: 'เกิดข้อผิดพลาดในการทำรายการ กรุณาลองใหม่อีกครั้ง', type: IToastType.ERROR });
            return CREATE_AND_SUBMIT_REVIEW_EBOOK_RETURN_STATUS.ERROR;
        }
      } else if (res.data?.createAndSubmitReviewEbook._id) {
        // ToastComponent({ label: 'ทีมงานกำลังตรวจสอบเพื่อนำอีบุ๊กของคุณขึ้นชั้นหนังสือโดยใช้เวลาประมาณ 1-3 วันทำการ' });
        openModal({
          modalName: IModalNames.CONFIRM,
          modalPayload: {
            title: 'เผยแพร่ Ebook สำเร็จ',
            onConfirm: () => {
              router.push(`/ebooks/detail?id=${res.data?.createAndSubmitReviewEbook._id}`);
              removeModal(IModalNames.CONFIRM);
            },
          },
        });
      }
      return CREATE_AND_SUBMIT_REVIEW_EBOOK_RETURN_STATUS.SUCCESS;
    },
    [mutateCreateAndSubmitReviewEbook, openModal, removeModal, router],
  );

  return {
    createAndSubmitReviewEbook,
    isLoadingCreateAndSubmitReviewEbook: loading,
  };
};

export default useMutationCreateAndSubmitReviewEbook;
