/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const alignCenter = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path
      fill="#8C8C8C"
      fillRule="evenodd"
      d="M7.118 12a5.16 5.16 0 01-.034-5.952L7.118 6h33.764l.036.04a4.486 4.486 0 01-.036 5.96H7.118zm-.12 20a4.486 4.486 0 01-.035-5.96L7 26h33.764l.036.04a4.486 4.486 0 01-.036 5.96H7zm7.483 10a6.9 6.9 0 01-.028-5.941l.028-.059h18.123l.028.059A6.9 6.9 0 0132.604 42H14.48zm0-20a6.9 6.9 0 01-.028-5.941l.028-.059h18.123l.028.059A6.9 6.9 0 0132.604 22H14.48z"
    />
  </svg>
);

export default alignCenter;
