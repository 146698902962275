import { useCallback, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import FeedListSorting, { ISort } from '../../app/feeds/components/FeedListSorting';
import FeedListTable, { FeedItem } from '../../app/feeds/components/FeedListTable';
import { FeedPref, FeedsFilterSortBy } from '../../core/graphql/types';
import useMutationPintoDeleteFeed from '../../hooks/useMutationPintoDeleteFeed';
import useQueryPintoFeeds from '../../hooks/useQueryPintoFeeds';
import { getDayTime } from '../../utils/dayjs';

export const FEED_PREVIEW_SLUG = 'feed-preview';

const SORTING: ISort[] = [
  { name: 'Last Updated', value: FeedsFilterSortBy.Updated },
  { name: 'Created Date', value: FeedsFilterSortBy.Created },
];

const FeedsPage = () => {
  const { fetchPintoFeeds, feeds, isLoadingPintoFeeds } = useQueryPintoFeeds();
  const { deletePintoFeed } = useMutationPintoDeleteFeed();
  const [currentSorting, setCurrentSorting] = useState<ISort>(SORTING[0]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [feedList, setFeedList] = useState<FeedItem[]>([]);

  const sortingRef = useRef<HTMLUListElement>(null);

  const handleClickSorting = useCallback(
    (sorting) => {
      setCurrentSorting(sorting);
      sortingRef.current?.blur();
    },
    [sortingRef],
  );

  const handleChangePage = useCallback(
    (page: number) => {
      const offset = (page - 1) * 10;
      setCurrentPage(page);
      fetchPintoFeeds({ sortBy: currentSorting.value, offset });
    },
    [currentSorting.value, fetchPintoFeeds],
  );

  const handleDeleteFeed = useCallback(
    (slug: string, pref: FeedPref) => {
      deletePintoFeed(slug, pref);
    },
    [deletePintoFeed],
  );

  useEffect(() => {
    if (!feeds || !feeds.edges.length) return;
    const _feedData: FeedItem[] = feeds?.edges
      ?.map(({ node: feed }) => ({
        slug: feed.slug ?? '',
        lastUpdated: getDayTime(feed.updatedAt),
        pref: feed.pref ?? FeedPref.Default,
        og: {
          image: feed.meta.ogImage || null,
          title: feed.meta.ogTitle,
          description: feed.meta.ogDescription,
        },
      }))
      // NOTE: filter feed-preview out list (hidden from user view)
      .filter((feed) => feed.slug !== FEED_PREVIEW_SLUG);

    setFeedList(_feedData);
  }, [feeds]);

  useEffect(() => {
    fetchPintoFeeds({ sortBy: currentSorting.value });
  }, [currentSorting.value, fetchPintoFeeds]);

  return (
    <div className="mx-auto">
      {/* section: title */}
      <div className="flex justify-between py-5">
        <h1 className="font-dbh text-[28px]">Feeds</h1>

        <Link to="/feedAndSection/feeds/edit">
          <button className="btn btn-error">Create New Feed</button>
        </Link>
      </div>

      <div>
        <div className="pb-5">
          <FeedListSorting currentSorting={currentSorting} sortingList={SORTING} sortingRef={sortingRef} handleClickSorting={handleClickSorting} />
        </div>
        {isLoadingPintoFeeds ? (
          <div className="w-full btn btn-outline btn-disabled bg-systemGrays04LabelQuaternary border-none loading h-[100px]" />
        ) : (
          <FeedListTable feedList={feedList} currentPage={currentPage} totalFeeds={feeds?.totalCount || 0} onChangePage={handleChangePage} onDeleteFeed={handleDeleteFeed} />
        )}
      </div>
    </div>
  );
};

export default FeedsPage;
