import { useEffect, useCallback } from 'react';

import { usePintoCategoriesLazyQuery } from '../core/graphql/operation-hooks';

const useQueryPintoCategories = () => {
  const [queryPintoCategories, { data, loading }] = usePintoCategoriesLazyQuery({ fetchPolicy: 'cache-first' });

  const onFetchQuery = useCallback(() => {
    queryPintoCategories();
  }, [queryPintoCategories]);

  useEffect(() => {
    onFetchQuery();
  }, [onFetchQuery]);

  return {
    queryPintoCategories: onFetchQuery,
    pintoCategories: data,
    isLoadingPintoCategories: loading,
  };
};

export default useQueryPintoCategories;
