import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import ToastComponent, { IToastType } from '../components/Toast/ToastComponent';
import { IModalNames } from '../core/context/modalReducer';
import { useCreateDraftEbookMutation } from '../core/graphql/operation-hooks';
import { CreateDraftEbookInput } from '../core/graphql/types';
import useModal from '../core/hook/context/useModal';

export enum CREATE_DRAFT_EBOOK_RETURN_STATUS {
  SUCCESS = 'success',
  ERROR = 'error',
  DUPLICATE_PRODUCT_SET = 'product_set_item_has_duplicate_vol',
}

const useMutationCreateDraftEbook = () => {
  const [mutateCreateDraftEbook, { loading }] = useCreateDraftEbookMutation({ errorPolicy: 'all' });
  const { openModal, removeModal } = useModal();
  const router = useHistory();

  const createDraftEbook = useCallback(
    async (input: CreateDraftEbookInput) => {
      const res = await mutateCreateDraftEbook({ variables: { input } });

      if (res.errors) {
        const [editDraftEbookError] = res.errors;
        const editDraftEbookErrorMessage = editDraftEbookError.message;
        switch (editDraftEbookErrorMessage) {
          case 'product_set_item_has_duplicate_vol':
            return CREATE_DRAFT_EBOOK_RETURN_STATUS.DUPLICATE_PRODUCT_SET;
          default:
            ToastComponent({ label: 'เกิดข้อผิดพลาดในการทำรายการ กรุณาลองใหม่อีกครั้ง', type: IToastType.ERROR });
            return CREATE_DRAFT_EBOOK_RETURN_STATUS.ERROR;
        }
      } else if (res.data?.createDraftEbook._id) {
        // ToastComponent({ label: 'บันทึกแบบร่างเรียบร้อยแล้ว' });
        openModal({
          modalName: IModalNames.CONFIRM,
          modalPayload: {
            title: 'บันทึกแบบร่างเรียบร้อยแล้ว',
            onConfirm: () => {
              router.push(`/ebooks/detail?id=${res.data?.createDraftEbook._id}`);
              removeModal(IModalNames.CONFIRM);
            },
          },
        });
      }
      return CREATE_DRAFT_EBOOK_RETURN_STATUS.SUCCESS;
    },
    [mutateCreateDraftEbook, openModal, removeModal, router],
  );

  return { createDraftEbook, isLoadingCreateDraftEbook: loading };
};

export default useMutationCreateDraftEbook;
