import { useCallback } from 'react';

import { usePintoCreateFeedMutation } from '../core/graphql/operation-hooks';
import { CreateFeedInput } from '../core/graphql/types';

const useMutatePintoCreateFeed = () => {
  const [mutatePintoCreateFeed, { loading: isLoadingMutatePintoCreateFeed }] = usePintoCreateFeedMutation();

  const onMutatePintoCreateFeed = useCallback(
    async (input: CreateFeedInput) => {
      const variables = { input };
      const result = await mutatePintoCreateFeed({ variables });
      return !!result.data?.pintoCreateFeed._id;
    },
    [mutatePintoCreateFeed],
  );

  return {
    onMutatePintoCreateFeed,
    isLoadingMutatePintoCreateFeed,
  };
};

export default useMutatePintoCreateFeed;
