export interface IPropsLibraryFeedPref {
  pref: 'default' | 'male' | 'female' | 'y';
}

const LibraryFeedPref = ({ pref }: IPropsLibraryFeedPref) => {
  switch (pref) {
    case 'default':
      return <div className="leading-normal badge badge-outline">{pref}</div>;
    case 'male':
      return <div className="leading-normal badge badge-info">{pref}</div>;
    case 'female':
      return <div className="leading-normal badge badge-error">{pref}</div>;
    case 'y':
      return <div className="leading-normal badge badge-success">{pref}</div>;
    default:
      return <div className="leading-normal badge badge-outline">{pref}</div>;
  }
};

export default LibraryFeedPref;
