import { useCallback, useRef, useState } from 'react';

const FEED_PREVIEW_URL = `${process.env.REACT_APP_PINTO_BOOK_URL}/dev/feed-preview?hideNavbarAndFooter=true`;

const IFRAME_FEED_PREVIEW_NAME = 'feedPreview';
interface IPropsFeedPreviewPresenter {
  onSavePreview?: () => void;
}

function FeedPreviewPresenter(props: IPropsFeedPreviewPresenter) {
  const { onSavePreview } = props;

  const [isFirstRefresh, setIsFirstRefresh] = useState(true);

  const iFrameRef = useRef(null);

  const onRefresh = useCallback(async () => {
    await onSavePreview?.();
    window.open(FEED_PREVIEW_URL, IFRAME_FEED_PREVIEW_NAME);
    setIsFirstRefresh(false);
  }, [onSavePreview]);

  return (
    <>
      <div className="flex items-center justify-between px-12 mb-4">
        <p>Preview</p>
        <button onClick={onRefresh} className="btn btn-outline">
          Refresh
        </button>
      </div>

      <div className="relative h-[95%] border mockup-window border-base-300 mr-16">
        {isFirstRefresh && <div className="absolute top-0 w-full h-full bg-opacityWhite80"></div>}
        <div className="flex flex-col items-center justify-center h-full px-4 py-16 bg-white">
          <iframe ref={iFrameRef} id={IFRAME_FEED_PREVIEW_NAME} name={IFRAME_FEED_PREVIEW_NAME} className="w-full h-full" src={FEED_PREVIEW_URL}></iframe>
        </div>
      </div>
    </>
  );
}

export default FeedPreviewPresenter;
