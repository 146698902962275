import { useCallback } from 'react';

import ToastComponent, { IToastType } from '../components/Toast/ToastComponent';
import { useEditEbookMutation } from '../core/graphql/operation-hooks';
import { EditEbookInput } from '../core/graphql/types';

const useMutationEditEbook = () => {
  const [mutateCancelScheduledEbook, { loading }] = useEditEbookMutation({ errorPolicy: 'all' });

  const editEbook = useCallback(
    async (ebookId: string, input: EditEbookInput) => {
      const res = await mutateCancelScheduledEbook({ variables: { id: ebookId, input } });

      if (res.errors) {
        ToastComponent({ label: 'เกิดข้อผิดพลาดในการทำรายการ กรุณาลองใหม่อีกครั้ง', type: IToastType.ERROR });
      } else if (res.data?.editEbook?._id) {
        ToastComponent({ label: 'ยกเลิกการตั้งเวลาเผยแพร่เรียบร้อยแล้ว' });
      }
    },
    [mutateCancelScheduledEbook],
  );

  return { editEbook, isSubmittingCancelScheduledEbook: loading };
};

export default useMutationEditEbook;
