export interface IErrorItem {
  ebookId: string;
  ebookTitle: string;
  userAccount: string;
  errorMessage: string;
}

export interface IPropsPublishDiscountErrorModal {
  onConFirm?: () => void;
  title?: string;
  errorItems?: IErrorItem[];
  confirmText?: string;
}

const PublishDiscountErrorModal = (props: IPropsPublishDiscountErrorModal) => {
  const { onConFirm, confirmText, title, errorItems } = props;

  return (
    <>
      <div className="modal modal-open">
        <div className="relative modal-box max-w-[76vw] w-[76vw]">
          <h3 className="text-lg font-bold font-dbh">{title || 'Error Transactions'}</h3>

          <div className="rounded-md mt-12 overflow-auto h-[60vh] scrollbar-hide">
            <table className="table w-full table-zebra font-tt">
              <thead>
                <tr>
                  <th>No</th>
                  <th className="sticky z-50 left-12">Ebook Id</th>
                  <th>Ebook Title</th>
                  <th>User Account</th>
                  <th>Error Message</th>
                </tr>
              </thead>
              <tbody>
                {errorItems?.map((errorItem, index) => (
                  <tr key={`${index}-${errorItem.ebookId}`}>
                    <th className="font-normal">{index + 1}</th>
                    <td className="sticky z-50 left-12">{errorItem.ebookId}</td>
                    <td>{errorItem.ebookTitle}</td>
                    <td>{errorItem.userAccount}</td>
                    <td>{errorItem.errorMessage}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="flex justify-end w-full pt-24 space-x-4">
            <button className="btn btn-error" onClick={onConFirm}>
              {confirmText || 'OK, I’m noticed'}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PublishDiscountErrorModal;
