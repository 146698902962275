import { useCallback } from 'react';

import { useSearchSubscriptionTitlesLazyQuery } from '../core/graphql/operation-hooks';
import { SearchSubscriptionTitlesFilter } from '../core/graphql/types';

export interface IPropsUseSearchSubscriptionTitles {
  filter: SearchSubscriptionTitlesFilter;
  includeDetail?: boolean;
}

const INITIAL_LIMIT = 10;

const useSearchSubscriptionTitles = () => {
  const [querySearchSubscriptionTitles, { data, fetchMore, loading, variables }] = useSearchSubscriptionTitlesLazyQuery();

  const fetchSearchSubscriptionTitles = useCallback(
    ({ filter, includeDetail = false }: IPropsUseSearchSubscriptionTitles) => {
      querySearchSubscriptionTitles({
        variables: {
          filter: {
            ...filter,
            limit: INITIAL_LIMIT,
          },
          includeDetail,
        },
      });
    },
    [querySearchSubscriptionTitles],
  );

  const onFetchMoreSearchSubscriptionTitles = useCallback(() => {
    if (loading || !data?.searchSubscriptionTitles?.pageInfo.hasNextPage) return;
    fetchMore?.({
      variables: {
        filter: {
          ...variables?.filter,
          afterCursor: data?.searchSubscriptionTitles?.pageInfo.endCursor,
        },
      },
    });
  }, [data?.searchSubscriptionTitles?.pageInfo.endCursor, data?.searchSubscriptionTitles?.pageInfo.hasNextPage, fetchMore, loading, variables?.filter]);

  return {
    searchSubscriptionTitles: data?.searchSubscriptionTitles,
    isLoadingSearchSubscriptionTitles: loading,
    fetchSearchSubscriptionTitles,
    onFetchMoreSearchSubscriptionTitles,
  };
};

export default useSearchSubscriptionTitles;
