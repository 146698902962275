export interface IPropsWriterInputRowItem {
  index: number;
  value?: string;
  onRemove: (removeIndex: number) => void;
  onChange: (index: number, value: string) => void;
  placeholder?: string;
}

function WriterInputRowItem(props: IPropsWriterInputRowItem) {
  const { placeholder, index, onRemove, onChange, value } = props;

  return (
    <div className="flex w-full space-x-4">
      <input
        value={value}
        className="w-full font-semibold input input-bordered font-tt text-systemGrays02LabelSecondary"
        placeholder={placeholder}
        onChange={(e) => onChange(index, e.target.value)}
      />
      <button className="p-8" onClick={() => onRemove(index)}>
        Delete
      </button>
    </div>
  );
}

export default WriterInputRowItem;
