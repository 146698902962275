import classNames from 'classnames';
import { ReactNode } from 'react';

export interface IPropsConfirmModal {
  onClose?: () => void;
  onConFirm?: () => void;
  title?: string;
  subTitle?: string | ReactNode;
  messages?: string | string[];
  messageList?: { title1: string; title2: string }[];
  cancelText?: string;
  confirmText?: string;
  confirmButtonClassname?: string;
  messageListClassName?: string;
  disabledIndexOrder?: boolean;
}

const ConfirmModal = (props: IPropsConfirmModal) => {
  const { onClose, onConFirm, cancelText, confirmText, title, subTitle, messages, confirmButtonClassname, messageList, messageListClassName, disabledIndexOrder } = props;

  return (
    <>
      <div className="modal modal-open">
        <div className="modal-box relative">
          <h3 className="text-lg font-bold font-tt text-systemGrays01LabelPrimary">{title || 'Delete Item from Section'}</h3>
          {subTitle && <p className="font-tt pt-12 text-colorsBrandWarmBlack01LabelPrimary">{subTitle}</p>}

          {messages && (
            <div className="p-12 bg-red-100 rounded-md mt-12">
              <ul className="prose text-systemGrays02LabelSecondary">
                {Array.isArray(messages) ? (
                  messages.map((error, index) => (
                    <li key={index} className="py-4 font-tt">
                      - {error}
                    </li>
                  ))
                ) : (
                  <li className="py-4 font-tt">- {messages}</li>
                )}
              </ul>
            </div>
          )}

          {messageList && messageList.length > 0 && (
            <div className="mt-16">
              <ul
                className={classNames(
                  'border border-solid border-colorsBrandPaper02Darker bg-colorsBrandPaper07FillTertiary rounded-8 text-16 leading-[20px] text-colorsBrandWarmBlack01LabelPrimary font-tt p-12',
                  messageListClassName,
                )}
              >
                {messageList.map((item, index) => (
                  <li key={index} className="flex justify-between items-center py-4">
                    <span>
                      {disabledIndexOrder ? '•' : `${index + 1}.`} {item.title1}
                    </span>
                    {item.title2 && <span className="text-right">{item.title2}</span>}
                  </li>
                ))}
              </ul>
            </div>
          )}

          <div className="w-full flex justify-end space-x-6 pt-24">
            <button className="btn btn-outline btn-error" onClick={onClose}>
              {cancelText || 'Cancel'}
            </button>
            <button className={classNames('btn btn-error', confirmButtonClassname)} onClick={onConFirm}>
              {confirmText || 'Confirm'}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmModal;
