import { useFormContext } from 'react-hook-form';

import Card from '../../../components/Card';
import Input from '../../../components/form/Input';
import { FEED_BUILDER_FIELD_KEYS } from '../validates/feedBuilderSchema';

const FeedDetailPresenter = () => {
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext();
  const [slug, ogTitle, ogDescription, ogImage] = watch([
    FEED_BUILDER_FIELD_KEYS.SLUG,
    FEED_BUILDER_FIELD_KEYS.OG_TITLE,
    FEED_BUILDER_FIELD_KEYS.OG_DESCRIPTION,
    FEED_BUILDER_FIELD_KEYS.OG_IMAGE,
  ]);
  return (
    <Card className="space-y-4">
      <div className="text-22">Feed Detail</div>
      <Input
        name={FEED_BUILDER_FIELD_KEYS.SLUG}
        register={register}
        label="Slug"
        required
        placeholder="slug"
        helperText={`${(slug || '').length}/40 characters`}
        isInvalid={(slug || '').length > 40 || errors.slug}
      />
      <Input
        name={FEED_BUILDER_FIELD_KEYS.OG_TITLE}
        register={register}
        label="ogTitle"
        placeholder="og title"
        helperText={`${(ogTitle || '').length}/40 characters`}
        isInvalid={(ogTitle || '').length > 120}
      />
      <Input
        name={FEED_BUILDER_FIELD_KEYS.OG_DESCRIPTION}
        register={register}
        label="ogDescription"
        placeholder="og description"
        helperText={`${(ogDescription || '').length}/40 characters`}
        isInvalid={(ogDescription || '').length > 250}
      />
      <Input
        name={FEED_BUILDER_FIELD_KEYS.OG_IMAGE}
        register={register}
        label="ogImage"
        placeholder="og image"
        helperText="img url, 1200 x 630px in jpeg, png"
        isInvalid={!!errors?.[FEED_BUILDER_FIELD_KEYS.OG_IMAGE]}
      />
      {ogImage && !errors?.[FEED_BUILDER_FIELD_KEYS.OG_IMAGE] && (
        <div className="w-full rounded">
          <img src={ogImage} alt={FEED_BUILDER_FIELD_KEYS.OG_IMAGE} />
        </div>
      )}
    </Card>
  );
};

export default FeedDetailPresenter;
