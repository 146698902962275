/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const swap = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      fill="#757575"
      d="M15.674 20a.307.307 0 0 1-.2-.074.313.313 0 0 1-.109-.239V17H6a3 3 0 0 1-3-3v-.683a.311.311 0 0 1 .309-.312h12.057v-2.7a.311.311 0 0 1 .308-.305.306.306 0 0 1 .236.111L20 15l-4.09 4.889a.307.307 0 0 1-.236.111zM4.576 10a.332.332 0 0 1-.249-.111L0 5 4.327.111A.331.331 0 0 1 4.576 0a.338.338 0 0 1 .212.074.308.308 0 0 1 .112.239V3l9.09-.02H14a3.006 3.006 0 0 1 3 2.992v.681a.32.32 0 0 1-.327.312L4.9 7v2.688a.32.32 0 0 1-.324.312z"
      transform="translate(2 2)"
    />
  </svg>
);

export default swap;
