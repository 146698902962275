import { Node, Schema } from 'prosemirror-model';

const getParagraphAttrs = (node: string | globalThis.Node) => {
  if (node instanceof HTMLElement) {
    const { textAlign } = node.style;
    let align = node.getAttribute('align') || textAlign || '';
    align = /(left|right|center|justify)/.test(align) ? align : '';
    const indent = !!node.getAttribute('data-indent') || !!node.style.tabSize || !!node.style.textIndent;
    if (indent || node.className.indexOf('indent') > 0) {
      return { class: 'indent' };
    }
    if (node.className.indexOf('center') > 0) {
      return { class: 'center' };
    }
    if (node.className.indexOf('right') > 0) {
      return { class: 'right' };
    }
    if (align.length) {
      return { class: align };
    }
  }
  return {};
};
// :: Schema
// This schema roughly corresponds to the document schema used by
// [CommonMark](http://commonmark.org/), minus the list elements,
// which are defined in the [`prosemirror-schema-list`](#schema-list)
// module.
//
// To reuse elements from this schema, extend or read from its
// `spec.nodes` and `spec.marks` [properties](#model.Schema.spec).
export default new Schema({
  // :: Object
  // [Specs](#model.NodeSpec) for the nodes defined in this schema.
  nodes: {
    // :: NodeSpec The top level document node.
    doc: {
      content: 'block+',
    },

    // :: NodeSpec A plain paragraph textblock. Represented in the DOM
    // as a `<p>` element.
    paragraph: {
      content: 'inline*',
      group: 'block',
      attrs: {
        class: {
          default: undefined,
        },
      },
      parseDOM: [
        { tag: 'p', getAttrs: getParagraphAttrs },
        // { tag: "div", getAttrs: getParagraphAttrs },
      ],
      toDOM(node) {
        return ['p', { class: node.attrs.class }, 0];
      },
    },

    // :: NodeSpec A blockquote (`<blockquote>`) wrapping one or more blocks.
    blockquote: {
      content: 'inline*',
      marks: '', // disallow marks
      group: 'block',
      parseDOM: [{ tag: 'blockquote' }],
      toDOM() {
        return ['blockquote', 0];
      },
    },

    // :: NodeSpec A horizontal rule (`<hr>`).
    horizontalRule: {
      group: 'block',
      parseDOM: [{ tag: 'hr' }],
      toDOM() {
        return ['hr'];
      },
    },

    // :: NodeSpec A heading textblock, with a `level` attribute that
    // should hold the number 1 to 6. Parsed and serialized as `<h1>` to
    // `<h6>` elements.
    heading: {
      attrs: { level: { default: 1 } },
      content: 'inline*',
      group: 'block',
      marks: '',
      defining: true,
      parseDOM: [
        // support single level
        { tag: 'h1', attrs: { level: 1 } },
        { tag: 'h2', attrs: { level: 1 } },
        { tag: 'h3', attrs: { level: 1 } },
        { tag: 'h4', attrs: { level: 1 } },
        { tag: 'h5', attrs: { level: 1 } },
        { tag: 'h6', attrs: { level: 1 } },
      ],
      toDOM(node: Node) {
        return ['h' + node.attrs.level, 0];
      },
    },

    // :: NodeSpec A code listing. Disallows marks or non-text inline
    // nodes by default. Represented as a `<pre>` element with a
    // `<code>` element inside of it.
    codeBlock: {
      content: 'text*',
      marks: '',
      group: 'block',
      code: true,
      defining: true,
      parseDOM: [{ tag: 'pre', preserveWhitespace: 'full' }],
      toDOM() {
        return ['pre', ['code', 0]];
      },
    },

    // :: NodeSpec The text node.
    text: {
      group: 'inline',
    },

    //
    figure: {
      content: 'image* figcaption{1}',
      group: 'block',
      draggable: false,
      defining: true,
      parseDOM: [{ tag: 'figure' }],
      toDOM() {
        return ['figure', 0];
      },
    },

    // :: NodeSpec An inline image (`<img>`) node. Supports `src`,
    // `alt`, and `href` attributes. The latter two default to the empty
    // string.
    // image: {
    //   attrs: {
    //     src: { default: undefined },
    //     alt: { default: null },
    //     title: { default: null }
    //   },
    //   group: "figure",
    //   draggable: true,
    //   parseDOM: [{
    //     tag: "img[src]", getAttrs(dom: any) {
    //       return {
    //         src: dom.getAttribute("src"),
    //         title: dom.getAttribute("title"),
    //         alt: dom.getAttribute("alt")
    //       }
    //     }
    //   }],
    //   toDOM(node) {
    //     let { src, alt, title } = node.attrs;
    //     return ["img", { src, alt, title }]
    //   },
    // },
    image: {
      attrs: {
        src: {},
        alt: { default: null },
        title: { default: null },
      },
      defining: true,
      group: 'figure',
      draggable: false,
      parseDOM: [
        {
          tag: 'img[src]',
          getAttrs(dom) {
            const element = dom as HTMLElement;
            return {
              src: element.getAttribute('src'),
              title: element.getAttribute('title'),
              alt: element.getAttribute('alt'),
            };
          },
        },
      ],
      toDOM(node) {
        const { src, alt, title } = node.attrs;
        return ['img', { src, alt, title }];
      },
    },

    figcaption: {
      content: 'inline*',
      group: 'figure',
      defining: true,
      isolating: true,
      draggable: false,
      parseDOM: [{ tag: 'figcaption' }],
      toDOM() {
        return ['figcaption', 0];
      },
    },

    // :: NodeSpec A hard line break, represented in the DOM as `<br>`.
    // hard_break: {
    //   inline: true,
    //   group: "inline",
    //   selectable: false,
    //   parseDOM: [{ tag: "br" }],
    //   toDOM() { return ["br"] }
    // }
  },

  // :: Object [Specs](#model.MarkSpec) for the marks in the schema.
  marks: {
    // :: MarkSpec A link. Has `href` and `title` attributes. `title`
    // defaults to the empty string. Rendered and parsed as an `<a>`
    // element.
    // link: {
    //   attrs: {
    //     href: {},
    //     title: { default: null }
    //   },
    //   inclusive: false,
    //   parseDOM: [{
    //     tag: "a[href]", getAttrs(dom: any) {
    //       return { href: dom.getAttribute("href"), title: dom.getAttribute("title") }
    //     }
    //   }],
    //   toDOM(node) { let { href, title } = node.attrs; return ["a", { href, title }, 0] }
    // },

    // :: MarkSpec An emphasis mark. Rendered as an `<em>` element.
    // Has parse rules that also match `<i>` and `font-style: italic`.
    italic: {
      parseDOM: [{ tag: 'i' }, { tag: 'em' }, { style: 'font-style=italic' }],
      toDOM() {
        return ['i', 0];
      },
    },

    // :: MarkSpec A strong mark. Rendered as `<strong>`, parse rules
    // also match `<b>` and `font-weight: bold`.
    bold: {
      parseDOM: [
        { tag: 'strong' },
        // This works around a Google Docs misbehavior where
        // pasted content will be inexplicably wrapped in `<b>`
        // tags with a font-weight normal.
        {
          tag: 'b',
          getAttrs: (node) => {
            const element = node as HTMLElement;
            return element.style.fontWeight !== 'normal' && null;
          },
        },
        { style: 'font-weight', getAttrs: (value) => /^(bold(er)?|[5-9]\d{2,})$/.test(value as string) && null },
      ],
      toDOM() {
        return ['b', 0];
      },
    },

    // :: MarkSpec underline mark. Rendered as a `<u>` element.
    // also match `<u>` and `text-decoration: underline`.
    underline: {
      parseDOM: [{ tag: 'u' }, { style: 'text-decoration=underline', getAttrs: (value) => /underline/.test(value as string) && null }],
      toDOM() {
        return ['u', 0];
      },
    },

    // :: MarkSpec strikethrough mark. Rendered as a `<s>` element.
    // also match `<s>` and `text-decoration: line-through`.
    strikethrough: {
      parseDOM: [{ tag: 'strike' }, { tag: 's' }, { style: 'text-decoration=line-through' }],
      toDOM() {
        return ['s', 0];
      },
    },

    // :: MarkSpec Code font mark. Represented as a `<code>` element.
    // code: {
    //   parseDOM: [{ tag: "code" }],
    //   toDOM() { return ["code", 0] }
    // }
  },
});
