/* eslint-disable @typescript-eslint/ban-types */
import classNames from 'classnames';
import { convertFromRaw, RawDraftContentState } from 'draft-js';
import { EditorState, Transaction } from 'prosemirror-state';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import '../../../components/editor/Editor.css';
import Card from '../../../components/Card';
import Dropdown from '../../../components/Dropdown';
import Editor, { IEditorState } from '../../../components/editor/Editor';
import { insertNodeHorizontalRule } from '../../../components/editor/utils/commands';
import { insertImage } from '../../../components/editor/utils/editorUtils';
import { convertHtmlToDoc } from '../../../components/editor/utils/transformer';
import Input from '../../../components/form/Input';
import { PintoCategory } from '../../../core/graphql/types';
import contentStateToHtml from '../../../utils/contentRawState';
import CategorySelector from '../components/CategorySelector';
import { EBOOK_BUILDER_FIELD_KEYS } from '../validates/ebookBuilderSchema';

import EditEbookTagNamesPresenter from './EditEbookTagNamesPresenter';
import EditEbookWriterNamePresenter from './EditEbookWriterNamePresenter';

const insertTools = [
  {
    id: 'horizontalRule',
    content: (
      <div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
          <path
            fillRule="evenodd"
            d="M11 24h26v-5a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v10.01c0 .539-.446.99-.997.99H5.997A.998.998 0 0 1 5 29.01V19a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v5z"
          ></path>
        </svg>
      </div>
    ),
    action: insertNodeHorizontalRule,
    enable: insertNodeHorizontalRule,
    active: () => false,
  },
  {
    id: 'image',
    content: (
      <div>
        <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M8.619 5h30.762A3.62 3.62 0 0 1 43 8.619v30.762A3.62 3.62 0 0 1 39.381 43H8.619A3.62 3.62 0 0 1 5 39.381V8.619A3.62 3.62 0 0 1 8.619 5zM37 12a1 1 0 0 0-1-1H12a1 1 0 0 0-1 1v22l6.541-6.411a1 1 0 0 1 1.059-.22l3.89 1.493a1 1 0 0 0 1.068-.229l8.052-8.114a1 1 0 0 1 1.166-.185L37 22.5V12zM19 23a4 4 0 1 1 0-8 4 4 0 0 1 0 8z"
            fillRule="evenodd"
          ></path>
        </svg>
      </div>
    ),
    action: (state: EditorState, dispatch?: (tr: Transaction) => void) => {
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = '.png, .jpg, .jpeg';
      input.onchange = (e) => {
        const files = (e.target as HTMLInputElement)?.files;
        if (files) {
          setTimeout(() => {
            insertImage(URL.createObjectURL(files[0]))(state, dispatch);
          }, 1000);
        }
      };
      input.click();
      return true;
    },
    enable: () => true,
    active: () => false,
  },
];

interface IPropsEditEbookDetailPresenter {
  editorState: EditorState | null;
  setEditorState: (editorState: IEditorState) => void;
  currentCategoryName?: string;
  synopsis?:
    | {
        blocks: object[];
        entityMap: object;
      }
    | undefined;
  languageOptions?: { label: string; value: string }[];
  initCategories?: PintoCategory[];
}

function EditEbookDetailPresenter(props: IPropsEditEbookDetailPresenter) {
  const { synopsis, editorState, setEditorState, languageOptions, currentCategoryName, initCategories } = props;
  const {
    register,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();

  const [title, description, originalLanguageCode, languageCode] = watch([
    EBOOK_BUILDER_FIELD_KEYS.TITLE,
    EBOOK_BUILDER_FIELD_KEYS.DESCRIPTION,
    EBOOK_BUILDER_FIELD_KEYS.ORIGINAL_LANGUAGE_CODE,
    EBOOK_BUILDER_FIELD_KEYS.LANGUAGE_CODE,
  ]);

  const [initHtmlContent] = useState(synopsis ? contentStateToHtml(convertFromRaw(synopsis as RawDraftContentState)) : undefined);
  const [doc] = useState(convertHtmlToDoc(initHtmlContent || undefined));

  return (
    <Card className="space-y-16">
      <div className="text-22">รายละเอียด</div>

      {/* ชื่ออีบุ๊ก */}
      <div data-invalid={!!errors?.title?.message || !title?.length}>
        <Input
          name="title"
          label="ชื่ออีบุ๊ก"
          required
          placeholder="ใส่ชื่ออีบุ๊ก"
          helperText={`${title?.length || 0}/150`}
          register={register}
          isInvalid={!!errors?.title?.message}
        />
      </div>
      {/* -- end-- ชื่ออีบุ๊ก -- end -- */}

      {/* คำโปรย */}
      <div data-invalid={!!errors?.description?.message || !description?.length}>
        <Input
          name="description"
          label="คำโปรย"
          required
          placeholder="ใส่ประโยคฮุคชวนอ่าน เป็นคำขายสั้นๆ หรือโควทเด็ดประจำเล่ม และต้องมีอย่างน้อย 50 ตัวอักษร"
          helperText={errors?.description?.message || `${description?.length || 0}/200`}
          register={register}
          isInvalid={!!errors?.description?.message}
        />
      </div>

      {/* -- end-- คำโปรย -- end -- */}

      {/* แท็ก */}
      <EditEbookTagNamesPresenter />
      {/* -- end-- แท็ก -- end -- */}
      {/* เรื่องย่อ */}
      <div data-invalid={!!errors?.synopsis?.message}>
        <label className="label">
          <span className={classNames('label-text text-systemGrays02LabelSecondary')}>
            เรื่องย่อ <span className="text-colorsBrandRed01Primary">*</span>
          </span>
        </label>
        <div
          className={classNames('border rounded-b-8', {
            'border-colorsBrandRed01Primary ': !!errors?.synopsis?.message && (editorState?.doc?.textContent.length || 0) < 1,
          })}
        >
          <Editor
            // initHtmlContent={initHtmlContent}
            doc={doc}
            setEditorState={setEditorState}
            insertTools={insertTools}
            // inValid={!!errors?.synopsis?.message && (editorState?.doc?.textContent.length || 0) < 1}
            className={classNames('border-[px] rounded-b-8 border-solid', {
              'border-colorsBrandRed01Primary': !!errors?.synopsis?.message && (editorState?.doc?.textContent.length || 0) < 1,
            })}
          />
        </div>
        {!!errors?.synopsis?.message && (editorState?.doc?.textContent.length || 0) < 1 && (
          <div className="text-colorsBrandRed01Primary font-tt text-12">{errors?.synopsis?.message}</div>
        )}
      </div>
      {/* -- end-- เรื่องย่อ -- end -- */}

      {/* เลือกหมวดหมู่ */}
      <CategorySelector
        name={EBOOK_BUILDER_FIELD_KEYS.CATEGORY_ID}
        errorsMessage={errors?.categoryId?.message}
        currentCategoryName={currentCategoryName}
        initCategories={initCategories}
      />
      {/* -- end-- เลือกหมวดหมู่ -- end -- */}

      {/* ชื่อนักเขียน */}
      <EditEbookWriterNamePresenter
        placeholder="ใส่ชื่อนักเขียน"
        name={EBOOK_BUILDER_FIELD_KEYS.AUTHOR_NAMES}
        label="ชื่อนักเขียน"
        subLabel="สามารถเพิ่มได้สูงสุด 5 คน"
        errorsMessage={errors?.authorNames?.message}
        requiredLabel
        addButtonLabel="เพิ่มชื่อนักเขียน"
      />
      {/* -- end-- ชื่อนักเขียน -- end -- */}
      {/* ชื่อนักแปล */}
      <EditEbookWriterNamePresenter
        placeholder="ใส่ชื่อนักแปล"
        name={EBOOK_BUILDER_FIELD_KEYS.TRANSLATOR_NAMES}
        label="ชื่อนักแปล"
        subLabel="สามารถเพิ่มได้สูงสุด 5 คน"
        addButtonLabel="เพิ่มชื่อนักแปล"
      />
      {/* -- end-- ชื่อนักแปล -- end -- */}

      <div>
        <div className="text-systemGrays02LabelSecondary text-14">
          <p>ภาษา</p>
          <p>ภาษาต้นฉบับ : ต้นกำเนิดภาษาของหนังสือเล่มนั้นๆ</p>
          <p>ภาษาแปล: ภาษาที่ถูกแปลแล้วของหนังสือเล่มนั้นๆ</p>
        </div>
        <div className="flex flex-row space-x-24">
          {/* ภาษาต้นฉบับ */}
          <Dropdown
            className="min-w-[300px] w-full"
            name={EBOOK_BUILDER_FIELD_KEYS.ORIGINAL_LANGUAGE_CODE}
            register={register}
            dropdownList={languageOptions}
            label="ภาษาต้นฉบับ"
            placeholder="เลือกภาษาต้นฉบับ"
            currentOption={originalLanguageCode || 'THA'}
            handleSelectDropdown={(option) => {
              setValue(EBOOK_BUILDER_FIELD_KEYS.ORIGINAL_LANGUAGE_CODE, option);
            }}
          />
          {/* -- end-- ภาษาต้นฉบับ -- end -- */}
          {/* ภาษาแปล */}
          <Dropdown
            className="min-w-[300px] w-full"
            name={EBOOK_BUILDER_FIELD_KEYS.LANGUAGE_CODE}
            register={register}
            dropdownList={languageOptions}
            label="ภาษาแปล"
            placeholder="เลือกภาษาแปล"
            currentOption={languageCode || 'THA'}
            handleSelectDropdown={(option) => {
              setValue(EBOOK_BUILDER_FIELD_KEYS.LANGUAGE_CODE, option);
            }}
          />
          {/* -- end-- ภาษาแปล -- end -- */}
        </div>
      </div>
    </Card>
  );
}

export default EditEbookDetailPresenter;
