import classNames from 'classnames';
import { debounce } from 'lodash';
import { useCallback, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { IoMdClose } from 'react-icons/io';

import SearchInputDropdown from '../../../components/form/SearchInputDropdown';
import useSearchTags from '../../../hooks/useQuerySearchTags';
import { EBOOK_BUILDER_FIELD_KEYS } from '../validates/ebookBuilderSchema';

function EditEbookTagNamesPresenter() {
  const { setValue, control, watch } = useFormContext();
  const [tagNames] = watch([EBOOK_BUILDER_FIELD_KEYS.TAG_NAMES]);

  const { remove } = useFieldArray({
    control,
    name: EBOOK_BUILDER_FIELD_KEYS.TAG_NAMES,
  });

  const { fetchSearchTags, searchTags } = useSearchTags();

  const [tagNameSearch, setTagNameSearch] = useState('');

  const onSearchTagName = useCallback(
    (keyword: string) => {
      if (keyword) {
        fetchSearchTags(keyword, { isReserved: false });
      }
    },
    [fetchSearchTags],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChangeSearchTagName = useCallback(
    debounce((searchText: string) => {
      onSearchTagName(searchText);
    }, 300),
    [],
  );

  const onSelectedItem = useCallback(
    (item) => {
      if (tagNames?.find((tag: { name: string; _id: string }) => tag?.name === item?.label)) return;
      setValue(EBOOK_BUILDER_FIELD_KEYS.TAG_NAMES, [...tagNames, { _id: item.value, name: item.label }]);
      setTagNameSearch('');
    },
    [setValue, tagNames],
  );

  const onChangeSearchText = useCallback(
    (text: string) => {
      setTagNameSearch(text);
      onChangeSearchTagName(text);
    },
    [onChangeSearchTagName],
  );
  const onEnterNewTagName = useCallback(() => {
    setValue(EBOOK_BUILDER_FIELD_KEYS.TAG_NAMES, [...tagNames, { _id: tagNameSearch, name: tagNameSearch }]);
    onChangeSearchText('');
  }, [onChangeSearchText, setValue, tagNameSearch, tagNames]);

  return (
    <div>
      <div className={classNames('form-control')}>
        <label className="label">
          <span className="label-text text-systemGrays02LabelSecondary">แท็ก</span>
        </label>

        {(tagNames?.length || 0) > 0 && (
          <div className="flex flex-wrap gap-8 p-16 border-[1px] border-solid border-colorsBrandWarmBlack04LabelQuaternary rounded-6 min-h-[48px]">
            {tagNames?.map((item: { name: string; _id: string }, k: number) => (
              <button
                key={k}
                type="button"
                onClick={() => remove(k)}
                className="h-[30px] px-8 py-4 border border-systemGrays02LabelSecondary rounded-box flex justify-between items-center gap-x-8"
              >
                <div>{item.name}</div>
                <div className="p-4 pr-0 bg-componentsBgGrouped02 rounded-box">
                  <IoMdClose color="var(--systemGrays01LabelPrimary)" />
                </div>
              </button>
            ))}
          </div>
        )}
        <SearchInputDropdown
          items={searchTags?.edges?.map(({ node }) => ({ label: node?.name, value: node?._id }))}
          value={tagNameSearch}
          onChange={onChangeSearchText}
          menuClassName="w-full"
          onClickMenuItem={onSelectedItem}
          placeholder="ใส่แท็กที่ต้องการ"
          onKeydown={(e) => {
            if (e.key === 'Enter') {
              onEnterNewTagName();
            }
          }}
        />
      </div>
    </div>
  );
}

export default EditEbookTagNamesPresenter;
