import { baseKeymap, chainCommands, exitCode, joinDown, joinUp, lift, selectParentNode } from 'prosemirror-commands';
import { redo, undo } from 'prosemirror-history';
import { undoInputRule } from 'prosemirror-inputrules';
import { keymap } from 'prosemirror-keymap';
import { Plugin } from 'prosemirror-state';

// far far away in the future
import {
  insertNodeHorizontalRule,
  toggleMarkBold,
  toggleMarkItalic,
  toggleMarkStrikethrough,
  toggleMarkUnderline,
  toggleWrapBlockquote,
  insertNodeLineBreak,
  // toggleMarkCode,
} from '../utils/commands';
import { insertNewParagraph, toggleParagraphBetweenClass } from '../utils/editorUtils';

export const editorKeys = (): Plugin =>
  keymap({
    'Mod-z': undo,
    'Shift-Mod-z': redo,
    Backspace: undoInputRule,
    'Mod-y': redo,
    'Alt-ArrowUp': joinUp,
    'Alt-ArrowDown': joinDown,
    'Mod-BracketLeft': lift,
    Escape: selectParentNode,

    // inline-style, marks
    'Mod-b': toggleMarkBold,
    'Mod-B': toggleMarkBold,
    'Mod-i': toggleMarkItalic,
    'Mod-I': toggleMarkItalic,
    // 'Mod-`': toggleMarkCode,
    'Mod-u': toggleMarkUnderline,
    'Mod-U': toggleMarkUnderline,
    'Mod->': toggleWrapBlockquote,
    'Mod-K': toggleMarkStrikethrough,
    'Mod-k': toggleMarkStrikethrough,
    Enter: insertNewParagraph,
    'Mod-Enter': chainCommands(exitCode, insertNodeLineBreak),
    'Shift-Enter': chainCommands(exitCode, insertNodeLineBreak),
    'Ctrl-Enter': chainCommands(exitCode, insertNodeLineBreak), // mac-only?
    'Mod-_': insertNodeHorizontalRule,
    Tab: toggleParagraphBetweenClass('indent'),
  });

export const baseKeys = (): Plugin => keymap(baseKeymap);
