import images from '../../../assets/images';

export interface IPropsDropdownUserRowItem {
  profileImage?: string;
  displayName?: string;
  userName?: string;
  isPremium?: boolean;
}

function DropdownUserRowItem(props: IPropsDropdownUserRowItem) {
  const { profileImage, displayName, userName, isPremium } = props;

  return (
    <div className="flex flex-row w-full items-center overflow-hidden justify-evenly">
      <div className="avatar">
        <div className="w-[32px] h-[32px] rounded-full">
          <img src={profileImage || images.pinto.default.profileDefault} alt="Avatar" />
        </div>
      </div>
      <div className="flex flex-1 flex-col ml-12 justify-center truncate">
        <div className="text-16 font-dbh truncate">{displayName?.trim()}</div>
        <div className="text-14 font-tt text-systemGrays02LabelSecondary">{userName?.startsWith('@') ? userName.trim() : `@${userName?.trim()}`}</div>
      </div>
      {isPremium && (
        <div>
          <div className="badge badge-warning text-12 font-tt">Premium</div>
        </div>
      )}
    </div>
  );
}

export default DropdownUserRowItem;
