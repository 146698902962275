import { isNil, omitBy } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { FiDownload, FiPlus } from 'react-icons/fi';
import { Link } from 'react-router-dom';

import { IPropsDiscountListItem } from '../../app/discount/components/DiscountListItem';
import DiscountListTablePresenter from '../../app/discount/presenters/DiscountListTablePresenter';
import FilterAndSearchPresenter from '../../app/discount/presenters/FilterAndSearchPresenter';
import ConfirmModal from '../../app/modals/ConfirmModal';
import ToastComponent, { IToastType } from '../../components/Toast/ToastComponent';
import { EbookDiscountCampaignStatus } from '../../core/graphql/types';
import useMutationPintoCancelEbookDiscountCampaign from '../../hooks/useMutationPintoCancelEbookDiscountCampaign';
import useQueryPintoEbookDiscountCampaigns from '../../hooks/useQueryPintoEbookDiscountCampaigns';
import { downloadCSV } from '../../utils/blob';

const StatusOptions = [
  {
    label: 'All status',
    value: null,
  },
  {
    label: 'In Queue',
    value: EbookDiscountCampaignStatus.Pending,
  },
  {
    label: 'In Progress',
    value: EbookDiscountCampaignStatus.Processing,
  },
  {
    label: 'Completed',
    value: EbookDiscountCampaignStatus.Complete,
  },
  {
    label: 'Terminated',
    value: EbookDiscountCampaignStatus.Cancelled,
  },
];

const CSV_URL_PATH = '../../a/template/Discount_ebook_Template.csv';

const DiscountPage = () => {
  const { ebookDiscountCampaigns, fetchPintoEbookDiscountCampaigns, isLoadingPintoEbookDiscountCampaigns } = useQueryPintoEbookDiscountCampaigns();
  const { cancelEbookDiscountCampaign } = useMutationPintoCancelEbookDiscountCampaign();

  const [searchText, setSearchText] = useState<string | undefined>(undefined);

  const [status, setStatus] = useState<EbookDiscountCampaignStatus | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);

  //modal
  const [showConfirmTerminateDiscount, setShowConfirmTerminateDiscount] = useState<boolean>(false);
  const [ebookDiscountCampaignId, setEbookDiscountCampaignId] = useState<string | undefined>(undefined);

  const handleChangePage = useCallback(
    (page: number) => {
      const offset = (page - 1) * 10;
      setCurrentPage(page);
      const filter = omitBy({ status, offset }, isNil);
      fetchPintoEbookDiscountCampaigns(filter);
    },
    [fetchPintoEbookDiscountCampaigns, status],
  );

  useEffect(() => {
    const filter = omitBy({ status, q: searchText }, isNil);
    fetchPintoEbookDiscountCampaigns(filter);
  }, [fetchPintoEbookDiscountCampaigns, searchText, status]);

  const handleSetSearchText = useCallback((text) => {
    setSearchText(text);
  }, []);

  const handleOnTerminate = useCallback((ebookDiscountCampaignId: string) => {
    setShowConfirmTerminateDiscount(true);
    setEbookDiscountCampaignId(ebookDiscountCampaignId);
  }, []);

  const onSubmitTerminateDiscount = useCallback(() => {
    if (ebookDiscountCampaignId) {
      cancelEbookDiscountCampaign(ebookDiscountCampaignId);
      setShowConfirmTerminateDiscount(false);
      ToastComponent({
        label: 'Terminate successfully',
        type: IToastType.SUCCESS,
      });
    }
  }, [cancelEbookDiscountCampaign, ebookDiscountCampaignId]);

  return (
    <div className="mx-auto space-y-24">
      {showConfirmTerminateDiscount && (
        <ConfirmModal
          onClose={() => setShowConfirmTerminateDiscount(false)}
          onConFirm={() => onSubmitTerminateDiscount()}
          title="Terminate"
          subTitle="Discount will be cancel and price of e-book will change 
          to original price. Are you confirm to terminate?"
        />
      )}

      <div className="space-y-24 divider-y">
        {/* section: title */}
        <div className="flex justify-between py-5">
          <h1 className="font-dbh text-[28px]">Discount</h1>

          <div className="space-x-16">
            <button className="space-x-8 btn btn-error btn-outline" onClick={() => downloadCSV(CSV_URL_PATH, 'discount_ebook_template.csv')}>
              <FiDownload className="font-bold text-24" />
              <span>download Template</span>
            </button>

            <Link to="/discount/edit">
              <button className="space-x-8 btn btn-error">
                <FiPlus className="font-bold text-24" />
                <span>Create New Schedule Discount</span>
              </button>
            </Link>
          </div>
        </div>
        {/* filter and search */}
        <div className="space-y-8">
          <div className="text-colorsBrandWarmBlack02LabelSecondary">Sort by: Created date</div>
          <FilterAndSearchPresenter onSetSearchText={handleSetSearchText} status={status} onSetStatus={(_status) => setStatus(_status)} statusOptions={StatusOptions} />
        </div>
      </div>

      {/* table content */}
      <div>
        {isLoadingPintoEbookDiscountCampaigns && <div className="w-full btn btn-outline btn-disabled bg-systemGrays04LabelQuaternary border-none loading h-[100px]" />}

        {!isLoadingPintoEbookDiscountCampaigns && (
          <>
            {(ebookDiscountCampaigns?.edges?.length || 0) === 0 && (
              <div className="flex items-center justify-center w-full h-[60vh] text-colorsBrandWarmBlack02LabelSecondary">No list of discount</div>
            )}

            {(ebookDiscountCampaigns?.edges?.length || 0) !== 0 && (
              <DiscountListTablePresenter
                disCountLists={ebookDiscountCampaigns?.edges.map(
                  ({ node }) =>
                    ({
                      _id: node._id,
                      createdDate: node.createdAt,
                      discountDateTime: {
                        startedDate: node.startedAt,
                        endedDate: node.endedAt,
                      },
                      title: node.title,
                      status: node.status as unknown as EbookDiscountCampaignStatus,
                    } as IPropsDiscountListItem),
                )}
                currentPage={currentPage}
                totalCount={ebookDiscountCampaigns?.totalCount}
                onChangePage={handleChangePage}
                onTerminate={handleOnTerminate}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default DiscountPage;
