import { convertToHTML } from 'draft-convert';
import { ContentState } from 'draft-js';

import { Inline, Block, Entity } from './constants';

export const styleToHTML = (style: string) => {
  switch (style) {
    case Inline.ITALIC:
      return <em className={`storylog-inline-${style.toLowerCase()}`} />;
    case Inline.BOLD:
      return <strong className={`storylog-inline-${style.toLowerCase()}`} />;
    case Inline.STRIKETHROUGH:
      // <strike/> Not Supported in HTML5.
      // return <strike className={`storylog-inline-${style.toLowerCase()}`} />;
      return <s className={`storylog-inline-${style.toLowerCase()}`} />;
    case Inline.UNDERLINE:
      return <u className={`storylog-inline-${style.toLowerCase()}`} />;
    // case Inline.HIGHLIGHT:
    //   return <span className={`storylog-inline-${style.toLowerCase()}`} />;
    // case Inline.CODE:
    //   return <code className={`storylog-inline-${style.toLowerCase()}`} />;
    default:
      return <span />;
  }
};

export const blockToHTML = (block: any) => {
  const blockType = block.type;
  switch (blockType) {
    // case Block.H1:
    //   // eslint-disable-next-line jsx-a11y/heading-has-content
    //   return <h1 className={`storylog-editor-${blockType.toLowerCase()}`} />;
    // case Block.H2:
    //   // eslint-disable-next-line jsx-a11y/heading-has-content
    //   return <h2 className={`storylog-editor-${blockType.toLowerCase()}`} />;
    case Block.H3:
      return <h3 className={`storylog-editor-${blockType.toLowerCase()}`} />;
    // case Block.H4:
    //   // eslint-disable-next-line jsx-a11y/heading-has-content
    //   return <h4 className={`storylog-editor-${blockType.toLowerCase()}`} />;
    // case Block.H5:
    //   // eslint-disable-next-line jsx-a11y/heading-has-content
    //   return <h5 className={`storylog-editor-${blockType.toLowerCase()}`} />;
    // case Block.H6:
    //   // eslint-disable-next-line jsx-a11y/heading-has-content
    //   return <h6 className={`storylog-editor-${blockType.toLowerCase()}`} />;
    // case Block.BLOCKQUOTE_CAPTION:
    // case Block.CAPTION:
    //   return {
    //     start: `<p class="storylog-editor-${blockType.toLowerCase()}"><caption>`,
    //     end: '</caption></p>',
    //   };
    case Block.IMAGE: {
      const imgData = block.data;
      if (!imgData || imgData === '') return undefined;
      const text = block.text.trim() || '';
      const captionElement = text.length > 0 ? <figcaption className="description-image">{text}</figcaption> : '';
      return (
        <figure className="storylog-editor storylog-editor-image">
          <img src={`${imgData.src}`} alt={`${text}`} />
          {captionElement}
        </figure>
      );
    }
    case Block.ATOMIC:
      return {
        start: `<figure className="storylog-editor-${blockType.toLowerCase()}">`,
        end: '</figure>',
      };
    // case Block.TODO: {
    //   const checked = block.data.checked || false;
    //   let inp = '';
    //   let containerClass = '';
    //   if (checked) {
    //     inp = '<input type=checkbox disabled checked="checked" />';
    //     containerClass = 'storylog-block-todo-checked';
    //   } else {
    //     inp = '<input type=checkbox disabled />';
    //     containerClass = 'storylog-block-todo-unchecked';
    //   }
    //   return {
    //     start: `<div class="storylog-editor-${blockType.toLowerCase()} ${containerClass}">${inp}<p>`,
    //     end: '</p></div>',
    //   };
    // }
    case Block.BREAK:
      return <hr className="storylog-editor-hr" />;
    case Block.BLOCKQUOTE:
      return (
        <blockquote className="storylog-editor storylog-editor-blockquote">
          <div>{block.text}</div>
        </blockquote>
      );
    case Block.OL:
      return {
        element: <li />,
        nest: <ol className={`storylog-editor-${blockType.toLowerCase()}`} />,
      };
    case Block.UL:
      return {
        element: <li />,
        nest: <ul className={`storylog-editor-${blockType.toLowerCase()}`} />,
      };
    case Block.UNSTYLED:
    case Block.CENTER:
    case Block.RIGHT:
    case Block.INDENT:
      if (block.text.length < 1) {
        return (
          <p className={`storylog-editor ${blockType.toLowerCase()}`}>
            <br />
          </p>
        );
      }
      return <p className={`storylog-editor ${blockType.toLowerCase()}`} />;

    default:
      return <span />;
  }
};

export const entityToHTML = (entity: { type: string; data: { url?: string } }, originalText: string) => {
  if (entity.type === Entity.LINK) {
    return (
      <a className="storylog-inline-link" href={entity.data?.url} target="_blank" rel="noopener noreferrer">
        {originalText}
      </a>
    );
  }
  return originalText;
};

export const exportOptions = {
  styleToHTML,
  blockToHTML,
  entityToHTML,
};

export const setRenderOptions = (htmlOptions = exportOptions) => convertToHTML(htmlOptions);

const contentStateToHtml = (contentState: ContentState, htmlOptions = exportOptions) => convertToHTML(htmlOptions)(contentState);

export default contentStateToHtml;
