import { useCallback, useMemo } from 'react';

import useQueryPintoProductSet from '../../../hooks/useQueryPintoProductSet';
import useQueryPintoProductSets from '../../../hooks/useQueryPintoProductSets';

const useUsersProductSets = () => {
  const { fetchPintoProductSets, dataPintoProductSets, isLoadingPintoProductSets } = useQueryPintoProductSets();
  const { fetchPintoProductSet, dataPintoProductSet } = useQueryPintoProductSet();
  const onFetchProductSets = useCallback(
    (q?: string, userId?: string) => {
      if (userId) {
        //query product set list
        fetchPintoProductSets({
          filter: {
            userId,
            q,
          },
        });
      }
    },
    [fetchPintoProductSets],
  );

  const onFetchProductSetDetail = useCallback(
    (productSetId: string) => {
      fetchPintoProductSet(productSetId);
    },
    [fetchPintoProductSet],
  );

  const productSetItems = useMemo(() => {
    if (!!dataPintoProductSets && dataPintoProductSets.edges.length > 0) {
      return (dataPintoProductSets?.edges || [])?.map(({ node }) => ({
        label: node.title || 'ไม่มีชื่อเซ็ตหนังสือ',
        value: node._id,
      }));
    }
    return [];
  }, [dataPintoProductSets]);

  // get simple of product set detail from pintoProductSets
  const getProductSetSimpleDetail = useCallback(
    (currentProductSetId?: string) => {
      if (!!dataPintoProductSets && dataPintoProductSets.edges.length > 0 && currentProductSetId) {
        return dataPintoProductSets.edges.find(({ node }) => node._id === currentProductSetId)?.node;
      }
      return undefined;
    },
    [dataPintoProductSets],
  );

  return {
    usersProductSets: productSetItems,
    onFetchProductSets,
    onFetchProductSetDetail,
    productSetDetail: dataPintoProductSet,
    getProductSetSimpleDetail,
    isLoadingPintoProductSets,
  };
};

export default useUsersProductSets;
