import { useCallback } from 'react';

import { usePintoProductSetsLazyQuery } from '../core/graphql/operation-hooks';
import { PintoProductSetsFilter, PintoProductSetsFilterSortBy, SortType } from '../core/graphql/types';
export interface IPropsFetchPintoProductSets {
  q?: string;
  filter: PintoProductSetsFilter;
}

const INITIAL_LIMIT = 10;

const useQueryPintoProductSets = () => {
  const [queryPintoProductSets, { data, loading, fetchMore, variables }] = usePintoProductSetsLazyQuery({ fetchPolicy: 'cache-and-network' });

  const fetchPintoProductSets = useCallback(
    ({ filter }: IPropsFetchPintoProductSets) => {
      queryPintoProductSets({
        variables: {
          filter: {
            ...filter,
            limit: INITIAL_LIMIT,
            sortBy: PintoProductSetsFilterSortBy.Title,
            sortType: SortType.Asc,
          },
        },
      });
    },
    [queryPintoProductSets],
  );

  const onFetchMorePintoProductSets = useCallback(() => {
    if (loading || !data?.pintoProductSets?.pageInfo.hasNextPage) return;
    fetchMore?.({
      variables: {
        filter: {
          ...variables?.filter,
          afterCursor: data?.pintoProductSets?.pageInfo.endCursor,
        },
      },
    });
  }, [data?.pintoProductSets?.pageInfo.endCursor, data?.pintoProductSets?.pageInfo.hasNextPage, fetchMore, loading, variables?.filter]);

  return { fetchPintoProductSets, dataPintoProductSets: data?.pintoProductSets, isLoadingPintoProductSets: loading, onFetchMorePintoProductSets };
};
export default useQueryPintoProductSets;
