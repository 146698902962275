/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const textItalic = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path
      fill="#8C8C8C"
      fillRule="evenodd"
      d="M21.66 8h15.894c1.104 0 1.851.88 1.67 1.965l-.345 2.07C38.7 13.115 37.656 14 36.55 14h-3.555c-.545 0-1.179.408-1.405.911L23.41 33.09c-.23.511.036.911.585.911h3.555c1.105 0 1.855.88 1.674 1.965l-.345 2.07C28.7 39.115 27.66 40 26.557 40H10.694c-1.09 0-1.848-.88-1.667-1.965l.345-2.07c.18-1.08 1.22-1.965 2.323-1.965h2.246c.551 0 1.192-.408 1.43-.911l8.635-18.178c.243-.511-.018-.911-.573-.911h-2.767c-1.102 0-1.858-.88-1.677-1.965l.345-2.07C19.514 8.885 20.556 8 21.66 8z"
    />
  </svg>
);

export default textItalic;
