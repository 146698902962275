import { FieldValues, UseFormRegister } from 'react-hook-form';

import Input from '../../../components/form/Input';
import { IConfigItemType } from '../validates/sectionBuilderSchema';

export interface IPropsProductSetItem {
  type: IConfigItemType.ProductSet;
  _id?: string;
}
export interface IPropsProductSetSectionItem {
  index: number;
  itemData: IPropsProductSetItem;
  register: UseFormRegister<FieldValues>;
}

const ProductSetSectionItem = (props: IPropsProductSetSectionItem) => {
  const { index, register } = props;

  return (
    <div className="flex justify-between space-x-16">
      <div className="w-full collapse">
        <input type="checkbox" className="peer" />
        <div className=" collapse-title bg-systemGrays08FillQuaternary text-systemGrays01LabelPrimary peer-checked:bg-systemGrays08FillQuaternary peer-checked:text-systemGrays01LabelPrimary flex justify-between after:content-['+'] peer-checked:after:content-['-'] ease-out duration-300">
          Product Set
        </div>
        <div className="collapse-content text-systemGrays01LabelPrimary peer-checked:bg-systemGrays08FillQuaternary peer-checked:text-systemGrays01LabelPrimary">
          <div className="space-y-8">
            <Input register={register} name={`configItems.${index}._id`} label="Product Set Id" helperText="Input ProductSet ID" placeholder="product set ID" required />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductSetSectionItem;
