const scrollToView = (query: string) => {
  const inputField = document.querySelectorAll(query)[0];
  if (inputField) {
    inputField.scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'center' });

    // NOTE: scrollTo not working in this project, I guest problem is about css lib that fix height: is not auto.
    // const bodyRect = document.body.getBoundingClientRect();
    // const elemRect = inputField.getBoundingClientRect();
    // const offset = elemRect.top - bodyRect.top;
    // window.scrollTo({ top: offset - 80, behavior: 'smooth' });
  }
};

export default scrollToView;
