/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const questionMark = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path
      fill="#8C8C8C"
      fillRule="evenodd"
      d="M24 46C11.85 46 2 36.15 2 24S11.85 2 24 2s22 9.85 22 22-9.85 22-22 22zm0-6c8.837 0 16-7.163 16-16S32.837 8 24 8 8 15.163 8 24s7.163 16 16 16zm3-7.024c-.001 1.732-1.318 3.03-3.07 3.024-1.603-.007-2.928-1.35-2.93-2.975-.002-1.706 1.32-3.027 3.024-3.025A2.975 2.975 0 0127 32.976zm-2.039-5.182a2.353 2.353 0 01-1.922-4.295c3.008-1.347 4.255-2.594 4.255-3.5a3.294 3.294 0 00-6.588 0A2.353 2.353 0 0116 20a8 8 0 0116 0c0 3.253-2.518 5.771-7.039 7.795z"
    />
  </svg>
);

export default questionMark;
