/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const checkboxEmpty = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path
      fill="#8C8C8C"
      fillRule="evenodd"
      d="M11 11v26h26V11H11zm27.778-6C41.12 5 43 6.9 43 9.222v29.556A4.221 4.221 0 0138.778 43H9.222A4.221 4.221 0 015 38.778V9.222A4.221 4.221 0 019.222 5h29.556z"
    />
  </svg>
);

export default checkboxEmpty;
