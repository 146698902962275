import ClipLoader from 'react-spinners/ClipLoader';

export interface IPropsLoadingModal {
  title?: string;
  color?: string;
}

const LoadingModal = (props: IPropsLoadingModal) => {
  const { title } = props;

  return (
    <>
      <div className="modal modal-open">
        <div className="relative flex flex-col items-center justify-center space-y-16 modal-box">
          <ClipLoader color="var(--colorsBrandRed01Primary)" size={50} />
          <h3 className="text-lg font-bold font-dbh">{title || 'กำลังโหลด'}</h3>
        </div>
      </div>
    </>
  );
};

export default LoadingModal;
