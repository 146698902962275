import { Plugin } from 'prosemirror-state';
import { Decoration, DecorationSet } from 'prosemirror-view';

import schema from '../schema';

export const placeholder = (): Plugin =>
  new Plugin({
    // view: view => ({
    //   update: (view, prevState) => {
    //     console.log(view.state.);
    //   },
    // }),
    props: {
      decorations: (state) => {
        const decorations: Decoration[] = [];

        if (state.doc.content.size === 2) {
          decorations.push(
            Decoration.node(0, 2, {
              class: 'show-placeholder',
            }),
          );
        }

        return DecorationSet.create(state.doc, decorations);
      },
    },
  });
