import { ElementType } from 'react';

import { IModalPayload } from '../core/context/modalReducer';
import useModal from '../core/hook/context/useModal';

import ConfirmModal from './ConfirmModal';
import ProgressUploadFileModal from './ProgressUploadFileModal';

const AllModal: Record<keyof IModalPayload, ElementType> = {
  progressUploadFileModal: ProgressUploadFileModal,
  confirmModal: ConfirmModal,
};

const AllModals = () => {
  const { modals } = useModal();
  return (
    <div>
      {Object.keys(modals)?.map((modalName) => {
        const Modal = AllModal[modalName as keyof IModalPayload];
        // eslint-disable-next-line react/jsx-props-no-spreading
        return <Modal key={modalName} {...modals[modalName as keyof IModalPayload]} />;
      })}
    </div>
  );
};

export default AllModals;
