import { useEffect } from 'react';

import { useTemplateByQueryLazyQuery } from '../core/graphql/operation-hooks';
import { TemplateFilter } from '../core/graphql/types';

export type IPropsUseQueryTemplateByQuery = TemplateFilter;

const useQueryTemplateByQuery = (props: IPropsUseQueryTemplateByQuery) => {
  const [queryTemplateByQuery, { data, loading }] = useTemplateByQueryLazyQuery({
    variables: {
      filter: props,
    },
  });

  useEffect(() => {
    queryTemplateByQuery();
  }, [queryTemplateByQuery]);

  return {
    queryTemplateByQuery,
    templateByQuery: data?.templateByQuery,
    isLoadingTemplateByQuery: loading,
  };
};

export default useQueryTemplateByQuery;
