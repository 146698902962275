import classNames from 'classnames';
import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';

import Card from '../../../components/Card';
import SearchInputDropdown from '../../../components/form/SearchInputDropdown';
import { UserType } from '../../../core/graphql/types';
import useQuerySearchUsers from '../../../hooks/useQuerySearchUsers';
import DropdownUserRowItem from '../components/DropdownUserRowItem';
import UserProfile, { IPropsSelectedUser } from '../components/UserProfile';

const USER_TYPE_OPTIONS = [
  {
    label: 'Individual',
    value: UserType.Individual,
  },
  {
    label: 'Corporation',
    value: UserType.Corporation,
  },
  {
    label: 'Staff',
    value: UserType.Staff,
  },
];

interface IPropsEditSubscriptionUserCreatorPresenter {
  onSelectedUser: (userId: string) => void;
  selectedUser?: IPropsSelectedUser;
  isEditUser?: boolean;
  errors?: string;
}

const EditSubscriptionUserCreatorPresenter = (props: IPropsEditSubscriptionUserCreatorPresenter) => {
  const { onSelectedUser, selectedUser: _selectedUser, isEditUser } = props;

  const { searchUsers, fetchSearchUsers } = useQuerySearchUsers();
  const [inputSearchText, setInpurSearchText] = useState<string | undefined>(undefined);
  const [q, setQ] = useState<string | undefined>(undefined);
  const [userType, setUserType] = useState<UserType>(UserType.Individual);

  const [selectedUser, setSelectedUser] = useState<IPropsSelectedUser | undefined>(_selectedUser);

  const onSetSelectedUser = useCallback(
    (user: IPropsSelectedUser) => {
      if (!user._id) return;

      onSelectedUser(user._id);
      setSelectedUser(user);
    },
    [onSelectedUser],
  );

  useEffect(() => fetchSearchUsers({ q, filter: { type: userType } }), [fetchSearchUsers, q, userType]);

  const mappedUserItems = useMemo(() => {
    return searchUsers?.edges.map(({ node }) => ({
      label: node?.displayName || '',
      value: node?._id || '',
      node: <DropdownUserRowItem profileImage={node?.profileImage || ''} displayName={node?.displayName || ''} userName={node?.username || ''} isPremium={node.isPremium} />,
    }));
  }, [searchUsers?.edges]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChangeQueryString = useCallback(
    debounce((value) => setQ(value), 500),
    [],
  );

  const onChangeSearchText = useCallback(
    (text?: string) => {
      setInpurSearchText(text);
      onChangeQueryString(text);
    },
    [onChangeQueryString],
  );

  return (
    <Card className={classNames('flex flex-col space-y-4', { 'border-colorsBrandRed01Primary border-2 border-solid': !selectedUser?._id })}>
      <div className="space-x-16 text-22" data-invalid={!selectedUser?._id}>
        <span>User Account</span>
        {!selectedUser?._id && <span className="text-colorsBrandRed01Primary text-16">{'( กรุณาระบุ User ที่เป็นเจ้าของ Subscription นี้ เป็นลำดับแรก )'}</span>}
      </div>

      <div className="flex flex-col">
        <div className={'w-full transition-height duration-300'} style={{ height: selectedUser ? 96 : 0, overflowY: selectedUser ? 'auto' : 'hidden' }}>
          <UserProfile {...selectedUser} />
        </div>

        {!isEditUser && (
          <div className="flex w-full gap-16">
            <div className="w-1/6 max-w-xs form-control">
              <label className="label">
                <span className="label-text">User Type</span>
              </label>
              <select className="select select-bordered" onChange={(e) => setUserType(e.target.value as UserType)}>
                {USER_TYPE_OPTIONS.map((option) => (
                  <option value={option.value} key={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>

            <div className="w-1/3 max-w-xs form-control">
              <label className="label">
                <span className="label-text">Search User*</span>
              </label>
              <SearchInputDropdown
                items={mappedUserItems}
                value={inputSearchText}
                onChange={(value) => onChangeSearchText(value)}
                menuClassName="w-full"
                onClickMenuItem={(selectItem) => onSetSelectedUser(searchUsers?.edges.map(({ node }) => node).find((item) => item._id === selectItem.value) as IPropsSelectedUser)}
                placeholder="User ID, User Name or Email"
              />
            </div>
          </div>
        )}
      </div>
    </Card>
  );
};

export default EditSubscriptionUserCreatorPresenter;
