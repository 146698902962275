import classNames from 'classnames';
import { useState, ReactNode, useRef, useCallback, useEffect } from 'react';

import Icon from '../fictionlog/components/Icon';

export interface IPropsContentEllipsis {
  children: ReactNode;
  lineClamped?: number;
  showCollapseButton?: boolean;
}

const ContentEllipsis = (props: IPropsContentEllipsis) => {
  const { children, lineClamped, showCollapseButton } = props;
  const [isCollapse, setIsCollapse] = useState(false);
  const [isShowCollapseButton, setIsShowCollapseButton] = useState(!!showCollapseButton);
  const nodeRef = useRef<HTMLDivElement | null>(null);

  const getCollapseHeight = useCallback((element: Element, _lineClamped?: number) => {
    if (nodeRef?.current) {
      const elementStyle = window.getComputedStyle(element);
      const lineHeigh = Number(elementStyle.getPropertyValue('line-height').replace(/[^\d|.]/g, '') || 0);

      return lineHeigh * (_lineClamped || 3);
    }
    return 0;
  }, []);

  const setCollapseButton = useCallback(() => {
    let clamped = false;
    if (nodeRef?.current) {
      const childrenElement = nodeRef.current?.children[0];
      const childrenElementHeight = childrenElement.clientHeight;

      const collapseHeight = getCollapseHeight(childrenElement, lineClamped);
      clamped = childrenElementHeight > collapseHeight;
    }
    setIsShowCollapseButton(!clamped);
  }, [getCollapseHeight, lineClamped]);

  // change style when
  const onChangeStyleCollapse = useCallback(() => {
    if (!nodeRef?.current) return;

    let maxHeight = 'initial';
    let overflow = 'auto';

    if (!isCollapse) {
      const childrenElement = nodeRef.current?.children[0];
      const collapseHeight = getCollapseHeight(childrenElement, lineClamped);

      maxHeight = `${collapseHeight}px`;
      overflow = 'hidden';
    }

    nodeRef.current.style.maxHeight = maxHeight;
    nodeRef.current.style.overflow = overflow;
  }, [getCollapseHeight, isCollapse, lineClamped]);

  // show collapse button
  useEffect(() => {
    if (nodeRef.current && children) {
      setCollapseButton();
    }
  }, [children, setCollapseButton]);

  // change style at nodeRef element
  useEffect(() => {
    if (nodeRef.current && children) {
      onChangeStyleCollapse();
    }
  }, [children, onChangeStyleCollapse]);

  return (
    <div className="flex w-full flex-col break-words">
      <div ref={nodeRef} className={classNames('whitespace-pre-wrap text-colorsBrandWarmBlack01LabelPrimary')}>
        {children}
      </div>
      {!isShowCollapseButton && (
        <button
          type="button"
          onClick={() => setIsCollapse(!isCollapse)}
          className="m-0 flex cursor-pointer items-center space-x-4 self-end border-0 bg-transparent px-12 py-8 font-semibold text-colorsBrandWarmBlack01LabelPrimary"
        >
          <span>{isCollapse ? 'แสดงน้อยลง' : 'แสดงมากขึ้น'}</span>
          <Icon name={isCollapse ? 'caretUp' : 'caretDown'} color="var(--colorsBrandWarmBlack01LabelPrimary)" />
        </button>
      )}
    </div>
  );
};

export default ContentEllipsis;
