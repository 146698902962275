import classNames from 'classnames';
import { IoMdClose } from 'react-icons/io';
import { Link } from 'react-router-dom';

import Button from '../../../components/Button';
import { EbookDiscountCampaignStatus } from '../../../core/graphql/types';
import { getDayTime } from '../../../utils/dayjs';

export interface IPropsDiscountListItem {
  _id: string;
  createdDate?: string;
  discountDateTime?: { startedDate?: string; endedDate?: string };
  title: string;
  status: EbookDiscountCampaignStatus;
  onTerminate: () => void;
}

export const DISCOUNT_STATUS = {
  [EbookDiscountCampaignStatus.Pending]: 'In queue',
  [EbookDiscountCampaignStatus.Processing]: 'In progress',
  [EbookDiscountCampaignStatus.Complete]: 'Complete',
  [EbookDiscountCampaignStatus.Cancelled]: 'Terminated',
};

export const DISCOUNT_STATUS_CLASS_NAME = {
  [EbookDiscountCampaignStatus.Pending]: 'badge badge-outline',
  [EbookDiscountCampaignStatus.Processing]: 'badge badge-warning badge-outline',
  [EbookDiscountCampaignStatus.Complete]: 'badge badge-success badge-outline',
  [EbookDiscountCampaignStatus.Cancelled]: 'badge badge-error badge-outline',
};
const DATE_FORMAT_WITHOUT_TIME = 'DD-MM-YYYY';
const DATE_FORMAT = 'DD-MM-YYYY hh:mm A';

const DiscountListItem = ({ _id, createdDate, discountDateTime, title, status, onTerminate }: IPropsDiscountListItem) => {
  return (
    <tr className="hover">
      <td className="text-colorsBrandWarmBlack02LabelSecondary">{getDayTime(createdDate || '', DATE_FORMAT_WITHOUT_TIME)}</td>
      <td>
        {
          <div>
            <div className="flex gap-2 text-colorsBrandWarmBlack02LabelSecondary">
              <div className="w-[50px]">Start:</div>
              <span>{getDayTime(discountDateTime?.startedDate || '', DATE_FORMAT)}</span>
            </div>
            <div className="flex gap-2 text-colorsBrandWarmBlack02LabelSecondary">
              <div className="w-[50px]">End:</div>
              {discountDateTime?.endedDate ? <span>{getDayTime(discountDateTime?.endedDate || '', DATE_FORMAT)}</span> : <span>-</span>}
            </div>
          </div>
        }
      </td>
      <td>
        <Link to={`/discount/edit?id=${_id}`} className="underline text-colorsBrandRed01Primary">
          {title}
        </Link>
      </td>
      <td>
        <div className={classNames('gap-2', DISCOUNT_STATUS_CLASS_NAME[status])}>
          <span>{DISCOUNT_STATUS[status]}</span>
        </div>
      </td>
      <td>
        {[EbookDiscountCampaignStatus.Pending, EbookDiscountCampaignStatus.Processing].includes(status) && (
          <Button type="ghost" color="red" className="space-x-4 text-colorsBrandRed01Primary" onClick={onTerminate}>
            <IoMdClose />
            <span>terminate</span>
          </Button>
        )}
      </td>
    </tr>
  );
};

export default DiscountListItem;
