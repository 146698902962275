/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const iconDiscount = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g>
            <g transform="translate(-416 -419) translate(130 108) translate(274 299) translate(12 12)">
              <mask id="5naok5ctbb" fill="#fff">
                <path id="ozyovh9v0a" d="M0 0L16 0 16 16 0 16z" />
              </mask>
              <g fill="#FFB900" mask="url(#5naok5ctbb)">
                <path d="M10.272 11.217c.33 0 .598-.268.598-.598 0-.33-.268-.598-.598-.598-.33 0-.599.268-.599.598 0 .33.268.598.599.598M5.38 6.325c.33 0 .599-.267.599-.598 0-.33-.268-.596-.599-.596-.33 0-.597.266-.597.596 0 .33.268.598.597.598" />
                <path d="M10.272 12.413c-.991 0-1.793-.803-1.793-1.794 0-.99.802-1.793 1.793-1.793.99 0 1.794.803 1.794 1.793 0 .99-.803 1.794-1.794 1.794zm-4.735-1.038c-.138.13-.313.195-.489.195-.188 0-.377-.074-.517-.223-.27-.286-.257-.736.029-1.004l5.556-5.253c.285-.27.736-.257 1.006.029.27.286.256.736-.029 1.005l-5.556 5.251zm-1.95-5.648c0-.989.803-1.792 1.793-1.792.991 0 1.794.803 1.794 1.792 0 .991-.803 1.794-1.794 1.794-.99 0-1.793-.803-1.793-1.794zm11.83 1.662c-.09-.153-.194-.3-.315-.428-.268-.286-.544-.565-.83-.835-.31-.294-.458-.65-.451-1.076.003-.219.007-.438 0-.657-.007-.279-.006-.561-.043-.838-.086-.617-.398-1.075-1.018-1.264-.197-.06-.407-.097-.613-.106-.468-.022-.937-.017-1.405-.028-.298-.008-.552-.13-.762-.337-.227-.221-.445-.448-.668-.673-.37-.372-.77-.698-1.306-.8H7.61c-.582.134-1.003.513-1.4.929-.12.125-.247.243-.36.373-.333.386-.755.545-1.26.528-.36-.01-.724-.006-1.084.007-.172.005-.346.03-.511.073-.644.164-1.039.614-1.125 1.292-.036.276-.037.558-.045.838-.007.203-.002.406.004.61.014.46-.138.846-.483 1.157-.144.128-.277.269-.417.403-.414.397-.795.815-.928 1.4v.427c.138.6.535 1.026.96 1.432.104.098.202.203.311.296.41.348.59.79.558 1.325-.012.177-.012.356-.006.533.01.27.01.541.043.808.092.727.511 1.198 1.236 1.32.412.07.842.087 1.262.06.679-.043 1.213.17 1.654.695.242.29.53.554.832.782.647.491 1.323.487 1.967-.014.294-.229.58-.486.82-.774.439-.526.977-.736 1.656-.688.378.026.763.008 1.14-.035.691-.078 1.147-.484 1.304-1.13.042-.171.069-.35.073-.528.013-.366.016-.733.005-1.098-.011-.485.147-.887.512-1.207.139-.121.274-.247.395-.384.237-.265.49-.52.684-.815.332-.51.322-1.05.011-1.573z" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default iconDiscount;
