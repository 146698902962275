import classNames from 'classnames';
import { useState, ChangeEvent, useEffect } from 'react';

import css from './RangeSlider.module.scss';

interface IPropsRangeSlider {
  className?: string;
  initialValue?: number;
  sliderValue?: number;
  min?: number;
  max?: number;
  step?: number;
  onSliderChange?: (value: number) => void;
}

const defaultProps = {
  className: undefined,
  initialValue: 0,
  sliderValue: undefined,
  min: undefined,
  max: undefined,
  step: undefined,
  onSliderChange: undefined,
};

const RangeSlider = (props: IPropsRangeSlider) => {
  const { className, initialValue = 0, sliderValue, min, max, step, onSliderChange } = props;
  const [value, setValue] = useState<number>(initialValue);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const _value = Number(e.target.value);
    setValue(_value);
    onSliderChange?.(_value);
  };

  useEffect(() => {
    if (typeof sliderValue !== 'undefined' && sliderValue !== value) {
      setValue(sliderValue);
    }
  }, [sliderValue]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <input
      type="range"
      className={classNames([css.slider, className])}
      min={min}
      max={max}
      step={step}
      value={value}
      onChange={handleChange}
      style={{ backgroundSize: `${value}%` }}
    />
  );
};

RangeSlider.defaultProps = defaultProps;

export default RangeSlider;
