/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const heart = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path
      fill="#8C8C8C"
      fillRule="evenodd"
      d="M4.002 17.728A10.705 10.705 0 014 17.5C4 11.701 8.701 7 14.5 7c4.197 0 7.82 2.463 9.5 6.022A10.501 10.501 0 0133.5 7C39.299 7 44 11.701 44 17.5c0 .08 0 .159-.003.238.002.074.003.15.003.227C44 23.532 37.333 31.877 24 43 10.667 32.151 4 23.806 4 17.965c0-.08 0-.16.002-.237z"
    />
  </svg>
);

export default heart;
