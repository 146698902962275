import { useCallback } from 'react';

import { usePintoRemoveEbookBundleMutation } from '../core/graphql/operation-hooks';

const useMutationPintoRemoveEbookBundle = () => {
  const [mutatePintoRemoveEbookBundle, { loading }] = usePintoRemoveEbookBundleMutation({ errorPolicy: 'all' });

  const onMutatePintoRemoveEbookBundle = useCallback(
    async (bundleId: string, userId: string) => {
      return mutatePintoRemoveEbookBundle({ variables: { input: { id: bundleId, userId } } });
    },
    [mutatePintoRemoveEbookBundle],
  );

  return {
    onMutatePintoRemoveEbookBundle,
    isLoadingMutatePintoRemoveEbook: loading,
  };
};

export default useMutationPintoRemoveEbookBundle;
