import { useCallback, useState } from 'react';

function useEbookFiles() {
  const [selectedMainPdfFile, setSelectedMainPdfFile] = useState<File | null | undefined>(null);
  const [selectedSamplePdfFile, setSelectedSamplePdfFile] = useState<File | null | undefined>(null);
  const [selectedMainEpubFile, setSelectedMainEpubFile] = useState<File | null | undefined>(null);
  const [selectedSampleEpubFile, setSelectedSampleEpubFile] = useState<File | null | undefined>(null);

  const onSelectMainPdfFile = useCallback(async (file: File | undefined) => {
    setSelectedMainPdfFile(file);
  }, []);

  const onSelectSamplePdfFile = useCallback(async (file: File | undefined) => {
    setSelectedSamplePdfFile(file);
  }, []);

  const onSelectMainEpubFile = useCallback(async (file: File | undefined) => {
    setSelectedMainEpubFile(file);
  }, []);

  const onSelectSampleEpubFile = useCallback(async (file: File | undefined) => {
    setSelectedSampleEpubFile(file);
  }, []);

  return {
    selectedMainPdfFile,
    onSelectMainPdfFile,
    selectedSamplePdfFile,
    onSelectSamplePdfFile,
    selectedMainEpubFile,
    onSelectMainEpubFile,
    selectedSampleEpubFile,
    onSelectSampleEpubFile,
  };
}

export default useEbookFiles;
