/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const iOs = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path
      fill="#8C8C8C"
      fillRule="evenodd"
      d="M39.904 14.343c-2.452-3.737-6.273-4.25-7.635-4.309-3.251-.342-6.344 1.994-7.995 1.994-1.646 0-4.192-1.943-6.889-1.892-3.544.055-6.812 2.146-8.637 5.451-3.682 6.654-.943 16.512 2.646 21.908 1.754 2.641 3.845 5.608 6.59 5.502 2.645-.11 3.645-1.781 6.841-1.781 3.197 0 4.095 1.781 6.894 1.727 2.845-.055 4.647-2.692 6.389-5.342 2.014-3.064 2.843-6.031 2.892-6.184-.063-.03-5.548-2.218-5.603-8.795-.053-5.507 4.312-8.148 4.507-8.279zM31.958 0c-1.984.089-4.39 1.45-5.813 3.28-1.277 1.624-2.396 4.215-2.096 6.703 2.215.19 4.476-1.235 5.856-3.065 1.377-1.835 2.307-4.38 2.053-6.918z"
    />
  </svg>
);

export default iOs;
