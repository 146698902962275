import { slice } from 'lodash';
import { useCallback, useRef, useState } from 'react';

import { AllCategoriesQuery, ContentType, ExploreBookSortBy, ExploreEbookSortBy, ProductType, ScheduleInput } from '../../../../core/graphql/types';
import Icon from '../../../../fictionlog/components/Icon';
import useMutaionUploadStaticFile from '../../../../hooks/useMutaionUploadStaticFile';
import { getFictionlogExploreUrl } from '../../../../utils/router';
// import { getFictionlogUrl } from '../../../../utils/router';
import { IPopupItemInput } from '../../hooks/useFormPopupItems';

import ScheduleSelector from './ScheduleSelector';

interface IPropsExploreItemPopupForm {
  popupIndex: number;
  item: IPopupItemInput;
  allCategories?: AllCategoriesQuery;
  onUpdatePopupItem: (popupIndex: number, item: Partial<IPopupItemInput>) => void;
  onUpdatePopupConfig: (popupIndex: number, config: Pick<IPopupItemInput, 'configFeed' | 'configExploreBook' | 'configExploreEbook' | 'configSearch'>) => void;
  onDeletePopupItem: (popupIndex: number) => void;
  onPreviewPopupItem?: (popupIndex: number) => void;
}

const ExploreItemPopupForm = (props: IPropsExploreItemPopupForm) => {
  const { popupIndex, allCategories, item, onUpdatePopupItem, onUpdatePopupConfig, onPreviewPopupItem, onDeletePopupItem } = props;

  const { note, schedules } = item;

  const { mutateUpdateStaticFile } = useMutaionUploadStaticFile();

  const uploadImageRef = useRef<HTMLInputElement | null>(null);
  const [imageUploaded, setImageUploaded] = useState<string | ArrayBuffer | undefined | null>(item.popupImageUrl);
  const [isLimit, setIsLimit] = useState<boolean>(false);

  const onChangeAdminNote = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const note = e.target.value;

      const updatedItem = {
        note,
      };

      onUpdatePopupItem(popupIndex, updatedItem);
    },
    [onUpdatePopupItem, popupIndex],
  );

  const onClickUpload = useCallback(() => {
    if (!uploadImageRef?.current) return;
    uploadImageRef.current.click();
  }, []);

  const onUploaded = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files?.length) {
        const blob = e.target.files[0];

        if (blob.size > 5242880) {
          setIsLimit(true);
          setImageUploaded(undefined);
          return;
        }

        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = async () => {
          const base64 = reader.result as string;

          const data = await mutateUpdateStaticFile({ variables: { data: base64 } });
          const popupImageUrl = data.data?.uploadStaticFile?.url || undefined;

          const updatedItem = {
            popupImageUrl,
          };

          setImageUploaded(base64);
          onUpdatePopupItem(popupIndex, updatedItem);
        };
        setIsLimit(false);
      }
    },
    [mutateUpdateStaticFile, onUpdatePopupItem, popupIndex],
  );

  const onRemovePopupImage = useCallback(() => {
    const popupImageUrl = undefined;

    const updatedItem = {
      popupImageUrl,
    };

    setImageUploaded(popupImageUrl);
    onUpdatePopupItem(popupIndex, updatedItem);
  }, [onUpdatePopupItem, popupIndex]);

  const onCreateScheduleInSection = useCallback(
    (editedSchedule: ScheduleInput) => {
      const newSchedules = schedules?.length ? [...schedules, editedSchedule] : [editedSchedule];
      const updatedItem = {
        schedules: newSchedules,
      };

      onUpdatePopupItem(popupIndex, updatedItem);
    },
    [onUpdatePopupItem, popupIndex, schedules],
  );

  const onUpdateScheduleInSection = useCallback(
    (scheduleIndex: number, editedSchedule: ScheduleInput) => {
      if (!schedules?.length) return;
      // first arrays
      const beginArray = slice(schedules, 0, scheduleIndex);
      //last arrays
      const lastArray = slice(schedules, scheduleIndex + 1, schedules.length);
      const newSchedules = [...beginArray, editedSchedule, ...lastArray];

      const updatedItem = {
        schedules: newSchedules,
      };

      onUpdatePopupItem(popupIndex, updatedItem);
    },
    [onUpdatePopupItem, popupIndex, schedules],
  );

  const onDeleteScheduleInSection = useCallback(
    (scheduleIndex: number) => {
      if (!schedules?.length) return;

      const newSchedules = schedules.filter((e, index) => index !== scheduleIndex);

      const updatedItem = {
        schedules: newSchedules,
      };

      onUpdatePopupItem(popupIndex, updatedItem);
    },
    [onUpdatePopupItem, popupIndex, schedules],
  );

  const getConfigValue = useCallback((value: string) => {
    if (value === '') return undefined;
    return value;
  }, []);

  const getConfigRatingValue = useCallback((value: string) => {
    if (value === '') return undefined;
    return [value];
  }, []);

  const productType = item.configExploreBook ? ProductType.Book : ProductType.Ebook;

  return (
    <div className="p-16 m-8 space-y-16">
      <div className="flex justify-between">
        <div className="flex items-center space-x-8">
          <h3 className="font-bold text-20">Explore {productType}</h3>
        </div>
        <div>
          <div className="dropdown dropdown-end">
            <button tabIndex={-1} className="m-1 btn">
              ⋮
            </button>
            <ul tabIndex={-1} className="p-2 shadow menu dropdown-content bg-base-100 rounded-box w-52">
              {onPreviewPopupItem && (
                <li>
                  <a onClick={() => onPreviewPopupItem?.(popupIndex)}>Preview</a>
                </li>
              )}
              <li>
                <a onClick={() => onDeletePopupItem(popupIndex)}>Delete</a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="form-control">
        <label className="label" htmlFor="ExploreItemPopupForm-admin-note">
          <span className="font-bold label-text">Admin Note:</span>
        </label>
        <textarea className="h-24 textarea textarea-bordered" placeholder="Admin Note" id="ExploreItemPopupForm-admin-note" onChange={onChangeAdminNote} value={note}></textarea>

        {/* Book */}
        {item.configExploreBook && (
          <>
            <div className="flex space-x-8">
              <div className="flex flex-col flex-1 py-4">
                <label className="label" htmlFor="ExploreItemPopupForm-book-categoryTagId">
                  <span className="font-bold label-text">CategoryTagId:</span>
                </label>
                <select
                  id="ExploreItemPopupForm-book-categoryTagId"
                  className="select select-bordered select-sm"
                  value={item.configExploreBook?.categoryTagId ?? ''}
                  onChange={(e) => onUpdatePopupConfig(popupIndex, { configExploreBook: { categoryTagId: getConfigValue(e.target.value) } })}
                >
                  <option value="">-</option>
                  {allCategories?.fictionCategories?.map((category) => (
                    <option key={category?._id} value={category?._id ?? ''}>
                      {category?.name ?? ''}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex flex-col flex-1 py-4">
                <label className="label" htmlFor="ExploreItemPopupForm-book-contentRatingIds">
                  <span className="font-bold label-text">ContentRatingId:</span>
                </label>
                <select
                  id="ExploreItemPopupForm-book-contentRatingId"
                  className="select select-bordered select-sm"
                  value={item.configExploreBook?.contentRatingIds?.[0] ?? ''}
                  onChange={(e) => onUpdatePopupConfig(popupIndex, { configExploreBook: { contentRatingIds: getConfigRatingValue(e.target.value) as string[] } })}
                >
                  <option value="">-</option>
                  {allCategories?.fictionContentRatings?.map((contentRating) => (
                    <option key={contentRating?._id} value={contentRating?._id ?? ''}>
                      {contentRating?.name ?? ''}
                    </option>
                  ))}
                </select>
              </div>

              <div className="flex flex-col flex-1 py-4">
                <label className="label" htmlFor="ExploreItemPopupForm-book-SortBy">
                  <span className="font-bold label-text">SortBy:</span>
                </label>
                <select
                  id="ExploreItemPopupForm-book-SortBy"
                  className="select select-bordered select-sm"
                  value={item.configExploreBook.sortBy ?? ''}
                  onChange={(e) => onUpdatePopupConfig(popupIndex, { configExploreBook: { sortBy: getConfigValue(e.target.value) as ExploreBookSortBy } })}
                >
                  <option value="" disabled>
                    -
                  </option>
                  <option value={ExploreBookSortBy.LatestChapterPublished}>{ExploreBookSortBy.LatestChapterPublished}</option>
                  <option value={ExploreBookSortBy.Popular}>{ExploreBookSortBy.Popular}</option>
                  <option value={ExploreBookSortBy.Popular30Days}>{ExploreBookSortBy.Popular30Days}</option>
                  <option value={ExploreBookSortBy.Popular7Days}>{ExploreBookSortBy.Popular7Days}</option>
                  <option value={ExploreBookSortBy.Trending}>{ExploreBookSortBy.Trending}</option>
                </select>
              </div>
            </div>

            <div className="flex py-20 space-x-32">
              <div
                className="flex flex-row items-center space-x-8"
                onClick={() => onUpdatePopupConfig(popupIndex, { configExploreBook: { completed: !item.configExploreBook?.completed } })}
              >
                <input type="checkbox" checked={!!item.configExploreBook.completed} defaultChecked={!!item.configExploreBook.completed} className="checkbox checkbox-xs" />
                <label>completed</label>
              </div>
              <div
                className="flex flex-row items-center space-x-8"
                onClick={() => onUpdatePopupConfig(popupIndex, { configExploreBook: { isFree: !item.configExploreBook?.isFree } })}
              >
                <input type="checkbox" checked={!!item.configExploreBook.isFree} defaultChecked={!!item.configExploreBook.isFree} className="checkbox checkbox-xs" />
                <label>isFree</label>
              </div>
            </div>
          </>
        )}

        {/* Ebook */}
        {item.configExploreEbook && (
          <>
            <div className="flex space-x-8">
              <div className="flex flex-col flex-1 py-4">
                <label className="label" htmlFor="ExploreItemPopupForm-ebook-contentType">
                  <span className="font-bold label-text">ContentType:</span>
                </label>
                <select
                  id="ExploreItemPopupForm-ebook-contentType"
                  className="select select-bordered select-sm"
                  value={item.configExploreEbook?.contentType ?? ''}
                  onChange={(e) =>
                    onUpdatePopupConfig(popupIndex, {
                      configExploreEbook: { contentType: getConfigValue(e.target.value) as ContentType, categoryTagId: undefined, contentRatingIds: undefined },
                    })
                  }
                >
                  <option value="" disabled>
                    -
                  </option>
                  <option value={ContentType.Comic}>{ContentType.Comic}</option>
                  <option value={ContentType.Fiction}>{ContentType.Fiction}</option>
                  <option value={ContentType.Nonfiction}>{ContentType.Nonfiction}</option>
                </select>
              </div>

              <div className="flex flex-col flex-1 py-4">
                <label className="label" htmlFor="ExploreItemPopupForm-ebook-categoryTagId">
                  <span className="font-bold label-text">CategoryTagId:</span>
                </label>
                <select
                  id="ExploreItemPopupForm-ebook-categoryTagId"
                  className="select select-bordered select-sm"
                  value={item.configExploreEbook?.categoryTagId ?? ''}
                  onChange={(e) => onUpdatePopupConfig(popupIndex, { configExploreEbook: { categoryTagId: getConfigValue(e.target.value) } })}
                >
                  <option value="">-</option>
                  {item.configExploreEbook.contentType === ContentType.Fiction &&
                    allCategories?.fictionCategories?.map((category) => (
                      <option key={category?._id} value={category?._id ?? ''}>
                        {category?.name ?? ''}
                      </option>
                    ))}
                  {item.configExploreEbook.contentType === ContentType.Comic &&
                    allCategories?.comicCategories?.map((category) => (
                      <option key={category?._id} value={category?._id ?? ''}>
                        {category?.name ?? ''}
                      </option>
                    ))}
                  {item.configExploreEbook.contentType === ContentType.Nonfiction &&
                    allCategories?.nonfictionCategories?.map((category) => (
                      <option key={category?._id} value={category?._id ?? ''}>
                        {category?.name ?? ''}
                      </option>
                    ))}
                </select>
              </div>
              <div className="flex flex-col flex-1 py-4">
                <label className="label" htmlFor="ExploreItemPopupForm-ebook-contentRatingIds">
                  <span className="font-bold label-text">ContentRatingId:</span>
                </label>
                <select
                  id="ExploreItemPopupForm-ebook-contentRatingId"
                  className="select select-bordered select-sm"
                  value={item.configExploreEbook?.contentRatingIds?.[0] ?? ''}
                  onChange={(e) => onUpdatePopupConfig(popupIndex, { configExploreEbook: { contentRatingIds: getConfigRatingValue(e.target.value) as string[] } })}
                >
                  <option value="">-</option>
                  {item.configExploreEbook.contentType === ContentType.Fiction &&
                    allCategories?.fictionContentRatings?.map((contentRating) => (
                      <option key={contentRating?._id} value={contentRating?._id ?? ''}>
                        {contentRating?.name ?? ''}
                      </option>
                    ))}
                  {item.configExploreEbook.contentType === ContentType.Comic &&
                    allCategories?.comicContentRatings?.map((contentRating) => (
                      <option key={contentRating?._id} value={contentRating?._id ?? ''}>
                        {contentRating?.name ?? ''}
                      </option>
                    ))}
                  {item.configExploreEbook.contentType === ContentType.Nonfiction &&
                    allCategories?.nonfictionContentRatings?.map((contentRating) => (
                      <option key={contentRating?._id} value={contentRating?._id ?? ''}>
                        {contentRating?.name ?? ''}
                      </option>
                    ))}
                </select>
              </div>
            </div>

            <div className="flex space-x-8">
              <div className="flex flex-col flex-1 py-4">
                <label className="label" htmlFor="ExploreItemPopupForm-ebook-SortBy">
                  <span className="font-bold label-text">SortBy:</span>
                </label>
                <select
                  id="ExploreItemPopupForm-ebook-SortBy"
                  className="select select-bordered select-sm"
                  value={item.configExploreEbook.sortBy ?? ''}
                  onChange={(e) => onUpdatePopupConfig(popupIndex, { configExploreEbook: { sortBy: getConfigValue(e.target.value) as ExploreEbookSortBy } })}
                >
                  <option value="" disabled>
                    -
                  </option>
                  <option value={ExploreEbookSortBy.Published}>{ExploreEbookSortBy.Published}</option>
                  <option value={ExploreEbookSortBy.Popular}>{ExploreEbookSortBy.Popular}</option>
                  <option value={ExploreEbookSortBy.Popular30Days}>{ExploreEbookSortBy.Popular30Days}</option>
                  <option value={ExploreEbookSortBy.Popular7Days}>{ExploreEbookSortBy.Popular7Days}</option>
                  <option value={ExploreEbookSortBy.Trending}>{ExploreEbookSortBy.Trending}</option>
                </select>
              </div>
            </div>

            <div className="flex py-20 space-x-32">
              <div
                className="flex flex-row items-center space-x-8"
                onClick={() => onUpdatePopupConfig(popupIndex, { configExploreEbook: { isFree: !item.configExploreEbook?.isFree } })}
              >
                <input type="checkbox" checked={!!item.configExploreEbook.isFree} defaultChecked={!!item.configExploreEbook.isFree} className="checkbox checkbox-xs" />
                <label>isFree</label>
              </div>
            </div>
          </>
        )}

        <label className="label" htmlFor="ExploreItemPopupForm-admin-note">
          <span className="font-bold label-text">url:</span>
        </label>
        <div className="flex">
          <input
            type="text"
            placeholder="EBook Id"
            className="flex-1 input input-bordered"
            value={getFictionlogExploreUrl({ ...(item.configExploreBook || item.configExploreEbook), exploreType: productType })}
            disabled
          />
          <a href={getFictionlogExploreUrl({ ...(item.configExploreBook || item.configExploreEbook), exploreType: productType })} target="_blank" rel="noreferrer">
            <button className="btn">
              <Icon name="view" size="24px" />
            </button>
          </a>
        </div>
      </div>
      <div className="font-bold label-text">Popup Image:</div>
      <div className="flex space-x-24">
        <input ref={uploadImageRef} type="file" accept="image/*,audio/*,video/*" onChange={onUploaded} className="hidden opacity-0" />
        {imageUploaded && (
          <div className="relative w-[180px]">
            <img src={imageUploaded.toString()} alt="popupImage" width="180" className="relative" />
          </div>
        )}
        {isLimit && <div className="text-colorsBrandRed01Primary">รูปขนาด เกิน 5 MB ไม่สามารถอัปโหลดได้</div>}

        <div className="space-y-24">
          {imageUploaded && (
            <button onClick={onRemovePopupImage} className="w-[180px] btn btn-outline btn-sm">
              Remove Image
            </button>
          )}
          <button onClick={onClickUpload} className="w-[180px] btn btn-outline btn-sm">
            {imageUploaded ? 'Change Image' : 'Select Image'}
          </button>
        </div>
      </div>
      <ScheduleSelector
        schedules={schedules?.length ? schedules : []}
        onCreateScheduleInSection={onCreateScheduleInSection}
        onUpdateScheduleInSection={onUpdateScheduleInSection}
        onDeleteScheduleInSection={onDeleteScheduleInSection}
      />
    </div>
  );
};

export default ExploreItemPopupForm;
