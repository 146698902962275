import classNames from 'classnames';
import React from 'react';

import css from './Icon.module.scss';
import IIcons from './icons/IIcons';
import icons from './icons/index';

export type IconNames = IIcons;

export interface IIconProps {
  name: IconNames;
  color?: string;
  size?: string;
  className?: string;
  style?: React.CSSProperties;
}

const defaultProps: IIconProps = {
  name: 'warningFill',
};

const Icon = (props: IIconProps): JSX.Element => {
  if (!icons[props.name]) {
    return (
      <div
        className={classNames(`${css.iconWrapper} ${props.className ?? ''}`, {
          [css.svg]: props.color && props.size,
        })}
        // use props inlinestyle to pass to css variable module
        style={{ ...props.style, '--iconSize': props.size || '1em', '--fillColor': props.color || 'inherit' } as React.CSSProperties}
      >
        <div
          style={{
            background: 'red',
            height: props.size,
            width: props.size,
          }}
        />
      </div>
    );
  }
  const Svg = icons[props.name];
  return (
    <div
      className={classNames(`${css.iconWrapper} ${props.className ?? ''}`, {
        [css.svg]: props.color && props.size,
      })}
      // use props inlinestyle to pass to css variable module
      style={
        {
          ...props.style,
          '--iconSize': props.size || '1em',
          '--fillColor': props.color || 'inherit',
        } as React.CSSProperties
      }
    >
      <Svg />
    </div>
  );
};

Icon.defaultProps = defaultProps;

export default Icon;
