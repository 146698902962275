import Card from '../../../components/Card';
import RadioCardButton from '../../../components/RadioCardButton';
import RadioCardButtonGroup from '../../../components/RadioCardButtonGroup';
import Typography, { TYPOGRAPHY_TYPES } from '../../../components/Typography';
import UploadFileBox from '../../../components/UploadFileBox';
import { ReadingType } from '../../../core/graphql/types';

import { READING_TYPE_ITEMS } from './EditEbookFilesPresenter';

interface IPropsEbookFilesPresenter {
  readOnly?: boolean;
  // main pdf
  selectedMainPdfFile: File | undefined;
  mainPdfFileName?: string;
  mainPdfFileUrl?: string;
  onSelectMainPdfFile: (file: File | undefined) => void;
  // sample pdf
  selectedSamplePdfFile: File | undefined;
  samplePdfFileName?: string;
  samplePdfFileUrl?: string;
  onSelectSamplePdfFile: (file: File | undefined) => void;
  // main epub
  selectedMainEpubFile: File | undefined;
  mainEpubFileName?: string;
  mainEpubFileUrl?: string;
  onSelectMainEpubFile: (file: File | undefined) => void;
  // sample epub
  selectedSampleEpubFile: File | undefined;
  sampleEpubFileName?: string;
  sampleEpubFileUrl?: string;
  onSelectSampleEpubFile: (file: File | undefined) => void;

  readingType: ReadingType;
}

function EbookFilesPresenter(props: IPropsEbookFilesPresenter) {
  const {
    readOnly,
    selectedMainPdfFile,
    mainPdfFileName,
    mainPdfFileUrl,
    onSelectMainPdfFile,
    selectedSamplePdfFile,
    samplePdfFileName,
    samplePdfFileUrl,
    onSelectSamplePdfFile,
    readingType,
  } = props;
  const { selectedMainEpubFile, mainEpubFileName, mainEpubFileUrl, onSelectMainEpubFile, selectedSampleEpubFile, sampleEpubFileName, sampleEpubFileUrl, onSelectSampleEpubFile } =
    props;
  return (
    <Card className="space-y-4 flex flex-col">
      <div className="text-22">
        ไฟล์อีบุ๊ก<span className="text-colorsBrandRed01Primary">*</span>
      </div>
      <div className="label-text-alt text-systemGrays02LabelSecondary font-tt">*รองรับไฟล์ PDF และ EPUB เท่านั้น และไฟล์มีขนาดไม่เกิน 100 MB</div>
      <div className="label-text-alt text-systemGrays02LabelSecondary font-tt">*กรุณาเลือกอัปโหลดไฟล์อย่างน้อยหนึ่งประเภทหรือทั้ง 2 ไฟล์</div>
      <div className="flex flex-col w-full pt-24">
        <div>ไฟล์ PDF</div>
        <div className="flex-1">
          <UploadFileBox
            label="Main"
            acceptFileExtension=".pdf"
            onSelectFile={onSelectMainPdfFile}
            fileName={mainPdfFileName ?? selectedMainPdfFile?.name}
            fileUrl={mainPdfFileUrl}
            readOnly={readOnly}
          />
        </div>
        <div className="flex-1">
          <UploadFileBox
            label="Sample"
            acceptFileExtension=".pdf"
            onSelectFile={onSelectSamplePdfFile}
            fileName={samplePdfFileName ?? selectedSamplePdfFile?.name}
            fileUrl={samplePdfFileUrl}
            readOnly={readOnly}
          />
        </div>
      </div>

      {/* section: reading type */}
      <section className="flex flex-col pt-24">
        <div>รูปแบบการอ่านอีบุ๊ก</div>
        <RadioCardButtonGroup className="mt-4 md:flex md:space-x-24">
          {READING_TYPE_ITEMS.map((readingTypeItem) => (
            <RadioCardButton
              className="!mt-16 first:mt-16 md:!mt-0 md:first:mt-0"
              radioButtonClassname="self-start !mt-4 !ml-0"
              labelClassName="!px-16 !py-12 !hover:cursor-default !cursor-default"
              key={readingTypeItem.value}
              value={readingTypeItem.value}
              title={readingTypeItem.title}
              description={readingTypeItem.description}
              checked={readingTypeItem.value === readingType}
              onSelected={undefined}
              disabled
            />
          ))}
        </RadioCardButtonGroup>
        <Typography typography={TYPOGRAPHY_TYPES.CAPTION_1_TT} className="text-colorsBrandWarmBlack02LabelSecondary mt-4">
          คำเตือน : หากคุณเลือกอ่านแบบเว็บตูน นักอ่านจะไม่สามารถปรับรูปแบบการอ่านเป็นซ้ายไปขวา หรือขวาไปซ้ายได้
        </Typography>
      </section>

      <div className="flex flex-col w-full pt-24">
        <div>ไฟล์ EPUB</div>
        <div className="flex-1">
          <UploadFileBox
            label="Main"
            acceptFileExtension=".epub"
            onSelectFile={onSelectMainEpubFile}
            fileName={mainEpubFileName ?? selectedMainEpubFile?.name}
            fileUrl={mainEpubFileUrl}
            readOnly={readOnly}
          />
        </div>
        <div className="flex-1">
          <UploadFileBox
            label="Sample"
            acceptFileExtension=".epub"
            onSelectFile={onSelectSampleEpubFile}
            fileName={sampleEpubFileName ?? selectedSampleEpubFile?.name}
            fileUrl={sampleEpubFileUrl}
            readOnly={readOnly}
          />
        </div>
      </div>
    </Card>
  );
}

export default EbookFilesPresenter;
