import { useCallback } from 'react';

import { usePintoFeedLazyQuery } from '../core/graphql/operation-hooks';
import { Preference } from '../core/graphql/types';

const useQueryPintoFeed = () => {
  const [queryPintoFeed, { data: dataPintoFeed, loading: isLoadingPintoFeed }] = usePintoFeedLazyQuery();

  const onQueryPintoFeed = useCallback(
    async (slug: string) => {
      queryPintoFeed({ variables: { slug, pref: Preference.Default } });
    },
    [queryPintoFeed],
  );
  return {
    onQueryPintoFeed,
    dataPintoFeed,
    isLoadingPintoFeed,
  };
};

export default useQueryPintoFeed;
