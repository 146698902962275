import classNames from 'classnames';
import { isNull } from 'lodash';
import { FieldValues, UseFormRegister } from 'react-hook-form';

export type IInputValue = string | number | boolean | null | undefined;

export interface IPropsCheckBoxGroup {
  name: string;
  options?: { label: string; value?: IInputValue | IInputValue[] }[];
  label?: string;
  className?: string;
  required?: boolean;
  helperText?: string;
  isInvalid?: boolean;
  register?: UseFormRegister<FieldValues>;
}

const CheckBoxGroup = (props: IPropsCheckBoxGroup) => {
  const { options, label, name, required, className, helperText, isInvalid, register } = props;

  return (
    <div className={classNames('form-control', className)}>
      {label && (
        <span className="label-text text-systemGrays02LabelSecondary">
          {label} {required && <span className="text-colorsBrandRed01Primary">*</span>}
        </span>
      )}

      <div className="flex space-x-12">
        {options?.map((option) => (
          <div className="form-control" key={option.label}>
            <label className="space-x-8 cursor-pointer label">
              <input type="checkbox" className="checkbox" {...register?.(name)} value={(isNull(option?.value) ? undefined : option?.value) as string | number | undefined} />
              <span className="label-text">{option.label}</span>
            </label>
          </div>
        ))}
      </div>

      {helperText && (
        <label className="label">
          <span
            className={classNames('label-text-alt text-systemGrays02LabelSecondary font-tt', {
              '!text-colorsBrandRed01Primary': isInvalid,
            })}
          >
            {helperText}
          </span>
        </label>
      )}
    </div>
  );
};

export default CheckBoxGroup;
