import { useCallback } from 'react';

import { usePintoUploadEbookImageMutation } from '../core/graphql/operation-hooks';
import { UploadEbookImageInput } from '../core/graphql/types';

const useMutationPintoUploadImage = () => {
  const [mutatePintoUploadImage, { loading }] = usePintoUploadEbookImageMutation();

  const onMutatePintoUploadImage = useCallback(
    async (input: UploadEbookImageInput) => {
      const res = await mutatePintoUploadImage({ variables: { input } });
      return res;
    },
    [mutatePintoUploadImage],
  );

  return {
    onMutatePintoUploadImage,
    isLoadingMutatePintoUploadImage: loading,
  };
};

export default useMutationPintoUploadImage;
