import { useCallback } from 'react';

import { usePendingEbookInSubscriptionTitleLazyQuery } from '../core/graphql/operation-hooks';

const useQueryPendingEbookInSubscriptionTitle = () => {
  const [queryPendingEbookInSubscriptionTitle, { data, loading }] = usePendingEbookInSubscriptionTitleLazyQuery();

  const onFetchPendingEbookInSubscriptionTitle = useCallback(
    async (subscriptionTitleId: string) => {
      queryPendingEbookInSubscriptionTitle({ variables: { subscriptionTitleId } });
    },
    [queryPendingEbookInSubscriptionTitle],
  );
  return {
    onFetchPendingEbookInSubscriptionTitle,
    pendingEbookInSubscriptionTitleData: data?.pendingEbookInSubscriptionTitle,
    isLoadingPendingEbookInSubscriptionTitle: loading,
  };
};

export default useQueryPendingEbookInSubscriptionTitle;
