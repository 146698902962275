import React from 'react';

import Card from '../../../components/Card';
import { numberWithCommas } from '../../../utils/convertNumber';
import { getDayTime } from '../../../utils/dayjs';

const DATE_FORMAT = 'DD-MM-YYYY hh:mm A';

interface IPropsStatusDetailPresenter {
  viewCount?: number;
  createdAt?: string;
  createdBy?: string;
  publishedAt?: string;
  updatedAt?: string;
  updatedBy?: string;
}

function StatusDetailPresenter(props: IPropsStatusDetailPresenter) {
  const { viewCount, createdAt, createdBy, publishedAt, updatedAt, updatedBy } = props;

  return (
    <Card className="space-y-8 flex flex-col flex-1">
      <div className="text-22">Status</div>
      <div className="flex space-x-24">
        <div className="flex w-[200px] font-tt">View Count</div>
        <div>{numberWithCommas(viewCount || 0)}</div>
      </div>
      <div className="flex space-x-24">
        <div className="flex w-[200px] font-tt">Created At</div>
        <div>{createdAt ? getDayTime(createdAt, DATE_FORMAT) : '-'}</div>
      </div>
      <div className="flex space-x-24">
        <div className="flex w-[200px] font-tt">Created By</div>
        <div>{createdBy || '-'}</div>
      </div>
      <div className="flex space-x-24">
        <div className="flex w-[200px] font-tt">Published At</div>
        <div>{publishedAt ? getDayTime(publishedAt, DATE_FORMAT) : '-'}</div>
      </div>
      <div className="flex space-x-24">
        <div className="flex w-[200px] font-tt">Updated At</div>
        <div>{updatedAt ? getDayTime(updatedAt, DATE_FORMAT) : '-'}</div>
      </div>
      <div className="flex space-x-24">
        <div className="flex w-[200px] font-tt">Updated By</div>
        <div>{updatedBy || '-'}</div>
      </div>
    </Card>
  );
}

export default StatusDetailPresenter;
