import { Calendar, Event } from 'react-big-calendar';

import { localizer } from '../../../utils/dayjs';

export interface IPropsDiscountCalendar {
  events?: Event[];
}

function DiscountCalendar(props: IPropsDiscountCalendar) {
  const { events = [] } = props;

  return <Calendar selectable={false} localizer={localizer} events={events} startAccessor="start" endAccessor="end" style={{ height: 450 }} views={['month', 'agenda']} />;
}

export default DiscountCalendar;
