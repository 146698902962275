/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const bankAccount = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path
      fill="#8C8C8C"
      fillRule="evenodd"
      d="M41 22.437V37h2.002a1 1 0 011 1v5a1 1 0 01-1 1h-38a1 1 0 01-1-1v-5a1 1 0 011-1H7V22.437H3.007c-.551 0-.998-.45-1-1.007l-.005-2.04a1.015 1.015 0 01.434-.838L22.868 4.356a1.981 1.981 0 012.264 0l20.434 14.198c.272.189.434.5.434.834v2.038c0 .558-.448 1.011-1 1.011h-4zM14.002 22a1 1 0 00-1 1v12a1 1 0 001 1h2a1 1 0 001-1V23a1 1 0 00-1-1h-2zm9 0a1 1 0 00-1 1v12a1 1 0 001 1h2a1 1 0 001-1V23a1 1 0 00-1-1h-2zm9 0a1 1 0 00-1 1v12a1 1 0 001 1h2a1 1 0 001-1V23a1 1 0 00-1-1h-2z"
    />
  </svg>
);

export default bankAccount;
