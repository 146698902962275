import classNames from 'classnames';
import { useCallback, useMemo } from 'react';
import DatePicker from 'react-datepicker';
import { useFormContext } from 'react-hook-form';

import CommonInput from '../../../../components/form/CommonInput';
import DropdownSelect from '../../../../components/form/DropdownSelect';
import { addDate, isBeforeDate } from '../../../../utils/dayjs';
import { IDropdownPriceTierOption } from '../hook/useStatePriceTier';
import { DISCOUNT_OPTIONS, DISCOUNT_VALUE, HAS_DISCOUNT_END_DATE_OPTIONS } from '../SetPriceModal';
import { ISetPriceModalFields, SET_PRICE_MODAL_FIELD_KEYS } from '../validates/setPriceModalBuilderSchema';

export interface IPropsDiscountPricePresenter {
  className?: string;
  discountGooglePriceTierOptions?: IDropdownPriceTierOption[];
  discountApplePriceTierOptions?: IDropdownPriceTierOption[];
}

const DiscountPricePresenter = (props: IPropsDiscountPricePresenter) => {
  const { discountGooglePriceTierOptions, discountApplePriceTierOptions } = props;
  const {
    watch,
    setValue,
    formState: { errors },
    register,
  } = useFormContext();

  const [googlePriceTierId, applePriceTierId] = watch([SET_PRICE_MODAL_FIELD_KEYS.GOOGLE_PRICE_TIER_ID, SET_PRICE_MODAL_FIELD_KEYS.APPLE_PRICE_TIER_ID]);

  const [discountType, discountPrice, discountRatio, discountGooglePriceTierId, discountApplePriceTierId] = watch([
    SET_PRICE_MODAL_FIELD_KEYS.DISCOUNT_TYPE,
    SET_PRICE_MODAL_FIELD_KEYS.DISCOUNT_PRICE,
    SET_PRICE_MODAL_FIELD_KEYS.DISCOUNT_RATIO,
    SET_PRICE_MODAL_FIELD_KEYS.DISCOUNT_GOOGLE_PRICE_TIER_ID,
    SET_PRICE_MODAL_FIELD_KEYS.DISCOUNT_APPLE_PRICE_TIER_ID,
  ]);

  const [discountStartDate, discountEndDate, isHasDiscountEndDate] = watch([
    SET_PRICE_MODAL_FIELD_KEYS.DISCOUNT_START_DATE,
    SET_PRICE_MODAL_FIELD_KEYS.DISCOUNT_END_DATE,
    SET_PRICE_MODAL_FIELD_KEYS.IS_HAS_DISCOUNT_END_DATE,
  ]);

  const requiredDateRange = useMemo(() => {
    return discountType !== DISCOUNT_VALUE.NO_DISCOUNT || discountGooglePriceTierId || discountApplePriceTierId;
  }, [discountApplePriceTierId, discountGooglePriceTierId, discountType]);

  const onClickDiscountOption = useCallback(
    (value: DISCOUNT_VALUE) => {
      setValue(SET_PRICE_MODAL_FIELD_KEYS.DISCOUNT_TYPE as ISetPriceModalFields, value);
    },
    [setValue],
  );

  const onClickIsHasDiscountEndDateOption = useCallback(
    (value: boolean) => {
      setValue(SET_PRICE_MODAL_FIELD_KEYS.IS_HAS_DISCOUNT_END_DATE as ISetPriceModalFields, value);
      if (value && discountStartDate) {
        setValue(SET_PRICE_MODAL_FIELD_KEYS.DISCOUNT_END_DATE as ISetPriceModalFields, discountEndDate || addDate(discountStartDate, 3, 'day').toDate());
      } else {
        setValue(SET_PRICE_MODAL_FIELD_KEYS.DISCOUNT_END_DATE as ISetPriceModalFields, undefined);
      }
    },
    [discountEndDate, discountStartDate, setValue],
  );

  const onChangeDiscountPrice = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue(SET_PRICE_MODAL_FIELD_KEYS.DISCOUNT_PRICE as ISetPriceModalFields, event.target.value ?? undefined, { shouldValidate: true });
    },
    [setValue],
  );

  const onChangeDiscountRatio = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newRatio = event.target.value?.replace(/\D/g, '');
      setValue(SET_PRICE_MODAL_FIELD_KEYS.DISCOUNT_RATIO as ISetPriceModalFields, newRatio ?? undefined, { shouldValidate: true });
    },
    [setValue],
  );

  const onChangeDiscountStartDate = useCallback(
    (date: Date) => {
      setValue(SET_PRICE_MODAL_FIELD_KEYS.DISCOUNT_START_DATE as ISetPriceModalFields, date);
      // if isHasDiscountEndDate is true and new start date is after current end date, end date will be reset to undefined
      if (isHasDiscountEndDate && isBeforeDate(discountEndDate, date)) {
        setValue(SET_PRICE_MODAL_FIELD_KEYS.DISCOUNT_END_DATE as ISetPriceModalFields, undefined, { shouldValidate: true });
      }
    },
    [discountEndDate, isHasDiscountEndDate, setValue],
  );
  const onChangeDiscountEndDate = useCallback(
    (date: Date) => {
      setValue(SET_PRICE_MODAL_FIELD_KEYS.DISCOUNT_END_DATE as ISetPriceModalFields, date);
    },
    [setValue],
  );
  return (
    <div className="py-16">
      <label className="label">
        <span className="label-text text-systemGrays02LabelSecondary">ส่วนลดเว็บไซต์</span>
      </label>
      <div className="space-x-16">
        {DISCOUNT_OPTIONS.map((option) => (
          <button
            key={option.label}
            className={classNames('btn btn-outline border px-8', {
              'border-colorsBrandRed01Primary text-colorsBrandRed01Primary': discountType === option.value,
            })}
            onClick={() => onClickDiscountOption?.(option.value)}
          >
            {option.label}
          </button>
        ))}
      </div>
      {/* Select discount price Section */}
      <section className="py-12">
        {discountType === DISCOUNT_VALUE.SPECIFY_PRICE && (
          <CommonInput
            label="ราคาส่วนลด (บาท)"
            required
            placeholder="ราคาส่วนลด (บาท)"
            helperText={errors?.[SET_PRICE_MODAL_FIELD_KEYS.DISCOUNT_PRICE]?.message}
            isInvalid={!!errors?.[SET_PRICE_MODAL_FIELD_KEYS.DISCOUNT_PRICE]}
            onChange={onChangeDiscountPrice}
            value={discountPrice}
          />
        )}
        {discountType === DISCOUNT_VALUE.PERCENT_DISCOUNT && (
          <div>
            <CommonInput
              label="เปอร์เซ็นต์ส่วนลด (%)"
              required
              placeholder="เปอร์เซ็นต์ส่วนลด (%)"
              helperText={errors?.[SET_PRICE_MODAL_FIELD_KEYS.DISCOUNT_RATIO]?.message}
              isInvalid={!!errors?.[SET_PRICE_MODAL_FIELD_KEYS.DISCOUNT_RATIO]}
              value={discountRatio}
              onChange={onChangeDiscountRatio}
            />
          </div>
        )}
      </section>
      {/** app discount */}
      <section className="flex justify-between space-x-16">
        {/* ราคาขาย Google Play (บาท) */}
        <DropdownSelect
          className="w-1/2"
          selectClassName="w-full"
          options={discountGooglePriceTierOptions}
          label="ราคาโปรโมชั่นบน Google Play (บาท)"
          labelClassName="text-[13px]"
          name={SET_PRICE_MODAL_FIELD_KEYS.DISCOUNT_GOOGLE_PRICE_TIER_ID}
          register={register}
          helperText="ผู้ใช้จะเห็นเฉพาะราคาเงินบาทเท่านั้น"
          disabled={!googlePriceTierId}
        />
        {/* -- end -- ราคาขาย Google Play (บาท) -- end -- */}
        {/* ราคาขาย App Store (บาท) */}
        <DropdownSelect
          className="w-1/2"
          selectClassName="w-full"
          options={discountApplePriceTierOptions}
          label="ราคาโปรโมชั่นบน App Store (บาท)"
          labelClassName="text-[13px]"
          name={SET_PRICE_MODAL_FIELD_KEYS.DISCOUNT_APPLE_PRICE_TIER_ID}
          register={register}
          helperText="ผู้ใช้จะเห็นเฉพาะราคาเงินบาทเท่านั้น"
          disabled={!applePriceTierId}
        />
        {/* -- end -- ราคาขาย App Store (บาท) -- end -- */}
      </section>
      {/* Discount Duration Section */}
      {requiredDateRange && (
        <section>
          <label className="label">
            <span className="label-text text-systemGrays02LabelSecondary">
              ระยะเวลาลดราคา <span className="text-colorsBrandRed01Primary">*</span>
            </span>
          </label>
          <div className="space-x-16">
            {HAS_DISCOUNT_END_DATE_OPTIONS.map((option) => (
              <button
                key={option.label}
                className={classNames('btn btn-outline border px-8', {
                  'border-colorsBrandRed01Primary text-colorsBrandRed01Primary': isHasDiscountEndDate === option.value,
                })}
                onClick={() => onClickIsHasDiscountEndDateOption?.(option.value)}
              >
                {option.label}
              </button>
            ))}
          </div>
          <div className="flex">
            <div className="flex flex-col flex-1">
              <label className="label label-text text-systemGrays02LabelSecondary">
                <span className="label-text text-systemGrays02LabelSecondary">
                  วันที่เริ่ม <span className="text-colorsBrandRed01Primary">*</span>
                </span>
              </label>
              <DatePicker
                className={classNames('input input-bordered w-full font-tt text-systemGrays02LabelSecondary font-semibold', {
                  '!input-error': false,
                })}
                required
                calendarClassName="select-none"
                dateFormat="d MMM yyyy"
                selectsStart
                selected={discountStartDate}
                startDate={discountStartDate}
                endDate={isHasDiscountEndDate ? discountEndDate : undefined}
                minDate={new Date()}
                placeholderText="วันที่เริ่ม"
                onChange={(start: Date) => {
                  onChangeDiscountStartDate(start);
                }}
              />
            </div>
            {isHasDiscountEndDate && <div className="px-4 flex justify-center items-end mb-[6px]">-</div>}

            {isHasDiscountEndDate && (
              <div className="flex flex-col flex-1">
                <label className="label label-text text-systemGrays02LabelSecondary">
                  <span className="label-text text-systemGrays02LabelSecondary">
                    วันที่สิ้นสุด <span className="text-colorsBrandRed01Primary">*</span>
                  </span>
                </label>
                <DatePicker
                  className={classNames('input input-bordered w-full font-tt text-systemGrays02LabelSecondary font-semibold', {
                    '!input-error': false,
                  })}
                  dateFormat="d MMM yyyy"
                  selectsStart
                  selected={discountEndDate}
                  startDate={discountStartDate}
                  endDate={discountEndDate}
                  minDate={discountStartDate || new Date()}
                  placeholderText="วันที่สิ้นสุด"
                  onChange={(end: Date) => {
                    onChangeDiscountEndDate(end);
                  }}
                />
              </div>
            )}
          </div>
        </section>
      )}
    </div>
  );
};

export default DiscountPricePresenter;
