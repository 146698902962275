/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const caretLeft = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path
      fill="#8C8C8C"
      fillRule="evenodd"
      d="M29.46 4a4.541 4.541 0 014.541 4.541v.126L19.082 24l14.919 15.332v.127A4.541 4.541 0 0129.46 44L14.542 28.666 10 24l4.542-4.667L29.46 4z"
    />
  </svg>
);

export default caretLeft;
