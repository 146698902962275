import { identity, isEmpty, pickBy } from 'lodash';
import qs from 'qs';
import { useLocation } from 'react-router-dom';

import { Explore, ProductType } from '../core/graphql/types';

export const useQueryString = () => new URLSearchParams(useLocation().search);

export const gotoLegacyAdmin = (path: string) => {
  window.open(`${process.env.REACT_APP_LEGACY_ADMIN_URL}${path}`);
};

export const getFictionlogUrl = (path: string) => {
  return `${process.env.REACT_APP_FICTIONLOG_URL}${path}`;
};

export const getFictionlogExploreUrl = ({
  exploreType,
  contentType,
  completed,
  isFree,
  categoryTagId,
  sortBy,
  contentRatingIds,
}: Pick<Explore, 'contentType' | 'completed' | 'isFree' | 'categoryTagId' | 'sortBy' | 'contentRatingIds'> & {
  exploreType?: ProductType | null;
}): string => {
  const filterObj = pickBy({ completed, isFree }, identity);
  const queryParams = {
    type: exploreType || undefined,
    contentType: contentType || undefined,
    categoryTagId: categoryTagId || undefined,
    contentRatingIds: contentRatingIds || undefined,
    sortBy: sortBy || undefined,
    filters: !isEmpty(filterObj) ? Object.keys(filterObj).filter((key) => filterObj[key]) : undefined,
  };
  return getFictionlogUrl(
    `/explore${qs.stringify(queryParams, {
      skipNulls: true,
      addQueryPrefix: true,
      arrayFormat: 'comma',
    })}`,
  );
};
