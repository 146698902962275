import { IoMdCopy } from 'react-icons/io';

import Button from '../../../components/Button';
import Typography, { TYPOGRAPHY_TYPES } from '../../../components/Typography';
import { getDayTime } from '../../../utils/dayjs';

export interface IPropsAdListItem {
  _id: string;
  createdDate?: string;
  bookTitle: string;
  bookId: string;
  chapterTitleList: { id: string; title: string }[];
  note?: string;
  onCopyAdLink?: () => void;
}

const DATE_FORMAT_WITHOUT_TIME = 'DD-MM-YYYY';

const AdListItem = ({ _id, createdDate, bookTitle, bookId, chapterTitleList, note, onCopyAdLink }: IPropsAdListItem) => {
  return (
    <tr className="hover w-full font-tt">
      <td>{getDayTime(createdDate || '', DATE_FORMAT_WITHOUT_TIME)}</td>
      <td className="text-colorsBrandOrange01Primary">{_id}</td>
      <td>
        <div className="flex">
          <a href={`${process.env.REACT_APP_FIC_URL}/b/${bookId}`} target="_blank" rel="noreferrer" className="!font-dbh hover:underline">
            <Typography typography={TYPOGRAPHY_TYPES.BODY_DBH} renderAs="p" className="break-word" lineClamp={3}>
              {bookTitle}
            </Typography>
          </a>
        </div>
      </td>
      <td>
        <div
          className="flex flex-col"
          style={{
            textOverflow: 'ellipsis',
          }}
        >
          {chapterTitleList.map((chapter) => (
            <a key={chapter.id} href={`${process.env.REACT_APP_FIC_URL}/c/${chapter.id}`} target="_blank" rel="noreferrer" className="hover:underline">
              <Typography typography={TYPOGRAPHY_TYPES.CALLOUT_TT} renderAs="p" className="break-word" lineClamp={1}>
                {chapter.title}
              </Typography>
            </a>
          ))}
        </div>
      </td>
      <td>{note}</td>
      <td>
        <Button type="ghost" color="red" className="space-x-4 text-colorsBrandOrange01Primary" onClick={onCopyAdLink}>
          <IoMdCopy />
          <span>Copy Link</span>
        </Button>
      </td>
    </tr>
  );
};

export default AdListItem;
