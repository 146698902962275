import { omit } from 'lodash';

import { IPropsConfirmModal } from '../../modals/ConfirmModal';
import { IPropsProgressUploadFileModal } from '../../modals/ProgressUploadFileModal';

export type IModalPayload = {
  progressUploadFileModal: IPropsProgressUploadFileModal;
  confirmModal: IPropsConfirmModal;
};

export enum IModalNames {
  PROGRESS_UPLOAD_FILE = 'progressUploadFileModal',
  CONFIRM = 'confirmModal',
}

export type ActionModalsMap<M> = {
  [Key in keyof M]: {
    type: 'OPEN_MODAL' | 'REMOVE_MODAL';
    modalName: Key | IModalNames;
    modalPayload?: M[Key];
  };
};

export type IModalActions = ActionModalsMap<IModalPayload>[keyof ActionModalsMap<IModalPayload>];

export const modalReducer = (state: IModalPayload, action: IModalActions) => {
  if (action?.type === 'OPEN_MODAL' && action.modalName) {
    return { ...state, [action.modalName]: action?.modalPayload ?? {} };
  }
  if (action?.type === 'REMOVE_MODAL' && action?.modalName && state[action.modalName]) {
    return omit(state, action.modalName);
  }
  return state;
};
