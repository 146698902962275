/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const moreVertical = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path
      fill="#8C8C8C"
      fillRule="evenodd"
      d="M24 13a5 5 0 110-10 5 5 0 010 10zm0 16a5 5 0 110-10 5 5 0 010 10zm0 16a5 5 0 110-10 5 5 0 010 10z"
    />
  </svg>
);

export default moreVertical;
