import axios, { AxiosRequestConfig } from 'axios';
import FormData from 'form-data';

// eslint-disable-next-line import/prefer-default-export
export const uploadFilePresigned = async (
  file: File,
  url: string,
  fields: { [key: string]: string },
  onPercentUploadProgress?: (percentage: number) => void,
  abortController?: AbortController,
) => {
  try {
    const formData = new FormData();
    Object.keys(fields).forEach((key) => {
      formData.append(key, fields[key]);
    });
    formData.append('file', file);
    const axiosConfig: AxiosRequestConfig = {
      method: 'POST',
      url,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: formData,
      onUploadProgress: (progressEvent) => {
        const percentage = Math.round((progressEvent.loaded / (progressEvent.total ?? 0)) * 100);
        onPercentUploadProgress?.(percentage);
      },
      signal: abortController?.signal,
    };
    await axios(axiosConfig);

    return `${url}/${fields.Key}`;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    alert(`เกิดข้อผิดพลาด${error?.message ?? ''}`);
    return undefined;
  }
};
