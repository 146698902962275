/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const ebookComic = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
    <path
      fill="#8A8A8E"
      fillRule="evenodd"
      d="M12.667.667C13.403.667 14 1.264 14 2v12c0 .736-.597 1.333-1.333 1.333H3.333A1.333 1.333 0 012 14V2C2 1.264 2.597.667 3.333.667h9.334zM6.8 7.333H4.533a.534.534 0 00-.529.467L4 7.867V12.8c0 .272.203.496.466.53l.067.003H6.8a.535.535 0 00.53-.466l.003-.067V7.867a.534.534 0 00-.466-.53L6.8 7.333zM11.467 10H9.2a.535.535 0 00-.53.466l-.003.067V12.8c0 .272.203.496.466.53l.067.003h2.267a.533.533 0 00.529-.466L12 12.8v-2.267a.533.533 0 00-.466-.529L11.467 10zm0-7.333H9.2a.535.535 0 00-.53.466l-.003.067v4.933c0 .272.203.497.466.53l.067.004h2.267a.534.534 0 00.529-.467L12 8.133V3.2a.535.535 0 00-.466-.53l-.067-.003zm-4.667 0H4.533a.533.533 0 00-.529.466L4 3.2v2.267c0 .272.203.496.466.529L4.533 6H6.8a.535.535 0 00.53-.466l.003-.067V3.2a.535.535 0 00-.466-.53L6.8 2.668z"
    />
  </svg>
);

export default ebookComic;
