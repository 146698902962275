import { useEditorState, useEditorView } from '@aeaton/react-prosemirror/EditorProvider';
import { EditorState, Transaction } from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';
import React, { useCallback } from 'react';

import schema from '../schema';

export interface ToolbarItem {
  id: string;
  action: (state: EditorState, dispatch: (tr: Transaction) => void, view: EditorView) => boolean;
  content: JSX.Element;
  title?: string;
  active?: (state: EditorState) => boolean;
  enable?: (state: EditorState) => boolean;
}

const ToolbarButton: React.FC<{ item: ToolbarItem }> = ({ item }) => {
  const state = useEditorState();
  const view = useEditorView();

  const executeAction = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      item.action(state, view.dispatch, view);
    },
    [item, state, view],
  );

  return (
    <button
      type="button"
      className={`toolbar-item ${item.id}`}
      data-active={item.active && item.active(state)}
      disabled={item.enable && !item.enable(state)}
      onMouseDown={executeAction}
      title={item.title}
    >
      {item.content}
    </button>
  );
};

export default ToolbarButton;
