import { useCallback } from 'react';

import ToastComponent, { IToastType } from '../components/Toast/ToastComponent';
import { useCreateBookPageAdMutation } from '../core/graphql/operation-hooks';
import { CreateBookPageAdInput } from '../core/graphql/types';

const useMutationCreateBookPageAd = () => {
  const [mutateCreateBookPageAd, { loading: isSubmittingBookPageAd }] = useCreateBookPageAdMutation({ errorPolicy: 'all' });

  const onCreateBookPageAd = useCallback(
    async (input: CreateBookPageAdInput) => {
      const variables = { input };
      const result = await mutateCreateBookPageAd({ variables });
      if (result.data?.createBookPageAd._id) {
        return true;
      }
      ToastComponent({ type: IToastType.ERROR, label: `${result?.errors?.[0].message}` });
      return false;
    },
    [mutateCreateBookPageAd],
  );

  return {
    onCreateBookPageAd,
    isSubmittingBookPageAd,
  };
};

export default useMutationCreateBookPageAd;
