import { useCallback } from 'react';

import { usePintoEbookLazyQuery } from '../core/graphql/operation-hooks';

const useQueryPintoEbook = () => {
  const [queryPintoEbook, { data, loading }] = usePintoEbookLazyQuery();

  const onFetchPintoEbook = useCallback(
    async (id: string) => {
      queryPintoEbook({ variables: { id } });
    },
    [queryPintoEbook],
  );
  return {
    onFetchPintoEbook,
    pintoEbookData: data?.ebook,
    isLoadingPintoEbook: loading,
  };
};

export default useQueryPintoEbook;
