import { EbookBundleSubmission } from '../../../core/graphql/types';
import { getDayTime } from '../../../utils/dayjs';

export interface EbookBundleSubmissionItem {
  _id: EbookBundleSubmission['_id'];
  fileName: EbookBundleSubmission['fileName'];
  createdAt: EbookBundleSubmission['createdAt'];
  ebookBundleCount: EbookBundleSubmission['ebookBundleCount'];
  adminNote: EbookBundleSubmission['adminNote'];
}

interface IPropsEbookBundleSubmissionListTablePresenter {
  submissions?: (EbookBundleSubmissionItem | null)[];
}

const DATE_FORMAT_WITHOUT_TIME = 'DD-MM-YYYY';

const BundleSubmissionListTablePresenter = (props: IPropsEbookBundleSubmissionListTablePresenter) => {
  const { submissions } = props;

  return (
    <div className="overflow-x-auto overflow-y-hidden">
      <table className="table w-full">
        <thead>
          <tr>
            <th>Submission ID</th>
            <th>Bundle Counts</th>
            <th>Admin Note</th>
            <th>Filename</th>
            <th>Upload Date</th>
          </tr>
        </thead>
        <tbody>
          {submissions?.map((submission) => {
            return (
              <tr className="hover" key={submission?._id}>
                <th>{submission?._id}</th>
                <td>{submission?.ebookBundleCount}</td>
                <td>{submission?.adminNote}</td>
                <td>{submission?.fileName}</td>
                <td>{getDayTime(submission?.createdAt || '', DATE_FORMAT_WITHOUT_TIME)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default BundleSubmissionListTablePresenter;
