/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const questionMarkFill = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path
      fill="#8C8C8C"
      fillRule="evenodd"
      d="M24 46C11.85 46 2 36.15 2 24S11.85 2 24 2s22 9.85 22 22-9.85 22-22 22zm3-11.024c-.001 1.732-1.318 3.03-3.07 3.024-1.603-.007-2.928-1.35-2.93-2.975-.002-1.706 1.32-3.027 3.024-3.025A2.975 2.975 0 0127 34.976zm-1.876-6.2a2.741 2.741 0 01-3.635-1.4c-.62-1.4 0-3.042 1.387-3.669 3.518-1.59 4.976-3.06 4.976-4.129 0-2.146-1.725-3.887-3.852-3.887s-3.852 1.74-3.852 3.887c0 1.534-1.231 2.777-2.75 2.777-1.52 0-2.752-1.243-2.752-2.777 0-5.213 4.188-9.439 9.354-9.439 5.166 0 9.354 4.226 9.354 9.44 0 3.837-2.944 6.808-8.23 9.196z"
    />
  </svg>
);

export default questionMarkFill;
