/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const home = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path
      fill="#8C8C8C"
      fillRule="evenodd"
      d="M41 22.788V42a2 2 0 01-2 2h-7.989a2 2 0 01-2-2v-5.097a2 2 0 00-2-2H21.01a2 2 0 00-2 2V42a2 2 0 01-2 2H10a2 2 0 01-2-2V22.788H3.007c-.551 0-.998-.45-1-1.008l-.005-2.39a1.015 1.015 0 01.434-.838L22.868 4.356a1.981 1.981 0 012.264 0l20.434 14.198c.272.189.434.5.434.834v2.388c0 .559-.448 1.012-1 1.012h-4z"
    />
  </svg>
);

export default home;
