/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const textIndent = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path
      fill="#8C8C8C"
      fillRule="evenodd"
      d="M7.121 12a5.148 5.148 0 01-.034-5.952L7.121 6h33.88l.036.04a4.478 4.478 0 01-.035 5.96H7.12zm.055 30a4.486 4.486 0 01-.036-5.96l.036-.04H40.94l.036.04A4.486 4.486 0 0140.94 42H7.176zm13.792-10a6.28 6.28 0 01-.03-5.944l.03-.056h20.414l.03.056a6.28 6.28 0 01-.03 5.944H20.968zm0-10a6.28 6.28 0 01-.03-5.944l.03-.056h20.414l.03.056a6.28 6.28 0 01-.03 5.944H20.968zM6 29.418V18.584c0-.55.365-.748.815-.426L15 24l-8.185 5.844c-.451.322-.815.13-.815-.427z"
    />
  </svg>
);

export default textIndent;
