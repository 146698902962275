import loadImage from 'blueimp-load-image';
import pica from 'pica';

// eslint-disable-next-line import/prefer-default-export
export const resizeImageToBase64 = async (blobUrl: string, maxWidthImg: number, maxHeightImg: number): Promise<string> => {
  return new Promise((resolve, reject) => {
    loadImage(
      blobUrl,
      (img) => {
        const _img = img as HTMLImageElement;
        const resultCanvas = document.createElement('canvas');
        resultCanvas.width = _img.width;
        resultCanvas.height = _img.height;
        pica()
          .resize(_img, resultCanvas, {
            quality: 2,
            unsharpAmount: 60,
            unsharpRadius: 1,
            unsharpThreshold: 100,
          })
          .then(() => resolve(resultCanvas.toDataURL('image/jpeg', 0.8)))
          .catch((err) => reject(err));
      },
      {
        orientation: true,
        maxWidth: maxWidthImg,
        maxHeight: maxHeightImg,
      },
    );
  });
};

export const convertFileToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(reader.result as string);
    };
    reader.onerror = () => {
      resolve('');
    };
  });
};
