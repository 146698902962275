import classNames from 'classnames';
import { ChangeEvent, HTMLInputTypeAttribute } from 'react';

export interface IPropsCommonInput {
  className?: string;
  inputClassName?: string;
  helperText?: string;
  isInvalid?: boolean;
  label?: string;
  subLabel?: string;
  placeholder?: string;
  required?: boolean;
  type?: HTMLInputTypeAttribute;
  min?: string;
  max?: string;
  disabled?: boolean;
  step?: string | number;
  value?: string | number;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

function CommonInput(props: IPropsCommonInput) {
  const { type, className, helperText, isInvalid, label, subLabel, disabled, placeholder, required, inputClassName, min, max, value, onChange } = props;

  return (
    <div className={classNames('form-control', className)}>
      {label && (
        <label className={classNames('label', { 'pb-0': !!subLabel })}>
          <span className="label-text text-systemGrays02LabelSecondary">
            {label} {required && <span className="text-colorsBrandRed01Primary">*</span>}
          </span>
        </label>
      )}

      {subLabel && (
        <label className="label pt-0">
          <span className="label-text-alt text-systemGrays02LabelSecondary font-tt">{subLabel}</span>
        </label>
      )}

      <input
        disabled={disabled}
        type={type || 'text'}
        min={min}
        max={max}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        className={classNames(
          'input input-bordered w-full font-tt text-systemGrays02LabelSecondary font-semibold',
          {
            '!input-error': isInvalid,
          },
          inputClassName,
        )}
      />
      {helperText && (
        <label className="label">
          <span
            className={classNames('label-text-alt text-systemGrays02LabelSecondary font-tt', {
              'text-colorsBrandRed01Primary': isInvalid,
            })}
          >
            {helperText}
          </span>
        </label>
      )}
    </div>
  );
}

export default CommonInput;
