import fonts from '../assets/fonts';

const Fonts = (): JSX.Element => (
  <style
    dangerouslySetInnerHTML={{
      __html: `
  @font-face {
    font-display: swap;
    font-family: 'DBHeavent';
    font-style: normal;
    font-weight: bold;
    src: url('${fonts.db_heavent_bd_woff2}') format('woff2'),
        url('${fonts.db_heavent_bd_ttf}')  format('truetype');
  }
  @font-face {
    font-display: swap;
    font-family: 'DBHeavent';
    font-style: italic;
    font-weight: bold;
    src: url('${fonts.db_heavent_bd_it_woff2}') format('woff2'),
        url('${fonts.db_heavent_bd_it_ttf}')  format('truetype');
  }
  @font-face {
    font-display: swap;
    font-family: 'Thongterm';
    font-style: normal;
    font-weight: light;
    src: url('${fonts.thongterm_light_woff2}') format('woff2'),
        url('${fonts.thongterm_light_ttf}')  format('truetype');
  }
  @font-face {
    font-display: swap;
    font-family: 'Thongterm';
    font-style: normal;
    font-weight: bold;
    src: url('${fonts.thongterm_bold_woff2}') format('woff2'),
        url('${fonts.thongterm_bold_ttf}')  format('truetype');
  }
  @font-face {
    font-display: swap;
    font-family: 'MNPaethai';
    font-style: normal;
    font-weight: normal;
    src: url('${fonts.MNPaethai_woff2}') format('woff2'),
        url('${fonts.MNPaethai_ttf}')  format('truetype');
  }
  @font-face {
    font-display: swap;
    font-family: 'MNPaethai';
    font-style: italic;
    font-weight: bold;
    src: url('${fonts.MNPaethai_bold_italic_woff2}') format('woff2'),
        url('${fonts.MNPaethai_bold_italic_ttf}') format('truetype');
  }
  @font-face {
    font-display: swap;
    font-family: 'MNPaethai';
    font-style: italic;
    font-weight: light;
    src: url('${fonts.MNPaethai_light_italic_woff2}') format('woff2'),
        url('${fonts.MNPaethai_light_italic_ttf}') format('truetype');
  }
  @font-face {
    font-display: swap;
    font-family: 'MNPaethai';
    font-style: normal;
    font-weight: bold;
    src: url('${fonts.MNPaethai_bold_woff2}') format('woff2'),
        url('${fonts.MNPaethai_bold_ttf}')  format('truetype');
  }
  @font-face {
    font-display: swap;
    font-family: 'MNPaethai';
    font-style: normal;
    font-weight: medium;
    src: url('${fonts.MNPaethai_medium_woff2}') format('woff2'),
        url('${fonts.MNPaethai_medium_ttf}')  format('truetype');
  }
  @font-face {
    font-display: swap;
    font-family: 'MNPaethai';
    font-style: italic;
    font-weight: normal;
    src: url('${fonts.MNPaethai_italic_woff2}') format('woff2'),
        url('${fonts.MNPaethai_italic_ttf}')  format('truetype');
  }
  @font-face {
    font-display: swap;
    font-family: 'MNPaethai';
    font-style: normal;
    font-weight: light;
    src: url('${fonts.MNPaethai_light_woff2}') format('woff2'),
        url('${fonts.MNPaethai_light_ttf}')  format('truetype');
  }
  `,
    }}
  />
);

export default Fonts;
