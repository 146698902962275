/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const preference = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
    <path
      fill="#3C3C43"
      fillRule="evenodd"
      d="M13.333 13.333c.925 0 1.733.503 2.165 1.25h2.318l.015.016c.676.692.669 1.8-.015 2.484h-2.317a2.498 2.498 0 01-4.331 0H2.184a1.768 1.768 0 01-.015-2.484l.015-.016h8.984a2.5 2.5 0 012.165-1.25zm0 1.667a.833.833 0 100 1.666.833.833 0 000-1.666zM10 7.5c.925 0 1.733.503 2.165 1.25h5.65l.016.015c.676.693.669 1.8-.015 2.485h-5.65a2.498 2.498 0 01-4.331 0h-5.65a1.768 1.768 0 01-.016-2.485l.015-.015h5.65A2.503 2.503 0 0110 7.5zm0 1.667a.833.833 0 100 1.666.833.833 0 000-1.666zm-3.333-7.5c.925 0 1.733.503 2.165 1.25h8.984l.015.015c.676.693.669 1.8-.015 2.485H8.832a2.5 2.5 0 01-4.33 0H2.184a1.77 1.77 0 01-.015-2.485l.015-.015h2.317a2.5 2.5 0 012.166-1.25zm0 1.666a.834.834 0 100 1.668.834.834 0 000-1.668z"
    />
  </svg>
);

export default preference;
