/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const arrowDown = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path
      fill="#8C8C8C"
      fillRule="evenodd"
      d="M21.005 5a2 2 0 012-2h1.989a2 2 0 012 2v29.22L37.8 24h.227A3.973 3.973 0 0142 27.973L28.2 41.027 24 44.999l-4.2-3.972L6 27.973A3.973 3.973 0 019.973 24h.227l10.805 10.22V5z"
    />
  </svg>
);

export default arrowDown;
