/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const save = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path
      fill="#8C8C8C"
      fillRule="evenodd"
      d="M32 18h7.332a1 1 0 01.638 1.77L24 33 8.03 19.77A1 1 0 018.668 18H16V5a1 1 0 011-1h14a1 1 0 011 1v13zM11 38h26v-5a1 1 0 011-1h4a1 1 0 011 1v10.01c0 .539-.446.99-.997.99H5.997A.998.998 0 015 43.01V33a1 1 0 011-1h4a1 1 0 011 1v5z"
    />
  </svg>
);

export default save;
