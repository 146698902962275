import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import ToastComponent, { IToastType } from '../components/Toast/ToastComponent';
import { IModalNames } from '../core/context/modalReducer';
import { useEditDraftEbookMutation } from '../core/graphql/operation-hooks';
import { EditDraftEbookInput } from '../core/graphql/types';
import useModal from '../core/hook/context/useModal';

export enum EDIT_DRAFT_EBOOK_RETURN_STATUS {
  SUCCESS = 'success',
  ERROR = 'error',
  DUPLICATE_PRODUCT_SET = 'product_set_item_has_duplicate_vol',
}

const useEditDraftEbook = () => {
  const [mutateEditDraftEbook, { loading }] = useEditDraftEbookMutation({ errorPolicy: 'all' });
  const { openModal, removeModal } = useModal();
  const router = useHistory();

  const editDraftEbook = useCallback(
    async (ebookId: string, input: EditDraftEbookInput) => {
      const res = await mutateEditDraftEbook({
        variables: {
          ebookId,
          input,
        },
      });
      if (res.errors) {
        const [editDraftEbookError] = res.errors;
        const editDraftEbookErrorMessage = editDraftEbookError.message;
        switch (editDraftEbookErrorMessage) {
          case 'product_set_item_has_duplicate_vol':
            return EDIT_DRAFT_EBOOK_RETURN_STATUS.DUPLICATE_PRODUCT_SET;
          default:
            ToastComponent({ label: 'เกิดข้อผิดพลาดในการทำรายการ กรุณาลองใหม่อีกครั้ง', type: IToastType.ERROR });
            return EDIT_DRAFT_EBOOK_RETURN_STATUS.ERROR;
        }
      } else if (res.data?.editDraftEbook._id) {
        openModal({
          modalName: IModalNames.CONFIRM,
          modalPayload: {
            title: 'บันทึกแบบร่างเรียบร้อยแล้ว',
            onConfirm: () => {
              router.push(`/ebooks/detail?id=${res.data?.editDraftEbook._id}`);
              removeModal(IModalNames.CONFIRM);
            },
          },
        });
      }
      return EDIT_DRAFT_EBOOK_RETURN_STATUS.SUCCESS;
    },
    [router, mutateEditDraftEbook, openModal, removeModal],
  );

  return {
    editDraftEbook,
    isLoadingEditDraftEbook: loading,
  };
};

export default useEditDraftEbook;
