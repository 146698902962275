/* eslint-disable no-useless-escape */
import * as yup from 'yup';
import { AnySchema } from 'yup';
import Lazy from 'yup/lib/Lazy';
import Reference from 'yup/lib/Reference';

import { IErrorObject } from '../../../utils/validator';

export enum DISCOUNT_FIELD_KEYS {
  TITLE = 'title',
  START_DATE = 'startDate',
  IS_HAS_DISCOUNT_END_DATE = 'isHasDiscountEndDate',
  END_DATE = 'endDate',
  FILE_NAME = 'fileName',
}

export interface IErrorsDiscount {
  title?: IErrorObject;
  startDate?: IErrorObject;
  isHasDiscountEndDate?: IErrorObject;
  endDate?: IErrorObject;
  fileName?: IErrorObject;
}

export type IDiscountFields = keyof IErrorsDiscount;

const DiscountFormObj: { [key in IDiscountFields]: AnySchema<unknown, unknown, unknown> | Reference<unknown> | Lazy<never, unknown> } = {
  title: yup.string().nullable().required('Title is required').trim(),
  startDate: yup.string().nullable().required('Start effective date/time is required'),
  isHasDiscountEndDate: yup.boolean().default(true),
  endDate: yup.string().when(DISCOUNT_FIELD_KEYS.IS_HAS_DISCOUNT_END_DATE, {
    is: false,
    then: (schema) => schema.nullable(),
  }),
  fileName: yup.string().nullable().required('Please upload CSV file'),
};

export default DiscountFormObj;
