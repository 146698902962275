import { useFormContext } from 'react-hook-form';

import Input from '../../../components/form/Input';
import SearchInputDropdown from '../../../components/form/SearchInputDropdown';
import { PintoProductSetFragment } from '../../../core/graphql/types';
import { EBOOK_BUILDER_FIELD_KEYS } from '../validates/ebookBuilderSchema';

export interface IPropsProductSetInput {
  label?: string;
  className?: string;
  name?: string;
  productSetList?: { label: string; value: string }[];
  selectedProductSetId?: string;
  productSetId?: string;
  onSelectProductSet: (item: { label: string; value: string }) => void;
  productSetDetail?: PintoProductSetFragment;
  productSetsSearch?: string;
  onChangeProductSetsSearch: (value: string) => void;
  disabled?: boolean;
  isLoadingFetchProductSets?: boolean;
}

const ProductSetInput = (props: IPropsProductSetInput) => {
  const {
    label = 'เซ็ตหนังสือ',
    productSetList,
    productSetId,
    onSelectProductSet,
    productSetDetail,
    productSetsSearch,
    onChangeProductSetsSearch,
    disabled,
    isLoadingFetchProductSets,
  } = props;
  const { formState, register, watch } = useFormContext();
  const [productSetHasItemVol] = watch([EBOOK_BUILDER_FIELD_KEYS.PRODUCT_SET_HAS_ITEM_VOL]);
  const { errors } = formState;
  return (
    <div className="flex flex-row w-full space-x-24">
      <div className="flex flex-col w-full">
        {label && (
          <label className="label">
            <span className="label-text text-systemGrays02LabelSecondary">{label}</span>
          </label>
        )}
        <SearchInputDropdown
          items={productSetList}
          value={productSetsSearch}
          onChange={onChangeProductSetsSearch}
          menuClassName="w-full"
          onClickMenuItem={onSelectProductSet}
          placeholder={productSetId ? productSetDetail?.title || 'ไม่มีชื่อเซ็ตหนังสือ' : 'ไม่มีเซ็ตหนังสือ'}
          disabled={disabled}
          isLoading={isLoadingFetchProductSets}
        />
      </div>
      {productSetHasItemVol && (
        <Input
          required
          className="w-full"
          name={EBOOK_BUILDER_FIELD_KEYS.PRODUCT_SET_VOL}
          register={register}
          label="ลำดับเล่ม"
          isInvalid={!!errors.productSetVol}
          helperText={errors?.productSetVol?.message}
        />
      )}
    </div>
  );
};

export default ProductSetInput;
