/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const addOutline = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path
      fill="#8C8C8C"
      fillRule="evenodd"
      d="M21 21v-6.638a6.975 6.975 0 016 0V21h6.648a6.858 6.858 0 010 6H27v6.644a6.909 6.909 0 01-6 0V27h-6.627a7.13 7.13 0 010-6H21zm3 19c8.837 0 16-7.163 16-16S32.837 8 24 8 8 15.163 8 24s7.163 16 16 16zm0 6C11.85 46 2 36.15 2 24S11.85 2 24 2s22 9.85 22 22-9.85 22-22 22z"
    />
  </svg>
);

export default addOutline;
