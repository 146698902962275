import { ToastContainer as ToastifyContainer } from 'react-toastify';

export interface IPropsToastContainer {
  limit?: number;
}

const ToastContainer = (props: IPropsToastContainer): JSX.Element => {
  const { limit } = props;
  return <ToastifyContainer className="w-auto" limit={limit || 3} />;
};

export default ToastContainer;
