/* eslint-disable react/prop-types */
import React from 'react';

import schema from '../schema';
import {
  insertNodeHorizontalRule,
  setBlockTypeAlignCenter,
  setBlockTypeAlignRight,
  setBlockTypeParagraph,
  insertBlobImage,
  toggleMarkBold,
  toggleMarkItalic,
  toggleMarkStrikethrough,
  toggleMarkUnderline,
  toggleWrapBlockquote,
  toggleWrapHeading,
  toggleWrapIndent,
} from '../utils/commands';
import { isMarkActive, isWrapped, isWrappedInClass } from '../utils/editorUtils';

import ToolbarButton, { ToolbarItem } from './ToolbarButton';
// import GroupToolbarButton from './GroupToolbarButton';

export const defaultBlockStyles: ToolbarItem[] = [
  {
    id: 'paragraph',
    content: (
      <div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
          <path d="M40.7947 8.30132V9.91669C40.7947 10.3445 40.7947 9.91669 40.7947 11.9746C39.1607 11.9746 39.5893 11.9746 39.1607 11.9746C38.2679 11.9746 36.8482 11.982 36.7768 11.9968C36.7768 12.6827 36.7768 11.9968 36.7768 12.6827C36.7232 12.845 36.7768 13.3171 36.7768 14.099V39.5909C36.7768 39.9597 36.7768 39.5909 36.7768 40.9407C35.1429 40.9407 35.5893 40.9407 35.1429 40.9407L32.25 40.9515C31.8036 40.9515 32.25 40.9515 30.6031 40.9515C30.6161 39.5909 30.6161 39.9597 30.6161 39.5909V12.6385H26.7857V39.5909C26.7857 39.9597 26.7857 39.5909 26.7857 40.9407C25.1518 40.9407 25.6161 40.9407 25.1518 40.9407H22.259C21.7947 40.9407 22.259 40.9407 20.6155 40.9407C20.6155 39.5909 20.6155 39.9597 20.6155 39.5909L20.625 28.6152C18 28.4382 15.8125 28.003 14.0625 27.3096C11.8125 26.454 10.0982 25.1337 8.91967 23.3486C7.77681 21.6226 7.20538 19.7122 7.20538 17.6174C7.20538 15.1685 7.9911 13.0589 9.56253 11.2887C11.134 9.54788 13 8.37508 15.1607 7.77024C17.1429 7.2244 20.8661 6.95148 26.3304 6.95148H39.1607C39.6072 6.95148 39.1607 6.95148 40.7947 6.95148C40.7947 8.30132 40.7947 7.93251 40.7947 8.30132Z" />
        </svg>
      </div>
    ),
    action: setBlockTypeParagraph,
    enable: setBlockTypeParagraph,
    active: isWrapped(schema.nodes.paragraph),
  },
  {
    id: 'heading',
    content: (
      <div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
          <path
            fillRule="evenodd"
            d="M30 28H18v11a1 1 0 0 1-1 1h-6a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v11h12V9a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v30a1 1 0 0 1-1 1h-6a1 1 0 0 1-1-1V28z"
          ></path>
        </svg>
      </div>
    ),
    action: toggleWrapHeading,
    enable: toggleWrapHeading,
    active: isWrapped(schema.nodes.heading),
  },
  {
    id: 'quote',
    content: (
      <div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
          <path
            fillRule="evenodd"
            d="M32.601 35.303a1 1 0 0 1-.953.697h-5.444a1 1 0 0 1-.983-1.184l4.11-22a1 1 0 0 1 .984-.816H40l-7.399 23.303zM16.1 35.286a1 1 0 0 1-.959.714h-4.958a1 1 0 0 1-.987-1.165l3.689-22A1 1 0 0 1 13.87 12h9.18l-6.951 23.286z"
          ></path>
        </svg>
      </div>
    ),
    action: toggleWrapBlockquote,
    enable: toggleWrapBlockquote,
    active: isWrapped(schema.nodes.blockquote),
  },
];

export const defaultInlineStyles: ToolbarItem[] = [
  {
    id: 'bold',
    content: (
      <div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
          <path
            fillRule="evenodd"
            d="M36.79 17.143c0 2.331-1.644 4.846-3.99 6.377 3.12 1.371 5.2 4.343 5.2 7.817C38 36.114 34.082 40 29.027 40H14.003A1.999 1.999 0 0 1 12 37.998V10.002A2 2 0 0 1 14.007 8h13.11c5.441 0 9.674 3.977 9.674 9.143zM25.668 14h-5.669c-.55 0-.998.449-.998 1.003v4.994A.993.993 0 0 0 19.998 21h5.669C27.51 21 29 19.437 29 17.5S27.511 14 25.667 14zm-5.66 20H26.7c1.826 0 3.3-1.563 3.3-3.5S28.526 27 26.7 27h-6.693c-.56 0-1.007.449-1.007 1.003v4.994A.998.998 0 0 0 20.007 34z"
          ></path>
        </svg>
      </div>
    ),
    action: toggleMarkBold,
    enable: toggleMarkBold,
    active: isMarkActive(schema.marks.bold),
  },
  {
    id: 'italic',
    content: (
      <div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
          <path
            fillRule="evenodd"
            d="M21.66 8h15.894c1.104 0 1.851.88 1.67 1.965l-.345 2.07C38.7 13.115 37.656 14 36.55 14h-3.555c-.545 0-1.179.408-1.405.911L23.41 33.09c-.23.511.036.911.585.911h3.555c1.105 0 1.855.88 1.674 1.965l-.345 2.07C28.7 39.115 27.66 40 26.557 40H10.694c-1.09 0-1.848-.88-1.667-1.965l.345-2.07c.18-1.08 1.22-1.965 2.323-1.965h2.246c.551 0 1.192-.408 1.43-.911l8.635-18.178c.243-.511-.018-.911-.573-.911h-2.767c-1.102 0-1.858-.88-1.677-1.965l.345-2.07C19.514 8.885 20.556 8 21.66 8z"
          ></path>
        </svg>
      </div>
    ),
    action: toggleMarkItalic,
    enable: toggleMarkItalic,
    active: isMarkActive(schema.marks.italic),
  },
  {
    id: 'underline',
    content: (
      <div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
          <path
            fillRule="evenodd"
            d="M10 39.8v-3.6c0-.668.446-1.2.997-1.2h26.006c.557 0 .997.537.997 1.2v3.6c0 .668-.446 1.2-.997 1.2H10.997c-.557 0-.997-.537-.997-1.2zM12.026 7H17a1 1 0 0 1 1 1v10.038c0 3.03 2.663 6.011 6 6.011 3.362 0 6-2.99 6-6.01V8a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v11c0 8.008-7.462 12-13 12-5.647 0-12.974-3.992-12.974-12V8a1 1 0 0 1 1-1z"
          ></path>
        </svg>
      </div>
    ),
    action: toggleMarkUnderline,
    enable: toggleMarkUnderline,
    active: isMarkActive(schema.marks.underline),
  },
  {
    id: 'strikethrough',
    content: (
      <div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
          <path
            fillRule="evenodd"
            d="M10.6 30H8c-.545 0-1-.448-1-1v-3c0-.556.447-1 1-1h32c.545 0 1 .448 1 1v3c0 .556-.447 1-1 1h-2.452l3.358 8.638A1 1 0 0 1 39.974 40h-6.8a1 1 0 0 1-.927-.624l-3.194-7.862h-10.72l-3.513 7.893a1 1 0 0 1-.914.593H8.028a1 1 0 0 1-.927-1.376L10.6 30zm4.056-10l4.636-11.43C19.755 7.575 20.665 7 21.787 7h4.931c1.118 0 2.028.573 2.497 1.57l.053.127L33.66 20h-7.706l-1.741-5-2.009 5h-7.55z"
          ></path>
        </svg>
      </div>
    ),
    action: toggleMarkStrikethrough,
    enable: toggleMarkStrikethrough,
    active: isMarkActive(schema.marks.strikethrough),
  },
  // {
  //   id: 'code',
  //   content: <div>C</div>,
  //   action: toggleMarkCode,
  //   enable: toggleMarkCode,
  //   active: isMarkActive(schema.marks.code),
  // },
];

export const defaultAlignStyles: ToolbarItem[] = [
  {
    id: 'indent',
    content: (
      <div style={{ height: 20, width: 20 }}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
          <path
            fillRule="evenodd"
            d="M7.121 12a5.148 5.148 0 0 1-.034-5.952L7.121 6h33.88l.036.04a4.478 4.478 0 0 1-.035 5.96H7.12zm.055 30a4.486 4.486 0 0 1-.036-5.96l.036-.04H40.94l.036.04A4.486 4.486 0 0 1 40.94 42H7.176zm13.792-10a6.28 6.28 0 0 1-.03-5.944l.03-.056h20.414l.03.056a6.28 6.28 0 0 1-.03 5.944H20.968zm0-10a6.28 6.28 0 0 1-.03-5.944l.03-.056h20.414l.03.056a6.28 6.28 0 0 1-.03 5.944H20.968zM6 29.418V18.584c0-.55.365-.748.815-.426L15 24l-8.185 5.844c-.451.322-.815.13-.815-.427z"
          ></path>
        </svg>
      </div>
    ),
    action: toggleWrapIndent,
    enable: toggleWrapIndent,
    active: isWrappedInClass(schema.nodes.paragraph, 'indent'),
  },
  {
    id: 'alignLeft',
    content: (
      <div style={{ height: 20, width: 20 }}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
          <path
            fillRule="evenodd"
            d="M7.118 12a5.16 5.16 0 0 1-.034-5.952L7.118 6h33.764l.036.04a4.486 4.486 0 0 1-.036 5.96H7.118zm-.033 20a4.486 4.486 0 0 1-.036-5.96l.036-.04H40.85l.035.04A4.486 4.486 0 0 1 40.85 32H7.085zm-.518 10a6.9 6.9 0 0 1-.028-5.941L6.567 36H24.69l.028.059A6.9 6.9 0 0 1 24.69 42H6.567zm0-20a6.9 6.9 0 0 1-.028-5.941L6.567 16H24.69l.028.059A6.9 6.9 0 0 1 24.69 22H6.567z"
          ></path>
        </svg>
      </div>
    ),
    action: setBlockTypeParagraph,
    enable: setBlockTypeParagraph,
    active: isWrappedInClass(schema.nodes.paragraph, undefined),
  },
  {
    id: 'alignCenter',
    content: (
      <div style={{ height: 20, width: 20 }}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
          <path
            fillRule="evenodd"
            d="M7.118 12a5.16 5.16 0 0 1-.034-5.952L7.118 6h33.764l.036.04a4.486 4.486 0 0 1-.036 5.96H7.118zm-.12 20a4.486 4.486 0 0 1-.035-5.96L7 26h33.764l.036.04a4.486 4.486 0 0 1-.036 5.96H7zm7.483 10a6.9 6.9 0 0 1-.028-5.941l.028-.059h18.123l.028.059A6.9 6.9 0 0 1 32.604 42H14.48zm0-20a6.9 6.9 0 0 1-.028-5.941l.028-.059h18.123l.028.059A6.9 6.9 0 0 1 32.604 22H14.48z"
          ></path>
        </svg>
      </div>
    ),
    action: setBlockTypeAlignCenter,
    enable: setBlockTypeAlignCenter,
    active: isWrappedInClass(schema.nodes.paragraph, 'center'),
  },
  {
    id: 'alignRight',
    content: (
      <div style={{ height: 20, width: 20 }}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
          <path
            fillRule="evenodd"
            d="M6.81 12a5.16 5.16 0 0 1-.034-5.952L6.81 6h33.764l.036.04a4.486 4.486 0 0 1-.036 5.96H6.81zm.189 20a4.486 4.486 0 0 1-.036-5.96L7 26h33.764l.036.04a4.486 4.486 0 0 1-.036 5.96H7zm16.45 10a6.9 6.9 0 0 1-.028-5.941l.027-.059h18.123l.028.059A6.9 6.9 0 0 1 41.57 42H23.448zm0-20a6.9 6.9 0 0 1-.028-5.941l.027-.059h18.123l.028.059A6.9 6.9 0 0 1 41.57 22H23.448z"
          ></path>
        </svg>
      </div>
    ),
    action: setBlockTypeAlignRight,
    enable: setBlockTypeAlignRight,
    active: isWrappedInClass(schema.nodes.paragraph, 'right'),
  },
];

export const defaultInsertTools: ToolbarItem[] = [
  {
    id: 'horizontalRule',
    content: (
      <div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
          <path
            fillRule="evenodd"
            d="M11 24h26v-5a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v10.01c0 .539-.446.99-.997.99H5.997A.998.998 0 0 1 5 29.01V19a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v5z"
          ></path>
        </svg>
      </div>
    ),
    action: insertNodeHorizontalRule,
    enable: insertNodeHorizontalRule,
    active: () => false,
  },
  {
    id: 'image',
    content: (
      <div>
        <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M8.619 5h30.762A3.62 3.62 0 0 1 43 8.619v30.762A3.62 3.62 0 0 1 39.381 43H8.619A3.62 3.62 0 0 1 5 39.381V8.619A3.62 3.62 0 0 1 8.619 5zM37 12a1 1 0 0 0-1-1H12a1 1 0 0 0-1 1v22l6.541-6.411a1 1 0 0 1 1.059-.22l3.89 1.493a1 1 0 0 0 1.068-.229l8.052-8.114a1 1 0 0 1 1.166-.185L37 22.5V12zM19 23a4 4 0 1 1 0-8 4 4 0 0 1 0 8z"
            fillRule="evenodd"
          ></path>
        </svg>
      </div>
    ),
    action: insertBlobImage,
    enable: () => true,
    active: () => false,
  },
];

export const Toolbar: React.FC<{
  isFocusEditor: boolean;
  inlineStyles: ToolbarItem[];
  alignStyles: ToolbarItem[];
  blockStyles: ToolbarItem[];
  insertTools: ToolbarItem[];
  className?: string;
}> = React.memo((props) => {
  const { inlineStyles, alignStyles, blockStyles, insertTools, className = '', isFocusEditor } = props;
  return (
    <div className={`editor-toolbar ${isFocusEditor ? 'isFocus' : ''} ${className}`}>
      {/* <div className="show-in-mobile">
        <GroupToolbarButton items={blockStyles} />
      </div> */}
      <div>
        {blockStyles.map((item) => (
          <ToolbarButton key={item.id} item={item} />
        ))}
      </div>

      <div>
        {inlineStyles.map((item) => (
          <ToolbarButton key={item.id} item={item} />
        ))}
      </div>

      {/* <div className="show-in-mobile">
        <GroupToolbarButton items={alignStyles} />
      </div> */}
      <div>
        {alignStyles.map((item) => (
          <ToolbarButton key={item.id} item={item} />
        ))}
      </div>

      <div>
        {insertTools.map((item) => (
          <ToolbarButton key={item.id} item={item} />
        ))}
      </div>
    </div>
  );
});

Toolbar.displayName = 'Toolbar';
