import { useCallback } from 'react';

import { usePintoSectionsLazyQuery } from '../core/graphql/operation-hooks';
import { SectionsFilterV2 } from '../core/graphql/types';

const INITIAL_LIMIT = 10;

const useQueryPintoSections = () => {
  const [queryPintoSections, { data, fetchMore, variables, loading }] = usePintoSectionsLazyQuery({ fetchPolicy: 'network-only' });

  const fetchPintoSections = useCallback(
    (filter?: SectionsFilterV2) => {
      queryPintoSections({
        variables: {
          filter: {
            ...filter,
            limit: INITIAL_LIMIT,
          },
        },
      });
    },
    [queryPintoSections],
  );

  const onFetchMorePintoSections = useCallback(() => {
    if (loading || !data?.pintoSections?.pageInfo.hasNextPage) return;
    fetchMore?.({
      variables: {
        filter: {
          ...variables?.filter,
          afterCursor: data?.pintoSections?.pageInfo.endCursor,
        },
      },
    });
  }, [loading, data?.pintoSections?.pageInfo.hasNextPage, data?.pintoSections?.pageInfo.endCursor, fetchMore, variables?.filter]);

  return {
    fetchPintoSections,
    onFetchMorePintoSections,
    sections: data?.pintoSections,
    isLoadingPintoSections: loading,
  };
};

export default useQueryPintoSections;
