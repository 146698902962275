import React from 'react';

function LoadingModal() {
  return (
    <div className="modal modal-open">
      <div className="modal-box w-[150px] h-[150px]">
        <div className="w-full btn btn-outline border-none loading h-full">loading...</div>
      </div>
    </div>
  );
}

export default LoadingModal;
