import { useState, useCallback } from 'react';
import { MdClose } from 'react-icons/md';

import Button from './Button';
import ImageCropper from './ImageCropper';

export const CROP_IMAGE_SIZE = {
  ebookCover: {
    imageWidth: 1000,
    imageHeight: 1414,
  },
  bannerCover: {
    imageWidth: 1500,
    imageHeight: 686,
  },
};

export interface IPropsCropImageModal {
  title?: string;
  confirmText?: string;
  base64Image?: string;
  imageWidth?: number;
  imageHeight?: number;
  modalWidth?: number;
  onConfirm?: (croppedBase64Image: string) => void;
  onClose?: () => void;
}

const defaultProps = {
  title: 'อัปโหลดรูปภาพ',
  confirmText: 'บันทึก',
  base64Image: undefined,
  imageWidth: 1000,
  imageHeight: 1414,
  modalWidth: 420,
  onConfirm: undefined,
  onClose: undefined,
};

const CropImageModal = (props: IPropsCropImageModal) => {
  const { title, confirmText, base64Image, imageWidth, imageHeight, onConfirm, onClose, modalWidth } = props;
  const [cropper, setCropper] = useState<Cropper>();

  const handleConfirm = useCallback(() => {
    if (cropper) {
      const _base64Image = cropper.getCroppedCanvas().toDataURL('image/jpeg', 0.8);
      onConfirm?.(_base64Image);
    }
  }, [cropper, onConfirm]);

  return (
    <div className="modal modal-open">
      <div className="relative flex flex-col p-32 modal-box justify-center items-center" style={{ width: modalWidth }}>
        {onClose && (
          <Button type="ghost" color="gray" className="absolute right-2 top-2" onClick={onClose}>
            <MdClose size={22} />
          </Button>
        )}

        <h4 className="text-48 text-center font-opn font-semibold text-colorsBrandWarmBlack01LabelPrimary md:text-22">{title}</h4>
        <div className="px-7 pt-16">
          <ImageCropper
            base64Image={base64Image}
            imageWidth={imageWidth ?? defaultProps.imageWidth}
            imageHeight={imageHeight ?? defaultProps.imageHeight}
            setCropper={(_cropper) => setCropper(_cropper)}
          />
        </div>
        <div className="mt-32 flex w-full">
          <Button className="w-full" type="fill" color="red" onClick={handleConfirm}>
            {confirmText}
          </Button>
        </div>
      </div>
    </div>
  );
};

CropImageModal.defaultProps = defaultProps;

export default CropImageModal;
