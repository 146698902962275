/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const shareAndroid = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path
      fill="#8C8C8C"
      fillRule="evenodd"
      d="M29.103 34.798L17.105 28.79a7 7 0 11-.002-9.58l11.998-6.015a7 7 0 111.78 3.582l-11.986 6.01a7.044 7.044 0 010 2.426l11.992 6.007a7 7 0 11-1.785 3.58z"
    />
  </svg>
);

export default shareAndroid;
