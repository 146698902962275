/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const stats = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path
      fill="#8C8C8C"
      fillRule="evenodd"
      d="M4 5a1 1 0 011-1h4a1 1 0 011 1v33.135h33a1 1 0 011 1V43a1 1 0 01-1 1H4V5zm20 29V10.923a5.558 5.558 0 016 0V34h-6zm10 0V4.938a5.529 5.529 0 016 0V34h-6zm-20 0V21.4a7.093 7.093 0 016 0V34h-6z"
    />
  </svg>
);

export default stats;
