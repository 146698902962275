import classNames from 'classnames';
import { isNull } from 'lodash';
import { FieldValues, UseFormRegister } from 'react-hook-form';

export type IInputValue = string | number | boolean | null | undefined;

export interface IOption {
  label: string;
  value?: IInputValue | IInputValue[];
  disable?: boolean;
}

export interface IPropsDropdownSelect {
  name: string;
  options?: IOption[];
  label?: string;
  className?: string;
  selectClassName?: string;
  labelClassName?: string;
  required?: boolean;
  helperText?: string;
  isInvalid?: boolean;
  register?: UseFormRegister<FieldValues>;
  disabled?: boolean;
}
const DropdownSelect = (props: IPropsDropdownSelect) => {
  const { options, label, name, required, className, helperText, isInvalid, register, selectClassName, labelClassName, disabled } = props;
  return (
    <div className={classNames('form-control', className)}>
      {label && (
        <label className="label">
          <span className={classNames('label-text text-systemGrays02LabelSecondary', labelClassName)}>
            {label} {required && <span className="text-colorsBrandRed01Primary">*</span>}
          </span>
        </label>
      )}
      <div className="flex flex-wrap space-x-8">
        <select {...register?.(name)} className={classNames('select select-bordered w-80', selectClassName)} disabled={disabled}>
          {options?.map((option) => (
            <option key={option.label} disabled={option.disable} value={(isNull(option?.value) ? undefined : option?.value) as string | number | undefined}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
      {helperText && (
        <label className="label">
          <span
            className={classNames('label-text-alt text-systemGrays02LabelSecondary font-tt', {
              'text-colorsBrandRed01Primary': isInvalid,
            })}
          >
            {helperText}
          </span>
        </label>
      )}
    </div>
  );
};

export default DropdownSelect;
