/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const android = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path
      fill="#8C8C8C"
      fillRule="evenodd"
      d="M18.393 5.905l-1.688-3.392c-.204-.411-.071-.929.297-1.156.369-.228.833-.08 1.038.331l1.73 3.479A10.39 10.39 0 0124 4.27c1.503 0 2.933.32 4.23.896l1.73-3.479c.205-.41.669-.559 1.037-.331.369.227.502.745.298 1.156l-1.688 3.392a10.899 10.899 0 013.577 3.742 11.176 11.176 0 011.496 5.742v.622H13.32v-.753c0-3.952 2.028-7.417 5.073-9.353zm13.705 30.93h-.978v5.874c0 1.68-1.574 3.041-3.13 3.041-1.555 0-2.816-1.362-2.816-3.041v-5.873h-2.348v5.873c0 1.68-1.26 3.041-2.816 3.041-1.556 0-3.13-1.362-3.13-3.041v-5.873h-.978c-1.426 0-2.582-1.077-2.582-2.616V17.27h21.36v16.95c0 1.54-1.156 2.616-2.582 2.616zM11.54 30.399c0 1.596-1.195 2.89-2.67 2.89-1.475 0-2.67-1.294-2.67-2.89V20.16c0-1.596 1.195-2.89 2.67-2.89 1.475 0 2.67 1.294 2.67 2.89v10.238zm8.215-20.248c-.605 0-1.095.51-1.095 1.14 0 .63.49 1.141 1.095 1.141s1.096-.51 1.096-1.14c0-.63-.49-1.141-1.096-1.141zM41.8 30.398c0 1.596-1.195 2.89-2.67 2.89-1.475 0-2.67-1.294-2.67-2.89V20.16c0-1.596 1.195-2.89 2.67-2.89 1.475 0 2.67 1.294 2.67 2.89v10.238zM28.655 10.15c-.605 0-1.095.51-1.095 1.14 0 .63.49 1.141 1.095 1.141s1.096-.51 1.096-1.14c0-.63-.49-1.141-1.096-1.141z"
    />
  </svg>
);

export default android;
