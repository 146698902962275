/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const check = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path
      fill="#8C8C8C"
      fillRule="evenodd"
      d="M3.567 26.36a2.13 2.13 0 01.003-2.897l1.483-1.566a1.869 1.869 0 012.748-.002l9.395 9.89L40.77 6.597a1.866 1.866 0 012.746.01L45 8.186a2.146 2.146 0 01-.003 2.912l-27.8 29.65L3.566 26.36z"
    />
  </svg>
);

export default check;
