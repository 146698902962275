import { useCallback } from 'react';

import { usePintoUpdateFeedMutation } from '../core/graphql/operation-hooks';
import { FeedInput, FeedPref } from '../core/graphql/types';

const useMutatePintoUpdateFeed = () => {
  const [mutatePintoUpdateFeed, { loading }] = usePintoUpdateFeedMutation();

  const onMutatePintoUpdateFeed = useCallback(
    async (slug: string, input: FeedInput) => {
      const res = await mutatePintoUpdateFeed({ variables: { slug, pref: FeedPref.Default, input } });
      return !!res.data?.pintoUpdateFeed._id;
    },
    [mutatePintoUpdateFeed],
  );

  return {
    onMutatePintoUpdateFeed,
    isLoadingMutatePintoUpdateFeed: loading,
  };
};

export default useMutatePintoUpdateFeed;
