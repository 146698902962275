/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const addFill = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path
      fill="#8C8C8C"
      fillRule="evenodd"
      d="M21 21h-9.456a5.832 5.832 0 000 6H21v9.468a5.671 5.671 0 006 0V27h9.471a5.634 5.634 0 000-6H27v-9.464a5.72 5.72 0 00-6 0V21zm3 25C11.85 46 2 36.15 2 24S11.85 2 24 2s22 9.85 22 22-9.85 22-22 22z"
    />
  </svg>
);

export default addFill;
