import { useCallback } from 'react';

import { usePintoUnHideEbookMutation } from '../core/graphql/operation-hooks';

const useMutationPintoUnHideEbook = () => {
  const [mutatePintoUnHideEbook, { loading }] = usePintoUnHideEbookMutation();

  const onMutatePintoUnHideEbook = useCallback(
    async (ebookId: string) => {
      const res = await mutatePintoUnHideEbook({ variables: { ebookId }, refetchQueries: ['PintoEbook'] });
      return !!res.data?.unhideEbook._id;
    },
    [mutatePintoUnHideEbook],
  );

  return {
    onMutatePintoUnHideEbook,
    isLoadingMutatePintoUnHideEbook: loading,
  };
};

export default useMutationPintoUnHideEbook;
