import { useCallback } from 'react';

import { usePintoEbooksLazyQuery } from '../core/graphql/operation-hooks';
import { EbooksFilter } from '../core/graphql/types';

const INITIAL_LIMIT = 10;

const useQueryPintoEbooks = () => {
  const [queryPintoEbooks, { data, variables, loading }] = usePintoEbooksLazyQuery({ fetchPolicy: 'network-only' });

  const fetchPintoEbooks = useCallback(
    (filter?: EbooksFilter) => {
      queryPintoEbooks({
        variables: {
          filter: {
            ...filter,
            limit: INITIAL_LIMIT,
          },
        },
      });
    },
    [queryPintoEbooks],
  );

  const onFetchMorePintoEbooks = useCallback(() => {
    if (loading || !data?.ebooks?.pageInfo.hasNextPage) return;
    fetchPintoEbooks?.({
      ...variables?.filter,
      afterCursor: data?.ebooks?.pageInfo.endCursor,
    });
  }, [loading, data?.ebooks?.pageInfo.hasNextPage, data?.ebooks?.pageInfo.endCursor, fetchPintoEbooks, variables?.filter]);

  return {
    fetchPintoEbooks,
    onFetchMorePintoEbooks,
    ebooks: data?.ebooks,
    isLoadingPintoEbooks: loading,
  };
};

export default useQueryPintoEbooks;
