import { useCallback, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';

import BookPageAdsDetailPresenter from '../../app/genPageForAds/presenters/BookPageAdDetailPresenter';
import BookPageAdSchema, { IBookPageAdFields } from '../../app/genPageForAds/validates/bookPageAdSchema';
import Breadcrumbs from '../../components/Breadcrumbs';
import Card from '../../components/Card';
import LeavePageAlert from '../../components/LeavePageAlert';
import ToastComponent, { IToastType } from '../../components/Toast/ToastComponent';
import { CreateBookPageAdInput } from '../../core/graphql/types';
import useMutationCreateBookPageAd from '../../hooks/useMutationCreateBookPageAd';
import useHookForm from '../../utils/form/useHookForm';

const CreateAdsPage = () => {
  const history = useHistory();
  const { onCreateBookPageAd } = useMutationCreateBookPageAd();
  const { methods, formState, getValues } = useHookForm<IBookPageAdFields>({
    objectShapeForm: BookPageAdSchema,
    mode: 'onChange',
  });
  const { isDirty, isValid } = formState;

  const [createdAdSuccess, setCreatedAdSuccess] = useState<boolean>(false);

  const onSubmit = useCallback(async () => {
    if (isValid) {
      const fromData = getValues();
      const input: CreateBookPageAdInput = {
        bookId: fromData.bookId,
        chapterSeqList: fromData.chapterSeqList.map((chapterNo: string) => +chapterNo),
        destinationUrl: fromData.destinationUrl,
        note: fromData.note || undefined,
      };
      const adId = await onCreateBookPageAd(input);
      if (adId) {
        ToastComponent({ type: IToastType.SUCCESS, label: 'Generate an ad page successfully' });
        setCreatedAdSuccess(true);
        history.replace('/gen-page-for-ads');
      }
    }
  }, [getValues, history, isValid, onCreateBookPageAd]);

  return (
    <div>
      <LeavePageAlert showConfirmLeavePageAlert={isDirty && !createdAdSuccess} message="All change will be discarded, Are you sure to leave this page ?" />

      <div className="sticky top-0 z-50 px-5 bg-componentsBgGrouped02">
        {/* section: breadcrumbs */}
        <Breadcrumbs items={[{ name: 'Gen Page for Ads', url: '/gen-page-for-ads' }, { name: 'Create' }]} />

        {/* section: title */}
        <div className="flex justify-between pt-5">
          <h1 className="font-dbh text-[28px]">Gen page for ads</h1>

          <div className="space-x-12">
            <Link to="/gen-page-for-ads">
              <button className="btn btn-outline">CANCEL</button>
            </Link>
            <button className="btn btn-warning" onClick={onSubmit} disabled={!isValid}>
              Generate
            </button>
          </div>
        </div>

        {/* section: main */}
        <div className="mb-0 divider opacity-80" />
      </div>

      <Card className="flex flex-row w-full">
        <FormProvider {...methods}>
          <BookPageAdsDetailPresenter />
        </FormProvider>
      </Card>
    </div>
  );
};

export default CreateAdsPage;
