/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const caretUp = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path
      fill="#8C8C8C"
      fillRule="evenodd"
      d="M24 11l4.667 4.541L44 30.461A4.539 4.539 0 0139.461 35h-.128L24 20.082 8.668 35h-.129A4.539 4.539 0 014 30.461l15.333-14.92L24 11z"
    />
  </svg>
);

export default caretUp;
