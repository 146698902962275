/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const comment = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path
      fill="#8C8C8C"
      fillRule="evenodd"
      d="M10.316 7h27.368C41.172 7 44 9.845 44 13.355v16.39c0 3.51-2.828 6.355-6.316 6.355h-4.21l-12.048 8.71a1 1 0 01-1.578-.937l.994-7.773H10.316C6.828 36.1 4 33.254 4 29.744V13.355C4 9.845 6.828 7 10.316 7z"
    />
  </svg>
);

export default comment;
