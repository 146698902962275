import classnames from 'classnames';

import { decodeHTMLEnitities } from '../../utils/string';

import css from './FeedCoverItem.module.scss';
import Icon, { IconNames } from './Icon';
import Image from './Image';

export interface IPropsFeedCoverItem {
  imageUrl: string;
  alt: string;
  placeholderColor?: string;

  // infomations
  badgeLabel?: string;
  title: string;
  text1?: string;
  text2?: string;
  meta?: { icon: IconNames; label: string }[];
}

const FeedCoverItem = (props: IPropsFeedCoverItem): JSX.Element => {
  return (
    <div>
      <div className="relative overflow-hidden rounded-8">
        <Image lazy src={props.imageUrl} alt={decodeHTMLEnitities(props.alt)} aspectRatio={0.7072135785} placeholderColor={props.placeholderColor} />
        {props.badgeLabel ? <div className={css.badge}>{props.badgeLabel}</div> : undefined}
      </div>
      <div className={classnames('!text-systemGrays01LabelPrimary', css.title)}>{decodeHTMLEnitities(props.title)}</div>
      {props.text1 ? <div className={classnames('!text-systemGrays02LabelSecondary', css.text1)}>{decodeHTMLEnitities(props.text1)}</div> : undefined}
      {props.text2 ? <div className={classnames('!text-systemGrays02LabelSecondary', css.text2)}>{decodeHTMLEnitities(props.text2)}</div> : undefined}
      {props.meta && props.meta.length > 0 ? (
        <div className={css.metaBox}>
          {props.meta.map((metaItem) => (
            <div className={css.metaItem} key={metaItem.icon + metaItem.label}>
              <Icon name={metaItem.icon} size="16px" color="var(--systemGrays02LabelSecondary)" />
              <div className="text-systemGrays02LabelSecondary">{metaItem.label}</div>
            </div>
          ))}
        </div>
      ) : undefined}
    </div>
  );
};

export default FeedCoverItem;
