import { Book } from '../../core/graphql/types';
import { numberWithUnits, numberWithCommas } from '../../utils/convertNumber';
import { getFictionlogUrl } from '../../utils/router';

import FeedCoverItem from './FeedCoverItem';
import { IconNames } from './Icon';

export type IPropsBookCoverSectionItem = Pick<
  Book,
  '_id' | 'viewsCount' | 'coverImage' | 'title' | 'completed' | 'placeholderBackgroundColor' | 'authorName' | 'translatorName'
> & {
  __typename: 'Book';
  discount?: number;
  bundlePriceTierDetail?: {
    discount: number;
  };
  chaptersCount: {
    public: number;
  };
  categoryTag: {
    name: string;
  };
  contentRating: {
    _id: string;
    name: string;
  };
  user: {
    displayName: string;
  };
};

const contentRatingNoNC = '1';
const getBookCategory = (categoryTag: { name: string }, contentRating: { _id: string; name: string }) => {
  if (!categoryTag) {
    return 'ไม่มีหมวดหมู่';
  }
  return `${categoryTag.name}${contentRating._id === contentRatingNoNC ? ` · ${contentRating.name}` : ''}`;
};

const getMeta = (viewsCount: IPropsBookCoverSectionItem['viewsCount'], chaptersCount: IPropsBookCoverSectionItem['chaptersCount']): { icon: IconNames; label: string }[] => {
  return [
    { icon: 'view', label: viewsCount ? numberWithUnits(viewsCount) : '0' },
    { icon: 'list', label: chaptersCount?.public ? numberWithCommas(chaptersCount.public)! : '0' },
  ];
};

const getBadgeLabel = (book: IPropsBookCoverSectionItem): string | undefined => {
  if ((book?.bundlePriceTierDetail?.discount ?? 0) > 0) {
    return `-${book!.bundlePriceTierDetail!.discount! * 100}%`;
  }
  if (book.completed) return 'จบ';
  return undefined;
};

const getBookCoverProps = (
  props: IPropsBookCoverSectionItem,
): {
  imageUrl: string;
  alt: string;
  placeholderColor?: string;

  // infomations
  badgeLabel?: string;
  title: string;
  text1?: string;
  text2?: string;
  meta?: { icon: IconNames; label: string }[];
} => {
  return {
    placeholderColor: props.placeholderBackgroundColor || '',
    imageUrl: props.coverImage || '',
    alt: props.title || '',
    meta: getMeta(props.viewsCount, props.chaptersCount),
    badgeLabel: getBadgeLabel(props),
    title: props.title || '',
    text1: (props.user?.displayName ?? '') + (props.authorName ? ' · ' + props.authorName : '') + (props.translatorName ? ' · ' + props.translatorName : ''),
    text2: getBookCategory(props.categoryTag, props.contentRating),
  };
};

const BookCoverSectionItem = (props: IPropsBookCoverSectionItem): JSX.Element => {
  return (
    <a target="_blank" href={getFictionlogUrl(`/b/${props._id}`)} rel="noreferrer">
      <FeedCoverItem {...getBookCoverProps(props)} />
    </a>
  );
};

export default BookCoverSectionItem;
