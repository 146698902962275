import { useState } from 'react';
import { MdClose } from 'react-icons/md';

import Card from '../../../components/Card';
import UploadFileBox from '../../../components/UploadFileBox';
import { IModalNames } from '../../../core/context/modalReducer'; // Modal management
import { EbookFileType, ReadingType, SubmitReviewEbookEbookFileInput } from '../../../core/graphql/types';
import useModal from '../../../core/hook/context/useModal'; // To handle modal
import useUploadFiles from '../../ebooks/hooks/useUploadFiles';

enum ebookFileKey {
  PDF_MAIN_FILE = 'main-pdf',
  PDF_SAMPLE_FILE = 'sample-pdf',
  EPUB_MAIN_FILE = 'main-epub',
  EPUB_SAMPLE_FILE = 'sample-epub',
}

export const READING_TYPE_ITEMS = [
  { title: 'อ่านจากซ้ายไปขวา', description: 'เหมาะสำหรับอีบุ๊กที่เรียงลำดับหน้าจากซ้ายไปขวา เช่น หนังสือภาษาไทยทั่วไป การ์ตูนไทย', value: ReadingType.LeftToRight },
  { title: 'อ่านจากขวาไปซ้าย', description: 'เหมาะสำหรับอีบุ๊กที่เรียงลำดับหน้าจากขวาไปซ้าย เช่น การ์ตูนญี่ปุ่น การ์ตูนเกาหลี', value: ReadingType.RightToLeft },
  { title: 'อ่านแบบเว็บตูน', description: 'เหมาะสำหรับอีบุ๊กที่เรียงลำดับหน้าจากบนลงล่างอย่างไร้รอยต่อ เช่น เว็บตูน ', value: ReadingType.TopToBottomWithoutSpace },
];

export interface IPropsUploadFilesModal {
  onClose: () => void;
  onConFirm: (files: SubmitReviewEbookEbookFileInput[]) => void;
  // onConFirm: (files: { mainFileUrl: string | null; sampleFileUrl: string | null; readingType?: ReadingType }) => void;
  fileType: EbookFileType; // Dynamic file type based on enum
}

function UploadFilesModal(props: IPropsUploadFilesModal) {
  const { onClose, onConFirm, fileType } = props;
  const { handleUploadFile } = useUploadFiles();
  const { openModal, removeModal } = useModal();

  // State for main and sample files
  const [mainFile, setMainFile] = useState<File | undefined>(undefined);
  const [sampleFile, setSampleFile] = useState<File | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState<string | null>(null); // Error state for validation

  // Determine file extension based on fileType
  const fileExtension = fileType === EbookFileType.Pdf ? '.pdf' : '.epub';

  // Handle file upload and return URLs
  const handleConfirm = async () => {
    if (!mainFile || !sampleFile) {
      setErrorMessage('Please upload both the Main File and the Sample File.');
      return;
    }
    setErrorMessage(null); // Clear the error message if both files are selected

    const uploadFileItems: { key: string; file: File; fileLabel: string }[] = [];
    if (mainFile) {
      uploadFileItems.push({
        key: fileType === EbookFileType.Pdf ? ebookFileKey.PDF_MAIN_FILE : ebookFileKey.EPUB_MAIN_FILE,
        file: mainFile,
        fileLabel: mainFile.name,
      });
    }

    if (sampleFile) {
      uploadFileItems.push({
        key: fileType === EbookFileType.Pdf ? ebookFileKey.PDF_SAMPLE_FILE : ebookFileKey.EPUB_SAMPLE_FILE,
        file: sampleFile,
        fileLabel: sampleFile.name,
      });
    }

    // Open the progress modal and start uploading files
    openModal({
      modalName: IModalNames.PROGRESS_UPLOAD_FILE,
      modalPayload: {
        uploadFileItems, // Files to upload
        onUploadFile: handleUploadFile, // Function to handle file upload
        onUploadFileSuccess: (uploadResponse) => {
          removeModal(IModalNames.PROGRESS_UPLOAD_FILE);

          const formFile: SubmitReviewEbookEbookFileInput[] = [];

          // Handle the PDF file upload
          if (uploadResponse[ebookFileKey.PDF_MAIN_FILE] || uploadResponse[ebookFileKey.PDF_SAMPLE_FILE]) {
            const mainFileData = uploadResponse[ebookFileKey.PDF_MAIN_FILE];
            const sampleFileData = uploadResponse[ebookFileKey.PDF_SAMPLE_FILE];
            formFile.push({
              mainFileUrl: mainFileData?.fileUrl || '',
              mainFilename: mainFileData?.fileName || '',
              sampleFileUrl: sampleFileData?.fileUrl || '',
              sampleFilename: sampleFileData?.fileName || '',
              type: EbookFileType.Pdf,
            });
          }

          // Handle the EPUB file upload
          if (uploadResponse[ebookFileKey.EPUB_MAIN_FILE] || uploadResponse[ebookFileKey.EPUB_SAMPLE_FILE]) {
            const mainFileData = uploadResponse[ebookFileKey.EPUB_MAIN_FILE];
            const sampleFileData = uploadResponse[ebookFileKey.EPUB_SAMPLE_FILE];
            formFile.push({
              mainFileUrl: mainFileData?.fileUrl || '',
              mainFilename: mainFileData?.fileName || '',
              sampleFileUrl: sampleFileData?.fileUrl || '',
              sampleFilename: sampleFileData?.fileName || '',
              type: EbookFileType.Epub,
            });
          }

          if (formFile.length > 0) {
            // Call the confirm handler with the uploaded file data and reading type
            onConFirm(formFile);
          } else {
            return;
          }
        },
        onClose: (abortController) => {
          abortController?.abort(); // Abort the upload if the modal is closed
          removeModal(IModalNames.PROGRESS_UPLOAD_FILE);
        },
      },
    });
  };

  return (
    <div className="modal modal-open !mt-0">
      <div className="modal-box max-w-[960px] max-h-[80vh] overflow-y-scroll">
        {/* // <div className="modal-box max-w-[960px] max-h-[80vh] overflow-y-scroll"> */}
        {/* Close button */}
        <button onClick={onClose} className="rounded-full flex self-end justify-center items-center btn-outline w-[36px] h-[36px]">
          <MdClose size={24} />
        </button>

        {/* Card Section */}
        <Card className="space-y-4 flex flex-col w-full">
          <div className="text-22">
            {fileType === EbookFileType.Pdf ? 'ไฟล์ PDF' : 'ไฟล์ EPUB'}
            <span className="text-colorsBrandRed01Primary"> * </span>
            <span className="label-text-alt text-systemGrays02LabelSecondary font-tt">ไฟล์มีขนาดไม่เกิน 100 MB</span>
          </div>

          {/* Main and Sample File Upload */}
          <div className="flex flex-col w-full">
            <div className="flex-1">
              <UploadFileBox label="Main" acceptFileExtension={fileExtension} onSelectFile={setMainFile} fileName={mainFile?.name} />
            </div>
            <div className="flex-1">
              <UploadFileBox label="Sample" acceptFileExtension={fileExtension} onSelectFile={setSampleFile} fileName={sampleFile?.name} />
            </div>
          </div>

          {/* Only show "Reading Type" if PDF */}
          {/* {fileType === EbookFileType.Pdf && (
            <section className="flex flex-col pt-24">
              <div>รูปแบบการอ่านอีบุ๊ก</div>
              <RadioCardButtonGroup className="mt-4 md:flex md:space-x-24">
                {READING_TYPE_ITEMS.map((readingTypeItem) => (
                  <RadioCardButton
                    className="!mt-16 first:mt-16 md:!mt-0 md:first:mt-0"
                    radioButtonClassname="self-start !mt-4 !ml-0"
                    labelClassName="!px-16 !py-12 !hover:cursor-default !cursor-default"
                    key={readingTypeItem.value}
                    value={readingTypeItem.value}
                    title={readingTypeItem.title}
                    description={readingTypeItem.description}
                    checked={readingTypeItem.value === readingType}
                    onSelected={() => setReadingType(readingTypeItem.value)}
                  />
                ))}
              </RadioCardButtonGroup>
              <Typography typography={TYPOGRAPHY_TYPES.CAPTION_1_TT} className="text-colorsBrandWarmBlack02LabelSecondary mt-4">
                คำเตือน : หากคุณเลือกอ่านแบบเว็บตูน นักอ่านจะไม่สามารถปรับรูปแบบการอ่านเป็นซ้ายไปขวา หรือขวาไปซ้ายได้
              </Typography>
            </section>
          )} */}

          {/* Error message */}
          {errorMessage && <div className="text-red-600">{errorMessage}</div>}
        </Card>

        {/* Action Buttons */}
        <div className="w-full flex justify-end space-x-6 pt-24">
          <button className="btn btn-outline btn-error " onClick={onClose}>
            Cancel
          </button>
          <button className="btn btn-error " onClick={handleConfirm}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
}

export default UploadFilesModal;
