/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const filter = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path
      fill="#8C8C8C"
      fillRule="evenodd"
      d="M5.242 15a4.244 4.244 0 01-.036-5.963L5.242 9h37.516l.036.037A4.244 4.244 0 0142.758 15H5.242zm4.69 12a5.002 5.002 0 01-.035-5.953L9.931 21H38.07l.034.047A5.002 5.002 0 0138.069 27H9.93zm6.182 12a4.635 4.635 0 01-.035-5.958l.035-.042H31.89l.035.043A4.648 4.648 0 0131.89 39H16.114z"
    />
  </svg>
);

export default filter;
