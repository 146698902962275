/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const settings = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path
      fill="#8C8C8C"
      fillRule="evenodd"
      d="M19.667 39.406a15.902 15.902 0 01-3.496-1.449l-5.294 2.996a1 1 0 01-1.2-.163l-2.458-2.458a1 1 0 01-.164-1.198l2.99-5.301a15.902 15.902 0 01-1.451-3.5l-5.861-1.625A1 1 0 012 25.744V22.27a1 1 0 01.731-.964l5.861-1.634a15.902 15.902 0 011.451-3.5l-2.996-5.294a1 1 0 01.163-1.2L9.668 7.22a1 1 0 011.198-.164l5.301 2.99a15.902 15.902 0 013.5-1.451l1.625-5.861A1 1 0 0122.256 2h3.475a1 1 0 01.964.731l1.634 5.861c1.235.347 2.409.837 3.504 1.453l5.3-2.99a1 1 0 011.199.164l2.458 2.458a1 1 0 01.163 1.2l-2.996 5.294a15.902 15.902 0 011.45 3.5l5.862 1.634a1 1 0 01.731.964v3.475a1 1 0 01-.733.964l-5.86 1.625a15.902 15.902 0 01-1.452 3.5l2.99 5.3a1 1 0 01-.164 1.199l-2.458 2.458a1 1 0 01-1.2.163l-5.294-2.996a15.902 15.902 0 01-3.5 1.45l-1.634 5.862a1 1 0 01-.964.731h-3.475a1 1 0 01-.964-.733l-1.625-5.86zM24 30a6 6 0 100-12 6 6 0 000 12z"
    />
  </svg>
);

export default settings;
