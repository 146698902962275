/* eslint-disable no-useless-escape */
import * as yup from 'yup';
import { AnySchema } from 'yup';
import Lazy from 'yup/lib/Lazy';
import Reference from 'yup/lib/Reference';

import { IErrorObject } from '../../../utils/validator';

export enum FEED_BUILDER_FIELD_KEYS {
  SLUG = 'slug',
  PREF = 'pref',
  OG_TITLE = 'ogTitle',
  OG_DESCRIPTION = 'ogDescription',
  OG_IMAGE = 'ogImage',
  FEED_ITEMS = 'feedItems',
}

export interface IErrorsFeedBuilder {
  slug?: IErrorObject;
  pref?: IErrorObject;
  ogTitle?: IErrorObject;
  ogDescription?: IErrorObject;
  ogImage?: IErrorObject;
  feedItems?: IErrorObject;
}

export type IFeedBuilderFields = keyof IErrorsFeedBuilder;

const FeedBuilderFormObj: { [key in IFeedBuilderFields]: AnySchema<unknown, unknown, unknown> | Reference<unknown> | Lazy<never, unknown> } = {
  slug: yup.string().required().max(40).trim(),
  pref: yup.string().required(),
  ogTitle: yup.string().max(120).trim(),
  ogDescription: yup.string().max(250).trim(),
  ogImage: yup
    .string()
    .trim()
    .test('format', 'format', (val) => ['.png', '.jpeg'].some((ext) => val?.endsWith(ext))),

  feedItems: yup.array().of(
    yup.object().shape({
      id: yup.string().trim(),
      note: yup.string(),
    }),
  ),
};

export default FeedBuilderFormObj;
