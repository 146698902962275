import { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';

import SectionListSorting from '../../app/sections/components/SectionListSorting';
import SectionListTable, { SectionItem } from '../../app/sections/components/SectionListTable';
import { SectionsFilterSortBy } from '../../core/graphql/types';
import useMutationPintoDeleteSection from '../../hooks/useMutationPintoDeleteSection';
import useQueryPintoSections from '../../hooks/useQueryPintoSections';
import { getDayTime } from '../../utils/dayjs';

const SectionsPage = () => {
  const { fetchPintoSections, sections, isLoadingPintoSections } = useQueryPintoSections();
  const { deletePintoSection } = useMutationPintoDeleteSection();
  const [sectionList, setSectionList] = useState<SectionItem[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [sorting, setSorting] = useState<SectionsFilterSortBy>();

  const handleChangePage = useCallback(
    (page: number) => {
      const offset = (page - 1) * 10;
      setCurrentPage(page);
      fetchPintoSections({ offset });
    },
    [fetchPintoSections],
  );

  const handleDeleteSection = useCallback(
    (id: string) => {
      deletePintoSection(id);
    },
    [deletePintoSection],
  );

  useEffect(() => {
    if (sections && sections.edges.length > 0) {
      const _sectionList: SectionItem[] = sections.edges.map(({ node }) => ({
        _id: node._id,
        lastUpdated: getDayTime(node.updatedAt),
        name: node.note || '',
        isNCContent: node.isNCContent,
      }));
      setSectionList(_sectionList);
    }
  }, [sections]);

  useEffect(() => {
    fetchPintoSections({ sortBy: sorting });
  }, [fetchPintoSections, sorting]);

  return (
    <div className="mx-auto">
      {/* section: title */}
      <div className="py-5 flex justify-between">
        <h1 className="font-dbh text-[28px]">Sections</h1>

        <Link to="/feedAndSection/sections/edit">
          <button className="btn btn-error">Create New Section</button>
        </Link>
      </div>

      <div>
        <div className="pb-5">
          <SectionListSorting onSortingChange={setSorting} />
        </div>
        {isLoadingPintoSections ? (
          <div className="w-full btn btn-outline btn-disabled bg-systemGrays04LabelQuaternary border-none loading h-[100px]" />
        ) : (
          <SectionListTable
            sectionList={sectionList}
            currentPage={currentPage}
            totalSections={sections?.totalCount || 0}
            onChangePage={handleChangePage}
            onDeleteSection={handleDeleteSection}
          />
        )}
      </div>
    </div>
  );
};

export default SectionsPage;
