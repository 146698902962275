import { useCallback } from 'react';

import { usePintoEbookDiscountCampaignLazyQuery } from '../core/graphql/operation-hooks';

const useQueryPintoEbookDiscountCampaign = () => {
  const [queryPintoEbookDiscountCampaign, { data, loading }] = usePintoEbookDiscountCampaignLazyQuery({ fetchPolicy: 'network-only' });

  const fetchPintoEbookDiscountCampaign = useCallback(
    async (ebookDiscountCampaignId: string) => {
      await queryPintoEbookDiscountCampaign({
        variables: {
          ebookDiscountCampaignId,
        },
      });
    },
    [queryPintoEbookDiscountCampaign],
  );

  return {
    fetchPintoEbookDiscountCampaign,
    ebookDiscountCampaign: data?.ebookDiscountCampaign,
    isLoadingPintoEbookDiscountCampaign: loading,
  };
};

export default useQueryPintoEbookDiscountCampaign;
