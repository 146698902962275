import { debounce } from 'lodash';
import { ChangeEvent, useCallback } from 'react';
import { FiSearch } from 'react-icons/fi';

import Button from '../../../components/Button';
import { EbookDiscountCampaignStatus } from '../../../core/graphql/types';

interface IPropsFilterAndSearchPresenter {
  status: EbookDiscountCampaignStatus | null;
  statusOptions?: { label: string; value: EbookDiscountCampaignStatus | null }[];
  onSetStatus: (status: EbookDiscountCampaignStatus | null) => void;
  onSetSearchText: (text?: string) => void;
}

const FilterAndSearchPresenter = ({ status, statusOptions, onSetStatus, onSetSearchText }: IPropsFilterAndSearchPresenter) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChangeKeyWord = useCallback(
    debounce((e: ChangeEvent<HTMLInputElement>) => {
      onSetSearchText(e.target.value);
    }, 500),
    [],
  );

  return (
    <div className="flex items-center justify-between">
      <div className="space-x-4">
        {statusOptions?.map((option) => (
          <Button
            round
            type={option.value === status ? 'fill' : 'outline'}
            key={option.value}
            color={option.value === status ? 'red' : 'gray'}
            onClick={() => onSetStatus(option.value)}
            className="h-[40px]"
          >
            {option.label}
          </Button>
        ))}
      </div>

      <div className="relative">
        <FiSearch className="absolute top-[16px] left-[12px] text-colorsBrandWarmBlack02LabelSecondary text-18" />
        <input type="text" placeholder="search title" className="w-[300px] pl-36 input" onChange={onChangeKeyWord} />
      </div>
    </div>
  );
};

export default FilterAndSearchPresenter;
