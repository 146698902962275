/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const iconLayoutCover = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path
      fill="#8A8A8E"
      fillRule="evenodd"
      d="M10 3a1 1 0 011 1v16a1 1 0 01-1 1H4a1 1 0 01-1-1V4a1 1 0 011-1h6zm10 0a1 1 0 011 1v16a1 1 0 01-1 1h-6a1 1 0 01-1-1V4a1 1 0 011-1h6z"
    />
  </svg>
);

export default iconLayoutCover;
