/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const invoice = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="18"
    viewBox="0 0 19 18"
  >
    <g fill="none" fillRule="evenodd">
      <g fill="#FFFFFE">
        <g>
          <g>
            <g>
              <g>
                <path
                  d="M10.426 4.165H7.787c-.082 0-.15-.066-.15-.15V1.378c0-.04.017-.078.045-.106.059-.06.153-.06.212 0l2.638 2.638c.028.027.044.066.044.105 0 .084-.068.15-.15.15zm-.849 4.377h-7.34c-.417 0-.755-.336-.755-.75s.338-.751.754-.751h7.341c.417 0 .755.337.755.751s-.338.75-.755.75zm0 3h-7.34c-.417 0-.755-.336-.755-.75s.338-.751.754-.751h7.341c.417 0 .755.337.755.751s-.338.75-.755.75zm0 3h-7.34c-.417 0-.755-.336-.755-.75s.338-.751.754-.751h7.341c.417 0 .755.337.755.751s-.338.75-.755.75zM2.23 4.041h1.954c.413 0 .748.337.748.75 0 .415-.335.751-.748.751H2.23c-.413 0-.748-.336-.748-.75s.335-.751.748-.751zm9.584-.438c-.002-.125-.05-.24-.143-.335C8.334-.065 8.551.106 8.46.057c-.055-.03-.12-.049-.186-.055C8.235 0 8.706 0 .97 0c-.534 0-.97.433-.97.967v14.566c0 .533.436.967.97.967h9.877c.533 0 .968-.434.968-.967V3.603z"
                  transform="translate(-751 -705) translate(728 695) translate(23.5 8) translate(0 2) translate(3 .75)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default invoice;
