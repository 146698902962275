import { useCallback } from 'react';

import { usePintoProductSetLazyQuery } from '../core/graphql/operation-hooks';

const useQueryPintoProductSet = () => {
  const [queryPintoProductSet, { data, loading }] = usePintoProductSetLazyQuery({ fetchPolicy: 'cache-and-network' });

  const fetchPintoProductSet = useCallback(
    (productSetId: string) => {
      queryPintoProductSet({
        variables: {
          productSetId,
        },
      });
    },
    [queryPintoProductSet],
  );

  return { fetchPintoProductSet, dataPintoProductSet: data?.pintoProductSet, isLoadingPintoProductSet: loading };
};
export default useQueryPintoProductSet;
