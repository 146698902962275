/* eslint-disable no-useless-escape */
import * as yup from 'yup';
import { AnySchema } from 'yup';
import Lazy from 'yup/lib/Lazy';
import Reference from 'yup/lib/Reference';

import { EbookFileType, EbookWritingType, ReadingType } from '../../../core/graphql/types';
import { IErrorObject } from '../../../utils/validator';
import { DISCOUNT_VALUE } from '../../modals/setPriceModal/SetPriceModal';

export enum EBOOK_BUILDER_FIELD_KEYS {
  USER_ID = 'userId',

  // Price setting
  ORIGINAL_PRICE_THB = 'originalPriceThb',
  GOOGLE_ORIGINAL_PRICE_TIER_ID = 'googleOriginalPriceTierId',
  APPLE_ORIGINAL_PRICE_TIER_ID = 'appleOriginalPriceTierId',
  IS_FREE = 'isFree',
  HAVE_DISCOUNT = 'haveDiscount',
  DISCOUNT_TYPE = 'discountType',
  DISCOUNT_PRICE = 'discountPrice',
  DISCOUNT_RATIO = 'discountRatio',
  IS_HAS_DISCOUNT_END_DATE = 'isHasDiscountEndDate',
  DISCOUNT_START_DATE = 'discountStartDate',
  DISCOUNT_END_DATE = 'discountEndDate',
  DISCOUNT_GOOGLE_PRICE_TIER_ID = 'discountGooglePriceTierId',
  DISCOUNT_APPLE_PRICE_TIER_ID = 'discountApplePriceTierId',
  // Image
  COVER_IMAGE_URL = 'coverImageUrl',
  BANNER_IMAGE_URL = 'bannerImageUrl',

  // Ebook Detail
  TITLE = 'title',
  DESCRIPTION = 'description',
  SYNOPSIS = 'synopsis',
  CATEGORY_ID = 'categoryId',
  WRITING_TYPE = 'writingType',
  IS_CARTOON_TYPE = 'isCartoonType',
  ORIGINAL_LANGUAGE_CODE = 'originalLanguageCode',
  LANGUAGE_CODE = 'languageCode',
  AUTHOR_NAMES = 'authorNames',
  TRANSLATOR_NAMES = 'translatorNames',
  TAG_NAMES = 'tagNames',

  // Additional Info
  ISBN = 'isbn',
  HAVE_COVER_PRICE = 'haveCoverPrice',
  COVER_PRICE_THB = 'coverPriceThb',
  // Product Set
  PRODUCT_SET_ID = 'productSetId',
  PRODUCT_SET_HAS_ITEM_VOL = 'productSetHasItemVol',
  PRODUCT_SET_VOL = 'productSetVol',

  // File
  FILES = 'files',

  // Schedule
  PUBLISHED_AT = 'publishedAt',

  READING_TYPE = 'readingType',

  // subscription
  SUBSCRIPTION_TITLE_ID = 'subscriptionTitleId',
}

export interface IErrorsEbookBuilder {
  userId?: IErrorObject;

  // image
  coverImageUrl?: IErrorObject;
  bannerImageUrl?: IErrorObject;

  // ebook detail
  title?: IErrorObject;
  description?: IErrorObject;
  synopsis?: IErrorObject;
  categoryId?: IErrorObject;
  writingType?: IErrorObject;
  isCartoonType?: IErrorObject;
  originalLanguageCode?: IErrorObject;
  languageCode?: IErrorObject;
  authorNames?: IErrorObject;
  translatorNames?: IErrorObject;
  tagNames?: IErrorObject;

  // additional info
  isbn?: IErrorObject;
  haveCoverPrice?: IErrorObject;
  coverPriceThb?: IErrorObject;

  // files
  files?: IErrorObject;

  // pricing
  originalPriceThb?: IErrorObject;
  isFree?: IErrorObject;
  // in-app pricing
  googleOriginalPriceTierId?: IErrorObject;
  appleOriginalPriceTierId?: IErrorObject;
  // discount
  haveDiscount?: IErrorObject;
  discountType?: IErrorObject;
  discountPrice?: IErrorObject;
  discountRatio?: IErrorObject;
  discountGooglePriceTierId?: IErrorObject;
  discountApplePriceTierId?: IErrorObject;
  discountStartDate?: IErrorObject;
  discountEndDate?: IErrorObject;
  // product set
  productSetId?: IErrorObject;
  productSetHasItemVol?: IErrorObject;
  productSetVol?: IErrorObject;

  // Schedule
  publishedAt?: IErrorObject;
  isHasDiscountEndDate?: IErrorObject;

  readingType?: IErrorObject;

  subscriptionTitleId?: IErrorObject;
}

export type IEbookBuilderFields = keyof IErrorsEbookBuilder;

const EbookBuilderFormObj: { [key in IEbookBuilderFields]: AnySchema<unknown, unknown, unknown> | Reference<unknown> | Lazy<never, unknown> } = {
  userId: yup.string().required(),

  // image
  coverImageUrl: yup.string().trim(),
  bannerImageUrl: yup.string().trim(),

  // ebook detail
  title: yup.string().max(150).trim().required(),
  description: yup.string().min(50, 'กรุณาใส่คำโปรยอย่างน้อย 50 ตัวอักษร').max(200).trim().required(),
  // synopsis default count = 2 <==> const contentLength = editorState?.doc?.content?.size ?? 0; => set min = 3
  synopsis: yup.number().min(3, 'กรุณาระบุรายละเอียดเรื่องย่อ').max(5000, ''),
  categoryId: yup.string().required('กรุณาเลือกหมวดหมู่'),
  writingType: yup.string().nullable().default(EbookWritingType.Bundle),
  isCartoonType: yup.boolean().default(false),
  originalLanguageCode: yup.string().trim().default('THA'),
  languageCode: yup.string().trim().default('THA'),
  tagNames: yup.array().of(
    yup.object().shape({
      id: yup.string().trim(),
      name: yup.string(),
    }),
  ),
  authorNames: yup
    .array()
    .of(yup.string().trim())
    .test('required at least 1 person', 'ผลงานดีๆ ต้องมีผู้เขียน โปรดใส่อย่างน้อย 1 คน', (value) => {
      return value?.some((v = '') => v.length > 0) ?? false;
    })
    .default(['']),
  translatorNames: yup.array().default(['']),

  // pricing
  isFree: yup.boolean(),
  originalPriceThb: yup
    .number()
    .when(['isFree'], {
      is: (isFree: boolean) => !isFree,
      then: (schema) => schema.required('กรุณากรอกราคาอีบุ๊ก'),
    })
    .when(['isFree'], {
      is: (isFree: boolean) => isFree,
      then: (schema) => schema.nullable(),
    }),
  googleOriginalPriceTierId: yup.string().nullable(),
  appleOriginalPriceTierId: yup.string().nullable(),
  haveDiscount: yup.boolean(),
  discountType: yup.string().when(['haveDiscount'], {
    is: (haveDiscount: boolean) => haveDiscount,
    then: (schema) => schema.required('กรุณาเลือกประเภทส่วนลด'),
  }),
  discountPrice: yup.number().when(['haveDiscount', 'discountType'], {
    is: (haveDiscount: boolean, discountType: string) => haveDiscount && discountType === DISCOUNT_VALUE.SPECIFY_PRICE,
    then: (schema) => schema.required('กรุณากรอกส่วนลด'),
  }),
  discountRatio: yup.number().when(['haveDiscount', 'discountType'], {
    is: (haveDiscount: boolean, discountType: string) => haveDiscount && discountType === DISCOUNT_VALUE.PERCENT_DISCOUNT,
    then: (schema) => schema.required('กรุณากรอกส่วนลด'),
  }),

  discountGooglePriceTierId: yup.string().nullable(),
  discountApplePriceTierId: yup.string().nullable(),
  discountStartDate: yup.date(),
  discountEndDate: yup.date().nullable(),

  // additional info
  isbn: yup
    .string()
    .nullable()
    .test('len', 'Length must be less than or equals 13 characters', (val) => (val ? val.length <= 13 && /^[0-9]+$/.test(val) : true)),

  haveCoverPrice: yup.boolean().default(false),
  coverPriceThb: yup
    .number()
    .nullable()
    .typeError('ระบุราคาปก')
    .when('haveCoverPrice', {
      is: (haveCoverPrice: boolean) => haveCoverPrice,
      then: (schema) =>
        schema.required('ระบุราคาปก').min(1, 'กรุณากำหนดราคาขายอีบุ๊กขั้นต่ำ 1 แต่ไม่เกิน 5,000 บาท').max(5000, 'กรุณากำหนดราคาขายอีบุ๊กขั้นต่ำ 1 แต่ไม่เกิน 5,000 บาท'),
      otherwise: (schema) =>
        schema.notRequired().min(1, 'กรุณากำหนดราคาขายอีบุ๊กขั้นต่ำ 1 แต่ไม่เกิน 5,000 บาท').max(5000, 'กรุณากำหนดราคาขายอีบุ๊กขั้นต่ำ 1 แต่ไม่เกิน 5,000 บาท'),
    }),

  productSetId: yup.string().nullable().default(null),
  productSetHasItemVol: yup.boolean().default(false),
  productSetVol: yup
    .number()
    .nullable()
    .typeError('กรุณาระบุลำดับเล่ม')
    .when(['productSetId', 'productSetHasItemVol'], {
      is: (productSetId: string, productSetHasItemVol: boolean) => productSetId?.length > 0 && productSetHasItemVol,
      then: (schema) => schema.required('กรุณาระบุลำดับเล่ม').min(1, 'ไม่สามารถระบุลำดับเล่มน้อยกว่า 1 ได้ ').max(500, 'ไม่สามารถระบุลำดับเล่มมากกว่า 500 ได้ '),
    }),

  // files
  files: yup
    .array()
    .of(
      yup.object().shape({
        mainFileUrl: yup.string().trim(),
        mainFilename: yup.string().trim(),
        sampleFileUrl: yup.string().trim(),
        sampleFilename: yup.string().trim(),
        type: yup.string().oneOf(Object.values(EbookFileType)),
      }),
    )
    .min(1)
    .test('required at least one file', 'required at least one file', (value) => {
      return !!value?.every((_value) => _value.mainFilename && _value.sampleFilename);
    }),
  // product set volume

  // schedule
  publishedAt: yup.date(),
  isHasDiscountEndDate: yup.boolean(),

  readingType: yup.string().oneOf(Object.values(ReadingType)).default(ReadingType.LeftToRight),

  subscriptionTitleId: yup.string().nullable(),
};

export default EbookBuilderFormObj;
