import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import ToastComponent, { IToastType } from '../components/Toast/ToastComponent';
import { IModalNames } from '../core/context/modalReducer';
import { useEditPintoSubscriptionTitleMutation } from '../core/graphql/operation-hooks';
import { EditPintoSubscriptionTitleInput } from '../core/graphql/types';
import useModal from '../core/hook/context/useModal';

export enum RETURN_STATUS {
  SUCCESS = 'success',
  ERROR = 'error',
  DUPLICATE_TITLE = 'title_unavailable',
}

const useMutationEditPintoSubscriptionTitle = () => {
  const [mutateEditPintoSubscriptionTitle, { loading }] = useEditPintoSubscriptionTitleMutation({ errorPolicy: 'all' });
  const { openModal, removeModal } = useModal();
  const router = useHistory();

  const editPintoSubscriptionTitle = useCallback(
    async (subscriptionTitleId: string, input: EditPintoSubscriptionTitleInput) => {
      const res = await mutateEditPintoSubscriptionTitle({ variables: { subscriptionTitleId, input } });

      if (res.errors) {
        const [editError] = res.errors;
        const editErrorMessage = editError.message;
        switch (editErrorMessage) {
          case 'title_unavailable':
            ToastComponent({ label: 'มีหัวหนังสือนี้แล้ว', type: IToastType.ERROR });
            return RETURN_STATUS.DUPLICATE_TITLE;
          default:
            ToastComponent({ label: 'เกิดข้อผิดพลาดในการทำรายการ กรุณาลองใหม่อีกครั้ง', type: IToastType.ERROR });
            return RETURN_STATUS.ERROR;
        }
      } else if (res.data?.editPintoSubscriptionTitle._id) {
        openModal({
          modalName: IModalNames.CONFIRM,
          modalPayload: {
            title: 'บันทึกสำเร็จ',
            onConfirm: () => {
              router.push(`/subscriptions`);
              removeModal(IModalNames.CONFIRM);
            },
          },
        });
      }
      return RETURN_STATUS.SUCCESS;
    },
    [mutateEditPintoSubscriptionTitle, openModal, removeModal, router],
  );

  return { editPintoSubscriptionTitle, isLoadingEditPintoSubscriptionTitle: loading };
};

export default useMutationEditPintoSubscriptionTitle;
