import { Control, FieldValues, UseFormRegister } from 'react-hook-form';

import ArraysInput from '../../../components/form/ArraysInput';
import Input from '../../../components/form/Input';
import RadioGroupButton from '../../../components/form/RadioGroupButton';
import { EBOOK_HAS_DISCOUNT, EBOOK_TYPE_OPTIONS, IConfigItemType, IS_FREE_OPTIONS, RATING_OPTIONS, SORT_BY_QUERY_EBOOK_OPTIONS } from '../validates/sectionBuilderSchema';

import DropdownSelectNested from './DropdownSelectNested';
import RenderObjectLabel from './RenderObjectLabel';
import SelectDiscountSetting from './SelectDiscountSetting';
import SelectEbookPriceType from './SelectEbookPriceType';
import SelectPintoCategoryWrapper from './SelectPintoCategory';

//TODO: continue implements
export interface IPropsQueryEbookItem {
  type: IConfigItemType.QueryEbook;
  categoryId: number;
  gteRating?: number;
  //replace boolean with string (useHookForm save string)
  hasDiscount?: string;
  gteDiscountRatio?: number;
  lteDiscountRatio?: number;
  anyTypes?: string[];
  anyUserIds?: string[];
  whitelistIds?: string[];
  blacklistIds?: string[];
  tagNames?: string[];
  anyTagNames?: string[];
  isFree?: string;
  sortType?: 'asc' | 'desc';
  sortBy?:
    | ''
    | 'relevant'
    | 'viewsCount'
    | 'published'
    | 'created'
    | 'trending'
    | 'popular'
    | 'rating'
    | 'bestseller'
    | 'bestseller7Days'
    | 'bestseller30Days'
    | 'priceThb'
    | 'title'
    | 'random';
  limit?: number;
}

interface IPropsQueryEbookSectionItem {
  index: number;
  itemData: IPropsQueryEbookItem;
  control: Control<FieldValues, any>;
  register: UseFormRegister<FieldValues>;
}

const QueryEbookSectionItem = (props: IPropsQueryEbookSectionItem) => {
  const { index, itemData, control, register } = props;

  return (
    <div className="relative flex justify-between space-x-16">
      <div className="w-full collapse">
        <input type="checkbox" className="peer" />
        <div className=" collapse-title bg-systemGrays08FillQuaternary text-systemGrays01LabelPrimary peer-checked:bg-systemGrays08FillQuaternary peer-checked:text-systemGrays01LabelPrimary flex justify-between after:content-['+'] peer-checked:after:content-['-'] ease-out duration-300">
          <span>
            <span>Query Ebook </span>
            <RenderObjectLabel objectLists={[{ key: 'CategoryId', value: itemData?.categoryId, required: true }]} />
          </span>
        </div>
        <div className="collapse-content text-systemGrays01LabelPrimary bg-systemGrays08FillQuaternary !peer-checked:bg-systemGrays08FillQuaternary peer-checked:text-systemGrays01LabelPrimary">
          <div className="space-y-8">
            <SelectPintoCategoryWrapper name={`configItems.${index}.categoryId`} categoryId={itemData?.categoryId} />

            <Input
              type="number"
              register={() => register(`configItems.${index}.limit`)}
              label="limit"
              name="queryEbookSectionItemLimit"
              helperText="Input number of item to show, limit at 100 items"
              placeholder="limit"
              min="0"
              max="100"
            />

            <DropdownSelectNested
              label="SortBy"
              options={SORT_BY_QUERY_EBOOK_OPTIONS}
              prefixName={`configItems.${index}`}
              name={[`configItems.${index}.sortBy`, `configItems.${index}.sortType`]}
            />

            <RadioGroupButton label="Rating" options={RATING_OPTIONS} register={register} name={`configItems.${index}.gteRating`} />

            <SelectEbookPriceType label="IsFree" options={IS_FREE_OPTIONS} prefixName={`configItems.${index}`} name={[`configItems.${index}.isFree`]} />

            {!itemData.isFree && (
              <>
                <SelectDiscountSetting options={EBOOK_HAS_DISCOUNT} prefixName={`configItems.${index}`} name={[`configItems.${index}.hasDiscount`]} label={'HasDiscount'} />

                {itemData.hasDiscount && (
                  <div className="flex items-center justify-between gap-x-8">
                    <Input
                      type="number"
                      register={() => register(`configItems.${index}.gteDiscountRatio`)}
                      label="min_DiscountId"
                      name="minDiscountId"
                      helperText="limited 0-50"
                      placeholder="min_DiscountId"
                      className="flex-1"
                      min="0"
                      max="50"
                    />
                    <span>-</span>
                    <Input
                      type="number"
                      register={() => register(`configItems.${index}.lteDiscountRatio`)}
                      label="max_DiscountId"
                      name="maxDiscountId"
                      helperText="limited 0-50"
                      placeholder="max_DiscountId"
                      className="flex-1"
                      min="0"
                      max="50"
                    />
                  </div>
                )}
              </>
            )}

            <RadioGroupButton isSetArrayValue options={EBOOK_TYPE_OPTIONS} name={`configItems.${index}.anyTypes`} register={register} label={'EbookType'} />

            <div>
              <ArraysInput control={control} name={`configItems.${index}.anyUserIds`} label="UserIds" helperText="Input User ID" placeholder="userIds" />
              <ArraysInput control={control} name={`configItems.${index}.whitelistIds`} label="whitelistIds" helperText="Input ebook ID" placeholder="whitelistIds" />
              <ArraysInput control={control} name={`configItems.${index}.blacklistIds`} label="blacklistIds" helperText="Input ebook ID" placeholder="blacklistIds" />
              <ArraysInput control={control} name={`configItems.${index}.tagNames`} label="tagNames" helperText="Input Tags" placeholder="tagNames" />
              <ArraysInput control={control} name={`configItems.${index}.anyTagNames`} label="anyTagNames" helperText="Input Tags" placeholder="anyTagNames" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QueryEbookSectionItem;
