/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const send = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path
      fill="#8C8C8C"
      fillRule="evenodd"
      d="M7.237 5.826a1 1 0 011.421-1.035l38.476 18.306a1 1 0 010 1.806L8.658 43.208a1 1 0 01-1.42-1.034l1.82-13.704a1 1 0 01.792-.849L27.687 24 9.852 20.386a1 1 0 01-.793-.848L7.237 5.826z"
    />
  </svg>
);

export default send;
