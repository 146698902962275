import classNames from 'classnames';
import { Ref, useMemo } from 'react';
import { FieldValues, UseFormRegister } from 'react-hook-form';

export interface IDropdownItem {
  label: string;
  value?: string | null;
}

export interface IPropsDropdown {
  className?: string;
  isInvalid?: boolean;
  label?: string;
  name: string;
  placeholder?: string;
  register?: UseFormRegister<FieldValues>;
  required?: boolean;
  ref?: Ref<HTMLUListElement>;
  currentOption?: string;
  dropdownList?: IDropdownItem[];
  handleSelectDropdown?: (option?: string | null) => void;
}

function Dropdown(props: IPropsDropdown) {
  const { className, label, dropdownList = [], required, ref, currentOption = undefined, handleSelectDropdown, placeholder } = props;

  const selectedOption = useMemo(() => {
    if (currentOption && dropdownList) {
      return dropdownList.find((item) => item.value === currentOption);
    }
  }, [currentOption, dropdownList]);
  return (
    <div className={classNames('form-control flex', className)}>
      {label && (
        <label className="label">
          <span className="label-text text-systemGrays02LabelSecondary">
            {label} {required && <span className="text-colorsBrandRed01Primary">*</span>}
          </span>
        </label>
      )}
      <div>
        <div className="dropdown border rounded-md w-full">
          <label tabIndex={0} className="btn btn-ghost rounded-btn w-full justify-start text-systemGrays02LabelSecondary">
            {selectedOption?.label || currentOption || placeholder}
          </label>
          <ul ref={ref} tabIndex={0} className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-full">
            {dropdownList.map((item: IDropdownItem) => {
              return (
                <li key={item.label}>
                  <a onClick={() => handleSelectDropdown?.(item.value)}>{item.label}</a>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Dropdown;
