import classNames from 'classnames';
import dayjs from 'dayjs';
import { filter, some } from 'lodash';
import { useCallback, useState } from 'react';
import DatePicker from 'react-datepicker';
import { Link, useHistory } from 'react-router-dom';

import EndOfChapterCalendar from '../../../app/endOfChapter/components/EndOfChapterCalendar';
import EndOfChapterForm from '../../../app/endOfChapter/components/EndOfChapterForm';
import useFormSections from '../../../app/endOfChapter/hooks/useFormSections';
import SelectSectionModal from '../../../app/endOfChapter/modals/SelectSectionModal';
import PreviewSectionModal from '../../../app/modals/PreviewSectionModal';
import { TemplateFilter, TemplatePlacement } from '../../../core/graphql/types';
import useMutateUpsertTemplate from '../../../hooks/useMutateUpsertTemplate';
import useQueryTemplateByQuery from '../../../hooks/useQueryTemplateByQuery';
import { useQueryString } from '../../../utils/router';
import { getProductShortName } from '../../../utils/string';

const EditEndOfChapter = (): JSX.Element => {
  const queryString = useQueryString();
  const history = useHistory();
  const contentType = queryString.get('contentType') as TemplateFilter['contentType'];
  const productType = queryString.get('productType') as TemplateFilter['productType'];
  const categoryTagId = queryString.get('categoryTagId') as TemplateFilter['categoryTagId'];
  const { isLoadingTemplateByQuery, templateByQuery } = useQueryTemplateByQuery({
    placement: TemplatePlacement.Product,
    categoryTagId,
    contentType,
    productType,
  });

  const { formSections, onEditSection, onCreateSection, onDeleteSection, onUpdateScheduleInSection, onDeleteScheduleInSection, onCreateScheduleInSection } = useFormSections({
    templateByQuery,
  });

  const { mutateUpsertTemplate, isLoadingMutateUpsertTemplate } = useMutateUpsertTemplate();

  const [previewDate, setPreviewDate] = useState<Date>(dayjs().startOf('day').toDate());
  const [previewSectionIds, setPreviewSectionIds] = useState<string[] | undefined>(undefined);
  const [isNCPreview, setIsNCPreview] = useState<boolean | undefined>(undefined);

  const onOpenModalPreview = useCallback(
    (sectionId: string) => {
      setPreviewSectionIds([sectionId]);
    },
    [setPreviewSectionIds],
  );

  const onOpenModalPreviewOnDate = useCallback(() => {
    const sectionsOnDate = filter(
      formSections,
      (section) =>
        section.isActivated &&
        (isNCPreview === undefined || section.isNCContent === isNCPreview) &&
        ((section.schedules ?? []).length === 0 ||
          some(section.schedules, (schedule) => dayjs(previewDate).startOf('day').isBetween(dayjs(schedule.start).add(-1, 'day'), dayjs(schedule.end)))),
    );
    if (sectionsOnDate.length) setPreviewSectionIds(sectionsOnDate.map((section) => section._id));
  }, [formSections, isNCPreview, previewDate]);

  const [isOpenSelectModal, setIsOpenSelectModal] = useState<boolean>(false);

  const onOpenSelectSectionModal = useCallback(() => {
    setIsOpenSelectModal(true);
  }, []);

  const onCloseSelectSectionModal = useCallback(() => {
    setIsOpenSelectModal(false);
  }, []);

  if (!contentType || !productType || !categoryTagId) {
    return <h1>not found</h1>;
  }

  return (
    <div>
      <div className="breadcrumbs">
        <ul>
          <li>
            <Link to="/suggestProducts/endOfChapter">
              <h1 className="py-5 font-dbh text-[28px]">End of chapter</h1>
            </Link>
          </li>
          <li>
            <h2 className="py-5 font-dbh text-[20px]">
              {getProductShortName(productType, contentType)} - {templateByQuery?.categoryTag?.name}
            </h2>
          </li>
        </ul>
      </div>

      {isLoadingTemplateByQuery && <div className="w-full btn btn-outline btn-disabled bg-componentsBgGrouped02 border-none loading h-[100px]" />}

      {!isLoadingTemplateByQuery && (
        <>
          <div className="flex flex-col p-16 xl:p-32 xl:flex-row bg-componentsBgGrouped02">
            <div className="w-full pb-32 xl:w-2/5">
              <div className="flex flex-col justify-start pb-16 space-x-0 space-y-24 sm:space-x-24 sm:items-end sm:flex-row">
                <div className="flex flex-col">
                  <div className="mr-8 label">Preview:</div>
                  <div className="btn-group">
                    <button
                      className={classNames('btn btn-sm', { 'bg-colorsBrandOrange01Primary text-white': isNCPreview === undefined })}
                      onClick={() => setIsNCPreview(undefined)}
                    >
                      All
                    </button>
                    <button className={classNames('btn btn-sm', { 'bg-colorsBrandOrange01Primary text-white': isNCPreview === false })} onClick={() => setIsNCPreview(false)}>
                      No NC
                    </button>
                  </div>
                </div>
                <div className="flex relative w-[250px]">
                  <DatePicker
                    selected={previewDate}
                    dateFormat="dd/MM/yyyy"
                    onChange={(date) => {
                      if (date instanceof Date) {
                        setPreviewDate(date);
                      }
                    }}
                  />
                  <button className="absolute top-0 right-0 h-full rounded-l-none btn btn-sm" onClick={onOpenModalPreviewOnDate}>
                    Preview
                  </button>
                </div>
              </div>

              <div className="pr-0 xl:pr-32">
                <div className="divider opacity-10" />

                <EndOfChapterForm
                  formSections={formSections}
                  onEditSection={onEditSection}
                  onDeleteSection={onDeleteSection}
                  onUpdateScheduleInSection={onUpdateScheduleInSection}
                  onCreateScheduleInSection={onCreateScheduleInSection}
                  onDeleteScheduleInSection={onDeleteScheduleInSection}
                  onOpenModalPreview={onOpenModalPreview}
                />

                <div className="flex items-center justify-center">
                  <button onClick={onOpenSelectSectionModal} className="btn btn-outline">
                    Select sections
                  </button>
                </div>
              </div>
            </div>
            <div className="xl:sticky top-0 h-[fit-content] h-600 xl:w-3/5">
              <EndOfChapterCalendar formSections={formSections} onUpdateScheduleInSection={onUpdateScheduleInSection} onDeleteScheduleInSection={onDeleteScheduleInSection} />
            </div>
          </div>
          <div className="flex items-center justify-center p-32">
            <button
              className={classNames('btn btn-md', {
                '!bg-systemGrays02LabelSecondary': isLoadingMutateUpsertTemplate,
              })}
              disabled={isLoadingMutateUpsertTemplate}
              onClick={async () => {
                const result = await mutateUpsertTemplate({
                  variables: {
                    input: {
                      categoryTagId,
                      contentType,
                      productType,
                      placement: TemplatePlacement.Product,
                      isActivated: true,
                      sections: formSections.map((section) => {
                        return {
                          _id: section._id!,
                          note: section.note,
                          isActivated: section.isActivated,
                          schedules: section.schedules?.map((schedule) => ({
                            startedAt: dayjs(schedule.start).startOf('day').toISOString(),
                            endedAt: dayjs(schedule.end).endOf('day').toISOString(),
                          })),
                        };
                      }),
                    },
                  },
                });
                if (result.data?.upsertTemplate) {
                  history.push('/suggestProducts/endOfChapter');
                }
              }}
            >
              {isLoadingMutateUpsertTemplate && <div className="text-white">Loading...</div>}
              {!isLoadingMutateUpsertTemplate && 'Submit'}
            </button>
          </div>
        </>
      )}

      {isOpenSelectModal && (
        <SelectSectionModal onClose={onCloseSelectSectionModal} formSections={formSections} onCreateSection={onCreateSection} onDeleteSection={onDeleteSection} />
      )}
      {previewSectionIds?.length && <PreviewSectionModal isNCPreview={isNCPreview} sectionIds={previewSectionIds} onClose={() => setPreviewSectionIds(undefined)} />}
    </div>
  );
};

export default EditEndOfChapter;
