import cookie from 'cookie';

export const createCookie = (cookieName: string, value: string, hours = 24, path = '/'): void => {
  const date = new Date();
  date.setTime(date.getTime() + hours * 60 * 60 * 1000);
  document.cookie = `${cookieName}=${value}; expires=${date.toUTCString()}; path=${path}`;
};

export const setCookie = (cookieName: string, value: string): void => {
  // expire in 10 year
  createCookie(cookieName, value, 365 * 10);
};

export const deleteCookie = (cookieName: string): void => {
  createCookie(cookieName, '', -1);
};

export const getCookie = (cookieName: string): undefined | string => cookie.parse(document.cookie)[cookieName];
