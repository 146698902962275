import classNames from 'classnames';
import { useState, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { routes, pintoRoutes } from '../App';
import images from '../assets/images';
import { IPropsColorSchemeCSSVariable } from '../styles/ColorSchemeCSSVariable';

type Platform = 'Fictionlog' | 'Pinto';

export interface IPropsNavbar {
  theme: IPropsColorSchemeCSSVariable['theme'];
  platform: Platform;
}

const Navbar = (props: IPropsNavbar) => {
  const location = useLocation();
  const [showMenu, setShowMenu] = useState<boolean>(false);

  const platformRoutes = useMemo(() => {
    return props.platform === 'Pinto' ? pintoRoutes : routes;
  }, [props.platform]);

  return (
    <div className="flex flex-col flex-shrink-0 w-full md:w-64 text-systemGrays01LabelPrimary bg-componentsBgGrouped02 sticky md:h-[100vh] top-0 justify-between">
      <div>
        <div className="flex flex-row justify-between flex-shrink-0 py-20">
          <Link className="flex items-center justify-center w-full min-h-[100px]" key="/" to="/">
            {props.platform === 'Fictionlog' && <img src={images.logo} width="130px" alt="fictionlog logo" />}
            {props.platform === 'Pinto' && <img src={props.theme === 'DAY' ? images.pinto.logo : images.pinto.logoDark} width="130px" alt="pinto logo" />}
          </Link>
          <button className="rounded-lg md:hidden focus:outline-none focus:shadow-outline" onClick={() => setShowMenu(!showMenu)}>
            <svg fill="currentColor" viewBox="0 0 20 20" className="w-6 h-6">
              {!showMenu && (
                <path
                  fillRule="evenodd"
                  d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
                  clipRule="evenodd"
                />
              )}
              {showMenu && (
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              )}
            </svg>
          </button>
        </div>
        <div className={classNames('md:block', { hidden: !showMenu })}>
          <nav className={classNames('md:block px-16 pb-8 md:pb-0 md:overflow-y-auto justify-between')}>
            <div>
              {platformRoutes.map((route) => {
                if (route.hideInMenu) return null;
                if (route.nestedRoutes) {
                  return (
                    <div
                      key={route.name}
                      className={classNames(
                        {
                          'collapse-open bg-systemGrays08FillQuaternary': route.nestedRoutes.map((nestedRoute) => nestedRoute.path).includes(location.pathname),
                        },
                        'w-full mt-8 rounded-lg collapse collapse-arrow hover:bg-systemGrays08FillQuaternary',
                      )}
                    >
                      <input type="checkbox" />
                      <div className="collapse-title font-dbh">{route.name}</div>
                      <div className="collapse-content">
                        {route.nestedRoutes.map((nestedRoute) => {
                          if (nestedRoute.hideInMenu) return null;
                          return (
                            <Link key={nestedRoute.path} to={nestedRoute.path}>
                              <div
                                className={classNames('p-8 pl-16 mt-16 rounded-lg first:mt-0 font-dbh hover:bg-systemGrays03LabelTertiary', {
                                  'bg-systemGrays03LabelTertiary': location.pathname === nestedRoute.path,
                                })}
                              >
                                {nestedRoute.name}
                              </div>
                            </Link>
                          );
                        })}
                      </div>
                    </div>
                  );
                }

                if (route.path) {
                  return (
                    <Link key={route.path} to={route.path}>
                      <div
                        className={classNames(
                          'block px-16 py-8 mt-8 text-sm font-dbh text-systemGrays01LabelPrimary rounded-lg hover:bg-systemGrays05FillPrimary focus:outline-none focus:shadow-outline',
                          {
                            'bg-systemGrays07FillTertiary': location.pathname === route.path,
                            'bg-transparent': location.pathname !== route.path,
                          },
                        )}
                      >
                        {route.name}
                      </div>
                    </Link>
                  );
                }

                return null;
              })}
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
