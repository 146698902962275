import classNames from 'classnames';
import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

export type IInputValue = string | number | boolean | null | undefined;

export interface IPropsSelectDiscountSetting {
  prefixName: string;
  name: string[];
  options?: { label: string; value?: { keyName: string; value?: IInputValue | IInputValue[] }[] }[];
  label?: string;
  className?: string;
  required?: boolean;
  helperText?: string;
  isInvalid?: boolean;
}
const SelectDiscountSetting = (props: IPropsSelectDiscountSetting) => {
  const { options, label, name, required, className, helperText, isInvalid, prefixName } = props;
  const { watch, setValue } = useFormContext();
  const watchList = watch(name);

  const getActiveButton = useCallback(() => {
    if (typeof watchList[0] !== 'boolean') {
      // all: isFree === null
      return '0';
    } else if (!watchList[0]) {
      // isFree === false
      return '1';
    } else {
      // isFree === true
      return '2';
    }
  }, [watchList]);

  return (
    <div className={classNames('form-control', className)}>
      {label && (
        <span className="label-text text-systemGrays02LabelSecondary">
          {label} {required && <span className="text-colorsBrandRed01Primary">*</span>}
        </span>
      )}

      <div className="flex flex-wrap py-8 space-x-8">
        {options?.map((option, index) => (
          <label key={option.label} className="relative cursor-pointer" onClick={() => option?.value?.map((v) => setValue(`${prefixName}.${v.keyName}`, v.value))}>
            <div
              className={classNames('border border-systemGrays01LabelPrimary px-12 py-8 rounded-[8px] w-[fit-content]', {
                'border-colorsBrandRed01Primary text-colorsBrandRed01Primary': String(index) === getActiveButton(),
              })}
            >
              {option.label}
            </div>
          </label>
        ))}
      </div>
      {helperText && (
        <label className="label">
          <span
            className={classNames('label-text-alt text-systemGrays02LabelSecondary font-tt', {
              'text-colorsBrandRed01Primary': isInvalid,
            })}
          >
            {helperText}
          </span>
        </label>
      )}
    </div>
  );
};

export default SelectDiscountSetting;
