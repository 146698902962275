/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const close = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path
      fill="#8C8C8C"
      fillRule="evenodd"
      d="M24 18.994L38 5h.006A4.994 4.994 0 0143 9.994L28.992 24 43 38.015A4.985 4.985 0 0138.015 43H38L24 28.992 10 43h-.015A4.985 4.985 0 015 38.015L19.102 24 5 9.994A4.994 4.994 0 019.994 5H10l14 13.994z"
    />
  </svg>
);

export default close;
