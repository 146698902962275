import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import ToastComponent from '../components/Toast/ToastComponent';
import { IModalNames } from '../core/context/modalReducer';
import { useSubmitReviewDraftEbookMutation } from '../core/graphql/operation-hooks';
import { SubmitReviewEbookInput } from '../core/graphql/types';
import useModal from '../core/hook/context/useModal';

export enum SUBMIT_REVIEW_DRAFT_EBOOK_RETURN_STATUS {
  SUCCESS = 'success',
  ERROR = 'error',
  DUPLICATE_PRODUCT_SET = 'product_set_item_has_duplicate_vol',
}

const useMutationSubmitReviewDraftEbook = () => {
  const [mutateSubmitReviewDraftEbook, { loading }] = useSubmitReviewDraftEbookMutation({ errorPolicy: 'all' });
  const { openModal, removeModal } = useModal();
  const router = useHistory();

  const submitReviewDraftEbook = useCallback(
    async (ebookId: string, input: SubmitReviewEbookInput) => {
      const res = await mutateSubmitReviewDraftEbook({
        variables: {
          ebookId,
          input,
        },
      });

      if (res.errors) {
        const [submitReviewDraftEbookError] = res.errors;
        const submitReviewDraftEbookErrorMessage = submitReviewDraftEbookError.message;
        switch (submitReviewDraftEbookErrorMessage) {
          case 'product_set_item_has_duplicate_vol':
            return SUBMIT_REVIEW_DRAFT_EBOOK_RETURN_STATUS.DUPLICATE_PRODUCT_SET;
          default:
            ToastComponent({ label: 'เกิดข้อผิดพลาดในการทำรายการ กรุณาลองใหม่อีกครั้ง' });
            return SUBMIT_REVIEW_DRAFT_EBOOK_RETURN_STATUS.ERROR;
        }
      } else if (res.data?.submitReviewDraftEbook._id) {
        // ToastComponent({ label: 'ทีมงานกำลังตรวจสอบเพื่อนำอีบุ๊กของคุณขึ้นชั้นหนังสือโดยใช้เวลาประมาณ 1-3 วันทำการ' });
        // router.push('/ebooks');
        openModal({
          modalName: IModalNames.CONFIRM,
          modalPayload: {
            title: 'เผยแพร่ Ebook สำเร็จ',
            onConfirm: () => {
              // router.push(`/ebooks/detail?id=${res.data?.submitReviewDraftEbook._id}`);
              removeModal(IModalNames.CONFIRM);
            },
          },
        });
        router.push('/ebooks');
      }
      return SUBMIT_REVIEW_DRAFT_EBOOK_RETURN_STATUS.SUCCESS;
    },
    [mutateSubmitReviewDraftEbook, openModal, router, removeModal],
  );
  return {
    submitReviewDraftEbook,
    isLoadingSubmitReviewDraftEbook: loading,
  };
};

export default useMutationSubmitReviewDraftEbook;
