import { FieldValues, UseFormRegister } from 'react-hook-form';

import Input from '../../../components/form/Input';
import { IConfigItemType } from '../validates/sectionBuilderSchema';

import RenderObjectLabel from './RenderObjectLabel';

export interface IPropsFeedItem {
  type: IConfigItemType.Feed;
  slug?: string;
  _id?: string;
  title?: string;
  description?: string;
  coverImage?: string;
  bannerImage?: string;
  popupImageUrl?: string;
}
interface IPropsFeedSectionItem {
  index: number;
  itemData?: IPropsFeedItem;
  register: UseFormRegister<FieldValues>;
}

const FeedSectionItem = (props: IPropsFeedSectionItem) => {
  const { index, itemData, register } = props;

  return (
    <div className="flex justify-between space-x-16">
      <div className="w-full collapse">
        <input type="checkbox" className="peer" />
        <div className=" collapse-title bg-systemGrays08FillQuaternary text-systemGrays01LabelPrimary peer-checked:bg-systemGrays08FillQuaternary peer-checked:text-systemGrays01LabelPrimary flex justify-between after:content-['+'] peer-checked:after:content-['-'] ease-out duration-300">
          <span>
            <span>Feed </span>
            <RenderObjectLabel objectLists={[{ key: 'slug', value: itemData?.slug, required: true }]} />
          </span>
        </div>
        <div className="collapse-content text-systemGrays01LabelPrimary peer-checked:bg-systemGrays08FillQuaternary peer-checked:text-systemGrays01LabelPrimary">
          <div className="space-y-8">
            <Input label="slug" name="slug" helperText="Input feed slug" placeholder="slug" register={() => register(`configItems.${index}.slug`)} required />
            <Input label="title" name="title" helperText="Input user ID" placeholder="0/40 characters" register={() => register(`configItems.${index}.title`)} />
            <Input label="Description" name="description" helperText="0/120 characters" placeholder="Description" register={() => register(`configItems.${index}.description`)} />
            <Input
              label="coverImage"
              name="coverImage"
              helperText="Input coverImage url, use for Layout: Banner type"
              placeholder="coverImage"
              register={() => register(`configItems.${index}.coverImage`)}
            />
            <Input
              label="bannerImage"
              name="bannerImage"
              helperText="Input bannerImage url, use for Layout: Banner type"
              placeholder="bannerImage"
              register={() => register(`configItems.${index}.bannerImage`)}
            />
            <Input
              label="popupImageUrl"
              name="popupImageUrl"
              helperText="Input popupImageUrl url, use for Layout: Banner type"
              placeholder="popupImageUrl"
              register={() => register(`configItems.${index}.popupImageUrl`)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeedSectionItem;
