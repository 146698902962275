import classNames from 'classnames';
import dayjs from 'dayjs';
import { filter, some } from 'lodash';
import { useCallback, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Link, useHistory } from 'react-router-dom';

import { ContentType, PopupItemType, UpdatePopupItemInput } from '../../../../core/graphql/types';
import AdModal, { AdModalItem } from '../../../../fictionlog/modals/AdModal';
import useMutateUpdatePopup from '../../../../hooks/useMutationUpdatePopup';
import useQueryAllCategories from '../../../../hooks/useQueryAllCategories';
import useQueryPopupById from '../../../../hooks/useQueryPopupById';
import { useQueryString } from '../../../../utils/router';
import useFormPopupItems from '../../hooks/useFormPopupItems';
import SelectSectionInPopupPageModal from '../../modal/SelectSectionInPopupPageModal';

import AddPopupItemOptionsButton from './AddPopupItemOptionsButton';
import BookItemPopupForm from './BookItemPopupForm';
import EbookItemPopupForm from './EbookItemPopupForm';
import ExploreItemPopupForm from './ExploreItemPopupForm';
import FeedItemPopupForm from './FeedItemPopupForm';
import LinkItemPopupForm from './LinkItemPopupForm';
import PopupCalendar from './PopupCalendar';
import ProductsetPopupForm from './ProductsetPopupForm';
import SectionItemPopupForm from './SectionItemPopupForm';

const EditPopup = () => {
  const queryString = useQueryString();
  const history = useHistory();
  const popupId = queryString.get('popupId');
  const { allCategories } = useQueryAllCategories();

  const [previewDate, setPreviewDate] = useState<Date>(dayjs().startOf('day').toDate());
  const [isOpenPreviewPopupAdModal, setIsOpenPreviewPopupAdModal] = useState<boolean>(false);
  const [previewItems, setPreviewItems] = useState<AdModalItem[] | undefined>(undefined);
  const [isOpenEditSectionModal, setOpenEditSectionModal] = useState<boolean>(false);
  const [editingPopupIndex, setEditingPopupIndex] = useState<number | undefined>(undefined);

  const onOpenEditSectionPopupModal = (popupIndex: number) => {
    setEditingPopupIndex(popupIndex);
    setOpenEditSectionModal(true);
  };

  const onCloseEditSectionPopupModal = () => {
    setOpenEditSectionModal(false);
  };

  const { isLoadingQueryPopupById, popupQueryResult } = useQueryPopupById({
    _id: popupId,
  });

  const { popupItems, onCreatePopupItem, onUpdatePopupItem, onDeletePopupItem, onUpdateScheduleInSection, onDeleteScheduleInSection, onUpdatePopupConfig } = useFormPopupItems({
    popupQueryResult,
  });

  const { isLoadingMutateUpdatePopup, mutateUpdatePopup } = useMutateUpdatePopup();

  const onOpenPreviewAdModal = useCallback(() => {
    const previewItemsOnDate = filter(popupItems, (popup) =>
      some(popup.schedules, (schedule) => dayjs(previewDate).startOf('day').isBetween(dayjs(schedule.startedAt).add(-1, 'day'), dayjs(schedule.endedAt))),
    );
    if ((previewItemsOnDate ?? []).length > 0) {
      setPreviewItems(previewItemsOnDate.map((item) => ({ id: item._id!, imageUrl: item.popupImageUrl! })));
      setIsOpenPreviewPopupAdModal(true);
    }
  }, [popupItems, previewDate]);

  const onConvertItemsToSubmit = useCallback(() => {
    const result: UpdatePopupItemInput[] | undefined =
      popupItems!.map((item) => {
        const popupItemOptions = {
          _id: item._id,
          type: item.type!,
          note: item.note,
          schedules: item.schedules?.map((schedule) => ({
            startedAt: dayjs(schedule.startedAt).startOf('day').toISOString(),
            endedAt: dayjs(schedule.endedAt).endOf('day').toISOString(),
          })),
        };
        switch (item.type) {
          case PopupItemType.Book:
          case PopupItemType.Ebook:
          case PopupItemType.ProductSet:
          case PopupItemType.Section:
          case PopupItemType.User:
            return {
              ...popupItemOptions,
              config: {
                [item.type]: {
                  popupImageUrl: item.popupImageUrl!,
                  _id: item.configId,
                },
              },
            };
          case PopupItemType.Link:
            return {
              ...popupItemOptions,
              config: {
                link: {
                  popupImageUrl: item.popupImageUrl!,
                  url: item.configUrl ?? '',
                },
              },
            };
          case PopupItemType.Search:
            return {
              ...popupItemOptions,
              config: {
                search: {
                  popupImageUrl: item.popupImageUrl!,
                  searchType: item.configSearch!.searchType!,
                  contentType: item.configSearch!.contentType!,
                  searchText: item.configSearch?.searchText ?? null,
                  completed: item.configSearch?.completed ?? null,
                  contentRatingIds: item.configSearch?.contentRatingIds ?? null,
                  isFree: item.configSearch?.isFree ?? null,
                  categoryTagId: item.configSearch?.categoryTagId ?? null,
                },
              },
            };
          case PopupItemType.Feed:
            return {
              ...popupItemOptions,
              config: {
                feed: {
                  popupImageUrl: item.popupImageUrl!,
                  slug: item.configFeed!.slug!,
                },
              },
            };
          case PopupItemType.ExploreEbook:
            return {
              ...popupItemOptions,
              config: {
                [item.type]: {
                  popupImageUrl: item.popupImageUrl!,
                  contentType: item.configExploreEbook!.contentType!,
                  sortBy: item.configExploreEbook!.sortBy!,
                  categoryTagId: item.configExploreEbook?.categoryTagId ?? undefined,
                  contentRatingIds: item.configExploreEbook?.contentRatingIds ?? undefined,
                  isFree: item.configExploreEbook?.isFree ?? undefined,
                },
              },
            };
          case PopupItemType.ExploreBook:
            return {
              ...popupItemOptions,
              config: {
                [item.type]: {
                  popupImageUrl: item.popupImageUrl!,
                  contentType: ContentType.Fiction,
                  completed: item.configExploreBook?.completed ?? undefined,
                  sortBy: item.configExploreBook!.sortBy!,
                  categoryTagId: item.configExploreBook?.categoryTagId ?? undefined,
                  contentRatingIds: item.configExploreBook?.contentRatingIds ?? undefined,
                  isFree: item.configExploreBook?.isFree ?? undefined,
                },
              },
            };
          case PopupItemType.Static:
          default:
            return {
              ...popupItemOptions,
              config: {
                [item.type!]: {
                  popupImageUrl: item.popupImageUrl!,
                },
              },
            };
        }
      }) ?? [];
    return result;
  }, [popupItems]);

  if (isLoadingQueryPopupById) return <div className="w-full btn btn-outline btn-disabled bg-systemGrays04LabelQuaternary border-none loading h-[100px]" />;

  if (!popupQueryResult) return <div>Not found</div>;

  return (
    <div>
      <div className="breadcrumbs">
        <ul>
          <li>
            <Link to="/suggestProducts/Popup">
              <h1 className="py-5 font-dbh text-[28px]">Popup</h1>
            </Link>
          </li>
          <li>
            <div className="font-dbh text-24">{popupQueryResult?.pref}</div>
          </li>
        </ul>
      </div>

      <div className="px-8 pb-32 mb-64 bg-componentsBgGrouped02">
        <div className="flex flex-col space-x-0 xl:space-x-16 xl:flex-row">
          <div className="w-full space-y-24 xl:w-2/5">
            <div className="flex flex-row items-end justify-between px-16 py-24 space-y-16">
              <div className="pb-16 md:pb-0 text-24">{`Edit Popup`}</div>

              <div className="flex relative w-[250px]">
                <ReactDatePicker
                  popperClassName="z-10"
                  dateFormat="dd/MM/yyyy"
                  selected={previewDate}
                  onChange={(date) => {
                    if (date instanceof Date) {
                      setPreviewDate(date);
                    }
                  }}
                />
                <button className="absolute top-0 right-0 h-full rounded-l-none btn btn-sm" onClick={onOpenPreviewAdModal}>
                  Preview
                </button>
              </div>
            </div>

            <div className="divider opacity-10" />

            {popupItems?.map((item, index) => {
              switch (item.type) {
                case PopupItemType.Book:
                  return (
                    <div key={item._id}>
                      <BookItemPopupForm item={item} onUpdatePopupItem={onUpdatePopupItem} popupIndex={index} key={item._id} onDeletePopupItem={onDeletePopupItem} />
                      <div className="mx-8 divider opacity-80" />
                    </div>
                  );

                case PopupItemType.Ebook:
                  return (
                    <div key={item._id}>
                      <EbookItemPopupForm item={item} onUpdatePopupItem={onUpdatePopupItem} popupIndex={index} key={item._id} onDeletePopupItem={onDeletePopupItem} />
                      <div className="mx-8 divider opacity-80" />
                    </div>
                  );

                case PopupItemType.ProductSet:
                  return (
                    <div key={item._id}>
                      <ProductsetPopupForm item={item} onUpdatePopupItem={onUpdatePopupItem} popupIndex={index} key={item._id} onDeletePopupItem={onDeletePopupItem} />
                      <div className="mx-8 divider opacity-80" />
                    </div>
                  );

                case PopupItemType.Section:
                  return (
                    <div key={item._id}>
                      <SectionItemPopupForm
                        key={item._id}
                        item={item}
                        popupIndex={index}
                        onOpenEditSectionPopupModal={onOpenEditSectionPopupModal}
                        onUpdatePopupItem={onUpdatePopupItem}
                        onDeletePopupItem={onDeletePopupItem}
                      />
                      <div className="mx-8 divider opacity-80" />
                    </div>
                  );

                case PopupItemType.Link:
                  return (
                    <div key={item._id}>
                      <LinkItemPopupForm item={item} onUpdatePopupItem={onUpdatePopupItem} popupIndex={index} key={item._id} onDeletePopupItem={onDeletePopupItem} />
                      <div className="mx-8 divider opacity-80" />
                    </div>
                  );

                case PopupItemType.Feed:
                  return (
                    <div key={item._id}>
                      <FeedItemPopupForm item={item} onUpdatePopupItem={onUpdatePopupItem} popupIndex={index} key={item._id} onDeletePopupItem={onDeletePopupItem} />
                      <div className="mx-8 divider opacity-80" />
                    </div>
                  );
                case PopupItemType.ExploreBook:
                case PopupItemType.ExploreEbook:
                  return (
                    <div key={item._id}>
                      <ExploreItemPopupForm
                        item={item}
                        allCategories={allCategories}
                        onUpdatePopupItem={onUpdatePopupItem}
                        onUpdatePopupConfig={onUpdatePopupConfig}
                        popupIndex={index}
                        key={item._id}
                        onDeletePopupItem={onDeletePopupItem}
                      />
                      <div className="mx-8 divider opacity-80" />
                    </div>
                  );
              }
            })}

            <div className="w-full my-24 text-center">
              <AddPopupItemOptionsButton onCreatePopupItem={onCreatePopupItem} />
            </div>
          </div>

          <div className="xl:sticky top-0 h-[fit-content] w-full xl:w-3/5 pt-32">
            <PopupCalendar popupItems={popupItems} onDeleteScheduleInSection={onDeleteScheduleInSection} onUpdateScheduleInSection={onUpdateScheduleInSection} />
          </div>
        </div>
      </div>

      <div className="w-full pb-48 my-64 mb-64 text-center">
        <button
          className={classNames('btn btn-md', {
            '!bg-systemGrays02LabelSecondary': isLoadingMutateUpdatePopup,
          })}
          disabled={isLoadingMutateUpdatePopup}
          onClick={async () => {
            if (!popupId) return;
            const items = onConvertItemsToSubmit();
            const result = await mutateUpdatePopup({
              variables: { popupId, input: { items } },
            });
            if (result.data?.updatePopup._id) {
              history.push('/suggestProducts/popup');
            }
          }}
        >
          {isLoadingMutateUpdatePopup && 'Loading...'}
          {!isLoadingMutateUpdatePopup && 'Submit'}
        </button>
      </div>

      {isOpenEditSectionModal && editingPopupIndex !== undefined && (
        <SelectSectionInPopupPageModal
          popupIndex={editingPopupIndex}
          selectedSectionId={popupItems?.[editingPopupIndex].configId ?? ''}
          onUpdatePopupItem={onUpdatePopupItem}
          onCloseEditSectionPopupModal={onCloseEditSectionPopupModal}
        />
      )}

      {isOpenPreviewPopupAdModal && previewItems && <AdModal onClose={() => setIsOpenPreviewPopupAdModal(false)} items={previewItems} />}
    </div>
  );
};

export default EditPopup;
