import React from 'react';
// this is component to set css variable

const day = `
  --colorsBrandOrange01Primary: #ffb900;
  --colorsBrandOrange02Darker: #e6a700;
  --colorsBrandOrange03Lighter: #ffc733;
  --colorsBrandOrange04Lightest: #ffdc81;
  --colorsBrandOrange05FillPrimary: #fff1cc;
  --colorsBrandOrange06FillSecondary: #fff4d6;
  --colorsBrandOrange07FillTertiary: #fff6e0;
  --colorsBrandOrange08FillQuaternary: #fffaeb;
  --colorsBrandRed01Primary: #ff5a65;
  --colorsBrandRed02Darker: #ff414d;
  --colorsBrandRed03Lighter: #ff8d95;
  --colorsBrandRed04Lightest: #ffc0c4;
  --colorsBrandRed05FillPrimary: #ffdee0;
  --colorsBrandRed06FillSecondary: #ffe4e6;
  --colorsBrandRed07FillTertiary: #ffebec;
  --colorsBrandRed08FillQuaternary: #fff2f3;
  --colorsBrandTurquoise01Primary: #3bc6c6;
  --colorsBrandTurquoise02Darker: #34b3b3;
  --colorsBrandTurquoise03Lighter: #63d1d1;
  --colorsBrandTurquoise04Lightest: #8adddd;
  --colorsBrandTurquoise05FillPrimary: #d8f4f4;
  --colorsBrandTurquoise06FillSecond: #dff6f6;
  --colorsBrandTurquoise07FillTertiary: #e7f8f8;
  --colorsBrandTurquoise08FillQuaternary: #f0fbfb;
  --systemGrays01LabelPrimary: #010000;
  --systemGrays02LabelSecondary: #757575;
  --systemGrays03LabelTertiary: #bebec0;
  --systemGrays04LabelQuaternary: #dcdcdd;
  --systemGrays05FillPrimary: #e4e4e6;
  --systemGrays06FillSecondary: #e9e9ea;
  --systemGrays07FillTertiary: #eeeeef;
  --systemGrays08FillQuaternary: #f4f4f5;
  --colorsWhite: #ffffff;
  --colorsFacebook: #4267b2;
  --systemComponentsDisabled: #999999;
  --componentsBgBase: #fffffe;
  --componentsBgGrouped01: #f4f4f4;
  --componentsBgGrouped02: #fffffd;
  --componentsDivider: #e6e6e6;
  --opacityBlack20: rgba(0, 0, 0, 0.2);
  --opacityBlack40: rgba(0, 0, 0, 0.4);
  --opacityBlack60: rgba(0, 0, 0, 0.6);
  --opacityBlack80: rgba(0, 0, 0, 0.8);
  --opacityWhite20: rgba(255, 255, 255, 0.2);
  --opacityWhite60: rgba(255, 255, 255, 0.6);
  --opacityWhite80: rgba(255, 255, 255, 0.8);
  --overlayBGModal: rgba(255, 255, 255, 0.6);
  --colorsBrandWarmBlack01LabelPrimary: #2d292a;
  --colorsBrandWarmBlack02LabelSecondary: #6e6e6e;
  --colorsBrandWarmBlack03LabelTertiary: #acacac;
  --colorsBrandWarmBlack04LabelQuaternary: #dcdcdd;
  --colorsBrandWarmBlack05FillPrimary: #eaeaea;
  --colorsBrandWarmBlack06FillSecondary: #f5f5f5;
  --colorsBrandWarmBlack07FillTertiary: #f8f8f8;
  --colorsBrandPaper01Primary: #fff8e6;
  --colorsBrandPaper02Darker: #fad063;
  --colorsBrandPaper03Lighter: #fff4d6;
  --colorsBrandPaper04Lightest: #fff6e0;
  --colorsBrandPaper05FillPrimary: #fffaeb;
  --colorsBrandPaper06FillSecondary: #fffbef;
  --colorsBrandPaper07FillTertiary: #fffdf5;
  --colorsPintoBlueprint01Primary: #37b0cc;
  --colorsPintoBlueprint02Darker: #249eba;
  --colorsPintoBlueprint03Lighter: #4cc3de;
  --colorsPintoBlueprint04Lightest: #86def0;
  --colorsPintoBlueprint05FillPrimary: #c9f4fd;
  --colorsPintoBlueprint06FillSecondary: #dbf7fd;
  --colorsPintoBlueprint07FillTertiary: #effcff;
`;

const night = `
  --colorsBrandOrange01Primary: #f6b60e;
  --colorsBrandOrange02Darker: #f8c53f;
  --colorsBrandOrange03Lighter: #c99408;
  --colorsBrandOrange04Lightest: #987006;
  --colorsBrandOrange05FillPrimary: #59420e;
  --colorsBrandOrange06FillSecondary: #4a370e;
  --colorsBrandOrange07FillTertiary: #3b2c0e;
  --colorsBrandOrange08FillQuaternary: #2c210f;
  --colorsBrandRed01Primary: #ec5660;
  --colorsBrandRed02Darker: #cf5d68;
  --colorsBrandRed03Lighter: #8d3343;
  --colorsBrandRed04Lightest: #682531;
  --colorsBrandRed05FillPrimary: #551f2c;
  --colorsBrandRed06FillSecondary: #471a27;
  --colorsBrandRed07FillTertiary: #391522;
  --colorsBrandRed08FillQuaternary: #2a0f1d;
  --colorsBrandTurquoise01Primary: #2ebdbd;
  --colorsBrandTurquoise02Darker: #4bd3d3;
  --colorsBrandTurquoise03Lighter: #249494;
  --colorsBrandTurquoise04Lightest: #1a6b6b;
  --colorsBrandTurquoise05FillPrimary: #11444d;
  --colorsBrandTurquoise06FillSecond: #0e3943;
  --colorsBrandTurquoise07FillTertiary: #0b2d38;
  --colorsBrandTurquoise08FillQuaternary: #08222e;
  --systemGrays01LabelPrimary: #fffffe;
  --systemGrays02LabelSecondary: #a2a2a2;
  --systemGrays03LabelTertiary: #5a5a5e;
  --systemGrays04LabelQuaternary: #404044;
  --systemGrays05FillPrimary: #37373a;
  --systemGrays06FillSecondary: #323235;
  --systemGrays07FillTertiary: #2b2b2f;
  --systemGrays08FillQuaternary: #262629;
  --colorsWhite: #ffffff;
  --colorsFacebook: #4267b2;
  --systemComponentsDisabled: #757575;
  --componentsBgBase: #000000;
  --componentsBgGrouped01: #010000;
  --componentsBgGrouped02: #1c1c1e;
  --componentsDivider: #3a3a3b;
  --opacityBlack20: rgba(0, 0, 0, 0.2);
  --opacityBlack40: rgba(0, 0, 0, 0.4);
  --opacityBlack60: rgba(0, 0, 0, 0.6);
  --opacityBlack80: rgba(0, 0, 0, 0.8);
  --opacityWhite20: rgba(255, 255, 255, 0.2);
  --opacityWhite60: rgba(255, 255, 255, 0.6);
  --opacityWhite80: rgba(255, 255, 255, 0.8);
  --overlayBGModal: rgba(0, 0, 0, 0.6);
  --colorsBrandWarmBlack01LabelPrimary: #fdfdfd;
  --colorsBrandWarmBlack02LabelSecondary: #adadad;
  --colorsBrandWarmBlack03LabelTertiary: #8e8e8e;
  --colorsBrandWarmBlack04LabelQuaternary: #444445;
  --colorsBrandWarmBlack05FillPrimary: #2f2f2f;
  --colorsBrandWarmBlack06FillSecondary: #1c1d21;
  --colorsBrandWarmBlack07FillTertiary: #151517;
  --colorsBrandPaper01Primary: #453300;
  --colorsBrandPaper02Darker: #805f00;
  --colorsBrandPaper03Lighter: #5c5031;
  --colorsBrandPaper04Lightest: #4b3f1c;
  --colorsBrandPaper05FillPrimary: #3e3312;
  --colorsBrandPaper06FillSecondary: #2e2408;
  --colorsBrandPaper07FillTertiary: #211902;
  --colorsPintoBlueprint01Primary: #37c0e0;
  --colorsPintoBlueprint02Darker: #37d6fa;
  --colorsPintoBlueprint03Lighter: #337888;
  --colorsPintoBlueprint04Lightest: #255864;
  --colorsPintoBlueprint05FillPrimary: #1c4a55;
  --colorsPintoBlueprint06FillSecondary: #0f3a45;
  --colorsPintoBlueprint07FillTertiary: #012933;
`;

const sepia = `
--colorsBrandOrange01Primary: #ffb900;
--colorsBrandOrange02Darker: #e6a700;
--colorsBrandOrange03Lighter: #ffc733;
--colorsBrandOrange04Lightest: #ffdc81;
--colorsBrandOrange05FillPrimary: #fff1cc;
--colorsBrandOrange06FillSecondary: #fff4d6;
--colorsBrandOrange07FillTertiary: #fff6e0;
--colorsBrandOrange08FillQuaternary: #fffaeb;
--colorsBrandRed01Primary: #ff5a65;
--colorsBrandRed02Darker: #ff414d;
--colorsBrandRed03Lighter: #ff8d95;
--colorsBrandRed04Lightest: #ffc0c4;
--colorsBrandRed05FillPrimary: #ffdee0;
--colorsBrandRed06FillSecondary: #ffe4e6;
--colorsBrandRed07FillTertiary: #ffebec;
--colorsBrandRed08FillQuaternary: #fff2f3;
--colorsBrandTurquoise01Primary: #3bc6c6;
--colorsBrandTurquoise02Darker: #34b3b3;
--colorsBrandTurquoise03Lighter: #63d1d1;
--colorsBrandTurquoise04Lightest: #8adddd;
--colorsBrandTurquoise05FillPrimary: #d8f4f4;
--colorsBrandTurquoise06FillSecond: #dff6f6;
--colorsBrandTurquoise07FillTertiary: #e7f8f8;
--colorsBrandTurquoise08FillQuaternary: #f0fbfb;
--systemGrays01LabelPrimary: #554533;
--systemGrays02LabelSecondary: #b5966c;
--systemGrays03LabelTertiary: #cabda8;
--systemGrays04LabelQuaternary: #e2d5bb;
--systemGrays05FillPrimary: #eedcbf;
--systemGrays06FillSecondary: #f0e0c7;
--systemGrays07FillTertiary: #f2e6cf;
--systemGrays08FillQuaternary: #f4ead7;
--colorsWhite: #ffffff;
--colorsFacebook: #4267b2;
--systemComponentsDisabled: #999999;
--componentsBgBase: #f8f1e4;
--componentsBgGrouped01: #f0e8d9;
--componentsBgGrouped02: #f8f1e5;
--componentsDivider: #d5cec4;
--opacityBlack20: rgba(0, 0, 0, 0.2);
--opacityBlack40: rgba(0, 0, 0, 0.4);
--opacityBlack60: rgba(0, 0, 0, 0.6);
--opacityBlack80: rgba(0, 0, 0, 0.8);
--opacityWhite20: rgba(255, 255, 255, 0.2);
--opacityWhite60: rgba(255, 255, 255, 0.6);
--opacityWhite80: rgba(255, 255, 255, 0.8);
--overlayBGModal: rgba(255, 255, 255, 0.6);
`;

export interface IPropsColorSchemeCSSVariable {
  theme: 'DAY' | 'NIGHT' | 'SEPIA';
}

const getTheme = (props: IPropsColorSchemeCSSVariable) => {
  switch (props.theme) {
    case 'DAY':
      return day;
    case 'NIGHT':
      return night;
    case 'SEPIA':
      return sepia;
    default:
      return day;
  }
};

export const ColorSchemeCSSVariable = (props: IPropsColorSchemeCSSVariable): JSX.Element => {
  const currentTheme = getTheme(props);
  return <style dangerouslySetInnerHTML={{ __html: `:root {${currentTheme}}` }} />;
};
