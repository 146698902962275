/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const zoomIn = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path
      fill="#8C8C8C"
      fillRule="evenodd"
      d="M23.5 18.5h4a1 1 0 011 1v3a1 1 0 01-1 1h-4V27a1 1 0 01-1 1h-3a1 1 0 01-1-1v-3.5h-4a1 1 0 01-1-1v-3a1 1 0 011-1h4V15a1 1 0 011-1h3a1 1 0 011 1v3.5zm12.398 12.606l8.11 8.11a3.388 3.388 0 11-4.793 4.792l-8.11-8.11A17.917 17.917 0 0121 39c-9.941 0-18-8.059-18-18S11.059 3 21 3s18 8.059 18 18c0 3.746-1.144 7.225-3.102 10.106zM21 33c6.627 0 12-5.373 12-12S27.627 9 21 9 9 14.373 9 21s5.373 12 12 12z"
    />
  </svg>
);

export default zoomIn;
