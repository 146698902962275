import { setContext } from '@apollo/client/link/context';

import { getCookie } from '../../utils/cookie';
import { cookieName } from '../cookieName';

const headerLink = setContext((_, previousContext) => {
  const headers = { ...previousContext.headers };
  headers['accept-language'] = 'th';

  const accessToken = getCookie(cookieName.ACCESS_TOKEN);
  if (accessToken) {
    headers.authorization = `JWT ${accessToken}`;
  }
  headers['storylog-server-name'] = 'fictionlog-secretbase-server-side';

  return { headers };
});

export default headerLink;
