import { EbookStatus } from '../core/graphql/types';

type EBOOK_STATUS = {
  [key in EbookStatus]: string;
};

export const MAPPED_EBOOK_STATUS: EBOOK_STATUS = {
  draft: 'แบบร่าง',
  public: 'เผยแพร่',
  hidden: 'ซ่อน',
  removed: 'ลบ',
  scheduled: 'รอเผยแพร่',
};
