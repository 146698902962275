/* eslint-disable */
// this file generate by raw-icons/build.js

import AddFill from './components/AddFill';
import AddOutline from './components/AddOutline';
import Add from './components/Add';
import AlignCenter from './components/AlignCenter';
import AlignLeft from './components/AlignLeft';
import AlignRight from './components/AlignRight';
import Android from './components/Android';
import ArrowDown from './components/ArrowDown';
import ArrowLeft from './components/ArrowLeft';
import ArrowRight from './components/ArrowRight';
import ArrowUp from './components/ArrowUp';
import BankAccount from './components/BankAccount';
import Bin from './components/Bin';
import BookSeries from './components/BookSeries';
import Bookmark from './components/Bookmark';
import Camera from './components/Camera';
import CaretDown from './components/CaretDown';
import CaretLeft from './components/CaretLeft';
import CaretRight from './components/CaretRight';
import CaretUp from './components/CaretUp';
import Cashout from './components/Cashout';
import CheckBadge from './components/CheckBadge';
import CheckFill from './components/CheckFill';
import Check from './components/Check';
import CheckboxEmpty from './components/CheckboxEmpty';
import CheckboxFill from './components/CheckboxFill';
import ClockCircle from './components/ClockCircle';
import Clock from './components/Clock';
import Close from './components/Close';
import Coin from './components/Coin';
import Comment from './components/Comment';
import Create from './components/Create';
import CreditCard from './components/CreditCard';
import Download from './components/Download';
import Dropdown from './components/Dropdown';
import EbookComic from './components/EbookComic';
import EbookNonfiction from './components/EbookNonfiction';
import Ebook from './components/Ebook';
import Edit from './components/Edit';
import Explore from './components/Explore';
import Filter from './components/Filter';
import Follow from './components/Follow';
import Hamburger from './components/Hamburger';
import Heart from './components/Heart';
import Home from './components/Home';
import IOs from './components/IOs';
import IconDiscount from './components/IconDiscount';
import IconLayoutBanner from './components/IconLayoutBanner';
import IconLayoutCover from './components/IconLayoutCover';
import Image from './components/Image';
import Invoice from './components/Invoice';
import Key from './components/Key';
import Library from './components/Library';
import Link from './components/Link';
import List from './components/List';
import Loading from './components/Loading';
import Lock from './components/Lock';
import Logout from './components/Logout';
import Mail from './components/Mail';
import MinusFill from './components/MinusFill';
import MinusOutline from './components/MinusOutline';
import Minus from './components/Minus';
import MoreHorizontal from './components/MoreHorizontal';
import MoreVertical from './components/MoreVertical';
import Notification from './components/Notification';
import Preference from './components/Preference';
import Profile from './components/Profile';
import Publish from './components/Publish';
import QuestionMarkFill from './components/QuestionMarkFill';
import QuestionMark from './components/QuestionMark';
import RadioEmpty from './components/RadioEmpty';
import RadioFill from './components/RadioFill';
import RemoveFill from './components/RemoveFill';
import RemoveOutline from './components/RemoveOutline';
import Reply from './components/Reply';
import Report from './components/Report';
import Save from './components/Save';
import Search from './components/Search';
import Send from './components/Send';
import Settings from './components/Settings';
import ShareAndroid from './components/ShareAndroid';
import ShareIos from './components/ShareIos';
import SocialFb from './components/SocialFb';
import SocialLine from './components/SocialLine';
import SocialTwitter from './components/SocialTwitter';
import StarEmpty from './components/StarEmpty';
import StarHalf from './components/StarHalf';
import Star from './components/Star';
import Stats from './components/Stats';
import Sticker from './components/Sticker';
import Swap from './components/Swap';
import TextBold from './components/TextBold';
import TextDivider from './components/TextDivider';
import TextHeader from './components/TextHeader';
import TextIndent from './components/TextIndent';
import TextItalic from './components/TextItalic';
import TextQuote from './components/TextQuote';
import TextSize from './components/TextSize';
import TextStrikeOut from './components/TextStrikeOut';
import TextUnderline from './components/TextUnderline';
import Touch from './components/Touch';
import Trending from './components/Trending';
import Upload from './components/Upload';
import View from './components/View';
import VoteDown from './components/VoteDown';
import VoteUp from './components/VoteUp';
import WarningFill from './components/WarningFill';
import WarningOutline from './components/WarningOutline';
import ZoomFit from './components/ZoomFit';
import ZoomIn from './components/ZoomIn';
import ZoomOut from './components/ZoomOut';
import { IconNames } from '../Icon';
export const allIcons: IconNames[] = [
  'addFill',
  'addOutline',
  'add',
  'alignCenter',
  'alignLeft',
  'alignRight',
  'android',
  'arrowDown',
  'arrowLeft',
  'arrowRight',
  'arrowUp',
  'bankAccount',
  'bin',
  'bookSeries',
  'bookmark',
  'camera',
  'caretDown',
  'caretLeft',
  'caretRight',
  'caretUp',
  'cashout',
  'checkBadge',
  'checkFill',
  'check',
  'checkboxEmpty',
  'checkboxFill',
  'clockCircle',
  'clock',
  'close',
  'coin',
  'comment',
  'create',
  'creditCard',
  'download',
  'dropdown',
  'ebookComic',
  'ebookNonfiction',
  'ebook',
  'edit',
  'explore',
  'filter',
  'follow',
  'hamburger',
  'heart',
  'home',
  'iOs',
  'iconDiscount',
  'iconLayoutBanner',
  'iconLayoutCover',
  'image',
  'invoice',
  'key',
  'library',
  'link',
  'list',
  'loading',
  'lock',
  'logout',
  'mail',
  'minusFill',
  'minusOutline',
  'minus',
  'moreHorizontal',
  'moreVertical',
  'notification',
  'preference',
  'profile',
  'publish',
  'questionMarkFill',
  'questionMark',
  'radioEmpty',
  'radioFill',
  'removeFill',
  'removeOutline',
  'reply',
  'report',
  'save',
  'search',
  'send',
  'settings',
  'shareAndroid',
  'shareIos',
  'socialFb',
  'socialLine',
  'socialTwitter',
  'starEmpty',
  'starHalf',
  'star',
  'stats',
  'sticker',
  'swap',
  'textBold',
  'textDivider',
  'textHeader',
  'textIndent',
  'textItalic',
  'textQuote',
  'textSize',
  'textStrikeOut',
  'textUnderline',
  'touch',
  'trending',
  'upload',
  'view',
  'voteDown',
  'voteUp',
  'warningFill',
  'warningOutline',
  'zoomFit',
  'zoomIn',
  'zoomOut'
];

export default {
  addFill: AddFill,
  addOutline: AddOutline,
  add: Add,
  alignCenter: AlignCenter,
  alignLeft: AlignLeft,
  alignRight: AlignRight,
  android: Android,
  arrowDown: ArrowDown,
  arrowLeft: ArrowLeft,
  arrowRight: ArrowRight,
  arrowUp: ArrowUp,
  bankAccount: BankAccount,
  bin: Bin,
  bookSeries: BookSeries,
  bookmark: Bookmark,
  camera: Camera,
  caretDown: CaretDown,
  caretLeft: CaretLeft,
  caretRight: CaretRight,
  caretUp: CaretUp,
  cashout: Cashout,
  checkBadge: CheckBadge,
  checkFill: CheckFill,
  check: Check,
  checkboxEmpty: CheckboxEmpty,
  checkboxFill: CheckboxFill,
  clockCircle: ClockCircle,
  clock: Clock,
  close: Close,
  coin: Coin,
  comment: Comment,
  create: Create,
  creditCard: CreditCard,
  download: Download,
  dropdown: Dropdown,
  ebookComic: EbookComic,
  ebookNonfiction: EbookNonfiction,
  ebook: Ebook,
  edit: Edit,
  explore: Explore,
  filter: Filter,
  follow: Follow,
  hamburger: Hamburger,
  heart: Heart,
  home: Home,
  iOs: IOs,
  iconDiscount: IconDiscount,
  iconLayoutBanner: IconLayoutBanner,
  iconLayoutCover: IconLayoutCover,
  image: Image,
  invoice: Invoice,
  key: Key,
  library: Library,
  link: Link,
  list: List,
  loading: Loading,
  lock: Lock,
  logout: Logout,
  mail: Mail,
  minusFill: MinusFill,
  minusOutline: MinusOutline,
  minus: Minus,
  moreHorizontal: MoreHorizontal,
  moreVertical: MoreVertical,
  notification: Notification,
  preference: Preference,
  profile: Profile,
  publish: Publish,
  questionMarkFill: QuestionMarkFill,
  questionMark: QuestionMark,
  radioEmpty: RadioEmpty,
  radioFill: RadioFill,
  removeFill: RemoveFill,
  removeOutline: RemoveOutline,
  reply: Reply,
  report: Report,
  save: Save,
  search: Search,
  send: Send,
  settings: Settings,
  shareAndroid: ShareAndroid,
  shareIos: ShareIos,
  socialFb: SocialFb,
  socialLine: SocialLine,
  socialTwitter: SocialTwitter,
  starEmpty: StarEmpty,
  starHalf: StarHalf,
  star: Star,
  stats: Stats,
  sticker: Sticker,
  swap: Swap,
  textBold: TextBold,
  textDivider: TextDivider,
  textHeader: TextHeader,
  textIndent: TextIndent,
  textItalic: TextItalic,
  textQuote: TextQuote,
  textSize: TextSize,
  textStrikeOut: TextStrikeOut,
  textUnderline: TextUnderline,
  touch: Touch,
  trending: Trending,
  upload: Upload,
  view: View,
  voteDown: VoteDown,
  voteUp: VoteUp,
  warningFill: WarningFill,
  warningOutline: WarningOutline,
  zoomFit: ZoomFit,
  zoomIn: ZoomIn,
  zoomOut: ZoomOut
};
