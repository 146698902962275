import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import ToastComponent, { IToastType } from '../components/Toast/ToastComponent';
import { IModalNames } from '../core/context/modalReducer';
import { useCreatePintoSubscriptionTitleMutation } from '../core/graphql/operation-hooks';
import { CreatePintoSubscriptionTitleInput } from '../core/graphql/types';
import useModal from '../core/hook/context/useModal';

export enum RETURN_STATUS {
  SUCCESS = 'success',
  ERROR = 'error',
  DUPLICATE_TITLE = 'title_unavailable',
  DUPLICATE_SLUG = 'slug_unavailable',
}

const useMutationCreatePintoSubscriptionTitle = () => {
  const [mutateCreatePintoSubscriptionTitle, { loading }] = useCreatePintoSubscriptionTitleMutation({ errorPolicy: 'all' });
  const { openModal, removeModal } = useModal();
  const router = useHistory();

  const createPintoSubscriptionTitle = useCallback(
    async (input: CreatePintoSubscriptionTitleInput) => {
      const res = await mutateCreatePintoSubscriptionTitle({ variables: { input } });

      if (res.errors) {
        const [editError] = res.errors;
        const editErrorMessage = editError.message;
        switch (editErrorMessage) {
          case 'title_unavailable':
            ToastComponent({ label: 'มีหัวหนังสือนี้แล้ว', type: IToastType.ERROR });
            return RETURN_STATUS.DUPLICATE_TITLE;
          case 'slug_unavailable':
            ToastComponent({ label: 'มี "Short Name" นี้แล้ว', type: IToastType.ERROR });
            return RETURN_STATUS.DUPLICATE_SLUG;
          default:
            ToastComponent({ label: 'เกิดข้อผิดพลาดในการทำรายการ กรุณาลองใหม่อีกครั้ง', type: IToastType.ERROR });
            return RETURN_STATUS.ERROR;
        }
      } else if (res.data?.createPintoSubscriptionTitle._id) {
        openModal({
          modalName: IModalNames.CONFIRM,
          modalPayload: {
            title: 'บันทึกสำเร็จ',
            onConfirm: () => {
              router.push(`/subscriptions`);
              removeModal(IModalNames.CONFIRM);
            },
          },
        });
      }
      return RETURN_STATUS.SUCCESS;
    },
    [mutateCreatePintoSubscriptionTitle, openModal, removeModal, router],
  );

  return { createPintoSubscriptionTitle, isLoadingCreatePintoSubscriptionTitle: loading };
};

export default useMutationCreatePintoSubscriptionTitle;
