import classNames from 'classnames';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';

import Pagination from '../../../components/Pagination';
import { CorrectionEbookStatus, Ebook, EbookStatus } from '../../../core/graphql/types';
import { getDayTime } from '../../../utils/dayjs';

const PAGE_LIMIT = 10;
const DATE_FORMAT = 'DD-MM-YYYY hh:mm A';

export interface IEbookItem {
  id: string;
  title: string;
  status?: EbookStatus;
  correctionStatus?: CorrectionEbookStatus;
  updateDate: string;
  coverUrl: string;
  category?: string;
  originalLang?: string;
  translatedLang?: string;
  userAccount: string;
}

export interface IPropsEbooksTablePresenter {
  ebookItems: Ebook[];
  currentPage: number;
  pageLimit?: number;
  totalCount?: number;
  onChangePage: (page: number) => void;
}

function EbooksTablePresenter(props: IPropsEbooksTablePresenter) {
  const { ebookItems, currentPage, pageLimit, totalCount, onChangePage } = props;

  const mappedEbookItems = useMemo(
    () =>
      ebookItems.map(
        (item): IEbookItem => ({
          id: item?._id,
          title: item?.title || '',
          status: item?.status ?? undefined,
          correctionStatus: item?.correctionEbookStatus ?? undefined,
          updateDate: item?.updatedAt,
          coverUrl: item?.coverImageUrl || '',
          category: item.correction?.category?.name || item?.category?.name,
          originalLang: item?.originalLanguage.name,
          translatedLang: item?.language.name,
          userAccount: item?.user?.displayName || '',
        }),
      ),
    [ebookItems],
  );

  return (
    <div>
      <div className="mt-12 overflow-auto rounded-md">
        <table className="table w-full table-zebra font-tt">
          <thead>
            <tr>
              <th>ID</th>
              <th>Ebook Status</th>
              <th>Review Status</th>
              <th>Cover</th>
              <th>Title</th>
              <th>Update At</th>
              <th>User Account</th>
              <th>Category</th>
              <th>Original Language</th>
              <th>Translated Language</th>
            </tr>
          </thead>
          <tbody>
            {mappedEbookItems?.map((item) => (
              <tr key={item.id}>
                <th className="font-normal">
                  <Link to={`/ebooks/detail?id=${item.id}`} className="underline text-colorsBrandRed01Primary">
                    {item.id}
                  </Link>
                </th>
                <td>
                  <div
                    className={classNames({
                      badge: !!item.status,
                      'badge-success': item.status === EbookStatus.Public,
                      'badge-warning': item.status === EbookStatus.Hidden,
                      'badge-error': item.status === EbookStatus.Removed,
                    })}
                  >
                    {item.status?.toLocaleUpperCase() || '-'}
                  </div>
                </td>
                <td>
                  <div
                    className={classNames({
                      badge: item.correctionStatus === CorrectionEbookStatus.Draft,
                      'badge badge-warning': item.correctionStatus === CorrectionEbookStatus.Pending,
                      'badge badge-error': item.correctionStatus === CorrectionEbookStatus.Rejected,
                    })}
                  >
                    {item.correctionStatus?.toLocaleUpperCase() || '-'}
                  </div>
                </td>
                <td>
                  <div className="avatar">
                    <div className="w-[50px] h-[75px] rounded">
                      <img src={item.coverUrl} alt={item.title} />
                    </div>
                  </div>
                </td>
                <td>{item.title}</td>
                <td>{getDayTime(item.updateDate || '', DATE_FORMAT)}</td>
                <td>{item.userAccount}</td>
                <td>
                  <div
                    className={classNames({
                      'badge badge-outline': !!item.category,
                    })}
                  >
                    {item.category || '-'}
                  </div>
                </td>
                <td>{item.originalLang}</td>
                <td>{item.translatedLang}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="flex items-center justify-between mt-12">
        <span className="text-systemGrays02LabelSecondary">แสดงผล 10 รายการต่อหน้า</span>

        {(totalCount || 0) > 0 && <Pagination currentPage={currentPage} totalCount={totalCount || 0} pageSize={pageLimit || PAGE_LIMIT} onPageChange={onChangePage} />}
      </div>
    </div>
  );
}

export default EbooksTablePresenter;
