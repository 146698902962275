import classNames from 'classnames';
import { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import Card from '../../../components/Card';
import Input from '../../../components/form/Input';
import ExploreSectionTarget from '../components/ExploreSectionTarget';
import ProductSetSectionTarget from '../components/ProductSetSectionTarget';
import SearchSectionTarget from '../components/SearchSectionTarget';
import { ISectionLayoutId, ISectionTargetType, TARGET_TYPE_OPTIONS } from '../validates/sectionBuilderSchema';

const getInputPropsByTargetType = (targetType?: ISectionTargetType) => {
  switch (targetType) {
    case ISectionTargetType.FEED:
      return { name: 'target.slug', label: 'slug', placeholder: 'slug' };
    case ISectionTargetType.LINK:
      return { name: 'target.url', label: 'url', placeholder: 'url' };
    case ISectionTargetType.USER:
      return { name: 'target._id', label: '_id', placeholder: '_id' };
    default: {
      return { name: '', label: '-' };
    }
  }
};

const SectionDetailPresenter = () => {
  const { register, watch, setValue } = useFormContext();

  const [title, target, layoutId] = watch(['title', 'target', 'layoutId']);

  const renderTargetInput = useCallback(
    (type?: ISectionTargetType) => {
      switch (type) {
        case ISectionTargetType.FEED:
        case ISectionTargetType.LINK:
        case ISectionTargetType.USER:
          return (
            <div>
              <Input register={register} {...getInputPropsByTargetType(target?.type)} />
            </div>
          );
        case ISectionTargetType.SEARCH:
          return (
            <div>
              <SearchSectionTarget register={register} />
            </div>
          );
        case ISectionTargetType.EXPLORE:
          return (
            <div>
              <ExploreSectionTarget register={register} />
            </div>
          );
        case ISectionTargetType.PRODUCT_SET:
          return (
            <div>
              <ProductSetSectionTarget />
            </div>
          );
        default:
          return null;
      }
    },
    [register, target?.type],
  );

  const TargetOptions = useMemo(() => {
    if (layoutId !== ISectionLayoutId.BookBanner) {
      return TARGET_TYPE_OPTIONS.filter((option) => option.value !== ISectionTargetType.PRODUCT_SET);
    }
    return TARGET_TYPE_OPTIONS;
  }, [layoutId]);
  return (
    <Card className="space-y-16">
      <div className="text-22">Section Detail</div>
      <Input
        name="title"
        register={register}
        label="Title"
        required={!(layoutId === ISectionLayoutId.MainBanner || layoutId === ISectionLayoutId.TagCloud)}
        placeholder="section title"
        helperText={`${(title || '').length}/40 characters`}
        isInvalid={(title || '').length > 40}
      />
      <div className="space-y-8">
        <label className="label">
          <span className="label-text text-systemGrays02LabelSecondary">Target Type</span>
        </label>
        <div className="space-x-4">
          {TargetOptions.map((option) => (
            <button
              key={option.label}
              className={classNames('btn btn-outline border', {
                'border-colorsBrandRed01Primary text-colorsBrandRed01Primary': target?.type === option.value || (!target?.type && option.label === 'ไม่ระบุ'),
              })}
              onClick={() => setValue('target', option?.value ? { type: option.value } : null)}
            >
              {option.label}
            </button>
          ))}
          {renderTargetInput(target?.type)}
        </div>
      </div>
    </Card>
  );
};

export default SectionDetailPresenter;
