import dayjs from 'dayjs';
import React, { useCallback } from 'react';

import LibraryTableItem from '../../../app/libraryAd/components/LibraryTableItem';
import LibraryTable from '../../../app/libraryAd/components/LibraryTableList';
import { TemplatePlacement, FeedPref } from '../../../core/graphql/types';
import useMutateUpsertTemplate from '../../../hooks/useMutateUpsertTemplate';
import useQueryTemplates from '../../../hooks/useQueryTemplates';

const LibraryAdPage = (): JSX.Element => {
  const { templates, isLoadingTemplates } = useQueryTemplates({
    placement: TemplatePlacement.Library,
  });
  const { mutateUpsertTemplate } = useMutateUpsertTemplate();
  const mappedFeedPrefWithTemplates = React.useMemo(() => {
    const feedPrefs = [FeedPref.Default, FeedPref.Female, FeedPref.Male, FeedPref.Y];
    return feedPrefs.map((pref) => {
      const nodePref = templates?.edges.find((edge) => edge.node.pref === pref)?.node;
      return nodePref ?? { pref, isActivated: false, updatedAt: null };
    });
  }, [templates?.edges]);

  const onUpdateRecord = useCallback(
    async (isActivated: boolean, pref: FeedPref) => {
      await mutateUpsertTemplate({
        variables: {
          input: {
            pref,
            placement: TemplatePlacement.Library,
            isActivated,
            sections: [],
            sectionIds: [],
          },
        },
      });
    },
    [mutateUpsertTemplate],
  );

  return (
    <div>
      <div className="breadcrumbs">
        <ul>
          <li>
            <h1 className="py-5 font-dbh text-[28px]">Library Ad</h1>
          </li>
        </ul>
      </div>
      <div>
        {isLoadingTemplates && <div className="w-full btn btn-outline btn-disabled bg-systemGrays04LabelQuaternary border-none loading h-[100px]" />}

        {!isLoadingTemplates && templates && (
          <LibraryTable>
            {mappedFeedPrefWithTemplates.map((item) => (
              <LibraryTableItem
                key={item?.pref}
                pref={item?.pref ?? FeedPref.Default}
                isActivated={item.isActivated}
                updatedAtInFormat={item.updatedAt ? dayjs(item.updatedAt).format('DD/MM/YYYY HH:mm') : '-'}
                onUpdateRecord={onUpdateRecord}
              />
            ))}
          </LibraryTable>
        )}
      </div>
    </div>
  );
};

export default LibraryAdPage;
