export const downloadCSV = async (url: string, name: string) => {
  const response = await fetch(url);
  const data = await response.text();
  const blob = new Blob([data], { type: `data:text/csv;charset=utf-8,${data}` });
  const blobURL = window.URL.createObjectURL(blob);

  // Create new tag for download file
  const anchor = document.createElement('a');
  anchor.download = name;
  anchor.href = blobURL;
  anchor.dataset.downloadurl = ['text/csv', anchor.download, anchor.href].join(':');
  anchor.click();

  // Remove URL.createObjectURL. The browser should not save the reference to the file.
  setTimeout(() => {
    // For Firefox it is necessary to delay revoking the ObjectURL
    URL.revokeObjectURL(blobURL);
  }, 100);
};

export const convertFileToBase64 = (file?: File) => {
  if (!file) return;
  return new Promise((resolve) => {
    let baseURL;
    // Make new FileReader
    const reader = new FileReader();

    // Convert the file to base64 text
    reader.readAsDataURL(file);

    // on reader load somthing...
    reader.onload = () => {
      // Make a fileInfo Object
      baseURL = reader.result || '';
      resolve(baseURL);
    };
  });
};
