import classNames from 'classnames';
import { useState } from 'react';
import { Control, FieldValues, useFieldArray, useFormContext } from 'react-hook-form';
import { IoMdClose } from 'react-icons/io';

interface IArraysInput {
  name: string;
  control: Control<FieldValues, any>;
  label?: string;
  helperText?: string;
  className?: string;
  required?: boolean;
  isInvalid?: boolean;
  placeholder?: string;
  inputContainerClassName?: string;
  inputClassName?: string;
}

const ArraysInput = (props: IArraysInput) => {
  const { control, label, helperText, required, isInvalid, className, name, placeholder, inputClassName, inputContainerClassName } = props;
  const { watch, setValue } = useFormContext();
  const [watchList] = watch([name]);
  const { remove } = useFieldArray({
    control,
    name,
  });

  const [tempValue, setTempValue] = useState<undefined | string>(undefined);

  return (
    <div className={classNames('form-control', className)}>
      {label && (
        <label className="label">
          <span className="label-text text-systemGrays02LabelSecondary">
            {label} {required && <span className="text-colorsBrandRed01Primary">*</span>}
          </span>
        </label>
      )}

      <div className={classNames('flex flex-wrap items-center gap-8 bg-componentsBgGrouped02 textarea', inputContainerClassName)}>
        {watchList?.map((item: string, k: number) => (
          <button
            key={k}
            type="button"
            onClick={() => remove(k)}
            className="h-[30px] px-8 py-4 border border-systemGrays02LabelSecondary rounded-box flex justify-between items-center gap-x-8"
          >
            <div>{item}</div>
            <div className="p-4 pr-0 bg-componentsBgGrouped02 rounded-box">
              <IoMdClose color="var(--systemGrays01LabelPrimary)" />
            </div>
          </button>
        ))}

        <input
          className={classNames('w-full outline-none bg-componentsBgGrouped02', inputClassName)}
          value={tempValue}
          placeholder={placeholder}
          onKeyDown={(e) => {
            if (!tempValue || watchList?.find((item: string) => item === tempValue)) return;
            if (e.keyCode === 13) {
              setValue(name, [...watchList, tempValue]);
              setTempValue('');
              // setShowDropdown(false);
            }
          }}
          // onFocus={() => {
          //   onChangeInputToggleDropdown(inputValue, setShowDropdown);
          // }}
          // onKeyUp={() => {
          //   onChangeInputToggleDropdown(inputValue, setShowDropdown);
          // }}
          onChange={(e) => {
            setTempValue(e.target.value || '');
          }}
        />
      </div>

      {helperText && (
        <label className="label">
          <span
            className={classNames('label-text-alt text-systemGrays02LabelSecondary font-tt', {
              'text-colorsBrandRed01Primary': isInvalid,
            })}
          >
            {helperText}
          </span>
        </label>
      )}
    </div>
  );
};

export default ArraysInput;
