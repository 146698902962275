import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

import { endOfDate } from '../../../utils/dayjs';
import DiscountCalendar, { IPropsDiscountCalendar } from '../components/DiscountCalendar';
import { DISCOUNT_FIELD_KEYS } from '../validates/discountSchema';

function DiscountCalendarPresenter() {
  const { watch } = useFormContext();
  const [startDate, endDate, title, isHasDiscountEndDate = true] = watch([
    DISCOUNT_FIELD_KEYS.START_DATE,
    DISCOUNT_FIELD_KEYS.END_DATE,
    DISCOUNT_FIELD_KEYS.TITLE,
    DISCOUNT_FIELD_KEYS.IS_HAS_DISCOUNT_END_DATE,
  ]);
  const getCalendarEvents = useCallback(
    (): IPropsDiscountCalendar['events'] => [
      {
        title: `${title || 'No Title'}`,
        allDay: true,
        start: startDate || endDate,
        end: isHasDiscountEndDate ? endOfDate(endDate).toDate() : startDate,
      },
    ],
    [title, startDate, endDate, isHasDiscountEndDate],
  );

  return <DiscountCalendar events={getCalendarEvents()} />;
}

export default DiscountCalendarPresenter;
