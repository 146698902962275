import { useState, useCallback, useRef } from 'react';

import { SectionsFilterSortBy } from '../../../core/graphql/types';

interface SortingItem {
  name: string;
  value: SectionsFilterSortBy;
}

interface IPropsSectionListSorting {
  onSortingChange?: (value: SectionsFilterSortBy) => void;
}

export const SORTING: SortingItem[] = [
  { name: 'Last Updated', value: SectionsFilterSortBy.Updated },
  { name: 'Created Date', value: SectionsFilterSortBy.Created },
];

const SectionListSorting = (props: IPropsSectionListSorting) => {
  const { onSortingChange } = props;
  const [currentSorting, setCurrentSorting] = useState<string>(SORTING[0].name);
  const sortingRef = useRef<HTMLUListElement>(null);

  const handleClickSorting = useCallback(
    (sortingItem: SortingItem) => {
      setCurrentSorting(sortingItem.name);
      sortingRef.current?.blur();
      onSortingChange?.(sortingItem.value);
    },
    [sortingRef, onSortingChange],
  );

  return (
    <div>
      <span className="text-systemGrays02LabelSecondary">Sort by: </span>
      <div className="dropdown border rounded-md">
        <label tabIndex={0} className="btn btn-ghost rounded-btn w-[204px] justify-start text-systemGrays02LabelSecondary">
          {currentSorting}
        </label>
        <ul ref={sortingRef} tabIndex={0} className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52">
          {SORTING.map((item) => {
            return (
              <li key={item.name}>
                <a onClick={() => handleClickSorting(item)}>{item.name}</a>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default SectionListSorting;
