import dayjs from 'dayjs';
import orderBy from 'lodash/orderBy';
import { useState } from 'react';
import { Calendar, CalendarProps, Event } from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';

import { ScheduleInput } from '../../../../core/graphql/types';
import { localizer } from '../../../../utils/dayjs';
import { IPopupItemInput } from '../../hooks/useFormPopupItems';

interface IResource {
  sectionId: string;
  scheduleIndex: number;
}

const DragAndDropCalendar = withDragAndDrop<Event, IResource>(Calendar as React.ComponentType<CalendarProps<Event, IResource>>);

export type IStateSchemaSections = Pick<Event, 'title' | 'start' | 'end'>;

export interface IPropsPopupCalendar {
  popupItems?: IPopupItemInput[];
  onUpdateScheduleInSection: (sectionId: string, scheduleIndex: number, editedSchedule: ScheduleInput) => void;
  onDeleteScheduleInSection: (sectionId: string, scheduleIndex: number) => void;
}

const getEventsFromSections = (popupItems?: IPopupItemInput[]): Event[] => {
  const stateSchemaSections: Event[] = [];
  popupItems?.map((section) =>
    section.schedules?.map((schedule, scheduleIndex) =>
      stateSchemaSections.push({
        title: `${scheduleIndex + 1}.${section.note ?? ''}`,
        allDay: true,
        start: schedule.startedAt ?? undefined,
        end: schedule.endedAt ?? undefined,
        resource: {
          sectionId: section._id,
          scheduleIndex,
        },
      }),
    ),
  );
  return orderBy(stateSchemaSections, ['title', 'start'], ['asc']);
};

const PopupCalendar = (props: IPropsPopupCalendar) => {
  const { popupItems, onUpdateScheduleInSection } = props;
  const [dragingEvent, setDragingEvent] = useState<Event | undefined>(undefined);
  return (
    <DragAndDropCalendar
      resizable
      selectable
      popup
      localizer={localizer}
      onDropFromOutside={({ start, end }) => {
        if (!dragingEvent) return;
        onUpdateScheduleInSection((dragingEvent.resource as Event['resource']).sectionId, (dragingEvent.resource as Event['resource']).scheduleIndex, {
          startedAt: start as Date,
          endedAt: end as Date,
        });
        setDragingEvent(undefined);
      }}
      handleDragStart={setDragingEvent}
      events={getEventsFromSections(popupItems)}
      onEventResize={({ event, start, end }) => {
        onUpdateScheduleInSection((event.resource as Event['resource']).sectionId, (event.resource as Event['resource']).scheduleIndex, {
          startedAt: start as Date,
          endedAt: end as Date,
        });
      }}
      onEventDrop={({ event, start, end }) => {
        onUpdateScheduleInSection((event.resource as Event['resource']).sectionId, (event.resource as Event['resource']).scheduleIndex, {
          startedAt: start as Date,
          endedAt: end as Date,
        });
      }}
      views={['month', 'agenda']}
      startAccessor="start"
      endAccessor={(event) => {
        return dayjs(event.end)?.endOf('day').toDate();
      }}
      style={{ height: '100vh', minHeight: 500 }}
    />
  );
};

export default PopupCalendar;
