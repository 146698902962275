/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const ebookNonfiction = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
    <path
      fill="#8A8A8E"
      fillRule="evenodd"
      d="M12.643 1c.736 0 1.333.597 1.333 1.333v8.677c0 .417-.139.671-.32.866-.067.08-.145.151-.23.213-.113.095-.268.165-.413.245h-7.81c-.48 0-.87.298-.87.666 0 .368.39.667.87.667h7.67v.088c-.028.405-.324.823-.889 1.255H4a2 2 0 01-2-2V3a2 2 0 012-2h4.667v5.454c0 .01 0 .02.003.03l.01.028a.134.134 0 00.144.073l.034-.011 1.417-.687c.025-.012.052-.016.078-.012l.038.012 1.418.687a.132.132 0 00.186-.085L12 6.454V1h.643z"
    />
  </svg>
);

export default ebookNonfiction;
