import EndOfChapterTableFilters from '../../../app/endOfChapter/components/EndOfChapterTableFilters';
import EndOfChapterTableList from '../../../app/endOfChapter/components/EndOfChapterTableList';
import { ContentType, ProductType, TemplatePlacement, TemplatesFilterSortBy, TemplatesSortType } from '../../../core/graphql/types';
import useQueryAllCategories from '../../../hooks/useQueryAllCategories';
import useQueryTemplates from '../../../hooks/useQueryTemplates';
import { useQueryString } from '../../../utils/router';

const EndOfChapterAdPage = (): JSX.Element => {
  const queryString = useQueryString();
  const contentType = (queryString.get('contentType') as ContentType) || ContentType.Fiction;
  const productType = (queryString.get('productType') as ProductType) || ProductType.Book;

  const { templates, isLoadingTemplates } = useQueryTemplates({
    placement: TemplatePlacement.Product,
    sortBy: TemplatesFilterSortBy.Updated,
    sortType: TemplatesSortType.Desc,
    contentType,
    productType,
  });
  const { allCategories, isLoadingAllCategories } = useQueryAllCategories();
  return (
    <div>
      <div className="breadcrumbs">
        <ul>
          <li>
            <h1 className="py-5 font-dbh text-[28px]">End of chapter</h1>
          </li>
        </ul>
      </div>
      <div className="mb-16">
        <EndOfChapterTableFilters />
      </div>
      <div>
        {(isLoadingTemplates || isLoadingAllCategories) && <div className="w-full btn btn-outline btn-disabled bg-systemGrays04LabelQuaternary border-none loading h-[100px]" />}

        {!isLoadingTemplates && !isLoadingAllCategories && allCategories && <EndOfChapterTableList templatesQueryEdges={templates?.edges} allCategories={allCategories} />}
      </div>
    </div>
  );
};

export default EndOfChapterAdPage;
