import Card from '../../../components/Card';

interface IPropsCoverAndBannerDetailPresenter {
  coverFileUrl?: string;
  bannerFileUrl?: string;
}

function CoverAndBannerDetailPresenter(props: IPropsCoverAndBannerDetailPresenter) {
  const { coverFileUrl, bannerFileUrl } = props;
  return (
    <Card className="flex flex-col space-y-24 md:space-x-52 md:space-y-0 md:flex-row">
      <div className="flex flex-col space-y-4">
        <div className="text-22">Cover Image</div>
        <div className="w-[170px] h-[250px] bg-colorsBrandWarmBlack04LabelQuaternary rounded-6">
          {coverFileUrl && <img className="w-[170px] h-[250px] rounded-6 object-cover" src={coverFileUrl} />}
        </div>
      </div>
      <div className="flex flex-col flex-1 space-y-4">
        <div className="text-22">Banner Image</div>
        <div className="w-full md:w-[730px] h-[310px] bg-colorsBrandWarmBlack04LabelQuaternary rounded-6">
          {bannerFileUrl && <img className="w-full md:w-[730px] h-[310px] rounded-6 object-cover" src={bannerFileUrl} />}
        </div>
      </div>
    </Card>
  );
}

export default CoverAndBannerDetailPresenter;
