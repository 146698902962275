import classNames from 'classnames';
import { convertFromRaw, RawDraftContentState } from 'draft-js';

import contentStateToHtml from '../utils/contentRawState';

import css from './RenderContentRawState.module.scss';

// eslint-disable-next-line @typescript-eslint/ban-types
export type ContentRawState = { blocks: Array<object>; entityMap: object };

export interface IPropsRenderContentRawState {
  contentRawState?: ContentRawState | null;
  fontSize?: string;
  contentClassName?: string;
}

const defaultProps = {
  fontSize: '18px',
};

const RenderContentRawState = (props: IPropsRenderContentRawState): JSX.Element => {
  const { contentRawState, fontSize, contentClassName } = props;
  if (!contentRawState) return <div className={css.emptyContent}>ไม่พบเนื้อหา</div>;
  const htmlFromContentRawState = contentStateToHtml(convertFromRaw(contentRawState as RawDraftContentState));
  // eslint-disable-next-line react/no-danger
  return (
    <div className={classNames(css.contentRawStateElement, contentClassName)} style={{ fontSize: fontSize ?? 18 }} dangerouslySetInnerHTML={{ __html: htmlFromContentRawState }} />
  );
};

RenderContentRawState.defaultProps = defaultProps;

export default RenderContentRawState;
