import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { FeedPref, PopupNodeFragment, PopupsQuery, PopupType } from '../../../core/graphql/types';
import useMutaionCreatePopUp from '../../../hooks/useMutaionCreatePopUp';
import useMutationUpdatePopup from '../../../hooks/useMutationUpdatePopup';
import { getDayTime } from '../../../utils/dayjs';

import PopupTableItem, { IPropsPopupTableItem } from './PopupTableItem';

export interface IPropsPopupTableList {
  defaultPopupLists?: { pref: FeedPref }[];
  popupLists?: PopupsQuery['popups']['edges'];
}

const getPopupItemProps = (popupNode: Partial<PopupNodeFragment>, isLoadingMutateUpdatePopup: boolean): IPropsPopupTableItem => {
  const { isActivated, itemsCount } = popupNode;
  const _id = popupNode._id!;
  const pref = popupNode.pref!;
  const type = popupNode.type!;
  const updatedAt = getDayTime(popupNode.updatedAt);
  const activeItemsCount = itemsCount?.activeItems || 0;
  const totalItemsCount = itemsCount?.totalItems || 0;

  return {
    _id,
    pref,
    type,
    isActivated,
    updatedAt,
    activeItemsCount,
    totalItemsCount,
    isLoadingMutateUpdatePopup,
  };
};

const PopupTableList = (props: IPropsPopupTableList) => {
  const alreadyItemsNode = props.popupLists?.map((edege) => edege.node.pref) || [];
  const defaultPopupLists = props.defaultPopupLists?.filter((popup) => !alreadyItemsNode.includes(popup.pref));

  const history = useHistory();

  const { mutateCreatePopup, isLoadingMutateCreatePopup } = useMutaionCreatePopUp();
  const { mutateUpdatePopup, isLoadingMutateUpdatePopup } = useMutationUpdatePopup();

  const onCreatePopup = useCallback(
    async (pref: FeedPref) => {
      const res = await mutateCreatePopup({ variables: { input: { pref, type: PopupType.Startup } } });
      const popupId = res?.data?.createPopup._id;
      if (popupId) {
        history.push(`/suggestProducts/popUp/edit?popupId=${popupId}`);
      }
    },
    [history, mutateCreatePopup],
  );

  const onTogglePublish = useCallback(
    (popupId: string, isActivated: boolean) => {
      mutateUpdatePopup({ variables: { popupId, input: { isActivated } } });
    },
    [mutateUpdatePopup],
  );

  return (
    <div>
      <table className="table w-full overflow-y-scroll">
        <thead>
          <tr>
            <th>Popup Type</th>
            <th className="!rounded-bl-0">Feed Preference</th>
            <th>Active Items</th>
            <th>Total Items</th>
            <th>Last Update</th>
            <th>Publish</th>
            <th className="!rounded-br-0">Actions</th>
          </tr>
        </thead>
        <tbody>
          {props.popupLists?.map((edge) => (
            <PopupTableItem
              {...getPopupItemProps(edge.node, isLoadingMutateUpdatePopup)}
              onTogglePublish={() => onTogglePublish(edge.node._id, !edge.node.isActivated)}
              key={edge.node._id}
            />
          ))}
          {defaultPopupLists?.map((edge) => (
            <PopupTableItem
              {...edge}
              isLoadingMutateUpdatePopup={false}
              isLoadingMutateCreatePopup={isLoadingMutateCreatePopup}
              key={edge.pref}
              onCreatePopup={() => onCreatePopup(edge.pref)}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PopupTableList;
