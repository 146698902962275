import { useCallback, useEffect } from 'react';

import { usePintoEbookBundleSubmissionsLazyQuery } from '../core/graphql/operation-hooks';

const useQueryPintoEbookBundleSubmissions = () => {
  const [queryPintoEbookBundleSubmissions, { data, loading }] = usePintoEbookBundleSubmissionsLazyQuery({ fetchPolicy: 'network-only' });

  const fetchPintoEbookBundleSubmissions = useCallback(() => {
    queryPintoEbookBundleSubmissions();
  }, [queryPintoEbookBundleSubmissions]);

  useEffect(() => fetchPintoEbookBundleSubmissions(), [fetchPintoEbookBundleSubmissions]);

  return {
    fetchPintoEbookBundleSubmissions,
    ebookBundleSubmissions: data?.ebookBundleSubmissions,
    isLoadingPintoEbookBundleSubmissions: loading,
  };
};

export default useQueryPintoEbookBundleSubmissions;
