/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const checkboxFill = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path
      fill="#8C8C8C"
      fillRule="evenodd"
      d="M38.778 5C41.12 5 43 6.9 43 9.222v29.556A4.221 4.221 0 0138.778 43H9.222A4.221 4.221 0 015 38.778V9.222A4.221 4.221 0 019.222 5h29.556zm-27.78 20.619L20.173 35l16.83-17.177a2.065 2.065 0 00.006-2.867l-.9-.927a1.94 1.94 0 00-2.791-.012L20.147 27.462l-5.488-5.587a1.956 1.956 0 00-2.809.006l-.85.868a2.052 2.052 0 00-.002 2.87z"
    />
  </svg>
);

export default checkboxFill;
