import { useCallback } from 'react';

import { usePintoDeleteSectionMutation } from '../core/graphql/operation-hooks';

const useMutationPintoDeleteSection = () => {
  const [mutatePintoDeleteSection] = usePintoDeleteSectionMutation();

  const deletePintoSection = useCallback(
    (id: string) => {
      mutatePintoDeleteSection({
        variables: { id },
        refetchQueries: ['PintoSections'],
      });
    },
    [mutatePintoDeleteSection],
  );

  return {
    deletePintoSection,
  };
};

export default useMutationPintoDeleteSection;
