import { useEffect } from 'react';

import { usePopupLazyQuery } from '../core/graphql/operation-hooks';

export type IPropsUseQueryPopupById = {
  _id: string | null;
};

const useQueryPopupById = (props: IPropsUseQueryPopupById) => {
  const { _id } = props;
  const [queryPopupById, { data, loading }] = usePopupLazyQuery();

  useEffect(() => {
    if (!_id) return;
    queryPopupById({
      variables: { id: _id },
    });
  }, [_id, queryPopupById]);

  return {
    queryPopupById,
    popupQueryResult: data?.popup,
    isLoadingQueryPopupById: loading,
  };
};

export default useQueryPopupById;
