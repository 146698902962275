import { useFormContext } from 'react-hook-form';

import { SORT_BY_PRODUCT_SET_OPTIONS } from '../validates/sectionBuilderSchema';

import DropdownSelectNested from './DropdownSelectNested';
import SelectPintoCategoryWrapper from './SelectPintoCategory';

export type SearchType = 'ebook' | 'user';

const ProductSetSectionTarget = () => {
  const { watch } = useFormContext();
  const [categoryId] = watch(['target.categoryId']);
  return (
    <div className="space-y-8 py-8">
      <DropdownSelectNested label="SortBy" required options={SORT_BY_PRODUCT_SET_OPTIONS} prefixName={`target`} name={[`target.sortBy`, 'target.sortType']} />
      <SelectPintoCategoryWrapper name={`target.categoryId`} categoryId={categoryId} />
    </div>
  );
};

export default ProductSetSectionTarget;
