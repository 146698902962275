import { useCallback } from 'react';

import { dispatch, useStoreState } from '../../context/appContext';
import { IModalNames, IModalPayload } from '../../context/modalReducer';

export type ActionModalsMap<M> = {
  [Key in keyof M]: {
    modalName: Key | IModalNames;
    modalPayload?: M[Key];
  };
};

export type IOpenModalActions = ActionModalsMap<IModalPayload>[keyof ActionModalsMap<IModalPayload>];

const useModal = () => {
  const modals = useStoreState('modals');
  const openModal = useCallback((obj: IOpenModalActions) => {
    if (obj?.modalName) {
      dispatch({ type: 'OPEN_MODAL', modalName: obj.modalName as never, modalPayload: obj.modalPayload as never });
    }
  }, []);
  const removeModal = useCallback((modalName: keyof IModalPayload) => {
    dispatch({ type: 'REMOVE_MODAL', modalName: modalName as IModalNames });
  }, []);

  return {
    modals,
    openModal,
    removeModal,
  };
};

export default useModal;
