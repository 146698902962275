import { useCallback } from 'react';

import { usePintoUploadRequestedEbookFileMutation } from '../core/graphql/operation-hooks';
import { UploadRequestedEbookFileInput } from '../core/graphql/types';

const useMutationPintoUploadRequestedEbookFile = () => {
  const [mutatePintoUploadRequestedEbookFile, { loading }] = usePintoUploadRequestedEbookFileMutation();

  const onMutatePintoUploadRequestedEbookFile = useCallback(
    async (ebookId: string, input: UploadRequestedEbookFileInput) => {
      const res = await mutatePintoUploadRequestedEbookFile({
        variables: {
          ebookId,
          input,
        },
      });
      return !!res.data?.uploadRequestedEbookFile?.success;
    },
    [mutatePintoUploadRequestedEbookFile],
  );

  return {
    onMutatePintoUploadRequestedEbookFile,
    isLoadingMutatePintoUploadRequestedEbookFile: loading,
  };
};

export default useMutationPintoUploadRequestedEbookFile;
