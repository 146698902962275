import classNames from 'classnames';
import { isNil } from 'lodash';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';

import Pagination from '../../../components/Pagination';
import { SubscriptionTitle } from '../../../core/graphql/types';
import { getDayTime } from '../../../utils/dayjs';

const PAGE_LIMIT = 10;
const DATE_FORMAT = 'DD-MM-YYYY hh:mm A';

export interface ISubscriptionTitleItem {
  id: string;
  createDate: string;
  updateDate: string;
  title: string;
  userAccount: string;
  ebookCount: number;
  publishCount: number;
  activatedPackageCount: {
    web: number;
    ios: number;
    android: number;
  };
}

export interface IPropsSubscriptionsTablePresenter {
  subscriptionTitleItems: SubscriptionTitle[];
  currentPage: number;
  pageLimit?: number;
  totalCount?: number;
  onChangePage: (page: number) => void;
}

function SubscriptionsTablePresenter(props: IPropsSubscriptionsTablePresenter) {
  const { subscriptionTitleItems, currentPage, pageLimit, totalCount, onChangePage } = props;

  const mappedSubscriptionTitleItems = useMemo(
    () =>
      subscriptionTitleItems.map(
        (item): ISubscriptionTitleItem => ({
          id: item?._id,
          createDate: item?.createdAt,
          updateDate: item?.updatedAt,
          title: item?.title || '',
          userAccount: item?.user?.displayName || '',
          ebookCount: item?.ebooksCount || 0,
          publishCount: item?.publishedEbooksCount || 0,
          activatedPackageCount: {
            web: item?.packages?.filter((pkg) => !isNil(pkg?.priceThb)).length ?? 0,
            ios: item?.packages?.filter((pkg) => !isNil(pkg?.applePriceTierId)).length ?? 0,
            android: item?.packages?.filter((pkg) => !isNil(pkg?.googlePriceTierId)).length ?? 0,
          },
        }),
      ),
    [subscriptionTitleItems],
  );

  return (
    <div>
      <div className="mt-12 overflow-auto rounded-md">
        <table className="table w-full table-zebra font-tt">
          <thead>
            <tr>
              <th>ID</th>
              <th>Time Stamps</th>
              <th>Title</th>
              <th>User Account</th>
              <th>Total Ebook</th>
              <th>Publish</th>
              <th>Open for Subscription</th>
            </tr>
          </thead>
          <tbody>
            {mappedSubscriptionTitleItems?.map((item) => (
              <tr key={item.id}>
                <th className="font-normal">
                  <Link to={`/subscriptions/edit?id=${item.id}`} className="underline text-colorsBrandRed01Primary">
                    {item.id}
                  </Link>
                </th>
                <td>
                  {
                    <div>
                      <div className="flex gap-2 text-colorsBrandWarmBlack02LabelSecondary">
                        <div className="w-[85px]">Created At:</div>
                        <span>{getDayTime(item?.createDate || '', DATE_FORMAT)}</span>
                      </div>
                      <div className="flex gap-2 text-colorsBrandWarmBlack02LabelSecondary">
                        <div className="w-[85px]">Last Update:</div>
                        {item?.updateDate ? <span>{getDayTime(item?.updateDate || '', DATE_FORMAT)}</span> : <span>-</span>}
                      </div>
                    </div>
                  }
                </td>
                <td className="max-w-xs truncate" title={item.title}>
                  {item.title}
                </td>
                <td>{item.userAccount}</td>
                <td>{item.ebookCount}</td>
                <td>{item.publishCount}</td>
                <td>
                  <div
                    className={classNames({
                      'badge badge-warning': item.activatedPackageCount.web > 0,
                      'badge badge-outline': item.activatedPackageCount.web <= 0,
                    })}
                    style={{ marginRight: '8px' }}
                  >
                    WEB ({item.activatedPackageCount.web})
                  </div>
                  <div
                    className={classNames({
                      'badge badge-warning': item.activatedPackageCount.ios > 0,
                      'badge badge-outline': item.activatedPackageCount.ios <= 0,
                    })}
                    style={{ marginRight: '8px' }}
                  >
                    IOS ({item.activatedPackageCount.ios})
                  </div>
                  <div
                    className={classNames({
                      'badge badge-warning': item.activatedPackageCount.android > 0,
                      'badge badge-outline': item.activatedPackageCount.android <= 0,
                    })}
                  >
                    AND ({item.activatedPackageCount.android})
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="flex items-center justify-between mt-12">
        <span className="text-systemGrays02LabelSecondary">แสดงผล {PAGE_LIMIT} รายการต่อหน้า</span>

        {(totalCount || 0) > 0 && <Pagination currentPage={currentPage} totalCount={totalCount || 0} pageSize={pageLimit || PAGE_LIMIT} onPageChange={onChangePage} />}
      </div>
    </div>
  );
}

export default SubscriptionsTablePresenter;
