import { useEffect, useState, useCallback, useMemo } from 'react';
import { FiX } from 'react-icons/fi';

import ProgressBar from '../components/ProgressBar';
import { IModalNames } from '../core/context/modalReducer';
import useModal from '../core/hook/context/useModal';

type UploadFileFunc = (file: File, onProgress: (percentage: number) => void, abortController?: AbortController) => Promise<string | undefined>;

interface IPropsProgressUploadFileItem {
  file: File;
  fileLabel: string;
  onUploadFile: UploadFileFunc;
  onUploadFileSuccess: (res: string | undefined) => void;
  abortController: AbortController;
}

interface UploadFileItem {
  key: string;
  file: File;
  fileLabel: string;
}

interface UploadResponseData {
  fileUrl?: string;
  fileName?: string;
}
interface UploadResponse {
  [key: string]: UploadResponseData;
}
export interface IPropsProgressUploadFileModal {
  title?: string;
  onClose?: (abortContoller?: AbortController) => void;
  uploadFileItems: UploadFileItem[];
  onUploadFile: UploadFileFunc;
  onUploadFileSuccess?: (uploadResponse: UploadResponse) => void;
}

const ProgressUploadFileItem = (props: IPropsProgressUploadFileItem) => {
  const { removeModal } = useModal();
  const { file, fileLabel, onUploadFile, onUploadFileSuccess, abortController } = props;
  const [percentValue, setPercentValue] = useState<number>(0);

  const handleUploadFile = useCallback(
    async (_file: File) => {
      try {
        const res = await onUploadFile(
          _file,
          (percentage) => {
            setPercentValue(percentage);
          },
          abortController,
        );
        if (res) {
          onUploadFileSuccess(res);
        } else {
          alert(`เกิดข้อผิดพลาด`);
          removeModal(IModalNames.PROGRESS_UPLOAD_FILE);
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        alert(`เกิดข้อผิดพลาด${error?.message ?? ''}`);
        removeModal(IModalNames.PROGRESS_UPLOAD_FILE);
        console.error(error);
      }
    },
    [abortController, onUploadFile, onUploadFileSuccess, removeModal],
  );

  useEffect(() => {
    if (file) {
      handleUploadFile(file);
    }
  }, [file, handleUploadFile]);

  return <ProgressBar percentValue={percentValue} label={fileLabel} />;
};

const ProgressUploadFileModal = (props: IPropsProgressUploadFileModal) => {
  const { title, onClose, uploadFileItems, onUploadFile, onUploadFileSuccess } = props;
  const { removeModal } = useModal();
  const [uploadResponse, setUploadResponse] = useState<UploadResponse>({});
  const [abortController, setAbortController] = useState<AbortController>();

  const onCloseProgressUploadFileModal = () => {
    if (onClose) {
      onClose(abortController);
    } else {
      removeModal(IModalNames.PROGRESS_UPLOAD_FILE);
    }
  };

  const uploadFileList = useMemo(() => {
    const controller = new AbortController();
    setAbortController(controller);
    return uploadFileItems.map((item) => (
      <ProgressUploadFileItem
        key={item.key}
        file={item.file}
        fileLabel={item.fileLabel}
        onUploadFile={onUploadFile}
        onUploadFileSuccess={(res) => {
          const _uploadResponse: UploadResponseData = {};
          if (res) {
            _uploadResponse.fileUrl = res;
            _uploadResponse.fileName = item.file.name;
          }
          setUploadResponse((prevState) => ({
            ...prevState,
            [item.key]: _uploadResponse,
          }));
        }}
        abortController={controller}
      />
    ));
  }, [uploadFileItems, onUploadFile]);

  useEffect(() => {
    if (uploadFileItems && uploadFileItems.length > 0 && uploadFileItems.length === Object.keys(uploadResponse).length) {
      onUploadFileSuccess?.(uploadResponse);
    }
  }, [uploadFileItems, uploadResponse, onUploadFileSuccess]);

  return (
    <div className="modal modal-open">
      <div className="modal-box relative bg-colorsWhite w-[622px]">
        <FiX size="32px" className="absolute top-8 right-8 cursor-pointer" onClick={onCloseProgressUploadFileModal} />
        <div className="flex flex-col p-16 pb-32 md:p-32 md:pb-48">
          <h4 className="mt-8 text-center font-opn text-22 font-semibold text-colorsBrandWarmBlack01LabelPrimary">{title || 'กำลังอัปโหลดไฟล์'}</h4>
          <div className="mt-24 space-y-24">{uploadFileList}</div>
        </div>
      </div>
    </div>
  );
};

export default ProgressUploadFileModal;
