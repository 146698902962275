/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const bookmark = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path
      fill="#8C8C8C"
      fillRule="evenodd"
      d="M14 5h20a4 4 0 014 4v32.235a1 1 0 01-1.53.848L23.514 34l-11.957 7.997A1 1 0 0110 41.166V9a4 4 0 014-4z"
    />
  </svg>
);

export default bookmark;
