/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const edit = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path
      fill="#8C8C8C"
      fillRule="evenodd"
      d="M34.664 5.088a3.712 3.712 0 015.25 0l2.999 2.998a3.713 3.713 0 010 5.25l-3.204 3.26a.928.928 0 01-1.313 0L31.461 9.66a.928.928 0 010-1.312l3.203-3.26zM5.414 35.005c.068-.38.25-.729.523-1.002l21.486-21.412a.941.941 0 011.33 0l6.746 6.73a.941.941 0 01.001 1.332L14.015 42.064a1.882 1.882 0 01-1.004.524l-7.906 1.398a.941.941 0 01-1.09-1.092l1.4-7.889z"
    />
  </svg>
);

export default edit;
