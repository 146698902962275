import { useCallback } from 'react';

import { usePintoUpdateSectionMutation } from '../core/graphql/operation-hooks';
import { SectionInput } from '../core/graphql/types';

const useMutationPintoUpdateSection = () => {
  const [mutatePintoUpdateSection, { loading }] = usePintoUpdateSectionMutation();

  const onMutatePintoUpdateSection = useCallback(
    async (id: string, input: SectionInput) => {
      const res = await mutatePintoUpdateSection({ variables: { id, input } });
      return !!res.data?.pintoUpdateSection?._id;
    },
    [mutatePintoUpdateSection],
  );

  return {
    onMutatePintoUpdateSection,
    isLoadingMutatePintoUpdateSection: loading,
  };
};

export default useMutationPintoUpdateSection;
