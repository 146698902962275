/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const upload = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path
      fill="#8C8C8C"
      fillRule="evenodd"
      d="M32 19v13a1 1 0 01-1 1H17a1 1 0 01-1-1V19H8.762a1 1 0 01-.64-1.768L24 4l15.878 13.232a1 1 0 01-.64 1.768H32zM6 43.01v-4.02A1 1 0 017 38h34a1 1 0 011 .99v4.02a1 1 0 01-1 .99H7a1 1 0 01-1-.99z"
    />
  </svg>
);

export default upload;
