import classNames from 'classnames';
import { useState, ChangeEvent, useEffect } from 'react';
import { FiTrash } from 'react-icons/fi';

export interface IPropsConfirmInputModal {
  onClose?: () => void;
  onSubmit?: (input: string) => void;
  title?: string;
  subTitle?: string;
  cancelText?: string;
  confirmText?: string;
  errorMsg?: string;
}

const ConfirmInputModal = (props: IPropsConfirmInputModal) => {
  const [inputValue, setInputValue] = useState('');
  const [isValid, setIsValid] = useState(true);
  const [localErrorMsg, setLocalErrorMsg] = useState('');
  const { onClose, onSubmit, cancelText, confirmText, title, subTitle, errorMsg } = props;

  useEffect(() => {
    if (errorMsg) {
      setIsValid(false);
      setLocalErrorMsg(errorMsg);
    }
  }, [errorMsg]);

  const validateInput = (input: string) => {
    const regex = /^[0-9a-fA-F]{24}$/;
    const isValidInput = regex.test(input);
    setIsValid(isValidInput);
    // Set localErrorMsg based on validation
    if (!isValidInput) {
      setLocalErrorMsg('Invalid format.');
    } else {
      setLocalErrorMsg(''); // Reset error message if input is valid
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInputValue(value);
    validateInput(value);
  };

  const handleConfirm = () => {
    if (errorMsg) {
      setIsValid(false);
    }
    if (isValid && onSubmit) {
      onSubmit(inputValue);
    }
  };

  const handleClose = () => {
    onClose?.();
    setInputValue('');
    setIsValid(true);
    setLocalErrorMsg(''); // Reset localErrorMsg when closing
  };

  return (
    <div className="modal modal-open">
      <div className="modal-box relative">
        <h3 className="text-lg font-bold font-dbh text-systemGrays01LabelPrimary">{title || 'Delete Bundle'}</h3>
        {subTitle && <p className="font-tt font-bold pt-4 text-systemGrays02LabelSecondary">{subTitle}</p>}
        <div className="relative">
          <FiTrash className="absolute top-[16px] left-[12px] text-colorsBrandWarmBlack02LabelSecondary text-18" />
          <input
            id="bundleId"
            type="text"
            placeholder="Bundle ID"
            className={classNames('w-full pl-36 input input-bordered', {
              'border-red-500 text-red-500': !isValid,
            })}
            onChange={handleChange}
            value={inputValue}
          />
          {!isValid && <p className="text-red-500 text-xs mt-1">{localErrorMsg}</p>}
        </div>
        <div className="w-full flex justify-end space-x-6 pt-24">
          <button className="btn btn-outline btn-error" onClick={handleClose}>
            {cancelText || 'Cancel'}
          </button>
          <button className={classNames('btn btn-error', { 'opacity-50 cursor-not-allowed': !isValid })} onClick={handleConfirm} disabled={!isValid || !inputValue}>
            {confirmText || 'Delete'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmInputModal;
