import classNames from 'classnames';
import { isEmpty, isNull } from 'lodash';
import { FieldValues, useFormContext, UseFormRegister } from 'react-hook-form';

export type IInputValue = string | number | boolean | null | undefined;
export interface IPropsRadioGroupButton {
  name: string;
  options?: { label: string; value?: IInputValue | IInputValue[] }[];
  label?: string;
  className?: string;
  required?: boolean;
  helperText?: string;
  isInvalid?: boolean;
  register?: UseFormRegister<FieldValues>;
  isSetArrayValue?: boolean;
  onSetActiveButton?: (value?: IInputValue | IInputValue[]) => void;
}
const RadioGroupButton = (props: IPropsRadioGroupButton) => {
  const { options, label, name, required, className, helperText, isInvalid, register, isSetArrayValue, onSetActiveButton } = props;
  const { watch, setValue } = useFormContext();
  const [watchList] = watch([name]);

  return (
    <div className={classNames('form-control', className)}>
      {label && (
        <span className="label-text text-systemGrays02LabelSecondary">
          {label} {required && <span className="text-colorsBrandRed01Primary">*</span>}
        </span>
      )}
      <div className="flex flex-wrap py-8 space-x-8">
        {!isSetArrayValue &&
          options?.map((option) => (
            <label key={option.label} className="relative cursor-pointer">
              <input
                type="radio"
                {...register?.(name)}
                value={(isNull(option?.value) ? undefined : option?.value) as string | number | undefined}
                className="absolute invisible peer t-0"
              />
              <div
                className={classNames('border border-systemGrays01LabelPrimary px-12 py-8 rounded-[8px] w-[fit-content]', {
                  'border-colorsBrandRed01Primary text-colorsBrandRed01Primary': watchList == option.value || (!watchList && !option.value) || onSetActiveButton?.(watchList),
                })}
              >
                {option.label}
              </div>
            </label>
          ))}

        {isSetArrayValue &&
          options?.map((option) => (
            <label key={option.label} className="relative cursor-pointer" onClick={() => setValue(name, option.value)}>
              <div
                className={classNames('border border-systemGrays01LabelPrimary px-12 py-8 rounded-[8px] w-[fit-content]', {
                  'border-colorsBrandRed01Primary text-colorsBrandRed01Primary': String(watchList) == option.value?.toString() || (isEmpty(watchList) && isEmpty(option?.value)),
                })}
              >
                {option.label}
              </div>
            </label>
          ))}
      </div>
      {helperText && (
        <label className="label">
          <span
            className={classNames('label-text-alt text-systemGrays02LabelSecondary font-tt', {
              'text-colorsBrandRed01Primary': isInvalid,
            })}
          >
            {helperText}
          </span>
        </label>
      )}
    </div>
  );
};

export default RadioGroupButton;
