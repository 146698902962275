/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const add = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path
      fill="#8C8C8C"
      fillRule="evenodd"
      d="M21 21V5.16a4.403 4.403 0 016 0V21h15.822a4.353 4.353 0 010 6H27v15.83a4.376 4.376 0 01-6 0V27H5.14a4.466 4.466 0 010-6H21z"
    />
  </svg>
);

export default add;
