/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const touch = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path
      fill="#8C8C8C"
      fillRule="evenodd"
      d="M24 47C11.297 47 1 36.703 1 24S11.297 1 24 1s23 10.297 23 23-10.297 23-23 23zm0-2c11.598 0 21-9.402 21-21S35.598 3 24 3 3 12.402 3 24s9.402 21 21 21zm0-5c-8.837 0-16-7.163-16-16S15.163 8 24 8s16 7.163 16 16-7.163 16-16 16zm0-5c6.075 0 11-4.925 11-11s-4.925-11-11-11-11 4.925-11 11 4.925 11 11 11z"
    />
  </svg>
);

export default touch;
