/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const report = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path
      fill="#8C8C8C"
      fillRule="evenodd"
      d="M32.65 11.043h8.338a1 1 0 011.007 1.001v20.952a.998.998 0 01-1.007 1H32.65c-1.113 0-2.665-.605-3.482-1.351l-2.508-2.293c-.82-.75-2.372-1.352-3.473-1.352h-9.163v13.007a2 2 0 01-2.006 1.992h-2.012A1.998 1.998 0 018 41.996V6h15.177c1.105 0 2.66.605 3.469 1.351l2.536 2.34c.806.744 2.362 1.352 3.468 1.352z"
    />
  </svg>
);

export default report;
