import PopupTableList from '../../../app/popup/components/PopupTableList';
import { FeedPref } from '../../../core/graphql/types';
import useQueryPopups from '../../../hooks/useQueryPopups';

const defaultPopupLists = [{ pref: FeedPref.Default }, { pref: FeedPref.Female }, { pref: FeedPref.Male }, { pref: FeedPref.Y }];

const PopupPage = (): JSX.Element => {
  const { popups, isLoadingPopups } = useQueryPopups();

  return (
    <div>
      <div className="breadcrumbs">
        <ul>
          <li>
            <h1 className="py-5 font-dbh text-[28px]">Popup</h1>
          </li>
        </ul>
      </div>
      <div>
        {isLoadingPopups && <div className="w-full btn btn-outline btn-disabled bg-systemGrays04LabelQuaternary border-none loading h-[100px]" />}
        {!isLoadingPopups && <PopupTableList popupLists={popups?.edges} defaultPopupLists={defaultPopupLists} />}
      </div>
    </div>
  );
};

export default PopupPage;
