/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const textBold = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path
      fill="#8C8C8C"
      fillRule="evenodd"
      d="M36.79 17.143c0 2.331-1.644 4.846-3.99 6.377 3.12 1.371 5.2 4.343 5.2 7.817C38 36.114 34.082 40 29.027 40H14.003A1.999 1.999 0 0112 37.998V10.002A2 2 0 0114.007 8h13.11c5.441 0 9.674 3.977 9.674 9.143zM25.668 14h-5.669c-.55 0-.998.449-.998 1.003v4.994A.993.993 0 0019.998 21h5.669C27.51 21 29 19.437 29 17.5S27.511 14 25.667 14zm-5.66 20H26.7c1.826 0 3.3-1.563 3.3-3.5S28.526 27 26.7 27h-6.693c-.56 0-1.007.449-1.007 1.003v4.994A.998.998 0 0020.007 34z"
    />
  </svg>
);

export default textBold;
