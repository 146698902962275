import { history } from 'prosemirror-history';

import { disableDrop } from './borwserEvents';
import { deleteEmptyFigure } from './image';
import { baseKeys, editorKeys } from './keys';
import { placeholder } from './placeholder';

export const plugins = [
  history(),
  placeholder(),
  // hot-keys
  editorKeys(),
  baseKeys(), // last one for override hot-keys
  // browser events
  disableDrop(),
  // editor
  deleteEmptyFigure(),
];

export default plugins;
