import { FieldValues, UseFormRegister } from 'react-hook-form';

import Input from '../../../components/form/Input';
import { IConfigItemType } from '../validates/sectionBuilderSchema';

import RenderObjectLabel from './RenderObjectLabel';

export interface IPropsLinkItem {
  type: IConfigItemType.Link;
  url: string;
  title?: string;
  description?: string;
  coverImage?: string;
  bannerImage?: string;
  popupImageUrl?: string;
}
interface IPropsLinkSectionItem {
  index: number;
  itemData?: IPropsLinkItem;

  register: UseFormRegister<FieldValues>;
}
const LinkSectionItem = (props: IPropsLinkSectionItem) => {
  const { index, itemData, register } = props;

  return (
    <div className="flex justify-between space-x-16">
      <div className="w-full collapse">
        <input type="checkbox" className="peer" />
        <div className=" collapse-title bg-systemGrays08FillQuaternary text-systemGrays01LabelPrimary peer-checked:bg-systemGrays08FillQuaternary peer-checked:text-systemGrays01LabelPrimary flex justify-between after:content-['+'] peer-checked:after:content-['-'] ease-out duration-300">
          <span>
            <span>Link </span>
            <RenderObjectLabel objectLists={[{ key: 'url', value: itemData?.url, required: true }]} />
          </span>
        </div>
        <div className="collapse-content text-systemGrays01LabelPrimary peer-checked:bg-systemGrays08FillQuaternary peer-checked:text-systemGrays01LabelPrimary">
          <div className="space-y-8">
            {/* TODO: helperText "0/120 characters" */}
            <Input label="url" name="url" helperText="Input link ID" placeholder="url" register={() => register(`configItems.${index}.url`)} required />
            <Input label="title" name="title" helperText="Input user ID" placeholder="0/40 characters" register={() => register(`configItems.${index}.title`)} />
            <Input label="Description" name="description" helperText="0/120 characters" placeholder="Description" register={() => register(`configItems.${index}.description`)} />
            <Input
              label="bannerImage"
              name="bannerImage"
              helperText="Input bannerImage url, use for Layout: Banner type"
              placeholder="bannerImage"
              register={() => register(`configItems.${index}.bannerImage`)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LinkSectionItem;
