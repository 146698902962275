/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const minus = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path
      fill="#8C8C8C"
      fillRule="evenodd"
      d="M5.17 21h37.66a4.376 4.376 0 010 6H5.17a4.376 4.376 0 010-6z"
    />
  </svg>
);

export default minus;
