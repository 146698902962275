import classnames from 'classnames';
import { MouseEventHandler, ReactNode } from 'react';

const COLOR_SCHEME = {
  red: 'btn-error',
  gray: '',
  black: '',

  fill: '',
  outline: 'btn btn-outline',
  ghost: 'btn btn-ghost',
};

export interface IPropsButton {
  block?: boolean;
  children?: ReactNode;
  color: 'red' | 'gray' | 'black';
  type: 'fill' | 'outline' | 'ghost';
  disabled?: boolean;
  round?: boolean;
  id?: string;
  onClick: MouseEventHandler<HTMLButtonElement>;
  className?: string;
}

const defaultProps = {
  children: undefined,
  disabled: false,
  id: undefined,
  onClick: undefined,
  round: false,
};

const Button = (props: IPropsButton) => {
  const { color = 'black', type = 'fill', children, disabled, round, id, onClick, className } = props;

  return (
    <button
      type="button"
      className={classnames('btn h-[48px] min-h-[24px] normal-case', COLOR_SCHEME[type], COLOR_SCHEME[color], className, { 'rounded-full': round })}
      disabled={disabled}
      id={id}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

Button.defaultProps = defaultProps;

export default Button;
