import classNames from 'classnames';
import { useMemo } from 'react';

import { IPriceTier } from '../app/modals/setPriceModal/hook/useStatePriceTier';
import { numberWithCommasWithDecimalPlace } from '../utils/convertNumber';
import { getDayTime } from '../utils/dayjs';
import { getDiscountPercent } from '../utils/price';

export interface IPropsSummaryPrice {
  className?: string;
  isFree?: boolean;
  webPrice?: number;
  webDiscountPercent?: number;
  webDiscountPrice?: number;
  googlePrice?: IPriceTier;
  discountGooglePrice?: IPriceTier;
  applePrice?: IPriceTier;
  discountApplePrice?: IPriceTier;
  haveDiscount?: boolean;
  discountStartDate?: Date;
  discountEndDate?: Date;
  language?: 'TH' | 'EN';
  isHasDiscountEndDate?: boolean;
}

const SummaryPrice = (props: IPropsSummaryPrice) => {
  const {
    className,
    isFree,
    webPrice = 0,
    webDiscountPercent,
    webDiscountPrice,
    googlePrice,
    discountGooglePrice,
    applePrice,
    discountApplePrice,
    haveDiscount,
    discountStartDate,
    discountEndDate,
    language = 'TH',
    isHasDiscountEndDate = true,
  } = props;

  const calculatedWebDiscountPrice = useMemo(() => {
    if (webDiscountPrice) {
      return webPrice - webDiscountPrice;
    } else if (webDiscountPercent) {
      return webPrice - (webPrice * webDiscountPercent) / 100;
    }
    return webPrice;
  }, [webDiscountPercent, webDiscountPrice, webPrice]);

  const actualWebDiscountPercent = useMemo<number | undefined>(() => {
    if (webDiscountPercent) {
      return Math.ceil(webDiscountPercent);
    } else if (webDiscountPrice) {
      return Math.ceil((webDiscountPrice * 100) / webPrice);
    }
  }, [webDiscountPercent, webDiscountPrice, webPrice]);

  const googleDiscountPercent = useMemo(() => {
    if (discountGooglePrice && googlePrice) return getDiscountPercent(googlePrice.thb ?? 0, discountGooglePrice.thb ?? 0);
    return undefined;
  }, [discountGooglePrice, googlePrice]);
  const totalGooglePrice = useMemo(() => {
    return discountGooglePrice || googlePrice;
  }, [discountGooglePrice, googlePrice]);
  const appleDiscountPercent = useMemo(() => {
    if (discountApplePrice && applePrice) return getDiscountPercent(applePrice.thb ?? 0, discountApplePrice.thb ?? 0);
    return undefined;
  }, [applePrice, discountApplePrice]);

  const totalApplePrice = useMemo(() => {
    return discountApplePrice || applePrice;
  }, [applePrice, discountApplePrice]);

  const bahtText = useMemo(() => {
    return language === 'TH' ? 'บาท' : 'Baht';
  }, [language]);

  const freeText = useMemo(() => {
    return language === 'TH' ? 'ฟรี' : 'Free';
  }, [language]);
  return (
    <div className="w-full flex flex-col">
      <table className={classNames('w-full table-auto', className)}>
        <thead className="bg-colorsBrandWarmBlack07FillTertiary">
          <tr>
            <th scope="col" className="w-1/3 whitespace-nowrap text-left pl-8 py-4">
              {language === 'TH' ? 'ช่องทางการขาย' : 'Platform'}
            </th>
            <th scope="col" className="w-1/3 whitespace-nowrap text-left py-4">
              {language === 'TH' ? 'ราคาขาย' : 'Original Price'}
            </th>
            <th scope="col" className="w-1/3 whitespace-nowrap text-right pr-8 py-4">
              {language === 'TH' ? 'ราคาสุทธิ' : 'Sell Price'}
            </th>
          </tr>
        </thead>
        <tbody className="rounded-lg">
          {/** Web */}
          <tr className="border-b">
            <th scope="row" className="whitespace-nowrap px-12 py-16">
              <div className="flex h-[32px] items-center">
                <label className="label">
                  <span className="label-text text-systemGrays02LabelSecondary">{language === 'TH' ? 'เว็บไซต์' : 'Website'}</span>
                </label>
              </div>
            </th>
            <th scope="row" className="flex items-center space-x-12 whitespace-nowrap py-16">
              {isFree && freeText}
              {!isFree && <span>{webPrice ? `${numberWithCommasWithDecimalPlace(webPrice)} ${bahtText}` : '-'}</span>}
              {!isFree && actualWebDiscountPercent && <span className="p-4 bg-colorsBrandRed01Primary text-colorsWhite rounded-8">{`-${actualWebDiscountPercent}%`}</span>}
            </th>
            <th scope="row" className="whitespace-nowrap pr-8 py-16 text-right">
              {isFree && freeText}
              {!isFree && ((calculatedWebDiscountPrice || 0) >= 0 ? `${numberWithCommasWithDecimalPlace(calculatedWebDiscountPrice)} ${bahtText}` : '-')}
            </th>
          </tr>
          {/** End of Web */}
          {/** Google */}
          <tr className="border-b">
            <th scope="row" className="whitespace-nowrap px-12 py-16">
              <div className="flex h-[32px] items-center">
                <label className="label">
                  <span className="label-text text-systemGrays02LabelSecondary">Google Play</span>
                </label>
              </div>
            </th>
            <th scope="row" className="flex items-center space-x-12 whitespace-nowrap py-16">
              {isFree && freeText}
              {!isFree && <span>{googlePrice ? `${numberWithCommasWithDecimalPlace(googlePrice.thb)} ${bahtText}` : '-'}</span>}
              {!isFree && googleDiscountPercent && <span className="p-4 bg-colorsBrandRed01Primary text-colorsWhite rounded-8">{`-${googleDiscountPercent}%`}</span>}
            </th>
            <th scope="row" className="whitespace-nowrap pr-8 py-16 text-right">
              {isFree && freeText}
              {!isFree && (totalGooglePrice ? `${numberWithCommasWithDecimalPlace(totalGooglePrice.thb)} ${bahtText}` : '-')}
            </th>
          </tr>
          {/** End of Google */}
          {/** Apple*/}
          <tr className="border-b">
            <th scope="row" className="whitespace-nowrap px-12 py-16">
              <div className="flex h-[32px] items-center">
                <label className="label">
                  <span className="label-text text-systemGrays02LabelSecondary">App Store</span>
                </label>
              </div>
            </th>
            <th scope="row" className="flex items-center space-x-12 whitespace-nowrap py-16">
              {isFree && freeText}
              {!isFree && <span>{applePrice ? `${numberWithCommasWithDecimalPlace(applePrice.thb)} ${bahtText}` : '-'}</span>}
              {!isFree && appleDiscountPercent && <span className="p-4 bg-colorsBrandRed01Primary text-colorsWhite rounded-8">{`-${appleDiscountPercent}%`}</span>}
            </th>
            <th scope="row" className="whitespace-nowrap pr-8 py-16 text-right">
              {isFree && freeText}
              {!isFree && (totalApplePrice ? `${numberWithCommasWithDecimalPlace(totalApplePrice.thb)} ${bahtText}` : '-')}
            </th>
          </tr>
          {/** End of Apple*/}
        </tbody>
      </table>
      {haveDiscount && (
        <div className="self-end">
          {discountStartDate && !isHasDiscountEndDate && (
            <div className="space-y-8 text-systemGrays02LabelSecondary font-tt">
              <span>{language === 'TH' ? 'วันที่เริ่มลดราคา: ' : 'Discount Start Date: '}</span>
              <span>{getDayTime(discountStartDate, 'DD/MM/YYYY')}</span>
            </div>
          )}
          {discountStartDate && discountEndDate && isHasDiscountEndDate && (
            <div className="space-y-8 text-systemGrays02LabelSecondary font-tt">
              ( <span>{language === 'TH' ? 'ระยะเวลาลดราคา: ' : 'Discount Duration: '}</span>
              <span>{getDayTime(discountStartDate, 'DD/MM/YYYY')}</span>
              <span> - </span>
              <span>{getDayTime(discountEndDate, 'DD/MM/YYYY')}</span> )
            </div>
          )}
        </div>
      )}
    </div>
  );
};
export default SummaryPrice;
