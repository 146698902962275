import classNames from 'classnames';
import { ChangeEvent } from 'react';

import Image from '../fictionlog/components/Image';

import css from './RadioCardButton.module.scss';
import Typography, { TYPOGRAPHY_TYPES } from './Typography';

export interface IPropsImageConfigData {
  src?: string;
  aspectRatio?: number;
  maxWidth?: number;
}

interface IPropsRadioCardButton {
  value: string;
  title: string;
  description?: string;
  image?: IPropsImageConfigData;
  checked?: boolean;
  disabled?: boolean;
  onSelected?: (paymentServiceId: string) => void;
  className?: string;
  radioButtonClassname?: string;
  titleClassname?: string;
  descriptionClassname?: string;
  labelClassName?: string;
}

const defaultProps = {
  description: '',
  checked: false,
  disabled: false,
  onSelected: undefined,
  image: undefined,
  className: '',
  radioButtonClassname: '',
  titleClassname: '',
  descriptionClassname: '',
  labelClassName: '',
  tooltipContent: undefined,
  tooltipContentPlacement: undefined,
  tooltipIconSize: undefined,
};

const RadioCardButton = (props: IPropsRadioCardButton) => {
  const { value, title, description, image, checked, disabled, onSelected, className, radioButtonClassname, titleClassname, descriptionClassname, labelClassName } = props;

  const handleChangePaymentMethod = (e: ChangeEvent<HTMLInputElement>) => {
    let paymentMethodValue = '';
    if (!disabled) {
      paymentMethodValue = e.target.value;
    }
    onSelected?.(paymentMethodValue);
  };

  return (
    <div className={classNames('mt-[12px] min-h-[56px] w-full first:mt-0', className)}>
      <div className={classNames(css['radio-card-btn'], 'w-full')}>
        <input type="radio" id={`${value}_${title}`} value={value} checked={checked} disabled={disabled} onChange={handleChangePaymentMethod} />
        <label htmlFor={`${value}_${title}`} className={labelClassName}>
          <div className="flex items-center">
            <span
              className={classNames(
                css['radio-card-btn__btn'],
                'mx-12 flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-full border border-solid border-colorsBrandWarmBlack04LabelQuaternary bg-white',
                radioButtonClassname,
              )}
            />
            <div className={classNames(css['radio-card-btn__label'])}>
              <div className="flex items-center">
                <Typography typography={TYPOGRAPHY_TYPES.SUB_HEADLINE_DBH} className={classNames('break-word', titleClassname)}>
                  {title}
                </Typography>
              </div>
              {description && (
                <Typography typography={TYPOGRAPHY_TYPES.CALLOUT_TT} className={classNames('text-colorsBrandWarmBlack02LabelSecondary', descriptionClassname)}>
                  {description}
                </Typography>
              )}
            </div>
          </div>
          {image?.src && (
            <Image
              className={classNames(css['radio-card-btn__image'])}
              src={image?.src}
              alt={title}
              aspectRatio={image?.aspectRatio}
              placeholderColor={`var(--componentsBgBase)`}
              maxWidth={`${image?.maxWidth ?? 0}px`}
            />
          )}
        </label>
      </div>
    </div>
  );
};

RadioCardButton.defaultProps = defaultProps;

export default RadioCardButton;
