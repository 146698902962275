import { useMutation } from '@apollo/client';

import { UploadStaticFileDocument } from '../core/graphql/operation-hooks';

const useMutateUpdateStaticFile = () => {
  const [mutateUpdateStaticFile, { data, loading }] = useMutation(UploadStaticFileDocument);
  return {
    mutateUpdateStaticFile,
    uploadStaticFileUrl: data?.uploadStaticFile?.url,
    isLoadingMutateUpdateStaticFile: loading,
  };
};

export default useMutateUpdateStaticFile;
