import { useCallback } from 'react';

import { usePintoCreateEbookDiscountCampaignMutation } from '../core/graphql/operation-hooks';
import { CreateEbookDiscountCampaignInput } from '../core/graphql/types';

const useMutationPintoCreateEbookDiscountCampaign = () => {
  const [mutatePintoCreateEbookDiscountCampaign, { loading: isLoadingMutatePintoCreateEbookDiscountCampaign }] = usePintoCreateEbookDiscountCampaignMutation();

  const onMutatePintoCreateEbookDiscountCampaign = useCallback(
    (input: CreateEbookDiscountCampaignInput) => {
      const variables = { input };
      return mutatePintoCreateEbookDiscountCampaign({ variables });
    },
    [mutatePintoCreateEbookDiscountCampaign],
  );

  return {
    onMutatePintoCreateEbookDiscountCampaign,
    isLoadingMutatePintoCreateEbookDiscountCampaign,
  };
};

export default useMutationPintoCreateEbookDiscountCampaign;
