import classNames from 'classnames';
import React from 'react';
import { memo, ReactNode, useMemo } from 'react';

import css from './Typography.module.scss';

export enum TYPOGRAPHY_TYPES {
  //db-heavent
  'LARGE_TITLE' = 'largetitle-db-heavent-34pt',
  'TITLE_1' = 'title1-db-heavent-28pt',
  'TITLE_2' = 'title2-db-heavent-22pt',
  'TITLE_3' = 'title3-db-heavent-20pt',
  'HEADLINE_DBH' = 'headline-db-heavent-17pt',
  'BODY_DBH' = 'body-db-heavent-17pt',
  'CALLOUT_DBH' = 'callout-db-heavent-16pt',
  'SUB_HEADLINE_DBH' = 'subheadline-db-heavent-15pt',
  'FOOTNOTE_DBH' = 'footnote-db-heavent-13pt',
  'CAPTION_1_DBH' = 'caption1-db-heavent-12pt',
  'CAPTION_2_DBH' = 'caption2-db-heavent-11pt',
  //thongterm-roman
  'HEADLINE_TT_ROMAN' = 'headline-tt-roman-17pt',
  'BODY_TT_ROMAN' = 'body-tt-roman-16pt',
  'CALLOUT_TT_ROMAN' = 'callout-tt-roman-16pt',
  'SUB_HEADLINE_TT_ROMAN' = 'subheadline-tt-roman-15pt',
  'FOOTNOTE_TT_ROMAN' = 'footnote-tt-roman-13pt',
  'CAPTION_1_TT_ROMAN' = 'caption1-tt-roman-12pt',
  'CAPTION_2_TT_ROMAN' = 'caption2-tt-roman-11pt',
  'PARAGRAPH_TT_ROMAN' = 'paragraph-tt-roman-20pt',
  //thongterm
  'HEADLINE_TT' = 'headline-tt-17pt',
  'BODY_TT' = 'body-tt-16pt',
  'CALLOUT_TT' = 'callout-tt-16pt',
  'SUB_HEADLINE_TT' = 'subheadline-tt-15pt',
  'FOOTNOTE_TT' = 'footnote-tt-13pt',
  'CAPTION_1_TT' = 'caption1-tt-12pt',
  'CAPTION_2_TT' = 'caption2-tt-11pt',
  'PARAGRAPH_TT' = 'paragraph-tt-20pt',
}
export enum TEXT_ALIGN {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
  JUSIFY = 'justify',
}
// https://mui.com/system/typography/
// https://chakra-ui.com/docs/components/typography/heading
export interface IPropsTypography {
  children: ReactNode;
  className?: string;
  fontStyle?: 'normal' | 'italic';
  lineClamp?: 1 | 2 | 3 | 4 | 5 | 6; // the clamping text up to 6 lines are generated by default
  renderAs?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'span' | 'p'; // eg. h1 for page title that good for SEO bots and Screen reader
  textAlign?: TEXT_ALIGN;
  typography: TYPOGRAPHY_TYPES;
  dataTestId?: string;
  style?: React.CSSProperties | undefined;
}

const Typography = (props: IPropsTypography): JSX.Element => {
  const { children, className, fontStyle, lineClamp, renderAs, textAlign = TEXT_ALIGN.LEFT, typography, dataTestId, style } = props;

  const composedClassNames = useMemo(
    () => classNames(`line-clamp-${lineClamp ?? 'none'}`, `text-${textAlign}`, css[typography], css[fontStyle ?? ''], className),
    [className, fontStyle, lineClamp, textAlign, typography],
  );

  switch (renderAs) {
    case 'h1':
      return (
        <h1 className={composedClassNames} style={style}>
          {children}
        </h1>
      );
    case 'h2':
      return (
        <h2 className={composedClassNames} style={style}>
          {children}
        </h2>
      );
    case 'h3':
      return (
        <h3 className={composedClassNames} style={style}>
          {children}
        </h3>
      );
    case 'h4':
      return (
        <h4 className={composedClassNames} style={style}>
          {children}
        </h4>
      );
    case 'h5':
      return (
        <h5 className={composedClassNames} style={style}>
          {children}
        </h5>
      );
    case 'h6':
      return (
        <h6 className={composedClassNames} style={style}>
          {children}
        </h6>
      );
    case 'span':
      return (
        <span className={composedClassNames} style={style}>
          {children}
        </span>
      );
    case 'p':
    default:
      return (
        <>
          <p
            className={classNames(`line-clamp-${lineClamp ?? 'none'}`, `text-${textAlign}`, css[typography], css[fontStyle ?? ''], className)}
            data-testid={dataTestId}
            style={style}
          >
            {children}
          </p>
        </>
      );
  }
};

export default memo(Typography);
