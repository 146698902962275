import { useMemo } from 'react';

import Card from '../../../components/Card';
import SummaryPricePresenter from '../../../components/SummaryPrice';
import { AppPriceTier } from '../../../core/graphql/types';

export interface IPropsPriceDetailPresenter {
  className?: string;
  isFree?: boolean;
  webPrice?: number;
  webDiscountPrice?: number;
  webDiscountRatio?: number;
  googlePriceTier?: AppPriceTier;
  applePriceTier?: AppPriceTier;
  discountGooglePriceTier?: AppPriceTier;
  discountApplePriceTier?: AppPriceTier;
  discountStartDate?: Date;
  discountEndDate?: Date;
}

const PriceDetailPresenter = (props: IPropsPriceDetailPresenter) => {
  const {
    isFree,
    webDiscountRatio,
    webPrice,
    webDiscountPrice,
    googlePriceTier,
    applePriceTier,
    discountGooglePriceTier,
    discountApplePriceTier,
    discountStartDate,
    discountEndDate,
  } = props;
  const haveDiscount = useMemo(() => {
    return !!(webDiscountRatio || webDiscountPrice || discountGooglePriceTier || discountApplePriceTier);
  }, [discountApplePriceTier, discountGooglePriceTier, webDiscountPrice, webDiscountRatio]);

  const _discountPercent = useMemo(() => {
    if (webDiscountRatio) {
      return Math.ceil(webDiscountRatio * 100);
    }
    return undefined;
  }, [webDiscountRatio]);

  const _googlePriceTier = useMemo(() => {
    if (googlePriceTier) {
      return {
        id: googlePriceTier._id,
        thb: googlePriceTier.thb,
        usd: googlePriceTier.usd,
      };
    }
    return undefined;
  }, [googlePriceTier]);

  const _applePriceTier = useMemo(() => {
    if (applePriceTier) {
      return {
        id: applePriceTier._id,
        thb: applePriceTier.thb,
        usd: applePriceTier.usd,
      };
    }
    return undefined;
  }, [applePriceTier]);

  const _discountGooglePriceTier = useMemo(() => {
    if (discountGooglePriceTier) {
      return {
        id: discountGooglePriceTier._id,
        thb: discountGooglePriceTier.thb,
        usd: discountGooglePriceTier.usd,
      };
    }
    return undefined;
  }, [discountGooglePriceTier]);

  const _discountApplePriceTier = useMemo(() => {
    if (discountApplePriceTier) {
      return {
        id: discountApplePriceTier._id,
        thb: discountApplePriceTier.thb,
        usd: discountApplePriceTier.usd,
      };
    }
    return undefined;
  }, [discountApplePriceTier]);

  return (
    <Card className="flex flex-col space-y-8">
      <div className="text-22">Price</div>
      <div className="w-full">
        <SummaryPricePresenter
          className="pt-16"
          isFree={isFree}
          webDiscountPercent={_discountPercent}
          webDiscountPrice={webDiscountPrice}
          webPrice={webPrice}
          haveDiscount={haveDiscount}
          googlePrice={_googlePriceTier}
          discountGooglePrice={_discountGooglePriceTier}
          applePrice={_applePriceTier}
          discountApplePrice={_discountApplePriceTier}
          discountStartDate={discountStartDate}
          discountEndDate={discountEndDate}
          language="EN"
          isHasDiscountEndDate={!!discountEndDate}
        />
      </div>
    </Card>
  );
};
export default PriceDetailPresenter;
