import { useCallback } from 'react';

import { usePintoUploadEbookBundleMutation } from '../core/graphql/operation-hooks';
import { UploadEbookBundleInput } from '../core/graphql/types';

const useMutationPintoUploadEbookBundle = () => {
  const [mutatePintoUploadEbookBundle, { loading: isLoadingMutatePintoUploadEbookBundle }] = usePintoUploadEbookBundleMutation({ errorPolicy: 'all' });

  const onMutatePintoUploadEbookBundle = useCallback(
    async (input: UploadEbookBundleInput) => {
      const variables = { input };
      const res = await mutatePintoUploadEbookBundle({ variables });
      if (res.errors) {
        return { success: false, errors: res.errors };
      } else {
        return { success: true, data: res.data };
      }
    },
    [mutatePintoUploadEbookBundle],
  );

  return {
    onMutatePintoUploadEbookBundle,
    isLoadingMutatePintoUploadEbookBundle,
  };
};

export default useMutationPintoUploadEbookBundle;
