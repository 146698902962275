import { useCallback } from 'react';

import { usePintoCancelEbookDiscountCampaignMutation } from '../core/graphql/operation-hooks';

const useMutationPintoCancelEbookDiscountCampaign = () => {
  const [mutatePintoCancelEbookDiscountCampaign] = usePintoCancelEbookDiscountCampaignMutation();

  const cancelEbookDiscountCampaign = useCallback(
    (ebookDiscountCampaignId: string) => {
      mutatePintoCancelEbookDiscountCampaign({
        variables: { ebookDiscountCampaignId },
        refetchQueries: ['PintoEbookDiscountCampaigns', 'PintoEbookDiscountCampaign'],
      });
    },
    [mutatePintoCancelEbookDiscountCampaign],
  );

  return {
    cancelEbookDiscountCampaign,
  };
};

export default useMutationPintoCancelEbookDiscountCampaign;
