/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const reply = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path
      fill="#8C8C8C"
      fillRule="evenodd"
      d="M28.03 30.972c-9.81 0-18.257-6.897-24.03-20.69a1 1 0 011.556-1.16c7.841 6.42 15.77 9.63 22.475 9.63v-7.55a1 1 0 011.706-.709L44 24.722 29.737 38.949a1 1 0 01-1.706-.707v-7.27z"
    />
  </svg>
);

export default reply;
