import Card from '../../../components/Card';
import { numberWithCommasWithDecimalPlace } from '../../../utils/convertNumber';

interface IPropsSalesReportDetailPresenter {
  totalSales?: number;
  purchaseCount?: number;
  earningRatio?: number;
}

function SalesReportDetailPresenter(props: IPropsSalesReportDetailPresenter) {
  const { totalSales, purchaseCount, earningRatio } = props;
  return (
    <Card className="space-y-8 flex flex-col flex-1">
      <div className="text-22">Sales Report</div>
      <div className="flex space-x-24">
        <div className="flex w-[200px] font-tt">Total Sales</div>
        <div>{totalSales ? numberWithCommasWithDecimalPlace(totalSales || 0) : 0}</div>
      </div>
      <div className="flex space-x-24">
        <div className="flex w-[200px] font-tt">Purchase Count</div>
        <div>{purchaseCount || 0}</div>
      </div>
      <div className="flex space-x-24">
        <div className="flex w-[200px] font-tt">Earning Ratio</div>
        <div>{earningRatio || '-'}</div>
      </div>
    </Card>
  );
}

export default SalesReportDetailPresenter;
