import classNames from 'classnames';

import Card from '../../../components/Card';
import ContentEllipsis from '../../../components/ContentEllipsis';
import RenderContentRawState, { ContentRawState } from '../../../components/RenderContentRawState';
import { CorrectionEbookStatus, EbookStatus } from '../../../core/graphql/types';
import { getDateInFormat } from '../../../utils/dayjs';

const PINTO_BOOK_BASE_URL = process.env.REACT_APP_PINTO_BOOK_URL;

const DATE_FORMAT = 'D MMM YYYY เวลา H:mm น.';

interface IUser {
  userName: string;
  name: string;
}

interface IPropsAboutDetailPresenter {
  ebookId: string;
  ebookStatus?: EbookStatus;
  reviewStatus?: CorrectionEbookStatus;
  authorNames?: string[];
  translatorNames?: string[];
  user?: IUser;
  description?: string;
  contentRawState?: ContentRawState;
  publishedAt?: string;
}

function AboutDetailPresenter(props: IPropsAboutDetailPresenter) {
  const { ebookId, ebookStatus, reviewStatus, authorNames, translatorNames, user, description, contentRawState, publishedAt } = props;
  return (
    <Card className="flex flex-col space-y-8">
      <div className="text-22">Detail</div>
      <div className="flex space-x-24">
        <div className="flex w-[200px] font-tt">Ebook ID</div>
        <a target="_blank" className="link text-colorsBrandRed01Primary" href={`${PINTO_BOOK_BASE_URL}/ebooks/${ebookId}`} rel="noreferrer">
          {ebookId}
        </a>
      </div>
      <div className="flex space-x-24">
        <div className="flex w-[200px] font-tt">User</div>
        <a target="_blank" className="link text-colorsBrandRed01Primary" href={`${PINTO_BOOK_BASE_URL}/user/${user?.userName}`} rel="noreferrer">
          {user?.name}
        </a>
      </div>
      <div className="flex space-x-24">
        <div className="flex w-[200px] font-tt">Ebook Status</div>
        <div
          className={classNames('badge', {
            'badge-success': ebookStatus === EbookStatus.Public,
            'badge-error': ebookStatus === EbookStatus.Removed,
            'badge-warning': ebookStatus === EbookStatus.Scheduled,
          })}
        >
          {ebookStatus === EbookStatus.Scheduled
            ? publishedAt
              ? getDateInFormat(publishedAt, 'th', `หนังสือจะถูกเผยแพร่วันที่ ${DATE_FORMAT}`)
              : '-'
            : ebookStatus?.toLocaleUpperCase()}
        </div>
      </div>
      <div className="flex space-x-24">
        <div className="flex w-[200px] font-tt">Review Status</div>
        {reviewStatus ? (
          <div className="flex space-x-4">
            <div
              className={classNames({
                badge: reviewStatus === CorrectionEbookStatus.Draft,
                'badge badge-warning': reviewStatus === CorrectionEbookStatus.Pending,
                'badge badge-error': reviewStatus === CorrectionEbookStatus.Rejected,
              })}
            >
              {reviewStatus?.toLocaleUpperCase()}
            </div>
            <div className="font-tt">
              {reviewStatus === CorrectionEbookStatus.Pending ? (publishedAt ? getDateInFormat(publishedAt, 'th', `(ตั้งเวลาเผยแพร่วันที่ ${DATE_FORMAT})`) : null) : null}
            </div>
          </div>
        ) : (
          <div>-</div>
        )}
      </div>
      <div className="flex space-x-24">
        <div className="flex w-[200px] font-tt">Ebook ID</div>
        <div className="flex-1">{ebookId}</div>
      </div>
      <div className="flex space-x-24">
        <div className="flex w-[200px] font-tt">Pinto Link</div>
        <a target="_blank" className="link text-colorsBrandRed01Primary" href={`${PINTO_BOOK_BASE_URL}/ebooks/${ebookId}`} rel="noreferrer">
          {`${PINTO_BOOK_BASE_URL}/ebooks/${ebookId}`}
        </a>
      </div>
      <div className="flex space-x-24">
        <div className="flex w-[200px] font-tt">User</div>
        <a target="_blank" className="link text-colorsBrandRed01Primary" href={`${PINTO_BOOK_BASE_URL}/user/${user?.userName}`} rel="noreferrer">
          {user?.name}
        </a>
      </div>
      <div className="flex space-x-24">
        <div className="flex w-[200px] font-tt">Author Names</div>
        <div className="flex flex-row space-x-4">
          {authorNames && authorNames?.length > 0 ? (
            authorNames.map((author, index) => (
              <div key={index} className="pl-4 border-l-2 first:border-l-0 first:pl-0 border-colorsBrandWarmBlack02LabelSecondary">
                {author}
              </div>
            ))
          ) : (
            <div className="text-colorsBrandWarmBlack02LabelSecondary">-</div>
          )}
        </div>
      </div>
      <div className="flex space-x-24">
        <div className="flex w-[200px] font-tt">Translator Names</div>
        <div className="flex flex-row space-x-4">
          {translatorNames && translatorNames.length > 0 ? (
            translatorNames?.map((translator, index) => (
              <div key={index} className="pl-4 border-l-2 first:border-l-0 first:pl-0 border-colorsBrandWarmBlack02LabelSecondary">
                {translator}
              </div>
            ))
          ) : (
            <div className="text-colorsBrandWarmBlack02LabelSecondary">-</div>
          )}
        </div>
      </div>
      <div className="flex space-x-24">
        <div className="flex w-[200px] font-tt">คำโปรย</div>
        <div className="flex-1">{description || '-'}</div>
      </div>
      <div className="flex space-x-24">
        <div className="flex w-[200px] font-tt">เรื่องย่อ</div>
        <div className="flex-1">
          {contentRawState ? (
            <ContentEllipsis lineClamped={5}>
              <RenderContentRawState contentRawState={contentRawState} contentClassName="font-tt" />
            </ContentEllipsis>
          ) : (
            '-'
          )}
        </div>
      </div>
    </Card>
  );
}

export default AboutDetailPresenter;
