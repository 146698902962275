/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const bin = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path
      fill="#8C8C8C"
      fillRule="evenodd"
      d="M8.133 14h31.734c.626 0 1.133.512 1.133 1.143v24C41 42.93 37.956 46 34.2 46H13.8C10.044 46 7 42.93 7 39.143v-24C7 14.512 7.507 14 8.133 14zM28 19c-.552 0-1 .473-1 1.056v16.888c0 .583.448 1.056 1 1.056h3c.552 0 1-.473 1-1.056V20.056c0-.583-.448-1.056-1-1.056h-3zM20.126 4a4.002 4.002 0 017.748 0H36a6 6 0 016 6H6a6 6 0 016-6h8.126zM17 19c-.552 0-1 .473-1 1.056v16.888c0 .583.448 1.056 1 1.056h3c.552 0 1-.473 1-1.056V20.056c0-.583-.448-1.056-1-1.056h-3z"
    />
  </svg>
);

export default bin;
