import { EditorState, Transaction } from 'prosemirror-state';
import { Plugin } from 'prosemirror-state';

import schema from '../schema';

export const deleteEmptyFigure = (): Plugin => {
  return new Plugin({
    appendTransaction: (transactions: readonly Transaction[], oldState: EditorState, newState: EditorState) => {
      if (!transactions && !oldState) return;
      const tr = newState.tr;
      let modified = false;
      newState.doc.descendants((node, pos) => {
        if (node.type.name !== 'figure') return;
        const imageNode = node.firstChild;
        if (!imageNode?.attrs.src) {
          tr.deleteRange(pos, pos + node.nodeSize);
          modified = true;
        }
      });
      if (modified) return tr;
    },
  });
};
