/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const sticker = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path
      fill="#8C8C8C"
      fillRule="evenodd"
      d="M11.9 1.003h.292c5.979.104 10.874 5.043 10.811 11.172-.059 5.956-5.026 10.858-11.025 10.827-6.065.013-11.132-5.011-10.975-11.288.146-5.792 4.804-10.501 10.605-10.705l.291-.006zm.07 2.497c-4.676.01-8.479 3.84-8.47 8.523.009 4.677 3.833 8.483 8.515 8.477 4.672-.006 8.488-3.835 8.485-8.512C20.496 7.3 16.666 3.49 11.97 3.5zm4.006 9.5c.68 0 1.048.471.893 1.136-.561 2.403-2.807 4.04-5.282 3.849-2.136-.165-4.01-1.79-4.463-3.869-.132-.608.187-1.055.774-1.11L8.02 13h7.956zm-7.11-5.71c.882.002 1.563.7 1.561 1.601a1.562 1.562 0 01-1.553 1.537c-.896-.002-1.587-.689-1.585-1.577a1.553 1.553 0 011.576-1.56zm6.298-.002a1.56 1.56 0 011.547 1.592c-.008.882-.709 1.554-1.615 1.547-.845-.006-1.533-.723-1.524-1.59a1.564 1.564 0 011.592-1.549z"
    />
  </svg>
);

export default sticker;
