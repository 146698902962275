/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const removeFill = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path
      fill="#8C8C8C"
      fillRule="evenodd"
      d="M24 19.757l-6.686-6.686a5.832 5.832 0 00-4.243 4.243L19.757 24l-6.695 6.695a5.671 5.671 0 004.243 4.243L24 28.243l6.697 6.697a5.634 5.634 0 004.243-4.243L28.243 24l6.692-6.692a5.72 5.72 0 00-4.243-4.243L24 19.757zm-15.556 19.8c-8.592-8.592-8.592-22.522 0-31.113 8.591-8.592 22.52-8.592 31.112 0 8.592 8.591 8.592 22.52 0 31.112-8.591 8.592-22.52 8.592-31.112 0z"
    />
  </svg>
);

export default removeFill;
