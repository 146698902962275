/* eslint-disable @typescript-eslint/no-explicit-any */
import dayjs, { ManipulateType, OpUnitType } from 'dayjs';
import localeTh from 'dayjs/locale/th';
import isBetween from 'dayjs/plugin/isBetween';
import localeData from 'dayjs/plugin/localeData';
import { DateLocalizer } from 'react-big-calendar';

dayjs.extend(localeData);
dayjs.extend(isBetween);

export const getDateInFormat = (date: dayjs.ConfigType, locale: 'th' | 'en', format?: string): string => {
  return dayjs(date)
    .locale(locale === 'th' ? localeTh : 'en')
    .format(format || `DD MMM YYYY`);
};

const weekRangeFormat = ({ start, end }: any, culture: any, local: any) =>
  `${local.format(start, 'MMMM DD', culture)} – ${local.format(end, local.eq(start, end, 'month') ? 'DD' : 'MMMM DD', culture)}`;

const dateRangeFormat = ({ start, end }: any, culture: any, local: any) => `${local.format(start, 'L', culture)} – ${local.format(end, 'L', culture)}`;

const timeRangeFormat = ({ start, end }: any, culture: any, local: any) => `${local.format(start, 'LT', culture)} – ${local.format(end, 'LT', culture)}`;

const timeRangeStartFormat = ({ start }: any, culture: any, local: any) => `${local.format(start, 'LT', culture)} – `;

const timeRangeEndFormat = ({ end }: any, culture: any, local: any) => ` – ${local.format(end, 'LT', culture)}`;

export const formats = {
  dateFormat: 'DD',
  dayFormat: 'DD ddd',
  weekdayFormat: 'ddd',

  selectRangeFormat: timeRangeFormat,
  eventTimeRangeFormat: timeRangeFormat,
  eventTimeRangeStartFormat: timeRangeStartFormat,
  eventTimeRangeEndFormat: timeRangeEndFormat,

  timeGutterFormat: 'LT',

  monthHeaderFormat: 'MMMM YYYY',
  dayHeaderFormat: 'dddd MMM DD',
  dayRangeHeaderFormat: weekRangeFormat,
  agendaHeaderFormat: dateRangeFormat,

  agendaDateFormat: 'ddd MMM DD',
  agendaTimeFormat: 'LT',
  agendaTimeRangeFormat: timeRangeFormat,
};

export const dayjsLocalizer = () => {
  const locale = (m: any, c: any) => (c ? m.locale(c) : m);

  return new DateLocalizer({
    formats,
    firstOfWeek() {
      const data = dayjs.localeData();
      return data ? data.firstDayOfWeek() : 0;
    },

    format(value, format, culture) {
      return locale(dayjs(value), culture).format(format);
    },
  });
};

export const localizer = dayjsLocalizer();

export const getDayTime = (date: string | Date, format?: string) => dayjs(date).format(format || 'DD/MM/YYYY');

export const isSameDate = (date1: string | Date, date2: string | Date, unit?: OpUnitType) => dayjs(date1).isSame(date2, unit || 'day');

export const isBeforeDate = (date1: string | Date, date2: string | Date, unit?: OpUnitType) => dayjs(date1).isBefore(date2, unit || 'day');

export const endOfDate = (date: string | Date, unit?: OpUnitType) => dayjs(date).endOf(unit || 'day');

export const startOfDate = (date: string | Date, unit?: OpUnitType) => dayjs(date).startOf(unit || 'day');

export const addDate = (date: string | Date, count: number, unit?: ManipulateType) => dayjs(date).add(count, unit || 'day');

export const startOfToDate = (date: dayjs.ConfigType, unit: dayjs.OpUnitType): Date => dayjs(date).startOf(unit).toDate();

export const addDaysFromDate = (count: number, unit?: dayjs.ManipulateType, date?: Date): Date =>
  dayjs(date || new Date())
    .add(count, unit || 'day')
    .toDate();

export const startOf = (date: dayjs.ConfigType, unit: dayjs.OpUnitType): Date => dayjs(date).startOf(unit).toDate();
export const endOf = (date: dayjs.ConfigType, unit: dayjs.OpUnitType): Date => dayjs(date).endOf(unit).toDate();

export const subtractDate = (date: string | Date, count: number, unit?: ManipulateType) => dayjs(date).subtract(count, unit || 'day');

export const addDateEbook = (count: number, unit?: dayjs.ManipulateType, date?: Date): Date =>
  dayjs(date || new Date())
    .add(count, unit || 'day')
    .toDate();

export const minusDateEbook = (count: number, unit?: dayjs.ManipulateType, date?: Date): Date =>
  dayjs(date || new Date())
    .subtract(count, unit || 'day')
    .toDate();
