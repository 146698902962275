import classNames from 'classnames';
import { ReactChild } from 'react';

interface IPropsRadioCardButtonGroup {
  className?: string;
  children?: ReactChild | ReactChild[];
}

const defaultProps = {
  className: undefined,
  children: undefined,
};

const RadioCardButtonGroup = (props: IPropsRadioCardButtonGroup) => {
  const { children, className } = props;
  return <div className={classNames('w-full', className)}>{children}</div>;
};

RadioCardButtonGroup.defaultProps = defaultProps;

export default RadioCardButtonGroup;
