import { throttle } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';

import SearchInputDropdown from '../../../components/form/SearchInputDropdown';
import useSearchSubscriptionTitles from '../../../hooks/useSearchSubscriptionTitles';

interface IPropsEditEbookSubscriptionTitlePresenter {
  userId?: string;
  selectedSubscriptionTitle?: string;
  onSelectSubscriptionTitle?: (item: { label: string; value: string }) => void;
  disabled?: boolean;
}

const EditEbookSubscriptionTitlePresenter = (props: IPropsEditEbookSubscriptionTitlePresenter) => {
  const { selectedSubscriptionTitle, onSelectSubscriptionTitle, disabled, userId } = props;

  const { fetchSearchSubscriptionTitles, searchSubscriptionTitles } = useSearchSubscriptionTitles();

  const [inputSearchText, setInputSearchText] = useState<string | undefined>(undefined);
  const [q, setQ] = useState<string | undefined>(undefined);
  const [selectedSubscriptionTitleItem, setSelectedSubscriptionTitle] = useState<{ label: string; value: string } | undefined>(undefined);

  const onSetSelectedSubscriptionTitle = useCallback(
    (item: { label: string; value: string }) => {
      console.log(item);
      if (!item) return;

      setSelectedSubscriptionTitle(item);
      onSelectSubscriptionTitle?.(item);
    },
    [onSelectSubscriptionTitle],
  );

  useEffect(() => {
    if (userId) {
      fetchSearchSubscriptionTitles({ filter: { q, userId } });
    }
  }, [fetchSearchSubscriptionTitles, q, userId]);

  const mappedSubscriptionTitleItems = useMemo(() => {
    const items =
      searchSubscriptionTitles?.edges.map(({ node }) => ({
        label: node?.title || '',
        value: node?._id || '',
      })) || [];

    return [{ label: 'ไม่มีหัวหนังสือ', value: '' }, ...items];
  }, [searchSubscriptionTitles?.edges]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChangeQueryString = useCallback(
    throttle((value) => setQ(value), 500),
    [],
  );

  const onChangeSearchText = useCallback(
    (text?: string) => {
      setInputSearchText(text);
      onChangeQueryString(text);
    },
    [onChangeQueryString],
  );

  return (
    <div className="flex flex-col w-full">
      <div className="w-full form-control">
        <label className="label">
          <span className="label-text">หัวหนังสือ</span>
        </label>
        <SearchInputDropdown
          className="w-full"
          disabled={disabled}
          items={mappedSubscriptionTitleItems}
          placeholder={selectedSubscriptionTitleItem ? selectedSubscriptionTitleItem?.label : selectedSubscriptionTitle || 'ไม่มีหัวหนังสือ'}
          value={inputSearchText}
          onChange={(value) => onChangeSearchText(value)}
          menuClassName="w-full"
          onClickMenuItem={(selectItem) => {
            if (selectItem) {
              const item = searchSubscriptionTitles?.edges.map(({ node }) => node).find((item) => item._id === selectItem.value);
              onSetSelectedSubscriptionTitle({
                label: item?.title || 'ไม่มีหัวหนังสือ',
                value: item?._id || '',
              });
            }
          }}
        />
      </div>
    </div>
  );
};

export default EditEbookSubscriptionTitlePresenter;
