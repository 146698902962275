/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const list = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path
      fill="#8C8C8C"
      fillRule="evenodd"
      d="M15.996 39a5.016 5.016 0 01-.034-5.953l.034-.047h28.008l.034.047A5.016 5.016 0 0144.004 39H15.996zm0-12a5.016 5.016 0 01-.034-5.953l.034-.047h28.008l.034.047A5.016 5.016 0 0144.004 27H15.996zm0-12a5.016 5.016 0 01-.034-5.953L15.996 9h28.008l.034.047A5.016 5.016 0 0144.004 15H15.996zM7 16a4 4 0 110-8 4 4 0 010 8zm0 12a4 4 0 110-8 4 4 0 010 8zm0 12a4 4 0 110-8 4 4 0 010 8z"
    />
  </svg>
);

export default list;
