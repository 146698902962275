/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const textUnderline = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path
      fill="#8C8C8C"
      fillRule="evenodd"
      d="M10 39.8v-3.6c0-.668.446-1.2.997-1.2h26.006c.557 0 .997.537.997 1.2v3.6c0 .668-.446 1.2-.997 1.2H10.997c-.557 0-.997-.537-.997-1.2zM12.026 7H17a1 1 0 011 1v10.038c0 3.03 2.663 6.011 6 6.011 3.362 0 6-2.99 6-6.01V8a1 1 0 011-1h5a1 1 0 011 1v11c0 8.008-7.462 12-13 12-5.647 0-12.974-3.992-12.974-12V8a1 1 0 011-1z"
    />
  </svg>
);

export default textUnderline;
