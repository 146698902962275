import { useCallback } from 'react';

import { EbookFileType } from '../../../core/graphql/types';
import useMutationPintoCreatePresignedUploadEbookFile from '../../../hooks/useMutationPintoCreatePresignedUploadEbookFile';
import { uploadFilePresigned } from '../../../services/upload';

const useUploadFiles = () => {
  const { pintoCreatePresignedUploadEbookFile } = useMutationPintoCreatePresignedUploadEbookFile();

  const handleUploadFile = useCallback(
    async (file: File, handleProgress: (percentage: number) => void, abortController?: AbortController) => {
      const type = file.type === 'application/pdf' ? EbookFileType.Pdf : EbookFileType.Epub;
      const presignedResponse = await pintoCreatePresignedUploadEbookFile(type);
      if (presignedResponse) {
        return uploadFilePresigned(file, presignedResponse.url!, presignedResponse.fields, handleProgress, abortController);
      }

      return undefined;
    },
    [pintoCreatePresignedUploadEbookFile],
  );

  return {
    handleUploadFile,
  };
};

export default useUploadFiles;
