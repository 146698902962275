/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const bookSeries = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path
      fill="#8C8C8C"
      fillRule="evenodd"
      d="M10.235 2h27.53C40.104 2 42 3.97 42 6.4v35.2c0 2.43-1.896 4.4-4.235 4.4h-27.53C7.896 46 6 44.03 6 41.6V6.4C6 3.97 7.896 2 10.235 2zm3.66 13h20.1a3.671 3.671 0 00.03-4.967l-.03-.033h-20.1l-.029.037a3.993 3.993 0 00.03 4.963zm.057 10h20.026a3.63 3.63 0 00.03-4.968l-.03-.032H13.952l-.03.035a3.82 3.82 0 00.03 4.965zm-.147 10H27.09a3.865 3.865 0 00.03-4.965L27.09 30H13.805l-.028.04a4.263 4.263 0 00.028 4.96z"
    />
  </svg>
);

export default bookSeries;
