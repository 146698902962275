import Card from '../../../components/Card';
import SummarySubscriptionPackagePrice from '../../../components/SummarySubscriptionPackagePrice';
import { SubscriptionTitlePackage } from '../../../core/graphql/types';

export interface IPropsEditSubscriptionPackagePricePresenter {
  packages?: (SubscriptionTitlePackage | null)[];
}

const EditSubscriptionPackagePricePresenter = (props: IPropsEditSubscriptionPackagePricePresenter) => {
  const { packages } = props;
  const activatedPackage = packages?.length ?? 0;
  return (
    <Card className={'flex flex-col items-center space-y-16'}>
      <div className="w-full flex">
        <div className="flex space-x-8 items-baseline">
          <h1 className="font-dbh text-[22px]">แพคเกจสมาชิกรายเล่ม ({activatedPackage}/30)</h1>
          {activatedPackage > 0 ? <h2 className="text-colorsBrandWarmBlack02LabelSecondary">Sort by: Interval time (min-max)</h2> : null}
        </div>
      </div>
      {(packages?.length || 0) === 0 ? (
        <div className="flex items-center justify-center w-full h-[25vh] text-colorsBrandWarmBlack02LabelSecondary">No Packages Found.</div>
      ) : (
        <SummarySubscriptionPackagePrice className="pt-16" packages={packages} />
      )}
    </Card>
  );
};

export default EditSubscriptionPackagePricePresenter;
