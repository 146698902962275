import { Prompt } from 'react-router';
import { useBeforeUnload } from 'react-use';

interface IPropsLeavePageAlert {
  showConfirmLeavePageAlert: boolean;
  message?: string;
}

function LeavePageAlert(props: IPropsLeavePageAlert) {
  const { showConfirmLeavePageAlert = true, message = 'Are you sure want to leave this page?' } = props;

  useBeforeUnload(showConfirmLeavePageAlert, message);

  return <Prompt when={showConfirmLeavePageAlert} message={message} />;
}

export default LeavePageAlert;
