import classnames from 'classnames';
import Slider from 'react-slick';

import Icon from '../components/Icon';
import Image from '../components/Image';
import Modal from '../components/Modal';

import css from './AdModal.module.scss';

const MAX_WIDTH_MODAL = 344;

export interface IPropsAdModal {
  onClose: () => void;
  items: AdModalItem[];
}

export interface AdModalItem {
  id: string;
  imageUrl: string;
  link?: string;
}

const ArrowButton = (props: { direction: 'Left' | 'Right'; onClick?: () => void }): JSX.Element => (
  <button
    onClick={props.onClick}
    className={classnames('absolute top-[50%] transform translate-y-[-15px] flex z-[1] p-4 border-none rounded-[50%] opacity-70 bg-[#222222]', {
      ['left-[-18px] hidden sm:left-[-44px] sm:flex']: props.direction === 'Left',
      ['right-[-18px] hidden sm:right-[-44px] sm:flex']: props.direction === 'Right',
    })}
  >
    <Icon color="var(--colorsWhite)" name={props.direction === 'Left' ? 'caretLeft' : 'caretRight'} size="22px" />
  </button>
);

const slideSettings = {
  pauseOnHover: true,
  pauseOnFocus: true,
  pauseOnDotsHover: true,
  infinite: true,
  speed: 500,
  autoplaySpeed: 3000,
  slidesToScroll: 1,
  prevArrow: <ArrowButton direction="Left" />,
  nextArrow: <ArrowButton direction="Right" />,
  draggable: true,
  autoplay: true,
  adaptiveHeight: false,
  dots: true,
  dotsClass: css.slickDots,
};

const AdModal = (props: IPropsAdModal): JSX.Element => {
  const { items, onClose } = props;
  return (
    <Modal maxWidthModal={MAX_WIDTH_MODAL} onClose={onClose} closeButtonStyle={{ background: '#fff', padding: 6 }}>
      <div className={css.sliderWrapper}>
        <Slider {...slideSettings}>
          {items.map((item) => {
            return (
              <div key={item.id} className="w-full">
                <a>
                  <Image src={item.imageUrl} aspectRatio={0.796296} alt={`ad image ${item.link}`} />
                </a>
              </div>
            );
          })}
        </Slider>
      </div>
      <div className={classnames('px-12 py-8 flex items-center', { 'mt-16': items.length > 1 })}>
        <input className={css.checkbox} id="check-isDisable-Popup-Ads" type="checkbox" />
        <label htmlFor="check-isDisable-Popup-Ads" className="text-12 text-systemGrays02LabelSecondary">
          ปิดการแสดงเรื่องทั้งหมด
        </label>
      </div>
    </Modal>
  );
};

export default AdModal;
