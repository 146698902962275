import { useCallback } from 'react';

import { useSignedUrlMutation } from '../core/graphql/operation-hooks';

const useMutationSignedUrl = () => {
  const [mutationSignedUrl, { loading }] = useSignedUrlMutation();

  const signedUrl = useCallback(
    async (url: string) => {
      try {
        const response = await mutationSignedUrl({ variables: { url } });
        return response.data?.signedUrl;
      } catch (error) {
        console.error('Error downloading ebook bundles:', error);
        throw error;
      }
    },
    [mutationSignedUrl],
  );
  return {
    signedUrl,
    isLoadingDownloadEbookBundles: loading,
  };
};

export default useMutationSignedUrl;
