/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const lock = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path
      fill="#8C8C8C"
      fillRule="evenodd"
      d="M29.455 17v-4c0-2.761-2.443-5-5.455-5s-5.455 2.239-5.455 5v4h10.91zM12 17v-4c0-6.075 5.373-11 12-11s12 4.925 12 11v4h1a4 4 0 014 4v19a4 4 0 01-4 4H11a4 4 0 01-4-4V21a4 4 0 014-4h1z"
    />
  </svg>
);

export default lock;
