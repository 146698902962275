import { toast, Zoom } from 'react-toastify';
export enum IToastType {
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
  DEFAULT = 'default',
}

export interface IPropsToastComponent {
  label: string;
  autoCloseTime?: number;
  type?: IToastType;
}

const ToastComponent = (props: IPropsToastComponent): void => {
  const { label, autoCloseTime = 3000, type } = props;

  toast(
    <div className="flex items-center justify-center">
      <div className="whitespace-nowrap">{label}</div>
    </div>,
    {
      type: type || IToastType.DEFAULT,
      position: 'top-center',
      autoClose: autoCloseTime,
      hideProgressBar: true,
      closeOnClick: true,
      closeButton: false,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      transition: Zoom,
      theme: 'colored',
    },
  );
};

export default ToastComponent;
