import { useCallback, useEffect } from 'react';

import { usePintoEbookDiscountCampaignsLazyQuery } from '../core/graphql/operation-hooks';
import { EbookDiscountCampaignsFilter } from '../core/graphql/types';

const INITIAL_LIMIT = 10;

const useQueryPintoEbookDiscountCampaigns = () => {
  const [queryPintoEbookDiscountCampaigns, { data, loading }] = usePintoEbookDiscountCampaignsLazyQuery({ fetchPolicy: 'network-only' });

  const fetchPintoEbookDiscountCampaigns = useCallback(
    (filter?: EbookDiscountCampaignsFilter) => {
      queryPintoEbookDiscountCampaigns({
        variables: {
          filter: {
            ...filter,
            limit: INITIAL_LIMIT,
          },
        },
      });
    },
    [queryPintoEbookDiscountCampaigns],
  );

  useEffect(() => fetchPintoEbookDiscountCampaigns(), [fetchPintoEbookDiscountCampaigns]);

  return {
    fetchPintoEbookDiscountCampaigns,
    ebookDiscountCampaigns: data?.ebookDiscountCampaigns,
    isLoadingPintoEbookDiscountCampaigns: loading,
  };
};

export default useQueryPintoEbookDiscountCampaigns;
