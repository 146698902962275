/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const textQuote = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path
      fill="#8C8C8C"
      fillRule="evenodd"
      d="M32.601 35.303a1 1 0 01-.953.697h-5.444a1 1 0 01-.983-1.184l4.11-22a1 1 0 01.984-.816H40l-7.399 23.303zM16.1 35.286a1 1 0 01-.959.714h-4.958a1 1 0 01-.987-1.165l3.689-22A1 1 0 0113.87 12h9.18l-6.951 23.286z"
    />
  </svg>
);

export default textQuote;
