/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const loading = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path
      fill="#8C8C8C"
      fillRule="evenodd"
      d="M5 24C5 13.523 13.523 5 24 5s19 8.523 19 19a3 3 0 11-6 0c0-7.168-5.832-13-13-13s-13 5.832-13 13 5.832 13 13 13a3 3 0 110 6C13.523 43 5 34.477 5 24z"
    />
  </svg>
);

export default loading;
