/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const search = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path
      fill="#8C8C8C"
      fillRule="evenodd"
      d="M35.898 31.106l8.11 8.11a3.388 3.388 0 11-4.793 4.792l-8.11-8.11A17.917 17.917 0 0121 39c-9.941 0-18-8.059-18-18S11.059 3 21 3s18 8.059 18 18c0 3.746-1.144 7.225-3.102 10.106zM21 32c6.075 0 11-4.925 11-11s-4.925-11-11-11-11 4.925-11 11 4.925 11 11 11z"
    />
  </svg>
);

export default search;
