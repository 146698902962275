import { omit } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { Link, useLocation } from 'react-router-dom';

import ErrorModal from '../../app/modals/ErrorModal';
import LoadingModal from '../../app/modals/LoadingModal';
import EditSubscriptionDetailPresenter from '../../app/subscriptions/presenters/EditSubscriptionDetailPresenter';
import EditSubscriptionPackagePricePresenter from '../../app/subscriptions/presenters/EditSubscriptionPackagePricePresenter';
import EditSubscriptionUserCreatorPresenter from '../../app/subscriptions/presenters/EditSubscriptionUserCreatorPresenter';
import SubscriptionEbooksTablePresenter from '../../app/subscriptions/presenters/SubscriptionEbooksTablePresenter';
import SubscriptionBuilderFormObj, { ISubscriptionBuilderFields, SUBSCRIPTION_BUILDER_FIELD_KEYS } from '../../app/subscriptions/validates/subscriptionBuilderSchema';
import Breadcrumbs from '../../components/Breadcrumbs';
import Card from '../../components/Card';
import ToastComponent, { IToastType } from '../../components/Toast/ToastComponent';
import { CreatePintoSubscriptionTitleInput, EbooksSortType, EditPintoSubscriptionTitleInput, SubscriptionTitlePackage } from '../../core/graphql/types';
import useMutationCreatePintoSubscriptionTitle from '../../hooks/useMutationCreatePintoSubscriptionTitle';
import useEditDraftEbook from '../../hooks/useMutationEditDraftEbook';
import useMutationEditPintoSubscriptionTitle from '../../hooks/useMutationEditPintoSubscriptionTitle';
import useQueryPintoSubscriptionEbooks from '../../hooks/useQueryPintoSubscriptionEbooks';
import useQueryPintoSubscriptionTitle from '../../hooks/useQueryPintoSubscriptionTitle';
import useHookForm from '../../utils/form/useHookForm';
import scrollToView from '../../utils/scrollToView';

const PAGE_LIMIT = 10;

function EditSubscriptionPage() {
  // query and resume state
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const subscriptionId = searchParams.get('id');
  const { onFetchPintoSubscriptionTitle, isLoadingPintoSubscriptionTitle, pintoSubscriptionTitleData } = useQueryPintoSubscriptionTitle();
  const { fetchPintoSubscriptionEbooks, isLoadingPintoSubscriptionEbooks, subscriptionEbooks } = useQueryPintoSubscriptionEbooks();

  // mutation
  // mutation: create subscription
  const { isLoadingCreatePintoSubscriptionTitle, createPintoSubscriptionTitle } = useMutationCreatePintoSubscriptionTitle();
  // mutation: edit subscription
  const { isLoadingEditDraftEbook } = useEditDraftEbook();
  const { editPintoSubscriptionTitle } = useMutationEditPintoSubscriptionTitle();
  const [currentPage, setCurrentPage] = useState<number>(1);

  const {
    methods,
    onValidate,
    formState: { errors },
  } = useHookForm<ISubscriptionBuilderFields>({
    objectShapeForm: SubscriptionBuilderFormObj,
    mode: 'onChange',
    //resume state
    defaultValues: {},
  });

  const { setValue, watch } = methods;

  useEffect(() => {
    if (subscriptionId) {
      onFetchPintoSubscriptionTitle(subscriptionId);
      fetchPintoSubscriptionEbooks({ subscriptionTitleId: subscriptionId, limit: PAGE_LIMIT, sortBy: EbooksSortType.Created });
    }
  }, [subscriptionId, onFetchPintoSubscriptionTitle, fetchPintoSubscriptionEbooks]);

  const handleChangePage = useCallback(
    (page: number) => {
      setCurrentPage(page);
      const offset = (page - 1) * PAGE_LIMIT;
      fetchPintoSubscriptionEbooks({ subscriptionTitleId: subscriptionId, offset, limit: PAGE_LIMIT });
    },
    [fetchPintoSubscriptionEbooks, subscriptionId],
  );

  // resume form state
  useEffect(() => {
    if (pintoSubscriptionTitleData) {
      // user
      setValue(SUBSCRIPTION_BUILDER_FIELD_KEYS.USER_ID as ISubscriptionBuilderFields, pintoSubscriptionTitleData?.user._id);

      // detail
      setValue(SUBSCRIPTION_BUILDER_FIELD_KEYS.TITLE as ISubscriptionBuilderFields, pintoSubscriptionTitleData?.title);
      setValue(SUBSCRIPTION_BUILDER_FIELD_KEYS.SLUG as ISubscriptionBuilderFields, pintoSubscriptionTitleData?.slug);
    }
  }, [pintoSubscriptionTitleData, setValue]);

  // User
  const [userId] = watch([SUBSCRIPTION_BUILDER_FIELD_KEYS.USER_ID]);

  // Subscritipn Detail
  const [title, slug] = watch([SUBSCRIPTION_BUILDER_FIELD_KEYS.TITLE, SUBSCRIPTION_BUILDER_FIELD_KEYS.SLUG]);

  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);

  const onSelectedUser = useCallback(
    (userIdValue: string) => {
      setValue(SUBSCRIPTION_BUILDER_FIELD_KEYS.USER_ID as ISubscriptionBuilderFields, userIdValue);
    },
    [setValue],
  );

  const gatherFormInput = useCallback(async (): Promise<CreatePintoSubscriptionTitleInput | EditPintoSubscriptionTitleInput | undefined> => {
    // NOTES: use returned response from async function for that form state not changed yet when async function is done
    return { userId, title, slug };
  }, [userId, title, slug]);

  const onValidateFields = useCallback(async () => {
    const isValid = await onValidate();
    scrollToView('[data-invalid="true"]');
    return isValid;
  }, [onValidate]);

  const onSubmitPublish = useCallback(async () => {
    if (!(await onValidateFields())) {
      ToastComponent({ label: 'กรุณากรอกข้อมุลให้ครบถ้วน', type: IToastType.ERROR });
      return;
    }

    const input = await gatherFormInput();
    // console.log('input', input);

    if (!input) {
      ToastComponent({ label: 'กรุณากรอกข้อมุลให้ครบถ้วน', type: IToastType.ERROR });
      return;
    }

    if (subscriptionId) {
      await editPintoSubscriptionTitle(subscriptionId, omit(input, ['userId', 'slug']) as EditPintoSubscriptionTitleInput);
    } else {
      await createPintoSubscriptionTitle(input as CreatePintoSubscriptionTitleInput);
    }
  }, [createPintoSubscriptionTitle, editPintoSubscriptionTitle, gatherFormInput, onValidateFields, subscriptionId]);

  if (isLoadingPintoSubscriptionTitle) return <LoadingModal />;

  return (
    <>
      {(isLoadingCreatePintoSubscriptionTitle || isLoadingEditDraftEbook) && <LoadingModal title="กำลังบันทึก Subscription title" />}
      {showErrorModal && <ErrorModal onConFirm={() => setShowErrorModal(false)} confirmText="ตกลง" errorMessages={[]} />}

      <div className="space-y-12">
        <div className="sticky top-0 z-50 px-5 bg-componentsBgGrouped02">
          {/* section: breadcrumbs */}
          <Breadcrumbs items={[{ name: 'Subscriptions', url: '/subscriptions' }, { name: subscriptionId ? 'Edit Subscription' : 'Create Subscription' }]} />
          {/* section: title */}
          <div className="flex justify-between pt-5">
            <div className="flex space-x-8 items-baseline">
              <h1 className="font-dbh text-[28px]">{subscriptionId ? 'Edit' : 'Create'} Subscription</h1>
              {subscriptionId ? <h2 className="text-colorsBrandWarmBlack02LabelSecondary">{subscriptionId}</h2> : null}
            </div>
            <div className="flex items-center space-x-12">
              <Link to="/subscriptions">
                <button className="btn btn-outline">CANCEL</button>
              </Link>

              <button className="space-x-4 btn btn-error" disabled={!userId} onClick={() => onSubmitPublish()}>
                <div>Publish</div>
              </button>
            </div>
          </div>

          {/* section: main */}
          <div className="mb-0 divider opacity-10"></div>
        </div>
        <FormProvider {...methods}>
          <EditSubscriptionUserCreatorPresenter
            onSelectedUser={onSelectedUser}
            errors={errors?.userId?.message}
            selectedUser={pintoSubscriptionTitleData?.user}
            isEditUser={!!subscriptionId}
          />
          <EditSubscriptionDetailPresenter isCreateSub={!subscriptionId} />

          {subscriptionId ? <EditSubscriptionPackagePricePresenter packages={pintoSubscriptionTitleData?.packages ?? []} /> : null}

          {/* table content */}
          {subscriptionId ? (
            <div>
              {isLoadingPintoSubscriptionEbooks && <div className="w-full btn btn-outline btn-disabled bg-systemGrays04LabelQuaternary border-none loading h-[100px]" />}
              {!isLoadingPintoSubscriptionEbooks && (subscriptionEbooks?.edges?.length || 0) === 0 && (
                <Card className="flex flex-col items-center space-y-16 p-8 overflow-hidden">
                  <div className="w-full">
                    <div className="w-full flex">
                      <div className="text-22">Ebooks</div>
                    </div>
                    <div className="flex items-center justify-center w-full h-[25vh] text-colorsBrandWarmBlack02LabelSecondary">No Ebooks Found.</div>
                  </div>
                </Card>
              )}
              {!isLoadingPintoSubscriptionEbooks && (subscriptionEbooks?.edges?.length || 0) > 0 && (
                <SubscriptionEbooksTablePresenter
                  totalCount={subscriptionEbooks?.totalCount || 0}
                  pageLimit={PAGE_LIMIT}
                  currentPage={currentPage}
                  onChangePage={handleChangePage}
                  subscriptionEbookItem={subscriptionEbooks?.edges.map((edge) => edge.node) || []}
                />
              )}
            </div>
          ) : null}
        </FormProvider>
      </div>
    </>
  );
}

export default EditSubscriptionPage;
