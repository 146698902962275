import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';

export interface IBreadcrumbsItem {
  name: string;
  url?: string;
}

export interface IPropsBreadcrumbs {
  className?: string;
  items: IBreadcrumbsItem[];
}

function Breadcrumbs(props: IPropsBreadcrumbs) {
  const { className, items } = props;
  return (
    <div className={classNames('text-sm breadcrumbs text-systemGrays02LabelSecondary', className)}>
      <ul>
        {items.map((item) =>
          item.url ? (
            <li key={item.url}>
              <Link to={item.url}>{item.name}</Link>
            </li>
          ) : (
            <li key={item.name}>{item.name}</li>
          ),
        )}
      </ul>
    </div>
  );
}

export default Breadcrumbs;
