/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const profile = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path
      fill="#8C8C8C"
      fillRule="evenodd"
      d="M18.676 23C16.446 24.096 15 26.06 15 29.04v6.44c0 .65.284 1.258.76 1.633 2.395 1.882 5.142 2.823 8.24 2.823s5.845-.94 8.24-2.823c.476-.375.76-.984.76-1.633v-6.44c0-2.98-1.447-4.944-3.676-6.04-1.491 1.217-3.332 1.936-5.324 1.936s-3.833-.719-5.324-1.936zM24 21a6 6 0 100-12 6 6 0 000 12zm0 25C11.85 46 2 36.15 2 24S11.85 2 24 2s22 9.85 22 22-9.85 22-22 22z"
    />
  </svg>
);

export default profile;
