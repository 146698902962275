import classNames from 'classnames';
import { ReactNode } from 'react';
import { AiOutlineClockCircle } from 'react-icons/ai';

type AlertType = 'warning' | 'error';

export interface IPropsAlert {
  type?: AlertType;
  message?: string;
  description?: string | string[];
  showIcon?: boolean;
  action?: ReactNode;
}

const defaultProps = {
  message: '',
  description: '',
  type: 'warning',
  showIcon: false,
  action: undefined,
};

const ALERT_COLOR_SCHEME = {
  warning: {
    backgroundColor: 'alert alert-warning shadow-lg',
    iconColor: 'var(--white)',
  },
  error: {
    backgroundColor: 'alert alert-error shadow-lg',
    iconColor: 'var(--white)',
  },
};

const Alert = (props: IPropsAlert) => {
  const { type, message, description, showIcon, action } = props;
  const colorScheme = ALERT_COLOR_SCHEME[type as AlertType];

  return (
    <div className={classNames('flex w-full items-center justify-between rounded-6 px-16 py-12', colorScheme.backgroundColor)}>
      <div className="flex flex-col items-start">
        <div className="flex items-center">
          {showIcon && <AiOutlineClockCircle name="clock" size="24px" color={'fff'} />}
          <span className="ml-4 font-semibold text-white font-opn text-16">{message}</span>
        </div>
        {description && (
          <div className="mt-4 text-white font-tt text-20">
            {typeof description === 'string' ? (
              description
            ) : (
              <ul className="leading-tight">
                {description.map((_desc) => (
                  <li key={_desc} className="before:mr-4 before:content-['-']">
                    {_desc}
                  </li>
                ))}
              </ul>
            )}
          </div>
        )}
      </div>
      {action && <div>{action}</div>}
    </div>
  );
};

Alert.defaultProps = defaultProps;

export default Alert;
