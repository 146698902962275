import classNames from 'classnames';
import { MdOutlineArrowBackIos, MdOutlineArrowForwardIos } from 'react-icons/md';
import Slider from 'react-slick';

import Card from '../../../components/Card';
import { SECTION_LAYOUT_OPTIONS } from '../validates/sectionBuilderSchema';

const SampleNextArrow = (props: { className?: string; style?: React.CSSProperties | undefined; onClick?: any }) => {
  const { onClick } = props;
  return (
    <div className="text-black absolute right-[-48px] top-[20%] cursor-pointer px-8 py-48 border rounded-full hover:bg-gray-100 bg-gray-50" onClick={onClick}>
      <MdOutlineArrowForwardIos size="16px" />
    </div>
  );
};

const SamplePrevArrow = (props: { className?: string; style?: React.CSSProperties | undefined; onClick?: any }) => {
  const { onClick } = props;
  return (
    <div className="text-black absolute left-[-48px] top-[20%] cursor-pointer px-8 py-48 border rounded-full hover:bg-gray-100 bg-gray-50" onClick={onClick}>
      <MdOutlineArrowBackIos size="16px" />
    </div>
  );
};

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
};

interface IPropsSelectLayoutSectionPresenter {
  isEnableChangeLayout?: boolean;
  selectedLayout?: string;
  onSelectLayout?: (layoutId: string) => void;
  helpText?: string;
  className?: string;
  configItemsCount?: number;
}

const SelectLayoutSectionPresenter = (props: IPropsSelectLayoutSectionPresenter) => {
  const { selectedLayout, isEnableChangeLayout, configItemsCount, className, onSelectLayout } = props;
  return (
    <Card>
      <div className="text-22">Section Layout</div>
      <div className="py-16 space-y-12">
        <div>
          <span className="text-systemGrays02LabelSecondary">{'Layout '}</span>
          <span className="text-colorsBrandRed01Primary">*</span>: {SECTION_LAYOUT_OPTIONS.find((layout) => selectedLayout === layout.layoutId)?.label}
        </div>
        {!isEnableChangeLayout && (
          <div className="text-systemGrays02LabelSecondary text-12">
            {`*หากต้องการเปลี่ยน Section Layout กรุณาลบ ConfigItems เดิมออกทั้งหมดก่อน: (ConfigItems: ${configItemsCount || 0})`}
          </div>
        )}
      </div>
      <div className={classNames('px-48 w-[calc(100vw-160px)] py-20', className)}>
        <Slider {...settings}>
          {SECTION_LAYOUT_OPTIONS.map((layout) => (
            <button
              key={layout.layoutId}
              className={classNames('w-[fix-content] border-2 outline-none p-8 rounded-[6px] mx-4', {
                'border-colorsBrandRed01Primary': selectedLayout === layout.layoutId,
                'cursor-not-allowed': !isEnableChangeLayout,
              })}
              onClick={() => onSelectLayout?.(layout.layoutId)}
              disabled={!isEnableChangeLayout}
            >
              <div className="text-center">
                <div>{layout.label}</div>
                <img src={layout.imageUrl} />
              </div>
            </button>
          ))}
        </Slider>
      </div>
    </Card>
  );
};

export default SelectLayoutSectionPresenter;
