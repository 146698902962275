/* eslint-disable prettier/prettier */

import { useCallback, useEffect, useState } from 'react';
import { FiPlus } from 'react-icons/fi';
import { Link } from 'react-router-dom';

import SubscriptionsTablePresenter from '../../app/subscriptions/presenters/SubscriptionsTablePresenter';
import { SubscriptionTitle,  } from '../../core/graphql/types';
import useSearchSubscriptionTitles from '../../hooks/useSearchSubscriptionTitles';


const SubscriptionsPage = () => {
  const { fetchSearchSubscriptionTitles, searchSubscriptionTitles, isLoadingSearchSubscriptionTitles } = useSearchSubscriptionTitles();

  // const [searchText, setSearchText] = useState<string | undefined>(undefined);
  const [currentPage, setCurrentPage] = useState<number>(1);

  useEffect(() => {
      fetchSearchSubscriptionTitles({filter:{}, includeDetail: true});
  }, [fetchSearchSubscriptionTitles]);


  // const handleSetSearchText = useCallback((text) => {
  //   setSearchText(text);
  // }, []);

  const handleChangePage = useCallback(
    (page: number) => {
      setCurrentPage(page);
      const offset = (page - 1) * 10;
      fetchSearchSubscriptionTitles({ filter:{offset}, includeDetail: true});
    },
    [fetchSearchSubscriptionTitles],
  );

  return (
    <div className="mx-auto space-y-24">
      <div className="space-y-24 divider-y">
        {/* section: title */}
        <div className="flex justify-between py-5">
          <div className="flex space-x-8 items-baseline">
            <h1 className="font-dbh text-[28px]">Subscription</h1>
            <h2 className="text-colorsBrandWarmBlack02LabelSecondary">Sort by: Created at</h2>
          </div>

          <div className="space-x-16">
            <Link to="/subscriptions/edit">
              <button className="space-x-8 btn btn-error">
                <FiPlus className="font-bold text-24" />
                <span>Create New Subscription</span>
              </button>
            </Link>
          </div>
        </div>
        <div className="mb-0 divider opacity-10"></div>
      </div>

      {/* table content */}
      <div>
        {isLoadingSearchSubscriptionTitles && <div className="w-full btn btn-outline btn-disabled bg-systemGrays04LabelQuaternary border-none loading h-[100px]" />}
        {!isLoadingSearchSubscriptionTitles && (searchSubscriptionTitles?.edges?.length || 0) === 0 && (
          <div className="flex items-center justify-center w-full h-[60vh] text-colorsBrandWarmBlack02LabelSecondary">No Subscription Title data</div>
        )}
        {!isLoadingSearchSubscriptionTitles && (searchSubscriptionTitles?.edges?.length || 0) > 0 && (
          <SubscriptionsTablePresenter
            totalCount={searchSubscriptionTitles?.totalCount || 0}
            pageLimit={10}
            currentPage={currentPage}
            onChangePage={handleChangePage}
            subscriptionTitleItems={searchSubscriptionTitles?.edges.map((edge) => edge.node as SubscriptionTitle) || []}
          />
        )}
      </div>
    </div>
  );
};

export default SubscriptionsPage;
