import { useCallback } from 'react';

import { usePintoRemoveEbookMutation } from '../core/graphql/operation-hooks';

const useMutationPintoRemoveEbook = () => {
  const [mutatePintoRemoveEbook, { loading }] = usePintoRemoveEbookMutation();

  const onMutatePintoRemoveEbook = useCallback(
    async (ebookId: string, userId: string) => {
      const res = await mutatePintoRemoveEbook({
        variables: {
          input: {
            id: ebookId,
            userId,
          },
        },
      });
      return !!res.data?.removeEbook?._id;
    },
    [mutatePintoRemoveEbook],
  );

  return {
    onMutatePintoRemoveEbook,
    isLoadingMutatePintoRemoveEbook: loading,
  };
};

export default useMutationPintoRemoveEbook;
