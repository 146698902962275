/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const shareIos = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path
      fill="#8C8C8C"
      fillRule="evenodd"
      d="M28.03 17.165v-7.27a1 1 0 011.707-.708L44 23.415 29.737 37.644a1 1 0 01-1.706-.708v-7.55c-6.706 0-14.634 3.21-22.475 9.63A1 1 0 014 37.855c5.773-13.794 14.22-20.69 24.03-20.69z"
    />
  </svg>
);

export default shareIos;
