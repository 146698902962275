import { FieldValues, useFormContext, UseFormRegister } from 'react-hook-form';

import Input from '../../../components/form/Input';
import RadioGroupButton from '../../../components/form/RadioGroupButton';
import { EBOOK_TYPE_OPTIONS, PRICE_SETTING_OPTIONS, RATING_OPTIONS, SEARCH_TYPE_OPTIONS, SORT_BY_SEARCH_OPTIONS, SPECIAL_SEARCH_OPTIONS } from '../validates/sectionBuilderSchema';

import DropdownSelectNested from './DropdownSelectNested';
import SelectPintoCategoryWrapper from './SelectPintoCategory';
import SelectSearchType from './SelectSearchType';

export type SearchType = 'ebook' | 'user';

export interface ISearchSectionTarget {
  //TODO: continue implements
  register: UseFormRegister<FieldValues>;
  onSelectCategoryId?: (category: number) => void;
}

const SearchSectionTarget = (props: ISearchSectionTarget) => {
  const { register } = props;
  const { watch } = useFormContext();
  const [searchType, categoryId] = watch(['target.searchType', 'target.categoryId']);
  return (
    <div className="space-y-8 py-8">
      <SelectSearchType required label="Search Type" options={SEARCH_TYPE_OPTIONS} prefixName={`target`} name={[`target.searchType`]} />

      <Input register={() => register(`target.searchText`)} label="Search Text" name="searchText" helperText="Input Search Text" placeholder="Search Text" />

      {searchType === 'ebook' && (
        <>
          <SelectPintoCategoryWrapper name={`target.categoryId`} categoryId={categoryId} />

          <DropdownSelectNested label="SortBy" options={SORT_BY_SEARCH_OPTIONS} prefixName={`target`} name={[`target.sortBy`, 'target.sortType']} />

          <RadioGroupButton label="Specific Search" options={SPECIAL_SEARCH_OPTIONS} register={register} name={`target.searchField`} />

          <RadioGroupButton label="Rating" options={RATING_OPTIONS} register={register} name={`target.gteRating`} />

          <RadioGroupButton isSetArrayValue label="Price Type" options={PRICE_SETTING_OPTIONS} register={register} name={`target.anyPriceTypes`} />

          <RadioGroupButton isSetArrayValue label="Ebook Type" options={EBOOK_TYPE_OPTIONS} register={register} name={`target.anyTypes`} />
        </>
      )}
    </div>
  );
};

export default SearchSectionTarget;
