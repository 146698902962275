import classNames from 'classnames';
import qs from 'qs';
import { Link } from 'react-router-dom';

import { ContentType, ProductType } from '../../../core/graphql/types';

export interface IPropsEndOfChapterTableItem {
  contentType: ContentType;
  productType: ProductType;
  categoryTagId: string;
  productShortName: string;
  categoryName: string;
  updatedAtInFormat?: string;
  isActivated?: boolean;
  onTogglePublish?: () => void;
}

const EndOfChapterTableItem = (props: IPropsEndOfChapterTableItem): JSX.Element => (
  <tr className="hover">
    <th className="capitalize">
      {props.productShortName} - {props.categoryName}
    </th>
    <td>{props.updatedAtInFormat}</td>
    <td>
      {props.onTogglePublish && (
        <input
          onChange={props.onTogglePublish}
          checked={props.isActivated}
          defaultChecked={props.isActivated}
          type="checkbox"
          className={classNames('toggle', {
            'bg-colorsBrandTurquoise01Primary border-colorsBrandTurquoise01Primary': props.isActivated,
            'bg-colorsBrandRed01Primary border-bandredbg-colorsBrandRed01Primary': !props.isActivated,
          })}
        />
      )}

      {!props.onTogglePublish && '-'}
    </td>
    <td>
      <Link
        className="underline"
        to={`/suggestProducts/endOfChapter/edit?${qs.stringify({
          categoryTagId: props.categoryTagId,
          contentType: props.contentType,
          productType: props.productType,
        })}`}
      >
        Edit
      </Link>
    </td>
  </tr>
);

export default EndOfChapterTableItem;
