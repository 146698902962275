import classnames from 'classnames';
import { useState, useEffect, useMemo, useCallback } from 'react';
import { IoArrowForward, IoChevronForward } from 'react-icons/io5';

import { PintoCategory } from '../../core/graphql/types';
import useQueryPintoCategories from '../../hooks/useQueryPintoCategories';
import Button from '../Button';

export interface IPropsSelectPintoCategoryModal {
  openModalLabel?: string;
  title?: string;
  cancelText?: string;
  confirmText?: string;
  selectedCategories?: PintoCategory[];
  name: string;
  onConfirm?: (selectedCategories: PintoCategory[]) => void;
  onCancel?: () => void;
  btnClassName?: string;
}

const defaultProps = {
  title: 'เลือกหมวดหมู่',
  cancelText: 'ยกเลิก',
  confirmText: 'ยืนยัน',
  selectedCategories: undefined,
  onConfirm: undefined,
  onCancel: undefined,
  btnClassName: undefined,
};

// const allCategories = {
//   pintoCategories: [
//     {
//       _id: '1',
//       name: 'นิยาย',
//       canSetPrice: true,
//       childrens: [
//         {
//           _id: '4',
//           name: 'วรรณกรรมทั่วไป',
//           canSetPrice: true,
//           __typename: 'Category',
//           parentId: '1',
//           parent: { _id: '1', name: 'นิยาย', canSetPrice: true, __typename: 'Category' },
//           childrens: [
//             { _id: '43', name: 'วรรณกรรมเยาวชน', canSetPrice: true, parentId: '4' },
//             {
//               _id: '46',
//               name: 'วรรณกรรมการเมือง',
//               canSetPrice: true,
//               parentId: '4',
//             },
//           ],
//         },
//       ],
//     },
//   ],
// };

const SelectEbookPintoCategoryModal = (props: IPropsSelectPintoCategoryModal) => {
  const { openModalLabel, title, cancelText, confirmText, selectedCategories, name, onConfirm, btnClassName } = props;
  const { pintoCategories: allCategories } = useQueryPintoCategories();
  const [firstLevelCategory, setFirstLevelCategory] = useState<PintoCategory>();
  const [secondLevelCategory, setSecondLevelCategory] = useState<PintoCategory>();
  const [thirdLevelCategory, setThirdLevelCategory] = useState<PintoCategory>();
  const [activeTab, setActiveTab] = useState<number>(selectedCategories?.length || 1);

  const handleConfirm = useCallback(() => {
    const _selectedCategories = [firstLevelCategory, secondLevelCategory, thirdLevelCategory].filter((category) => category) as PintoCategory[];
    onConfirm?.(_selectedCategories);
  }, [firstLevelCategory, secondLevelCategory, thirdLevelCategory, onConfirm]);

  const handleSelectedCategory = useCallback((category: PintoCategory, selectedLevel: number) => {
    let _activeTab = selectedLevel;
    if (selectedLevel === 1) {
      setFirstLevelCategory(category);
      setSecondLevelCategory(undefined);
      setThirdLevelCategory(undefined);
    } else if (selectedLevel === 2) {
      _activeTab = 2;
      setSecondLevelCategory(category);
      setThirdLevelCategory(undefined);
    } else {
      setThirdLevelCategory(category);
    }
    if ((category?.childrens?.length || 0) > 0) {
      _activeTab += 1;
    }
    setActiveTab(_activeTab);
  }, []);

  // NOTES: handle back tab for mobile size
  const handleBackTab = useCallback(() => {
    if (activeTab > 1) {
      setActiveTab(activeTab - 1);
    }
  }, [activeTab]);

  const renderCategoryListComponent = useCallback(
    (_categories: PintoCategory[] | undefined, selectedCategory: PintoCategory | undefined, selectedLevel: number) => {
      return _categories?.map((category) => {
        return (
          <div
            key={category._id}
            aria-hidden="true"
            className={classnames(
              'flex h-[52px] cursor-pointer items-center justify-between px-[10px] pr-[15px] pl-[16px] font-semibold text-colorsBrandWarmBlack02LabelSecondary hover:bg-colorsBrandWarmBlack07FillTertiary',
              { 'bg-componentsBgGrouped01 !text-colorsBrandWarmBlack01LabelPrimary': category._id === selectedCategory?._id },
            )}
            onClick={() => {
              handleSelectedCategory(category, selectedLevel);
            }}
          >
            <span>{category.name}</span>
            {(category?.childrens?.length || 0) > 0 && <IoChevronForward title="IoChevronForward" size="24px" color="var(--colorsBrandWarmBlack01LabelPrimary)" />}
          </div>
        );
      });
    },
    [handleSelectedCategory],
  );

  const categories = useMemo(() => allCategories?.pintoCategories, [allCategories?.pintoCategories]) as PintoCategory[];
  const firstLevelCategoryList = useMemo(() => renderCategoryListComponent(categories, firstLevelCategory, 1), [categories, firstLevelCategory, renderCategoryListComponent]);
  const secondLevelCategoryList = useMemo(
    () => renderCategoryListComponent(firstLevelCategory?.childrens as PintoCategory[], secondLevelCategory, 2),
    [firstLevelCategory, secondLevelCategory, renderCategoryListComponent],
  );
  const thirdLevelCategoryList = useMemo(
    () => renderCategoryListComponent(secondLevelCategory?.childrens as PintoCategory[], thirdLevelCategory, 3),
    [secondLevelCategory, thirdLevelCategory, renderCategoryListComponent],
  );
  const currentSelectedCategoryLabel = useMemo(() => {
    let currentSelectedCategoryName;
    if (activeTab === 2) {
      currentSelectedCategoryName = firstLevelCategory?.name;
    } else if (activeTab === 3) {
      currentSelectedCategoryName = secondLevelCategory?.name;
    }

    return currentSelectedCategoryName;
  }, [activeTab, firstLevelCategory, secondLevelCategory]);

  useEffect(() => {
    if (selectedCategories && selectedCategories.length > 0) {
      let _activeTab = 1;
      if (selectedCategories[2]) {
        _activeTab = 3;
      } else if (selectedCategories[1]) {
        _activeTab = 2;
      }
      const _firstLevelCategory = categories.find((cat) => cat._id === selectedCategories?.[0]?._id);
      const _secondLevelCategory = _firstLevelCategory?.childrens?.find((cat) => cat?._id === selectedCategories?.[1]?._id) as PintoCategory | undefined;
      const _thirdLevelCategory = _secondLevelCategory?.childrens?.find((cat) => cat?._id === selectedCategories?.[2]?._id) as PintoCategory | undefined;
      setFirstLevelCategory(_firstLevelCategory);
      setSecondLevelCategory(_secondLevelCategory);
      setThirdLevelCategory(_thirdLevelCategory);
      setActiveTab(_activeTab);
    }
  }, [categories, selectedCategories]);

  return (
    <>
      <label htmlFor={name} className={classnames('btn btn-error modal-button', btnClassName)}>
        {openModalLabel || 'Select Pinto Category'}
      </label>

      <input type="checkbox" id={name} className="modal-toggle" />
      <div className="modal modal-bottom">
        <div className="flex flex-col p-24 md:p-32 min-w-[768px] bg-componentsBgGrouped02 rounded-box">
          <h4 className={classnames('text-48 text-center font-opn font-semibold text-colorsBrandWarmBlack01LabelPrimary md:text-22')}>{title || 'เลือกหมวดหมู่'}</h4>
          <div className="mt-12 h-[440px] overflow-y-hidden rounded-lg border border-solid border-colorsBrandWarmBlack04LabelQuaternary">
            <div className={classnames('mt-12 flex h-[32px] items-center md:hidden', { hidden: activeTab === 1 })}>
              <Button type="ghost" color="black" className="h-full p-0 pl-8" onClick={handleBackTab}>
                <IoArrowForward title="IoArrowForward" size="24px" color="var(--colorsBrandWarmBlack01LabelPrimary)" />
              </Button>
              <span className="ml-4 font-semibold font-opn text-16 text-colorsBrandWarmBlack01LabelPrimary">{currentSelectedCategoryLabel}</span>
            </div>
            <div className="relative flex w-full h-full py-12 overflow-x-hidden bg-currentColor justify-evenly">
              <div
                className={classnames('absolute z-20 h-full w-full overflow-y-scroll bg-componentsBgGrouped02 transition-transform md:relative', {
                  'translate-x-[0px]': activeTab === 1,
                  'translate-x-[-100%] md:translate-x-[0px]': activeTab !== 1,
                })}
              >
                {firstLevelCategoryList}
              </div>
              <div
                className={classnames('absolute z-10 h-full w-full overflow-y-scroll bg-componentsBgGrouped02 transition-transform md:relative', {
                  'translate-x-[100%] md:translate-x-[-100%]': activeTab === 1,
                  'translate-x-[0px]': activeTab === 2,
                  'translate-x-[-100%] md:translate-x-[0px]': activeTab === 3,
                })}
              >
                {secondLevelCategoryList}
              </div>
              <div
                className={classnames('absolute z-0 h-full w-full overflow-y-scroll bg-componentsBgGrouped02 transition-transform md:relative', {
                  'translate-x-[0px]': activeTab === 3,
                  'translate-x-[100%] md:translate-x-[-100%]': activeTab !== 3,
                })}
              >
                {thirdLevelCategoryList}
              </div>
            </div>
          </div>
          <div className="mb-8 mt-12 hidden h-[28px] items-center font-opn text-14 font-semibold text-colorsBrandWarmBlack02LabelSecondary md:flex">
            {[firstLevelCategory, secondLevelCategory, thirdLevelCategory]
              .filter((category) => category)
              .map((category) => {
                return (
                  <span key={category?._id} className="after:mx-[8px] after:content-['•'] last:after:content-none">
                    {category?.name}
                  </span>
                );
              })}
          </div>
          <div className="flex justify-between mt-24">
            <div className="modal-action">
              <label htmlFor={name} className="btn btn-outline">
                {cancelText}
              </label>
            </div>

            <div className="modal-action">
              <label htmlFor={name} className="btn btn-error" onClick={handleConfirm}>
                {confirmText}
              </label>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

SelectEbookPintoCategoryModal.defaultProps = defaultProps;

export default SelectEbookPintoCategoryModal;
