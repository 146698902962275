import { useMemo } from 'react';
import { Link } from 'react-router-dom';

import Card from '../../../components/Card';
import Pagination from '../../../components/Pagination';
import { Ebook, EbookStatus } from '../../../core/graphql/types';
import { getDayTime } from '../../../utils/dayjs';

const DATE_FORMAT = 'DD/MM/YYYY';

export interface ISubscriptionEbookItem {
  id: string;
  createDate: string;
  updateDate: string;
  title: string;
  status: EbookStatus;
}

export interface IPropsSubscriptionEbooksTablePresenter {
  subscriptionEbookItem: Pick<Ebook, '_id' | 'title' | 'status' | 'createdAt' | 'updatedAt'>[];
  currentPage: number;
  pageLimit?: number;
  totalCount?: number;
  onChangePage: (page: number) => void;
}

export const EBOOK_STATUS_CLASS_NAME = {
  [EbookStatus.Public]: 'badge badge-success',
  [EbookStatus.Removed]: 'badge badge-error',
  [EbookStatus.Scheduled]: 'badge badge-warning',
  [EbookStatus.Draft]: 'badge',
  [EbookStatus.Hidden]: 'badge badge-error',
};

function SubscriptionEbooksTablePresenter(props: IPropsSubscriptionEbooksTablePresenter) {
  const { subscriptionEbookItem, currentPage, pageLimit, totalCount, onChangePage } = props;

  const mappedSubscriptionEbookItems = useMemo(
    () =>
      subscriptionEbookItem.map(
        (item): ISubscriptionEbookItem =>
          ({
            id: item?._id,
            createDate: item?.createdAt,
            updateDate: item?.updatedAt,
            title: item?.title || '',
            status: item.status,
          } as ISubscriptionEbookItem),
      ),
    [subscriptionEbookItem],
  );

  return (
    <Card className="flex flex-col items-center space-y-16 p-8 overflow-hidden">
      <div className="w-full">
        <div className="w-full flex">
          <div className="flex space-x-8 items-baseline">
            <h1 className="font-dbh text-[22px]">Ebooks</h1>
            <h2 className="text-colorsBrandWarmBlack02LabelSecondary">Sort by: Created at</h2>
          </div>
        </div>
        <div className="mt-12 overflow-auto rounded-md">
          <table className="table w-full table-zebra font-tt">
            <thead>
              <tr>
                <th>ID</th>
                <th>Title</th>
                <th>Ebook Status</th>
              </tr>
            </thead>
            <tbody>
              {mappedSubscriptionEbookItems?.map((item) => (
                <tr key={item.id}>
                  <th className="font-normal">
                    <Link to={`/ebooks/detail?id=${item.id}`} className="underline text-colorsBrandRed01Primary">
                      {item.id}
                    </Link>
                  </th>
                  <td>
                    {item.title} <span>({getDayTime(item?.createDate || '', DATE_FORMAT)})</span>
                  </td>
                  <td>
                    <div className={EBOOK_STATUS_CLASS_NAME[item.status]} style={{ marginRight: '8px' }}>
                      {item.status}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="flex items-center justify-between mt-12">
          <span className="text-systemGrays02LabelSecondary">แสดงผล {pageLimit} รายการต่อหน้า</span>

          {(totalCount || 0) > 0 && <Pagination currentPage={currentPage} totalCount={totalCount || 0} pageSize={pageLimit || 0} onPageChange={onChangePage} />}
        </div>
      </div>
    </Card>
  );
}

export default SubscriptionEbooksTablePresenter;
