import { useCallback } from 'react';

import { useBookPageAdsLazyQuery } from '../core/graphql/operation-hooks';
import { BookPageAdsFilter } from '../core/graphql/types';

const INITIAL_LIMIT = 10;

const useQueryBookPageAds = () => {
  const [queryBookPageAds, { data, loading }] = useBookPageAdsLazyQuery({ fetchPolicy: 'cache-and-network' });

  const fetchBookPageAds = useCallback(
    (filter: BookPageAdsFilter) => {
      queryBookPageAds({
        variables: {
          filter: {
            ...filter,
            limit: INITIAL_LIMIT,
          },
        },
      });
    },
    [queryBookPageAds],
  );

  return { fetchBookPageAds, bookPageAds: data?.bookPageAds, isLoadingBookPageAds: loading };
};

export default useQueryBookPageAds;
