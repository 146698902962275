import { useMutation } from '@apollo/client';

import { CreatePopupDocument } from '../core/graphql/operation-hooks';

const useMutationCreatePopUp = () => {
  const [mutateCreatePopup, { data, loading }] = useMutation(CreatePopupDocument);
  return {
    mutateCreatePopup,
    createPopupQueryId: data?.createPopup._id,
    isLoadingMutateCreatePopup: loading,
  };
};

export default useMutationCreatePopUp;
