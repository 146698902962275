/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const key = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path
      fill="#8C8C8C"
      fillRule="evenodd"
      d="M42 29v4.313c-.833.873-1.667 1.31-2.5 1.31s-1.667-.437-2.5-1.31V29h-2v4.313c-.833.873-1.667 1.31-2.5 1.31s-1.667-.437-2.5-1.31V29h-5.088c-1.896 4.131-6.069 7-10.912 7-6.627 0-12-5.373-12-12 0-3.304 1.335-6.296 3.495-8.466A11.963 11.963 0 0114 12c4.843 0 9.016 2.869 10.912 7h18.035a1 1 0 01.735.322l3.066 3.321a2 2 0 010 2.714l-3.066 3.321a1 1 0 01-.735.322H42zm-28 0a5 5 0 100-10 5 5 0 000 10z"
    />
  </svg>
);

export default key;
