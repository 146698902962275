/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const warningFill = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path
      fill="#8C8C8C"
      fillRule="evenodd"
      d="M24 46C11.85 46 2 36.15 2 24S11.85 2 24 2s22 9.85 22 22-9.85 22-22 22zm3-11.024c-.001 1.732-1.318 3.03-3.07 3.024-1.603-.007-2.928-1.35-2.93-2.975-.002-1.706 1.32-3.027 3.024-3.025A2.975 2.975 0 0127 34.976zM24 10a3 3 0 013 3v12a3 3 0 01-6 0V13a3 3 0 013-3z"
    />
  </svg>
);

export default warningFill;
