import classnames from 'classnames';
import { MdArrowLeft, MdArrowRight } from 'react-icons/md';

interface IPropsPagination {
  className?: string;
  currentPage: number;
  onPageChange: (page: number) => void;
  pageSize: number;
  siblingCount?: number;
  totalCount: number;
}

import { usePagination, DOTS } from './usePagination';

const Pagination = (props: IPropsPagination) => {
  const { onPageChange, totalCount, siblingCount = 1, currentPage, pageSize, className } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  const lastPage = paginationRange[paginationRange.length - 1];
  return (
    <div className={classnames('btn-group', className)}>
      <button
        className={classnames('btn btn-sm', {
          'btn-disabled': currentPage === 1,
        })}
        disabled={currentPage === 1}
        onClick={onPrevious}
      >
        <MdArrowLeft size={22} />
      </button>

      {paginationRange.map((pageNumber) => {
        if (pageNumber === DOTS) {
          return <div className="btn btn-sm">&#8230;</div>;
        }

        return (
          <div
            key={pageNumber}
            className={classnames('btn btn-sm', {
              'btn-error': pageNumber === currentPage,
            })}
            onClick={() => onPageChange(Number(pageNumber))}
          >
            {pageNumber}
          </div>
        );
      })}

      <button
        className={classnames('btn btn-sm', {
          'btn-disabled': currentPage === lastPage,
        })}
        disabled={currentPage === lastPage}
        onClick={onNext}
      >
        <MdArrowRight size={22} />
      </button>
    </div>
  );
};

export default Pagination;
