/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const creditCard = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path
      fill="#8C8C8C"
      fillRule="evenodd"
      d="M3 20h42a1 1 0 011 1v15a3 3 0 01-3 3H5a3 3 0 01-3-3V21a1 1 0 011-1zM5 8h38a3 3 0 013 3v3a1 1 0 01-1 1H3a1 1 0 01-1-1v-3a3 3 0 013-3zm4.692 24h15.532a4.284 4.284 0 00.028-4.96l-.028-.04H9.692l-.027.044A4.742 4.742 0 009.692 32z"
    />
  </svg>
);

export default creditCard;
