/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const starEmpty = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path
      fill="#8C8C8C"
      fillRule="evenodd"
      d="M23.545 38.389l-12.094 6.496c-.498.267-1.105.054-1.356-.476a1.136 1.136 0 01-.093-.673l2.29-13.636a1.117 1.117 0 00-.305-.973L2.32 19.5a1.124 1.124 0 01-.048-1.519.991.991 0 01.598-.33l13.434-1.994c.326-.048.609-.262.759-.573l6.039-12.5c.255-.528.864-.736 1.36-.465.187.103.34.265.436.464l6.04 12.501c.15.311.432.525.758.573l13.434 1.994c.552.082.937.625.86 1.213a1.099 1.099 0 01-.31.636l-9.667 9.627c-.25.249-.365.615-.304.973l2.289 13.636c.098.584-.268 1.143-.817 1.247a.956.956 0 01-.632-.098l-12.094-6.496a.956.956 0 00-.91 0zm.269-8.156a.383.383 0 01.372 0l4.948 2.719c.08.044.17.058.258.04.225-.043.375-.276.334-.521l-.936-5.708a.475.475 0 01.124-.408l3.955-4.03a.464.464 0 00.127-.266c.032-.246-.126-.473-.352-.507l-5.496-.835a.416.416 0 01-.31-.24l-2.47-5.233a.433.433 0 00-.18-.194c-.202-.114-.451-.027-.555.194l-2.471 5.233a.416.416 0 01-.31.24l-5.496.835a.403.403 0 00-.245.138.478.478 0 00.02.636l3.955 4.03a.475.475 0 01.124.407l-.936 5.708a.485.485 0 00.037.282c.103.222.352.31.555.199l4.948-2.72z"
    />
  </svg>
);

export default starEmpty;
