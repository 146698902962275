import { debounce, sum } from 'lodash';
import React from 'react';

import css from './HorizontalScrollable.module.scss';
import Icon from './Icon';

export interface IPropsHorizontalScrollable {
  isShowArrowButton?: boolean;
  children: JSX.Element[];
}

const onScrollToSnapChildren = debounce((event: React.UIEvent<HTMLDivElement, UIEvent>) => {
  const element = event.target as HTMLDivElement;
  const itemWidth = element.children[0].clientWidth;
  const leftItemIndex = Math.round(element.scrollLeft / itemWidth);
  element.scroll({ left: sum([...element.children].slice(0, leftItemIndex).map((child) => child.clientWidth)), behavior: 'smooth' });
}, 400);

const HorizontalScrollable = (props: IPropsHorizontalScrollable): JSX.Element => {
  const scrollableNodeRef = React.useRef<HTMLDivElement | null>(null);
  const [scrollLeft, setScrollLeft] = React.useState(0);
  const [isShowLeftButton, setIsShowLeftButton] = React.useState(false);
  const [isShowRightButton, setIsShowRightButton] = React.useState(false);

  React.useEffect(() => {
    setIsShowLeftButton(scrollLeft > 0 ? true : false);
    const sectionInnerContentScrollLeft = scrollLeft + (scrollableNodeRef.current?.offsetWidth || 0);
    const scrollWidth = scrollableNodeRef.current?.scrollWidth ?? 0;
    const hasMoreRightScroll = scrollWidth > (scrollableNodeRef.current?.offsetWidth ?? 0);
    setIsShowRightButton(sectionInnerContentScrollLeft < scrollWidth && hasMoreRightScroll ? true : false);
  }, [scrollableNodeRef, scrollLeft]);

  const onClickRightArrow = () => {
    scrollableNodeRef.current?.scroll({ left: scrollLeft + scrollableNodeRef.current?.offsetWidth, behavior: 'smooth' });
  };

  const onClickLeftArrow = () => {
    scrollableNodeRef.current?.scroll({ left: scrollLeft - scrollableNodeRef.current?.offsetWidth, behavior: 'smooth' });
  };

  return (
    <div className="relative">
      <div>
        {props.isShowArrowButton && isShowLeftButton ? (
          <div className={`${css['arrow-button']} ${css['arrow-left']}`} onClick={onClickLeftArrow}>
            <Icon color="#fff" name="caretLeft" size="22px" />
          </div>
        ) : null}

        <div
          ref={scrollableNodeRef}
          className={css.hideScrollbar}
          onScroll={(e) => {
            onScrollToSnapChildren(e);
            const scrollLeft = (e.target as HTMLDivElement).scrollLeft ?? 0;
            setScrollLeft(scrollLeft);
          }}
        >
          {props.children}
        </div>

        {props.isShowArrowButton && isShowRightButton ? (
          <div className={`${css['arrow-button']} ${css['arrow-right']}`} onClick={onClickRightArrow}>
            <Icon color="#fff" name="caretRight" size="22px" />
          </div>
        ) : undefined}
      </div>
    </div>
  );
};

HorizontalScrollable.defaultProps = {
  isShowArrowButton: true,
};

export default HorizontalScrollable;
