/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const cashout = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path
      fill="#8C8C8C"
      fillRule="evenodd"
      d="M16 29V18a1 1 0 011-1h14a1 1 0 011 1v11h6.985a1 1 0 01.647 1.763L24 44 8.368 30.763A1 1 0 019.015 29H16zm-5-19v5a1 1 0 01-1 1H6a1 1 0 01-1-1V4.99c0-.546.453-.99.997-.99h36.006c.55 0 .997.451.997.99V15a1 1 0 01-1 1h-4a1 1 0 01-1-1v-5H11z"
    />
  </svg>
);

export default cashout;
