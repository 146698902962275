import { useCallback } from 'react';

import { usePintoEbookBundleLazyQuery } from '../core/graphql/operation-hooks';

const useQueryPintoEbookBundle = () => {
  const [queryPintoEbookBundle, { data, loading }] = usePintoEbookBundleLazyQuery();

  const onFetchPintoEbookBundle = useCallback(
    async (id: string) => {
      queryPintoEbookBundle({ variables: { id } });
    },
    [queryPintoEbookBundle],
  );
  return {
    onFetchPintoEbookBundle,
    pintoEbookBundleData: data?.ebookBundle,
    isLoadingPintoEbookBundle: loading,
  };
};

export default useQueryPintoEbookBundle;
