interface IPropsObjectLabel {
  objectLists?: { key: string; value?: string | number; required?: boolean }[];
}

const RenderObjectLabel = (props: IPropsObjectLabel) => {
  const { objectLists } = props;
  return (
    <>
      <span className="label-text text-14 text-systemGrays02LabelSecondary">{`( `}</span>
      {objectLists?.map((obj, index) => (
        <span className="label-text text-14 text-systemGrays02LabelSecondary" key={obj.key}>
          <span>{obj.key}</span>
          {obj.required && <span className="text-colorsBrandRed01Primary">{` *`}</span>}
          <span>{`: ${obj.value || '-'}`}</span>
          {index !== objectLists.length - 1 && <span>{`, `}</span>}
        </span>
      ))}
      <span className="label-text text-14 text-systemGrays02LabelSecondary">{` )`}</span>
    </>
  );
};

export default RenderObjectLabel;
