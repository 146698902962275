/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const voteUp = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path
      fill="#8C8C8C"
      fillRule="evenodd"
      d="M30.23 16h9.833a4 4 0 013.85 5.085l-4.23 15A4 4 0 0135.833 39H23a6 6 0 01-6-6V17a1.02 1.02 0 010-.066c.01-3.895 8.12-3.367 8.05-12.893-.005-.568 0-.347 0-1.067 0-1.02 1.77-.974 2.65-.974.87 0 6.401-.054 2.53 14zM5.347 16H12a1 1 0 011 1v21a1 1 0 01-1 1H6.67a1 1 0 01-.994-.884L3.361 18.23A2 2 0 015.348 16z"
    />
  </svg>
);

export default voteUp;
