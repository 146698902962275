import classNames from 'classnames';
import { useCallback, useMemo } from 'react';
import DatePicker from 'react-datepicker';
import { useFormContext } from 'react-hook-form';
import { FiDownload } from 'react-icons/fi';

import Button from '../../../components/Button';
import Input from '../../../components/form/Input';
import UploadFileBox from '../../../components/UploadFileBox';
import { EbookDiscountCampaignStatus } from '../../../core/graphql/types';
import { addDate, endOfDate, isSameDate, startOfDate, subtractDate } from '../../../utils/dayjs';
import { DISCOUNT_FIELD_KEYS, IDiscountFields } from '../validates/discountSchema';

export const DISCOUNT_STATUS = {
  [EbookDiscountCampaignStatus.Pending]: 'In queue',
  [EbookDiscountCampaignStatus.Processing]: 'In progress',
  [EbookDiscountCampaignStatus.Complete]: 'Complete',
  [EbookDiscountCampaignStatus.Cancelled]: 'Terminated',
};

export const DISCOUNT_STATUS_CLASS_NAME = {
  [EbookDiscountCampaignStatus.Pending]: 'badge badge-outline',
  [EbookDiscountCampaignStatus.Processing]: 'badge badge-warning badge-outline',
  [EbookDiscountCampaignStatus.Complete]: 'badge badge-success badge-outline',
  [EbookDiscountCampaignStatus.Cancelled]: 'badge badge-error badge-outline',
};

const HAS_DISCOUNT_END_DATE_OPTIONS = [
  { label: 'With End Date', value: true },
  { label: 'Without End Date', value: false },
];

interface IPropsDiscountDetailPresenter {
  status?: EbookDiscountCampaignStatus;
  selectedCSVFile: File | undefined;
  fileName?: string;
  fileUrl?: string;
  readOnly?: boolean;
  onSelectCSVFile: (file: File | undefined) => void;
  onClickDownloadCSVTemplate: () => void;
}

const DiscountDetailPresenter = (props: IPropsDiscountDetailPresenter) => {
  const { selectedCSVFile, onSelectCSVFile, status, onClickDownloadCSVTemplate, fileName, fileUrl, readOnly } = props;

  const {
    register,
    setValue,
    setError,
    watch,
    formState: { errors },
  } = useFormContext();
  const [startDate, endDate, isHasDiscountEndDate = true] = watch([DISCOUNT_FIELD_KEYS.START_DATE, DISCOUNT_FIELD_KEYS.END_DATE, DISCOUNT_FIELD_KEYS.IS_HAS_DISCOUNT_END_DATE]);

  const currentDate = useMemo(() => new Date(), []);

  // start date
  const getStartDateMinTime = useCallback(() => {
    if (!startDate || (startDate && isSameDate(startDate, currentDate))) {
      return addDate(currentDate, 1, 'minute').toDate();
    }

    return startOfDate(startDate).toDate();
  }, [currentDate, startDate]);

  const getStartDateMaxTime = useCallback(() => {
    if (endDate && isSameDate(endDate, startDate)) {
      return subtractDate(endDate, 1, 'minute').toDate();
    }

    return endOfDate(startDate || currentDate).toDate();
  }, [currentDate, endDate, startDate]);

  // end date
  const getEndDateMinTime = useCallback(() => {
    if ((startDate && !endDate) || (endDate && isSameDate(endDate, startDate))) {
      return addDate(startDate, 1, 'minute').toDate();
    }

    if (!endDate || (endDate && isSameDate(endDate, currentDate))) {
      return addDate(currentDate, 1, 'minute').toDate();
    }

    return startOfDate(endDate || currentDate).toDate();
  }, [currentDate, endDate, startDate]);

  const getEndDateMaxTime = useCallback(() => {
    return endOfDate(startDate || endDate || currentDate).toDate();
  }, [currentDate, endDate, startDate]);

  const onClickIsHasDiscountEndDateOption = useCallback(
    (value: boolean) => {
      setValue(DISCOUNT_FIELD_KEYS.IS_HAS_DISCOUNT_END_DATE as IDiscountFields, value);
      if (!value) {
        setValue(DISCOUNT_FIELD_KEYS.END_DATE as IDiscountFields, undefined);
      }
    },
    [setValue],
  );

  return (
    <div className="h-full space-y-12">
      {status && (
        <div className="flex items-center justify-end">
          Status: <span className={classNames('ml-4', DISCOUNT_STATUS_CLASS_NAME[status])}>{DISCOUNT_STATUS[status]}</span>
        </div>
      )}

      <Input
        name={DISCOUNT_FIELD_KEYS.TITLE}
        register={register}
        label="Note for admin"
        required
        placeholder="note"
        helperText={errors.title?.message}
        isInvalid={errors.title}
        disabled={!!readOnly}
      />

      <div>
        {!readOnly && (
          <div className="space-x-16">
            {HAS_DISCOUNT_END_DATE_OPTIONS.map((option) => (
              <button
                key={option.label}
                className={classNames('btn btn-outline border px-8', {
                  'border-colorsBrandRed01Primary text-colorsBrandRed01Primary': isHasDiscountEndDate === option.value,
                })}
                onClick={() => onClickIsHasDiscountEndDateOption?.(option.value)}
              >
                {option.label}
              </button>
            ))}
          </div>
        )}
        <div className="flex w-full max-w-[680px]">
          <div className="flex flex-col flex-1">
            <label className="label label-text text-systemGrays02LabelSecondary">
              <span className="label-text text-systemGrays02LabelSecondary">
                Start Date <span className="text-colorsBrandRed01Primary">*</span>
              </span>
            </label>
            <DatePicker
              className={classNames('input input-bordered w-full font-tt text-systemGrays02LabelSecondary font-semibold', {
                '!input-error': errors.startDate,
              })}
              required
              timeFormat="HH:mm"
              timeIntervals={5}
              timeCaption="time"
              dateFormat="d MMM yyyy, h:mm aa."
              showTimeSelect
              // showYearDropdown
              selectsStart
              selected={startDate}
              startDate={startDate}
              endDate={endDate}
              minDate={currentDate}
              maxDate={endDate}
              minTime={getStartDateMinTime()}
              maxTime={getStartDateMaxTime()}
              placeholderText="Start , Time"
              onChange={(start: Date) => {
                if (start >= endDate) {
                  setError(DISCOUNT_FIELD_KEYS.START_DATE, {
                    type: 'min',
                    message: 'The start date must earlier then the end date',
                  });
                } else {
                  setValue(DISCOUNT_FIELD_KEYS.START_DATE as IDiscountFields, start, {
                    shouldValidate: true,
                  });
                }
              }}
              disabled={!!readOnly}
            />
          </div>
          {isHasDiscountEndDate && <div className="px-4 flex justify-center items-end mb-[6px]">-</div>}
          {isHasDiscountEndDate && (
            <div className="flex flex-col flex-1">
              <label className="label label-text text-systemGrays02LabelSecondary">
                <span className="label-text text-systemGrays02LabelSecondary">
                  End Date <span className="text-colorsBrandRed01Primary">*</span>
                </span>
              </label>
              <DatePicker
                className={classNames('input input-bordered w-full font-tt text-systemGrays02LabelSecondary font-semibold', {
                  '!input-error': errors.endDate,
                })}
                timeFormat="HH:mm"
                timeIntervals={5}
                timeCaption="time"
                dateFormat="d MMM yyyy, h:mm aa."
                showTimeSelect
                // showYearDropdown
                selectsStart
                selected={endDate}
                startDate={startDate}
                endDate={endDate}
                minDate={startDate || currentDate}
                minTime={getEndDateMinTime()}
                maxTime={getEndDateMaxTime()}
                placeholderText="End , Time"
                onChange={(end: Date) => {
                  setValue(DISCOUNT_FIELD_KEYS.END_DATE as IDiscountFields, end, {
                    shouldValidate: true,
                  });
                }}
                disabled={!!readOnly}
              />
            </div>
          )}
        </div>

        {errors.startDate?.message || errors.endDate?.message ? (
          <label className="label">
            <span className="label-text-alt text-colorsBrandRed01Primary font-tt">{errors.startDate?.message || errors.endDate?.message}</span>
          </label>
        ) : (
          <label className="label !mt-0">
            <span className="label-text-alt text-systemGrays02LabelSecondary font-tt">Effective discount date and time</span>
          </label>
        )}
      </div>

      <UploadFileBox
        label="Upload file"
        required
        hasError={!!errors.fileName}
        helperText={errors.fileName?.message || 'Only .CSV file type allowed'}
        onSelectFile={onSelectCSVFile}
        acceptFileExtension=".csv"
        fileName={fileName ?? selectedCSVFile?.name}
        rightLabelNode={
          <Button color="red" type="ghost" className="text-colorsBrandRed01Primary" onClick={onClickDownloadCSVTemplate}>
            <FiDownload className="text-24" />
            <p className="ml-4">Download CSV Template</p>
          </Button>
        }
        readOnly={!!readOnly}
        fileUrl={fileUrl}
      />
    </div>
  );
};

export default DiscountDetailPresenter;
