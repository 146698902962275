import { slice } from 'lodash';
import { useCallback, useRef, useState } from 'react';

import { ScheduleInput } from '../../../../core/graphql/types';
import Icon from '../../../../fictionlog/components/Icon';
import useMutaionUploadStaticFile from '../../../../hooks/useMutaionUploadStaticFile';
import { IPopupItemInput } from '../../hooks/useFormPopupItems';

import ScheduleSelector from './ScheduleSelector';

interface IPropsLinkPopupItem {
  popupIndex: number;
  item: IPopupItemInput;
  onUpdatePopupItem: (popupIndex: number, item: Partial<IPopupItemInput>) => void;
  onDeletePopupItem: (popupIndex: number) => void;
  onPreviewPopupItem?: (popupIndex: number) => void;
}

const LinkPopupItem = (props: IPropsLinkPopupItem) => {
  const { popupIndex, item, onUpdatePopupItem, onDeletePopupItem, onPreviewPopupItem } = props;

  const { note, schedules, configUrl, popupImageUrl } = item;

  const { mutateUpdateStaticFile } = useMutaionUploadStaticFile();

  const uploadImageRef = useRef<HTMLInputElement | null>(null);
  const [imageUploaded, setImageUploaded] = useState<string | ArrayBuffer | undefined | null>(popupImageUrl);
  const [isLimit, setIsLimit] = useState<boolean>(false);

  const onChangeAdminNote = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const note = e.target.value;

      const updatedItem = {
        note,
      };

      onUpdatePopupItem(popupIndex, updatedItem);
    },
    [onUpdatePopupItem, popupIndex],
  );

  const onChangeConfigInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, fieldName: string) => {
      const configData = e.target.value;
      const updatedItem = {
        [fieldName]: configData,
      };

      onUpdatePopupItem(popupIndex, updatedItem);
    },
    [onUpdatePopupItem, popupIndex],
  );

  const onClickUpload = useCallback(() => {
    if (!uploadImageRef?.current) return;
    uploadImageRef.current.click();
  }, []);

  const onUploaded = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files?.length) {
        const blob = e.target.files[0];

        if (blob.size > 5242880) {
          setIsLimit(true);
          setImageUploaded(undefined);
          return;
        }

        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = async () => {
          const base64 = reader.result as string;

          const data = await mutateUpdateStaticFile({ variables: { data: base64 } });
          const popupImageUrl = data.data?.uploadStaticFile?.url || undefined;

          const updatedItem = {
            popupImageUrl,
          };

          setImageUploaded(base64);
          onUpdatePopupItem(popupIndex, updatedItem);
        };
        setIsLimit(false);
      }
    },
    [mutateUpdateStaticFile, onUpdatePopupItem, popupIndex],
  );

  const onRemovePopupImage = useCallback(() => {
    const popupImageUrl = undefined;

    const updatedItem = {
      popupImageUrl,
    };

    setImageUploaded(popupImageUrl);
    onUpdatePopupItem(popupIndex, updatedItem);
  }, [onUpdatePopupItem, popupIndex]);

  const onCreateScheduleInSection = useCallback(
    (editedSchedule: ScheduleInput) => {
      const newSchedules = schedules?.length ? [...schedules, editedSchedule] : [editedSchedule];
      const updatedItem = {
        schedules: newSchedules,
      };

      onUpdatePopupItem(popupIndex, updatedItem);
    },
    [onUpdatePopupItem, popupIndex, schedules],
  );

  const onUpdateScheduleInSection = useCallback(
    (scheduleIndex: number, editedSchedule: ScheduleInput) => {
      if (!schedules?.length) return;
      // first arrays
      const beginArray = slice(schedules, 0, scheduleIndex);
      //last arrays
      const lastArray = slice(schedules, scheduleIndex + 1, schedules.length);
      const newSchedules = [...beginArray, editedSchedule, ...lastArray];

      const updatedItem = {
        schedules: newSchedules,
      };

      onUpdatePopupItem(popupIndex, updatedItem);
    },
    [onUpdatePopupItem, popupIndex, schedules],
  );

  const onDeleteScheduleInSection = useCallback(
    (scheduleIndex: number) => {
      if (!schedules?.length) return;

      const newSchedules = schedules.filter((e, index) => index !== scheduleIndex);

      const updatedItem = {
        schedules: newSchedules,
      };

      onUpdatePopupItem(popupIndex, updatedItem);
    },
    [onUpdatePopupItem, popupIndex, schedules],
  );

  return (
    <div className="p-16 m-8 space-y-16">
      <div className="flex justify-between">
        <div className="flex items-center space-x-8">
          <h3 className="font-bold text-20">Link</h3>
        </div>
        <div>
          <div className="dropdown dropdown-end">
            <button tabIndex={-1} className="m-1 btn">
              ⋮
            </button>
            <ul tabIndex={-1} className="p-2 shadow menu dropdown-content bg-base-100 rounded-box w-52">
              {onPreviewPopupItem && (
                <li>
                  <a onClick={() => onPreviewPopupItem(popupIndex)}>Preview</a>
                </li>
              )}
              <li>
                <a onClick={() => onDeletePopupItem(popupIndex)}>Delete</a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="form-control">
        <label className="label" htmlFor="LinkPopupItem-admin-note">
          <span className="font-bold label-text">Admin Note:</span>
        </label>
        <textarea className="h-24 textarea textarea-bordered" placeholder="Admin Note" id="LinkPopupItem-admin-note" onChange={onChangeAdminNote} value={note}></textarea>
      </div>
      <div className="form-control">
        <label className="label" htmlFor="LinkPopupItem-url">
          <span className="font-bold label-text-text">Url*:</span>
        </label>
        <div className="flex">
          <input
            type="text"
            id="LinkPopupItem-book-id"
            placeholder="url"
            className="flex-1 input input-bordered"
            onChange={(e) => onChangeConfigInput(e, 'configUrl')}
            value={configUrl}
          />
          <a href={configUrl} target="_blank" rel="noreferrer">
            <button className="btn">
              <Icon name="view" size="24px" />
            </button>
          </a>
        </div>
      </div>
      <div className="font-bold label-text">Popup Image:</div>
      <div className="flex space-x-24">
        <input ref={uploadImageRef} type="file" accept="image/*,audio/*,video/*" onChange={onUploaded} className="hidden opacity-0" />
        {imageUploaded && (
          <div className="relative w-[180px]">
            <img src={imageUploaded.toString()} alt="popupImage" width="180" className="relative" />
          </div>
        )}
        {isLimit && <div className="text-colorsBrandRed01Primary">รูปขนาด เกิน 5 MB ไม่สามารถอัปโหลดได้</div>}

        <div className="space-y-24">
          {imageUploaded && (
            <button onClick={onRemovePopupImage} className="w-[180px] btn btn-outline btn-sm">
              Remove Image
            </button>
          )}
          <button onClick={onClickUpload} className="w-[180px] btn btn-outline btn-sm">
            {imageUploaded ? 'Change Image' : 'Select Image'}
          </button>
        </div>
      </div>
      <ScheduleSelector
        schedules={schedules?.length ? schedules : []}
        onCreateScheduleInSection={onCreateScheduleInSection}
        onUpdateScheduleInSection={onUpdateScheduleInSection}
        onDeleteScheduleInSection={onDeleteScheduleInSection}
      />
    </div>
  );
};

export default LinkPopupItem;
