import { ApolloClient, from } from '@apollo/client';

import errorLink from './errorLink';
import headerLink from './headerLink';
import httpLink from './httpLink';
import inMemoryCache from './inMemoryCache';

export const client = new ApolloClient({
  uri: process.env.REACT_APP_GRAPHQL_API_URL,
  ssrMode: false,
  connectToDevTools: process.env.NODE_ENV !== 'production',
  cache: inMemoryCache,
  link: from([errorLink, headerLink, httpLink]),
  defaultOptions: {
    mutate: {
      errorPolicy: 'ignore',
    },
    watchQuery: {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
    },
  },
});
