/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const arrowUp = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path
      fill="#8C8C8C"
      fillRule="evenodd"
      d="M24 3l4.2 3.973L42 20.027A3.974 3.974 0 0138.026 24H37.8L26.995 13.779V43a2 2 0 01-2 2h-1.99a2 2 0 01-2-2V13.78L10.2 24h-.226A3.974 3.974 0 016 20.028L19.8 6.973 24 3z"
    />
  </svg>
);

export default arrowUp;
