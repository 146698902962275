// import { debounce } from 'lodash';
// import { ChangeEvent, useCallback } from 'react';
// import { FiSearch } from 'react-icons/fi';

import Button from '../../../components/Button';
import { PublisherType } from '../../../core/graphql/types';
import EbookFilterDropdown, { IEbookFilterItem } from '../components/EbookFilterDropdown';

interface IPropsEditEbookFilterAndSearchPresenter {
  status: string | null;
  statusOptions?: { label: string; value: string | null }[];
  onSetStatus: (status: string | null) => void;
  onSetSearchText?: (text?: string) => void;

  publisherFilterOptions: IEbookFilterItem[];
  onPublisherFilterChange?: (value: PublisherType | null) => void;
}

const EditEbookFilterAndSearchPresenter = ({
  status,
  statusOptions,
  onSetStatus,
  // onSetSearchText,
  onPublisherFilterChange,
  publisherFilterOptions,
}: IPropsEditEbookFilterAndSearchPresenter) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // const onChangeKeyWord = useCallback(
  //   debounce((e: ChangeEvent<HTMLInputElement>) => {
  //     onSetSearchText(e.target.value);
  //   }, 500),
  //   [],
  // );

  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center space-x-12">
        <div className="text-systemGrays02LabelSecondary">Status: </div>
        <div className="space-x-4">
          {statusOptions?.map((option) => (
            <Button
              round
              type={option.value === status ? 'fill' : 'outline'}
              key={option.value}
              color={option.value === status ? 'red' : 'gray'}
              onClick={() => onSetStatus(option.value)}
              className="h-[40px]"
            >
              {option.label?.toLocaleUpperCase()}
            </Button>
          ))}
        </div>
        <EbookFilterDropdown label="Publisher: " items={publisherFilterOptions} onFilterChange={onPublisherFilterChange} />
      </div>

      {/* <div className="relative">
        <FiSearch className="absolute top-[16px] left-[12px] text-colorsBrandWarmBlack02LabelSecondary text-18" />
        <input type="text" placeholder="Search ebooks" className="w-[300px] pl-36 input" onChange={onChangeKeyWord} />
      </div> */}
    </div>
  );
};

export default EditEbookFilterAndSearchPresenter;
