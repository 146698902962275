/* eslint-disable no-useless-escape */
import * as yup from 'yup';
import { AnySchema } from 'yup';
import Lazy from 'yup/lib/Lazy';
import Reference from 'yup/lib/Reference';

import { IErrorObject } from '../../../utils/validator';

export enum BUNDLE_FIELD_KEYS {
  TITLE = 'title',
  FILE_NAME = 'fileName',
}

export interface IErrorsBundle {
  title?: IErrorObject;
  fileName?: IErrorObject;
}

export type IBundleFields = keyof IErrorsBundle;

const BundleFormObj: { [key in IBundleFields]: AnySchema<unknown, unknown, unknown> | Reference<unknown> | Lazy<never, unknown> } = {
  title: yup.string().nullable().required('Title is required').trim(),
  fileName: yup.string().nullable().required('Please upload CSV file'),
};

export default BundleFormObj;
