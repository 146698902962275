import { usePopupsQuery } from '../core/graphql/operation-hooks';
import { PopupsFilter } from '../core/graphql/types';

export type IPropsUseQueryPopups = PopupsFilter;

const useQueryPopups = (props?: IPropsUseQueryPopups) => {
  const { data, loading } = usePopupsQuery({ variables: { filter: props } });

  return {
    popups: data?.popups,
    isLoadingPopups: loading,
  };
};

export default useQueryPopups;
