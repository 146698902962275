export enum ErrorTypes {
  MIN = 'min',
  MAX = 'max',
  MATCHES = 'matches',
  EMAIL = 'email',
  REQUIRED = 'required',
  REQUIRED_AT_LEAST_ONE = 'required at least 1 person',
}
export interface IErrorObject {
  message?: string;
  type?: ErrorTypes;
}
