import { useCallback } from 'react';

import { usePintoDownloadEbookBundlesMutation } from '../core/graphql/operation-hooks';

const useMutationPintoDownloadEbookBundles = () => {
  const [mutationPintoDownloadEbookBundles, { loading }] = usePintoDownloadEbookBundlesMutation();

  const downloadEbookBundleCsv = useCallback(async () => {
    try {
      const response = await mutationPintoDownloadEbookBundles();
      return response.data?.downloadEbookBundles;
    } catch (error) {
      console.error('Error downloading ebook bundles:', error);
      throw error;
    }
  }, [mutationPintoDownloadEbookBundles]);
  return {
    downloadEbookBundleCsv,
    isLoadingDownloadEbookBundles: loading,
  };
};

export default useMutationPintoDownloadEbookBundles;
