import { useCallback } from 'react';

import { usePintoHideEbookMutation } from '../core/graphql/operation-hooks';

const useMutationPintoHideEbook = () => {
  const [mutatePintoHideEbook, { loading }] = usePintoHideEbookMutation();

  const onMutatePintoHideEbook = useCallback(
    async (ebookId: string) => {
      const res = await mutatePintoHideEbook({ variables: { ebookId }, refetchQueries: ['PintoEbook'] });
      return !!res.data?.hideEbook._id;
    },
    [mutatePintoHideEbook],
  );

  return {
    onMutatePintoHideEbook,
    isLoadingMutatePintoHideEbook: loading,
  };
};

export default useMutationPintoHideEbook;
