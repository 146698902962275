/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const view = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path
      fill="#8C8C8C"
      fillRule="evenodd"
      d="M1.415 26c-.553-.68-.553-3.32 0-4C9.455 12.105 15 8 24 8s14.545 4.105 22.585 14c.553.68.553 3.32 0 4C38.545 35.894 33.047 40 24 40S9.455 35.894 1.415 26zM34 24.061c.029-5.514-4.427-10.017-9.955-10.06-5.503-.044-10.01 4.425-10.045 9.96-.035 5.509 4.43 10.005 9.968 10.039 5.474.033 10.003-4.454 10.032-9.939zM24 29a5 5 0 100-10 5 5 0 000 10z"
    />
  </svg>
);

export default view;
