import classNames from 'classnames';
import { useMemo } from 'react';

import { SubscriptionTitlePackage } from '../core/graphql/types';
import { numberWithCommasWithDecimalPlace } from '../utils/convertNumber';
import { convertTimeDescription } from '../utils/date';

export interface IPropsSummarySubscriptionPackagePrice {
  className?: string;
  packages?: (SubscriptionTitlePackage | null)[];
  language?: 'TH' | 'EN';
}

const SummarySubscriptionPackagePrice = (props: IPropsSummarySubscriptionPackagePrice) => {
  const { className, packages = [], language = 'TH' } = props;

  const bahtText = useMemo(() => {
    return language === 'TH' ? 'บาท' : 'Baht';
  }, [language]);

  const columns = [
    { label: language === 'TH' ? 'ชื่อแพคเกจ' : 'Package Name', key: 'title', align: 'left' },
    { label: language === 'TH' ? 'ราคาเว็บไซต์' : 'Website Price', key: 'webPrice', align: 'right' },
    { label: language === 'TH' ? 'ราคา App Store' : 'App Store Price', key: 'applePrice', align: 'right' },
    { label: language === 'TH' ? 'ราคา Google Play' : 'Google Play Price', key: 'googlePrice', align: 'right' },
    { label: language === 'TH' ? 'Interval time' : 'Interval Time', key: 'period', align: 'right' },
  ];

  return (
    <div className={classNames('w-full flex flex-col', className)}>
      <table className="w-full table-auto border-collapse shadow-lg">
        <thead className="bg-gray-200">
          <tr>
            {columns.map((col) => (
              <th key={col.key} scope="col" className={`whitespace-nowrap py-4 px-6 text-${col.align} font-medium text-gray-700`}>
                {col.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {packages.map((pkg, index) => {
            if (!pkg) return null;

            const { title, applePriceTier, googlePriceTier, priceThb, period } = pkg;

            return (
              <tr key={index} className="border-b">
                <th scope="row" className="whitespace-nowrap text-left pl-8 py-16">
                  {title || '-'}
                </th>
                <th scope="row" className="whitespace-nowrap text-right py-16">
                  {priceThb ? `${numberWithCommasWithDecimalPlace(priceThb)} ${bahtText}` : '-'}
                </th>
                <th scope="row" className="whitespace-nowrap text-right pr-8 py-16">
                  {applePriceTier?.thb ? `${numberWithCommasWithDecimalPlace(applePriceTier.thb)} ${bahtText}` : '-'}
                </th>
                <th scope="row" className="whitespace-nowrap text-right pr-8 py-16">
                  {googlePriceTier?.thb ? `${numberWithCommasWithDecimalPlace(googlePriceTier.thb)} ${bahtText}` : '-'}
                </th>
                <th scope="row" className="whitespace-nowrap text-right pr-8 py-16">
                  {period ? `${convertTimeDescription(period)}` : '-'}
                </th>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default SummarySubscriptionPackagePrice;
