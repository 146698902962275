/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const minusFill = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path
      fill="#8C8C8C"
      fillRule="evenodd"
      d="M12.487 21a6.186 6.186 0 000 6H35.53a5.967 5.967 0 000-6H12.487zM24 46C11.85 46 2 36.15 2 24S11.85 2 24 2s22 9.85 22 22-9.85 22-22 22z"
    />
  </svg>
);

export default minusFill;
