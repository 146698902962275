import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { FiCheck, FiUploadCloud, FiX } from 'react-icons/fi';
import { Link } from 'react-router-dom';

import LeavePageAlert from '../../../components/LeavePageAlert';
import Pagination from '../../../components/Pagination';
import ToastComponent, { IToastType } from '../../../components/Toast/ToastComponent';
import { useSignedUrlMutation } from '../../../core/graphql/operation-hooks';
import {
  EbookFileType,
  EditEbook,
  SubmitReviewEbookEbookFileInput,
  EditEbookStatus,
  EditEbookFile,
  CorrectionEbookRequestedSampleFileType,
  CorrectionEbookFileStatus,
  UploadRequestedEbookFileData,
  EbookStatus,
} from '../../../core/graphql/types';
import useMutationPintoRejectEditEbook from '../../../hooks/useMutationPintoRejectEditEbook';
import useMutationPintoUploadRequestedEbookFile from '../../../hooks/useMutationPintoUploadRequestedEbookFile';
import useMutationSignedUrl from '../../../hooks/useMutationSignedUrl';
import { getDayTime } from '../../../utils/dayjs';
import ErrorModal from '../../modals/ErrorModal';
import LoadingModal from '../../modals/LoadingModal';
import RejectModal from '../modals/RejectModal';
import UploadFilesModal from '../modals/UploadFilesModal';

const PAGE_LIMIT = 10;
const DATE_FORMAT = 'DD-MM-YYYY hh:mm A';

export interface IUploadToolItem {
  id: string;
  coverImageUrl: string;
  title?: string;
  ebookId?: string;
  ebookStatus?: EbookStatus;
  status?: EditEbookStatus;
  categoryId?: string;
  correctionEpubFiles: EditEbookFile[];
  correctionPdfFiles: EditEbookFile[];
  userNote?: string;
  rejectNotes?: string[];
  activatedDate?: string;
  createDate?: string;
  updateDate?: string;
  requiresEpub?: boolean;
  requiresPdf?: boolean;
}

export interface IPropsUploadToolTablePresenter {
  uploadToolItems: EditEbook[];
  currentPage: number;
  pageLimit?: number;
  totalCount?: number;
  onChangePage: (page: number) => void;
}

export const STATUS_CLASS_NAME = {
  [EditEbookStatus.Draft]: 'badge badge-info',
  [EditEbookStatus.Pending]: 'badge badge-warning',
  [EditEbookStatus.Rejected]: 'badge badge-error',
};

export const EBOOK_STATUS_CLASS_NAME = {
  [EbookStatus.Draft]: 'badge badge-ghost',
  [EbookStatus.Public]: 'badge badge-success',
  [EbookStatus.Removed]: 'badge badge-error',
  [EbookStatus.Hidden]: 'badge badge-error',
  [EbookStatus.Scheduled]: 'badge badge-warning',
};

type FileData = {
  mainFileUrl: string;
  mainFilename: string;
  sampleFileUrl: string;
  sampleFilename: string;
};

type UploadedFiles = {
  [rowId: string]: {
    [EbookFileType.Pdf]?: FileData;
    [EbookFileType.Epub]?: FileData;
  };
};

type UploadedStatus = {
  [rowId: string]: {
    pdf?: boolean;
    epub?: boolean;
    pdf_saved?: boolean;
    epub_saved?: boolean;
    saved?: boolean;
    rejected?: boolean;
  };
};

function UploadToolTablePresenter(props: IPropsUploadToolTablePresenter) {
  const { uploadToolItems, currentPage, pageLimit, totalCount, onChangePage } = props;

  // Track uploaded status for each row
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFiles>({});
  const [uploadedStatus, setUploadedStatus] = useState<UploadedStatus>({});
  const [showUploadFilesModal, setUploadFilesModal] = useState<boolean>(false);
  const [showRejectModal, setShowRejectModal] = useState<boolean>(false);
  const [currentFileType, setCurrentFileType] = useState<EbookFileType>(EbookFileType.Pdf);
  const [rejectData, setRejectData] = useState<IUploadToolItem>();
  const [currentRowInfo, setCurrentRowInfo] = useState<{ rowId: string | null; ebookId: string | null }>({
    rowId: null,
    ebookId: null,
  });

  const { onMutatePintoRejectEditEbook } = useMutationPintoRejectEditEbook();
  const { onMutatePintoUploadRequestedEbookFile } = useMutationPintoUploadRequestedEbookFile();
  const { signedUrl } = useMutationSignedUrl();

  const [showLoadingModal, setShowLoadingModal] = useState<boolean>(false);
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
  const handleOpenModal = (fileType: EbookFileType, rowId: string, ebookId: string) => {
    setCurrentFileType(fileType);
    setCurrentRowInfo({ rowId, ebookId }); // Set both rowId and ebookId
    setUploadFilesModal(true); // Open modal
  };

  // Handle file save and mark row as uploaded
  // const handleConfirmFileUpload = (rowId: string, fileData: SubmitReviewEbookEbookFileInput[]) => {
  const handleConfirmFileUpload = (currentRowInfo: { rowId: string; ebookId: string }, fileData: SubmitReviewEbookEbookFileInput[]) => {
    setUploadFilesModal(false);

    const { rowId, ebookId } = currentRowInfo;

    // Return early if rowId or ebookId is null
    if (!rowId || !ebookId) {
      console.error('Invalid rowId or ebookId');
      return;
    }

    const _fileData = fileData[0];
    // Prepare input for mutation
    const uploadInput: UploadRequestedEbookFileData = {
      mainFileUrl: _fileData.mainFileUrl,
      mainFilename: _fileData.mainFilename,
      sampleFileUrl: _fileData.sampleFileUrl,
      sampleFilename: _fileData.sampleFilename,
      type: _fileData.type,
    };

    setUploadedFiles((prevFiles) => ({
      ...prevFiles,
      [rowId]: {
        ...prevFiles[rowId],
        [_fileData.type]: uploadInput,
      },
    }));

    setUploadedStatus((prevStatus) => ({
      ...prevStatus,
      [rowId]: {
        ...prevStatus[rowId],
        [_fileData.type]: true,
      },
    }));
  };

  const handleSubmit = useCallback(
    async (rowId: string, ebookId: string) => {
      // Return early if rowId or ebookId is null
      if (!rowId || !ebookId) {
        console.error('Invalid rowId or ebookId');
        return;
      }

      // Retrieve all files for the given rowId
      const filesToUpload = uploadedFiles[rowId];

      // Check if there are files to upload
      if (!filesToUpload || Object.keys(filesToUpload).length === 0) {
        console.error('No file data to upload');
        return;
      }

      // Transform filesToUpload into an array of UploadRequestedEbookFileData with type added manually
      const ebookFiles: UploadRequestedEbookFileData[] = Object.entries(filesToUpload).map(([key, file]) => ({
        mainFileUrl: file.mainFileUrl,
        mainFilename: file.mainFilename,
        sampleFileUrl: file.sampleFileUrl,
        sampleFilename: file.sampleFilename,
        type: key === 'pdf' ? EbookFileType.Pdf : EbookFileType.Epub, // Set type based on the file key
      }));

      setShowLoadingModal(true);

      const isSuccess = await onMutatePintoUploadRequestedEbookFile(ebookId, { ebookFiles });
      setShowLoadingModal(false);

      if (isSuccess) {
        setUploadedStatus((prevStatus) => ({
          ...prevStatus,
          [rowId]: {
            ...prevStatus[rowId],
            ebookId,
            pdf_saved: !!filesToUpload.pdf, // Mark PDF as saved if it exists
            epub_saved: !!filesToUpload.epub, // Mark EPUB as saved if it exists
            saved: !!filesToUpload.pdf && !!filesToUpload.epub, // Mark the entire row as saved
          },
        }));
        ToastComponent({ label: 'Submit Ebook successfully', type: IToastType.SUCCESS });
      } else {
        setShowErrorModal(true);
      }
    },
    [onMutatePintoUploadRequestedEbookFile, uploadedFiles],
  );

  const handleOpenRejectModal = (data: IUploadToolItem) => {
    setRejectData(data);
    setShowRejectModal(true);
  };

  const handleRejectConfirm = async (data: { rowId: string; ebookId: string; rejectNote: string; fileTypes: EbookFileType[] }) => {
    setShowRejectModal(false);
    const { rowId, ebookId, rejectNote, fileTypes } = data;

    if (ebookId && rejectNote && !isEmpty(fileTypes)) {
      setShowLoadingModal(true);

      // Filter only the unsaved files for rejection
      const fileTypesToReject = fileTypes.filter((fileType) => {
        return !(fileType === EbookFileType.Pdf ? uploadedStatus[rowId]?.pdf_saved : uploadedStatus[rowId]?.epub_saved);
      });

      // Reject only if there are file types to reject (unsaved files)
      if (fileTypesToReject.length > 0) {
        const isSuccess = await onMutatePintoRejectEditEbook(ebookId, rejectNote, fileTypesToReject);
        setShowLoadingModal(false);

        if (isSuccess) {
          // Update uploadedStatus to mark only unsaved files as rejected
          setUploadedStatus((prevStatus) => ({
            ...prevStatus,
            [rowId]: {
              ...prevStatus[rowId],
              rejected: true,
              ...fileTypesToReject.reduce<{ pdf?: boolean; epub?: boolean }>((acc, fileType) => {
                if (fileType === EbookFileType.Pdf) {
                  acc.pdf = false; // Set PDF as rejected
                } else if (fileType === EbookFileType.Epub) {
                  acc.epub = false; // Set EPUB as rejected
                }
                return acc;
              }, {}),
            },
          }));
          ToastComponent({ label: 'Reject ebook successfully', type: IToastType.SUCCESS });
        } else {
          setShowErrorModal(true);
        }
      } else {
        setShowLoadingModal(false);
        ToastComponent({ label: 'No unsaved files to reject', type: IToastType.WARNING });
      }
    }
  };

  const handleOpenMainFile = async (url: string) => {
    try {
      const _signedUrl = (await signedUrl(url)) as string;
      window.open(_signedUrl, '_blank');
    } catch (e) {
      console.error(e);
      alert(e);
    }
  };

  const mappedUploadToolItems = useMemo(
    () =>
      uploadToolItems.map((item): IUploadToolItem => {
        const correctionEpubFiles = item?.correctionEbookFiles?.filter((file) => file.type === EbookFileType.Epub) || [];
        const correctionPdfFiles = item?.correctionEbookFiles?.filter((file) => file.type === EbookFileType.Pdf) || [];

        return {
          id: item?._id,
          createDate: item?.createdAt,
          updateDate: item?.updatedAt,
          ebookStatus: item.ebook?.status ?? undefined,
          status: item.status ?? undefined,
          title: item?.ebook?.title || '',
          ebookId: item.ebookId ?? undefined,
          coverImageUrl: item?.ebook?.coverImageUrl || '',
          correctionEpubFiles, // Store mapped Epub files
          correctionPdfFiles, // Store mapped Pdf files
          requiresEpub: correctionEpubFiles.length > 0 && !!correctionEpubFiles[0].requestedFileUrl, // Requires Epub if there’s at least one epub file with a requested URL
          requiresPdf: correctionPdfFiles.length > 0 && !!correctionPdfFiles[0].requestedFileUrl, // Requires Pdf if there’s at least one pdf file with a requested URL
        };
      }),
    [uploadToolItems],
  );

  // Memoize `hasUnsavedChanges` to update only when `uploadedStatus` changes
  const hasUnsavedChanges = useMemo(
    () => Object.values(uploadedStatus).some((status) => !status?.saved),
    [uploadedStatus], // Recalculate only when `uploadedStatus` changes
  );

  const handlePageChange = useCallback(
    (page: number) => {
      if (hasUnsavedChanges) {
        if (window.confirm('You have unsaved changes. Do you want to discard them and change the page?')) {
          onChangePage(page); // Proceed with page change
        }
      } else {
        onChangePage(page); // No unsaved changes, proceed
      }
    },
    [hasUnsavedChanges, onChangePage],
  );

  return (
    <div>
      {showErrorModal && <ErrorModal onConFirm={() => setShowErrorModal(false)} confirmText="ตกลง" errorMessages={['กรุณาตรวจสอบความถูกต้อง แล้วลองบันทึกอีกรอบ']} />}
      {showLoadingModal && <LoadingModal />}

      <LeavePageAlert showConfirmLeavePageAlert={hasUnsavedChanges} message="All change will be discarded, Are you sure to leave this page ?" />

      <div className="mt-12 overflow-auto rounded-md">
        <table className="table w-full table-zebra font-tt">
          <thead>
            <tr>
              <th>Requested Date</th>
              <th>Ebook Status</th>
              <th>Review Status</th>
              <th>Cover</th>
              <th>Ebook</th>
              <th>Raw PDF</th>
              <th>Raw EPUB</th>
              <th>Sample Chapter PDF</th>
              <th>Sample Chapter EPUB</th>
              <th>UpLoad PDF</th>
              <th>UpLoad EPUB</th>
              <th colSpan={2}>Action</th>
            </tr>
          </thead>
          <tbody>
            {mappedUploadToolItems?.map((item) => (
              <tr key={item.id}>
                <td className="max-w-xs break-words whitespace-normal">
                  <div className="whitespace-normal">{item?.updateDate ? <span>{getDayTime(item?.updateDate || '', DATE_FORMAT)}</span> : <span>-</span>}</div>
                </td>
                <td>
                  {item.ebookStatus ? (
                    <div className={`${EBOOK_STATUS_CLASS_NAME[item.ebookStatus]} font-bold`}>{item.ebookStatus.toLocaleUpperCase()}</div>
                  ) : (
                    <div className={`badge badge-ghost font-bold`}>None</div>
                  )}
                </td>
                <td>
                  {item.status ? (
                    <div className={`${STATUS_CLASS_NAME[item.status]} font-bold`}>{item.status.toLocaleUpperCase()}</div>
                  ) : (
                    <div className={`badge badge-ghost font-bold`}>None</div>
                  )}
                </td>
                <td>
                  <div className="avatar">
                    <div className="w-[50px] h-[75px] rounded">
                      <img src={item.coverImageUrl} alt={item.title} />
                    </div>
                  </div>
                </td>
                <td>
                  {
                    <div>
                      <Link to={`/ebooks/detail?id=${item.ebookId}`} className="underline text-colorsBrandRed01Primary">
                        {item.ebookId}
                      </Link>
                      <div className="max-w-xs truncate">{item.title}</div>
                    </div>
                  }
                </td>
                {/* PDF File Download */}
                <td>
                  <div className="flex items-center justify-center">
                    {item.requiresPdf ? (
                      <button className="btn btn-error btn-sm" onClick={() => handleOpenMainFile(item.correctionPdfFiles[0].requestedFileUrl!)}>
                        link
                      </button>
                    ) : (
                      <div className="flex items-center justify-center">
                        <span>-</span>
                      </div>
                    )}
                  </div>
                </td>

                {/* EPUB File Download */}
                <td>
                  <div className="flex items-center justify-center">
                    {item.requiresEpub ? (
                      <button className="btn btn-error btn-sm" onClick={() => handleOpenMainFile(item.correctionEpubFiles[0].requestedFileUrl!)}>
                        link
                      </button>
                    ) : (
                      <div className="flex items-center justify-center">
                        <span>-</span>
                      </div>
                    )}
                  </div>
                </td>
                <td className="max-w-[200px] break-words whitespace-normal">
                  <div className="whitespace-normal line-clamp-5">
                    {/* Prioritize PDF if it has values */}
                    {item.correctionPdfFiles.length > 0 && item.correctionPdfFiles[0]?.requestedSampleFileType === CorrectionEbookRequestedSampleFileType.Specific ? (
                      <div>
                        <div>
                          <span>{`(${item.correctionPdfFiles[0]?.lastSampleChapterNumber})`}</span>
                        </div>
                        <div>
                          <span>{`${item.correctionPdfFiles[0]?.lastSampleChapterName || ''}`}</span>
                        </div>
                      </div>
                    ) : item.correctionPdfFiles.length > 0 && item.correctionPdfFiles[0]?.requestedSampleFileType === CorrectionEbookRequestedSampleFileType.Standard ? (
                      <div>
                        <span className="text-colorsBrandRed01Primary text-12 font-bold">{'ตัดตามมาตรฐานปิ่นโต'}</span>
                      </div>
                    ) : (
                      <span>-</span>
                    )}
                  </div>
                </td>
                <td className="max-w-[200px] break-words whitespace-normal">
                  <div className="whitespace-normal line-clamp-5">
                    {/* Prioritize EPUB if it has values */}
                    {item.correctionEpubFiles.length > 0 && item.correctionEpubFiles[0]?.requestedSampleFileType === CorrectionEbookRequestedSampleFileType.Specific ? (
                      <div>
                        <div>
                          <span>{`(${item.correctionEpubFiles[0]?.lastSampleChapterNumber || 0})`}</span>
                        </div>
                        <div>
                          <span>{`${item.correctionEpubFiles[0]?.lastSampleChapterName || ''}`}</span>
                        </div>
                      </div>
                    ) : item.correctionEpubFiles.length > 0 && item.correctionEpubFiles[0]?.requestedSampleFileType === CorrectionEbookRequestedSampleFileType.Standard ? (
                      <div>
                        <span className="text-colorsBrandRed01Primary text-12 font-bold">ตัดตามมาตรฐานปิ่นโต</span>
                      </div>
                    ) : (
                      <span>-</span>
                    )}
                  </div>
                </td>
                <td>
                  {/* PDF UPLOAD */}
                  {item.correctionPdfFiles.length > 0 && item.correctionPdfFiles[0].requestedFileUrl ? (
                    <div className="flex items-center justify-center">
                      {item.correctionPdfFiles[0]?.status === CorrectionEbookFileStatus.WriterUploaded ? (
                        <div>
                          <span className="text-colorsBrandRed01Primary text-12 font-bold text-center block">Writer</span>
                          <span className="text-colorsBrandRed01Primary text-12 font-bold text-center block">Self-Submitted</span>
                        </div>
                      ) : !!uploadedStatus[item.id]?.pdf_saved || item.correctionPdfFiles[0]?.status === CorrectionEbookFileStatus.AdminUploaded ? (
                        <button disabled className="btn btn-outline btn-sm btn-warning btn-active">
                          Uploaded
                        </button>
                      ) : (
                        <button
                          onClick={() => handleOpenModal(EbookFileType.Pdf, item.id, item.ebookId!)}
                          className={classNames('btn btn-outline btn-sm !border !border-dashed', {
                            'btn-primary btn-active !border-solid': uploadedStatus[item.id]?.pdf,
                          })}
                          disabled={!!uploadedStatus[item.id]?.rejected}
                        >
                          {uploadedStatus[item.id]?.pdf ? 'Attached' : 'Upload'}
                        </button>
                      )}
                    </div>
                  ) : (
                    <div className="flex items-center justify-center">
                      <span>-</span>
                    </div>
                  )}
                </td>
                <td>
                  {/* EPUB UPLOAD */}
                  {item.correctionEpubFiles.length > 0 && item.correctionEpubFiles[0].requestedFileUrl ? (
                    <div className="flex items-center justify-center">
                      {item.correctionEpubFiles[0]?.status === CorrectionEbookFileStatus.WriterUploaded ? (
                        <div>
                          <span className="text-colorsBrandRed01Primary text-12 font-bold text-center block">Writer</span>
                          <span className="text-colorsBrandRed01Primary text-12 font-bold text-center block">Self-Submitted</span>
                        </div>
                      ) : !!uploadedStatus[item.id]?.epub_saved || item.correctionEpubFiles[0]?.status === CorrectionEbookFileStatus.AdminUploaded ? (
                        <button disabled className="btn btn-outline btn-sm btn-warning btn-active">
                          Uploaded
                        </button>
                      ) : (
                        <button
                          onClick={() => handleOpenModal(EbookFileType.Epub, item.id, item.ebookId!)}
                          className={classNames('btn btn-outline btn-sm !border !border-dashed', {
                            'btn-primary btn-active !border-solid': uploadedStatus[item.id]?.epub,
                          })}
                          disabled={!!uploadedStatus[item.id]?.rejected}
                        >
                          {uploadedStatus[item.id]?.epub ? 'Attached' : 'Upload'}
                        </button>
                      )}
                    </div>
                  ) : (
                    <div className="flex items-center justify-center">
                      <span>-</span>
                    </div>
                  )}
                </td>
                <td>
                  <div className="flex items-center justify-center space-x-4">
                    {/* Save Button */}
                    <button
                      className={classNames('flex items-center space-x-2', {
                        'text-gray-500':
                          uploadedStatus[item.id]?.rejected ||
                          !(uploadedStatus[item.id]?.pdf || uploadedStatus[item.id]?.epub) || // No files attached -> Gray
                          (uploadedStatus[item.id]?.pdf_saved && !uploadedStatus[item.id]?.epub && !uploadedStatus[item.id]?.epub_saved && item.requiresEpub) || // Only PDF saved, EPUB required but not attached
                          (uploadedStatus[item.id]?.epub_saved && !uploadedStatus[item.id]?.pdf && !uploadedStatus[item.id]?.pdf_saved && item.requiresPdf) || // Only EPUB saved, PDF required but not attached
                          (uploadedStatus[item.id]?.pdf_saved && uploadedStatus[item.id]?.epub_saved), // Both files saved -> Gray if both required
                        'text-blue-500':
                          (uploadedStatus[item.id]?.pdf && !uploadedStatus[item.id]?.pdf_saved) || // PDF attached but unsaved
                          (uploadedStatus[item.id]?.epub && !uploadedStatus[item.id]?.epub_saved), // EPUB attached but unsaved
                        'text-green-500':
                          (uploadedStatus[item.id]?.pdf_saved && !item.requiresEpub) || // PDF saved and EPUB not required
                          (uploadedStatus[item.id]?.epub_saved && !item.requiresPdf) || // EPUB saved and PDF not required
                          (uploadedStatus[item.id]?.pdf_saved && uploadedStatus[item.id]?.epub_saved), // Both files saved if both are required
                      })}
                      onClick={() => handleSubmit(item.id, item.ebookId!)}
                      disabled={
                        uploadedStatus[item.id]?.rejected ||
                        !(uploadedStatus[item.id]?.pdf || uploadedStatus[item.id]?.epub) || // Disable if no files attached
                        (uploadedStatus[item.id]?.pdf_saved && !uploadedStatus[item.id]?.epub && !uploadedStatus[item.id]?.epub_saved && item.requiresEpub) || // Disable if only PDF is saved and EPUB is not attached
                        (uploadedStatus[item.id]?.epub_saved && !uploadedStatus[item.id]?.pdf && !uploadedStatus[item.id]?.pdf_saved && item.requiresPdf) || // Disable if only EPUB is saved and PDF is not attached
                        (uploadedStatus[item.id]?.pdf_saved && uploadedStatus[item.id]?.epub_saved) || // Fully disable if both files are saved (if both are required)
                        (uploadedStatus[item.id]?.pdf_saved && !item.requiresEpub) || // Disable if only PDF is required and saved
                        (uploadedStatus[item.id]?.epub_saved && !item.requiresPdf) // Disable if only EPUB is required and saved
                      }
                    >
                      <FiUploadCloud size={20} />
                      <span>Save</span>
                    </button>

                    {/* Reject Button */}
                    <button
                      onClick={() => handleOpenRejectModal(item)}
                      className={classNames('flex items-center space-x-2', {
                        'text-red-500':
                          !uploadedStatus[item.id]?.rejected && // Show red if not rejected
                          (!uploadedStatus[item.id]?.pdf_saved || !uploadedStatus[item.id]?.epub_saved) && // Show red if not all required files are saved
                          ((item.requiresPdf && !uploadedStatus[item.id]?.pdf_saved) || (item.requiresEpub && !uploadedStatus[item.id]?.epub_saved)), // Required files unsaved
                        'text-green-500': uploadedStatus[item.id]?.rejected, // Show green if already rejected
                        'text-gray-500':
                          (uploadedStatus[item.id]?.pdf_saved && !item.requiresEpub) || // Show gray if only PDF is required and saved
                          (uploadedStatus[item.id]?.epub_saved && !item.requiresPdf) || // Show gray if only EPUB is required and saved
                          (uploadedStatus[item.id]?.pdf_saved && uploadedStatus[item.id]?.epub_saved), // Show gray if both files are saved
                      })}
                      disabled={
                        uploadedStatus[item.id]?.rejected || // Disable if already rejected
                        (uploadedStatus[item.id]?.pdf_saved && !item.requiresEpub) || // Disable if only PDF is required and saved
                        (uploadedStatus[item.id]?.epub_saved && !item.requiresPdf) || // Disable if only EPUB is required and saved
                        (uploadedStatus[item.id]?.pdf_saved && uploadedStatus[item.id]?.epub_saved) // Disable if both files are saved
                      }
                    >
                      {uploadedStatus[item.id]?.rejected ? <FiCheck size={20} /> : <FiX size={20} />}
                      <span>{uploadedStatus[item.id]?.rejected ? 'Rejected' : 'Reject'}</span>
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="flex items-center justify-between mt-12">
        <span className="text-systemGrays02LabelSecondary">แสดงผล {PAGE_LIMIT} รายการต่อหน้า</span>

        {(totalCount || 0) > 0 && <Pagination currentPage={currentPage} totalCount={totalCount || 0} pageSize={pageLimit || PAGE_LIMIT} onPageChange={handlePageChange} />}
      </div>

      {showUploadFilesModal && currentRowInfo && (
        <UploadFilesModal
          onClose={() => setUploadFilesModal(false)}
          onConFirm={(files) => {
            if (currentRowInfo.rowId && currentRowInfo.ebookId) {
              handleConfirmFileUpload(currentRowInfo as { rowId: string; ebookId: string }, files);
            } else {
              console.error('Missing rowId or ebookId');
            }
          }}
          fileType={currentFileType}
        />
      )}
      {showRejectModal && (
        <RejectModal
          rowId={rejectData!.id}
          ebookId={rejectData!.ebookId!} // Pass the selected ID to the modal
          requestedFileTypes={[
            ...(rejectData?.correctionPdfFiles && rejectData.correctionPdfFiles.length > 0 && rejectData.correctionPdfFiles[0]?.requestedFileUrl ? [EbookFileType.Pdf] : []),
            ...(rejectData?.correctionEpubFiles && rejectData.correctionEpubFiles.length > 0 && rejectData.correctionEpubFiles[0]?.requestedFileUrl ? [EbookFileType.Epub] : []),
          ]}
          onClose={() => setShowRejectModal(false)}
          onSubmit={handleRejectConfirm}
          title={`Reject Ebook with: ${rejectData?.title}`}
          placeholderRejectNote="Enter reason for rejection"
          confirmText="Reject"
          cancelText="Cancel"
          subTitle="Please provide a reason for rejection."
        />
      )}
    </div>
  );
}

export default UploadToolTablePresenter;
