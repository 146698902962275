/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const logout = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path
      fill="#8C8C8C"
      fillRule="evenodd"
      d="M9.858 9.996a3.424 3.424 0 014.829 0 3.391 3.391 0 010 4.81c-5.144 5.122-5.144 13.428 0 18.55 5.143 5.123 13.483 5.123 18.626 0 5.144-5.122 5.144-13.428 0-18.55a3.391 3.391 0 010-4.81 3.424 3.424 0 014.83 0c7.81 7.779 7.81 20.391 0 28.17-7.811 7.779-20.475 7.779-28.285 0-7.81-7.779-7.81-20.391 0-28.17zM24 2a4 4 0 014 4v12a4 4 0 11-8 0V6a4 4 0 014-4z"
    />
  </svg>
);

export default logout;
