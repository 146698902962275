/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const voteDown = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path
      fill="#8C8C8C"
      fillRule="evenodd"
      d="M17.002 30.07A1.015 1.015 0 0117 30V14a6 6 0 016-6h12.833a4 4 0 013.85 2.914l4.23 15A4 4 0 0140.063 31h-9.869c3.94 14.18-1.622 14.125-2.493 14.125-.88 0-2.652.047-2.652-.973 0-.721-.004-.5 0-1.068.07-9.525-8.038-8.997-8.049-12.893 0-.04 0-.08.002-.12zM5.348 31a2 2 0 01-1.987-2.231L5.676 8.884A1 1 0 016.67 8H12a1 1 0 011 1v21a1 1 0 01-1 1H5.348z"
    />
  </svg>
);

export default voteDown;
