import { FieldValues, UseFormRegister } from 'react-hook-form';

import Input from '../../../components/form/Input';
import { IConfigItemType } from '../validates/sectionBuilderSchema';

import RenderObjectLabel from './RenderObjectLabel';

export interface IPropsEbookBundleItem {
  type: IConfigItemType.EbookBundle;
  _id?: string;
}

interface IPropsEbookBundleSectionItem {
  index: number;
  itemData: IPropsEbookBundleItem;
  register: UseFormRegister<FieldValues>;
  onDeleteSectionItem?: (deleteIndex?: number) => void;
}

const EbookBundleSectionItem = (props: IPropsEbookBundleSectionItem) => {
  const { index, itemData, register } = props;

  return (
    <div className="flex justify-between space-x-16">
      <div className="w-full collapse">
        <input type="checkbox" className="peer" />
        <div className=" collapse-title bg-systemGrays08FillQuaternary text-systemGrays01LabelPrimary peer-checked:bg-systemGrays08FillQuaternary peer-checked:text-systemGrays01LabelPrimary flex justify-between after:content-['+'] peer-checked:after:content-['-'] ease-out duration-300">
          <span>
            <span>Bundle ID</span>
            <RenderObjectLabel objectLists={[{ key: 'bundle Id', value: itemData?._id, required: true }]} />
          </span>
        </div>
        <div className="collapse-content text-systemGrays01LabelPrimary peer-checked:bg-systemGrays08FillQuaternary peer-checked:text-systemGrays01LabelPrimary">
          <div className="space-y-8">
            <Input register={register} required label="bundle Id" name={`configItems.${index}._id`} helperText="Input bundle ID" placeholder="bundle ID" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EbookBundleSectionItem;
