import { ApolloProvider } from '@apollo/client';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

// Toastify
import 'react-toastify/dist/ReactToastify.css';

import App from './App';
import { client } from './core/apollo/apolloClient';

import './tailwind.generated.css';
import './styles/calendar/styles.scss';
import './styles/calendar/dragAndDrop.scss';
import './styles/datePicker/datepicker.scss';
import './styles/global.css';

// fictionlog
import './fictionlog/style/modal.scss';
import './fictionlog/style/slickCarousel.scss';

render(
  <BrowserRouter>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </BrowserRouter>,
  document.getElementById('root'),
);
