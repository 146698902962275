import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { IModalSectionItem } from '../../app/feeds/modals/SectionListModal';
import FeedDetailPresenter from '../../app/feeds/presenters/FeedDetailPresenter';
import FeedPreviewPresenter from '../../app/feeds/presenters/FeedPreviewPresenter';
import FeedSectionItemsPresenter from '../../app/feeds/presenters/FeedSectionItemsPresenter';
import FeedBuilderFormObj, { IFeedBuilderFields, FEED_BUILDER_FIELD_KEYS } from '../../app/feeds/validates/feedBuilderSchema';
import ErrorModal from '../../app/modals/ErrorModal';
import Breadcrumbs from '../../components/Breadcrumbs';
import { FeedPref } from '../../core/graphql/types';
import useMutatePintoCreateFeed from '../../hooks/useMutationPintoCreateFeed';
import useMutatePintoUpdateFeed from '../../hooks/useMutationPintoUpdateFeed';
import useQueryPintoFeed from '../../hooks/useQueryPintoFeed';
import useHookForm from '../../utils/form/useHookForm';

export const FEED_PREVIEW_SLUG = 'feed-preview';

const FeedBuilderController = () => {
  const history = useHistory();
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const searchSlug = searchParams.get('slug');
  const { onQueryPintoFeed, dataPintoFeed } = useQueryPintoFeed();
  const { onMutatePintoCreateFeed } = useMutatePintoCreateFeed();
  const { onMutatePintoUpdateFeed } = useMutatePintoUpdateFeed();
  const { methods, onValidate, getValues, setValue, watch } = useHookForm<IFeedBuilderFields>({
    objectShapeForm: FeedBuilderFormObj,
    mode: 'onChange',
  });

  useEffect(() => {
    if (searchSlug) {
      onQueryPintoFeed(searchSlug);
    }
  }, [onQueryPintoFeed, searchSlug]);

  const [slug, ogTitle, ogDescription, ogImage, feedItems] = watch([
    FEED_BUILDER_FIELD_KEYS.SLUG,
    FEED_BUILDER_FIELD_KEYS.OG_TITLE,
    FEED_BUILDER_FIELD_KEYS.OG_DESCRIPTION,
    FEED_BUILDER_FIELD_KEYS.OG_IMAGE,
    FEED_BUILDER_FIELD_KEYS.FEED_ITEMS,
  ]);
  useEffect(() => {
    if (dataPintoFeed) {
      const sectionToSet = (dataPintoFeed.pintoFeed?.sections || []).map((section) => ({ id: section?._id || '', note: section?.note || '' }));
      setValue(FEED_BUILDER_FIELD_KEYS.SLUG, dataPintoFeed.pintoFeed?.slug as never);
      setValue(FEED_BUILDER_FIELD_KEYS.OG_TITLE, dataPintoFeed.pintoFeed?.meta.ogTitle as never);
      setValue(FEED_BUILDER_FIELD_KEYS.OG_DESCRIPTION, dataPintoFeed.pintoFeed?.meta.ogDescription as never);
      setValue(FEED_BUILDER_FIELD_KEYS.OG_IMAGE, dataPintoFeed.pintoFeed?.meta.ogImage as never);
      setValue(FEED_BUILDER_FIELD_KEYS.FEED_ITEMS, sectionToSet as never);
    }
  }, [dataPintoFeed, setValue]);
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);

  const onSavePreview = useCallback(async () => {
    if (!getValues(FEED_BUILDER_FIELD_KEYS.PREF)) {
      setValue(FEED_BUILDER_FIELD_KEYS.PREF, FeedPref.Default as never);
    }
    const isValid = await onValidate();
    if (isValid) {
      // call api
      //Edit
      const input = {
        slug: FEED_PREVIEW_SLUG,
        pref: FeedPref.Default,
        meta: {
          ogImage,
          ogTitle,
          ogDescription,
        },
        sections: (feedItems as IModalSectionItem[]).map((item: IModalSectionItem) => item.id),
      };
      await onMutatePintoUpdateFeed(FEED_PREVIEW_SLUG, input);
    } else {
      setShowErrorModal(true);
    }
  }, [feedItems, getValues, ogDescription, ogImage, ogTitle, onMutatePintoUpdateFeed, onValidate, setValue]);

  const onSave = useCallback(async () => {
    if (!getValues(FEED_BUILDER_FIELD_KEYS.PREF)) {
      setValue(FEED_BUILDER_FIELD_KEYS.PREF, FeedPref.Default as never);
    }
    const isValid = await onValidate();
    if (isValid) {
      // call api
      if (searchSlug) {
        //Edit
        const input = {
          slug,
          pref: FeedPref.Default,
          meta: {
            ogImage,
            ogTitle,
            ogDescription,
          },
          sections: (feedItems as IModalSectionItem[]).map((item: IModalSectionItem) => item.id),
        };
        const isSuccess = await onMutatePintoUpdateFeed(searchSlug, input);
        if (isSuccess) {
          history.push('/feedAndSection/feeds');
          return;
        }
      } else {
        //Create
        const input = {
          slug,
          pref: FeedPref.Default,
          meta: {
            ogImage,
            ogTitle,
            ogDescription,
          },
          sections: (feedItems as IModalSectionItem[]).map((item: IModalSectionItem) => item.id),
        };
        const isSuccess = await onMutatePintoCreateFeed(input);
        if (isSuccess) {
          history.push('/feedAndSection/feeds');
          return;
        }
      }
    } else {
      setShowErrorModal(true);
    }
  }, [feedItems, getValues, history, ogDescription, ogImage, ogTitle, onMutatePintoCreateFeed, onMutatePintoUpdateFeed, onValidate, searchSlug, setValue, slug]);

  return (
    <div>
      {showErrorModal && <ErrorModal onConFirm={() => setShowErrorModal(false)} confirmText="ตกลง" errorMessages={['กรุณาแก้ไขข้อมูลให้ถูกต้อง']} />}

      <div className="sticky top-0 z-50 px-5 bg-componentsBgGrouped02">
        {/* section: breadcrumbs */}
        <Breadcrumbs items={[{ name: 'Feeds', url: '/feedAndSection/feeds' }, { name: 'Feed Builder' }]} />

        {/* section: title */}
        <div className="flex justify-between pt-5">
          <h1 className="font-dbh text-[28px]">Feed Builder</h1>

          <div className="space-x-12">
            <Link to="/feedAndSection/feeds">
              <button className="btn btn-outline">CANCEL</button>
            </Link>
            <button className="btn btn-error" onClick={onSave}>
              SAVE
            </button>
          </div>
        </div>

        {/* section: main */}
        <div className="mb-0 divider opacity-80"></div>
      </div>

      <div className="flex flex-row w-full space-x-12 ">
        <div className="flex-[0.8] space-y-2">
          <FormProvider {...methods}>
            <FeedDetailPresenter />
            <FeedSectionItemsPresenter />
          </FormProvider>
        </div>
        <div className="flex-[1.2]">
          <FeedPreviewPresenter onSavePreview={onSavePreview} />
        </div>
      </div>
    </div>
  );
};

export default FeedBuilderController;
