import dayjs from 'dayjs';
import { findIndex } from 'lodash';
import { useCallback, useState, useEffect } from 'react';

import { ContentType, FeedPref, ProductType, SectionV3WithoutItemsFragment, TemplateByQueryQuery } from '../../../core/graphql/types';

export interface IFormSection {
  _id: string;
  note: string;
  title: string;
  isActivated: boolean;
  isNCContent: boolean;
  contentType: ContentType;
  productType: ProductType;
  pref: FeedPref;
  camelCaseLayoutId: string;
  schedules?: IScheduleOption[];
}

export interface IScheduleOption {
  id?: string;
  start?: Date | null;
  end?: Date | null;
}

const getFormSections = (templateByQuery: IPropsUseFormSections['templateByQuery']): IFormSection[] =>
  templateByQuery?.sections.map((section) => ({
    _id: section._id,
    note: section.note ?? '',
    title: section.title ?? '',
    isNCContent: section.isNCContent ?? false,
    contentType: section.contentType!,
    productType: section.productType!,
    pref: section.pref!,
    camelCaseLayoutId: section.camelCaseLayoutId!,
    isActivated: section.isActivated ?? false,
    schedules: section.schedules?.map((schedule) => ({
      id: schedule?._id ?? undefined,
      start: dayjs(schedule?.startedAt).toDate(),
      end: dayjs(schedule?.endedAt).toDate(),
    })),
  })) ?? [];

export interface IPropsUseFormSections {
  templateByQuery: TemplateByQueryQuery['templateByQuery'];
}

const useFormSections = (props: IPropsUseFormSections) => {
  const [loadedSections, setLoadedSections] = useState<boolean>(false);
  const [formSections, setFormSections] = useState<IFormSection[]>(getFormSections(props.templateByQuery));

  useEffect(() => {
    if (props.templateByQuery && !loadedSections) {
      setLoadedSections(true);
      setFormSections(getFormSections(props.templateByQuery));
    }
  }, [props.templateByQuery, loadedSections]);

  const onCreateSection = useCallback(
    (sectionNode: SectionV3WithoutItemsFragment) => {
      const newSection = {
        _id: sectionNode._id!,
        note: sectionNode.note!,
        title: sectionNode.title!,
        isNCContent: !!sectionNode.isNCContent,
        contentType: sectionNode.contentType!,
        productType: sectionNode.productType!,
        pref: sectionNode.pref!,
        camelCaseLayoutId: sectionNode.camelCaseLayoutId ?? '',
        isActivated: false,
        schedules: undefined,
      };
      setFormSections([...formSections, newSection]);
    },
    [formSections],
  );

  const onEditSection = useCallback(
    (sectionId: string, object: Partial<IFormSection>) => {
      const sectionIndex = findIndex(formSections, (section) => section._id === sectionId);
      // overwrite old array of object
      formSections[sectionIndex] = {
        ...formSections[sectionIndex],
        ...object,
      };
      setFormSections([...formSections]);
    },
    [formSections, setFormSections],
  );

  const onDeleteSection = useCallback(
    (sectionId: string) => {
      const isConfirmAction = confirm('Delete this section?');
      if (isConfirmAction) {
        const sectionIndex = findIndex(formSections, (section) => section._id === sectionId);
        formSections?.splice(sectionIndex, 1);
        setFormSections([...formSections]);
      }
    },
    [formSections],
  );

  const onUpdateScheduleInSection = useCallback(
    (sectionId: string, scheduleIndex: number, editedSchedule: IScheduleOption) => {
      const sectionIndex = findIndex(formSections, (section) => section._id === sectionId);
      const currentSchedule = formSections[sectionIndex].schedules?.[scheduleIndex];
      if (currentSchedule) {
        formSections[sectionIndex].schedules![scheduleIndex] = { ...currentSchedule, ...editedSchedule };
      }
      setFormSections([...formSections]);
    },
    [formSections],
  );

  const onCreateScheduleInSection = useCallback(
    (sectionId: string, editedSchedule: IScheduleOption) => {
      const sectionIndex = findIndex(formSections, (section) => section._id === sectionId);
      formSections[sectionIndex].schedules = [...(formSections[sectionIndex]?.schedules ?? []), editedSchedule];
      setFormSections([...formSections]);
    },
    [formSections],
  );
  const onDeleteScheduleInSection = useCallback(
    (sectionId: string, scheduleIndex: number) => {
      const sectionIndex = findIndex(formSections, (section) => section._id === sectionId);
      formSections[sectionIndex].schedules?.splice(scheduleIndex, 1);
      setFormSections([...formSections]);
    },
    [formSections],
  );
  return {
    formSections,
    onEditSection,
    onCreateSection,
    onDeleteSection,
    onUpdateScheduleInSection,
    onCreateScheduleInSection,
    onDeleteScheduleInSection,
  };
};

export default useFormSections;
