/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const caretDown = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path
      fill="#8C8C8C"
      fillRule="evenodd"
      d="M39.333 13h.127A4.54 4.54 0 0144 17.54L28.667 32.46 24 37l-4.666-4.54L4 17.54A4.54 4.54 0 018.54 13h.127L24 27.918 39.333 13z"
    />
  </svg>
);

export default caretDown;
