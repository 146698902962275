/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const arrowLeft = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path
      fill="#8C8C8C"
      fillRule="evenodd"
      d="M20.027 6a3.973 3.973 0 013.972 3.973v.227L13.78 21.005H43a2 2 0 012 2v1.99a2 2 0 01-2 2H13.778L24 37.8v.227A3.973 3.973 0 0120.027 42L6.973 28.2 3 24l3.973-4.2L20.027 6z"
    />
  </svg>
);

export default arrowLeft;
