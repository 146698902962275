import { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';

import Pagination from '../../../components/Pagination';
import { FeedPref } from '../../../core/graphql/types';
import ConfirmModal from '../../modals/ConfirmModal';

interface OGItem {
  image: string;
  title: string;
  description: string;
}

export interface FeedItem {
  slug: string;
  lastUpdated: string;
  pref: FeedPref;
  og: OGItem;
}

export interface IPropsFeedListTable {
  feedList?: FeedItem[];
  isLoadingFeedList?: boolean;
  currentPage: number;
  totalFeeds: number;
  onChangePage: (num: number) => void;
  onDeleteFeed: (slug: string, pref: FeedPref) => void;
}

const PAGE_SIZE = 10;

const FeedListTable = (props: IPropsFeedListTable) => {
  const { feedList, currentPage, totalFeeds, onChangePage, onDeleteFeed } = props;
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<{ slug: string; pref: FeedPref } | undefined>();

  const handleDeleteFeed = useCallback(() => {
    setShowConfirmModal(false);
    if (typeof selectedItem !== 'undefined') {
      onDeleteFeed(selectedItem.slug, selectedItem.pref);
    }
  }, [selectedItem, onDeleteFeed]);

  return (
    <>
      {showConfirmModal && (
        <ConfirmModal
          onClose={() => setShowConfirmModal(false)}
          onConFirm={handleDeleteFeed}
          title="Remove Section"
          subTitle="Confirm to Remove this section"
          messages={[selectedItem?.slug as string]}
        />
      )}

      <table className="table w-full table-compact">
        <thead>
          <tr>
            <th>SLUG</th>
            <th>Last Updated</th>
            <th>ogImage</th>
            <th>Meta</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody className="font-tt">
          {feedList?.map((feed) => {
            return (
              <tr className="hover" key={feed.slug}>
                <th>
                  <Link to={`feeds/edit?slug=${feed.slug}`} className="mr-8 link text-colorsBrandRed01Primary">
                    {feed.slug}
                  </Link>
                </th>
                <td>{feed.lastUpdated}</td>
                <td>
                  <div className="w-[150px] rounded">
                    <img src={feed.og.image} alt={feed.og.title} />
                  </div>
                </td>
                <td>
                  <div className="flex flex-col">
                    <p className="whitespace-normal">
                      <span className="font-bold">ogTitle:</span> {feed.og.title}
                    </p>
                    <p className="whitespace-normal">
                      <span className="font-bold">ogDescription:</span> {feed.og.description}
                    </p>
                  </div>
                </td>
                <td>
                  <Link to={`feeds/edit?slug=${feed.slug}`} className="btn btn-active btn-link text-colorsBrandRed01Primary">
                    Edit
                  </Link>
                  <button
                    className="btn btn-active btn-link text-colorsBrandRed01Primary"
                    onClick={() => {
                      setSelectedItem({ slug: feed.slug, pref: feed.pref });
                      setShowConfirmModal(true);
                    }}
                  >
                    Remove
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="flex items-center justify-between mt-12">
        <span className="text-systemGrays02LabelSecondary">แสดงผล 10 รายการต่อหน้า</span>
        {!!totalFeeds && <Pagination currentPage={currentPage} totalCount={totalFeeds} pageSize={PAGE_SIZE} onPageChange={(page) => onChangePage(page)} />}
      </div>
    </>
  );
};

export default FeedListTable;
