/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const publish = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path
      fill="#8C8C8C"
      fillRule="evenodd"
      d="M28.988 32H18a1 1 0 01-1-1V17a1 1 0 011-1h10.988V9.015a1 1 0 011.764-.647L44 24 30.752 39.632a1.001 1.001 0 01-1.764-.647V32zM10 37h5a1 1 0 011 1v4a1 1 0 01-1 1H4.99a.998.998 0 01-.99-.997V5.997c0-.55.451-.997.99-.997H15a1 1 0 011 1v4a1 1 0 01-1 1h-5v26z"
    />
  </svg>
);

export default publish;
