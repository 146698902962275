import classNames from 'classnames';
import { ChangeEvent, useCallback, useEffect } from 'react';
import { FormProvider } from 'react-hook-form';

import SummaryPricePresenter from '../../../components/SummaryPrice';
import { AppPriceTier } from '../../../core/graphql/types';
import { IPropsOnConfirmSetPriceModal, PRICE_OPTIONS, PRICE_VALUE } from '../../../pages/ebooks/edit';
import { addDate } from '../../../utils/dayjs';
import useHookForm from '../../../utils/form/useHookForm';

import useStatePriceTier from './hook/useStatePriceTier';
import DiscountPricePresenter from './presenters/DiscountPricePresenter';
import PricePresenter from './presenters/PricePresenter';
import SetPriceModalFormObj, { ISetPriceModalFields, SET_PRICE_MODAL_FIELD_KEYS } from './validates/setPriceModalBuilderSchema';

export enum DISCOUNT_VALUE {
  NO_DISCOUNT = 'NoDiscount',
  SPECIFY_PRICE = 'SpecifyPrice',
  PERCENT_DISCOUNT = 'PercentDiscount',
}

export const HAS_DISCOUNT_END_DATE_OPTIONS = [
  { label: 'ไม่กำหนดวันหมดอายุ', value: false },
  { label: 'กำหนดวันลดราคาเอง', value: true },
];

export const DISCOUNT_OPTIONS = [
  { label: 'ไม่ลดราคา', value: DISCOUNT_VALUE.NO_DISCOUNT },
  { label: 'กำหนดราคาส่วนลด', value: DISCOUNT_VALUE.SPECIFY_PRICE },
  { label: 'กำหนดเปอร์เซ็นต์ส่วนลด', value: DISCOUNT_VALUE.PERCENT_DISCOUNT },
];

export interface IPropsSetPriceModal {
  onClose?: () => void;
  onConfirm?: (props: IPropsOnConfirmSetPriceModal) => void;
  initPriceOption?: PRICE_VALUE;
  initEbookPrice?: number;
  initHaveDiscount?: boolean;
  initDiscountType?: DISCOUNT_VALUE;
  initDiscountPrice?: number;
  initDiscountRatio?: number;
  initIsHasDiscountEndDate?: boolean;
  initDiscountStartDate?: string | Date;
  initDiscountEndDate?: string | Date;
  initGooglePriceTierId?: string;
  initApplePriceTierId?: string;
  initDiscountGooglePriceTierId?: string;
  initDiscountApplePriceTierId?: string;
  priceTierOptions?: {
    google?: AppPriceTier[];
    apple?: AppPriceTier[];
  };
}

const SetPriceModal = (props: IPropsSetPriceModal) => {
  const {
    onClose,
    onConfirm,
    initPriceOption = PRICE_VALUE.MANUAL,
    initEbookPrice = undefined,
    initHaveDiscount = false,
    initDiscountType,
    initDiscountPrice,
    initDiscountRatio,
    initDiscountStartDate,
    initDiscountEndDate,
    initGooglePriceTierId,
    initApplePriceTierId,
    initDiscountGooglePriceTierId,
    initDiscountApplePriceTierId,
    priceTierOptions,
    initIsHasDiscountEndDate = true,
  } = props;

  const {
    methods,
    onValidate,
    formState: { errors },
  } = useHookForm<ISetPriceModalFields>({
    objectShapeForm: SetPriceModalFormObj,
    mode: 'onChange',
    //resume state
    defaultValues: {},
  });
  const { setValue, watch } = methods;

  const [isFree] = watch([SET_PRICE_MODAL_FIELD_KEYS.IS_FREE]);
  const [webPriceThb, googlePriceTierId, applePriceTierId] = watch([
    SET_PRICE_MODAL_FIELD_KEYS.WEB_PRICE_THB,
    SET_PRICE_MODAL_FIELD_KEYS.GOOGLE_PRICE_TIER_ID,
    SET_PRICE_MODAL_FIELD_KEYS.APPLE_PRICE_TIER_ID,
  ]);
  const [
    haveDiscount,
    discountType,
    discountPrice,
    discountRatio,
    discountGooglePriceTierId,
    discountApplePriceTierId,
    discountStartDate,
    discountEndDate,
    isHasDiscountEndDate = true,
  ] = watch([
    SET_PRICE_MODAL_FIELD_KEYS.HAVE_DISCOUNT,
    SET_PRICE_MODAL_FIELD_KEYS.DISCOUNT_TYPE,
    SET_PRICE_MODAL_FIELD_KEYS.DISCOUNT_PRICE,
    SET_PRICE_MODAL_FIELD_KEYS.DISCOUNT_RATIO,
    SET_PRICE_MODAL_FIELD_KEYS.DISCOUNT_GOOGLE_PRICE_TIER_ID,
    SET_PRICE_MODAL_FIELD_KEYS.DISCOUNT_APPLE_PRICE_TIER_ID,
    SET_PRICE_MODAL_FIELD_KEYS.DISCOUNT_START_DATE,
    SET_PRICE_MODAL_FIELD_KEYS.DISCOUNT_END_DATE,
    SET_PRICE_MODAL_FIELD_KEYS.IS_HAS_DISCOUNT_END_DATE,
  ]);
  const {
    googlePriceTierList,
    discountGooglePriceTierList,
    applePriceTierList,
    discountApplePriceTierList,
    currentGooglePriceTier,
    currentApplePriceTier,
    currentDiscountGooglePriceTier,
    currentDiscountApplePriceTier,
    // utils
    isLowerAppleTier,
    isLowerGoogleTier,
  } = useStatePriceTier({
    priceTiers: priceTierOptions,
    googlePriceTierId,
    applePriceTierId,
    discountGooglePriceTierId,
    discountApplePriceTierId,
  });

  // Check tier of google price
  useEffect(() => {
    if (isLowerGoogleTier(googlePriceTierId || '')) {
      setValue(SET_PRICE_MODAL_FIELD_KEYS.DISCOUNT_GOOGLE_PRICE_TIER_ID as ISetPriceModalFields, undefined);
    }
  }, [googlePriceTierId, isLowerGoogleTier, setValue]);

  // Check tier of apple price
  useEffect(() => {
    if (isLowerAppleTier(applePriceTierId || '')) {
      setValue(SET_PRICE_MODAL_FIELD_KEYS.DISCOUNT_APPLE_PRICE_TIER_ID as ISetPriceModalFields, undefined);
    }
  }, [applePriceTierId, isLowerAppleTier, setValue]);

  useEffect(() => {
    if (initPriceOption) {
      setValue(SET_PRICE_MODAL_FIELD_KEYS.IS_FREE as ISetPriceModalFields, initPriceOption === PRICE_VALUE.FREE);
    }
    if (initEbookPrice) {
      setValue(SET_PRICE_MODAL_FIELD_KEYS.WEB_PRICE_THB as ISetPriceModalFields, initEbookPrice);
    }
    if (initHaveDiscount) {
      setValue(SET_PRICE_MODAL_FIELD_KEYS.HAVE_DISCOUNT as ISetPriceModalFields, initHaveDiscount);
    }
    if (initDiscountPrice) {
      setValue(SET_PRICE_MODAL_FIELD_KEYS.DISCOUNT_PRICE as ISetPriceModalFields, initDiscountPrice);
    }
    if (initDiscountRatio) {
      setValue(SET_PRICE_MODAL_FIELD_KEYS.DISCOUNT_RATIO as ISetPriceModalFields, initDiscountRatio);
    }
    setValue(SET_PRICE_MODAL_FIELD_KEYS.GOOGLE_PRICE_TIER_ID as ISetPriceModalFields, initGooglePriceTierId);
    setValue(SET_PRICE_MODAL_FIELD_KEYS.APPLE_PRICE_TIER_ID as ISetPriceModalFields, initApplePriceTierId);
    setValue(SET_PRICE_MODAL_FIELD_KEYS.DISCOUNT_GOOGLE_PRICE_TIER_ID as ISetPriceModalFields, initDiscountGooglePriceTierId);
    setValue(SET_PRICE_MODAL_FIELD_KEYS.DISCOUNT_APPLE_PRICE_TIER_ID as ISetPriceModalFields, initDiscountApplePriceTierId);
    setValue(
      SET_PRICE_MODAL_FIELD_KEYS.DISCOUNT_START_DATE as ISetPriceModalFields,
      initDiscountStartDate ? new Date(initDiscountStartDate) : addDate(new Date(), 1, 'day').toDate(),
    );
    setValue(SET_PRICE_MODAL_FIELD_KEYS.IS_HAS_DISCOUNT_END_DATE as ISetPriceModalFields, initIsHasDiscountEndDate);
    setValue(SET_PRICE_MODAL_FIELD_KEYS.DISCOUNT_END_DATE as ISetPriceModalFields, initDiscountEndDate ? new Date(initDiscountEndDate) : addDate(new Date(), 3, 'day').toDate());
    setValue(SET_PRICE_MODAL_FIELD_KEYS.DISCOUNT_TYPE as ISetPriceModalFields, initDiscountType || DISCOUNT_VALUE.NO_DISCOUNT);
  }, [
    initApplePriceTierId,
    initDiscountApplePriceTierId,
    initDiscountEndDate,
    initDiscountGooglePriceTierId,
    initDiscountPrice,
    initDiscountRatio,
    initDiscountStartDate,
    initDiscountType,
    initEbookPrice,
    initGooglePriceTierId,
    initHaveDiscount,
    initIsHasDiscountEndDate,
    initPriceOption,
    setValue,
  ]);

  const onChangeEbookPrice = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const inputValue = event.target.value.trim().replace(/[^0-9]/gi, '');
      const value = Number(inputValue);
      setValue(SET_PRICE_MODAL_FIELD_KEYS.WEB_PRICE_THB as ISetPriceModalFields, value || undefined, { shouldValidate: true });
    },
    [setValue],
  );

  const onSetHaveDiscount = useCallback(
    (value: boolean) => {
      setValue(SET_PRICE_MODAL_FIELD_KEYS.HAVE_DISCOUNT as ISetPriceModalFields, value);
      if (!value) {
        setValue(SET_PRICE_MODAL_FIELD_KEYS.DISCOUNT_START_DATE as ISetPriceModalFields, addDate(new Date(), 1, 'day').toDate());
        setValue(SET_PRICE_MODAL_FIELD_KEYS.IS_HAS_DISCOUNT_END_DATE as ISetPriceModalFields, true);
        setValue(SET_PRICE_MODAL_FIELD_KEYS.DISCOUNT_END_DATE as ISetPriceModalFields, addDate(new Date(), 3, 'day').toDate());
        setValue(SET_PRICE_MODAL_FIELD_KEYS.DISCOUNT_TYPE as ISetPriceModalFields, DISCOUNT_VALUE.NO_DISCOUNT);
        setValue(SET_PRICE_MODAL_FIELD_KEYS.DISCOUNT_RATIO as ISetPriceModalFields, undefined);
        setValue(SET_PRICE_MODAL_FIELD_KEYS.DISCOUNT_PRICE as ISetPriceModalFields, undefined);
        setValue(SET_PRICE_MODAL_FIELD_KEYS.DISCOUNT_GOOGLE_PRICE_TIER_ID as ISetPriceModalFields, undefined);
        setValue(SET_PRICE_MODAL_FIELD_KEYS.DISCOUNT_APPLE_PRICE_TIER_ID as ISetPriceModalFields, undefined);
      }
    },
    [setValue],
  );
  const onSelectOptions = useCallback(
    (value: PRICE_VALUE) => {
      setValue(SET_PRICE_MODAL_FIELD_KEYS.IS_FREE as ISetPriceModalFields, value === PRICE_VALUE.FREE);
    },
    [setValue],
  );

  const onSubmitForm = useCallback(async () => {
    const isValid = await onValidate();
    if (!isValid) return;
    onConfirm?.({
      ebookPrice: isFree ? null : webPriceThb!,
      googlePriceTierId,
      applePriceTierId,
      isFree,
      haveDiscount,
      discountType,
      discountPrice: discountPrice ? Number(discountPrice) : undefined,
      discountRatio: discountRatio ? Number(discountRatio) : undefined,
      discountStartDate,
      discountEndDate: isHasDiscountEndDate ? discountEndDate : undefined,
      discountGooglePriceTierId,
      discountApplePriceTierId,
      isHasDiscountEndDate,
    });
  }, [
    onValidate,
    isFree,
    webPriceThb,
    googlePriceTierId,
    applePriceTierId,
    haveDiscount,
    discountType,
    discountPrice,
    discountRatio,
    discountStartDate,
    isHasDiscountEndDate,
    discountEndDate,
    discountGooglePriceTierId,
    discountApplePriceTierId,
    onConfirm,
  ]);

  const onRenderPriceSetting = useCallback(
    (isFree: boolean) => {
      if (isFree) {
        return <div className=""></div>;
      }
      return (
        <div className="">
          <PricePresenter
            onChangeEbookPrice={onChangeEbookPrice}
            errorEbookPrice={errors?.[SET_PRICE_MODAL_FIELD_KEYS.WEB_PRICE_THB]?.message}
            webPrice={webPriceThb}
            googlePriceTierOptions={googlePriceTierList}
            applePriceTierOptions={applePriceTierList}
          />
          {/* Discount Section */}
          <section className="pt-16 mt-12 border-t-2">
            <div className="flex items-center space-x-8 cursor-pointer select-none" onClick={() => onSetHaveDiscount(!haveDiscount)}>
              <input type="checkbox" className="checkbox checkbox-sm" checked={haveDiscount} />
              <div>ส่วนลด</div>
            </div>
            {haveDiscount && <DiscountPricePresenter discountGooglePriceTierOptions={discountGooglePriceTierList} discountApplePriceTierOptions={discountApplePriceTierList} />}
          </section>
        </div>
      );
    },
    [applePriceTierList, discountApplePriceTierList, discountGooglePriceTierList, errors, googlePriceTierList, haveDiscount, onChangeEbookPrice, onSetHaveDiscount, webPriceThb],
  );

  return (
    <>
      <div className="modal modal-open">
        <div className="relative modal-box p-0 px-16 bg-componentsBgBase">
          <h3 className="text-lg font-bold font-dbh text-systemGrays01LabelPrimary sticky top-0 pt-16 bg-componentsBgBase">ตั้งราคาอีบุ๊ก</h3>
          <FormProvider {...methods}>
            <section className="py-16 space-y-16 overflow-y-auto">
              <div className="space-x-16">
                {PRICE_OPTIONS.map((option) => {
                  const selected = option.value === PRICE_VALUE.FREE ? isFree : !isFree;
                  return (
                    <button
                      key={option.label}
                      className={classNames('btn btn-outline border', {
                        'border-colorsBrandRed01Primary text-colorsBrandRed01Primary': selected,
                      })}
                      onClick={() => onSelectOptions(option.value)}
                    >
                      {option.label}
                    </button>
                  );
                })}
              </div>
              {onRenderPriceSetting(isFree)}
              <SummaryPricePresenter
                className="pt-16"
                isFree={isFree}
                webDiscountPercent={discountRatio}
                webDiscountPrice={discountPrice}
                webPrice={webPriceThb}
                haveDiscount={haveDiscount}
                googlePrice={currentGooglePriceTier}
                discountGooglePrice={currentDiscountGooglePriceTier}
                applePrice={currentApplePriceTier}
                discountApplePrice={currentDiscountApplePriceTier}
                discountStartDate={discountStartDate}
                discountEndDate={discountEndDate}
                isHasDiscountEndDate={isHasDiscountEndDate}
              />
            </section>
          </FormProvider>
          <div className="flex justify-end w-full pt-24 pb-16 space-x-6 sticky bottom-0 bg-componentsBgBase">
            <button className="btn btn-outline btn-error" onClick={onClose}>
              ยกเลิก
            </button>
            <button className="btn btn-error" onClick={onSubmitForm}>
              ยืนยัน
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SetPriceModal;
