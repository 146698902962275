import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import SelectPintoCategory from '../../../components/modal/SelectPintoCategory';
import { ContentType, PintoCategory } from '../../../core/graphql/types';
import { EBOOK_BUILDER_FIELD_KEYS, IEbookBuilderFields } from '../validates/ebookBuilderSchema';

import WritingTypeSelector from './WritingTypeSelector';
isEmpty;

interface IPropsSelectPintoCategory {
  name: string;
  currentCategoryName?: string;
  initCategories?: PintoCategory[];
  errorsMessage?: string;
  className?: string;
}

const CategorySelector = (props: IPropsSelectPintoCategory) => {
  const { name, errorsMessage, currentCategoryName, className, initCategories = [] } = props;
  const { setValue, watch } = useFormContext();

  const [isCartoonType = false] = watch([EBOOK_BUILDER_FIELD_KEYS.IS_CARTOON_TYPE]);
  const [categoryName, setCategoryName] = useState<string | undefined>(currentCategoryName || '');
  const [categories, setCategories] = useState<PintoCategory[]>(initCategories);

  const onSetPintoCategory = useCallback(
    (categories: PintoCategory[]) => {
      setValue(name, categories?.[(categories?.length || 0) - 1]._id);
      setCategoryName(categories?.[(categories?.length || 0) - 1].name);
      setValue(EBOOK_BUILDER_FIELD_KEYS.IS_CARTOON_TYPE as IEbookBuilderFields, categories?.[(categories?.length || 0) - 1].contentType === ContentType.Comic);
      setCategories(categories);
    },
    [name, setValue],
  );

  const categoriesLabel = useMemo(() => {
    return categories.map((category) => category.name).join(' > ');
  }, [categories]);

  return (
    <div data-invalid={!categoryName && !isEmpty(errorsMessage)} className={className}>
      <label className="label">
        <span className={classNames('label-text text-systemGrays02LabelSecondary', { 'text-colorsBrandRed01Primary': !categoryName && !isEmpty(errorsMessage) })}>
          หมวดหมู่ <span className="text-colorsBrandRed01Primary">*</span>
        </span>
      </label>
      <div className="space-x-16">
        <div
          className={classNames('py-16 badge badge-outline min-w-[150px]', {
            'badge-error': !categoryName && !isEmpty(errorsMessage),
          })}
        >{`${categoriesLabel || '-'}`}</div>
        <SelectPintoCategory
          btnClassName={categoryName ? '!btn-outline' : undefined}
          openModalLabel={categoryName ? 'เปลี่ยนหมวดหมู่' : 'เลือกหมวดหมู่'}
          onConfirm={onSetPintoCategory}
          name={name}
        />
      </div>

      {!categoryName && !isEmpty(errorsMessage) && (
        <label className="pt-4 label">
          <span className={classNames('label-text-alt text-colorsBrandRed01Primary font-tt')}>{errorsMessage}</span>
        </label>
      )}

      {isCartoonType && <WritingTypeSelector />}
    </div>
  );
};

export default CategorySelector;
