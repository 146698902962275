import React from 'react';
import { useInView } from 'react-intersection-observer';

import HorizontalScrollable from './HorizontalScrollable';

export interface IPropContentElement {
  children: JSX.Element;
  alwayDisplay: boolean;
  className?: string;
}

export interface IPropsCoverSectionScrollable {
  children: JSX.Element[];
  alwayDisplayCount?: number;
}

const ContentElement = ({ className, children, alwayDisplay }: IPropContentElement) => {
  const [ref, inView] = useInView({
    triggerOnce: false,
    initialInView: alwayDisplay,
  });

  // in case out of view
  // display with width empty element
  return (
    <div className={className} ref={ref}>
      {!!(inView || alwayDisplay) && children}
    </div>
  );
};

const CoverSectionScrollable = (props: IPropsCoverSectionScrollable): JSX.Element => {
  return (
    <HorizontalScrollable>
      {props.children.map(
        (child, index): JSX.Element => (
          <ContentElement className={child.props.className} key={child.key} alwayDisplay={props.alwayDisplayCount ? index < props.alwayDisplayCount : false}>
            {/* move className item to recycle wrapper */}
            {React.cloneElement(child, { className: undefined })}
          </ContentElement>
        ),
      )}
    </HorizontalScrollable>
  );
};

CoverSectionScrollable.defaultProps = {
  alwayDisplayCount: 6,
};

export default CoverSectionScrollable;
