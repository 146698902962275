import { useCallback } from 'react';

import { usePintoCreatePresignedUploadEbookFileMutation } from '../core/graphql/operation-hooks';
import { EbookFileType } from '../core/graphql/types';

const useMutationPintoCreatePresignedUploadEbookFile = () => {
  const [mutationPintoCreatePresignedUploadEbookFile] = usePintoCreatePresignedUploadEbookFileMutation();
  const pintoCreatePresignedUploadEbookFile = useCallback(
    async (fileType: EbookFileType) => {
      const res = await mutationPintoCreatePresignedUploadEbookFile({ variables: { input: { fileType } } });

      return res.data?.pintoCreatePresignedUploadEbookFile;
    },
    [mutationPintoCreatePresignedUploadEbookFile],
  );

  return {
    pintoCreatePresignedUploadEbookFile,
  };
};
export default useMutationPintoCreatePresignedUploadEbookFile;
