import { EditorState } from 'prosemirror-state';

import { IContentRawState, IInlineStyleRanges, IUnstyled } from '../@type/contentRawState';
import { INodeBlockquote, INodeFigcaption, INodeImage, INodeParagraph, INodeText, IPromirrorNode } from '../@type/promirrorNode';
import schema from '../schema';
import { generateRandomKey } from '../utils/draftjs';

const getContentWithInlineStyleRanges = (nodes?: INodeText[]): { inlineStyleRanges: IInlineStyleRanges[]; content: string } => {
  let content = '';
  const inlineStyleRanges: IInlineStyleRanges[] = [];
  nodes?.map((node) => {
    if (node.marks?.length) {
      node.marks.map((mark) => {
        inlineStyleRanges.push({
          style: mark.type.toUpperCase() as IInlineStyleRanges['style'],
          offset: content.length,
          length: node.text.length,
        });
      });
    }
    content += node.text;
  });
  return { inlineStyleRanges, content };
};

export const parserParagraph = (node: INodeParagraph | INodeBlockquote): Partial<IUnstyled> => {
  const { content, inlineStyleRanges } = getContentWithInlineStyleRanges(node.content);
  return {
    text: content,
    depth: 0,
    inlineStyleRanges,
    entityRanges: [],
    data: {},
  };
};

export const getContentRawState = (editorState?: EditorState | null): IContentRawState | undefined => {
  if (!editorState) return undefined;
  const blocks: IContentRawState['blocks'] = editorState.doc.toJSON().content.map((node: IPromirrorNode) => {
    switch (node.type) {
      case 'paragraph':
        return {
          key: generateRandomKey(),
          type: node.attrs.class === undefined ? 'unstyled' : node.attrs.class,
          ...parserParagraph(node),
        };

      case 'blockquote':
        return {
          key: generateRandomKey(),
          type: 'blockquote',
          ...parserParagraph(node),
        };

      case 'heading':
        return {
          key: generateRandomKey(),
          type: 'header-three',
          text: node.content?.map((content) => content.text).join('') ?? '',
          depth: 0,
          inlineStyleRanges: [] as IInlineStyleRanges[],
          entityRanges: [],
          data: {},
        };

      case 'horizontalRule':
        return {
          key: generateRandomKey(),
          text: '',
          type: 'atomic:break',
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
        };

      case 'figure': {
        const figcaptionNode = node.content.find((node) => node.type === 'figcaption') as INodeFigcaption;
        const { content, inlineStyleRanges } = getContentWithInlineStyleRanges(figcaptionNode.content);
        return {
          key: generateRandomKey(),
          text: content,
          type: 'atomic:image',
          depth: 0,
          inlineStyleRanges,
          entityRanges: [],
          data: {
            src: (node.content.find((node) => node.type === 'image') as INodeImage).attrs.src,
          },
        };
      }
      default:
        console.log('unknow', node);
        return undefined;
    }
  });
  return {
    blocks,
    entityMap: {},
  };
};

export default getContentRawState;
