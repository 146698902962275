import classNames from 'classnames';
import dayjs from 'dayjs';
import DatePicker from 'react-datepicker';

import { gotoLegacyAdmin } from '../../../utils/router';
import { getRandomStringInLength } from '../../../utils/string';
import { IScheduleOption, IFormSection } from '../hooks/useFormSections';

export interface IPropsEndOfChapterForm {
  formSections: IFormSection[];
  onEditSection: (sectionId: string, object: Partial<IFormSection>) => void;
  onDeleteSection: (sectionId: string) => void;
  onUpdateScheduleInSection: (sectionId: string, scheduleIndex: number, editedSchedule: IScheduleOption) => void;
  onCreateScheduleInSection: (sectionId: string, editedSchedule: IScheduleOption) => void;
  onDeleteScheduleInSection: (sectionId: string, scheduleIndex: number) => void;
  onOpenModalPreview: (sectionId: string) => void;
}

const EndOfChapterForm = (props: IPropsEndOfChapterForm) => {
  if (!props.formSections) return null;
  return (
    <div>
      {props.formSections.map((section) => (
        <div key={section._id} className="pb-16">
          <div className="flex items-center justify-between">
            <div className="flex items-center justify-center text-20">
              <input
                type="checkbox"
                className={classNames('toggle', {
                  'bg-colorsBrandTurquoise01Primary border-colorsBrandTurquoise01Primary': section.isActivated,
                  'bg-colorsBrandRed01Primary border-colorsBrandRed01Primary': !section.isActivated,
                })}
                checked={section.isActivated}
                onChange={() => props.onEditSection(section._id, { isActivated: !section.isActivated })}
              />
              <div className="flex flex-col pl-4">
                {section.isNCContent === true && <div className="badge badge-sm badge-error">NC</div>}
                {section.isNCContent === false && <div className="badge badge-sm badge-success">No NC</div>}
                <span className="pl-4">{section.title}</span>
              </div>
            </div>
            <div>
              <div className="dropdown dropdown-end">
                <button tabIndex={-1} className="m-1 btn">
                  ⋮
                </button>
                <ul tabIndex={-1} className="p-2 shadow menu dropdown-content bg-base-100 rounded-box w-52">
                  <li>
                    <a onClick={() => props.onOpenModalPreview(section._id)}>Preview</a>
                  </li>
                  <li>
                    <a onClick={() => gotoLegacyAdmin(`/sections/${section._id}/edit`)}>Edit</a>
                  </li>
                  <li>
                    <a onClick={() => props.onDeleteSection(section._id)}>Delete</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div>
            <label className="label" htmlFor="note">
              <span className="font-bold label-text">Admin note:</span>
            </label>
            <textarea
              id="note"
              className="min-h-[80px] textarea textarea-bordered w-full"
              placeholder="note"
              value={section.note}
              onChange={(e) =>
                props.onEditSection(section._id, {
                  note: e.target.value,
                })
              }
            />
          </div>
          <div>
            <label className="label" htmlFor="schedule">
              <span className="font-bold label-text">Schedule:</span>
            </label>
            <div id="schedule" className="flex flex-col items-center justify-center">
              {section.schedules?.map((schedule, scheduleIndex) => {
                return (
                  <div key={scheduleIndex} className="flex items-center py-4">
                    <div
                      className={classNames('w-[20px] sm:w-[55px] truncate font-bold text-colorsBrandTurquoise01Primary', {
                        'opacity-0': !dayjs().isBetween(dayjs(schedule.start).startOf('day'), dayjs(schedule.end).endOf('day')),
                      })}
                    >
                      Online
                    </div>
                    <div className="w-[25px] text-center text-6">{scheduleIndex + 1}.</div>
                    <div className="max-w-[220px] flex-1 flex items-center">
                      <DatePicker
                        dateFormat="d/M/yyyy"
                        selectsStart
                        selected={schedule.start}
                        startDate={schedule.start}
                        endDate={schedule.end}
                        maxDate={schedule.end}
                        onChange={(start: Date) => {
                          props.onUpdateScheduleInSection(section._id, scheduleIndex, { start });
                        }}
                      />
                      <div className="px-4">-</div>
                      <DatePicker
                        dateFormat="d/M/yyyy"
                        selectsEnd
                        selected={schedule.end}
                        startDate={schedule.start}
                        endDate={schedule.end}
                        minDate={schedule.start}
                        onChange={(end: Date) => {
                          props.onUpdateScheduleInSection(section._id, scheduleIndex, { end });
                        }}
                      />
                    </div>
                    <button className="btn-outline btn-sm text-colorsBrandRed01Primary" onClick={() => props.onDeleteScheduleInSection(section._id, scheduleIndex)}>
                      Delete
                    </button>
                  </div>
                );
              })}
            </div>
            <div className="flex items-center justify-center mt-16">
              <button
                className="btn-outline btn-sm"
                onClick={() =>
                  props.onCreateScheduleInSection(section._id, {
                    id: getRandomStringInLength(10),
                    start: dayjs(section.schedules?.[section.schedules.length - 1]?.end ?? undefined)
                      .add(1, 'day')
                      .toDate(),
                    end: dayjs(section.schedules?.[section.schedules.length - 1]?.end ?? undefined)
                      .add(1, 'day')
                      .toDate(),
                  })
                }
              >
                Add schedule
              </button>
            </div>
          </div>
          <div className="divider opacity-10" />
        </div>
      ))}
    </div>
  );
};

export default EndOfChapterForm;
