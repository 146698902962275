/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const dropdown = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path
      fill="#8C8C8C"
      fillRule="evenodd"
      d="M24 35L5.195 19.777A1 1 0 015.825 18h36.35a1 1 0 01.63 1.777L24 35z"
    />
  </svg>
);

export default dropdown;
