import images from '../../../assets/images';
import Card from '../../../components/Card';
import UploadFileBox from '../../../components/UploadFileBox';

interface IPropsEditEbookCoverPresenter {
  readOnly?: boolean;
  // cover
  selectedCoverFile: File | undefined;
  coverFileName?: string;
  coverFileUrl?: string;
  onSelectCoverFile: (file: File | undefined) => void;
  // banner
  selectedBannerFile: File | undefined;
  bannerFileName?: string;
  bannerFileUrl?: string;
  onSelectBannerFile: (file: File | undefined) => void;
  // error
  isRequiredBannerImage?: boolean;
}

function EditEbookCoverPresenter(props: IPropsEditEbookCoverPresenter) {
  const {
    readOnly,
    selectedCoverFile,
    coverFileName,
    coverFileUrl,
    onSelectCoverFile,
    selectedBannerFile,
    bannerFileName,
    bannerFileUrl,
    onSelectBannerFile,
    isRequiredBannerImage,
  } = props;

  return (
    <Card className="flex flex-col space-y-4">
      <div className="text-22">Cover & Banner Image</div>
      <div className="flex flex-col justify-between w-full space-x-0 md:flex-row md:space-x-24">
        <div className="flex-1">
          <UploadFileBox
            label="Cover Image"
            acceptFileExtension=".jpeg, .jpg, .png"
            onSelectFile={onSelectCoverFile}
            fileName={coverFileName ?? selectedCoverFile?.name}
            fileUrl={coverFileUrl}
            readOnly={readOnly}
            helperText="รองรับไฟล์ PNG, JPG ไม่เกิน 2MB ขนาดรูป 170x250 px"
          />
          <div className="w-[170px] h-[250px] bg-colorsBrandWarmBlack04LabelQuaternary rounded-6">
            <img className="w-[170px] h-[250px] rounded-6" src={coverFileUrl || images.pinto.default.ebookCover} />
          </div>
        </div>
        <div className="flex-1">
          <UploadFileBox
            hasError={!!isRequiredBannerImage}
            label="Banner Image"
            acceptFileExtension=".jpeg, .jpg, .png"
            onSelectFile={onSelectBannerFile}
            fileName={bannerFileName ?? selectedBannerFile?.name}
            fileUrl={bannerFileUrl}
            readOnly={readOnly}
            helperText="รองรับไฟล์ PNG, JPG ไม่เกิน 2MB ขนาดรูป 730x310 px"
          />
          <div className="w-full md:w-[730px] h-[310px] bg-colorsBrandWarmBlack04LabelQuaternary rounded-6">
            <img className="w-full md:w-[730px] h-[310px] rounded-6" src={bannerFileUrl || images.pinto.default.bannerCover} />
          </div>
        </div>
      </div>
    </Card>
  );
}

export default EditEbookCoverPresenter;
