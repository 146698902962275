import { useCallback } from 'react';

import { usePintoRejectEditEbookMutation } from '../core/graphql/operation-hooks';
import { EbookFileType } from '../core/graphql/types';

const useMutationPintoRejectEditEbook = () => {
  const [mutatePintoRejectEditEbook, { loading }] = usePintoRejectEditEbookMutation();

  const onMutatePintoRejectEditEbook = useCallback(
    async (ebookId: string, rejectNote: string, fileTypes: EbookFileType[]) => {
      const res = await mutatePintoRejectEditEbook({
        variables: {
          ebookId,
          rejectNote,
          fileTypes,
        },
      });
      return !!res.data?.rejectEditEbook?.success;
    },
    [mutatePintoRejectEditEbook],
  );

  return {
    onMutatePintoRejectEditEbook,
    isLoadingMutatePintoRejectEditEbook: loading,
  };
};

export default useMutationPintoRejectEditEbook;
