/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const camera = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path
      fill="#8C8C8C"
      fillRule="evenodd"
      d="M42 10c2.21 0 4 1.981 4 4.425v23.15C46 40.019 44.21 42 42 42H6c-2.21 0-4-1.981-4-4.425v-23.15C2 11.981 3.79 10 6 10h36zM24 36c6.075 0 11-4.925 11-11s-4.925-11-11-11-11 4.925-11 11 4.925 11 11 11zm0-5a6 6 0 110-12 6 6 0 010 12zM17 5h14a5 5 0 015 5H12a5 5 0 015-5z"
    />
  </svg>
);

export default camera;
