import type { FC } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

interface IPropsDraggableItems {
  id: string;
}
export interface IPropsDraggableArea {
  draggableItems?: IPropsDraggableItems[];
  onRenderDraggableItem: (draggableItem: IPropsDraggableItems, index: number) => JSX.Element;
  className?: string;
}

const DraggableArea: FC<IPropsDraggableArea> = ({ draggableItems, onRenderDraggableItem, className }) => {
  return (
    <div className={className}>
      <DndProvider backend={HTML5Backend}>{draggableItems?.map((draggableItem, index) => onRenderDraggableItem(draggableItem, index))}</DndProvider>
    </div>
  );
};

export default DraggableArea;
