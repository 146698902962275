import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import Card from '../../../components/Card';
import CheckBoxGroup from '../../../components/form/CheckBoxGroup';
import Input from '../../../components/form/Input';
import { EbookStatus, PintoProductSetFragment } from '../../../core/graphql/types';
import ProductSetInput from '../components/ProductSetInput';
// import useUsersProductSets from '../hooks/useUsersProductSet';
import { EBOOK_BUILDER_FIELD_KEYS } from '../validates/ebookBuilderSchema';

import EditEbookSubscriptionTitlePresenter from './EditEbookSubscriptionTitlePresenter';

export interface IPropsAdditionalInfoPresenter {
  userId?: string;
  usersProductSets?: { label: string; value: string }[];
  isbn?: string;
  haveCoverPrice?: boolean;
  productSetId?: string;
  productSetDetail?: PintoProductSetFragment;
  onSelectProductSet: (item: { label: string; value: string }) => void;
  onFetchProductSets: (keyword: string, userId?: string) => void;
  isLoadingFetchProductSets?: boolean;

  // subscription
  selectedSubscriptionTitle?: string;
  onSelectSubscriptionTitle?: (item: { label: string; value: string }) => void;
  ebookStatus?: EbookStatus;
}

const AdditionalInfoPresenter = (props: IPropsAdditionalInfoPresenter) => {
  const {
    userId,
    usersProductSets: _userProductSets = [],
    productSetId,
    isbn,
    productSetDetail,
    haveCoverPrice,
    onSelectProductSet,
    onFetchProductSets,
    isLoadingFetchProductSets,
    ebookStatus,
    selectedSubscriptionTitle,
    onSelectSubscriptionTitle,
  } = props;
  const { register, formState } = useFormContext();
  const { errors } = formState;
  const [searchProductSets, setSearchProductSets] = useState('');
  const [searchKeyword, setSearchKeyword] = useState<string | undefined>(undefined);

  useEffect(() => {
    const _searchKeyword = (searchKeyword || '').trim();
    onFetchProductSets(_searchKeyword, userId);
  }, [onFetchProductSets, searchKeyword, userId]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSearchProductSets = useCallback(
    debounce((keyword: string) => {
      setSearchKeyword(keyword);
    }, 300),
    [],
  );

  useEffect(() => {
    if (userId) {
      setSearchKeyword('');
      setSearchProductSets('');
    }
  }, [userId]);

  const onChangeSearchProductSets = useCallback(
    (searchText: string) => {
      setSearchProductSets(searchText);
      onSearchProductSets(searchText);
    },
    [onSearchProductSets],
  );
  const usersProductSets = useMemo(() => {
    if (searchKeyword && !isLoadingFetchProductSets && _userProductSets.length === 0) {
      return [];
    } else if (!isLoadingFetchProductSets && _userProductSets.length > 0) {
      return [{ label: 'ไม่มีเซ็ตหนังสือ', value: '' }, ..._userProductSets];
    }
  }, [_userProductSets, searchKeyword, isLoadingFetchProductSets]);
  return (
    <Card>
      <div className="text-22" data-invalid={!!errors.isbn || (!!errors.coverPriceThb && haveCoverPrice)}>
        ข้อมูลเพิ่มเติม
      </div>
      <div className="flex justify-between w-full space-x-24">
        <div className="w-1/2">
          <div className="h-[40px]" />
          <Input
            className="w-full"
            label="ISBN"
            name={EBOOK_BUILDER_FIELD_KEYS.ISBN}
            register={register}
            max="13"
            isInvalid={!!errors.isbn}
            helperText={errors?.isbn?.type === 'lang' ? errors?.isbn?.message : `${(isbn || '').length}/13`}
            maxLength={13}
          />
        </div>

        {/* {coverPriceThb} */}
        <div className="w-1/2">
          <CheckBoxGroup register={register} name={EBOOK_BUILDER_FIELD_KEYS.HAVE_COVER_PRICE} options={[{ label: 'กำหนดราคาปก' }]} />
          <Input
            type="number"
            className="w-full"
            label="ราคาปก (บาท)"
            name={EBOOK_BUILDER_FIELD_KEYS.COVER_PRICE_THB}
            register={register}
            isInvalid={!!errors.coverPriceThb && haveCoverPrice}
            helperText={errors.coverPriceThb?.message}
            disabled={!haveCoverPrice}
          />
        </div>
      </div>
      <div className="flex justify-between w-full space-x-24">
        <div className="w-1/2">
          <ProductSetInput
            label="เซ็ตหนังสือ"
            name={EBOOK_BUILDER_FIELD_KEYS.PRODUCT_SET_ID}
            productSetList={usersProductSets}
            productSetId={productSetId}
            onSelectProductSet={onSelectProductSet}
            productSetDetail={productSetDetail}
            productSetsSearch={searchProductSets}
            onChangeProductSetsSearch={onChangeSearchProductSets}
            disabled={!userId}
            isLoadingFetchProductSets={isLoadingFetchProductSets}
          />
        </div>
        <div className="w-1/2">
          <EditEbookSubscriptionTitlePresenter
            userId={userId}
            disabled={!userId || !!ebookStatus}
            selectedSubscriptionTitle={selectedSubscriptionTitle}
            onSelectSubscriptionTitle={onSelectSubscriptionTitle}
          />
        </div>
      </div>
    </Card>
  );
};

export default AdditionalInfoPresenter;
