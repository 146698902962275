import { Plugin } from 'prosemirror-state';

import schema from '../schema';

export const disableDrop = (): Plugin => {
  return new Plugin({
    props: {
      handleDOMEvents: {
        // disable drop content
        drop(this) {
          return true;
        },
      },
    },
  });
};

// make sure to run on client browser
export const handleBrowserEvents = (): void => {
  document.execCommand('enableObjectResizing', false, 'false');
  document.execCommand('enableInlineTableEditing', false, 'false');
};
