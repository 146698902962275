import React from 'react';
import { MdMenu } from 'react-icons/md';
import { RiDeleteBinLine } from 'react-icons/ri';

interface IPropsSectionItems {
  activeMultipleSelection: boolean;
  isSelectItemForDelete: boolean;
  children: React.ReactNode;
  onSelectItem: () => void;
  onDeleteSectionItem: () => void;
}

const SectionItems = (props: IPropsSectionItems) => {
  const { activeMultipleSelection, isSelectItemForDelete, onSelectItem, children, onDeleteSectionItem } = props;
  return (
    <div className="flex items-center space-x-12">
      {activeMultipleSelection ? (
        <div>
          <div className="flex items-center py-20 space-x-4">
            <input type="checkbox" checked={isSelectItemForDelete} onChange={onSelectItem} className="checkbox" />
          </div>
        </div>
      ) : (
        <MdMenu size="22px" color="var(--text-systemGrays01LabelPrimary)" />
      )}
      {children}

      <div className="py-8">
        <div className="flex items-center">
          <button className="space-x-4 underline btn btn-error btn-ghost text-colorsBrandRed01Primary" onClick={onDeleteSectionItem} disabled={activeMultipleSelection}>
            <RiDeleteBinLine />
            <span>Delete</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default SectionItems;
