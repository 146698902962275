import { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';

import Pagination from '../../../components/Pagination';
import ConfirmModal from '../../modals/ConfirmModal';

// import SectionItemNCBadge from './SectionItemNCBadge';

export interface SectionItem {
  _id: string;
  lastUpdated: string;
  name: string;
  isNCContent?: boolean | null;
}

export interface IPropsSectionListTable {
  sectionList: SectionItem[];
  isLoadingSectionList?: boolean;
  currentPage: number;
  totalSections: number;
  onChangePage: (num: number) => void;
  onDeleteSection: (id: string) => void;
}

const PAGE_LIMIT = 10;

const SectionListTable = (props: IPropsSectionListTable) => {
  const { sectionList, currentPage, totalSections, onChangePage, onDeleteSection } = props;
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<string | undefined>();

  const handleDeleteSection = useCallback(() => {
    setShowConfirmModal(false);
    if (typeof selectedItem !== 'undefined') {
      onDeleteSection(selectedItem);
    }
  }, [selectedItem, onDeleteSection]);

  return (
    <>
      {showConfirmModal && (
        <ConfirmModal
          onClose={() => setShowConfirmModal(false)}
          onConFirm={handleDeleteSection}
          title="Remove Section"
          subTitle="Confirm to Remove this section"
          messages={[selectedItem as string]}
        />
      )}

      <table className="table table-compact w-full">
        <thead>
          <tr>
            <th>ID</th>
            <th>Last Updated</th>
            <th>Section Name (Note)</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody className="font-tt">
          {sectionList.map((section) => {
            return (
              <tr className="hover" key={section._id}>
                <th>
                  <Link to={`sections/edit?id=${section._id}`} className="link text-colorsBrandRed01Primary mr-8">
                    {section._id}
                  </Link>
                  {/* <SectionItemNCBadge isNCContent={section.isNCContent} /> */}
                </th>
                <td>{section.lastUpdated}</td>
                <td>{section.name}</td>
                <td>
                  <Link to={`sections/edit?id=${section._id}`} className="btn btn-active btn-link text-colorsBrandRed01Primary">
                    Edit
                  </Link>

                  <button
                    className="btn btn-active btn-link text-colorsBrandRed01Primary"
                    onClick={() => {
                      setSelectedItem(section._id);
                      setShowConfirmModal(true);
                    }}
                  >
                    Remove
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="flex justify-between items-center mt-12">
        <span className="text-systemGrays02LabelSecondary">แสดงผล 10 รายการต่อหน้า</span>

        {totalSections > 0 && <Pagination currentPage={currentPage} totalCount={totalSections} pageSize={PAGE_LIMIT} onPageChange={onChangePage} />}
      </div>
    </>
  );
};

export default SectionListTable;
