/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const textDivider = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path
      fill="#8C8C8C"
      fillRule="evenodd"
      d="M11 24h26v-5a1 1 0 011-1h4a1 1 0 011 1v10.01c0 .539-.446.99-.997.99H5.997A.998.998 0 015 29.01V19a1 1 0 011-1h4a1 1 0 011 1v5z"
    />
  </svg>
);

export default textDivider;
