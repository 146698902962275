/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const mail = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path
      fill="#8C8C8C"
      fillRule="evenodd"
      d="M2 12a3 3 0 013-3h38a3 3 0 013 3v2.367a1 1 0 01-.572.904l-20.572 9.744a2 2 0 01-1.712 0L2.572 15.271A1 1 0 012 14.367V12zm21.154 17.99a2 2 0 001.695.005l19.73-9.148a1 1 0 011.421.907V35a4 4 0 01-4 4H6a4 4 0 01-4-4V21.578a1 1 0 011.427-.904l19.727 9.315z"
    />
  </svg>
);

export default mail;
