const fonts = {
  db_heavent_bd_woff2: '/a/fonts/db_heavent_bd.woff2',
  db_heavent_bd_ttf: '/a/fonts/db_heavent_bd.ttf',
  db_heavent_bd_it_woff2: '/a/fonts/db_heavent_bd_it.woff2',
  db_heavent_bd_it_ttf: '/a/fonts/db_heavent_bd_it.ttf',
  thongterm_light_woff2: '/a/fonts/thongterm_light.woff2',
  thongterm_light_ttf: '/a/fonts/thongterm_light.ttf',
  thongterm_bold_woff2: '/a/fonts/thongterm_bold.woff2',
  thongterm_bold_ttf: '/a/fonts/thongterm_bold.ttf',
  MNPaethai_woff2: '/a/fonts/MNPaethai.woff2',
  MNPaethai_ttf: '/a/fonts/MNPaethai.ttf',
  MNPaethai_bold_italic_woff2: '/a/fonts/MNPaethai_bold_italic.woff2',
  MNPaethai_bold_italic_ttf: '/a/fonts/MNPaethai_bold_italic.ttf',
  MNPaethai_light_italic_woff2: '/a/fonts/MNPaethai_light_italic.woff2',
  MNPaethai_light_italic_ttf: '/a/fonts/MNPaethai_light_italic.ttf',
  MNPaethai_bold_woff2: '/a/fonts/MNPaethai_bold.woff2',
  MNPaethai_bold_ttf: '/a/fonts/MNPaethai_bold.ttf',
  MNPaethai_medium_woff2: '/a/fonts/MNPaethai_medium.woff2',
  MNPaethai_medium_ttf: '/a/fonts/MNPaethai_medium.ttf',
  MNPaethai_italic_woff2: '/a/fonts/MNPaethai_italic.woff2',
  MNPaethai_italic_ttf: '/a/fonts/MNPaethai_italic.ttf',
  MNPaethai_light_woff2: '/a/fonts/MNPaethai_light.woff2',
  MNPaethai_light_ttf: '/a/fonts/MNPaethai_light.ttf',
};

export default fonts;
