import { useCallback } from 'react';

import { usePintoDeleteFeedMutation } from '../core/graphql/operation-hooks';
import { FeedPref } from '../core/graphql/types';

const useMutationPintoDeleteFeed = () => {
  const [mutatePintoDeleteSection] = usePintoDeleteFeedMutation();

  const deletePintoFeed = useCallback(
    (slug: string, pref: FeedPref) => {
      mutatePintoDeleteSection({
        variables: { slug, pref },
        refetchQueries: ['PintoFeeds'],
      });
    },
    [mutatePintoDeleteSection],
  );

  return {
    deletePintoFeed,
  };
};

export default useMutationPintoDeleteFeed;
