/* eslint-disable @typescript-eslint/ban-types */
import classNames from 'classnames';
import { useFormContext } from 'react-hook-form';

import '../../../components/editor/Editor.css';
import Card from '../../../components/Card';
import Input from '../../../components/form/Input';
import { SUBSCRIPTION_BUILDER_FIELD_KEYS } from '../validates/subscriptionBuilderSchema';

interface IPropsEditSubscriptionDetailPresenter {
  isCreateSub?: boolean;
}

function EditSubscriptionDetailPresenter(props: IPropsEditSubscriptionDetailPresenter) {
  const { isCreateSub } = props;

  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext();
  const MAX_LENGTH = 150;

  const [title, slug] = watch([SUBSCRIPTION_BUILDER_FIELD_KEYS.TITLE, SUBSCRIPTION_BUILDER_FIELD_KEYS.SLUG]);

  return (
    <Card className={classNames('space-y-16', { 'border-colorsBrandRed01Primary border-2 border-solid': !title })}>
      <div className="text-22">รายละเอียด</div>

      {/* ชื่อหัวหนังสือ */}
      <div data-invalid={!!errors?.title?.message || !title?.length}>
        <Input
          name="title"
          label="ชื่อหัวหนังสือ"
          required
          placeholder="ใส่ชื่อหัวหนังสือ"
          helperText={`${title?.length || 0}/${MAX_LENGTH}`}
          register={register}
          isInvalid={!!errors?.title?.message}
          maxLength={MAX_LENGTH}
        />
      </div>
      {/* -- end-- ชื่อหัวหนังสือ -- end -- */}

      {/* shortName */}
      {isCreateSub ? (
        <div data-invalid={!!errors?.slug?.message || !slug?.length}>
          <Input
            name="slug"
            label="Short Name"
            required
            placeholder="Short Name จำเป็นสำหรับการสร้างรหัสผลิตภัณฑ์บนแพลตฟอร์มของ Google และ Apple"
            helperText={errors?.slug?.message?.split(',') || `${slug?.length || 0}/15`}
            register={register}
            isInvalid={!!errors?.slug?.message}
            maxLength={15}
          />
        </div>
      ) : null}
      {/* -- end-- shortName -- end -- */}
    </Card>
  );
}

export default EditSubscriptionDetailPresenter;
