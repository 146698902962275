/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const create = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path
      fill="#8C8C8C"
      fillRule="evenodd"
      d="M12 4h24a8 8 0 018 8v24a8 8 0 01-8 8H12a8 8 0 01-8-8V12a8 8 0 018-8zm19.37 7.518l-2.013 1.95a.547.547 0 000 .774l4.369 4.488c.214.214.56.214.774 0l2.013-1.95a2.186 2.186 0 000-3.093l-2.045-2.169a2.191 2.191 0 00-3.098 0zm-19.442 19.58l-.918 5.176a.618.618 0 00.715.717l5.188-.918c.25-.044.48-.164.66-.343l14.246-14.258a.618.618 0 000-.874l-4.427-4.417a.618.618 0 00-.873 0L12.27 30.44a1.235 1.235 0 00-.343.657z"
    />
  </svg>
);

export default create;
