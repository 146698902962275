/* eslint-disable prettier/prettier */

import { isNil, omitBy } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { FiPlus } from 'react-icons/fi';
import { Link } from 'react-router-dom';

import { IEbookFilterItem } from '../../app/ebooks/components/EbookFilterDropdown';
import EbooksTablePresenter from '../../app/ebooks/presenters/EbooksTablePresenter';
import EditEbookFilterAndSearchPresenter from '../../app/ebooks/presenters/EditEbookFilterAndSearchPresenter';
import { Ebook, PublisherType, EbookStatus } from '../../core/graphql/types';
import useQueryPintoEbooks from '../../hooks/useQueryPintoEbooks';

const StatusOptions = [
  {
    label: 'All',
    value: null,
  },
  {
    label: 'Public',
    value: EbookStatus.Public,
  },
  {
    label: 'Hidden',
    value: EbookStatus.Hidden,
  },
  {
    label: 'Draft',
    value: EbookStatus.Draft,
  },
  {
    label: 'Scheduled',
    value: EbookStatus.Scheduled,
  },
  {
    label: 'Removed',
    value: EbookStatus.Removed,
  },
];

const PUBLISHER_FILTER: IEbookFilterItem[] = [
  { name: 'All', value: null },
  { name: 'UGC', value: PublisherType.Ugc },
  { name: 'PGC', value: PublisherType.Pgc },
];

const EbooksPage = () => {
  const { fetchPintoEbooks, ebooks, isLoadingPintoEbooks } = useQueryPintoEbooks();

  // const [searchText, setSearchText] = useState<string | undefined>(undefined);
  const [status, setStatus] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [publisherFilter, setPublisherFilter] = useState<PublisherType | null>(null);

  useEffect(() => {
    const filter = omitBy(
      {
        publisherType: publisherFilter,
        status,
      },
      isNil,
    );

    fetchPintoEbooks(filter);
  }, [fetchPintoEbooks, publisherFilter, status]);

  // const handleSetSearchText = useCallback((text) => {
  //   setSearchText(text);
  // }, []);

  const handleChangePage = useCallback(
    (page: number) => {
      setCurrentPage(page);
      const offset = (page - 1) * 10;
      fetchPintoEbooks({ offset });
    },
    [fetchPintoEbooks],
  );

  return (
    <div className="mx-auto space-y-24">
      <div className="space-y-24 divider-y">
        {/* section: title */}
        <div className="flex justify-between py-5">
          <h1 className="font-dbh text-[28px]">Ebooks</h1>

          <div className="space-x-16">
            <Link to="/ebooks/edit">
              <button className="space-x-8 btn btn-error">
                <FiPlus className="font-bold text-24" />
                <span>Create New Ebook</span>
              </button>
            </Link>
          </div>
        </div>
        <div className="mb-0 divider opacity-10"></div>
        {/* filter and search */}
        <div className="space-y-8">
          <EditEbookFilterAndSearchPresenter
            publisherFilterOptions={PUBLISHER_FILTER}
            onPublisherFilterChange={setPublisherFilter}
            status={status}
            onSetStatus={(_status) => setStatus(_status)}
            statusOptions={StatusOptions}
          />
        </div>
      </div>

      {/* table content */}
      <div>
        {isLoadingPintoEbooks && <div className="w-full btn btn-outline btn-disabled bg-systemGrays04LabelQuaternary border-none loading h-[100px]" />}
        {!isLoadingPintoEbooks && (ebooks?.edges?.length || 0) === 0 && (
          <div className="flex items-center justify-center w-full h-[60vh] text-colorsBrandWarmBlack02LabelSecondary">No ebooks data</div>
        )}
        {!isLoadingPintoEbooks && (ebooks?.edges?.length || 0) > 0 && (
          <EbooksTablePresenter
            totalCount={ebooks?.totalCount}
            pageLimit={10}
            currentPage={currentPage}
            onChangePage={handleChangePage}
            ebookItems={ebooks?.edges.map((edge) => edge.node as Ebook) || []}
          />
        )}
      </div>
    </div>
  );
};

export default EbooksPage;
