import { useCallback } from 'react';

import { useRequestedEbookFilesLazyQuery } from '../core/graphql/operation-hooks';
import { EditEbookFilter } from '../core/graphql/types';

export interface IPropsUseQueryRequestedEbookFiles {
  filter: EditEbookFilter;
  // includeDetail?: boolean;
}

const INITIAL_LIMIT = 10;

const useQueryRequestedEbookFiles = () => {
  const [queryRequestedEbookFiles, { data, fetchMore, loading, variables }] = useRequestedEbookFilesLazyQuery();

  const fetchRequestedEbookFiles = useCallback(
    ({ filter }: IPropsUseQueryRequestedEbookFiles) => {
      queryRequestedEbookFiles({
        variables: {
          filter: {
            ...filter,
            limit: INITIAL_LIMIT,
          },
        },
      });
    },
    [queryRequestedEbookFiles],
  );

  const onFetchMoreRequestedEbookFiles = useCallback(() => {
    if (loading || !data?.requestedEbookFiles?.pageInfo?.hasNextPage) return;
    fetchMore?.({
      variables: {
        filter: {
          ...variables?.filter,
          afterCursor: data?.requestedEbookFiles?.pageInfo.endCursor,
        },
      },
    });
  }, [data?.requestedEbookFiles?.pageInfo?.endCursor, data?.requestedEbookFiles?.pageInfo?.hasNextPage, fetchMore, loading, variables?.filter]);

  return {
    requestedEbookFiles: data?.requestedEbookFiles,
    isLoadingRequestedEbookFiles: loading,
    fetchRequestedEbookFiles,
    onFetchMoreRequestedEbookFiles,
  };
};

export default useQueryRequestedEbookFiles;
