import classNames from 'classnames';
import { isNull } from 'lodash';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

export type IInputValue = string | number | boolean | null | undefined;

export interface IPropsDropdownSelectNested {
  prefixName: string;
  name: string[];
  options?: { label: string; value?: { keyName: string; value?: IInputValue | IInputValue[] }[] }[];
  label?: string;
  className?: string;
  required?: boolean;
  helperText?: string;
  isInvalid?: boolean;
}
const DropdownSelectNested = (props: IPropsDropdownSelectNested) => {
  const { prefixName, options, label, name, required, className, helperText, isInvalid } = props;
  const { watch, setValue } = useFormContext();
  const watchList = watch(name);
  useEffect(() => {
    if (!options?.find((option) => watchList.every((data, index) => (data || null) === option.value?.[index].value))) {
      options?.[0].value?.map((v) => setValue(`${prefixName}.${v.keyName}`, v.value));
    }
  }, [options, prefixName, setValue, watchList]);
  return (
    <div className={classNames('form-control', className)}>
      {label && (
        <span className="label-text text-systemGrays02LabelSecondary">
          {label} {required && <span className="text-colorsBrandRed01Primary">*</span>}
        </span>
      )}
      <div className="flex flex-wrap py-8 space-x-8">
        <select
          onChange={(e) => options?.find((v) => v.label === e.target?.value)?.value?.map((v) => setValue(`${prefixName}.${v.keyName}`, v.value))}
          className="select select-bordered w-80"
        >
          {options?.map((option) => (
            <option
              selected={watchList.every((value, index) => option?.value?.[index]?.value === (value || null))}
              key={option.label}
              value={(isNull(option?.label) ? undefined : option?.label) as string | number | undefined}
            >
              {option.label}
            </option>
          ))}
        </select>
      </div>
      {helperText && (
        <label className="label">
          <span
            className={classNames('label-text-alt text-systemGrays02LabelSecondary font-tt', {
              'text-colorsBrandRed01Primary': isInvalid,
            })}
          >
            {helperText}
          </span>
        </label>
      )}
    </div>
  );
};

export default DropdownSelectNested;
