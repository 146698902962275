/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const alignLeft = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path
      fill="#8C8C8C"
      fillRule="evenodd"
      d="M7.118 12a5.16 5.16 0 01-.034-5.952L7.118 6h33.764l.036.04a4.486 4.486 0 01-.036 5.96H7.118zm-.033 20a4.486 4.486 0 01-.036-5.96l.036-.04H40.85l.035.04A4.486 4.486 0 0140.85 32H7.085zm-.518 10a6.9 6.9 0 01-.028-5.941L6.567 36H24.69l.028.059A6.9 6.9 0 0124.69 42H6.567zm0-20a6.9 6.9 0 01-.028-5.941L6.567 16H24.69l.028.059A6.9 6.9 0 0124.69 22H6.567z"
    />
  </svg>
);

export default alignLeft;
