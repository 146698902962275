import { usePreviewSectionQuery } from '../../core/graphql/operation-hooks';
import SuggestionProductSection, { IPropsSuggestionProductSection } from '../../fictionlog/components/SuggestionProductSection';

export interface IPropsPreviewSectionModal {
  sectionIds: string[];
  isNCPreview?: boolean;
  onClose?: () => void;
}

const PreviewSectionModal = (props: IPropsPreviewSectionModal) => {
  const { sectionIds, isNCPreview, onClose } = props;
  const { data: queryPreviewSection1, loading: isLoadingPreviewSection1 } = usePreviewSectionQuery({ variables: { id: sectionIds[0] }, skip: !sectionIds[0] });
  const { data: queryPreviewSection2, loading: isLoadingPreviewSection2 } = usePreviewSectionQuery({ variables: { id: sectionIds[1] }, skip: !sectionIds[1] });
  return (
    <div className="modal modal-open">
      <div className="modal-box max-w-[960px] max-h-[80vh] overflow-y-scroll">
        <div className="flex flex-row items-center justify-between pb-16">
          <div />

          <button onClick={onClose} className="rounded-full flex justify-center items-center btn-outline w-[36px] h-[36px]">
            X
          </button>
        </div>

        {queryPreviewSection1 && (isNCPreview === undefined || queryPreviewSection1?.previewSection.isNCContent === isNCPreview) && (
          <SuggestionProductSection
            title={queryPreviewSection1?.previewSection.title ?? ''}
            items={queryPreviewSection1?.previewSection.unionItems as IPropsSuggestionProductSection['items']}
          />
        )}
        {queryPreviewSection2 && (isNCPreview === undefined || queryPreviewSection2?.previewSection.isNCContent === isNCPreview) && (
          <SuggestionProductSection
            title={queryPreviewSection2?.previewSection.title ?? ''}
            items={queryPreviewSection2?.previewSection.unionItems as IPropsSuggestionProductSection['items']}
          />
        )}
        {(isLoadingPreviewSection1 || isLoadingPreviewSection2) && <div className="p-20">loading...</div>}
      </div>
    </div>
  );
};

export default PreviewSectionModal;
