/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const arrowRight = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path
      fill="#8C8C8C"
      fillRule="evenodd"
      d="M24 9.973A3.973 3.973 0 0127.973 6l13.054 13.8L45 24l-3.973 4.2L27.973 42A3.973 3.973 0 0124 38.027V37.8l10.22-10.805H5a2 2 0 01-2-2v-1.99a2 2 0 012-2h29.22L24 10.2v-.227z"
    />
  </svg>
);

export default arrowRight;
