/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const removeOutline = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path
      fill="#8C8C8C"
      fillRule="evenodd"
      d="M24.013 19.75l5.347-5.347a6.414 6.414 0 014.243 4.242l-5.347 5.347 5.36 5.36a6.369 6.369 0 01-4.243 4.242l-5.36-5.36-5.377 5.377a6.473 6.473 0 01-4.242-4.242l5.376-5.377-5.36-5.36a6.612 6.612 0 014.244-4.242l5.359 5.36zM24 40c8.837 0 16-7.163 16-16S32.837 8 24 8 8 15.163 8 24s7.163 16 16 16zm0 6C11.85 46 2 36.15 2 24S11.85 2 24 2s22 9.85 22 22-9.85 22-22 22z"
    />
  </svg>
);

export default removeOutline;
