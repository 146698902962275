/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const checkFill = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path
      fill="#8C8C8C"
      fillRule="evenodd"
      d="M24 46C11.85 46 2 36.15 2 24S11.85 2 24 2s22 9.85 22 22-9.85 22-22 22zM10.998 26.619L20.173 36l16.83-17.177a2.065 2.065 0 00.006-2.867l-.9-.927a1.94 1.94 0 00-2.791-.012L20.147 28.462l-5.488-5.587a1.956 1.956 0 00-2.809.006l-.85.868a2.052 2.052 0 00-.002 2.87z"
    />
  </svg>
);

export default checkFill;
