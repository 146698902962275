import classNames from 'classnames';
import dayjs from 'dayjs';
import DatePicker from 'react-datepicker';

import { ScheduleInput } from '../../../../core/graphql/types';
import { IPopupItemInput } from '../../hooks/useFormPopupItems';

export interface IPropsPopupSchedule {
  schedules?: IPopupItemInput['schedules'];
  onUpdateSchedule?: () => void;
  onCreateScheduleInSection: (editedSchedule: ScheduleInput) => void;
  onUpdateScheduleInSection: (scheduleIndex: number, editedSchedule: ScheduleInput) => void;
  onDeleteScheduleInSection: (scheduleIndex: number) => void;
}

const PopupSchedule = (props: IPropsPopupSchedule) => {
  const { schedules } = props;

  return (
    <div>
      <label className="label" htmlFor="schedule">
        <span className="font-bold label-text">Schedule:</span>
      </label>
      <div id="schedule" className="flex flex-col items-center justify-center">
        {schedules?.map((schedule, scheduleIndex) => {
          return (
            <div key={scheduleIndex} className="flex items-center py-4">
              <div
                className={classNames('w-[20px] sm:w-[55px] truncate font-bold text-colorsBrandTurquoise01Primary', {
                  'opacity-0': !dayjs().isBetween(dayjs(schedule.startedAt).startOf('day'), dayjs(schedule.endedAt).endOf('day')),
                })}
              >
                Online
              </div>
              <div className="w-[25px] text-center text-6">{scheduleIndex + 1}.</div>
              <div className="max-w-[250px] flex items-center flex-1">
                <DatePicker
                  dateFormat="d/M/yyyy"
                  selectsStart
                  selected={schedule?.startedAt}
                  startDate={schedule?.startedAt}
                  endDate={schedule?.endedAt}
                  maxDate={schedule?.endedAt}
                  popperClassName="z-10"
                  onChange={(startedAt: Date) => {
                    props.onUpdateScheduleInSection(scheduleIndex, { ...schedule, startedAt } as ScheduleInput);
                  }}
                />
                <div className="px-4">-</div>
                <DatePicker
                  dateFormat="d/M/yyyy"
                  selectsEnd
                  selected={schedule?.endedAt}
                  startDate={schedule?.startedAt}
                  endDate={schedule?.endedAt}
                  minDate={schedule?.startedAt}
                  popperClassName="z-10"
                  onChange={(endedAt: Date) => {
                    props.onUpdateScheduleInSection(scheduleIndex, { ...schedule, endedAt } as ScheduleInput);
                  }}
                />
              </div>
              <button className="btn btn-ghost btn-sm text-colorsBrandRed01Primary" onClick={() => props.onDeleteScheduleInSection(scheduleIndex)}>
                Delete
              </button>
            </div>
          );
        })}
      </div>
      <div className="flex items-center justify-center mt-16">
        <button
          className="btn btn-ghost btn-sm"
          onClick={() =>
            props.onCreateScheduleInSection({
              startedAt: dayjs(schedules?.[schedules.length - 1]?.endedAt ?? undefined)
                .add(1, 'day')
                .toDate(),
              endedAt: dayjs(schedules?.[schedules.length - 1]?.endedAt)
                .add(1, 'day')
                ?.endOf('day')
                .toDate(),
            })
          }
        >
          Add schedule
        </button>
      </div>
    </div>
  );
};

export default PopupSchedule;
