import { setBlockType, toggleMark } from 'prosemirror-commands';
import { EditorState, Transaction } from 'prosemirror-state';

import schema from '../schema';

import { insertImage, insertNodeOfType, insertNodeTypeBetweenParagraphs, toggleParagraphBetweenClass, toggleWrapBetweenParagraph } from './editorUtils';

// marks
export const toggleMarkBold = toggleMark(schema.marks.bold);
export const toggleMarkItalic = toggleMark(schema.marks.italic);
export const toggleMarkUnderline = toggleMark(schema.marks.underline);
export const toggleMarkStrikethrough = toggleMark(schema.marks.strikethrough);
export const toggleMarkSubscript = toggleMark(schema.marks.subscript);
export const toggleMarkSuperscript = toggleMark(schema.marks.superscript);
// export const toggleMarkCode = toggleMark(schema.marks.code);

// nodes
export const setBlockTypeParagraph = setBlockType(schema.nodes.paragraph, { class: undefined });
export const setBlockTypeAlignCenter = setBlockType(schema.nodes.paragraph, { class: 'center' });
export const setBlockTypeAlignRight = setBlockType(schema.nodes.paragraph, { class: 'right' });
export const toggleWrapIndent = toggleParagraphBetweenClass('indent');
export const setBlockTypeCodeBlock = setBlockType(schema.nodes.codeBlock);
export const toggleWrapBlockquote = toggleWrapBetweenParagraph(schema.nodes.blockquote);
export const toggleWrapHeading = toggleWrapBetweenParagraph(schema.nodes.heading, { level: 1 });
// export const setBlockTypeHeading = (level: number): Command => setBlockType(schema.nodes.heading, { level });

// insert
export const insertBlobImage = (state: EditorState, dispatch?: (tr: Transaction) => void): boolean => {
  const input = document.createElement('input');
  input.type = 'file';
  input.accept = '.png, .jpg, .jpeg';
  input.onchange = (e) => {
    const files = (<HTMLInputElement>e.target)?.files;
    if (files) {
      insertImage(URL.createObjectURL(files[0]))(state, dispatch);
    }
  };
  input.click();
  return true;
};
export const insertNodeLineBreak = insertNodeOfType(schema.nodes.paragraph);
export const insertNodeHorizontalRule = insertNodeTypeBetweenParagraphs(schema.nodes.horizontalRule);
