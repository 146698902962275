/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const follow = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path
      fill="#8C8C8C"
      fillRule="evenodd"
      d="M26.667 43a3.336 3.336 0 01-3.335-3.333c0-4.016-1.557-7.785-4.389-10.613A14.906 14.906 0 008.336 24.67h-.001A3.333 3.333 0 015 21.333 3.33 3.33 0 018.331 18h.005c5.792 0 11.234 2.25 15.325 6.336C27.75 28.426 30 33.872 30 39.666A3.336 3.336 0 0126.667 43zm-13.132-1.467a4.992 4.992 0 01-7.07 0 4.998 4.998 0 117.07-7.069 4.995 4.995 0 010 7.069zM39.695 43a3.305 3.305 0 01-3.306-3.304c0-7.516-2.92-14.57-8.215-19.865-5.3-5.302-12.353-8.218-19.868-8.218A3.304 3.304 0 015 8.306 3.305 3.305 0 018.306 5c9.279 0 17.997 3.606 24.543 10.154C39.394 21.697 43 30.414 43 39.696A3.307 3.307 0 0139.694 43z"
    />
  </svg>
);

export default follow;
