import classNames from 'classnames';
import { isNull, isUndefined } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import Card from '../../../components/Card';
import RadioCardButton from '../../../components/RadioCardButton';
import RadioCardButtonGroup from '../../../components/RadioCardButtonGroup';
import Typography, { TYPOGRAPHY_TYPES } from '../../../components/Typography';
import UploadFileBox from '../../../components/UploadFileBox';
import { EbookFileType, ReadingType } from '../../../core/graphql/types';
import { EBOOK_BUILDER_FIELD_KEYS } from '../validates/ebookBuilderSchema';

export const READING_TYPE_ITEMS = [
  { title: 'อ่านจากซ้ายไปขวา', description: 'เหมาะสำหรับอีบุ๊กที่เรียงลำดับหน้าจากซ้ายไปขวา เช่น หนังสือภาษาไทยทั่วไป การ์ตูนไทย', value: ReadingType.LeftToRight },
  { title: 'อ่านจากขวาไปซ้าย', description: 'เหมาะสำหรับอีบุ๊กที่เรียงลำดับหน้าจากขวาไปซ้าย เช่น การ์ตูนญี่ปุ่น การ์ตูนเกาหลี', value: ReadingType.RightToLeft },
  { title: 'อ่านแบบเว็บตูน', description: 'เหมาะสำหรับอีบุ๊กที่เรียงลำดับหน้าจากบนลงล่างอย่างไร้รอยต่อ เช่น เว็บตูน ', value: ReadingType.TopToBottomWithoutSpace },
];

interface IPropsEditEbookFilesPresenter {
  readOnly?: boolean;
  // main pdf
  selectedMainPdfFile: File | undefined | null;
  mainPdfFileName?: string;
  mainPdfFileUrl?: string;
  onSelectMainPdfFile: (file: File | undefined) => void;
  // sample pdf
  selectedSamplePdfFile: File | undefined | null;
  samplePdfFileName?: string;
  samplePdfFileUrl?: string;
  onSelectSamplePdfFile: (file: File | undefined) => void;
  // main epub
  selectedMainEpubFile: File | undefined | null;
  mainEpubFileName?: string;
  mainEpubFileUrl?: string;
  onSelectMainEpubFile: (file: File | undefined) => void;
  // sample epub
  selectedSampleEpubFile: File | undefined | null;
  sampleEpubFileName?: string;
  sampleEpubFileUrl?: string;
  onSelectSampleEpubFile: (file: File | undefined) => void;
  // errors
  isRequiredInputFile?: boolean;

  mappedEbookFiles?: {
    mainFileUrl: string;
    mainFilename: string;
    sampleFileUrl: string;
    sampleFilename: string;
    type: EbookFileType;
  }[];
}

function EditEbookFilesPresenter(props: IPropsEditEbookFilesPresenter) {
  const {
    readOnly,
    selectedMainPdfFile,
    mainPdfFileName,
    mainPdfFileUrl,
    onSelectMainPdfFile,
    selectedSamplePdfFile,
    samplePdfFileName,
    samplePdfFileUrl,
    onSelectSamplePdfFile,
    isRequiredInputFile,
  } = props;
  const { selectedMainEpubFile, mainEpubFileName, mainEpubFileUrl, onSelectMainEpubFile, selectedSampleEpubFile, sampleEpubFileName, sampleEpubFileUrl, onSelectSampleEpubFile } =
    props;
  const { watch, setValue } = useFormContext();

  const [readingType = ReadingType.LeftToRight] = watch([EBOOK_BUILDER_FIELD_KEYS.READING_TYPE]);

  const _mainPdfFileName = useMemo(
    () => (isNull(selectedMainPdfFile) ? mainPdfFileName : !isUndefined(selectedMainPdfFile) ? selectedMainPdfFile?.name : undefined),
    [mainPdfFileName, selectedMainPdfFile],
  );
  const _samplePdfFileName = useMemo(
    () => (isNull(selectedSamplePdfFile) ? samplePdfFileName : !isUndefined(selectedSamplePdfFile) ? selectedSamplePdfFile?.name : undefined),
    [samplePdfFileName, selectedSamplePdfFile],
  );
  const _mainEpubFileName = useMemo(
    () => (isNull(selectedMainEpubFile) ? mainEpubFileName : !isUndefined(selectedMainEpubFile) ? selectedMainEpubFile?.name : undefined),
    [mainEpubFileName, selectedMainEpubFile],
  );
  const _sampleEpubFileName = useMemo(
    () => (isNull(selectedSampleEpubFile) ? sampleEpubFileName : !isUndefined(selectedSampleEpubFile) ? selectedSampleEpubFile?.name : undefined),
    [sampleEpubFileName, selectedSampleEpubFile],
  );

  const _mainPdfFileUrl = useMemo(() => (isNull(selectedMainPdfFile) ? undefined : mainPdfFileUrl), [mainPdfFileUrl, selectedMainPdfFile]);
  const _mainEpubFileUrl = useMemo(() => (isNull(selectedMainEpubFile) ? undefined : mainEpubFileUrl), [mainEpubFileUrl, selectedMainEpubFile]);
  const _samplePdfFileUrl = useMemo(() => (isNull(selectedSamplePdfFile) ? undefined : samplePdfFileUrl), [samplePdfFileUrl, selectedSamplePdfFile]);
  const _sampleEpubFileUrl = useMemo(() => (isNull(selectedSampleEpubFile) ? undefined : sampleEpubFileUrl), [sampleEpubFileUrl, selectedSampleEpubFile]);

  const onChangeReadingType = useCallback(
    (value: string): void => {
      setValue(EBOOK_BUILDER_FIELD_KEYS.READING_TYPE, value);
    },
    [setValue],
  );

  return (
    <Card className={classNames('flex flex-col space-y-4', { 'border-colorsBrandRed01Primary border-2 border-solid': !!isRequiredInputFile })}>
      <div className={classNames('text-22 text-systemGrays01LabelPrimary', { 'text-colorsBrandRed01Primary': !!isRequiredInputFile })} data-invalid={!!isRequiredInputFile}>
        ไฟล์อีบุ๊ก<span className="text-colorsBrandRed01Primary">*</span>
      </div>
      <div className={classNames('label-text-alt text-systemGrays02LabelSecondary font-tt', { 'text-colorsBrandRed01Primary': !!isRequiredInputFile })}>
        *รองรับไฟล์ PDF และ EPUB เท่านั้น และไฟล์มีขนาดไม่เกิน 100 MB
      </div>
      <div className={classNames('label-text-alt text-systemGrays02LabelSecondary font-tt', { 'text-colorsBrandRed01Primary': !!isRequiredInputFile })}>
        *กรุณาเลือกอัปโหลดไฟล์อย่างน้อยหนึ่งประเภทหรือทั้ง 2 ไฟล์
      </div>
      <div className="flex flex-col w-full pt-24">
        <div>ไฟล์ PDF</div>
        <div className="flex-1">
          <UploadFileBox
            label="Main"
            acceptFileExtension=".pdf"
            onSelectFile={onSelectMainPdfFile}
            fileName={_mainPdfFileName}
            fileUrl={_mainPdfFileUrl}
            readOnly={readOnly}
            hasError={isRequiredInputFile}
          />
        </div>
        <div className="flex-1">
          <UploadFileBox
            label="Sample"
            acceptFileExtension=".pdf"
            onSelectFile={onSelectSamplePdfFile}
            fileName={_samplePdfFileName}
            fileUrl={_samplePdfFileUrl}
            readOnly={readOnly}
            hasError={isRequiredInputFile}
          />
        </div>
      </div>

      {/* section: reading type */}
      <section className="flex flex-col pt-24">
        <div>รูปแบบการอ่านอีบุ๊ก</div>
        <RadioCardButtonGroup className="mt-4 md:flex md:space-x-24">
          {READING_TYPE_ITEMS.map((readingTypeItem) => (
            <RadioCardButton
              className="!mt-16 first:mt-16 md:!mt-0 md:first:mt-0"
              radioButtonClassname="self-start !mt-4 !ml-0"
              labelClassName="!px-16 !py-12"
              key={readingTypeItem.value}
              value={readingTypeItem.value}
              title={readingTypeItem.title}
              description={readingTypeItem.description}
              checked={readingTypeItem.value === readingType}
              onSelected={() => onChangeReadingType(readingTypeItem.value)}
            />
          ))}
        </RadioCardButtonGroup>
        <Typography typography={TYPOGRAPHY_TYPES.CAPTION_1_TT} className="text-colorsBrandWarmBlack02LabelSecondary mt-4">
          คำเตือน : หากคุณเลือกอ่านแบบเว็บตูน นักอ่านจะไม่สามารถปรับรูปแบบการอ่านเป็นซ้ายไปขวา หรือขวาไปซ้ายได้
        </Typography>
      </section>

      <div className="flex flex-col w-full pt-24">
        <div>ไฟล์ EPUB</div>
        <div className="flex-1">
          <UploadFileBox
            label="Main"
            acceptFileExtension=".epub"
            onSelectFile={onSelectMainEpubFile}
            fileName={_mainEpubFileName}
            fileUrl={_mainEpubFileUrl}
            readOnly={readOnly}
            hasError={isRequiredInputFile}
          />
        </div>
        <div className="flex-1">
          <UploadFileBox
            label="Sample"
            acceptFileExtension=".epub"
            onSelectFile={onSelectSampleEpubFile}
            fileName={_sampleEpubFileName}
            fileUrl={_sampleEpubFileUrl}
            readOnly={readOnly}
            hasError={isRequiredInputFile}
          />
        </div>
      </div>
    </Card>
  );
}

export default EditEbookFilesPresenter;
