import { Ref } from 'react';

import { FeedsFilterSortBy } from '../../../core/graphql/types';

export interface ISort {
  name: string;
  value: FeedsFilterSortBy;
}

export interface IPropsFeedListSorting {
  currentSorting: ISort;
  sortingList: ISort[];
  handleClickSorting?: (item: ISort) => void;
  sortingRef?: Ref<HTMLUListElement>;
}

const FeedListSorting = (props: IPropsFeedListSorting) => {
  const { currentSorting, sortingList, sortingRef, handleClickSorting } = props;
  return (
    <div>
      <span className="text-systemGrays02LabelSecondary">Sort by: </span>
      <div className="dropdown border rounded-md">
        <label tabIndex={0} className="btn btn-ghost rounded-btn w-[204px] justify-start text-systemGrays02LabelSecondary">
          {currentSorting.name}
        </label>
        <ul ref={sortingRef} tabIndex={0} className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52">
          {sortingList.map((item) => {
            return (
              <li key={item.value}>
                <a onClick={() => handleClickSorting?.(item)}>{item.name}</a>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default FeedListSorting;
