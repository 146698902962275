import { useFormContext } from 'react-hook-form';

import CheckBoxGroup from '../../../components/form/CheckBoxGroup';
import Input from '../../../components/form/Input';
import { BOOK_PAGE_AD_FIELD_KEYS } from '../validates/bookPageAdSchema';

const BookPageAdsDetailPresenter = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <div className="h-full w-full max-w-[800px] space-y-12">
      <Input name={BOOK_PAGE_AD_FIELD_KEYS.BOOK_ID} register={register} label="Book ID" required helperText={errors.bookId?.message} isInvalid={errors.bookId} />
      <Input
        name={BOOK_PAGE_AD_FIELD_KEYS.DESTINATION_URL}
        register={register}
        label="Destination URL"
        required
        helperText={errors.destinationUrl?.message}
        isInvalid={errors.destinationUrl}
      />
      <CheckBoxGroup
        register={register}
        name={BOOK_PAGE_AD_FIELD_KEYS.CHAPTER_SEQ_LIST}
        required
        label="Chapter"
        options={[
          { label: '1', value: 1 },
          { label: '2', value: 2 },
          { label: '3', value: 3 },
          { label: '4', value: 4 },
          { label: '5', value: 5 },
        ]}
        isInvalid={errors.chapterSeqList}
        helperText={errors.chapterSeqList?.message}
      />
      <Input name={BOOK_PAGE_AD_FIELD_KEYS.NOTE} register={register} label="Note" />
    </div>
  );
};

export default BookPageAdsDetailPresenter;
