import { EditorProvider as Provider } from '@aeaton/react-prosemirror/EditorProvider';
import classNames from 'classnames';
import { Node } from 'prosemirror-model';
import { EditorState as State } from 'prosemirror-state';
import { useState } from 'react';

import ChangeStateHandle from './ChangeStateHandle';
import EditorReactProsemirror from './components/EditorReactProsemirror';
import { Toolbar, defaultInlineStyles, defaultAlignStyles, defaultBlockStyles, defaultInsertTools } from './components/Toolbar';
import { ToolbarItem } from './components/ToolbarButton';
import plugins from './plugins';
import schema from './schema';
export interface IPropsEditor {
  setEditorState: (editorState: IEditorState) => void;
  inlineStyles?: ToolbarItem[];
  alignStyles?: ToolbarItem[];
  blockStyles?: ToolbarItem[];
  insertTools?: ToolbarItem[];
  doc?: Node;
  inValid?: boolean;
  className?: string;
}

export type IEditorState = State;

const Editor = (props: IPropsEditor): JSX.Element => {
  const { setEditorState, inlineStyles, alignStyles, blockStyles, insertTools, doc, inValid, className } = props;
  const [isFocusEditor, setIsFocusEditor] = useState<boolean>(false);
  return (
    <div className={classNames('fictionlog-editor', className)}>
      <Provider schema={schema} plugins={plugins} doc={doc}>
        <Toolbar
          isFocusEditor={isFocusEditor}
          inlineStyles={inlineStyles || defaultInlineStyles}
          alignStyles={alignStyles || defaultAlignStyles}
          blockStyles={blockStyles || defaultBlockStyles}
          insertTools={insertTools || defaultInsertTools}
        />
        <ChangeStateHandle handleChange={setEditorState} debounce={300} />
        <div className="p-16">
          <EditorReactProsemirror setIsFocusEditor={setIsFocusEditor} />
        </div>
      </Provider>
    </div>
  );
};

export const EditorProvider = Provider;
export const EditorState = State;
export default Editor;
