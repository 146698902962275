import classNames from 'classnames';

import { IPropsColorSchemeCSSVariable } from '../styles/ColorSchemeCSSVariable';

import css from './ToggleThemeButton.module.scss';

export interface IPropsToggleThemeButton {
  currentTheme?: IPropsColorSchemeCSSVariable['theme'];
  toggleTheme?: () => void;
  className?: string;
}

const ToggleThemeButton = (props: IPropsToggleThemeButton): JSX.Element | null => {
  const { className, toggleTheme, currentTheme } = props;

  return (
    <button
      type="button"
      onClick={toggleTheme}
      className={classNames(
        'flex items-center px-8 py-8 text-sm rounded-lg cursor-pointer font-dbh text-systemGrays01LabelPrimary hover:bg-systemGrays05FillPrimary focus:outline-none focus:shadow-outline',
        className,
      )}
    >
      <input
        id="toggle"
        className={classNames(css.toggle, {
          [css.day]: currentTheme === 'DAY',
        })}
        type="checkbox"
      />
      <p className="ml-4">{currentTheme}</p>
    </button>
  );
};

export default ToggleThemeButton;
