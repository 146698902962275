import classNames from 'classnames';
import React from 'react';

export interface IPropsCard {
  className?: string;
  children: React.ReactNode;
}

function Card(props: IPropsCard) {
  const { className, children } = props;
  return <div className={classNames('block p-24 bg-componentsBgGrouped02 rounded-lg shadow-sm shadow-indigo-100', className)}>{children}</div>;
}

export default Card;
