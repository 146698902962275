/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const socialLine = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} viewBox="0 0 327 312" xmlns="http://www.w3.org/2000/svg">
    <g
      id="Page-1"
      stroke="none"
      stroke-width="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="line" fill="#FFFFFF" fillRule="nonzero">
        <path
          d="M326.4,133.3 C326.4,162.5 315.1,188.8 291.5,214.8 C257.2,254.2 180.7,302.2 163.3,309.5 C145.9,316.8 148.5,304.8 149.2,300.7 C153.5,275 155.7,268 136.6,263.9 C58.2,253.5 0.2,198.7 0.2,133.3 C0.2,60.3 73.4,0.9 163.3,0.9 C253.2,0.9 326.4,60.3 326.4,133.3 Z M212.1,101.2 C212.1,99.5 210.6,98 208.8,98.1 L197.4,98.1 C195.6,98.1 194.2,99.5 194.2,101.3 L194.2,143.5 L161.6,99.5 C161.1,98.6 160,98.1 159,98.1 L147.7,98.1 C145.9,98.1 144.5,99.5 144.5,101.3 L144.5,172.4 C144.5,174.2 145.9,175.6 147.7,175.6 L159.1,175.6 C160.9,175.6 162.3,174.2 162.3,172.4 L162.3,130.2 L194.9,174.2 C195.4,174.9 196.4,175.5 197.5,175.5 L208.9,175.5 C210.7,175.5 212.1,174.1 212.1,172.3 L212.1,101.2 Z M130.1,98 L118.7,98 C116.9,98 115.5,99.4 115.5,101.2 L115.5,172.3 C115.5,174.1 116.9,175.5 118.7,175.5 L130.1,175.5 C131.9,175.5 133.3,174.1 133.3,172.3 L133.3,101.2 C133.3,99.5 131.9,98 130.1,98 Z M102.6,157.6 L71.5,157.6 L71.5,101.2 C71.5,99.4 70.1,98 68.3,98 L56.9,98 C55.1,98 53.7,99.4 53.7,101.2 L53.7,172.3 C53.7,173.2 54,173.9 54.6,174.5 C55.2,175 55.9,175.4 56.8,175.4 L102.5,175.4 C104.3,175.4 105.7,174 105.7,172.2 L105.7,160.8 C105.7,159.1 104.3,157.6 102.6,157.6 Z M272.1,98 L226.4,98 C224.7,98 223.2,99.4 223.2,101.2 L223.2,172.3 C223.2,174 224.6,175.5 226.4,175.5 L272.1,175.5 C273.9,175.5 275.3,174.1 275.3,172.3 L275.3,160.9 C275.3,159.1 273.9,157.7 272.1,157.7 L241,157.7 L241,145.7 L272.1,145.7 C273.9,145.7 275.3,144.3 275.3,142.5 L275.3,131 C275.3,129.2 273.9,127.8 272.1,127.8 L241,127.8 L241,115.8 L272.1,115.8 C273.9,115.8 275.3,114.4 275.3,112.6 L275.3,101.2 C275.2,99.5 273.8,98 272.1,98 Z"
          id="Shape"
        ></path>
      </g>
    </g>
  </svg>
);

export default socialLine;
