/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const socialTwitter = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path
      fill="#8C8C8C"
      fillRule="evenodd"
      d="M39.524 13.217c1.61-1.03 2.846-2.662 3.425-4.607a15.008 15.008 0 01-4.951 2.021C36.579 9.011 34.552 8 32.308 8c-4.305 0-7.793 3.73-7.793 8.33 0 .653.065 1.29.198 1.897-6.477-.348-12.22-3.66-16.068-8.704a8.75 8.75 0 00-1.055 4.19c0 2.889 1.376 5.439 3.469 6.935a7.445 7.445 0 01-3.533-1.041v.102c0 4.038 2.685 7.406 6.256 8.168a7.17 7.17 0 01-2.056.293 7.24 7.24 0 01-1.466-.147c.99 3.308 3.87 5.72 7.283 5.784-2.67 2.236-6.032 3.566-9.683 3.566A14.47 14.47 0 016 37.258C9.45 39.62 13.548 41 17.95 41c14.342 0 22.18-12.694 22.18-23.703 0-.362-.004-.722-.02-1.077A16.434 16.434 0 0044 11.906a14.795 14.795 0 01-4.476 1.311z"
    />
  </svg>
);

export default socialTwitter;
