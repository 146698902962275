import { MdMenu } from 'react-icons/md';
import { Link } from 'react-router-dom';

import Card from '../../../components/Card';
import { IModalSectionItem } from '../modals/SectionListModal';

interface IPropsEbookSectionItem {
  activeMultipleSelection?: boolean;
  itemData: IModalSectionItem;
  deleteMultipleItem: IModalSectionItem[];
  onDeleteSectionItem?: (item: IModalSectionItem) => void;
  onToggleItem?: (item: IModalSectionItem) => void;
}

const SectionItem = (props: IPropsEbookSectionItem) => {
  const { activeMultipleSelection, onDeleteSectionItem, itemData, deleteMultipleItem, onToggleItem } = props;

  return (
    <Card className="flex justify-between items-center h-[45px] pl-4 pr-0 rounded-0">
      <div className="flex items-center justify-center">
        <div className="w-[32px] flex justify-center items-center">
          {activeMultipleSelection ? (
            <input
              type="checkbox"
              className="checkbox checkbox-sm"
              checked={!!deleteMultipleItem.find((item) => item.id === itemData.id)}
              onChange={() => onToggleItem?.(itemData)}
            />
          ) : (
            <MdMenu size={22} />
          )}
        </div>
        {itemData.id ? (
          <Link to={`/feedAndSection/sections/edit?id=${itemData.id}`} className="mr-8 link text-colorsBrandRed01Primary">
            <p className="ml-4 font-medium truncate font-tt"> {itemData.note}</p>
          </Link>
        ) : (
          <p className="ml-4 font-medium truncate font-tt"> {'[no note data]'}</p>
        )}
      </div>

      <button className="underline btn btn-error btn-ghost text-colorsBrandRed01Primary" onClick={() => onDeleteSectionItem?.(itemData)}>
        Delete
      </button>
    </Card>
  );
};

export default SectionItem;
