/* eslint-disable prettier/prettier */
import { chainCommands, createParagraphNear, liftEmptyBlock, newlineInCode, setBlockType, splitBlock } from 'prosemirror-commands';
import { MarkType, NodeType, ResolvedPos, Node as ProsemirrorNode, Fragment, Attrs } from 'prosemirror-model';
import { EditorState, Selection, NodeSelection, TextSelection, Transaction, Command } from 'prosemirror-state';
import { findWrapping, liftTarget } from 'prosemirror-transform';

import schema from '../schema';

export const parentWithNodeTypePos = ($pos: ResolvedPos, nodeType: NodeType): number | undefined => {
  const { depth } = $pos;
  for (let depthCount = depth; depthCount >= 0; depthCount--) {
    const parent = $pos.node(depthCount);
    if (parent.type === nodeType) {
      return $pos.before(depthCount);
    }
  }
};

const isNodeSelection = (selection: Selection): selection is NodeSelection => 'node' in selection;

const parentWithNodeType = ($pos: ResolvedPos, nodeType: NodeType): ProsemirrorNode | undefined => {
  const { depth } = $pos;
  for (let depthCount = depth; depthCount >= 0; depthCount--) {
    const parent = $pos.node(depthCount);
    if (parent.type === nodeType) {
      return parent;
    }
  }
};

const splitBlockPreservingAtts: Command = (state: EditorState, dispatch?: (arg0: Transaction) => void) => {
  const { $from: previousSelectionFrom } = state.selection;
  return splitBlock(state, (tr) => {
    if (dispatch) {
      const targetNodePosition = tr.selection.$from.before();
      const sourceNode = previousSelectionFrom.node();
      tr.setNodeMarkup(targetNodePosition, schema.nodes.paragraph, sourceNode.attrs);
      dispatch(tr);
    }
  });
};

export const insertNewParagraph: Command = (state: EditorState, dispatch: ((tr: Transaction) => void) | undefined) => {
  const { $from, $to } = state.selection;

  const range = $from.blockRange($to);

  if (!range) {
    return false;
  }

  // node is figcaption and selecting last character
  if (parentWithNodeType(range.$from, schema.nodes.figcaption) && $to.index() >= $to.parent.childCount) {
    insertNodeOfType(schema.nodes.paragraph)(state, dispatch);
    return true;
  }

  // split text to new paragraph
  if (parentWithNodeType(range.$from, schema.nodes.figcaption)) {
    const selection = state.selection;
    const position = selection.$to.pos;
    const textSplited = selection.$from.parent.textBetween(selection.$to.textOffset, selection.$to.parent.textContent.length ?? 0, '%');
    // create new paragraph with text
    const textNode = schema.text(textSplited);
    const node = schema.nodes.paragraph.create(null, Fragment.from(textNode));
    const transaction = state.tr.insert(position, node);
    // move selection to start of new paragraph
    const newSelection = TextSelection.create(transaction.doc, position + 1, position + 1);
    transaction.setSelection(newSelection);
    dispatch?.(transaction.scrollIntoView());
    return true;
  }

  // paragraph with class could continue with same attrs
  chainCommands(newlineInCode, createParagraphNear, liftEmptyBlock, splitBlockPreservingAtts)(state, dispatch);
  return true;
};

export const insertNodeOfType =
  (nodeType: NodeType, attrs?: Record<string, string>): Command =>
    (state: EditorState, dispatch?: (tr: Transaction) => void) => {
      const node = nodeType.create(attrs);
      if (dispatch) {
        dispatch(state.tr.replaceSelectionWith(node).scrollIntoView());
      }
      return true;
    };

export const insertNodeTypeBetweenParagraphs =
  (nodeType: NodeType, attrs?: Record<string, string>): Command =>
    (state: EditorState, dispatch?: (tr: Transaction) => void) => {
      const nodes = schema.nodes.doc.create({}, [schema.nodes.paragraph.create(), schema.node(nodeType, attrs), schema.nodes.paragraph.create()]);
      const transaction = state.tr;
      transaction.replaceSelectionWith(nodes);
      if (dispatch) {
        dispatch(transaction.scrollIntoView());
      }
      return true;
    };

export const isMarkActive =
  (markType: MarkType) =>
    (state: EditorState): boolean => {
      const { from, $from, to, empty } = state.selection;

      if (empty) {
        return Boolean(markType.isInSet(state.storedMarks || $from.marks()));
      }

      return state.doc.rangeHasMark(from, to, markType);
    };

export const isBlockActive =
  (type: NodeType, attrs: Record<string, unknown> = {}) =>
    (state: EditorState): boolean => {
      if (isNodeSelection(state.selection)) {
        return state.selection.node.hasMarkup(type, attrs);
      }

      const { $from, to } = state.selection;

      return to <= $from.end() && $from.parent.hasMarkup(type, attrs);
    };

export const isWrapped =
  (nodeType: NodeType) =>
    (state: EditorState): boolean => {
      const { $from, $to } = state.selection;

      const range = $from.blockRange($to);

      if (!range) {
        return false;
      }

      return parentWithNodeType(range.$from, nodeType) !== undefined;
    };

export const isWrappedInClass =
  (nodeType: NodeType, className?: string) =>
    (state: EditorState): boolean => {
      const { $from, $to } = state.selection;

      const range = $from.blockRange($to);

      if (!range) {
        return false;
      }

      return parentWithNodeType(range.$from, nodeType)?.attrs.class === className;
    };

export const toggleParagraphBetweenClass =
  (className: string): Command =>
    (state: EditorState, dispatch: ((tr: Transaction) => void) | undefined): boolean => {
      const { $from, $to } = state.selection;
      const range = $from.blockRange($to);
      const isActiveClassName = range ? parentWithNodeType(range.$from, schema.nodes.paragraph)?.attrs.class === className : false;
      if (isActiveClassName) {
        // unwrap
        setBlockType(schema.nodes.paragraph)(state, dispatch);
      } else {
        // wrap
        setBlockType(schema.nodes.paragraph, { class: className })(state, dispatch);
      }
      return true;
    };

export const toggleWrapBetweenParagraph =
  (nodeType: NodeType, attrs?: Record<string, unknown>): Command =>
    (state: EditorState, dispatch: ((tr: Transaction) => void) | undefined): boolean => {
      const { $from, $to } = state.selection;
      const range = $from.blockRange($to);
      const parentPos = range ? parentWithNodeTypePos(range.$from, nodeType) : undefined;
      if (typeof parentPos === 'number') {
        // unwrap
        setBlockType(schema.nodes.paragraph)(state, dispatch);
      } else {
        // wrap
        setBlockType(nodeType, attrs)(state, dispatch);
      }
      return true;
    };

export const toggleWrap =
  (nodeType: NodeType, attrs?: Record<string, unknown>): Command =>
    (state: EditorState, dispatch?: (tr: Transaction) => void): boolean => {
      const { $from, $to } = state.selection;

      const range = $from.blockRange($to);

      if (!range) {
        return false;
      }

      const parentPos = parentWithNodeTypePos(range.$from, nodeType);

      if (typeof parentPos === 'number') {
        // unwrap
        const target = liftTarget(range);

        if (typeof target !== 'number') {
          return false;
        }

        if (dispatch) {
          dispatch(state.tr.lift(range, target).scrollIntoView());
        }

        return true;
      } else {
        // wrap
        const wrapping = findWrapping(range, nodeType, attrs);

        if (!wrapping) {
          return false;
        }

        if (dispatch) {
          dispatch(state.tr.wrap(range, wrapping).scrollIntoView());
        }

        return true;
      }
    };

export const insertImage =
  (src: string): Command =>
    (state: EditorState, dispatch?: (tr: Transaction) => void) => {
      const nodes = schema.nodes.doc.create({}, [schema.nodes.image.create({ src }), schema.nodes.paragraph.create()]);

      if (dispatch) {
        dispatch(state.tr.replaceSelectionWith(nodes).scrollIntoView());
      }
      return true;
    };
