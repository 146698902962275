import { useCallback } from 'react';

import { usePintoSubscriptionTitleLazyQuery } from '../core/graphql/operation-hooks';

const useQueryPintoSubscriptionTitle = () => {
  const [queryPintoSubscriptionTitle, { data, loading }] = usePintoSubscriptionTitleLazyQuery();

  const onFetchPintoSubscriptionTitle = useCallback(
    async (id: string) => {
      queryPintoSubscriptionTitle({ variables: { subscriptionTitleId: id } });
    },
    [queryPintoSubscriptionTitle],
  );
  return {
    onFetchPintoSubscriptionTitle,
    pintoSubscriptionTitleData: data?.subscriptionTitle,
    isLoadingPintoSubscriptionTitle: loading,
  };
};

export default useQueryPintoSubscriptionTitle;
