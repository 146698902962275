/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const star = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path
      fill="#8C8C8C"
      fillRule="evenodd"
      d="M11.45 44.885c-.497.267-1.104.054-1.355-.476a1.136 1.136 0 01-.093-.673l2.29-13.636a1.117 1.117 0 00-.305-.973L2.32 19.5a1.124 1.124 0 01-.048-1.519.991.991 0 01.598-.33l13.434-1.994c.326-.048.609-.262.759-.573l6.039-12.5c.255-.528.864-.736 1.36-.465.187.103.34.265.436.464l6.04 12.501c.15.311.432.525.758.573l13.434 1.994c.552.082.937.625.86 1.213a1.099 1.099 0 01-.31.636l-9.667 9.627c-.25.249-.365.615-.304.973l2.289 13.636c.098.584-.268 1.143-.817 1.247a.956.956 0 01-.632-.098l-12.094-6.496a.956.956 0 00-.91 0l-12.094 6.496z"
    />
  </svg>
);

export default star;
