/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const iconLayoutBanner = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path
      fill="#8A8A8E"
      fillRule="evenodd"
      d="M20.889 13c.614 0 1.111.392 1.111.875v5.25c0 .483-.497.875-1.111.875H3.11C2.497 20 2 19.608 2 19.125v-5.25c0-.483.497-.875 1.111-.875H20.89zm0-9c.614 0 1.111.392 1.111.875v5.25c0 .483-.497.875-1.111.875H3.11C2.497 11 2 10.608 2 10.125v-5.25C2 4.392 2.497 4 3.111 4H20.89z"
    />
  </svg>
);

export default iconLayoutBanner;
