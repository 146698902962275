import { useCallback } from 'react';

import { useSectionPaginationQuery } from '../core/graphql/operation-hooks';
import { SectionsFilter } from '../core/graphql/types';

export type IPropsUseQuerySectionPagination = SectionsFilter;

const useQuerySectionPagination = (props: IPropsUseQuerySectionPagination) => {
  const { data, fetchMore, loading, variables } = useSectionPaginationQuery({ variables: { filter: props } });

  const onFetchMoreSectionPagination = useCallback(() => {
    if (loading || !data?.sectionPagination?.pageInfo.hasNextPage) return;
    fetchMore?.({
      variables: {
        filter: {
          ...variables?.filter,
          afterCursor: data?.sectionPagination?.pageInfo.endCursor,
        },
      },
    });
  }, [data?.sectionPagination?.pageInfo.endCursor, data?.sectionPagination?.pageInfo.hasNextPage, fetchMore, loading, variables?.filter]);

  return {
    sectionPagination: data?.sectionPagination,
    onFetchMoreSectionPagination,
    isLoadingSections: loading,
  };
};

export default useQuerySectionPagination;
