import { useCallback, useMemo } from 'react';

import { AppPriceTier } from '../../../../core/graphql/types';
import { AppPriceTierPlatform } from '../../../../core/graphql/types';
import { numberWithCommasWithDecimalPlace } from '../../../../utils/convertNumber';

export interface IPriceTier {
  id: string;
  thb?: number;
  usd?: number;
}

export interface IDropdownPriceTierOption {
  id: string;
  label: string;
  thb?: number;
  usd?: number;
  disable?: boolean;
  value: string;
}

export interface IPropsUseStatePriceTier {
  priceTiers?: {
    google?: AppPriceTier[];
    apple?: AppPriceTier[];
  };
  googlePriceTierId?: string;
  applePriceTierId?: string;

  discountGooglePriceTierId?: string;
  discountApplePriceTierId?: string;
}

const DEFAULT = { id: '', label: 'เลือกราคา', usd: undefined, thb: undefined, disable: false, value: '' };
const DISCOUNT_DEFAULT = { id: '', label: 'เลือกราคา', usd: undefined, thb: undefined, disable: false, value: '' };

const useStatePriceTier = (props: IPropsUseStatePriceTier) => {
  const { priceTiers, googlePriceTierId, applePriceTierId, discountGooglePriceTierId, discountApplePriceTierId } = props;

  const getPriceTierByPlatform = useCallback(
    (platform: AppPriceTierPlatform, discountState?: boolean) => {
      switch (platform) {
        case AppPriceTierPlatform.Google:
          return (priceTiers?.google ?? [])
            .filter((tier) => discountState || tier.thb > 0)
            .map((tier) => ({
              id: tier._id,
              label: `$${numberWithCommasWithDecimalPlace(tier.usd)} (${tier.thb} บาท)`,
              thb: tier.thb,
              usd: tier.usd,
              disable: false,
              value: tier._id,
            }));
        case AppPriceTierPlatform.Apple:
        default:
          return (priceTiers?.apple ?? [])
            .filter((tier) => discountState || tier.thb > 0)
            .map((tier) => ({
              id: tier._id,
              label: `$${numberWithCommasWithDecimalPlace(tier.usd)} (${tier.thb} บาท)`,
              thb: tier.thb,
              usd: tier.usd,
              disable: false,
              value: tier._id,
            }));
      }
    },
    [priceTiers?.apple, priceTiers?.google],
  );

  const mapAppPriceTierToIPriceTier = useCallback((priceTier?: IDropdownPriceTierOption) => {
    return priceTier
      ? {
          id: priceTier.id,
          thb: priceTier.thb,
          usd: priceTier.usd,
        }
      : undefined;
  }, []);

  const googlePriceTierList: IDropdownPriceTierOption[] = useMemo(() => {
    if (priceTiers?.google) {
      const _googlePriceTierList = [DEFAULT, ...getPriceTierByPlatform(AppPriceTierPlatform.Google)];
      return _googlePriceTierList;
    }
    return [DEFAULT];
  }, [getPriceTierByPlatform, priceTiers?.google]);

  const applePriceTierList: IDropdownPriceTierOption[] = useMemo(() => {
    if (priceTiers?.apple) {
      const _applePriceTierList = [DEFAULT, ...getPriceTierByPlatform(AppPriceTierPlatform.Apple)];
      return _applePriceTierList;
    }
    return [DEFAULT];
  }, [getPriceTierByPlatform, priceTiers?.apple]);

  const currentGooglePriceTier: IPriceTier | undefined = useMemo(() => {
    if (googlePriceTierId) {
      const _current = googlePriceTierList.find((tier) => tier.id === googlePriceTierId);
      return mapAppPriceTierToIPriceTier(_current);
    }
    return undefined;
  }, [googlePriceTierId, googlePriceTierList, mapAppPriceTierToIPriceTier]);

  const currentApplePriceTier: IPriceTier | undefined = useMemo(() => {
    if (applePriceTierId) {
      const _current = applePriceTierList.find((tier) => tier.id === applePriceTierId);
      return mapAppPriceTierToIPriceTier(_current);
    }
    return undefined;
  }, [applePriceTierId, applePriceTierList, mapAppPriceTierToIPriceTier]);

  const discountGooglePriceTierList: IDropdownPriceTierOption[] = useMemo(() => {
    if (!!currentGooglePriceTier && priceTiers?.google) {
      const _priceTier = [DISCOUNT_DEFAULT, ...getPriceTierByPlatform(AppPriceTierPlatform.Google, true)].map((tier) => ({
        ...tier,
        disable: (currentGooglePriceTier?.thb ?? 0) <= (tier.thb ?? 0),
      }));
      return _priceTier;
    }
    return [DISCOUNT_DEFAULT];
  }, [currentGooglePriceTier, getPriceTierByPlatform, priceTiers?.google]);

  const discountApplePriceTierList: IDropdownPriceTierOption[] = useMemo(() => {
    if (!!currentApplePriceTier && priceTiers?.apple) {
      const _priceTier = [DISCOUNT_DEFAULT, ...getPriceTierByPlatform(AppPriceTierPlatform.Apple, true)].map((tier) => ({
        ...tier,
        disable: (currentApplePriceTier?.thb ?? 0) <= (tier.thb ?? 0),
      }));
      return _priceTier;
    }
    return [DISCOUNT_DEFAULT];
  }, [currentApplePriceTier, getPriceTierByPlatform, priceTiers?.apple]);

  const currentDiscountGooglePriceTier: IPriceTier | undefined = useMemo(() => {
    if (discountGooglePriceTierId) {
      const _current = discountGooglePriceTierList.find((tier) => tier.id === discountGooglePriceTierId);
      return mapAppPriceTierToIPriceTier(_current);
    }
    return undefined;
  }, [discountGooglePriceTierId, discountGooglePriceTierList, mapAppPriceTierToIPriceTier]);
  const currentDiscountApplePriceTier: IPriceTier | undefined = useMemo(() => {
    if (discountApplePriceTierId) {
      const _current = discountApplePriceTierList.find((tier) => tier.id === discountApplePriceTierId);
      return mapAppPriceTierToIPriceTier(_current);
    }
    return undefined;
  }, [discountApplePriceTierId, discountApplePriceTierList, mapAppPriceTierToIPriceTier]);

  // utils

  // use to check if new google play tier is lower than discount tier
  const isLowerGoogleTier = useCallback(
    (checkTierId: string) => {
      const _checkTierItem = googlePriceTierList.find((tier) => tier.id === checkTierId);
      return (_checkTierItem?.thb ?? 0) <= (currentDiscountGooglePriceTier?.thb ?? 0);
    },
    [googlePriceTierList, currentDiscountGooglePriceTier?.thb],
  );

  // use to check if new app store tier is lower than discount tier
  const isLowerAppleTier = useCallback(
    (checkTierId: string) => {
      const _checkTierItem = applePriceTierList.find((tier) => tier.id === checkTierId);
      return (_checkTierItem?.thb ?? 0) <= (currentDiscountApplePriceTier?.thb ?? 0);
    },
    [applePriceTierList, currentDiscountApplePriceTier?.thb],
  );

  const getFreePriceTiers = useCallback(() => {
    return {
      googleFreePriceTierId: (priceTiers?.google ?? []).find((tier) => tier.thb === 0)?._id,
      appleFreePriceTierId: (priceTiers?.apple ?? []).find((tier) => tier.thb === 0)?._id,
    };
  }, [priceTiers?.apple, priceTiers?.google]);

  return {
    googlePriceTierList,
    applePriceTierList,
    discountGooglePriceTierList,
    discountApplePriceTierList,
    currentGooglePriceTier,
    currentApplePriceTier,
    currentDiscountGooglePriceTier,
    currentDiscountApplePriceTier,
    isLowerAppleTier,
    isLowerGoogleTier,
    getFreePriceTiers,
  };
};

export default useStatePriceTier;
