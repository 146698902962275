/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const moreHorizontal = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path
      fill="#8C8C8C"
      fillRule="evenodd"
      d="M35 24a5 5 0 1110 0 5 5 0 01-10 0m-16 0a5 5 0 1110 0 5 5 0 01-10 0M3 24a5 5 0 1110 0 5 5 0 01-10 0"
    />
  </svg>
);

export default moreHorizontal;
