import { useCallback } from 'react';

import { useSearchUsersLazyQuery } from '../core/graphql/operation-hooks';
import { SearchUsersFilter } from '../core/graphql/types';

export interface IPropsUseQuerySearchUsers {
  q?: string;
  filter?: SearchUsersFilter;
}

const INITIAL_LIMIT = 10;

const useQuerySearchUsers = () => {
  const [querySearchUsers, { data, fetchMore, loading, variables }] = useSearchUsersLazyQuery();

  const fetchSearchUsers = useCallback(
    ({ q, filter }: IPropsUseQuerySearchUsers) => {
      querySearchUsers({
        variables: {
          q,
          filter: {
            ...filter,
            limit: INITIAL_LIMIT,
          },
        },
      });
    },
    [querySearchUsers],
  );

  const onFetchMoreSearchUsers = useCallback(() => {
    if (loading || !data?.searchUsers?.pageInfo.hasNextPage) return;
    fetchMore?.({
      variables: {
        filter: {
          ...variables?.filter,
          afterCursor: data?.searchUsers?.pageInfo.endCursor,
        },
      },
    });
  }, [data?.searchUsers?.pageInfo.endCursor, data?.searchUsers?.pageInfo.hasNextPage, fetchMore, loading, variables?.filter]);

  return {
    searchUsers: data?.searchUsers,
    isLoadingSearchUsers: loading,
    fetchSearchUsers,
    onFetchMoreSearchUsers,
  };
};

export default useQuerySearchUsers;
