import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { ContentType, ProductType } from '../../../core/graphql/types';
import { useQueryString } from '../../../utils/router';

const EndOfChapterTableFilters = () => {
  const queryString = useQueryString();
  const contentType = queryString.get('contentType') as ContentType;
  const productType = queryString.get('productType') as ProductType;
  const isBookFic = (contentType === ContentType.Fiction && productType === ProductType.Book) || (!contentType && !productType);
  const isEbookFic = contentType === ContentType.Fiction && productType === ProductType.Ebook;
  const isEbookComic = contentType === ContentType.Comic && productType === ProductType.Ebook;
  const isEbookNonFic = contentType === ContentType.Nonfiction && productType === ProductType.Ebook;
  return (
    <div className="flex space-x-5">
      <div className="btn-group">
        <Link
          className={classNames('btn btn-outline btn-sm', {
            'btn-active !bg-colorsBrandOrange01Primary !border-colorsBrandOrange01Primary': isBookFic,
          })}
          to="/suggestProducts/endOfChapter"
        >
          <button>BookFic</button>
        </Link>
        <Link
          className={classNames('btn btn-outline btn-sm', {
            'btn-active !bg-colorsBrandOrange01Primary !border-colorsBrandOrange01Primary': isEbookFic,
          })}
          to="/suggestProducts/endOfChapter?contentType=fiction&productType=ebook"
        >
          <button>EbookFic</button>
        </Link>
        <Link
          className={classNames('btn btn-outline btn-sm', {
            'btn-active !bg-colorsBrandOrange01Primary !border-colorsBrandOrange01Primary': isEbookComic,
          })}
          to="/suggestProducts/endOfChapter?contentType=comic&productType=ebook"
        >
          <button>EBookComic</button>
        </Link>
        <Link
          className={classNames('btn btn-outline btn-sm', {
            'btn-active !bg-colorsBrandOrange01Primary !border-colorsBrandOrange01Primary': isEbookNonFic,
          })}
          to="/suggestProducts/endOfChapter?contentType=nonfiction&productType=ebook"
        >
          <button>EBookNonFic</button>
        </Link>
      </div>
    </div>
  );
};

export default EndOfChapterTableFilters;
