import classnames from 'classnames';
import { memo, ReactNode, useCallback, useRef } from 'react';
import { FiX, FiFileText } from 'react-icons/fi';

import Button from './Button';
import css from './UploadFileBox.module.scss';

interface IPropsUploadFileBox {
  acceptFileExtension: string;
  fileName?: string;
  className?: string;
  hasError?: boolean;
  label?: string;
  required?: boolean;
  readOnly?: boolean;
  helperText?: string;
  onSelectFile: (value?: File) => void;
  // extension
  fileUrl?: string;
  rightLabelNode?: ReactNode;
}

const defaultProps = {
  fileName: undefined,
  className: undefined,
  hasError: false,
};

const UploadFileBox = (props: IPropsUploadFileBox) => {
  const { label, required, readOnly, acceptFileExtension, fileName, className, hasError, onSelectFile, helperText, fileUrl, rightLabelNode } = props;
  const fileRef = useRef<HTMLInputElement>(null);

  const onChangeInputFile = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { files } = e.target;

      if (files) {
        onSelectFile(files[0]);
      }
    },
    [onSelectFile],
  );

  return (
    <div>
      <input ref={fileRef} hidden type="file" accept={acceptFileExtension} onChange={onChangeInputFile} />

      <div className="flex justify-between py-4">
        {label && (
          <label className="label">
            <span className="label-text text-systemGrays02LabelSecondary">
              {label} {required && <span className="text-colorsBrandRed01Primary">*</span>}
            </span>
          </label>
        )}

        {rightLabelNode}
      </div>

      <div
        className={classnames(
          'flex flex-col rounded-md justify-center px-12 transition-height',
          css.normal,
          {
            'border border-solid border-colorsBrandRed01Primary !bg-none': hasError,
            'border border-solid border-colorsBrandWarmBlack04LabelQuaternary !bg-none': fileName,
          },
          className,
        )}
        style={{ height: 72 }}
      >
        <div className="flex flex-row items-center">
          {!readOnly && (
            <Button color={fileName ? 'gray' : 'black'} type={fileName ? 'outline' : 'fill'} onClick={() => fileRef.current?.click()}>
              {fileName ? 'Change File' : 'Upload File'}
            </Button>
          )}

          {fileName && (
            <>
              {readOnly && (
                <a href={fileUrl} download>
                  <div className="flex items-center justify-between rounded-md border border-solid border-colorsPintoBlueprint05FillPrimary bg-colorsPintoBlueprint07FillTertiary px-16 h-[48px] md:ml-16 md:justify-center">
                    <FiFileText className="mr-4 font-bold text-colorsBrandWarmBlack02LabelSecondary text-24" />
                    <p className="font-semibold font-opn text-16 text-colorsBrandWarmBlack02LabelSecondary line-clamp-1">{fileName}</p>
                  </div>
                </a>
              )}

              {!readOnly && (
                <div className="flex items-center justify-between rounded-md border border-solid border-colorsPintoBlueprint05FillPrimary bg-colorsPintoBlueprint07FillTertiary px-16 h-[48px] md:ml-16 md:justify-center">
                  <FiFileText className="mr-4 font-bold text-colorsBrandWarmBlack02LabelSecondary text-24" />
                  <p className="font-semibold font-opn text-16 text-colorsBrandWarmBlack02LabelSecondary line-clamp-1">{fileName}</p>
                  <Button
                    color="red"
                    type="ghost"
                    className="ml-12 !p-0 !h-[24px] !w-[24px]"
                    onClick={() => {
                      fileRef!.current!.value = '';
                      onSelectFile(undefined);
                    }}
                  >
                    <FiX className="text-24 text-colorsBrandRed01Primary" />
                  </Button>
                </div>
              )}
            </>
          )}
        </div>
      </div>

      {helperText && (
        <label className="label !mt-0">
          <span
            className={classnames('label-text-alt text-systemGrays02LabelSecondary font-tt', {
              '!text-colorsBrandRed01Primary': hasError,
            })}
          >
            {helperText}
          </span>
        </label>
      )}
    </div>
  );
};

UploadFileBox.defaultProps = defaultProps;

export default memo(UploadFileBox);
