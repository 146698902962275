import classNames from 'classnames';
import React from 'react';

import images from '../../../assets/images';

export interface IPropsSelectedUser {
  __typename?: 'User' | undefined;
  _id?: string | null | undefined;
  username?: string | null | undefined;
  email?: string | null | undefined;
  displayName?: string | null | undefined;
  profileImage?: string | null | undefined;
  type?: string | null | undefined;
  isPremium?: boolean | null;
}

const UserProfile = (props: IPropsSelectedUser) => {
  const { profileImage, _id, displayName, username, email, type, isPremium } = props;
  return (
    <div className="flex gap-20">
      <table className="table w-full">
        <tr key={_id}>
          <td>
            <div className="flex items-center space-x-3">
              <div className="avatar">
                <div className="w-16 h-16 mask mask-circle">
                  <img src={profileImage || images.pinto.default.profileDefault} alt="Avatar" />
                </div>
              </div>
              <div>
                <div className="text-16 font-dbh truncate">{displayName?.trim()}</div>
                <div className="text-14 font-tt text-systemGrays02LabelSecondary">{username?.startsWith('@') ? username.trim() : `@${username?.trim()}`}</div>
              </div>
            </div>
          </td>
          <td>
            <span className="text-sm text-colorsBrandWarmBlack02LabelSecondary">ID</span>
            <br />
            <span className="font-bold badge badge-ghost badge-sm">{_id || '-'}</span>
          </td>
          <td>
            <span className="text-sm text-colorsBrandWarmBlack02LabelSecondary">Email</span>
            <br />
            <span className="font-bold badge badge-ghost badge-sm">{email || '-'}</span>
          </td>
          <td>
            <span className="text-sm text-colorsBrandWarmBlack02LabelSecondary">Type</span>
            <br />
            <span className="font-bold badge badge-ghost badge-sm">{type || '-'}</span>
          </td>
          <td>
            <span className="text-sm text-colorsBrandWarmBlack02LabelSecondary">Premium</span>
            <br />
            <span
              className={classNames('font-bold badge badge-sm', {
                'badge-warning': isPremium,
                'badge-ghost': !isPremium,
              })}
            >{`${!!isPremium}`}</span>
          </td>
        </tr>
      </table>
    </div>
  );
};

export default UserProfile;
