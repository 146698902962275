/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const download = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path
      fill="#8C8C8C"
      fillRule="evenodd"
      d="M32 19h7.332a1 1 0 01.638 1.77L24 34 8.03 20.77A1 1 0 018.668 19H16V5a1 1 0 011-1h14a1 1 0 011 1v14zM6 43.01v-4.02A1 1 0 017 38h34a1 1 0 011 .99v4.02a1 1 0 01-1 .99H7a1 1 0 01-1-.99z"
    />
  </svg>
);

export default download;
