import { useFormContext } from 'react-hook-form';

import Card from '../../../components/Card';
import Input from '../../../components/form/Input';

function SectionNotePresenter() {
  const { register } = useFormContext();

  return (
    <Card>
      <div className="text-22">Section Name (Note)</div>
      <Input name="note" register={register} required label="Note" placeholder="Note for Admin" />
    </Card>
  );
}

export default SectionNotePresenter;
