/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const minusOutline = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path
      fill="#8C8C8C"
      fillRule="evenodd"
      d="M14.373 21h19.275a6.858 6.858 0 010 6H14.373a7.13 7.13 0 010-6zM24 40c8.837 0 16-7.163 16-16S32.837 8 24 8 8 15.163 8 24s7.163 16 16 16zm0 6C11.85 46 2 36.15 2 24S11.85 2 24 2s22 9.85 22 22-9.85 22-22 22z"
    />
  </svg>
);

export default minusOutline;
