import classNames from 'classnames';
import { memo, ReactNode, useState, KeyboardEvent } from 'react';

type ISearchInputDropdownItem = {
  label: string;
  value: string;
  node?: ReactNode;
};

type IPropsSearchInputDropdown = {
  placeholder?: string;
  className?: string;
  menuClassName?: string;
  value?: string;
  items?: ISearchInputDropdownItem[];
  onChange(val: string): void;
  onClickMenuItem?: (item: { label: string; value: string }) => void;
  disabled?: boolean;
  isLoading?: boolean;
  onKeydown?: (event: KeyboardEvent<HTMLElement>) => void;
};

const SearchInputDropdown = (props: IPropsSearchInputDropdown) => {
  const { items, value, onChange, placeholder, className, menuClassName, onClickMenuItem, disabled, isLoading = false, onKeydown } = props;
  const [open, setOpen] = useState(false);

  return (
    <div
      // use classnames here to easily toggle dropdown open
      className={classNames(
        'dropdown w-full',
        {
          'dropdown-open': open,
        },
        className,
      )}
    >
      <input
        type="text"
        className="w-full input input-bordered"
        onFocus={() => setOpen(true)}
        onChange={(e) => onChange(e.target.value)}
        value={value}
        placeholder={placeholder}
        tabIndex={0}
        disabled={disabled}
        onKeyDown={onKeydown}
      />
      {/* add this part */}
      {open && (
        <ul className={classNames('bg-base-100 dropdown-content top-14 max-h-96 overflow-y-auto flex-col rounded-md drop-shadow', menuClassName)}>
          {isLoading && (
            <div className="w-full h-[100px]">
              <div className="w-full btn btn-outline btn-disabled border-none loading h-full">loading...</div>
            </div>
          )}
          {!isLoading && (items?.length ?? 0) > 0 ? (
            items?.map((item, index) => {
              return (
                <li
                  key={index}
                  tabIndex={index + 1}
                  onClick={() => {
                    onClickMenuItem?.(item);
                    setOpen(false);
                    onChange('');
                  }}
                  className="border-b border-b-base-content/10 w-full flex items-start text-left px-12 py-8 hover:cursor-pointer hover:bg-colorsBrandWarmBlack07FillTertiary"
                >
                  {item?.node ? item.node : <button className="text-left">{item.label}</button>}
                </li>
              );
            })
          ) : (
            <li
              onClick={() => {
                setOpen(false);
              }}
              className="w-full flex justify-center px-12 py-8"
            >
              <button>ไม่พบรายการที่ค้นหา</button>
            </li>
          )}
        </ul>
      )}
    </div>
  );
};

export default memo(SearchInputDropdown);
