/* eslint-disable no-useless-escape */
import * as yup from 'yup';
import { AnySchema } from 'yup';
import Lazy from 'yup/lib/Lazy';
import Reference from 'yup/lib/Reference';

import { IErrorObject } from '../../../utils/validator';

export enum BOOK_PAGE_AD_FIELD_KEYS {
  BOOK_ID = 'bookId',
  DESTINATION_URL = 'destinationUrl',
  CHAPTER_SEQ_LIST = 'chapterSeqList',
  NOTE = 'note',
}

export interface IErrorsBookPageAd {
  bookId?: IErrorObject;
  destinationUrl?: IErrorObject;
  chapterSeqList?: IErrorObject;
  note?: IErrorObject;
}

export type IBookPageAdFields = keyof IErrorsBookPageAd;

const BookPageAdFormObj: { [key in IBookPageAdFields]: AnySchema<unknown, unknown, unknown> | Reference<unknown> | Lazy<never, unknown> } = {
  bookId: yup.string().nullable().required('Book ID is required').trim(),
  destinationUrl: yup.string().nullable().required('URL is required').trim(),
  chapterSeqList: yup.array().of(yup.number()).min(1, 'Select at least 1 chapter').required('Select at least 1 chapter'),
  note: yup.string().nullable().trim(),
};

export default BookPageAdFormObj;
