import { useEffect } from 'react';

import { useTemplatesLazyQuery } from '../core/graphql/operation-hooks';
import { TemplatesFilter } from '../core/graphql/types';

export type IPropsUseQueryTemplates = TemplatesFilter;

const useQueryTemplates = (props: IPropsUseQueryTemplates) => {
  const [queryTemplates, { data, loading }] = useTemplatesLazyQuery({
    variables: {
      filter: {
        limit: 50,
        ...props,
      },
    },
  });

  useEffect(() => {
    queryTemplates();
  }, [queryTemplates]);

  return {
    queryTemplates,
    templates: data?.templates,
    isLoadingTemplates: loading,
  };
};

export default useQueryTemplates;
