import { useCallback } from 'react';

import { usePintoCreateSectionMutation } from '../core/graphql/operation-hooks';
import { SectionInput } from '../core/graphql/types';

const useMutatePintoCreateSection = () => {
  const [mutatePintoCreateSection, { loading: isLoadingMutatePintoCreateSection }] = usePintoCreateSectionMutation();

  const onMutatePintoCreateSection = useCallback(
    async (input: SectionInput) => {
      const variables = { input };
      const result = await mutatePintoCreateSection({ variables });
      return !!result.data?.pintoCreateSection._id;
    },
    [mutatePintoCreateSection],
  );

  return {
    onMutatePintoCreateSection,
    isLoadingMutatePintoCreateSection,
  };
};

export default useMutatePintoCreateSection;
