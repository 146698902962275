/* eslint-disable @typescript-eslint/no-explicit-any */
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import {
  useForm,
  UseFormGetValues,
  FormState,
  UseFormTrigger,
  Resolver,
  UseFormSetValue,
  UseFormRegisterReturn,
  Path,
  UseFormWatch,
  ValidationMode,
  UseFormReturn,
  DeepPartial,
  UnpackNestedValue,
  UseFormClearErrors,
  UseFormSetError,
} from 'react-hook-form';
import * as yup from 'yup';
import Lazy from 'yup/lib/Lazy';
import Reference from 'yup/lib/Reference';

interface IPropsUseHookForm<T extends string> {
  mode?: keyof ValidationMode;
  objectShapeForm: Record<T, yup.AnySchema | Reference | Lazy<any, any>>;
  defaultValues?: UnpackNestedValue<DeepPartial<Record<T, any>>>;
}

interface IUseHookForm<T extends string> {
  formState: FormState<Record<T, any>>;
  getValues: UseFormGetValues<Record<T, any>>;
  onValidate: UseFormTrigger<Record<T, any>>;
  formRegisters: Record<T, UseFormRegisterReturn>;
  setValue: UseFormSetValue<Record<T, any>>;
  watch: UseFormWatch<Record<T, any>>;
  methods: UseFormReturn<Record<T, any>>;
  setError: UseFormSetError<Record<T, any>>;
  clearErrors: UseFormClearErrors<Record<T, any>>;
}

const useHookForm = <T extends string>({ objectShapeForm, mode, defaultValues }: IPropsUseHookForm<T>): IUseHookForm<T> => {
  const methods = useForm<Record<T, any>>({
    mode: mode ?? 'onSubmit',
    resolver: yupResolver(yup.object().shape(objectShapeForm)) as Resolver<Record<T, any>, any>,
    defaultValues,
  });
  const { register, formState, getValues, setValue, trigger, watch, setError, clearErrors } = methods;

  const [formRegisters, setFormRegisters] = useState<Record<T, any>>({} as Record<T, UseFormRegisterReturn>);

  useEffect(() => {
    const fieldNames = Object.keys(objectShapeForm);
    const newFormRegisters = {} as any;

    fieldNames.forEach((fieldName) => {
      newFormRegisters[fieldName] = register(fieldName as Path<Record<T, any>>);
    });
    setFormRegisters(newFormRegisters);
  }, [objectShapeForm, register]);

  return { formRegisters, setValue, formState, getValues, onValidate: trigger, watch, methods, setError, clearErrors };
};

export default useHookForm;
