import classNames from 'classnames';
import { useCallback, useMemo, useState } from 'react';
import DatePicker from 'react-datepicker';
import { MdClose } from 'react-icons/md';

import { addDate, endOfDate, getDateInFormat, isSameDate, startOfDate } from '../../../utils/dayjs';

const DATE_FORMAT = 'D MMM YYYY H:mm';

export interface IPropsSchedulePublishModal {
  initialDate?: Date;
  onClose: () => void;
  onConFirm: (date?: Date) => void;
}

function SchedulePublishModal(props: IPropsSchedulePublishModal) {
  const { initialDate, onConFirm, onClose } = props;
  const [schedulePublishDate, setSchedulePublishDate] = useState<Date | undefined>(initialDate);

  const currentDate = useMemo(() => new Date(), []);

  // start date
  const getMinTime = useCallback(() => {
    if (!schedulePublishDate || (schedulePublishDate && isSameDate(schedulePublishDate, currentDate))) {
      return addDate(currentDate, 1, 'minute').toDate();
    }

    return startOfDate(schedulePublishDate).toDate();
  }, [currentDate, schedulePublishDate]);

  return (
    <>
      <div className="modal modal-open !mt-0">
        <div className="modal-box relative flex flex-col items-center w-[450px] space-y-24">
          <button onClick={onClose} className="rounded-full flex self-end justify-center items-center btn-outline w-[36px] h-[36px]">
            <MdClose size={24} />
          </button>
          <h3 className="text-lg font-bold font-dbh text-systemGrays01LabelPrimary">ตั้งเวลาเผยแพร่</h3>

          <div className="min-h-[282px]">
            <DatePicker
              calendarClassName="select-none"
              className={classNames('input input-bordered w-full h-full font-tt text-systemGrays02LabelSecondary font-semibold', {
                '!input-error': false,
              })}
              required
              inline
              showTimeSelect
              dateFormat="d MMM yyyy h:mm aa"
              timeIntervals={5}
              selected={schedulePublishDate}
              minTime={getMinTime()}
              maxTime={endOfDate(currentDate).toDate()}
              minDate={currentDate}
              maxDate={addDate(currentDate, 6, 'months').toDate()}
              onChange={(date: Date) => {
                setSchedulePublishDate(date);
              }}
            />
          </div>

          <p className="font-tt !mt-0 text-systemGrays01LabelPrimary">เผยแพร่: {getDateInFormat(schedulePublishDate, 'th', DATE_FORMAT)}</p>

          <div className="w-full justify-between flex space-x-6">
            <button className="btn btn-outline btn-error flex-1" onClick={onClose}>
              ยกเลิก
            </button>
            <button
              className="btn btn-error flex-1"
              onClick={() => {
                onConFirm(schedulePublishDate);
              }}
            >
              ตั้งเวลา
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default SchedulePublishModal;
