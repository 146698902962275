/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const notification = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path
      fill="#8C8C8C"
      fillRule="evenodd"
      d="M21 6.378V5a3 3 0 016 0v1.378C31.864 7.63 35.534 11.855 35.959 17H36v11.727l5.026 3.656A2 2 0 0139.85 36H8.15a2 2 0 01-1.176-3.617L12 28.727V17h.041c.425-5.145 4.095-9.37 8.959-10.622zM17 39h14a7 7 0 01-14 0z"
    />
  </svg>
);

export default notification;
