import { useState, useCallback, useRef } from 'react';

import { PublisherType } from '../../../core/graphql/types';

export interface IEbookFilterItem {
  name: string;
  value: PublisherType | null;
}

interface IPropsEbookFilterDropdown {
  label: string;
  items: IEbookFilterItem[];
  onFilterChange?: (value: PublisherType | null) => void;
}

const EbookFilterDropdown = (props: IPropsEbookFilterDropdown) => {
  const { label, items, onFilterChange } = props;
  const [currentFilter, setCurrentFilter] = useState<string | null>(items[0].name);
  const sortingRef = useRef<HTMLUListElement>(null);

  const handleFilterClick = useCallback(
    (sortingItem: IEbookFilterItem) => {
      setCurrentFilter(sortingItem.name);
      sortingRef.current?.blur();
      onFilterChange?.(sortingItem.value);
    },
    [sortingRef, onFilterChange],
  );

  return (
    <div>
      <span className="text-systemGrays02LabelSecondary">{label}</span>
      <div className="dropdown border rounded-md">
        <label tabIndex={0} className="btn btn-outline rounded-btn w-[200px] justify-start">
          {currentFilter}
        </label>
        <ul ref={sortingRef} tabIndex={0} className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52">
          {items.map((item) => {
            return (
              <li key={item.name}>
                <a onClick={() => handleFilterClick(item)}>{item.name}</a>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default EbookFilterDropdown;
