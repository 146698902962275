export interface IPropsProgressBar {
  label?: string;
  percentValue: number;
}

const ProgressBar = (props: IPropsProgressBar) => {
  const { label, percentValue = 0 } = props;
  return (
    <div>
      {label && (
        <div className="flex w-full justify-between">
          <p className="font-opn text-18 font-semibold text-colorsBrandWarmBlack02LabelSecondary">{label}</p>
          <p className="font-opn text-18 font-semibold text-colorsBrandWarmBlack02LabelSecondary">{percentValue}%</p>
        </div>
      )}

      <div className="h-[12px] w-full rounded-md !bg-colorsBrandWarmBlack05FillPrimary">
        <div className="h-[12px] rounded-md !bg-colorsBrandRed01Primary transition-all duration-500 ease-out" style={{ width: `${percentValue}%` }} />
      </div>
    </div>
  );
};

export default ProgressBar;
