import { useCallback, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';

import { ContentType, FeedPref, ProductType } from '../../../core/graphql/types';
import useQuerySectionPagination from '../../../hooks/useQuerySectionPagination';
import { IPopupItemInput } from '../hooks/useFormPopupItems';

export interface IPropsSelectSectionInPopupPageModal {
  popupIndex: number;
  selectedSectionId?: string;
  onUpdatePopupItem: (popupIndex: number, item: Partial<IPopupItemInput>) => void;
  onCloseEditSectionPopupModal: () => void;
}

const SelectSectionInPopupPageModal = (props: IPropsSelectSectionInPopupPageModal) => {
  const { popupIndex, selectedSectionId, onUpdatePopupItem, onCloseEditSectionPopupModal } = props;

  const [contentType, setContentType] = useState<string>('all');
  const [productType, setProductType] = useState<string>('all');
  const [pref, setPref] = useState<string>('all');
  const { sectionPagination, isLoadingSections, onFetchMoreSectionPagination } = useQuerySectionPagination({
    contentType: contentType === 'all' ? undefined : (contentType as ContentType),
    productType: productType === 'all' ? undefined : (productType as ProductType),
    pref: pref === 'all' ? undefined : (pref as FeedPref),
  });

  const sections = sectionPagination?.edges;

  const onSetConfigId = useCallback(
    (configId?: string) => {
      const updatedItem = {
        configId: configId ?? '',
      };
      onUpdatePopupItem(popupIndex, updatedItem);
    },
    [onUpdatePopupItem, popupIndex],
  );

  return (
    <div className="modal modal-open">
      <div className="modal-box max-w-[960px]">
        <div className="flex flex-row items-center justify-between pb-16">
          <h3 className="font-bold text-22">Select sections</h3>
          <button onClick={onCloseEditSectionPopupModal} className="rounded-full flex justify-center items-center btn-outline w-[36px] h-[36px]">
            X
          </button>
        </div>

        <div className="flex py-12 space-x-12 overflow-x-scroll scrollbar-hide">
          <div className="flex items-center justify-center">
            <div className="label">Pref:</div>
            <select className="w-[120px] select select-bordered select-sm" value={pref} onChange={(e) => setPref(e.target.value)}>
              <option value="all">All</option>
              <option value={FeedPref.Default}>Default</option>
              <option value={FeedPref.Female}>Female</option>
              <option value={FeedPref.Male}>Male</option>
              <option value={FeedPref.Y}>Y</option>
            </select>
          </div>
          <div className="flex items-center justify-center">
            <div className="label">ProductType:</div>
            <select className="w-[120px] select select-bordered select-sm" value={productType} onChange={(e) => setProductType(e.target.value)}>
              <option value="all">All</option>
              <option value={ProductType.Book}>Book</option>
              <option value={ProductType.Ebook}>Ebook</option>
            </select>
          </div>
          <div className="flex items-center justify-center">
            <div className="label">ContentType:</div>
            <select className="w-[120px] select select-bordered select-sm" value={contentType} onChange={(e) => setContentType(e.target.value)}>
              <option value="all">All</option>
              <option value={ContentType.Comic}>Comic</option>
              <option value={ContentType.Fiction}>Fiction</option>
              <option value={ContentType.Nonfiction}>Nonfiction</option>
            </select>
          </div>
        </div>

        <div className="h-[calc(100vh-400px)] overflow-y-scroll">
          {!isLoadingSections && sections?.length === 0 && <div className="flex items-center justify-center p-32 rounded-lg bg-systemGrays06FillSecondary">Not found</div>}
          {isLoadingSections && <div className="flex items-center justify-center p-32 rounded-lg bg-systemGrays06FillSecondary">Loading...</div>}
          {!isLoadingSections && (sections?.length ?? 0) > 0 && (
            <InfiniteScroll
              loadMore={onFetchMoreSectionPagination}
              hasMore={!!sectionPagination?.pageInfo.hasNextPage}
              loader={<div className="flex items-center justify-center p-32">Loading...</div>}
              useWindow={false}
            >
              {sections?.map(({ node: section }) => (
                <div
                  key={section?._id}
                  role="button"
                  aria-hidden="true"
                  className="inline-flex items-center w-full px-4 py-8 cursor-pointer md:w-1/2 hover:bg-systemGrays08FillQuaternary"
                  onClick={() => {
                    if (selectedSectionId === section?._id) {
                      return onSetConfigId(undefined);
                    }
                    return onSetConfigId(section._id);
                  }}
                >
                  <input type="checkbox" className="mr-12 checkbox checkbox-xs" readOnly checked={selectedSectionId === section?._id} />
                  <div className="flex justify-between w-full">
                    <div className="max-w-[230px] truncate">{section?.note}</div>
                    <div className="flex self-end space-x-4">
                      {section?.isNCContent && <div className="btn btn-xs btn-error">NC</div>}
                      {section?.contentType && <div className="btn btn-xs btn-error btn-outline">{section?.contentType}</div>}
                      {section?.productType && <div className="btn btn-xs btn-error btn-outline">{section?.productType}</div>}
                    </div>
                  </div>
                </div>
              ))}
            </InfiniteScroll>
          )}
        </div>

        <div className="modal-action">
          <button onClick={onCloseEditSectionPopupModal} className="btn">
            Accept
          </button>
        </div>
      </div>
    </div>
  );
};

export default SelectSectionInPopupPageModal;
