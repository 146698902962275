import dayjs from 'dayjs';

import { useUpdateTemplateMutation } from '../../../core/graphql/operation-hooks';
import { AllCategoriesQuery, Category, ContentType, ProductType, TemplateNodeFragment, TemplatesQuery } from '../../../core/graphql/types';
import { useQueryString } from '../../../utils/router';
import { getProductShortName } from '../../../utils/string';

import EndOfChapterTableItem, { IPropsEndOfChapterTableItem } from './EndOfChapterTableItem';

export interface IPropsLibraryTable {
  allCategories: AllCategoriesQuery;
  templatesQueryEdges?: TemplatesQuery['templates']['edges'];
}

const getPropsEndOfChapterTableItem = (node: Partial<TemplateNodeFragment>, onToggleActivate: (templateId: string, isActivated: boolean) => void): IPropsEndOfChapterTableItem => ({
  categoryTagId: node.categoryTag?._id ?? '',
  contentType: node.contentType!,
  productType: node.productType!,
  isActivated: node.isActivated,
  categoryName: node.categoryTag?.name ?? '',
  productShortName: getProductShortName(node.productType!, node.contentType!),
  updatedAtInFormat: dayjs(node.updatedAt).format('DD/MM/YYYY HH:mm'),
  onTogglePublish: () => onToggleActivate(node._id!, !node.isActivated),
});

const getEmptyPropsEndOfChapterTableItem = (category: Pick<Category, '_id' | 'name'>, contentType: ContentType, productType: ProductType): IPropsEndOfChapterTableItem => ({
  categoryTagId: category._id ?? '',
  contentType,
  productType,
  categoryName: category.name!,
  productShortName: getProductShortName(productType!, contentType!),
  updatedAtInFormat: '-',
});

const LibraryTable = (props: IPropsLibraryTable) => {
  const { templatesQueryEdges, allCategories } = props;
  const queryString = useQueryString();
  const contentType = (queryString.get('contentType') as ContentType) || ContentType.Fiction;
  const productType = (queryString.get('productType') as ProductType) || ProductType.Book;

  const [mutateUpdateTemplate] = useUpdateTemplateMutation();

  const onToggleActivate = (templateId: string, isActivated: boolean) => {
    mutateUpdateTemplate({
      variables: {
        templateId,
        input: { isActivated },
      },
    });
  };

  const alreadyItemsNode = templatesQueryEdges?.map((edege) => edege.node.categoryTag?._id) || [];
  const currentCategories = allCategories[`${contentType}Categories`]?.filter((category) => !alreadyItemsNode.includes(category!._id!));
  return (
    <div>
      <table className="table w-full">
        <thead>
          <tr>
            <th className="!rounded-bl-0">ContentType - Categories</th>
            <th>Last Update</th>
            <th>Publish</th>
            <th className="!rounded-br-0">Actions</th>
          </tr>
        </thead>
        <tbody>
          {templatesQueryEdges?.map((item) => (
            <EndOfChapterTableItem key={item.node._id} {...getPropsEndOfChapterTableItem(item.node, onToggleActivate)} />
          ))}

          {currentCategories?.map((category) => (
            <EndOfChapterTableItem key={`empty-${category?.name}`} {...getEmptyPropsEndOfChapterTableItem(category!, contentType, productType)} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default LibraryTable;
