import { useMutation } from '@apollo/client';

import { UpdatePopupDocument } from '../core/graphql/operation-hooks';

const useMutateUpdatePopup = () => {
  const [mutateUpdatePopup, { loading }] = useMutation(UpdatePopupDocument);
  return {
    mutateUpdatePopup,
    isLoadingMutateUpdatePopup: loading,
  };
};

export default useMutateUpdatePopup;
