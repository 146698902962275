import * as yup from 'yup';
import { AnySchema } from 'yup';
import Lazy from 'yup/lib/Lazy';
import Reference from 'yup/lib/Reference';

import { numberWithCommasWithDecimalPlace } from '../../../../utils/convertNumber';
import { IErrorObject } from '../../../../utils/validator';
import { DISCOUNT_VALUE } from '../SetPriceModal';

export enum SET_PRICE_MODAL_FIELD_KEYS {
  WEB_PRICE_THB = 'webPriceThb',
  IS_FREE = 'isFree',
  GOOGLE_PRICE_TIER_ID = 'googlePriceTierId',
  APPLE_PRICE_TIER_ID = 'applePriceTierId',
  HAVE_DISCOUNT = 'haveDiscount',
  DISCOUNT_TYPE = 'discountType',
  DISCOUNT_PRICE = 'discountPrice',
  DISCOUNT_RATIO = 'discountRatio',
  DISCOUNT_GOOGLE_PRICE_TIER_ID = 'discountGooglePriceTierId',
  DISCOUNT_APPLE_PRICE_TIER_ID = 'discountApplePriceTierId',
  IS_HAS_DISCOUNT_END_DATE = 'isHasDiscountEndDate',
  DISCOUNT_START_DATE = 'discountStartDate',
  DISCOUNT_END_DATE = 'discountEndDate',
}

export interface IErrorsSetPriceModal {
  webPriceThb?: IErrorObject;
  isFree?: IErrorObject;
  // in-app pricing
  googlePriceTierId?: IErrorObject;
  applePriceTierId?: IErrorObject;
  // discount
  haveDiscount?: IErrorObject;
  discountType?: IErrorObject;
  discountPrice?: IErrorObject;
  discountRatio?: IErrorObject;
  discountGooglePriceTierId?: IErrorObject;
  discountApplePriceTierId?: IErrorObject;
  isHasDiscountEndDate?: IErrorObject;
  discountStartDate?: IErrorObject;
  discountEndDate?: IErrorObject;
}

export type ISetPriceModalFields = keyof IErrorsSetPriceModal;

const SetPriceModalFormObj: { [key in ISetPriceModalFields]: AnySchema<any, any, any> | Reference<unknown> | Lazy<any, any> } = {
  // pricing
  isFree: yup.boolean(),
  webPriceThb: yup.number().when(['isFree'], {
    is: false,
    then: (schema) =>
      schema.required('กรุณากรอกราคาอีบุ๊ก').min(1, 'กรุณากำหนดราคาขายอีบุ๊กขั้นต่ำ 1 แต่ไม่เกิน 5,000 บาท').max(5000, 'กรุณากำหนดราคาขายอีบุ๊กขั้นต่ำ 1 แต่ไม่เกิน 5,000 บาท'),
    otherwise: (schema) => schema.notRequired(),
  }),
  googlePriceTierId: yup.string().nullable(),
  applePriceTierId: yup.string().nullable(),
  haveDiscount: yup.boolean(),
  discountType: yup.string().when(['haveDiscount'], {
    is: (haveDiscount: boolean) => haveDiscount,
    then: (schema) => schema.default(DISCOUNT_VALUE.NO_DISCOUNT),
  }),
  discountPrice: yup
    .number()
    .nullable()
    .typeError('ระบุราคาส่วนลด')

    .transform((value) => (Number.isNaN(value) || value === 'NaN' ? undefined : value))
    .when(['haveDiscount', 'discountType'], {
      is: (haveDiscount: boolean, discountType: string) => haveDiscount && discountType === DISCOUNT_VALUE.SPECIFY_PRICE,
      then: (schema) =>
        schema.required('กรุณากรอกส่วนลด').when(SET_PRICE_MODAL_FIELD_KEYS.WEB_PRICE_THB, (webPriceThb, schema) => {
          const maxDiscountPrice = webPriceThb;
          return schema.max(maxDiscountPrice, `ไม่สามารถระบุเกิน ${numberWithCommasWithDecimalPlace(maxDiscountPrice ?? 0)} ได้`);
        }),
    }),

  discountRatio: yup
    .number()
    .nullable()
    .typeError('ระบุเปอร์เซ็นต์ส่วนลด')
    .transform((value) => (Number.isNaN(value) || value === 'NaN' ? undefined : value))
    .max(100, 'ไม่สามารถระบุเปอร์เซ็นต์ส่วนลดมากกว่า 100% ได้')
    .when(['haveDiscount', 'discountType'], {
      is: (haveDiscount: boolean, discountType: string) => haveDiscount && discountType === DISCOUNT_VALUE.PERCENT_DISCOUNT,
      then: (schema) => schema.required('กรุณากรอกส่วนลด'),
    }),
  discountGooglePriceTierId: yup.string().nullable(),
  discountApplePriceTierId: yup.string().nullable(),
  isHasDiscountEndDate: yup.boolean(),
  discountStartDate: yup.date().when(SET_PRICE_MODAL_FIELD_KEYS.HAVE_DISCOUNT, { is: true, then: (schema) => schema.required() }),
  discountEndDate: yup
    .date()
    .when([SET_PRICE_MODAL_FIELD_KEYS.HAVE_DISCOUNT, SET_PRICE_MODAL_FIELD_KEYS.IS_HAS_DISCOUNT_END_DATE], {
      is: (haveDiscount: boolean, isHasEndDate: boolean) => haveDiscount && isHasEndDate,
      then: (schema) => schema.required(),
    })
    .when(SET_PRICE_MODAL_FIELD_KEYS.IS_HAS_DISCOUNT_END_DATE, {
      is: false,
      then: (schema) => schema.nullable(),
    }),
};

export default SetPriceModalFormObj;
