import { useCallback, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';

import PublishBundleErrorModal, { IErrorItem } from '../../app/bundles/modals/PublishBundleErrorModal';
import BundleDetailPresenter from '../../app/bundles/presenters/BundleDetailPresenter';
import BundleFormObj, { BUNDLE_FIELD_KEYS, IBundleFields } from '../../app/bundles/validates/bundleSchema';
import Breadcrumbs from '../../components/Breadcrumbs';
import Card from '../../components/Card';
import LeavePageAlert from '../../components/LeavePageAlert';
import Loading from '../../components/Loading';
import ToastComponent, { IToastType } from '../../components/Toast/ToastComponent';
import { UploadEbookBundleInput } from '../../core/graphql/types';
import { localStorageItemsKeyName } from '../../core/localStorageItemsKeyName';
import useMutationPintoUploadEbookBundle from '../../hooks/useMutationPintoUploadEbookBundle';
import { convertFileToBase64, downloadCSV } from '../../utils/blob';
import useHookForm from '../../utils/form/useHookForm';
import { getItemLocalStorage, removeItemLocalStorage } from '../../utils/localStorage';

const CSV_URL_PATH = '../../a/template/Create_Bundle_Template.csv';

const CreateBundleController = () => {
  const history = useHistory();

  const { methods, onValidate, setValue, watch } = useHookForm<IBundleFields>({
    objectShapeForm: BundleFormObj,
    mode: 'onChange',
  });

  const [title] = watch([BUNDLE_FIELD_KEYS.TITLE]);

  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
  const [selectedCSVFile, setSelectedCSVFile] = useState<File | undefined>(undefined);

  const { onMutatePintoUploadEbookBundle, isLoadingMutatePintoUploadEbookBundle } = useMutationPintoUploadEbookBundle();

  const [errorItems, setErrorsItems] = useState<IErrorItem[] | undefined>(undefined);
  const [showErrorAlert, setShowErrorAlert] = useState<boolean>(true);

  const onSelectCSVFile = useCallback(
    (file: File | undefined) => {
      setSelectedCSVFile(file);
      setValue(BUNDLE_FIELD_KEYS.FILE_NAME, file?.name as never, {
        shouldValidate: true,
      });
    },
    [setValue],
  );

  const onSubmitScheduledDiscount = useCallback(async () => {
    if (!selectedCSVFile || !title) return;

    const fileBase64 = await convertFileToBase64(selectedCSVFile);
    const input = {
      adminNote: title,
      fileName: selectedCSVFile?.name,
      file: fileBase64,
    };

    const res = await onMutatePintoUploadEbookBundle(input as UploadEbookBundleInput);
    if (res.success) {
      setShowErrorAlert(false);
      ToastComponent({ label: 'Publish successfully ', type: IToastType.SUCCESS });
      history.push('/bundles');
    } else {
      const errItems: IErrorItem[] | undefined | string = getItemLocalStorage(localStorageItemsKeyName.UPLOAD_BUNDLE_ERROR_ITEMS) || undefined;
      if (errItems) {
        setErrorsItems(
          JSON.parse(errItems)?.detail?.invalidInputs?.map(({ no, errors }: any) => ({
            row: no,
            errorMessage: errors,
          })) as IErrorItem[],
        );
        setShowErrorModal(true);
        return;
      }
    }
  }, [history, onMutatePintoUploadEbookBundle, selectedCSVFile, title]);

  const handlePublishValidate = useCallback(async () => {
    let isValid = false;
    isValid = await onValidate();

    if (isValid) onSubmitScheduledDiscount();
  }, [onSubmitScheduledDiscount, onValidate]);

  const onClickDownloadCSVTemplate = useCallback(() => {
    downloadCSV(CSV_URL_PATH, 'create_bundle_template.csv');
  }, []);

  return (
    <div>
      <>
        {isLoadingMutatePintoUploadEbookBundle && <Loading />}
        <LeavePageAlert showConfirmLeavePageAlert={showErrorAlert} message="All change will be discarded, Are you sure to leave this page ?" />
        {showErrorModal && (
          <PublishBundleErrorModal
            onConFirm={() => {
              removeItemLocalStorage(localStorageItemsKeyName.UPLOAD_BUNDLE_ERROR_ITEMS);
              setShowErrorModal(false);
            }}
            errorItems={errorItems}
          />
        )}
        <div className="sticky top-0 z-50 px-5 bg-componentsBgGrouped02">
          {/* section: breadcrumbs */}
          <Breadcrumbs items={[{ name: 'Bundle', url: '/bundles' }, { name: 'Create New Bundle' }]} />

          {/* section: title */}
          <div className="flex justify-between pt-5">
            <h1 className="font-dbh text-[28px]">Create New Bundle</h1>

            <div className="space-x-12">
              <Link to="/bundles">
                <button className="btn btn-outline">CANCEL</button>
              </Link>
              <button className="btn btn-error" onClick={handlePublishValidate}>
                Publish
              </button>
            </div>
          </div>

          {/* section: main */}
          <div className="mb-0 divider opacity-80" />
        </div>
        <Card className="flex flex-row w-full space-x-24">
          <div className="flex-1">
            <FormProvider {...methods}>
              <BundleDetailPresenter selectedCSVFile={selectedCSVFile} onSelectCSVFile={onSelectCSVFile} onClickDownloadCSVTemplate={onClickDownloadCSVTemplate} />
            </FormProvider>
          </div>

          {/* section: right */}
          <div className="flex-[0.8] p-12"></div>
        </Card>
      </>
    </div>
  );
};

export default CreateBundleController;
