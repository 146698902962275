/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const library = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path
      fill="#8C8C8C"
      fillRule="evenodd"
      d="M36 5.86a9.485 9.485 0 018 0v36.28a9.363 9.363 0 01-7.898.046L36 42.14V5.86zm-33 0a9.485 9.485 0 018 0v36.28a9.363 9.363 0 01-7.898.046L3 42.14V5.86zm11 0a9.485 9.485 0 018 0v36.28a9.363 9.363 0 01-7.898.046L14 42.14V5.86zm11 0a9.485 9.485 0 018 0v36.28a9.363 9.363 0 01-7.898.046L25 42.14V5.86z"
    />
  </svg>
);

export default library;
