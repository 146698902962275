import dayjs from 'dayjs';
import orderBy from 'lodash/orderBy';
import { useState } from 'react';
import { Calendar, CalendarProps, Event } from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';

import { localizer } from '../../../utils/dayjs';
import { IFormSection, IScheduleOption } from '../hooks/useFormSections';

interface IResource {
  sectionId: string;
  scheduleIndex: number;
}

const DragAndDropCalendar = withDragAndDrop<Event, IResource>(Calendar as React.ComponentType<CalendarProps<Event, IResource>>);

export type IStateSchemaSections = Pick<Event, 'title' | 'start' | 'end'>;

export interface IPropsEndOfChapterCalendar {
  formSections: IFormSection[];
  onUpdateScheduleInSection: (sectionId: string, scheduleIndex: number, editedSchedule: IScheduleOption) => void;
  onDeleteScheduleInSection: (sectionId: string, scheduleIndex: number) => void;
}

const getEventsFromSections = (formSections: IFormSection[]): Event[] => {
  const stateSchemaSections: Event[] = [];
  formSections.map((section) => {
    if (!section.isActivated) return;

    if ((section.schedules ?? []).length === 0) {
      stateSchemaSections.push({
        title: `${section.note}`,
        allDay: true,
        start: dayjs().set('year', 0).toDate(),
        end: dayjs().add(20, 'year').toDate(),
        resource: {
          sectionId: section._id,
        },
      });
      return;
    }
    section.schedules?.map((schedule, scheduleIndex) =>
      stateSchemaSections.push({
        title: `${scheduleIndex + 1}.${section.note}`,
        allDay: true,
        start: schedule.start ?? undefined,
        end: schedule.end ?? undefined,
        resource: {
          sectionId: section._id,
          scheduleIndex,
        },
      }),
    );
  });
  return orderBy(stateSchemaSections, ['title', 'start'], ['asc']);
};

const EndOfChapterCalendar = (props: IPropsEndOfChapterCalendar) => {
  const { formSections, onUpdateScheduleInSection } = props;
  const [dragingEvent, setDragingEvent] = useState<Event | undefined>(undefined);
  return (
    <DragAndDropCalendar
      resizable
      selectable
      popup
      localizer={localizer}
      draggableAccessor={(event) => event.resource.scheduleIndex !== undefined}
      onDropFromOutside={({ start, end }) => {
        if (!dragingEvent) return;
        onUpdateScheduleInSection((dragingEvent.resource as Event['resource']).sectionId, (dragingEvent.resource as Event['resource']).scheduleIndex, {
          start: start as Date,
          end: end as Date,
        });
        setDragingEvent(undefined);
      }}
      handleDragStart={setDragingEvent}
      events={getEventsFromSections(formSections)}
      onEventResize={({ event, start, end }) => {
        onUpdateScheduleInSection((event.resource as Event['resource']).sectionId, (event.resource as Event['resource']).scheduleIndex, { start: start as Date, end: end as Date });
      }}
      onEventDrop={({ event, start, end }) => {
        onUpdateScheduleInSection((event.resource as Event['resource']).sectionId, (event.resource as Event['resource']).scheduleIndex, { start: start as Date, end: end as Date });
      }}
      views={['month', 'agenda']}
      startAccessor="start"
      endAccessor={(event) => {
        return dayjs(event.end).endOf('day').toDate();
      }}
      style={{ height: '100vh', minHeight: 600 }}
    />
  );
};

export default EndOfChapterCalendar;
