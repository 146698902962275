import { FC } from 'react';

import Image from './Image';

export interface IPropsLibraryAdItem {
  adTitle?: string;
  title: string;
  subTitle: string;
  coverImage: string;
  placeholderColor?: string;
}

const LibraryAdItem: FC<IPropsLibraryAdItem> = (props: IPropsLibraryAdItem) => {
  return (
    <div className="flex flex-1 cursor-pointer">
      <div className="w-[61px] md:w-[96px] rounded-8">
        <Image
          className="w-[61px] h-[86px] md:w-[96px] md:h-[136px] rounded-8"
          src={props.coverImage}
          alt={props.title}
          aspectRatio={2.325}
          placeholderColor={props.placeholderColor}
        />
      </div>
      <div className="flex flex-col m-0 ml-16 md:m-16">
        <div className="text-[11px] md:text-12 text-colorsBrandRed01Primary">{props.adTitle}</div>
        <div className="mt-2 md:mt-4 text-12 md:text-16 text-systemGrays01LabelPrimary">{props.title}</div>
        <div className="mt-2 md:mt-4 text-[11px] md:text-12 text-systemGrays02LabelSecondary">{props.subTitle}</div>
      </div>
    </div>
  );
};

export default LibraryAdItem;
