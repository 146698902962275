import classNames from 'classnames';
import { useCallback, useState } from 'react';

import images from '../../assets/images';
import { cookieName } from '../../core/cookieName';
import { useLoginMutation } from '../../core/graphql/operation-hooks';
import { setCookie } from '../../utils/cookie';

const LoginForm = () => {
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [loginMutate, { loading }] = useLoginMutation();

  const login = useCallback(
    async (username, password) => {
      // loading
      const { data } = await loginMutate({
        variables: {
          input: {
            username,
            password,
          },
        },
      });
      // loading

      if (data?.login) {
        setCookie(cookieName.ACCESS_TOKEN, data.login.accessToken ?? '');
        window.location.reload();
      }
    },
    [loginMutate],
  );
  return (
    <div
      className="flex flex-col justify-center min-h-screen bg-componentsBgGrouped01 sm:py-12"
      style={{ background: `url(${images.bgLogin})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center center' }}
    >
      <div className="w-full p-8 mx-auto md:max-w-[360px] mt-[25vh]">
        <div className="w-full divide-y rounded-lg shadow bg-componentsBgGrouped02 divide-componentsDivider">
          <div className="px-24 py-32">
            <label htmlFor="username" className="block pb-8 text-sm font-dbh text-systemGrays02LabelSecondary">
              Username / E-mail
            </label>
            <input
              type="text"
              id="username"
              className="w-full px-3 py-8 mb-16 text-sm border border-none rounded-lg focus:outline-none bg-systemGrays05FillPrimary text-systemGrays01LabelPrimary"
              onChange={(e) => setUsername(e.target.value)}
            />
            <label htmlFor="password" className="block pb-8 text-sm font-dbh text-systemGrays02LabelSecondary">
              Password
            </label>
            <input
              type="password"
              id="password"
              onKeyDown={(e) => e.keyCode === 13 && login(username, password)}
              className="w-full px-3 py-8 mb-16 text-sm border border-none rounded-lg focus:outline-none bg-systemGrays05FillPrimary text-systemGrays01LabelPrimary"
              onChange={(e) => setPassword(e.target.value)}
            />
            <button
              disabled={loading}
              type="button"
              className={classNames(
                'focus:outline-none inline-block w-full py-8 text-sm font-bold text-center text-white transition duration-200 rounded-lg shadow-sm hover:shadow-md font-dbh',
                {
                  '!bg-systemGrays02LabelSecondary': loading,
                  'bg-blue-500': !loading,
                },
              )}
              onClick={() => login(username, password)}
            >
              {loading && <span className="inline-block mr-2">loading...</span>}

              {!loading && (
                <>
                  <span className="inline-block mr-2">Login</span>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="inline-block w-4 h-4">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 8l4 4m0 0l-4 4m4-4H3" />
                  </svg>
                </>
              )}
            </button>
          </div>
          {/* <div className="py-5">
          <div className="grid grid-cols-2 gap-1">
            <div className="text-center sm:text-left whitespace-nowrap">
              <button className="px-24 py-4 mx-5 text-sm text-gray-500 transition duration-200 rounded-lg cursor-pointer hover:bg-componentsBgGrouped02 focus:outline-none focus:bg-divdivide-componentsDivider focus:ring-2 focus:ring-gray-400 focus:ring-opacity-50 ring-inset">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="inline-block w-4 h-4 align-text-top">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 11V7a4 4 0 118 0m-4 8v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2z"></path>
                </svg>
                <span className="inline-block ml-1">Forgot Password</span>
              </button>
            </div>
            <div className="text-center sm:text-right whitespace-nowrap">
              <button className="px-24 py-4 mx-5 text-sm text-gray-500 transition duration-200 rounded-lg cursor-pointer hover:bg-componentsBgGrouped02 focus:outline-none focus:bg-divdivide-componentsDivider focus:ring-2 focus:ring-gray-400 focus:ring-opacity-50 ring-inset">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="inline-block w-4 h-4 align-text-bottom ">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M18.364 5.636l-3.536 3.536m0 5.656l3.536 3.536M9.172 9.172L5.636 5.636m3.536 9.192l-3.536 3.536M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-5 0a4 4 0 11-8 0 4 4 0 018 0z"></path>
                </svg>
                <span className="inline-block ml-1">Help</span>
              </button>
            </div>
          </div>
        </div> */}
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
