/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const link = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path
      fill="#8C8C8C"
      fillRule="evenodd"
      d="M35 31a7 7 0 000-14h-6a2 2 0 01-2-2v-2a2 2 0 012-2h6c7.18 0 13 5.82 13 13s-5.82 13-13 13h-6a2 2 0 01-2-2v-2a2 2 0 012-2h6zm-22 6C5.82 37 0 31.18 0 24s5.82-13 13-13h6a2 2 0 012 2v2a2 2 0 01-2 2h-6a7 7 0 000 14h6a2 2 0 012 2v2a2 2 0 01-2 2h-6zm2-16h18a2 2 0 012 2v2a2 2 0 01-2 2H15a2 2 0 01-2-2v-2a2 2 0 012-2z"
    />
  </svg>
);

export default link;
