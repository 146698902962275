import BookCoverSectionItem, { IPropsBookCoverSectionItem } from './BookCoverSectionItem';
import CoverSectionScrollable from './CoverSectionScrollable';
import EbookCoverSectionItem, { IPropsEbookCoverSectionItem } from './EbookCoverSectionItem';
import Icon from './Icon';

export interface IPropsSuggestionProductSection {
  items: (IPropsBookCoverSectionItem | IPropsEbookCoverSectionItem)[];
  title: string;
  targetLink?: string;
}

const SuggestionProductSection = (props: IPropsSuggestionProductSection): JSX.Element => {
  return (
    <div className="container">
      <div className="py-24">
        <div className="px-16 pb-12">
          <div className="text-20 md:text-24">
            <h3 className="mr-24 text-systemGrays01LabelPrimary">{props.title}</h3>
            {props.targetLink && (
              <span>
                <a target="_blank" href={props.targetLink} rel="noreferrer">
                  <span className="inline-flex items-center text-14 lg:text-18 text-systemGrays02LabelSecondary">
                    <span className="mr-8">ดูเพิ่มเติม</span>
                    <Icon name="caretRight" size="16px" color="var(--systemGrays02LabelSecondary)" />
                  </span>
                </a>
              </span>
            )}
          </div>
        </div>
        <CoverSectionScrollable alwayDisplayCount={6}>
          {props.items.map((item) => {
            switch (item.__typename) {
              case 'Book':
                return (
                  <div key={item._id} className="px-12 w-[190px]">
                    <BookCoverSectionItem {...item} />
                  </div>
                );

              case 'Ebook':
                return (
                  <div key={item._id} className="px-12 w-[190px]">
                    <EbookCoverSectionItem {...item} />
                  </div>
                );

              default:
                return <div />;
            }
          })}
        </CoverSectionScrollable>
      </div>
    </div>
  );
};

export default SuggestionProductSection;
