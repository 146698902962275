import { useEditorState } from '@aeaton/react-prosemirror/EditorProvider';
import { useDebounce } from '@aeaton/react-prosemirror/hooks/use-debounce';
import { PropsWithChildren, ReactElement, useEffect } from 'react';

import { IEditorState } from './Editor';

const ChangeStateHandle = ({
  handleChange,
  debounce = 0,
}: PropsWithChildren<{
  handleChange: (value: IEditorState) => void;
  debounce?: number;
}>): ReactElement | null => {
  const state = useEditorState();
  const debouncedDoc = useDebounce(state, debounce);

  useEffect(() => {
    handleChange(debouncedDoc);
  }, [handleChange, debouncedDoc]);

  return null;
};

export default ChangeStateHandle;
