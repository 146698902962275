import { useState } from 'react';

import { usePreviewSectionQuery } from '../../../core/graphql/operation-hooks';
import { Book, Ebook } from '../../../core/graphql/types';
import LibraryAdItem from '../../../fictionlog/components/LibraryAdItem';

export interface IPropsPreviewLibraryAdModal {
  sectionId: string;
  onClose?: () => void;
}

const PreviewLibraryAdModal = (props: IPropsPreviewLibraryAdModal) => {
  const { sectionId, onClose } = props;
  const { data: queryPreviewSection, loading: isLoadingPreviewLibraryAd } = usePreviewSectionQuery({ variables: { id: sectionId } });
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const activeItem = queryPreviewSection?.previewSection.unionItems[activeIndex] as Book | Ebook;
  const previewSection = queryPreviewSection?.previewSection;
  return (
    <div className="modal modal-open">
      <div className="modal-box max-w-[960px] max-h-[80vh] overflow-y-scroll">
        <div className="flex flex-row items-center justify-between pb-16">
          <div>
            <button className="btn" onClick={() => setActiveIndex(Math.floor(Math.random() * (queryPreviewSection?.previewSection.unionItems ?? []).length))}>
              Random new item
            </button>
          </div>

          <button onClick={onClose} className="rounded-full flex justify-center items-center btn-outline w-[36px] h-[36px]">
            X
          </button>
        </div>

        {isLoadingPreviewLibraryAd && <div className="p-20">loading...</div>}
        {!isLoadingPreviewLibraryAd && previewSection && activeItem && (
          <LibraryAdItem
            adTitle={previewSection.title ?? ''}
            title={activeItem?.title ?? ''}
            subTitle={`${activeItem?.categoryTag?.name} · ${activeItem?.contentRating?.name ?? ''}`}
            coverImage={activeItem.coverImage ?? ''}
            placeholderColor={activeItem?.placeholderBackgroundColor ?? 'var(--systemGrays05FillPrimary)'}
          />
        )}
      </div>
    </div>
  );
};

export default PreviewLibraryAdModal;
