export interface IPropsErrorModal {
  onConFirm?: () => void;
  title?: string;
  errorMessages: string | string[];
  confirmText?: string;
}

const ErrorModal = (props: IPropsErrorModal) => {
  const { onConFirm, confirmText, title, errorMessages } = props;

  return (
    <>
      <div className="modal modal-open">
        <div className="modal-box relative">
          <h3 className="text-lg font-bold font-dbh">{title || 'เกิดข้อผิดพลาด'}</h3>

          <div className="p-12 bg-red-100 rounded-md mt-12">
            <ul className="prose">
              {Array.isArray(errorMessages) ? (
                errorMessages.map((error, index) => (
                  <li key={index} className="py-4 font-tt">
                    - {error}
                  </li>
                ))
              ) : (
                <li className="py-4 font-tt">- {errorMessages}</li>
              )}
            </ul>
          </div>

          <div className="w-full flex justify-end space-x-4 pt-24">
            <button className="btn btn-error" onClick={onConFirm}>
              {confirmText || 'OK'}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ErrorModal;
