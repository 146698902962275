/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const image = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path
      fill="#8C8C8C"
      fillRule="evenodd"
      d="M8.619 5h30.762A3.62 3.62 0 0143 8.619v30.762A3.62 3.62 0 0139.381 43H8.619A3.62 3.62 0 015 39.381V8.619A3.62 3.62 0 018.619 5zM37 12a1 1 0 00-1-1H12a1 1 0 00-1 1v22l6.541-6.411a1 1 0 011.059-.22l3.89 1.493a1 1 0 001.068-.229l8.052-8.114a1 1 0 011.166-.185L37 22.5V12zM19 23a4 4 0 110-8 4 4 0 010 8z"
    />
  </svg>
);

export default image;
