/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const socialFb = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path
      fill="#8C8C8C"
      fillRule="evenodd"
      d="M9.6 6h28.8A3.6 3.6 0 0142 9.6v28.8a3.6 3.6 0 01-3.6 3.6H9.6A3.6 3.6 0 016 38.4V9.6A3.6 3.6 0 019.6 6zm11.176 14.102H16v5.16h4.776V38h5.493V25.263h4.82l.672-5.161H26.27V16.26c0-.911.801-1.648 1.79-1.648H32v-4.576a7.085 7.085 0 00-.716-.036h-4.06c-3.561 0-6.448 2.655-6.448 5.93v4.172z"
    />
  </svg>
);

export default socialFb;
