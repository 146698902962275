import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { FeedPref } from '../../../core/graphql/types';

import LibraryFeedPref from './BadgeLibraryAdFeedPref';

export type IPropsLibraryTableItem = {
  id?: string;
  pref: FeedPref;
  isActivated?: boolean;
  updatedAtInFormat?: string;
  onUpdateRecord: (isActivated: boolean, pref: FeedPref) => Promise<void>;
};

const LibraryTableItem = (props: IPropsLibraryTableItem): JSX.Element => (
  <tr className="hover">
    <th>
      <LibraryFeedPref pref={props.pref} />
    </th>
    <td>{props.updatedAtInFormat}</td>
    <td>
      <input
        onChange={() => props.onUpdateRecord(!props.isActivated, props.pref)}
        checked={props.isActivated}
        defaultChecked={props.isActivated}
        type="checkbox"
        className={classNames('toggle', {
          'bg-colorsBrandTurquoise01Primary border-colorsBrandTurquoise01Primary': props.isActivated,
          'bg-colorsBrandRed01Primary border-bandredbg-colorsBrandRed01Primary': !props.isActivated,
        })}
      />
    </td>
    <td>
      <Link className="underline" to={`/suggestProducts/libraryAd/edit?${props.id ? `id=${props.id}` : `pref=${props.pref}`}`}>
        Edit
      </Link>
    </td>
  </tr>
);

export default LibraryTableItem;
