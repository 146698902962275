/* eslint-disable react-hooks/rules-of-hooks */
import { isNull, omit, uniqBy, pick, pickBy, get } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { MdAlarm, MdSave, MdTaskAlt, MdUpload } from 'react-icons/md';
import { Link, useLocation } from 'react-router-dom';

import useCoverAndBanner from '../../app/ebooks/hooks/useCoverAndBanner';
import useEbookFiles from '../../app/ebooks/hooks/useEbookFiles';
import useUploadFiles from '../../app/ebooks/hooks/useUploadFiles';
import useUsersProductSets from '../../app/ebooks/hooks/useUsersProductSet';
import SchedulePublishModal from '../../app/ebooks/modals/SchedulePublishModal';
import AdditionalInfoPresenter from '../../app/ebooks/presenters/AdditionalInfoPresenter';
import EditEbookCoverPresenter from '../../app/ebooks/presenters/EditEbookCoverPresenter';
import EditEbookDetailPresenter from '../../app/ebooks/presenters/EditEbookDetailPresenter';
import EditEbookFilesPresenter from '../../app/ebooks/presenters/EditEbookFilesPresenter';
import EditEbookPricePresenter from '../../app/ebooks/presenters/EditEbookPricePresenter';
import EditEbookUserCreatorPresenter from '../../app/ebooks/presenters/EditEbookUserCreatorPresenter';
import EbookBuilderFormObj, { EBOOK_BUILDER_FIELD_KEYS, IEbookBuilderFields } from '../../app/ebooks/validates/ebookBuilderSchema';
import ConfirmModal from '../../app/modals/ConfirmModal';
import ErrorModal from '../../app/modals/ErrorModal';
import LoadingModal from '../../app/modals/LoadingModal';
import useStatePriceTier from '../../app/modals/setPriceModal/hook/useStatePriceTier';
import SetPriceModal, { DISCOUNT_VALUE } from '../../app/modals/setPriceModal/SetPriceModal';
import Alert from '../../components/Alert';
import Breadcrumbs from '../../components/Breadcrumbs';
import Button from '../../components/Button';
import CropImageModal from '../../components/CropImageModal';
import getContentRawState from '../../components/editor/converters/getContentRawState';
import { IEditorState } from '../../components/editor/Editor';
import ToastComponent, { IToastType } from '../../components/Toast/ToastComponent';
import { IModalNames } from '../../core/context/modalReducer';
import {
  ContentType,
  CorrectionEbookStatus,
  CreateAndSubmitReviewEbookInput,
  CreateDraftEbookInput,
  EbookFileType,
  EbookStatus,
  EbookWritingType,
  EditDraftEbookInput,
  EditEbookInput,
  PintoCategory,
  // EbookStatus,
  SubmitReviewEbookEbookFileInput,
  SubmitReviewEbookInput,
  UploadEbookImageInput,
  UploadEbookImageType,
} from '../../core/graphql/types';
import useModal from '../../core/hook/context/useModal';
import useEbookOptions from '../../hooks/useEbookOptions';
import useMutationCreateAndSubmitReviewEbook from '../../hooks/useMutationCreateAndSubmitReviewEbook';
import useMutationCreateDraftEbook from '../../hooks/useMutationCreateDraftEbook';
import useEditDraftEbook from '../../hooks/useMutationEditDraftEbook';
import useMutationEditEbook from '../../hooks/useMutationEditEbook';
import useMutationPintoUploadImage from '../../hooks/useMutationPintoUploadImage';
import useMutationSubmitReviewDraftEbook from '../../hooks/useMutationSubmitReviewDraftEbook';
import useQueryPendingEbookInSubscriptionTitle from '../../hooks/useQueryPendingEbookInSubscriptionTitle';
import useQueryPintoEbook from '../../hooks/useQueryPintoEbook';
// import useMutationSubmitReviewDraftEbook from '../../hooks/useMutationSubmitReviewDraftEbook';
import { addDaysFromDate, endOf, minusDateEbook, startOf } from '../../utils/dayjs';
import useHookForm from '../../utils/form/useHookForm';
import { MAPPED_EBOOK_STATUS } from '../../utils/pintoStatus';
import scrollToView from '../../utils/scrollToView';
import { ErrorTypes } from '../../utils/validator';

enum ebookFileKey {
  PDF_MAIN_FILE = 'main-pdf',
  PDF_SAMPLE_FILE = 'sample-pdf',
  EPUB_MAIN_FILE = 'main-epub',
  EPUB_SAMPLE_FILE = 'sample-epub',
}

export enum PRICE_VALUE {
  MANUAL = 'manual',
  FREE = 'free',
}

export const PRICE_OPTIONS = [
  { label: 'กำหนดเอง', value: PRICE_VALUE.MANUAL },
  { label: 'อีบุ๊กฟรี', value: PRICE_VALUE.FREE },
];

export type ISupportUploadFileType = 'PDFFile' | 'PDFSampleFile' | 'EPUBFile' | 'EPUBSampleFile';

export interface IPropsOnConfirmSetPriceModal {
  ebookPrice?: number | null;
  isFree: boolean;
  haveDiscount?: boolean;
  discountType?: DISCOUNT_VALUE;
  discountPrice?: number;
  discountRatio?: number;
  discountStartDate?: Date;
  discountEndDate?: Date;
  googlePriceTierId?: string;
  applePriceTierId?: string;
  discountGooglePriceTierId?: string;
  discountApplePriceTierId?: string;
  isHasDiscountEndDate?: boolean;
}

function EditEbookPage() {
  // query and resume state
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const ebookId = searchParams.get('id');
  const { onFetchPintoEbook, isLoadingPintoEbook, pintoEbookData } = useQueryPintoEbook();
  const { onFetchPendingEbookInSubscriptionTitle, pendingEbookInSubscriptionTitleData } = useQueryPendingEbookInSubscriptionTitle();
  const { languageOptions, priceTierOptions } = useEbookOptions();
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [hasPendingSubscriptionTitle, setHasPendingSubscriptionTitle] = useState<boolean>(false);
  const [subscriptionTitle, setSubscriptionTitle] = useState('');

  // mutation
  // mutation: create ebook
  const { createAndSubmitReviewEbook } = useMutationCreateAndSubmitReviewEbook();
  const { isLoadingCreateDraftEbook, createDraftEbook } = useMutationCreateDraftEbook();
  // mutation: edit ebook
  const { submitReviewDraftEbook } = useMutationSubmitReviewDraftEbook();
  const { isLoadingEditDraftEbook, editDraftEbook } = useEditDraftEbook();
  const { editEbook } = useMutationEditEbook();

  // mutation: upload image, file
  const { isLoadingMutatePintoUploadImage, onMutatePintoUploadImage } = useMutationPintoUploadImage();
  const { handleUploadFile } = useUploadFiles();
  const { openModal, removeModal } = useModal();
  const {
    methods,
    onValidate,
    formState: { errors },
    setError,
    clearErrors,
  } = useHookForm<IEbookBuilderFields>({
    objectShapeForm: EbookBuilderFormObj,
    mode: 'onChange',
    //resume state
    defaultValues: {},
  });
  const {
    selectedMainPdfFile,
    onSelectMainPdfFile,
    selectedSamplePdfFile,
    onSelectSamplePdfFile,
    selectedMainEpubFile,
    onSelectMainEpubFile,
    selectedSampleEpubFile,
    onSelectSampleEpubFile,
  } = useEbookFiles();
  const {
    cropImageBannerBase64,
    cropImageBase64,
    cropImageCoverBase64,
    cropImageHeight,
    cropImageWidth,
    onCancelCropImage,
    onConfirmCropImage,
    onSelectBannerFile,
    onSelectCoverFile,
    selectedBannerFile,
    selectedCoverFile,
  } = useCoverAndBanner();

  console.log(errors);
  const { setValue, watch } = methods;

  const mappedEbookFiles = useMemo(() => {
    const correctionFiles =
      pintoEbookData?.correction?.correctionEbookFiles?.map((file) => ({
        mainFileUrl: file?.mainFileUrl ?? '',
        mainFilename: file?.mainFilename ?? '',
        sampleFileUrl: file?.sampleFileUrl ?? '',
        sampleFilename: file?.sampleFilename ?? '',
        type: file?.type ?? '',
      })) ?? [];
    const ebookFiles =
      pintoEbookData?.ebookFiles?.map((file) => ({
        mainFileUrl: file?.mainFileUrl ?? '',
        mainFilename: file?.mainFilename ?? '',
        sampleFileUrl: file?.sampleFileUrl ?? '',
        sampleFilename: file?.sampleFilename ?? '',
        type: file?.type ?? '',
      })) ?? [];

    const correctionPdfFile = correctionFiles.find((file) => file.type === EbookFileType.Pdf);
    const correctionEpubFile = correctionFiles.find((file) => file.type === EbookFileType.Epub);
    const ebookPdfFile = ebookFiles.find((file) => file.type === EbookFileType.Pdf);
    const ebookEpubFile = ebookFiles.find((file) => file.type === EbookFileType.Epub);

    return [
      {
        mainFileUrl: correctionPdfFile?.mainFileUrl || ebookPdfFile?.mainFileUrl || '',
        mainFilename: correctionPdfFile?.mainFilename || ebookPdfFile?.mainFilename || '',
        sampleFileUrl: correctionPdfFile?.sampleFileUrl || ebookPdfFile?.sampleFileUrl || '',
        sampleFilename: correctionPdfFile?.sampleFilename || ebookPdfFile?.sampleFilename || '',
        type: EbookFileType.Pdf,
      },
      {
        mainFileUrl: correctionEpubFile?.mainFileUrl || ebookEpubFile?.mainFileUrl || '',
        mainFilename: correctionEpubFile?.mainFilename || ebookEpubFile?.mainFilename || '',
        sampleFileUrl: correctionEpubFile?.sampleFileUrl || ebookEpubFile?.sampleFileUrl || '',
        sampleFilename: correctionEpubFile?.sampleFilename || ebookEpubFile?.sampleFilename || '',
        type: EbookFileType.Epub,
      },
    ];
  }, [pintoEbookData?.correction?.correctionEbookFiles, pintoEbookData?.ebookFiles]);

  const groupedCategories: PintoCategory[] = useMemo(() => {
    if (pintoEbookData) {
      if (pintoEbookData.correction?.category) {
        const { category: _category } = pintoEbookData.correction;
        const groupData = [_category?.parent?.parent, _category?.parent, _category].filter((item) => !!item) as PintoCategory[];
        return groupData || [];
      }
      const groupData = [pintoEbookData?.category?.parent?.parent, pintoEbookData?.category?.parent, pintoEbookData.category].filter((item) => !!item) as PintoCategory[];
      return groupData || [];
    }
    return [];
  }, [pintoEbookData]);

  const ebStatus = useMemo(() => pintoEbookData?.status, [pintoEbookData?.status]);

  const getDiscountStartDateForMutation = useCallback((startDate: Date | null) => {
    if (!startDate) return null;

    const dateData = new Date(startDate);
    if (dateData.getHours() === 0 && dateData.getMinutes() === 0 && dateData.getSeconds() === 0) {
      return startOf(startDate, 'day').toISOString();
    }

    return dateData.toISOString();
  }, []);

  /**
   * NOTES: backend use logic check endedDate is start of day (endDate+1 00.00.00),
   * so we should convert from end of day (endDate 23.59.59) for backend can check properly
   */
  const getDiscountEndDateForMutation = useCallback((endDate: Date | null) => {
    if (!endDate) return null;

    const dateData = new Date(endDate);
    if (dateData.getHours() === 23 && dateData.getMinutes() === 59 && dateData.getSeconds() === 59) {
      const _endedAt = addDaysFromDate(0, undefined, endDate);
      return endOf(_endedAt, 'day').toISOString();
    }

    return dateData.toISOString();
  }, []);

  /**
   * NOTES: backend use logic check endedDate is start of day (endDate+1 00.00.00),
   * so we should convert to end of day (endDate 23.59.59) for date picker can render correctly.
   */
  const getDiscountEndDateFromQuery = useCallback((endDate?: string | null) => {
    if (!endDate) return null;

    const dateData = new Date(endDate);

    if (dateData.getHours() === 23 && dateData.getMinutes() === 59 && dateData.getSeconds() === 59) {
      const _endDate = endOf(endDate, 'day');
      return minusDateEbook(0, undefined, _endDate).toISOString();
    }

    return dateData.toISOString();
  }, []);

  useEffect(() => {
    if (ebookId) {
      onFetchPintoEbook(ebookId);
    }
  }, [ebookId, onFetchPintoEbook]);

  // resume form state
  useEffect(() => {
    if (pintoEbookData) {
      // user
      setValue(EBOOK_BUILDER_FIELD_KEYS.USER_ID as IEbookBuilderFields, pintoEbookData?.user._id);

      // detail
      setValue(EBOOK_BUILDER_FIELD_KEYS.TITLE as IEbookBuilderFields, pintoEbookData?.title);
      setValue(EBOOK_BUILDER_FIELD_KEYS.DESCRIPTION as IEbookBuilderFields, pintoEbookData?.description);
      setValue(EBOOK_BUILDER_FIELD_KEYS.COVER_IMAGE_URL as IEbookBuilderFields, pintoEbookData?.coverImageUrl);
      if (pintoEbookData?.bannerImageUrl) {
        setValue(EBOOK_BUILDER_FIELD_KEYS.BANNER_IMAGE_URL as IEbookBuilderFields, pintoEbookData?.bannerImageUrl);
      }
      setValue(EBOOK_BUILDER_FIELD_KEYS.CATEGORY_ID as IEbookBuilderFields, pintoEbookData.correction?.category?._id ?? pintoEbookData?.category?._id);
      setValue(EBOOK_BUILDER_FIELD_KEYS.WRITING_TYPE as IEbookBuilderFields, pintoEbookData.writingType ?? EbookWritingType.Bundle);
      setValue(
        EBOOK_BUILDER_FIELD_KEYS.IS_CARTOON_TYPE as IEbookBuilderFields,
        (pintoEbookData.correction?.category?.contentType || pintoEbookData.category?.contentType) === ContentType.Comic,
      );
      setValue(EBOOK_BUILDER_FIELD_KEYS.TAG_NAMES as IEbookBuilderFields, pintoEbookData?.tags);
      setValue(EBOOK_BUILDER_FIELD_KEYS.ORIGINAL_PRICE_THB as IEbookBuilderFields, pintoEbookData?.originalPriceThb);
      setValue(EBOOK_BUILDER_FIELD_KEYS.ISBN as IEbookBuilderFields, pintoEbookData?.isbn);
      setValue(EBOOK_BUILDER_FIELD_KEYS.IS_FREE as IEbookBuilderFields, pintoEbookData?.isFree);
      setValue(EBOOK_BUILDER_FIELD_KEYS.READING_TYPE as IEbookBuilderFields, pintoEbookData?.readingType);
      // setValue(EBOOK_BUILDER_FIELD_KEYS. as IEbookBuilderFields, pintoEbookData.category?.canSetPrice);

      // product set
      setValue(EBOOK_BUILDER_FIELD_KEYS.PRODUCT_SET_ID as IEbookBuilderFields, pintoEbookData?.productSetId);
      setValue(EBOOK_BUILDER_FIELD_KEYS.PRODUCT_SET_HAS_ITEM_VOL as IEbookBuilderFields, !!pintoEbookData?.productSetVol);
      setValue(EBOOK_BUILDER_FIELD_KEYS.PRODUCT_SET_VOL as IEbookBuilderFields, pintoEbookData?.productSetVol);
      // set selected product set item
      // if (pintoEbookData?.productSet) {
      //   const { productSet: ebookProductSet } = pintoEbookData;
      //   setSelectedProductSetItem({ id: ebookProductSet._id, label: ebookProductSet.title || 'ไม่มีชื่อเซ็ต', disabled: false });
      // }

      // cover price
      if (pintoEbookData?.coverPriceThb) {
        setValue(EBOOK_BUILDER_FIELD_KEYS.COVER_PRICE_THB as IEbookBuilderFields, pintoEbookData?.coverPriceThb);
        setValue(EBOOK_BUILDER_FIELD_KEYS.HAVE_COVER_PRICE as IEbookBuilderFields, !!pintoEbookData?.coverPriceThb);
      }

      // originalLanguage
      if (pintoEbookData?.originalLanguage) {
        setValue(EBOOK_BUILDER_FIELD_KEYS.ORIGINAL_LANGUAGE_CODE as IEbookBuilderFields, pintoEbookData.originalLanguage.code);
      }
      // google price tier
      setValue(EBOOK_BUILDER_FIELD_KEYS.GOOGLE_ORIGINAL_PRICE_TIER_ID as IEbookBuilderFields, pintoEbookData?.isFree ? undefined : pintoEbookData?.googleOriginalPriceTier?._id);

      // apple price tier
      setValue(EBOOK_BUILDER_FIELD_KEYS.APPLE_ORIGINAL_PRICE_TIER_ID as IEbookBuilderFields, pintoEbookData?.isFree ? undefined : pintoEbookData?.appleOriginalPriceTier?._id);

      // translated language
      if (pintoEbookData?.language) {
        setValue(EBOOK_BUILDER_FIELD_KEYS.LANGUAGE_CODE as IEbookBuilderFields, pintoEbookData.language.code);
      }

      // authorNames
      const _authorNames = pintoEbookData?.authorNames ?? [];
      // setAuthorNames(_authorNames.length === 0 ? [''] : _authorNames);
      setValue(EBOOK_BUILDER_FIELD_KEYS.AUTHOR_NAMES as IEbookBuilderFields, _authorNames);

      // translator
      const _translatorNames = pintoEbookData?.translatorNames ?? [];
      // setTranslatorNames(_translatorNames.length === 0 ? [''] : _translatorNames);
      setValue(EBOOK_BUILDER_FIELD_KEYS.TRANSLATOR_NAMES as IEbookBuilderFields, _translatorNames);

      // discount
      if (pintoEbookData?.discount) {
        setValue(EBOOK_BUILDER_FIELD_KEYS.HAVE_DISCOUNT as IEbookBuilderFields, true);

        setValue(
          EBOOK_BUILDER_FIELD_KEYS.DISCOUNT_TYPE as IEbookBuilderFields,
          pintoEbookData?.discount?.ratio ? DISCOUNT_VALUE.PERCENT_DISCOUNT : pintoEbookData?.discount?.thb ? DISCOUNT_VALUE.SPECIFY_PRICE : DISCOUNT_VALUE.NO_DISCOUNT,
        );
        setValue(EBOOK_BUILDER_FIELD_KEYS.DISCOUNT_END_DATE as IEbookBuilderFields, getDiscountEndDateFromQuery(pintoEbookData?.discount?.endedAt));
        setValue(EBOOK_BUILDER_FIELD_KEYS.IS_HAS_DISCOUNT_END_DATE as IEbookBuilderFields, !!pintoEbookData?.discount?.endedAt);
        setValue(EBOOK_BUILDER_FIELD_KEYS.DISCOUNT_PRICE as IEbookBuilderFields, pintoEbookData?.discount?.thb);
        setValue(EBOOK_BUILDER_FIELD_KEYS.DISCOUNT_RATIO as IEbookBuilderFields, pintoEbookData?.discount?.ratio ? Math.ceil(pintoEbookData?.discount?.ratio * 100) : undefined);
        setValue(EBOOK_BUILDER_FIELD_KEYS.DISCOUNT_START_DATE as IEbookBuilderFields, pintoEbookData?.discount?.startedAt);
        setValue(EBOOK_BUILDER_FIELD_KEYS.DISCOUNT_GOOGLE_PRICE_TIER_ID as IEbookBuilderFields, pintoEbookData?.discount?.googlePriceTier?._id);
        setValue(EBOOK_BUILDER_FIELD_KEYS.DISCOUNT_APPLE_PRICE_TIER_ID as IEbookBuilderFields, pintoEbookData?.discount?.applePriceTier?._id);
      }

      // files
      if (mappedEbookFiles && (pintoEbookData.ebookFiles.length > 0 || (pintoEbookData?.correction?.correctionEbookFiles.length || 0) > 0)) {
        const mappedEbookFilesWithFilterEmptyValue = mappedEbookFiles
          ?.map((file) => pickBy(file, (obj) => (obj && obj.length > 0) || obj === null))
          ?.filter((file) => Object.keys(file).length !== 1);
        setValue(EBOOK_BUILDER_FIELD_KEYS.FILES as IEbookBuilderFields, mappedEbookFilesWithFilterEmptyValue);
      }

      //subscriptionTitle
      if (pintoEbookData?.subscriptionTitleId) {
        setValue(EBOOK_BUILDER_FIELD_KEYS.SUBSCRIPTION_TITLE_ID as IEbookBuilderFields, pintoEbookData?.subscriptionTitleId);
        onFetchPendingEbookInSubscriptionTitle(pintoEbookData?.subscriptionTitleId);
      }
    }
  }, [pintoEbookData, mappedEbookFiles, getDiscountEndDateFromQuery, setValue, onFetchPendingEbookInSubscriptionTitle]);

  // User
  const [userId] = watch([EBOOK_BUILDER_FIELD_KEYS.USER_ID]);

  // Cover Image and Banner
  const [coverImageUrl, bannerImageUrl] = watch([EBOOK_BUILDER_FIELD_KEYS.COVER_IMAGE_URL, EBOOK_BUILDER_FIELD_KEYS.BANNER_IMAGE_URL]);

  // Ebook Detail
  const [title, description, categoryId, originalLanguageCode, languageCode, authorNames, translatorNames, tagNames, writingType = EbookWritingType.Bundle, isCartoonType] = watch([
    EBOOK_BUILDER_FIELD_KEYS.TITLE,
    EBOOK_BUILDER_FIELD_KEYS.DESCRIPTION,
    EBOOK_BUILDER_FIELD_KEYS.CATEGORY_ID,
    EBOOK_BUILDER_FIELD_KEYS.ORIGINAL_LANGUAGE_CODE,
    EBOOK_BUILDER_FIELD_KEYS.LANGUAGE_CODE,
    EBOOK_BUILDER_FIELD_KEYS.AUTHOR_NAMES,
    EBOOK_BUILDER_FIELD_KEYS.TRANSLATOR_NAMES,
    EBOOK_BUILDER_FIELD_KEYS.TAG_NAMES,
    EBOOK_BUILDER_FIELD_KEYS.WRITING_TYPE,
    EBOOK_BUILDER_FIELD_KEYS.IS_CARTOON_TYPE,
  ]);

  // Additional Information
  const [isbn, haveCoverPrice, coverPriceThb, productSetId, productSetHasItemVol, productSetVol, subscriptionTitleId] = watch([
    EBOOK_BUILDER_FIELD_KEYS.ISBN,
    EBOOK_BUILDER_FIELD_KEYS.HAVE_COVER_PRICE,
    EBOOK_BUILDER_FIELD_KEYS.COVER_PRICE_THB,
    EBOOK_BUILDER_FIELD_KEYS.PRODUCT_SET_ID,
    EBOOK_BUILDER_FIELD_KEYS.PRODUCT_SET_HAS_ITEM_VOL,
    EBOOK_BUILDER_FIELD_KEYS.PRODUCT_SET_VOL,
    EBOOK_BUILDER_FIELD_KEYS.SUBSCRIPTION_TITLE_ID,
  ]);

  // schedule publish
  const [showSchedulePublishModal, setSchedulePublishModal] = useState<boolean>(false);

  const { usersProductSets, productSetDetail, onFetchProductSetDetail, onFetchProductSets, getProductSetSimpleDetail, isLoadingPintoProductSets } = useUsersProductSets();

  const currentCategoryName = useMemo<string | undefined>(
    () => pintoEbookData?.correction?.category?.name || pintoEbookData?.category?.name,
    [pintoEbookData?.category?.name, pintoEbookData?.correction?.category?.name],
  );

  const onSelectProductSet = useCallback(
    (item: { label: string; value: string }) => {
      setValue(EBOOK_BUILDER_FIELD_KEYS.PRODUCT_SET_ID as IEbookBuilderFields, item.value);
      const productSetItem = getProductSetSimpleDetail(item.value);
      if (productSetItem?.hasItemVol) {
        setValue(EBOOK_BUILDER_FIELD_KEYS.PRODUCT_SET_HAS_ITEM_VOL as IEbookBuilderFields, true);
      } else {
        setValue(EBOOK_BUILDER_FIELD_KEYS.PRODUCT_SET_HAS_ITEM_VOL as IEbookBuilderFields, false);
      }
      setValue(EBOOK_BUILDER_FIELD_KEYS.PRODUCT_SET_VOL as IEbookBuilderFields, undefined);
      if (item.value) onFetchProductSetDetail(item.value);
    },
    [getProductSetSimpleDetail, onFetchProductSetDetail, setValue],
  );

  const onSelectSubscriptionTitle = useCallback(
    (item: { label: string; value: string }) => {
      setHasPendingSubscriptionTitle(false);
      setValue(EBOOK_BUILDER_FIELD_KEYS.SUBSCRIPTION_TITLE_ID as IEbookBuilderFields, item.value);
      if (item.value) {
        setSubscriptionTitle(item.label);
        onFetchPendingEbookInSubscriptionTitle(item.value);
      }
    },
    [onFetchPendingEbookInSubscriptionTitle, setValue],
  );

  // Price setting
  const [originalPriceThb, isFree, haveDiscount, discountType, discountPrice, discountRatio, discountStartDate, discountEndDate, isHasDiscountEndDate = true] = watch([
    EBOOK_BUILDER_FIELD_KEYS.ORIGINAL_PRICE_THB,
    EBOOK_BUILDER_FIELD_KEYS.IS_FREE,
    EBOOK_BUILDER_FIELD_KEYS.HAVE_DISCOUNT,
    EBOOK_BUILDER_FIELD_KEYS.DISCOUNT_TYPE,
    EBOOK_BUILDER_FIELD_KEYS.DISCOUNT_PRICE,
    EBOOK_BUILDER_FIELD_KEYS.DISCOUNT_RATIO,
    EBOOK_BUILDER_FIELD_KEYS.DISCOUNT_START_DATE,
    EBOOK_BUILDER_FIELD_KEYS.DISCOUNT_END_DATE,
    EBOOK_BUILDER_FIELD_KEYS.IS_HAS_DISCOUNT_END_DATE,
  ]);

  // Files
  const [files] = watch([EBOOK_BUILDER_FIELD_KEYS.FILES]);
  const [readingType] = watch([EBOOK_BUILDER_FIELD_KEYS.READING_TYPE]);
  const [editorState, setEditorState] = useState<IEditorState | null>(null);

  const [isOpenSetPriceModal, setIsOpenSetPriceModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);

  // In-app price
  const [googleOriginalPriceTierId, appleOriginalPriceTierId, discountGooglePriceTierId, discountApplePriceTierId] = watch([
    EBOOK_BUILDER_FIELD_KEYS.GOOGLE_ORIGINAL_PRICE_TIER_ID,
    EBOOK_BUILDER_FIELD_KEYS.APPLE_ORIGINAL_PRICE_TIER_ID,
    EBOOK_BUILDER_FIELD_KEYS.DISCOUNT_GOOGLE_PRICE_TIER_ID,
    EBOOK_BUILDER_FIELD_KEYS.DISCOUNT_APPLE_PRICE_TIER_ID,
  ]);
  const { currentGooglePriceTier, currentApplePriceTier, currentDiscountGooglePriceTier, currentDiscountApplePriceTier, getFreePriceTiers } = useStatePriceTier({
    priceTiers: priceTierOptions,
    googlePriceTierId: googleOriginalPriceTierId,
    applePriceTierId: appleOriginalPriceTierId,
    discountGooglePriceTierId,
    discountApplePriceTierId,
  });

  const onCloseSetPriceModal = useCallback(() => {
    setIsOpenSetPriceModal(false);
  }, []);

  const onOpenSetPriceModal = useCallback(() => {
    setIsOpenSetPriceModal(true);
  }, []);

  const onConfirmSetPriceModal = useCallback(
    ({
      ebookPrice,
      isFree,
      haveDiscount,
      discountType,
      discountPrice,
      discountRatio,
      discountStartDate,
      discountEndDate,
      googlePriceTierId,
      applePriceTierId,
      discountGooglePriceTierId: discountGooglePriceTier,
      discountApplePriceTierId: discountApplePriceTier,
      isHasDiscountEndDate,
    }: IPropsOnConfirmSetPriceModal) => {
      setValue(EBOOK_BUILDER_FIELD_KEYS.ORIGINAL_PRICE_THB as IEbookBuilderFields, ebookPrice);
      setValue(EBOOK_BUILDER_FIELD_KEYS.GOOGLE_ORIGINAL_PRICE_TIER_ID as IEbookBuilderFields, googlePriceTierId);
      setValue(EBOOK_BUILDER_FIELD_KEYS.APPLE_ORIGINAL_PRICE_TIER_ID as IEbookBuilderFields, applePriceTierId);
      setValue(EBOOK_BUILDER_FIELD_KEYS.IS_FREE as IEbookBuilderFields, isFree);
      setValue(EBOOK_BUILDER_FIELD_KEYS.HAVE_DISCOUNT as IEbookBuilderFields, haveDiscount);
      setValue(EBOOK_BUILDER_FIELD_KEYS.DISCOUNT_TYPE as IEbookBuilderFields, discountType);
      setValue(EBOOK_BUILDER_FIELD_KEYS.DISCOUNT_PRICE as IEbookBuilderFields, discountPrice);
      setValue(EBOOK_BUILDER_FIELD_KEYS.DISCOUNT_RATIO as IEbookBuilderFields, discountRatio);
      // set start of day when edit start date
      setValue(EBOOK_BUILDER_FIELD_KEYS.DISCOUNT_START_DATE as IEbookBuilderFields, discountStartDate ? startOf(discountStartDate, 'day') : undefined);
      // set end of day when edit end date
      setValue(EBOOK_BUILDER_FIELD_KEYS.DISCOUNT_END_DATE as IEbookBuilderFields, discountEndDate ? endOf(discountEndDate, 'day') : undefined);
      setValue(EBOOK_BUILDER_FIELD_KEYS.DISCOUNT_GOOGLE_PRICE_TIER_ID as IEbookBuilderFields, discountGooglePriceTier);
      setValue(EBOOK_BUILDER_FIELD_KEYS.DISCOUNT_APPLE_PRICE_TIER_ID as IEbookBuilderFields, discountApplePriceTier);
      setValue(EBOOK_BUILDER_FIELD_KEYS.IS_HAS_DISCOUNT_END_DATE as IEbookBuilderFields, isHasDiscountEndDate);
      setIsOpenSetPriceModal(false);
    },
    [setValue],
  );

  const onSelectedUser = useCallback(
    (userIdValue: string) => {
      setValue(EBOOK_BUILDER_FIELD_KEYS.USER_ID as IEbookBuilderFields, userIdValue);
      // change product set to default value
      if (userId !== userIdValue) {
        onSelectProductSet({ label: 'ไม่มีเซ็ตหนังสือ', value: '' });
        onSelectSubscriptionTitle({ label: 'ไม่มีหัวหนังสือ', value: '' });
      }
    },
    [onSelectProductSet, onSelectSubscriptionTitle, setValue, userId],
  );

  const getImageCoverUrl = useCallback(async () => {
    if (!cropImageCoverBase64 && coverImageUrl) return coverImageUrl;
    if (!cropImageCoverBase64 || !userId) return undefined;

    const input = {
      type: UploadEbookImageType.CoverImage,
      base64: cropImageCoverBase64,
      userId,
    };

    const res = await onMutatePintoUploadImage(input as UploadEbookImageInput);
    return res.data?.pintoUploadEbookImage;
  }, [coverImageUrl, cropImageCoverBase64, onMutatePintoUploadImage, userId]);

  const getImageBannerUrl = useCallback(async () => {
    if (!cropImageBannerBase64 && coverImageUrl) return bannerImageUrl;
    if (!cropImageBannerBase64 || !userId) return undefined;

    const input = {
      type: UploadEbookImageType.BannerImage,
      base64: cropImageBannerBase64,
      userId,
    };
    const res = await onMutatePintoUploadImage(input as UploadEbookImageInput);
    return res.data?.pintoUploadEbookImage;
  }, [bannerImageUrl, coverImageUrl, cropImageBannerBase64, onMutatePintoUploadImage, userId]);

  // watch editorState error -> handle isDirtyForm
  useEffect(() => {
    // default count = 2
    const contentLength = editorState?.doc?.content?.size ?? 0;
    setValue(EBOOK_BUILDER_FIELD_KEYS.SYNOPSIS as IEbookBuilderFields, contentLength);
  }, [editorState?.doc?.content?.size, setValue]);

  const setFileValue = useCallback(
    (type: ISupportUploadFileType, file?: File) => {
      const _files = files as SubmitReviewEbookEbookFileInput[];
      const pdfFile = _files?.find((_file) => _file.type === EbookFileType.Pdf);
      const epubFile = _files?.find((_file) => _file.type === EbookFileType.Epub);
      const _pdfFile: SubmitReviewEbookEbookFileInput = {
        mainFileUrl: '',
        sampleFileUrl: '',
        mainFilename: pdfFile?.mainFilename || '',
        sampleFilename: pdfFile?.sampleFilename || '',
        type: EbookFileType.Pdf,
      };
      const _epubFile: SubmitReviewEbookEbookFileInput = {
        mainFileUrl: '',
        sampleFileUrl: '',
        mainFilename: epubFile?.mainFilename || '',
        sampleFilename: epubFile?.sampleFilename || '',
        type: EbookFileType.Epub,
      };
      if (type === 'PDFFile') {
        _pdfFile.mainFilename = file?.name || '';
      } else if (type === 'PDFSampleFile') {
        _pdfFile.sampleFilename = file?.name || '';
      } else if (type === 'EPUBFile') {
        _epubFile.mainFilename = file?.name || '';
      } else if (type === 'EPUBSampleFile') {
        _epubFile.sampleFilename = file?.name || '';
      }
      const _updatedFiles = [_pdfFile, _epubFile].filter((_file) => _file.mainFilename || _file.sampleFilename);
      setValue('files', _updatedFiles && _updatedFiles.length > 0 ? _updatedFiles : undefined, { shouldValidate: false });
    },
    [files, setValue],
  );

  const onUploadFiles = useCallback(async (): Promise<SubmitReviewEbookEbookFileInput[] | undefined> => {
    return new Promise((resolve) => {
      if (selectedMainPdfFile || selectedSamplePdfFile || selectedMainEpubFile || selectedSampleEpubFile) {
        const uploadFileItems: { key: string; file: File; fileLabel: string }[] = [];
        if (selectedMainPdfFile) {
          uploadFileItems.push({
            key: ebookFileKey.PDF_MAIN_FILE,
            file: selectedMainPdfFile,
            fileLabel: selectedMainPdfFile.name,
          });
        }
        if (selectedSamplePdfFile) {
          uploadFileItems.push({
            key: ebookFileKey.PDF_SAMPLE_FILE,
            file: selectedSamplePdfFile,
            fileLabel: selectedSamplePdfFile.name,
          });
        }
        if (selectedMainEpubFile) {
          uploadFileItems.push({
            key: ebookFileKey.EPUB_MAIN_FILE,
            file: selectedMainEpubFile,
            fileLabel: selectedMainEpubFile.name,
          });
        }
        if (selectedSampleEpubFile) {
          uploadFileItems.push({
            key: ebookFileKey.EPUB_SAMPLE_FILE,
            file: selectedSampleEpubFile,
            fileLabel: selectedSampleEpubFile.name,
          });
        }
        openModal({
          modalName: IModalNames.PROGRESS_UPLOAD_FILE,
          modalPayload: {
            uploadFileItems,
            onUploadFile: handleUploadFile,
            onUploadFileSuccess: (uploadResponse) => {
              removeModal(IModalNames.PROGRESS_UPLOAD_FILE);
              const formFile: SubmitReviewEbookEbookFileInput[] = [];
              if (uploadResponse[ebookFileKey.PDF_MAIN_FILE] || uploadResponse[ebookFileKey.PDF_SAMPLE_FILE]) {
                const mainFile = uploadResponse[ebookFileKey.PDF_MAIN_FILE];
                const sampleFile = uploadResponse[ebookFileKey.PDF_SAMPLE_FILE];
                formFile.push({
                  mainFileUrl: mainFile?.fileUrl || '',
                  mainFilename: mainFile?.fileName || '',
                  sampleFileUrl: sampleFile?.fileUrl || '',
                  sampleFilename: sampleFile?.fileName || '',
                  type: EbookFileType.Pdf,
                });
              }
              if (uploadResponse[ebookFileKey.EPUB_MAIN_FILE] || uploadResponse[ebookFileKey.EPUB_SAMPLE_FILE]) {
                const mainFile = uploadResponse[ebookFileKey.EPUB_MAIN_FILE];
                const sampleFile = uploadResponse[ebookFileKey.EPUB_SAMPLE_FILE];
                formFile.push({
                  mainFileUrl: mainFile?.fileUrl || '',
                  mainFilename: mainFile?.fileName || '',
                  sampleFileUrl: sampleFile?.fileUrl || '',
                  sampleFilename: sampleFile?.fileName || '',
                  type: EbookFileType.Epub,
                });
              }
              resolve(formFile);
            },
            onClose: (abortController) => {
              abortController?.abort();
              removeModal(IModalNames.PROGRESS_UPLOAD_FILE);
              resolve(undefined);
            },
          },
        });
      } else {
        resolve([]);
      }
    });
  }, [selectedMainPdfFile, selectedSamplePdfFile, selectedMainEpubFile, selectedSampleEpubFile, openModal, handleUploadFile, removeModal]);

  const getChangedFilesInput = useCallback(() => {
    const groupedEbookFiles = uniqBy([...(pintoEbookData?.ebookFiles || []), ...(pintoEbookData?.correction?.correctionEbookFiles || [])], 'type');
    const ebookPdfFile = groupedEbookFiles.find((file) => file.type === EbookFileType.Pdf);
    const ebookEpubFile = groupedEbookFiles.find((file) => file.type === EbookFileType.Epub);
    const pdfFileState = ((files || []) as SubmitReviewEbookEbookFileInput[]).find((file) => file.type === EbookFileType.Pdf);
    const epubFileState = ((files || []) as SubmitReviewEbookEbookFileInput[]).find((file) => file.type === EbookFileType.Epub);

    return [
      {
        mainFileUrl: ebookPdfFile?.mainFilename !== pdfFileState?.mainFilename ? null : '',
        mainFilename: ebookPdfFile?.mainFilename !== pdfFileState?.mainFilename ? null : '',
        sampleFileUrl: ebookPdfFile?.sampleFilename !== pdfFileState?.sampleFilename ? null : '',
        sampleFilename: ebookPdfFile?.sampleFilename !== pdfFileState?.sampleFilename ? null : '',
        type: EbookFileType.Pdf,
      },
      {
        mainFileUrl: ebookEpubFile?.mainFilename !== epubFileState?.mainFilename ? null : '',
        mainFilename: ebookEpubFile?.mainFilename !== epubFileState?.mainFilename ? null : '',
        sampleFileUrl: ebookEpubFile?.sampleFilename !== epubFileState?.sampleFilename ? null : '',
        sampleFilename: ebookEpubFile?.sampleFilename !== epubFileState?.sampleFilename ? null : '',
        type: EbookFileType.Epub,
      },
    ];
  }, [pintoEbookData?.correction?.correctionEbookFiles, pintoEbookData?.ebookFiles, files]);

  const getEbookFilesInput = useCallback(
    (uploadedEbookFiles: SubmitReviewEbookEbookFileInput[]) => {
      let filesForSubmit;

      const mappedUploadedEbookFiles = uploadedEbookFiles.map((file) => pickBy(file, (obj) => obj !== ''));
      const changedFiles = getChangedFilesInput();
      const mappedChangedFiles = changedFiles.map((file) => pickBy(file, (obj) => obj !== ''));
      const changedPdfFile = mappedChangedFiles.find((file) => file.type === EbookFileType.Pdf);
      const changedEpubFile = mappedChangedFiles.find((file) => file.type === EbookFileType.Epub);
      const uploadedEbookPdfFile = mappedUploadedEbookFiles.find((file) => file.type === EbookFileType.Pdf);
      const uploadedEbookEpubFile = mappedUploadedEbookFiles.find((file) => file.type === EbookFileType.Epub);
      const prevEbookPdfFile = mappedEbookFiles.find((file) => file.type === EbookFileType.Pdf);
      const prevEbookEpubFile = mappedEbookFiles.find((file) => file.type === EbookFileType.Epub);

      filesForSubmit = [
        {
          mainFileUrl: get(uploadedEbookPdfFile, 'mainFileUrl', get(changedPdfFile, 'mainFileUrl', get(prevEbookPdfFile, 'mainFileUrl', ''))),
          mainFilename: get(uploadedEbookPdfFile, 'mainFilename', get(changedPdfFile, 'mainFilename', get(prevEbookPdfFile, 'mainFilename', ''))),
          sampleFileUrl: get(uploadedEbookPdfFile, 'sampleFileUrl', get(changedPdfFile, 'sampleFileUrl', get(prevEbookPdfFile, 'sampleFileUrl', ''))),
          sampleFilename: get(uploadedEbookPdfFile, 'sampleFilename', get(changedPdfFile, 'sampleFilename', get(prevEbookPdfFile, 'sampleFilename', ''))),
          type: EbookFileType.Pdf,
        },
        {
          mainFileUrl: get(uploadedEbookEpubFile, 'mainFileUrl', get(changedEpubFile, 'mainFileUrl', get(prevEbookEpubFile, 'mainFileUrl', ''))),
          mainFilename: get(uploadedEbookEpubFile, 'mainFilename', get(changedEpubFile, 'mainFilename', get(prevEbookEpubFile, 'mainFilename', ''))),
          sampleFileUrl: get(uploadedEbookEpubFile, 'sampleFileUrl', get(changedEpubFile, 'sampleFileUrl', get(prevEbookEpubFile, 'sampleFileUrl', ''))),
          sampleFilename: get(uploadedEbookEpubFile, 'sampleFilename', get(changedEpubFile, 'sampleFilename', get(prevEbookEpubFile, 'sampleFilename', ''))),
          type: EbookFileType.Epub,
        },
      ];

      // filter out fileName = ''
      filesForSubmit = filesForSubmit?.map((file) => pickBy(file, (obj) => obj !== ''));
      // filter out has no either sample or main file
      filesForSubmit = filesForSubmit?.filter((file) => Object.keys(file).length !== 1);
      return filesForSubmit;
    },
    [getChangedFilesInput, mappedEbookFiles],
  );

  const getEbookForm = useCallback(
    async (isSubmitForPublish?: boolean): Promise<CreateAndSubmitReviewEbookInput | SubmitReviewEbookInput | undefined> => {
      // async (ebookStatus?: EbookStatus): Promise<CreateAndSubmitReviewEbookInput | undefined> => {
      // NOTES: use returned response from async function for that form state not changed yet when async function is done

      const uploadedEbookFiles = await onUploadFiles();
      if (typeof uploadedEbookFiles === 'undefined') return undefined;

      const _coverImageUrl = await getImageCoverUrl();
      const _bannerImageUrl = await getImageBannerUrl();

      const _authorNames = ((authorNames || []) as Array<string>).filter((name) => name?.trim());
      const _translatorNames = (translatorNames as Array<string> | undefined)?.filter((name) => name?.trim());
      const _tagNames = (tagNames as { _id: string; name: string }[])?.map((_tag) => _tag.name);
      const _synopsisContentRawState =
        (editorState?.doc?.textContent.length ?? 0) > 0 ? (ebookId ? (getContentRawState(editorState) as unknown as JSON) : getContentRawState(editorState)) : null;
      // app price tier
      const freePriceTiers = getFreePriceTiers();
      const _appleOriginalPriceTierId = isFree ? freePriceTiers.appleFreePriceTierId : appleOriginalPriceTierId || null;
      const _googleOriginalPriceTierId = isFree ? freePriceTiers.googleFreePriceTierId : googleOriginalPriceTierId || null;
      const _discountApplePriceTierId = isNull(_appleOriginalPriceTierId) ? undefined : discountApplePriceTierId || null;
      const _discountGooglePriceTierId = isNull(_googleOriginalPriceTierId) ? undefined : discountGooglePriceTierId || null;
      let discount = null;
      if (discountRatio || discountPrice || _discountApplePriceTierId || _discountGooglePriceTierId) {
        discount = {
          ratio: discountRatio ? discountRatio / 100 : null,
          thb: discountPrice ? discountPrice : null,
          startedAt: getDiscountStartDateForMutation(discountStartDate),
          endedAt: isHasDiscountEndDate ? getDiscountEndDateForMutation(discountEndDate) : null,
          googlePriceTierId: _discountGooglePriceTierId,
          applePriceTierId: _discountApplePriceTierId,
        };
      }
      // setIsFormSubmitted(true);
      let filesForSubmit = getEbookFilesInput(uploadedEbookFiles);
      if (isSubmitForPublish) {
        filesForSubmit = filesForSubmit?.map((file) => pickBy(file, (obj) => obj));
        filesForSubmit = filesForSubmit?.filter((file) => Object.keys(file).length !== 1);
      }

      return {
        userId,
        authorNames: _authorNames,
        categoryId,
        writingType: isCartoonType ? writingType : undefined,
        coverImageUrl: _coverImageUrl,
        bannerImageUrl: _bannerImageUrl,
        coverPriceThb: Number(coverPriceThb) || null,
        description,
        discount,
        ebookFiles: filesForSubmit as SubmitReviewEbookEbookFileInput[],
        isbn: isbn || undefined,
        productSetId: productSetId || null,
        productSetVol: productSetId && productSetHasItemVol ? Number(productSetVol) : undefined,
        languageCode,
        originalLanguageCode,
        originalPriceThb: originalPriceThb ?? 0,
        googleOriginalPriceTierId: _googleOriginalPriceTierId,
        appleOriginalPriceTierId: _appleOriginalPriceTierId,
        synopsis: _synopsisContentRawState,
        tagNames: _tagNames,
        title,
        translatorNames: (_translatorNames?.length || 0) > 0 ? _translatorNames : undefined,
        readingType,
        subscriptionTitleId: subscriptionTitleId || undefined,
      };
    },
    [
      onUploadFiles,
      getImageCoverUrl,
      getImageBannerUrl,
      authorNames,
      translatorNames,
      tagNames,
      editorState,
      ebookId,
      getFreePriceTiers,
      isFree,
      appleOriginalPriceTierId,
      googleOriginalPriceTierId,
      discountApplePriceTierId,
      discountGooglePriceTierId,
      discountRatio,
      discountPrice,
      getEbookFilesInput,
      userId,
      categoryId,
      isCartoonType,
      writingType,
      coverPriceThb,
      description,
      isbn,
      productSetId,
      productSetHasItemVol,
      productSetVol,
      languageCode,
      originalLanguageCode,
      originalPriceThb,
      title,
      readingType,
      subscriptionTitleId,
      getDiscountStartDateForMutation,
      discountStartDate,
      isHasDiscountEndDate,
      getDiscountEndDateForMutation,
      discountEndDate,
    ],
  );

  const getEbookDraftForm = useCallback(async (): Promise<CreateDraftEbookInput | undefined> => {
    // NOTES: use returned response from async function for that form state not changed yet when async function is done
    const uploadedEbookFiles = await onUploadFiles();
    if (typeof uploadedEbookFiles === 'undefined') return undefined;

    const _coverImageUrl = await getImageCoverUrl();
    const _bannerImageUrl = await getImageBannerUrl();

    const _authorNames = ((authorNames || []) as Array<string>).filter((name) => name?.trim());
    const _translatorNames = (translatorNames as Array<string> | undefined)?.filter((name) => name?.trim());
    const _tagNames = (tagNames as { _id: string; name: string }[])?.map((_tag) => _tag.name);
    const _synopsisContentRawState = (editorState?.doc?.textContent.length ?? 0) > 0 ? getContentRawState(editorState) : null;
    // app price tier
    const freePriceTiers = getFreePriceTiers();
    const _appleOriginalPriceTierId = isFree ? freePriceTiers.appleFreePriceTierId : appleOriginalPriceTierId || null;
    const _googleOriginalPriceTierId = isFree ? freePriceTiers.googleFreePriceTierId : googleOriginalPriceTierId || null;
    const _discountApplePriceTierId = isNull(_appleOriginalPriceTierId) ? undefined : discountApplePriceTierId || null;
    const _discountGooglePriceTierId = isNull(_googleOriginalPriceTierId) ? undefined : discountGooglePriceTierId || null;
    let discount = null;
    if (discountRatio || discountPrice || _discountApplePriceTierId || _discountGooglePriceTierId) {
      discount = {
        ratio: discountRatio ? discountRatio / 100 : null,
        thb: discountPrice ? discountPrice : null,
        startedAt: getDiscountStartDateForMutation(discountStartDate),
        endedAt: getDiscountEndDateForMutation(discountEndDate),
        googlePriceTierId: _discountGooglePriceTierId,
        applePriceTierId: _discountApplePriceTierId,
      };
    }
    // setIsFormSubmitted(true);

    const filesForSubmit = getEbookFilesInput(uploadedEbookFiles);
    return {
      userId,
      authorNames: _authorNames,
      categoryId,
      writingType: isCartoonType ? writingType : undefined,
      coverImageUrl: _coverImageUrl,
      bannerImageUrl: _bannerImageUrl,
      coverPriceThb: Number(coverPriceThb) || null,
      description,
      discount,
      ebookFiles: filesForSubmit as SubmitReviewEbookEbookFileInput[],
      isbn: isbn || undefined,
      productSetId: productSetId || null,
      productSetVol: productSetId && productSetHasItemVol ? Number(productSetVol) : undefined,
      languageCode,
      originalLanguageCode,
      originalPriceThb: originalPriceThb ?? 0,
      googleOriginalPriceTierId: _googleOriginalPriceTierId,
      appleOriginalPriceTierId: _appleOriginalPriceTierId,
      synopsis: _synopsisContentRawState,
      tagNames: _tagNames,
      title,
      translatorNames: (_translatorNames?.length || 0) > 0 ? _translatorNames : undefined,
      readingType,
      subscriptionTitleId: subscriptionTitleId || undefined,
    };
  }, [
    onUploadFiles,
    getImageCoverUrl,
    getImageBannerUrl,
    authorNames,
    translatorNames,
    tagNames,
    editorState,
    getFreePriceTiers,
    isFree,
    appleOriginalPriceTierId,
    googleOriginalPriceTierId,
    discountApplePriceTierId,
    discountGooglePriceTierId,
    discountRatio,
    discountPrice,
    getEbookFilesInput,
    userId,
    categoryId,
    isCartoonType,
    writingType,
    coverPriceThb,
    description,
    isbn,
    productSetId,
    productSetHasItemVol,
    productSetVol,
    languageCode,
    originalLanguageCode,
    originalPriceThb,
    title,
    readingType,
    subscriptionTitleId,
    getDiscountStartDateForMutation,
    discountStartDate,
    getDiscountEndDateForMutation,
    discountEndDate,
  ]);

  const onValidateFields = useCallback(async () => {
    // setIsDirtyEditor(true);

    // if (!isFree && !isOriginalPriceSameAsCoverPrice && !originalPrice) {
    //   setError(EBOOK_BUILDER_FIELD_KEYS.ORIGINAL_PRICE, {
    //     type: ErrorTypes.REQUIRED,
    //     message: 'กรุณากำหนดราคาขายอีบุ๊กขั้นต่ำ 1 แต่ไม่เกิน 5,000 บาท',
    //   });
    // } else {
    //   clearErrors(EBOOK_BUILDER_FIELD_KEYS.ORIGINAL_PRICE);
    // }

    if (haveCoverPrice && !coverPriceThb) {
      setError(EBOOK_BUILDER_FIELD_KEYS.COVER_PRICE_THB, {
        type: ErrorTypes.REQUIRED,
        message: 'กรุณาระบุราคาปก',
      });
    } else {
      clearErrors(EBOOK_BUILDER_FIELD_KEYS.COVER_PRICE_THB);
    }

    if (!isbn) {
      clearErrors(EBOOK_BUILDER_FIELD_KEYS.ISBN);
    }

    // if (haveDiscountAmount && !discountAmount) {
    //   setError(EBOOK_BUILDER_FIELD_KEYS.DISCOUNT_AMOUNT, {
    //     type: ErrorTypes.REQUIRED,
    //     message: 'กรุณาระบุส่วนลด',
    //   });
    // } else {
    //   clearErrors(EBOOK_BUILDER_FIELD_KEYS.DISCOUNT_AMOUNT);
    // }

    // if (haveDiscountRatio && !discountRatio) {
    //   setError(EBOOK_BUILDER_FIELD_KEYS.DISCOUNT_RATIO, {
    //     type: ErrorTypes.REQUIRED,
    //     message: 'กรุณาระบุส่วนลด',
    //   });
    // } else {
    //   clearErrors(EBOOK_BUILDER_FIELD_KEYS.DISCOUNT_RATIO);
    // }

    // onValidateProductSetFields();

    const isValid = await onValidate();
    scrollToView('[data-invalid="true"]');
    return isValid;
  }, [clearErrors, coverPriceThb, haveCoverPrice, isbn, onValidate, setError]);

  const onSubmitEbookDraft = useCallback(async () => {
    // NOTE: validate title and description in only send ebook draft
    if (!title || !description) {
      setError(EBOOK_BUILDER_FIELD_KEYS.TITLE, {
        type: ErrorTypes.REQUIRED,
        message: 'กรุณาใส่ชื่ออีบุ๊ก 1 ตัวอักษร',
      });
      setError(EBOOK_BUILDER_FIELD_KEYS.DESCRIPTION, {
        type: ErrorTypes.REQUIRED,
        message: 'กรุณาใส่คำโปรยอย่างน้อย 50 ตัวอักษร',
      });
      scrollToView('[data-invalid="true"]');
      return;
    }

    const input = await getEbookDraftForm();
    console.log('input', input);

    if (!input) return;
    if (!input.userId) return setShowErrorModal(true);

    if (ebookId) {
      await editDraftEbook(ebookId, omit(input, ['userId', 'subscriptionTitleId']) as EditDraftEbookInput);
    } else {
      await createDraftEbook(input);
    }
  }, [createDraftEbook, description, ebookId, editDraftEbook, getEbookDraftForm, setError, title]);

  const onSubmitPublishEbook = useCallback(
    async (publishedAt?: Date) => {
      if (!(await onValidateFields())) {
        ToastComponent({ label: 'กรุณากรอกข้อมุลให้ครบถ้วน', type: IToastType.ERROR });
        return;
      }

      const input = await getEbookForm(true);

      if (!input) {
        ToastComponent({ label: 'กรุณากรอกข้อมุลให้ครบถ้วน', type: IToastType.ERROR });
        return;
      }

      input.publishedAt = publishedAt;

      if (ebookId) {
        await submitReviewDraftEbook(ebookId, omit(input, ['userId', 'subscriptionTitleId']) as SubmitReviewEbookInput);
      } else {
        await createAndSubmitReviewEbook(input as CreateAndSubmitReviewEbookInput);
      }
    },
    [createAndSubmitReviewEbook, ebookId, getEbookForm, onValidateFields, submitReviewDraftEbook],
  );

  const onValidateBeforeSubmit = useCallback(async () => {
    if (!(await onValidateFields())) {
      ToastComponent({ label: 'กรุณากรอกข้อมุลให้ครบถ้วน', type: IToastType.ERROR });
      return;
    }

    const input = await getEbookForm();

    if (!input) {
      ToastComponent({ label: 'กรุณากรอกข้อมุลให้ครบถ้วน', type: IToastType.ERROR });
      return;
    }

    setShowConfirmModal(true);
  }, [getEbookForm, onValidateFields]);

  // TODO: implement in next phase
  const onSubmitEditEbook = useCallback(async () => {
    if (!(await onValidateFields())) {
      ToastComponent({ label: 'กรุณากรอกข้อมุลให้ครบถ้วน', type: IToastType.ERROR });
      return;
    }

    const input = await getEbookForm();
    console.log('input', input);

    if (!input) {
      ToastComponent({ label: 'กรุณากรอกข้อมุลให้ครบถ้วน', type: IToastType.ERROR });
      return;
    }

    if (ebookId) {
      await editEbook(ebookId, pick(input, ['tagNames']) as EditEbookInput);
    }
  }, [ebookId, editEbook, getEbookForm, onValidateFields]);

  useEffect(() => {
    if (pendingEbookInSubscriptionTitleData && pendingEbookInSubscriptionTitleData.length > 0) {
      setHasPendingSubscriptionTitle(true);
    } else {
      setHasPendingSubscriptionTitle(false);
      setShowConfirmModal(false);
    }
  }, [pendingEbookInSubscriptionTitleData]);

  const subscriptionModalMessages = useMemo(() => {
    if (!pendingEbookInSubscriptionTitleData || pendingEbookInSubscriptionTitleData.length === 0) {
      return [];
    }

    return pendingEbookInSubscriptionTitleData.map((ebook) => ({ title1: ebook.ebookTitle!, title2: MAPPED_EBOOK_STATUS[ebook.ebookStatus!] }));
  }, [pendingEbookInSubscriptionTitleData]);

  if (isLoadingPintoEbook) return <LoadingModal />;

  return (
    <>
      {showSchedulePublishModal && (
        <SchedulePublishModal
          onClose={() => setSchedulePublishModal(false)}
          onConFirm={(date) => {
            setSchedulePublishModal(false);
            onSubmitPublishEbook(date);
          }}
        />
      )}

      {showConfirmModal && (
        <ConfirmModal
          onClose={() => setShowConfirmModal(false)}
          onConFirm={() => {
            setShowConfirmModal(false);
            onSubmitPublishEbook();
          }}
          title="เผยแพร่ทันที"
          subTitle={
            <>
              พบอีบุ๊กที่ยังไม่เผยแพร่ภายใต้ <span style={{ color: 'red', fontWeight: 'bold' }}>[{subscriptionTitle || pintoEbookData?.subscriptionTitle?.title}]</span> นี้
              ต้องการยืนยันการเผยแพร่ทันทีหรือไม่
            </>
          }
          messageList={subscriptionModalMessages}
          cancelText="Cancel"
          confirmText="Confirm"
        />
      )}

      {isLoadingMutatePintoUploadImage && <LoadingModal title="กำลังอัปโหลดภาพปก" />}
      {(isLoadingCreateDraftEbook || isLoadingEditDraftEbook) && <LoadingModal title="กำลังบันทึกแบบร่าง Ebook" />}
      {showErrorModal && <ErrorModal onConFirm={() => setShowErrorModal(false)} confirmText="ตกลง" errorMessages={[]} />}
      {isOpenSetPriceModal && (
        <SetPriceModal
          onClose={onCloseSetPriceModal}
          onConfirm={onConfirmSetPriceModal}
          initEbookPrice={originalPriceThb}
          initPriceOption={isFree ? PRICE_VALUE.FREE : PRICE_VALUE.MANUAL}
          initDiscountType={discountType || DISCOUNT_VALUE.NO_DISCOUNT}
          initHaveDiscount={haveDiscount || false}
          initDiscountPrice={discountPrice || undefined}
          initDiscountRatio={discountRatio || undefined}
          initDiscountStartDate={discountStartDate}
          initDiscountEndDate={discountEndDate}
          priceTierOptions={priceTierOptions}
          initGooglePriceTierId={googleOriginalPriceTierId}
          initApplePriceTierId={appleOriginalPriceTierId}
          initDiscountGooglePriceTierId={discountGooglePriceTierId}
          initDiscountApplePriceTierId={discountApplePriceTierId}
          initIsHasDiscountEndDate={isHasDiscountEndDate}
        />
      )}
      {cropImageBase64 && (
        <CropImageModal base64Image={cropImageBase64} imageWidth={cropImageWidth} imageHeight={cropImageHeight} onConfirm={onConfirmCropImage} onClose={onCancelCropImage} />
      )}

      <div className="space-y-12">
        <div className="sticky top-0 z-50 px-5 bg-componentsBgGrouped02">
          {/* section: breadcrumbs */}
          <Breadcrumbs items={[{ name: 'Ebooks', url: '/ebooks' }, { name: ebookId ? 'Edit Ebook' : 'Create Ebook' }]} />
          {/* section: title */}
          <div className="flex justify-between pt-5">
            <h1 className="font-dbh text-[28px]">{ebookId ? 'Edit' : 'Create'} Ebook</h1>
            <div className="flex items-center space-x-12">
              <Link to="/ebooks">
                <button className="btn btn-outline">CANCEL</button>
              </Link>
              {(!pintoEbookData || pintoEbookData?.status === EbookStatus.Draft) && (
                <button
                  className="space-x-4 btn"
                  onClick={onSubmitEbookDraft}
                  // for create part: we only verify if user already select user profile
                  // for edit part: has user id , ebook id and correction status not eq to Pending
                  disabled={!userId || !!(ebookId && pintoEbookData?.correction?.status === CorrectionEbookStatus.Pending)}
                >
                  <MdSave size={24} />
                  <div>Save Draft</div>
                </button>
              )}
              {userId ? (
                pintoEbookData?.status === EbookStatus.Public || pintoEbookData?.status === EbookStatus.Scheduled ? null : (
                  // <button className="space-x-4 btn btn-error" onClick={() => s()}>
                  //   <MdUpload size={24} />
                  //   <div>Update</div>
                  // </button>
                  <div className="dropdown dropdown-bottom dropdown-end">
                    <label tabIndex={0} className="m-1">
                      <button className="space-x-4 btn btn-error">
                        <MdUpload size={24} />
                        <div>Publish</div>
                      </button>
                    </label>
                    <ul tabIndex={0} className="p-2 shadow dropdown-content menu bg-base-100 rounded-box w-52">
                      <li>
                        <Button type="ghost" color="black" className="justify-start space-x-4" onClick={() => setSchedulePublishModal(true)}>
                          <MdAlarm size={24} />
                          <div>ตั้งเวลา</div>
                        </Button>
                      </li>
                      <li>
                        <Button
                          type="ghost"
                          color="black"
                          className="justify-start space-x-4"
                          onClick={() => {
                            hasPendingSubscriptionTitle ? onValidateBeforeSubmit() : onSubmitPublishEbook();
                          }}
                        >
                          <MdTaskAlt size={24} />
                          <div>เผยแพร่ทันที</div>
                        </Button>
                      </li>
                    </ul>
                  </div>
                )
              ) : (
                <button className="space-x-4 btn btn-error" disabled>
                  <MdUpload size={24} />
                  <div>Publish</div>
                </button>
              )}
            </div>
          </div>

          {pintoEbookData?.correction?.rejectNotes && (pintoEbookData?.correction?.rejectNotes?.length || 0) > 0 && (
            <div className="pt-16">
              <Alert type="error" message="อีบุ๊กไม่ผ่านการอนุมัติเนื่องจาก" description={pintoEbookData?.correction?.rejectNotes} showIcon />
            </div>
          )}

          {/* section: main */}
          <div className="mb-0 divider opacity-10"></div>
        </div>
        <FormProvider {...methods}>
          <EditEbookUserCreatorPresenter onSelectedUser={onSelectedUser} errors={errors?.userId?.message} selectedUser={pintoEbookData?.user} isEditUser={!!ebookId} />
          <EditEbookPricePresenter
            onOpenSetPriceModal={onOpenSetPriceModal}
            currentGooglePriceTier={currentGooglePriceTier}
            currentApplePriceTier={currentApplePriceTier}
            currentDiscountGooglePriceTier={currentDiscountGooglePriceTier}
            currentDiscountApplePriceTier={currentDiscountApplePriceTier}
          />
          <EditEbookCoverPresenter
            // cover
            selectedCoverFile={selectedCoverFile}
            onSelectCoverFile={onSelectCoverFile}
            coverFileUrl={cropImageCoverBase64 || coverImageUrl}
            // banner
            selectedBannerFile={selectedBannerFile}
            onSelectBannerFile={onSelectBannerFile}
            bannerFileUrl={cropImageBannerBase64 || bannerImageUrl}
            // error
            isRequiredBannerImage={!!errors.bannerImageUrl}
          />
          <EditEbookDetailPresenter
            synopsis={pintoEbookData?.synopsis}
            editorState={editorState}
            setEditorState={setEditorState}
            languageOptions={languageOptions?.map((lang) => ({ label: lang.name, value: lang.code }))}
            currentCategoryName={currentCategoryName}
            initCategories={groupedCategories}
          />

          <AdditionalInfoPresenter
            onSelectProductSet={onSelectProductSet}
            onSelectSubscriptionTitle={onSelectSubscriptionTitle}
            selectedSubscriptionTitle={pintoEbookData?.subscriptionTitle?.title}
            ebookStatus={ebStatus}
            haveCoverPrice={haveCoverPrice}
            usersProductSets={usersProductSets}
            productSetId={productSetId}
            isbn={isbn}
            productSetDetail={productSetDetail}
            onFetchProductSets={onFetchProductSets}
            userId={userId}
            isLoadingFetchProductSets={isLoadingPintoProductSets}
          />
          <EditEbookFilesPresenter
            // main pdf
            mainPdfFileUrl={mappedEbookFiles?.find((type) => type.type === EbookFileType.Pdf)?.mainFileUrl}
            mainPdfFileName={mappedEbookFiles?.find((type) => type.type === EbookFileType.Pdf)?.mainFilename}
            selectedMainPdfFile={selectedMainPdfFile}
            onSelectMainPdfFile={(file?: File) => {
              onSelectMainPdfFile(file);
              setFileValue('PDFFile', file);
            }}
            // sample pdf
            samplePdfFileUrl={mappedEbookFiles?.find((type) => type.type === EbookFileType.Pdf)?.sampleFileUrl}
            samplePdfFileName={mappedEbookFiles?.find((type) => type.type === EbookFileType.Pdf)?.sampleFilename}
            selectedSamplePdfFile={selectedSamplePdfFile}
            onSelectSamplePdfFile={(file?: File) => {
              onSelectSamplePdfFile(file);
              setFileValue('PDFSampleFile', file);
            }}
            // main epub
            mainEpubFileUrl={mappedEbookFiles?.find((type) => type.type === EbookFileType.Epub)?.mainFileUrl}
            mainEpubFileName={mappedEbookFiles?.find((type) => type.type === EbookFileType.Epub)?.mainFilename}
            selectedMainEpubFile={selectedMainEpubFile}
            onSelectMainEpubFile={(file?: File) => {
              onSelectMainEpubFile(file);
              setFileValue('EPUBFile', file);
            }}
            // sample epub
            sampleEpubFileUrl={mappedEbookFiles?.find((type) => type.type === EbookFileType.Epub)?.sampleFileUrl}
            sampleEpubFileName={mappedEbookFiles?.find((type) => type.type === EbookFileType.Epub)?.sampleFilename}
            selectedSampleEpubFile={selectedSampleEpubFile}
            onSelectSampleEpubFile={(file?: File) => {
              onSelectSampleEpubFile(file);
              setFileValue('EPUBSampleFile', file);
            }}
            // NOTE: validate file must input at least 1 file type (sample and main file)
            isRequiredInputFile={!!errors.files && !(files?.[0]?.['mainFilename'] && files?.[0]?.['sampleFilename'])}
          />
        </FormProvider>
      </div>
    </>
  );
}

export default EditEbookPage;
