import { useFormContext } from 'react-hook-form';
import { FiDownload } from 'react-icons/fi';

import Button from '../../../components/Button';
import Input from '../../../components/form/Input';
import UploadFileBox from '../../../components/UploadFileBox';
import { BUNDLE_FIELD_KEYS } from '../validates/bundleSchema';

interface IPropsBundleDetailPresenter {
  selectedCSVFile: File | undefined;
  fileName?: string;
  fileUrl?: string;
  readOnly?: boolean;
  onSelectCSVFile: (file: File | undefined) => void;
  onClickDownloadCSVTemplate: () => void;
}

const BundleDetailPresenter = (props: IPropsBundleDetailPresenter) => {
  const { selectedCSVFile, onSelectCSVFile, onClickDownloadCSVTemplate, fileName, fileUrl, readOnly } = props;

  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <div className="h-full space-y-12">
      <Input
        name={BUNDLE_FIELD_KEYS.TITLE}
        register={register}
        label="Note for admin"
        required
        placeholder="note"
        helperText={errors.title?.message}
        isInvalid={errors.title}
        disabled={!!readOnly}
      />

      <UploadFileBox
        label="Upload file"
        required
        hasError={!!errors.fileName}
        helperText={errors.fileName?.message || 'Only .CSV file type allowed'}
        onSelectFile={onSelectCSVFile}
        acceptFileExtension=".csv"
        fileName={fileName ?? selectedCSVFile?.name}
        rightLabelNode={
          <Button color="red" type="ghost" className="text-colorsBrandRed01Primary" onClick={onClickDownloadCSVTemplate}>
            <FiDownload className="text-24" />
            <p className="ml-4">Download CSV Template</p>
          </Button>
        }
        readOnly={!!readOnly}
        fileUrl={fileUrl}
      />
    </div>
  );
};

export default BundleDetailPresenter;
