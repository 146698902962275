import classNames from 'classnames';
import { useFormContext } from 'react-hook-form';

import Card from '../../../components/Card';
import SummaryPricePresenter from '../../../components/SummaryPrice';
import { IPriceTier } from '../../modals/setPriceModal/hook/useStatePriceTier';
import { EBOOK_BUILDER_FIELD_KEYS } from '../validates/ebookBuilderSchema';

export interface IPropsEditEbookPricePresenter {
  onOpenSetPriceModal?: () => void;
  currentGooglePriceTier?: IPriceTier;
  currentApplePriceTier?: IPriceTier;
  currentDiscountGooglePriceTier?: IPriceTier;
  currentDiscountApplePriceTier?: IPriceTier;
}

const EditEbookPricePresenter = (props: IPropsEditEbookPricePresenter) => {
  const { onOpenSetPriceModal, currentGooglePriceTier, currentApplePriceTier, currentDiscountGooglePriceTier, currentDiscountApplePriceTier } = props;

  const { watch } = useFormContext();

  const [originalPriceThb, isFree, haveDiscount, discountPrice, discountRatio, discountStartDate, discountEndDate, isHasDiscountEndDate] = watch([
    EBOOK_BUILDER_FIELD_KEYS.ORIGINAL_PRICE_THB,
    EBOOK_BUILDER_FIELD_KEYS.IS_FREE,
    EBOOK_BUILDER_FIELD_KEYS.HAVE_DISCOUNT,
    EBOOK_BUILDER_FIELD_KEYS.DISCOUNT_PRICE,
    EBOOK_BUILDER_FIELD_KEYS.DISCOUNT_RATIO,
    EBOOK_BUILDER_FIELD_KEYS.DISCOUNT_START_DATE,
    EBOOK_BUILDER_FIELD_KEYS.DISCOUNT_END_DATE,
    EBOOK_BUILDER_FIELD_KEYS.IS_HAS_DISCOUNT_END_DATE,
  ]);

  return (
    <Card className={classNames('flex flex-col items-center space-y-16', { 'border-colorsBrandRed01Primary border-2 border-solid': !isFree && !originalPriceThb })}>
      <div className="w-full flex justify-between items-center">
        <div className="flex items-center justify-center space-x-8" data-invalid={!isFree && !originalPriceThb}>
          <div className="text-22">ราคา</div>
          {!isFree && !originalPriceThb && <span className="text-colorsBrandRed01Primary text-16">{'( กรุณา "ตั้งราคา" ของ Ebook นี้ )'}</span>}
        </div>
        <button className="btn btn-error" onClick={onOpenSetPriceModal}>
          ตั้งราคา
        </button>
      </div>
      <SummaryPricePresenter
        className="pt-16"
        isFree={isFree}
        webDiscountPercent={discountRatio}
        webDiscountPrice={discountPrice}
        webPrice={originalPriceThb}
        googlePrice={currentGooglePriceTier}
        discountGooglePrice={currentDiscountGooglePriceTier}
        applePrice={currentApplePriceTier}
        discountApplePrice={currentDiscountApplePriceTier}
        haveDiscount={haveDiscount}
        discountStartDate={discountStartDate}
        discountEndDate={discountEndDate}
        isHasDiscountEndDate={isHasDiscountEndDate}
      />
    </Card>
  );
};

export default EditEbookPricePresenter;
