import { useCallback } from 'react';

import { usePintoFeedsLazyQuery } from '../core/graphql/operation-hooks';
import { FeedsFilterV2 } from '../core/graphql/types';

const INITIAL_LIMIT = 10;

const useQueryPintoFeeds = () => {
  const [queryPintoFeeds, { data, loading }] = usePintoFeedsLazyQuery({ fetchPolicy: 'network-only' });

  const fetchPintoFeeds = useCallback(
    (filter?: FeedsFilterV2) => {
      queryPintoFeeds({
        variables: {
          filter: {
            ...filter,
            limit: INITIAL_LIMIT,
          },
        },
      });
    },
    [queryPintoFeeds],
  );

  return {
    fetchPintoFeeds,
    feeds: data?.pintoFeeds,
    isLoadingPintoFeeds: loading,
  };
};

export default useQueryPintoFeeds;
