import { ChangeEvent } from 'react';
import { useFormContext } from 'react-hook-form';

import CommonInput from '../../../../components/form/CommonInput';
import DropdownSelect from '../../../../components/form/DropdownSelect';
import { IDropdownPriceTierOption } from '../hook/useStatePriceTier';
import { SET_PRICE_MODAL_FIELD_KEYS } from '../validates/setPriceModalBuilderSchema';

export interface IPropsPricePresenter {
  onChangeEbookPrice?: (event: ChangeEvent<HTMLInputElement>) => void;
  errorEbookPrice?: string;
  webPrice?: number;
  googlePriceTierOptions?: IDropdownPriceTierOption[];
  applePriceTierOptions?: IDropdownPriceTierOption[];
}

const PricePresenter = (props: IPropsPricePresenter) => {
  const { onChangeEbookPrice, errorEbookPrice, webPrice, googlePriceTierOptions, applePriceTierOptions } = props;
  const { register } = useFormContext();
  return (
    <div>
      <CommonInput
        label="ราคาขายเว็บไซต์ (บาท)"
        required
        placeholder="ราคาขายเว็บไซต์ (บาท)"
        onChange={onChangeEbookPrice}
        helperText={errorEbookPrice}
        isInvalid={!!errorEbookPrice}
        value={webPrice || ''}
      />
      <div className="pt-16 flex justify-between space-x-16">
        {/* ราคาขาย Google Play (บาท) */}
        <DropdownSelect
          className="w-1/2"
          selectClassName="w-full"
          options={googlePriceTierOptions}
          label="ราคาขาย Google Play (บาท)"
          name={SET_PRICE_MODAL_FIELD_KEYS.GOOGLE_PRICE_TIER_ID}
          register={register}
          helperText="ผู้ใช้จะเห็นเฉพาะราคาเงินบาทเท่านั้น"
        />
        {/* -- end -- ราคาขาย Google Play (บาท) -- end -- */}
        {/* ราคาขาย App Store (บาท) */}
        <DropdownSelect
          className="w-1/2"
          selectClassName="w-full"
          options={applePriceTierOptions}
          label="ราคาขาย App Store (บาท)"
          name={SET_PRICE_MODAL_FIELD_KEYS.APPLE_PRICE_TIER_ID}
          register={register}
          helperText="ผู้ใช้จะเห็นเฉพาะราคาเงินบาทเท่านั้น"
        />
        {/* -- end -- ราคาขาย App Store (บาท) -- end -- */}
      </div>
    </div>
  );
};

export default PricePresenter;
