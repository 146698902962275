/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const zoomFit = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path
      fill="#8C8C8C"
      fillRule="evenodd"
      d="M44 8.667v10.492a5.7 5.7 0 01-5.942.035L38 19.159V10h-9.167a5.753 5.753 0 010-6h10.5A4.68 4.68 0 0144 8.667zm0 20.166v10.5A4.68 4.68 0 0139.333 44h-10.5a5.753 5.753 0 010-6h6.977l2.19-.006v-9.161a5.753 5.753 0 016 0zM10.01 38h9.276l.046.092A6.442 6.442 0 0119.286 44H8.666A4.68 4.68 0 014 39.333v-10.5a5.753 5.753 0 016 0V30h.01v8zM10 18v1.159a5.7 5.7 0 01-5.942.035L4 19.159V8.664C4 6.099 5.958 4 8.35 4h10.936l.046.092A6.442 6.442 0 0119.286 10h-9.28v8H10z"
    />
  </svg>
);

export default zoomFit;
