type TimeUnit = 'day' | 'week' | 'month' | 'year';
type StandardizedTime = 'DAILY' | 'WEEKLY' | 'MONTHLY' | 'YEARLY' | `${number} DAYS` | `${number} WEEKS` | `${number} MONTHS` | `${number} YEARS`;

function normalizeUnit(unit: string): TimeUnit | undefined {
  unit = unit.toLowerCase();
  if (unit.endsWith('s')) unit = unit.slice(0, -1); // Remove plural 's'
  if (unit === 'week' || unit === 'month' || unit === 'year') return unit as TimeUnit;
  return undefined;
}

export const convertTimeDescription = (description: string): StandardizedTime | 'Unknown period' => {
  const parts = description.split('-');
  if (parts.length !== 2) return 'Unknown period';

  const [quantityStr, unitPart] = parts;
  const unit = normalizeUnit(unitPart);
  const quantity = parseInt(quantityStr, 10);

  if (!unit || isNaN(quantity)) return 'Unknown period';

  switch (unit) {
    case 'day':
      if (quantity === 1) return 'DAILY';
      if (quantity > 1) return `${quantity} DAYS`;
      break;
    case 'week':
      if (quantity === 1) return 'WEEKLY';
      if (quantity > 1) return `${quantity} WEEKS`;
      break;
    case 'month':
      if (quantity === 1) return 'MONTHLY';
      if (quantity > 1 && quantity <= 12) return `${quantity} MONTHS`;
      break;
    case 'year':
      if (quantity === 1) return 'YEARLY';
      if (quantity > 1) return `${quantity} YEARS`;
      break;
  }

  return 'Unknown period';
};
