import dayjs from 'dayjs';
import { findIndex, pick } from 'lodash';
import { useCallback, useState, useEffect } from 'react';

import {
  PopupItemType,
  PopupQuery,
  ScheduleInput,
  UpdatePopupItemConfigExploreBookInput,
  UpdatePopupItemConfigExploreEbookInput,
  UpdatePopupItemConfigFeedInput,
  UpdatePopupItemConfigSearchInput,
} from '../../../core/graphql/types';

export interface IPopupItemInput {
  type?: PopupItemType;
  _id?: string;
  note?: string;
  popupImageUrl?: string;
  schedules?: ScheduleInput[];
  configId?: string;
  configUrl?: string;
  configFeed?: Partial<UpdatePopupItemConfigFeedInput>;
  configSearch?: Partial<UpdatePopupItemConfigSearchInput>;
  configExploreBook?: Partial<UpdatePopupItemConfigExploreBookInput>;
  configExploreEbook?: Partial<UpdatePopupItemConfigExploreEbookInput>;
}

const getFormPopupItems = (popupQueryResult: IPropsUseFormPopupItems['popupQueryResult']): IPopupItemInput[] =>
  popupQueryResult?.items?.map((item) => {
    const popupItemOptions = {
      _id: item?._id,
      type: item?.type,
      note: item?.note ?? '',
      popupImageUrl: item?.config.popupImageUrl,
      schedules: item?.schedules?.map((schedule) => ({
        startedAt: dayjs(schedule?.startedAt).toDate(),
        endedAt: dayjs(schedule?.endedAt).toDate(),
      })),
    };
    switch (item.config.__typename) {
      case 'PopupItemConfigBook':
      case 'PopupItemConfigEbook':
      case 'PopupItemConfigProductSet':
      case 'PopupItemConfigSection':
        return {
          ...popupItemOptions,
          // type UpdatePopupItemConfigIdInput
          configId: item?.config?._id,
        };
      case 'PopupItemConfigLink':
        return {
          ...popupItemOptions,
          // type UpdatePopupItemConfigLinkInput
          configUrl: item?.config?.url,
        };
      case 'PopupItemConfigSearch':
        return {
          ...popupItemOptions,
          // type UpdatePopupItemConfigSearchInput
          configSearch: {
            ...pick(item.config, ['searchType', 'contentType', 'searchText', 'completed', 'contentRatingIds', 'isFree', 'categoryTagId']),
          },
        };
      case 'PopupItemConfigStatic':
        return {
          ...popupItemOptions,
          // type UpdatePopupItemConfigStaticInput
          // empty
        };
      case 'PopupItemConfigFeed':
        return {
          ...popupItemOptions,
          // type UpdatePopupItemConfigFeedInput
          configFeed: {
            slug: item.config.slug,
          },
        };
      case 'PopupItemConfigExploreBook':
        return {
          ...popupItemOptions,
          // type UpdatePopupItemConfigExploreBookInput
          configExploreBook: {
            ...pick(item.config!, ['contentType', 'completed', 'categoryTagId', 'contentRatingIds', 'isFree']),
            sortBy: item.config.sortByBook,
          },
        };
      case 'PopupItemConfigExploreEbook':
        return {
          ...popupItemOptions,
          // type UpdatePopupItemConfigExploreEbookInput
          configExploreEbook: {
            ...pick(item.config!, ['contentType', 'categoryTagId', 'contentRatingIds', 'isFree']),
            sortBy: item.config.sortByEbook,
          },
        };
      default:
        return {};
    }
  }) ?? [];

export interface IPropsUseFormPopupItems {
  popupQueryResult: PopupQuery['popup'];
}

const useFormPopupItems = (props: IPropsUseFormPopupItems) => {
  const [popupItems, setPopupItems] = useState<IPopupItemInput[]>(getFormPopupItems(props.popupQueryResult));

  useEffect(() => {
    if (props.popupQueryResult) {
      setPopupItems(getFormPopupItems(props.popupQueryResult));
    }
  }, [props.popupQueryResult]);

  const onCreatePopupItem = useCallback(
    (editedItem: IPopupItemInput) => {
      setPopupItems(popupItems?.length ? [...popupItems, editedItem] : [editedItem]);
    },
    [popupItems],
  );

  const onUpdatePopupItem = useCallback(
    (popupIndex: number, popupItem: Partial<IPopupItemInput>) => {
      if (!popupItems?.length) return;
      // overwrite old array of object
      popupItems[popupIndex] = {
        ...popupItems[popupIndex],
        ...popupItem,
      };
      setPopupItems([...popupItems]);
    },
    [popupItems],
  );

  const onUpdatePopupConfig = useCallback(
    (popupIndex: number, config: Pick<IPopupItemInput, 'configFeed' | 'configExploreBook' | 'configExploreEbook' | 'configSearch'>) => {
      const configTypeName = Object.keys(config)[0] as 'configFeed' | 'configExploreBook' | 'configExploreEbook' | 'configSearch';
      const updatedItem: IPopupItemInput = {
        [configTypeName]: {
          ...popupItems[popupIndex][configTypeName],
          ...config[configTypeName],
        },
      };

      onUpdatePopupItem(popupIndex, updatedItem);
    },
    [onUpdatePopupItem, popupItems],
  );

  const onDeletePopupItem = useCallback(
    (scheduleIndex: number) => {
      if (!popupItems?.length) return;
      const isConfirmDeleteItem = confirm('Delete this item?');
      if (isConfirmDeleteItem) {
        setPopupItems(popupItems.filter((item, index) => index !== scheduleIndex));
      }
    },
    [popupItems],
  );

  const onUpdateScheduleInSection = useCallback(
    (popupIndex: string, scheduleIndex: number, editedSchedule: ScheduleInput) => {
      if (!popupItems?.length) return;
      const sectionIndex = findIndex(popupItems, (section) => section._id === popupIndex);
      const currentSchedule = popupItems[sectionIndex].schedules?.[scheduleIndex];
      if (currentSchedule) {
        popupItems[sectionIndex].schedules![scheduleIndex] = { ...currentSchedule, ...editedSchedule };
      }
      setPopupItems([...popupItems]);
    },
    [popupItems],
  );

  const onCreateScheduleInSection = useCallback(
    (popupIndex: string, editedSchedule: ScheduleInput) => {
      if (!popupItems?.length) return;
      const sectionIndex = findIndex(popupItems, (section) => section._id === popupIndex);
      popupItems[sectionIndex].schedules = [...(popupItems[sectionIndex]?.schedules ?? []), editedSchedule];
      setPopupItems([...popupItems]);
    },
    [popupItems],
  );
  const onDeleteScheduleInSection = useCallback(
    (popupIndex: string, scheduleIndex: number) => {
      if (!popupItems?.length) return;
      const sectionIndex = findIndex(popupItems, (section) => section._id === popupIndex);
      popupItems[sectionIndex].schedules?.splice(scheduleIndex, 1);
      setPopupItems([...popupItems]);
    },
    [popupItems],
  );

  return {
    popupItems,
    onCreatePopupItem,
    onUpdatePopupItem,
    onDeletePopupItem,
    onUpdateScheduleInSection,
    onCreateScheduleInSection,
    onDeleteScheduleInSection,
    onUpdatePopupConfig,
  };
};

export default useFormPopupItems;
