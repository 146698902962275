import { createStore } from 'react-hooks-global-state';

import { modalReducer, IModalActions, IModalPayload } from './modalReducer';

type InitialStateType = {
  modals: Partial<IModalPayload>;
};

export const initialState: InitialStateType = {
  modals: {},
};

export type IAllActions = IModalActions;

const mainReducer = ({ modals }: InitialStateType, action: IAllActions) => ({
  modals: modalReducer(modals as IModalPayload, action as IModalActions) as IModalPayload,
});

export const { dispatch, useStoreState } = createStore(mainReducer, initialState);
