import { useCallback, useEffect, useRef, useState } from 'react';
import ReactCropper from 'react-cropper';
import { MdZoomIn, MdZoomOut, MdZoomOutMap, MdRotateLeft, MdRotateRight } from 'react-icons/md';

import 'cropperjs/dist/cropper.css';
import Button from './Button';
import RangeSlider from './RangeSlider';

export interface IPropsImageCropper {
  base64Image?: string;
  imageWidth: number;
  imageHeight: number;
  setCropper: (cropper: Cropper) => void;
}

const IMG_ZOOM_CONFIG = {
  min: 0,
  max: 100,
  step: 10,
};

const ImageCropper = (props: IPropsImageCropper) => {
  const { imageWidth, imageHeight, base64Image, setCropper } = props;
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [cropperInstance, setCropperInstance] = useState<Cropper>();
  const [canvasWidth, setCanvasWidth] = useState<number>(0);
  const [windowWidth, setWindowWidth] = useState<number>();
  const ratioImage = imageWidth / imageHeight;
  const [zoomValue, setZoomValue] = useState<number>(0);

  const zoomImg = useCallback(
    (num: number) => {
      if (cropperInstance) {
        const coverCropData = cropperInstance.getImageData();
        if (coverCropData.width <= coverCropData.naturalWidth * 1.5 || num < 0) {
          cropperInstance.zoom(num);
        }
      }
    },
    [cropperInstance],
  );

  const rotateImg = useCallback(
    (degree: number) => {
      if (cropperInstance) {
        cropperInstance.rotate(degree);
      }
    },
    [cropperInstance],
  );

  const handleZoomOut = useCallback(() => {
    if (cropperInstance && zoomValue > IMG_ZOOM_CONFIG.min) {
      const decreasedValue = zoomValue - IMG_ZOOM_CONFIG.step;
      setZoomValue(decreasedValue);
      zoomImg(-0.1);
    }
  }, [cropperInstance, zoomValue, zoomImg]);

  const handleZoomIn = useCallback(() => {
    if (cropperInstance && zoomValue < IMG_ZOOM_CONFIG.max) {
      const increasedValue = zoomValue + IMG_ZOOM_CONFIG.step;
      setZoomValue(increasedValue);
      zoomImg(0.1);
    }
  }, [cropperInstance, zoomValue, zoomImg]);

  const handleZoomFit = useCallback(() => {
    setZoomValue(IMG_ZOOM_CONFIG.min);
    if (cropperInstance) {
      zoomImg(-9999999999);
    }
  }, [cropperInstance, zoomImg]);

  const handleSliderChange = useCallback(
    (sliderValue: number) => {
      if (cropperInstance) {
        if (zoomValue < sliderValue) {
          // NOTES: zoom in
          const zoomed = (sliderValue - zoomValue) / 100;
          zoomImg(zoomed);
        } else {
          // NOTES: zoom out
          const zoomed = (zoomValue - sliderValue) / 100;
          zoomImg(-zoomed);
        }
        setZoomValue(sliderValue);
      }
    },
    [cropperInstance, zoomValue, zoomImg],
  );

  const handleRotateLeft = useCallback(() => {
    if (cropperInstance) {
      rotateImg(-90);
      zoomImg(-9999999999);
      setZoomValue(IMG_ZOOM_CONFIG.min);
    }
  }, [cropperInstance, rotateImg, zoomImg]);

  const handleRotateRight = useCallback(() => {
    if (cropperInstance) {
      rotateImg(90);
      zoomImg(-9999999999);
      setZoomValue(IMG_ZOOM_CONFIG.min);
    }
  }, [cropperInstance, rotateImg, zoomImg]);

  useEffect(() => {
    const { innerWidth } = window;
    setWindowWidth(innerWidth);
  }, []);

  useEffect(() => {
    const newCanvasWidth = wrapperRef?.current?.offsetWidth || 100;
    if (canvasWidth !== newCanvasWidth) {
      setCanvasWidth(newCanvasWidth);
      if (cropperInstance?.setCropBoxData) cropperInstance.setCropBoxData({ left: 0, top: 0, width: newCanvasWidth });
    }
  }, [wrapperRef, windowWidth, canvasWidth, cropperInstance]);

  return (
    <div className="w-full" ref={wrapperRef}>
      {base64Image && canvasWidth && (
        <ReactCropper
          src={base64Image}
          style={{ height: canvasWidth / ratioImage, width: canvasWidth }}
          dragMode="move"
          autoCropArea={1}
          aspectRatio={ratioImage}
          guides={false}
          zoomOnWheel={false}
          viewMode={3}
          cropBoxMovable={false}
          cropBoxResizable={false}
          restore={false}
          modal={false}
          highlight={false}
          toggleDragModeOnDblclick={false}
          responsive
          onInitialized={(instance) => {
            setCropper(instance);
            setCropperInstance(instance);
          }}
        />
      )}
      {/* Control center */}
      <div className="mt-[12px] flex flex-col items-center">
        {/* Zoom */}
        <div className="flex w-[256px] items-center md:w-[300px]">
          <Button type="outline" color="black" onClick={handleZoomOut}>
            <MdZoomOut size="28px" />
          </Button>
          <RangeSlider
            className="mx-8"
            min={IMG_ZOOM_CONFIG.min}
            max={IMG_ZOOM_CONFIG.max}
            step={IMG_ZOOM_CONFIG.step}
            sliderValue={zoomValue}
            onSliderChange={handleSliderChange}
          />
          <Button type="outline" color="black" onClick={handleZoomIn}>
            <MdZoomIn size="28px" />
          </Button>
        </div>
        {/* Rotate */}
        <div className="mt-4 flex justify-center space-x-4">
          <Button type="ghost" color="black" className="p-[8px]" onClick={handleZoomFit}>
            <MdZoomOutMap size="28px" />
          </Button>
          <Button type="ghost" color="black" className="p-[8px]" onClick={handleRotateLeft}>
            <MdRotateLeft size="28px" />
          </Button>
          <Button type="ghost" color="black" className="p-[8px]" onClick={handleRotateRight}>
            <MdRotateRight size="28px" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ImageCropper;
