import { useEffect } from 'react';

import { useEbookOptionsLazyQuery } from '../core/graphql/operation-hooks';

const useEbookOptions = () => {
  const [queryEbookOptions, { data, loading }] = useEbookOptionsLazyQuery({ fetchPolicy: 'network-only' });

  useEffect(() => {
    queryEbookOptions();
  }, [queryEbookOptions]);

  return {
    languageOptions: data?.languageOptions.languages,
    priceTierOptions: {
      google: data?.googlePriceTierOptions.priceTiers,
      apple: data?.applePriceTierOptions.priceTiers,
    },
    isLoadingEbookOptions: loading,
  };
};

export default useEbookOptions;
