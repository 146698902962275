import { ReactNode } from 'react';
import { Modal as OverlayModal } from 'react-overlays';

import Icon from './Icon';
import css from './Modal.module.scss';

interface IPropsModal {
  children: ReactNode;
  onClose?: () => void;
  isCloseOnBackdropClick?: boolean;
  maxWidthModal: number;
  backdropStyle?: React.CSSProperties;
  closeButtonStyle?: React.CSSProperties;
}

const Modal = (props: IPropsModal): JSX.Element => {
  return (
    <OverlayModal
      style={{
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        overflowY: 'scroll',
        WebkitOverflowScrolling: 'touch',
        zIndex: 1001,
        padding: '0 24px',
      }}
      renderBackdrop={(): JSX.Element => <div className={css.backdrop} onClick={props.isCloseOnBackdropClick ? props.onClose : undefined} style={props.backdropStyle} />}
      autoFocus={false}
      containerClassName="openedModal"
      show
    >
      <div
        className="relative w-full mx-auto my-32 md:my-64 rounded-12 bg-componentsBgGrouped02"
        style={{ zIndex: 1001, maxWidth: props.maxWidthModal, boxShadow: '0 10px 50px 0 var(--opacityBlack20)' }}
      >
        {props.onClose && (
          <button
            className="flex absolute z-[1001] top-[16px] right-[16px] content-center justify-center border-none rounded-[50%] bg-transparent cursor-pointer"
            onClick={props.onClose}
            style={props.closeButtonStyle}
          >
            <Icon name="close" size="16px" color="var(--systemGrays02LabelSecondary)" />
          </button>
        )}
        {props.children}
      </div>
    </OverlayModal>
  );
};

export default Modal;
