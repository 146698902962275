import numeral from 'numeral';

export const toFixedWithoutRound = (number: number | null, fixed: number): number => {
  if (!number) return 0;
  const re = new RegExp('^-?\\d+(?:.\\d{0,' + (fixed || -1) + '})?');
  return Number(number.toString().match(re)![0]);
};

export const numberWithCommas = (number?: number | null): string | undefined => {
  if (typeof number !== 'number') return undefined;
  return numeral(number).format('0,0');
};

export const numberWithCommasWithDecimalPlace = (number?: number | null): string | undefined => {
  if (typeof number !== 'number') return undefined;
  return numeral(number || 0).format('0,0.00');
};

export const numberWithUnits = (number?: number | null | undefined): string => {
  if (number === null || number === undefined) return '0';
  return numeral(number).format('0[.]0a').toUpperCase();
};
