import { InMemoryCache, defaultDataIdFromObject } from '@apollo/client';
import omit from 'lodash/omit';

import introspection from '../graphql/possible-types';

import { relayStylePagination } from './relayStylePagination';

const typePolicies = {
  Query: {
    fields: {
      sectionPagination: relayStylePagination((props) => JSON.stringify(omit(props?.filter, ['beforeCursor', 'afterCursor']))),
      pintoSections: relayStylePagination(),
    },
  },
};

const inMemoryCache = new InMemoryCache({
  typePolicies,
  addTypename: true,
  possibleTypes: introspection.possibleTypes,
  dataIdFromObject(responseObject) {
    switch (responseObject.__typename) {
      default:
        return defaultDataIdFromObject(responseObject);
    }
  },
});

export default inMemoryCache;
