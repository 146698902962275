import { useUpsertTemplateMutation } from '../core/graphql/operation-hooks';

const useMutateUpsertTemplate = () => {
  const [mutateUpsertTemplate, { loading }] = useUpsertTemplateMutation();
  return {
    mutateUpsertTemplate,
    isLoadingMutateUpsertTemplate: loading,
  };
};

export default useMutateUpsertTemplate;
