/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const trending = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path
      fill="#8C8C8C"
      fillRule="evenodd"
      d="M10 24.367l8.271-7.827a1 1 0 011.375 0l6.62 6.264a1 1 0 001.374 0l6.25-5.914-4.183-4.183A1 1 0 0130.414 11H44v13.586a1 1 0 01-1.707.707l-4.419-4.419L27.64 30.53a1 1 0 01-1.373-.002l-6.621-6.265a1 1 0 00-1.375 0L10 32.089v6.046h33a1 1 0 011 1V43a1 1 0 01-1 1H4V5a1 1 0 011-1h4a1 1 0 011 1v19.367z"
    />
  </svg>
);

export default trending;
