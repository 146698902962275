/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const explore = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path
      fill="#8C8C8C"
      fillRule="evenodd"
      d="M24 2c12.15 0 22 9.85 22 22s-9.85 22-22 22S2 36.15 2 24 11.85 2 24 2zm12.336 10.62c.195-.501-.378-1.074-.878-.879l-.348.133-16.498 6.32a.669.669 0 00-.392.397l-6.335 16.526-.122.316a.688.688 0 00.153.724.686.686 0 00.73.158l.32-.122 16.526-6.338a.697.697 0 00.39-.395l6.32-16.489.134-.35zm-14.458 9.257a3.005 3.005 0 014.244 0 3.004 3.004 0 010 4.245 3.003 3.003 0 01-4.244 0 3.004 3.004 0 010-4.245z"
    />
  </svg>
);

export default explore;
