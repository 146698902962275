import { useCallback, useEffect, useMemo, useState } from 'react';
import { MdSearch, MdClose } from 'react-icons/md';
import InfiniteScroll from 'react-infinite-scroller';
import { Link } from 'react-router-dom';

import { SectionLayoutId, SectionsFilterSortBy } from '../../../core/graphql/types';
import useQueryPintoSections from '../../../hooks/useQueryPintoSections';
import SectionListSorting, { SORTING } from '../../sections/components/SectionListSorting';

export interface IModalSectionItem {
  id: string;
  note?: string | null;
  _id?: string;
}

export interface IPropsSectionListModal {
  initialItems?: IModalSectionItem[];
  onClose?: () => void;
  onConfirm: (selectedItems: IModalSectionItem[]) => void;
}

const SectionListModal = (props: IPropsSectionListModal) => {
  const { onClose, onConfirm, initialItems = [] } = props;

  const [searchKeyword, setSearchKeyword] = useState<string | undefined>('');
  const [sortBy, setSortBy] = useState<SectionsFilterSortBy>(SORTING[0].value);
  const { isLoadingPintoSections, onFetchMorePintoSections, sections: dataSections, fetchPintoSections } = useQueryPintoSections();
  const [selectedItems, setSelectedItems] = useState<IModalSectionItem[]>(initialItems);

  const onSearch = useCallback(() => {
    fetchPintoSections({ q: searchKeyword, sortBy });
  }, [fetchPintoSections, searchKeyword, sortBy]);

  useEffect(() => {
    onSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onToggleItem = useCallback(
    ({ id, _id, note }: { id: string; note?: string | null; _id: string }) => {
      let newSelectedItems: IModalSectionItem[] = [];
      if (selectedItems.find((item) => item.id === id)) {
        newSelectedItems = selectedItems.filter((item) => item.id !== id);
      } else {
        newSelectedItems = [...selectedItems, { id, note, _id }];
      }
      setSelectedItems(newSelectedItems);
    },
    [selectedItems],
  );

  const onChangeSortBy = useCallback(
    (value: SectionsFilterSortBy) => {
      setSortBy(value);
      onSearch();
    },
    [onSearch],
  );

  const mapLayoutName = useCallback((camelCaseLayoutId: SectionLayoutId) => {
    switch (camelCaseLayoutId) {
      case SectionLayoutId.MainBanner:
        return 'Main Banner';
      case SectionLayoutId.BookBanner:
        return 'Book Banner';
      case SectionLayoutId.BookCover:
        return 'Book Cover';
      case SectionLayoutId.BookCover2:
        return 'Book Cover 2';
      case SectionLayoutId.TagCloud:
        return 'Tag Cloud';
      case SectionLayoutId.Rank:
        return 'Rank';
      case SectionLayoutId.StaticBanner:
        return 'Static Banner';
      // extra
      case SectionLayoutId.LibraryAd:
        return 'Library Ad';
      case SectionLayoutId.LinkBanner:
        return 'Link Banner';
      case SectionLayoutId.LinkBannerFullWidth:
        return 'Link Banner Full Width';
      default:
        return '';
    }
  }, []);

  const sections = useMemo(
    () =>
      dataSections?.edges.map(({ node }) => ({
        id: node._id,
        note: node.note,
        _id: node._id,
        layout: node?.camelCaseLayoutId ? mapLayoutName(node.camelCaseLayoutId) : '-',
      })),
    [dataSections?.edges, mapLayoutName],
  );

  return (
    <>
      <div className="modal modal-open">
        <div className="modal-box relative max-w-[960px]">
          {/* title */}
          <button className="absolute p-12 right-2 top-2" onClick={onClose}>
            <MdClose size={22} />
          </button>
          <h3 className="text-lg font-bold font-dbh text-systemGrays01LabelPrimary">Add Section</h3>

          {/* sort and create section */}
          <div className="flex items-center justify-between w-full mt-4">
            <SectionListSorting onSortingChange={onChangeSortBy} />
            <Link to="/feedAndSection/sections/edit" className="text-sm underline">
              Create New Section
            </Link>
          </div>

          <div className="flex w-full mt-4 space-x-4">
            <input
              name="keyword"
              placeholder="search"
              value={searchKeyword}
              onChange={(e) => setSearchKeyword?.(e.target.value)}
              className="w-full font-semibold input input-bordered font-tt text-systemGrays02LabelSecondary"
            />
            <button className="btn" onClick={() => onSearch?.()}>
              <span>
                <MdSearch size={22} />
              </span>
              Search
            </button>
          </div>

          {/* content */}
          <div className="w-full flex justify-between mt-6 h-[calc(100vh-400px)] overflow-y-scroll bg-gray-50 rounded-box py-4">
            {!isLoadingPintoSections && sections?.length === 0 && <div className="flex items-center justify-center w-full p-32">Not found</div>}
            {isLoadingPintoSections && <div className="flex items-center justify-center w-full p-32">Loading...</div>}
            {!isLoadingPintoSections && (sections?.length ?? 0) > 0 && (
              <InfiniteScroll
                className="w-full"
                loadMore={onFetchMorePintoSections}
                hasMore={!!dataSections?.pageInfo.hasNextPage}
                loader={<div className="flex items-center justify-center p-32">Loading...</div>}
                useWindow={false}
              >
                {sections?.map((section) => (
                  <div className="inline-flex items-start w-full px-4 py-4 cursor-pointer form-control md:w-1/2 hover:bg-systemGrays08FillQuaternary" key={section?.id}>
                    <div className="flex items-center justify-between w-full">
                      <label className="cursor-pointer label">
                        <input
                          type="checkbox"
                          className="checkbox checkbox-sm"
                          checked={!!selectedItems.find((item) => item.id === section.id)}
                          onChange={() => onToggleItem({ id: section.id, note: section.note, _id: section._id })}
                        />
                        <span className="ml-4 label-text max-w-[230px] lg:max-w-[300px] truncate">{section?.note || '[no note data]'}</span>
                      </label>
                      <div className="flex space-x-4">
                        <span className="badge badge-outline">{section.layout}</span>
                      </div>
                    </div>
                  </div>
                ))}
              </InfiniteScroll>
            )}
          </div>

          <div className="flex items-center justify-between w-full mt-6">
            <h3 className="text-lg font-bold font-dbh text-systemGrays01LabelPrimary">Select {selectedItems.length} items</h3>
            <button
              className="btn btn-error"
              onClick={() => {
                onConfirm(selectedItems);
                onClose?.();
              }}
            >
              Accept
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SectionListModal;
