/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const ebook = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path
      fill="#8C8C8C"
      fillRule="evenodd"
      d="M43 34.001c0 1.25-.417 2.011-.958 2.596a4.023 4.023 0 01-.69.64c-.858.714-1.729 1.322-1.729 2.764 0 1.645.85 2.615 2.549 2.911a1 1 0 01.828.985V45a1 1 0 01-1 1H12a6 6 0 01-6-6V8a6 6 0 016-6h27a4 4 0 014 4v28.001zM15 38a2 2 0 100 4h19.466a4.988 4.988 0 01-.407-1.942c0-.648.135-1.334.407-2.058H15zm-1.105-23h20.1a3.671 3.671 0 00.03-4.967l-.03-.033h-20.1l-.029.037a3.993 3.993 0 00.03 4.963zm.057 10h20.026a3.63 3.63 0 00.03-4.968l-.03-.032H13.952l-.03.035a3.82 3.82 0 00.03 4.965z"
    />
  </svg>
);

export default ebook;
