import classNames from 'classnames';
import { useState, ChangeEvent, useEffect } from 'react';
import { FiEdit } from 'react-icons/fi';

import { EbookFileType } from '../../../core/graphql/types';

export interface IPropsRejectModal {
  onClose?: () => void;
  onSubmit?: (data: { rowId: string; ebookId: string; rejectNote: string; fileTypes: EbookFileType[] }) => void;
  title?: string;
  subTitle?: string;
  cancelText?: string;
  confirmText?: string;
  errorMsg?: string;
  placeholderRejectNote?: string;
  validationMessage?: string;
  requestedFileTypes: EbookFileType[];
  ebookId: string; // Receive ID as a prop from the parent
  rowId: string; // Receive ID as a prop from the parent
}

const RejectModal = (props: IPropsRejectModal) => {
  const [rejectNote, setRejectNote] = useState(''); // For reject note input
  const [isValid, setIsValid] = useState(true);
  const [localErrorMsg, setLocalErrorMsg] = useState('');

  const {
    onClose,
    onSubmit,
    cancelText,
    confirmText,
    title,
    subTitle,
    errorMsg,
    placeholderRejectNote,
    validationMessage = 'Please enter a rejection note.',
    ebookId, // ID received from parent
    rowId,
    requestedFileTypes, // requestedFileTypes received from parent
  } = props;

  useEffect(() => {
    if (errorMsg) {
      setIsValid(false);
      setLocalErrorMsg(errorMsg);
    }
  }, [errorMsg]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setRejectNote(value);

    // If the input is empty, set validation error
    if (value.trim() === '') {
      setIsValid(false);
      setLocalErrorMsg(validationMessage);
    } else {
      setIsValid(true);
      setLocalErrorMsg('');
    }
  };

  const handleConfirm = () => {
    if (isValid && rejectNote.trim() !== '') {
      onSubmit?.({ rowId, ebookId, rejectNote, fileTypes: requestedFileTypes }); // Send both the id and rejectNote back to the parent
    }
  };

  const handleClose = () => {
    onClose?.();
    setRejectNote('');
    setIsValid(true);
    setLocalErrorMsg(''); // Reset localErrorMsg when closing
  };

  return (
    <div className="modal modal-open">
      <div className="modal-box relative">
        <h3 className="text-lg font-bold font-dbh text-systemGrays01LabelPrimary">{title || 'Reject Request'}</h3>
        {subTitle && <p className="font-tt font-bold py-8 text-systemGrays02LabelSecondary">{subTitle}</p>}

        {/* Reject Note Input Field */}
        <div className="relative mb-4">
          <FiEdit className="absolute top-[16px] left-[12px] text-colorsBrandWarmBlack02LabelSecondary text-18" />
          <input
            id="rejectNote"
            name="rejectNote"
            type="text"
            placeholder={placeholderRejectNote ?? 'Enter reject note'}
            className={classNames('w-full pl-36 input input-bordered', {
              'border-red-500': !isValid,
            })}
            onChange={handleChange}
            value={rejectNote}
          />
          {!isValid && <p className="text-red-500 text-xs mt-1">{localErrorMsg}</p>}
        </div>

        <div className="w-full flex justify-end space-x-6 pt-24">
          <button className="btn btn-outline btn-error" onClick={handleClose}>
            {cancelText || 'Cancel'}
          </button>
          <button
            className={classNames('btn btn-error', { 'opacity-50 cursor-not-allowed': !isValid || !rejectNote.trim() })}
            onClick={handleConfirm}
            disabled={!isValid || !rejectNote.trim()} // Disable if input is not valid or empty
          >
            {confirmText || 'Reject'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default RejectModal;
