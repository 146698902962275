import classNames from 'classnames';
import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

import { EbookWritingType } from '../../../core/graphql/types';
import { EBOOK_BUILDER_FIELD_KEYS, IEbookBuilderFields } from '../validates/ebookBuilderSchema';

const WRITING_TYPE_OPTIONS = [
  { label: 'รวมเล่ม', value: EbookWritingType.Bundle },
  { label: 'รายตอน', value: EbookWritingType.Chapter },
];

const WritingTypeSelector = () => {
  const { setValue, watch } = useFormContext();
  const [writingType = EbookWritingType.Bundle] = watch([EBOOK_BUILDER_FIELD_KEYS.WRITING_TYPE]);
  const onClickWritingType = useCallback(
    (value: EbookWritingType) => {
      setValue(EBOOK_BUILDER_FIELD_KEYS.WRITING_TYPE as IEbookBuilderFields, value);
    },
    [setValue],
  );
  return (
    <section className="flex flex-col">
      <label className="label">
        <span className="label-text text-systemGrays02LabelSecondary">
          ประภทการ์ตูน <span className="text-colorsBrandRed01Primary">*</span>
        </span>
      </label>
      <div className="space-x-16">
        {WRITING_TYPE_OPTIONS.map((option) => (
          <button
            key={option.label}
            className={classNames('btn btn-outline border px-8', {
              'border-colorsBrandRed01Primary text-colorsBrandRed01Primary': writingType === option.value,
            })}
            onClick={() => onClickWritingType?.(option.value)}
          >
            {option.label}
          </button>
        ))}
      </div>
    </section>
  );
};

export default WritingTypeSelector;
