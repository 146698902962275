/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const textSize = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path
      fill="#8C8C8C"
      fillRule="evenodd"
      d="M27.302 30.505c.428-1.48 1.307-2.687 2.636-3.618 1.962-1.374 4.769-1.936 8.417-1.936h3.183v-1.707c0-1.247-.36-1.593-1.078-2.344-.667-.698-1.745-1.071-3.06-1.12a8.144 8.144 0 00-.301-.005c-1.268 0-2.326.308-3.135.925-.442.337-.866.879-1.272 1.627a1 1 0 01-.879.523h-3.63a1 1 0 01-.955-1.297c.41-1.321.847-2.276 1.308-2.866.9-1.15 2.126-2.053 3.675-2.706 1.55-.654 3.28-.981 5.189-.981 2.9 0 5.214.71 6.94 2.128 1.724 1.419 2.61 3.412 2.656 5.983V39a1 1 0 01-1 1h-2.84a1 1 0 01-.964-.735c-.128-.466-.192-1.12-.192-1.961-1.54 1.85-2.916 2.955-6.347 2.955-2.943 0-4.315-.699-5.638-1.77A2 2 0 0128.075 40H24.88a2 2 0 01-1.87-1.29L20.465 32H9.514L7 38.702A2 2 0 015.128 40H2a2 2 0 01-1.864-2.725l10.89-28A2 2 0 0112.89 8h4.277a2 2 0 011.863 1.274l8.272 21.23zM14.966 16l-3.428 10h7l-3.572-10zm24.83 18.304c.97-.42 1.699-1.18 2.187-1.887v-3.465h-3c-2.062 0-3.612.282-4.65.845-1.038.562-1.558 1.358-1.558 2.388 0 .838.356 1.505 1.066 2.002.709.497 1.66.745 2.851.745 1.1 0 2.134-.21 3.104-.628z"
    />
  </svg>
);

export default textSize;
