import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { Link, useHistory, useLocation } from 'react-router-dom';

import PublishDiscountErrorModal, { IErrorItem } from '../../app/discount/modals/PublishDiscountErrorModal';
import DiscountCalendarPresenter from '../../app/discount/presenters/DiscountCalendarPresenter';
import DiscountDetailPresenter from '../../app/discount/presenters/DiscountDetailPresenter';
import DiscountFormObj, { DISCOUNT_FIELD_KEYS, IDiscountFields } from '../../app/discount/validates/discountSchema';
import ConfirmModal from '../../app/modals/ConfirmModal';
import Breadcrumbs from '../../components/Breadcrumbs';
import Button from '../../components/Button';
import Card from '../../components/Card';
import LeavePageAlert from '../../components/LeavePageAlert';
import ToastComponent, { IToastType } from '../../components/Toast/ToastComponent';
import { CreateEbookDiscountCampaignInput } from '../../core/graphql/types';
import { localStorageItemsKeyName } from '../../core/localStorageItemsKeyName';
import useMutationPintoCancelEbookDiscountCampaign from '../../hooks/useMutationPintoCancelEbookDiscountCampaign';
import useMutationPintoCreateEbookDiscountCampaign from '../../hooks/useMutationPintoCreateEbookDiscountCampaign';
import useQueryPintoEbookDiscountCampaign from '../../hooks/useQueryPintoEbookDiscountCampaign';
import { convertFileToBase64, downloadCSV } from '../../utils/blob';
import { isBeforeDate } from '../../utils/dayjs';
import useHookForm from '../../utils/form/useHookForm';
import { getItemLocalStorage, removeItemLocalStorage } from '../../utils/localStorage';

const CSV_URL_PATH = '../../a/template/Discount_ebook_Template.csv';

export const FEED_PREVIEW_SLUG = 'feed-preview';

const CreateDiscountController = () => {
  const history = useHistory();
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const discountId = searchParams.get('id');

  const { methods, onValidate, setValue, watch } = useHookForm<IDiscountFields>({
    objectShapeForm: DiscountFormObj,
    mode: 'onChange',
  });
  const { setError } = methods;

  const [startDate, endDate, title] = watch([DISCOUNT_FIELD_KEYS.START_DATE, DISCOUNT_FIELD_KEYS.END_DATE, DISCOUNT_FIELD_KEYS.TITLE]);

  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [showConfirmTerminateDiscount, setShowConfirmTerminateDiscount] = useState<boolean>(false);
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
  const [selectedCSVFile, setSelectedCSVFile] = useState<File | undefined>(undefined);

  const { ebookDiscountCampaign, fetchPintoEbookDiscountCampaign, isLoadingPintoEbookDiscountCampaign } = useQueryPintoEbookDiscountCampaign();
  const { onMutatePintoCreateEbookDiscountCampaign } = useMutationPintoCreateEbookDiscountCampaign();
  const { cancelEbookDiscountCampaign } = useMutationPintoCancelEbookDiscountCampaign();

  const [errorItems, setErrorsItems] = useState<IErrorItem[] | undefined>(undefined);
  const [showErrorAlert, setShowErrorAlert] = useState<boolean>(true);

  useEffect(() => {
    if (discountId) {
      fetchPintoEbookDiscountCampaign(discountId);
    }
  }, [discountId, fetchPintoEbookDiscountCampaign]);

  const onResumeState = useCallback(() => {
    setValue(DISCOUNT_FIELD_KEYS.START_DATE, new Date(ebookDiscountCampaign?.startedAt) as never);
    setValue(DISCOUNT_FIELD_KEYS.END_DATE, new Date(ebookDiscountCampaign?.endedAt) as never);
    setValue(DISCOUNT_FIELD_KEYS.IS_HAS_DISCOUNT_END_DATE as IDiscountFields, !!ebookDiscountCampaign?.endedAt);
    setValue(DISCOUNT_FIELD_KEYS.TITLE, ebookDiscountCampaign?.title as never);
  }, [ebookDiscountCampaign?.endedAt, ebookDiscountCampaign?.startedAt, ebookDiscountCampaign?.title, setValue]);

  useEffect(() => {
    if (ebookDiscountCampaign) {
      onResumeState();
    }
  }, [ebookDiscountCampaign, onResumeState]);

  const onSelectCSVFile = useCallback(
    (file: File | undefined) => {
      setSelectedCSVFile(file);
      setValue(DISCOUNT_FIELD_KEYS.FILE_NAME, file?.name as never, {
        shouldValidate: true,
      });
    },
    [setValue],
  );

  const onSubmitScheduledDiscount = useCallback(async () => {
    setShowConfirmModal(false);

    if (!selectedCSVFile || !title || !startDate) return;

    const fileBase64 = await convertFileToBase64(selectedCSVFile);
    const input = {
      title,
      fileName: selectedCSVFile?.name,
      file: fileBase64,
      startedAt: dayjs(startDate).set('millisecond', 0).toISOString(),
      endedAt: endDate ? dayjs(endDate).set('millisecond', 0).toISOString() : null,
    };

    const res = await onMutatePintoCreateEbookDiscountCampaign(input as CreateEbookDiscountCampaignInput);
    const errItems: IErrorItem[] | undefined | string = getItemLocalStorage(localStorageItemsKeyName.DISCOUNT_CAMPAIGN_ERROR_ITEMS) || undefined;

    if (errItems) {
      setErrorsItems(
        JSON.parse(errItems)?.detail?.invalidInputs?.map(({ ebookId, title, userAccount, errorCase }: any) => ({
          ebookId,
          ebookTitle: title,
          userAccount,
          errorMessage: errorCase,
        })) as IErrorItem[],
      );
      setShowErrorModal(true);
      return;
    } else if (res.data) {
      setShowErrorAlert(false);
      ToastComponent({ label: 'Publish successfully ', type: IToastType.SUCCESS });
      history.push('/discount');
    }
  }, [endDate, history, onMutatePintoCreateEbookDiscountCampaign, selectedCSVFile, startDate, title]);

  const onSubmitTerminateDiscount = useCallback(
    (ebookDiscountCampaignId?: string) => {
      if (ebookDiscountCampaignId) {
        cancelEbookDiscountCampaign(ebookDiscountCampaignId);
        setShowConfirmTerminateDiscount(false);
        ToastComponent({ label: 'Terminate successfully', type: IToastType.SUCCESS });
      }
    },
    [cancelEbookDiscountCampaign],
  );

  const handlePublishScheduledDiscount = useCallback(async () => {
    let isValid = false;
    if (isBeforeDate(startDate, new Date(), 'minute')) {
      setError(DISCOUNT_FIELD_KEYS.START_DATE, {
        type: 'min',
        message: 'The start date must to be more than now',
      });
    }
    if (endDate && isBeforeDate(endDate, startDate, 'minute')) {
      setError(DISCOUNT_FIELD_KEYS.END_DATE, {
        type: 'min',
        message: 'The end date must more then the start date',
      });
    } else {
      isValid = await onValidate();
    }
    if (isValid) {
      setShowConfirmModal(true);
    }
  }, [endDate, onValidate, setError, startDate]);

  const onClickDownloadCSVTemplate = useCallback(() => {
    downloadCSV(CSV_URL_PATH, 'discount_ebook_template.csv');
  }, []);

  return (
    <div>
      {isLoadingPintoEbookDiscountCampaign && <div className="w-full btn btn-outline btn-disabled bg-systemGrays04LabelQuaternary border-none loading h-[100px]" />}
      {!isLoadingPintoEbookDiscountCampaign && (
        <>
          <LeavePageAlert showConfirmLeavePageAlert={showErrorAlert && !ebookDiscountCampaign?._id} message="All change will be discarded, Are you sure to leave this page ?" />

          {showConfirmModal && (
            <ConfirmModal
              onClose={() => setShowConfirmModal(false)}
              onConFirm={onSubmitScheduledDiscount}
              title="Publish"
              subTitle="Schedule will automatically publish when in due course,
          Are you confirm to publish?"
            />
          )}

          {showConfirmTerminateDiscount && (
            <ConfirmModal
              onClose={() => setShowConfirmTerminateDiscount(false)}
              onConFirm={() => onSubmitTerminateDiscount(ebookDiscountCampaign?._id)}
              title="Terminate"
              subTitle="Discount will be cancel and price of e-book will change
          to original price. Are you confirm to terminate?"
            />
          )}

          {showErrorModal && (
            <PublishDiscountErrorModal
              onConFirm={() => {
                removeItemLocalStorage(localStorageItemsKeyName.DISCOUNT_CAMPAIGN_ERROR_ITEMS);
                setShowErrorModal(false);
              }}
              errorItems={errorItems}
            />
          )}

          <div className="sticky top-0 z-50 px-5 bg-componentsBgGrouped02">
            {/* section: breadcrumbs */}
            <Breadcrumbs items={[{ name: 'Discount', url: '/discount' }, { name: `${discountId ? 'Edit' : 'Create New'} Schedule Discount` }]} />

            {/* section: title */}
            <div className="flex justify-between pt-5">
              <h1 className="font-dbh text-[28px]">{`${discountId ? 'Edit' : 'Create New'} Schedule Discount`}</h1>

              <div className="space-x-12">
                <Link to="/discount">
                  <button className="btn btn-outline">CANCEL</button>
                </Link>
                <button className="btn btn-error" onClick={handlePublishScheduledDiscount} disabled={!!ebookDiscountCampaign}>
                  Publish
                </button>
              </div>
            </div>

            {/* section: main */}
            <div className="mb-0 divider opacity-80" />
          </div>

          <Card className="flex flex-row w-full space-x-24">
            <div className="flex-1">
              <FormProvider {...methods}>
                <DiscountDetailPresenter
                  selectedCSVFile={selectedCSVFile}
                  onSelectCSVFile={onSelectCSVFile}
                  status={ebookDiscountCampaign?.status}
                  onClickDownloadCSVTemplate={onClickDownloadCSVTemplate}
                  fileName={ebookDiscountCampaign?.fileName}
                  fileUrl={ebookDiscountCampaign?.fileUrl}
                  readOnly={!!ebookDiscountCampaign}
                />
              </FormProvider>
            </div>

            {/* section: calendar */}
            <div className="flex-[0.8] p-12">
              <FormProvider {...methods}>
                <DiscountCalendarPresenter />
              </FormProvider>
            </div>
          </Card>

          {discountId && ebookDiscountCampaign?._id && (
            <div className="sticky bottom-0 z-50 flex items-center justify-end p-24 mt-16 bordered border-componentsDivider bg-componentsBgGrouped02">
              <Button type="fill" color="red" onClick={() => setShowConfirmTerminateDiscount(true)}>
                Terminate
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default CreateDiscountController;
