/* eslint-disable no-useless-escape */
import * as yup from 'yup';
import { AnySchema } from 'yup';
import Lazy from 'yup/lib/Lazy';
import Reference from 'yup/lib/Reference';

import { IErrorObject } from '../../../utils/validator';

export enum SUBSCRIPTION_BUILDER_FIELD_KEYS {
  // Subscription Detail
  TITLE = 'title',
  USER_ID = 'userId',
  SLUG = 'slug',

  // Price setting
  ORIGINAL_PRICE_THB = 'originalPriceThb',
  GOOGLE_ORIGINAL_PRICE_TIER_ID = 'googleOriginalPriceTierId',
  APPLE_ORIGINAL_PRICE_TIER_ID = 'appleOriginalPriceTierId',
}

export interface IErrorsSubscriptionBuilder {
  // Subscription detail
  userId?: IErrorObject;
  title?: IErrorObject;
  slug?: IErrorObject;

  // pricing
  // originalPriceThb?: IErrorObject;
  // // in-app pricing
  // googleOriginalPriceTierId?: IErrorObject;
  // appleOriginalPriceTierId?: IErrorObject;
}

export type ISubscriptionBuilderFields = keyof IErrorsSubscriptionBuilder;

const shortNameRegex = /^[a-z0-9]{1,15}$/;

const SubscriptionBuilderFormObj: { [key in ISubscriptionBuilderFields]: AnySchema<unknown, unknown, unknown> | Reference<unknown> | Lazy<never, unknown> } = {
  // Subscription detail
  userId: yup.string().required(),
  title: yup.string().max(150).trim().required(),
  // slug: yup.string().max(15).trim().required(),
  slug: yup.string().required('short name is a required field').matches(shortNameRegex, {
    message: 'เป็นตัวอักษรภาษาอังกฤษตัวพิมพ์เล็กทั้งหมด,ไม่มีช่องว่าง,ไม่มีอักขระพิเศษ',
    excludeEmptyString: true, // This ensures the message is not used when the string is empty
  }),
  // pricing
  // originalPriceThb: yup
  //   .number()
  //   .when(['isFree'], {
  //     is: (isFree: boolean) => !isFree,
  //     then: (schema) => schema.required('กรุณากรอกราคาอีบุ๊ก'),
  //   })
  //   .when(['isFree'], {
  //     is: (isFree: boolean) => isFree,
  //     then: (schema) => schema.nullable(),
  //   }),
  // googleOriginalPriceTierId: yup.string().nullable(),
  // appleOriginalPriceTierId: yup.string().nullable(),
};

export default SubscriptionBuilderFormObj;
