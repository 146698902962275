import classnames from 'classnames';
import React, { CSSProperties } from 'react';
import { useInView } from 'react-intersection-observer';

import css from './Image.module.scss';

interface IImageProps {
  src?: string;
  defaultImageUrl?: string;
  errorImageUrl?: string;
  alt?: string;
  maxWidth?: string;
  aspectRatio?: number;
  placeholderColor?: string;
  lazy?: boolean;
  className?: string;
}

const defaultProps = {
  aspectRatio: 1,
  placeholderColor: '#b3b3b3',
  lazy: false,
};

const Image = (props: IImageProps): JSX.Element => {
  const [imageMissing, setImageMissing] = React.useState(false);
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  const handleError = (): void => {
    setImageMissing(true);
  };

  return (
    <div
      className={classnames(css.outerContainer, props.className)}
      //use props inlinestyle to pass to css variable module
      style={
        {
          '--aspectRatio': props.aspectRatio,
          '--backgroundColor': props.placeholderColor,
          '--maxWidth': props.maxWidth,
        } as CSSProperties
      }
      ref={ref}
    >
      <div className={css.innerContainer}>
        {!props.lazy || inView ? (
          <img onError={handleError} src={imageMissing ? props.errorImageUrl || props.defaultImageUrl : props.src || props.defaultImageUrl} alt={props.alt} />
        ) : undefined}
      </div>
    </div>
  );
};

Image.defaultProps = defaultProps;

export default Image;
