import { useCallback } from 'react';
import { FiCheck } from 'react-icons/fi';

import { IModalNames } from '../core/context/modalReducer';
import useModal from '../core/hook/context/useModal';

export interface IPropsConfirmModal {
  title?: string;
  description?: string;
  confirmButtonLabel?: string;
  onConfirm?: () => void;
}

const ConfirmModal = (props: IPropsConfirmModal) => {
  const { title, description, confirmButtonLabel, onConfirm } = props;
  const { removeModal } = useModal();
  const onConfirmModal = useCallback(() => {
    if (onConfirm) onConfirm();
    else removeModal(IModalNames.CONFIRM);
  }, [onConfirm, removeModal]);
  return (
    <div className="modal modal-open">
      <div className="modal-box relative bg-colorsWhite w-[px]">
        <div className="flex flex-col p-16 items-center space-y-24">
          <FiCheck size={60} color={'var(--colorsBrandRed01Primary)'} />
          <h4 className="mt-8 text-center font-opn text-22 font-semibold text-colorsBrandWarmBlack01LabelPrimary">{title || 'ConfirmModal'}</h4>
          {description && <span>{description}</span>}
          <button className="btn w-full btn-error" onClick={onConfirmModal}>
            {confirmButtonLabel || 'ตกลง'}
          </button>
        </div>
      </div>
    </div>
  );
};
export default ConfirmModal;
