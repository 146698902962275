import { PopupItemType } from '../../../../core/graphql/types';
import { IPopupItemInput } from '../../hooks/useFormPopupItems';

interface IPropAddPopupItemOptionsButton {
  onCreatePopupItem: (createPopupItem: IPopupItemInput) => void;
}

const AddPopupItemOptionsButton = ({ onCreatePopupItem }: IPropAddPopupItemOptionsButton) => {
  return (
    <div className="dropdown">
      <button tabIndex={0} className="btn btn-outline">
        + Add item
      </button>
      <ul tabIndex={0} className="p-2 shadow menu dropdown-content bg-base-100 rounded-box w-52">
        <li>
          <button className="m-1 btn btn-ghost" onClick={() => onCreatePopupItem({ type: PopupItemType.Book })}>
            Book
          </button>
        </li>
        <li>
          <button className="m-1 btn btn-ghost" onClick={() => onCreatePopupItem({ type: PopupItemType.Ebook })}>
            EBook
          </button>
        </li>
        <li>
          <button className="m-1 btn btn-ghost" onClick={() => onCreatePopupItem({ type: PopupItemType.ProductSet })}>
            ProductSet
          </button>
        </li>
        <li>
          <button className="m-1 btn btn-ghost" onClick={() => onCreatePopupItem({ type: PopupItemType.Section })}>
            Section
          </button>
        </li>
        <li>
          <button className="m-1 btn btn-ghost" onClick={() => onCreatePopupItem({ type: PopupItemType.Feed })}>
            Feed
          </button>
        </li>
        <li>
          <button className="m-1 btn btn-ghost" onClick={() => onCreatePopupItem({ type: PopupItemType.Link })}>
            Link
          </button>
        </li>
        <li>
          <button className="m-1 btn btn-ghost" onClick={() => onCreatePopupItem({ type: PopupItemType.ExploreBook, configExploreBook: {} })}>
            Explore Book
          </button>
        </li>
        <li>
          <button className="m-1 btn btn-ghost" onClick={() => onCreatePopupItem({ type: PopupItemType.ExploreEbook, configExploreEbook: {} })}>
            Explore Ebook
          </button>
        </li>
      </ul>
    </div>
  );
};

export default AddPopupItemOptionsButton;
