import React from 'react';

import Pagination from '../../../components/Pagination';
import DiscountListItem, { IPropsDiscountListItem } from '../components/DiscountListItem';

interface IPropsDiscountListTablePresenter {
  currentPage: number;
  pageLimit?: number;
  totalCount?: number;
  disCountLists?: IPropsDiscountListItem[];
  onChangePage: (page: number) => void;
  onTerminate: (discountListId: string) => void;
}

const PAGE_LIMIT = 10;

const DiscountListTablePresenter = ({ disCountLists, pageLimit, totalCount, currentPage, onChangePage, onTerminate }: IPropsDiscountListTablePresenter) => {
  return (
    <div className="overflow-x-auto overflow-y-hidden">
      <table className="table w-full">
        <thead>
          <tr>
            <th>Created Date</th>
            <th>Discount Date/Time</th>
            <th>Title</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {disCountLists?.map((discountList) => (
            <DiscountListItem {...discountList} key={discountList._id} onTerminate={() => onTerminate(discountList._id)} />
          ))}
        </tbody>
      </table>

      <div className="flex items-center justify-between mt-12">
        <span className="text-systemGrays02LabelSecondary">แสดงผล 10 รายการต่อหน้า</span>

        {(totalCount || 0) > 0 && <Pagination currentPage={currentPage} totalCount={totalCount || 0} pageSize={pageLimit || PAGE_LIMIT} onPageChange={onChangePage} />}
      </div>
    </div>
  );
};

export default DiscountListTablePresenter;
