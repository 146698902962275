/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const clockCircle = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.88 12.88">
    <path d="M12.83,5.71A6.33,6.33,0,0,0,6.59,0h0A6.06,6.06,0,0,0,2.05,2l-.63-.57A.84.84,0,0,0,.5,1.32a.86.86,0,0,0-.5.79V4.58a.86.86,0,0,0,.85.88H3.57a.86.86,0,0,0,.79-.57.87.87,0,0,0-.23-1l-.82-.74A4.39,4.39,0,0,1,6.58,1.74a4.62,4.62,0,0,1,4.56,4.17A4.7,4.7,0,0,1,7.62,11,4.52,4.52,0,0,1,2.28,8a.85.85,0,0,0-1.09-.54.87.87,0,0,0-.53,1.1,6.24,6.24,0,0,0,5.93,4.3A6.38,6.38,0,0,0,8,12.72,6.44,6.44,0,0,0,12.83,5.71Z" />
    <path d="M6.3,3a.9.9,0,0,0-.9.9v2.6a.9.9,0,0,0,.36.72l2.6,2a.88.88,0,0,0,.54.18h0A.9.9,0,0,0,9.62,9a.93.93,0,0,0,.17-.67.89.89,0,0,0-.35-.59L7.2,6V3.87A.9.9,0,0,0,6.3,3Z" />
  </svg>
);

export default clockCircle;
