import classNames from 'classnames';
import { useCallback, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import WriterInputRowItem from '../components/WriterInputRowItem';

export interface IPropsEditEbookWriterNamePresenter {
  name: string;
  label: string;
  subLabel: string;
  requiredLabel?: boolean;
  placeholder?: string;
  errorsMessage?: string;
  addButtonLabel?: string;
}

function EditEbookWriterNamePresenter(props: IPropsEditEbookWriterNamePresenter) {
  const { label, subLabel, name, errorsMessage, placeholder, requiredLabel, addButtonLabel } = props;
  const { watch, setValue } = useFormContext();

  const [watchList] = watch([name]);
  const [tempArrName, setTempArrNames] = useState<string[]>([...(watchList || [])]);

  const addTempArrName = useCallback(() => {
    const newTempArrNames = [...(watchList || []), ''];
    setTempArrNames(newTempArrNames);
    setValue(name, newTempArrNames);
  }, [name, setValue, watchList]);

  const removeTempArrName = useCallback(
    (removeIndex: number) => {
      const newTempArrNames = [...(watchList || [])];
      newTempArrNames.splice(removeIndex, 1);
      setTempArrNames(newTempArrNames);
      setValue(name, newTempArrNames);
    },
    [name, setValue, watchList],
  );

  const onChangeTempArrName = useCallback(
    (index: number, value: string) => {
      const newTempArrNames = [...(watchList || [])];
      newTempArrNames[index] = value;
      setTempArrNames(newTempArrNames);
      setValue(name, newTempArrNames, { shouldValidate: true });
    },
    [name, setValue, watchList],
  );

  const isValidInput = useMemo(() => (watchList || [])?.length === 0 || watchList?.some((item: unknown) => !item), [watchList]);
  const inValid = useMemo(() => !!errorsMessage && isValidInput, [errorsMessage, isValidInput]);
  const helperText = errorsMessage;

  return (
    <div className="w-full space-y-4" data-invalid={helperText}>
      <label className="pb-0 label">
        <span className={classNames('label-text text-systemGrays02LabelSecondary', { 'text-colorsBrandRed01Primary': inValid })}>
          {label} {requiredLabel && <span className="text-colorsBrandRed01Primary">*</span>}
        </span>
      </label>
      <label className="pt-0 label">
        <span className="label-text-alt text-systemGrays02LabelSecondary font-tt">{subLabel}</span>
      </label>

      {!![...(watchList || tempArrName)].length && (
        <div className="pb-8 space-y-4">
          {[...(watchList || tempArrName)]?.map((item: string, index: number) => (
            <div
              key={index}
              className="flex items-center justify-between px-12 py-8 border-2 border-solid border-colorsBrandWarmBlack05FillPrimary bg-colorsBrandWarmBlack07FillTertiary rounded-[6px]"
            >
              <WriterInputRowItem value={item} placeholder={placeholder} onRemove={removeTempArrName} onChange={onChangeTempArrName} index={index} />
            </div>
          ))}
        </div>
      )}

      {helperText && isValidInput && (
        <label className="pt-4 label">
          <span className={classNames('label-text-alt text-colorsBrandRed01Primary font-tt')}>{helperText}</span>
        </label>
      )}
      <button className="self-end space-x-4 btn" onClick={addTempArrName} disabled={(watchList?.length || 0) >= 5}>
        <div>{addButtonLabel || 'เพิ่ม'}</div>
      </button>
    </div>
  );
}

export default EditEbookWriterNamePresenter;
