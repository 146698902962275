import { useCallback, useState } from 'react';

import { CROP_IMAGE_SIZE } from '../../../components/CropImageModal';
import { resizeImageToBase64 } from '../../../utils/image';

function useCoverAndBanner() {
  const [imageToCropFile, setImageToCropFile] = useState<File | undefined>(undefined);
  const [cropImageBase64, setCropImageBase64] = useState<string | undefined>(undefined);
  const [cropImageWidth, setCropImageWidth] = useState<number | undefined>(undefined);
  const [cropImageHeight, setCropImageHeight] = useState<number | undefined>(undefined);
  const [cropImageCoverBase64, setCropImageCoverBase64] = useState<string | undefined>(undefined);
  const [cropImageBannerBase64, setCropImageBannerBase64] = useState<string | undefined>(undefined);

  const [selectedCoverFile, setSelectedCoverFile] = useState<File | undefined>(undefined);
  const [selectedBannerFile, setSelectedBannerFile] = useState<File | undefined>(undefined);

  const onSelectCoverFile = useCallback(async (file: File | undefined) => {
    setImageToCropFile(file);
    if (file) {
      const resizeBase64Image = await resizeImageToBase64(window.URL.createObjectURL(file), CROP_IMAGE_SIZE.ebookCover.imageWidth, CROP_IMAGE_SIZE.ebookCover.imageHeight);
      setCropImageWidth(CROP_IMAGE_SIZE.ebookCover.imageWidth);
      setCropImageHeight(CROP_IMAGE_SIZE.ebookCover.imageHeight);
      setCropImageBase64(resizeBase64Image);
    } else {
      setSelectedCoverFile(undefined);
      setCropImageCoverBase64(undefined);
    }
  }, []);

  const onSelectBannerFile = useCallback(async (file: File | undefined) => {
    setImageToCropFile(file);
    if (file) {
      const resizeBase64Image = await resizeImageToBase64(window.URL.createObjectURL(file), CROP_IMAGE_SIZE.bannerCover.imageWidth, CROP_IMAGE_SIZE.bannerCover.imageHeight);
      setCropImageWidth(CROP_IMAGE_SIZE.bannerCover.imageWidth);
      setCropImageHeight(CROP_IMAGE_SIZE.bannerCover.imageHeight);
      setCropImageBase64(resizeBase64Image);
    } else {
      setSelectedBannerFile(undefined);
      setCropImageBannerBase64(undefined);
    }
  }, []);

  const onConfirmCropImage = useCallback(
    (imageBase64: string) => {
      if (cropImageWidth === CROP_IMAGE_SIZE.ebookCover.imageWidth) {
        setSelectedCoverFile(imageToCropFile);
        setCropImageCoverBase64(imageBase64);
      } else {
        setSelectedBannerFile(imageToCropFile);
        setCropImageBannerBase64(imageBase64);
      }
      setCropImageBase64(undefined);
    },
    [cropImageWidth, imageToCropFile],
  );

  const onCancelCropImage = useCallback(() => {
    setCropImageBase64(undefined);
  }, []);

  return {
    cropImageBannerBase64,
    cropImageBase64,
    cropImageCoverBase64,
    cropImageHeight,
    cropImageWidth,
    onCancelCropImage,
    onConfirmCropImage,
    onSelectBannerFile,
    onSelectCoverFile,
    selectedBannerFile,
    selectedCoverFile,
  };
}

export default useCoverAndBanner;
