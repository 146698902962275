import { useEditorView } from '@aeaton/react-prosemirror/EditorProvider';
import React, { useCallback, useEffect } from 'react';

export interface IEditorReactProsemirror {
  autoFocus?: boolean;
  setIsFocusEditor?: (isFocus: boolean) => void;
}

// eslint-disable-next-line react/prop-types
const EditorReactProsemirror: React.FC<IEditorReactProsemirror> = React.memo(({ autoFocus = false, setIsFocusEditor }) => {
  const view = useEditorView();

  const editorRef = useCallback(
    (element: HTMLDivElement | null) => {
      if (element) {
        element.appendChild(view.dom);
      }
    },
    [view],
  );

  useEffect(() => {
    if (autoFocus) {
      view.focus();
    }
  }, [autoFocus, view]);

  return <div ref={editorRef} className="wysiwyg-editor" onFocus={() => setIsFocusEditor?.(true)} onBlur={() => setIsFocusEditor?.(false)} />;
});
EditorReactProsemirror.displayName = 'Editor';

export default EditorReactProsemirror;
