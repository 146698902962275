import he from 'he';

import { ContentType, ProductType } from '../core/graphql/types';

export const getProductShortName = (productType: ProductType, contentType: ContentType): string => {
  switch (contentType) {
    case ContentType.Comic:
      return `${productType}Comic`;
    case ContentType.Fiction:
      return `${productType}Fic`;
    case ContentType.Nonfiction:
      return `${productType}NonFic`;
  }
};

export const getRandomStringInLength = (maxLength: number) => {
  return Math.random().toString(36).substr(2, maxLength);
};

export const decodeHTMLEnitities = (text: string | null | undefined): string => he.decode(text || '');

export const camelToSnakeCase = (str: string) => str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
