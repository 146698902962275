import { UseFormRegister, FieldValues } from 'react-hook-form';

import Input from '../../../components/form/Input';
import RadioGroupButton from '../../../components/form/RadioGroupButton';
import {
  EBOOK_TYPE_OPTIONS,
  IConfigItemType,
  PRICE_SETTING_OPTIONS,
  RATING_OPTIONS,
  SEARCH_TYPE_OPTIONS,
  SORT_BY_SEARCH_OPTIONS,
  SPECIAL_SEARCH_OPTIONS,
} from '../validates/sectionBuilderSchema';

import DropdownSelectNested from './DropdownSelectNested';
import RenderObjectLabel from './RenderObjectLabel';
import SelectPintoCategoryWrapper from './SelectPintoCategory';
import SelectSearchType from './SelectSearchType';

//TODO: continue implements
export interface IPropsSearchItem {
  type: IConfigItemType.Search;
  searchType?: 'ebook' | 'user';
  searchText: string;
  title?: string;
  description?: string;
  coverImage?: string;
  bannerImage?: string;
  popupImageUrl?: string;
  searchField?: 'title' | 'tag' | 'authorOrTranslator';
  sortBy: 'relevant' | 'published' | 'priceThb' | 'title' | 'activated';
  categoryId: number;
  gteRating?: number;
  anyTypes?: string[];
  anyPriceTypes?: string[];
  //TODO: waiting for clear API
  // sortType?: 'asc' | 'desc';
}

interface IPropsSearchSectionItem {
  index: number;
  itemData: IPropsSearchItem;
  //TODO: continue implements
  register: UseFormRegister<FieldValues>;
}

const SearchSectionItem = (props: IPropsSearchSectionItem) => {
  const { index, itemData, register } = props;
  return (
    <div className="flex justify-between space-x-16">
      <div className="w-full collapse">
        <input type="checkbox" className="peer" />
        <div className=" collapse-title bg-systemGrays08FillQuaternary text-systemGrays01LabelPrimary peer-checked:bg-systemGrays08FillQuaternary peer-checked:text-systemGrays01LabelPrimary flex justify-between after:content-['+'] peer-checked:after:content-['-'] ease-out duration-300">
          <span>
            <span>Search </span>
            <RenderObjectLabel
              objectLists={[
                { key: 'searchType', value: itemData?.searchType, required: true },
                { key: 'categoryId', value: itemData?.categoryId, required: true },
              ]}
            />
          </span>
        </div>
        <div className="collapse-content text-systemGrays01LabelPrimary peer-checked:bg-systemGrays08FillQuaternary peer-checked:text-systemGrays01LabelPrimary">
          <div className="space-y-8">
            {/* TODO: helperText "0/120 characters" */}
            <Input register={() => register(`configItems.${index}.title`)} label="title" name="title" helperText="Input user ID" placeholder="0/40 characters" />
            <Input register={() => register(`configItems.${index}.description`)} label="Description" name="description" helperText="0/120 characters" placeholder="Description" />
            <Input
              register={() => register(`configItems.${index}.bannerImage`)}
              label="bannerImage"
              name="bannerImage"
              helperText="Input bannerImage url, use for Layout: Banner type"
              placeholder="bannerImage"
            />

            <SelectSearchType required label="searchType" options={SEARCH_TYPE_OPTIONS} prefixName={`configItems.${index}`} name={[`configItems.${index}.searchType`]} />

            <Input register={() => register(`configItems.${index}.searchText`)} label="searchText" name="searchText" helperText="Input Search Text" placeholder="searchText" />

            {itemData?.searchType === 'ebook' && (
              <>
                <SelectPintoCategoryWrapper name={`configItems.${index}.categoryId`} categoryId={itemData?.categoryId} />

                <RadioGroupButton label="Specific Search" options={SPECIAL_SEARCH_OPTIONS} register={register} name={`configItems.${index}.searchField`} />

                <DropdownSelectNested
                  label="SortBy"
                  options={SORT_BY_SEARCH_OPTIONS}
                  prefixName={`configItems.${index}`}
                  name={[`configItems.${index}.sortBy`, `configItems.${index}.sortType`]}
                />

                <RadioGroupButton label="Rating" options={RATING_OPTIONS} register={register} name={`configItems.${index}.gteRating`} />

                <RadioGroupButton isSetArrayValue label="AnyPriceTypes" options={PRICE_SETTING_OPTIONS} register={register} name={`configItems.${index}.anyPriceTypes`} />

                <RadioGroupButton isSetArrayValue label="AnyEbookType" options={EBOOK_TYPE_OPTIONS} register={register} name={`configItems.${index}.anyTypes`} />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchSectionItem;
