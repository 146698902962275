/* eslint-disable prettier/prettier */
import { isNil, omitBy } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { FiPlus, FiSearch } from 'react-icons/fi';
import { Link } from 'react-router-dom';

import { IPropsAdListItem } from '../../app/genPageForAds/components/AdListItem';
import BookPageAdListTablePresenter from '../../app/genPageForAds/presenters/BookPageAdListTablePresenter';
import ToastComponent, { IToastType } from '../../components/Toast/ToastComponent';
import useQueryBookPageAds from '../../hooks/useQueryBookPageAds';



const GenPageForAdsPage = () => {
  const { bookPageAds, isLoadingBookPageAds, fetchBookPageAds } = useQueryBookPageAds();

  const [searchText, setSearchText] = useState<string | undefined>(undefined);
  const [currentPage, setCurrentPage] = useState<number>(1);


  const handleChangePage = useCallback(
    (page: number) => {
      const offset = (page - 1) * 10;
      setCurrentPage(page);
      const filter = omitBy({ offset }, isNil);
      fetchBookPageAds(filter);
    },
    [fetchBookPageAds],
  );

  useEffect(() => {
    const filter = omitBy({ q: (searchText || '')?.length > 0 ? searchText : null }, isNil);
    fetchBookPageAds(filter);
  }, [fetchBookPageAds, searchText]);

  const handleSetSearchText = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  }, []);

  const onCopyAdLink = useCallback(async (adId: string) => {
    await navigator.clipboard.writeText(`${process.env.REACT_APP_FIC_URL}/social-content-preview/${adId}`);
    ToastComponent({ type: IToastType.SUCCESS, label: 'Copied link successfully' })
  }, [])

  return (
    <div className="mx-auto space-y-24">
      <div className="space-y-24 divider-y flex">
        {/* section: title */}
        <div className="flex justify-between py-5 flex-1">
          <h1 className="font-dbh text-[28px]">Gen Page for Ads</h1>

          <div className="space-x-16 flex">
            <div className="relative">
              <FiSearch className="absolute top-[16px] left-[12px] text-colorsBrandWarmBlack02LabelSecondary text-18" />
              <input type="text" placeholder="input bookId" className="w-[300px] pl-36 input" onChange={handleSetSearchText} />
            </div>
            <Link to="/gen-page-for-ads/edit">
              <button className="space-x-8 btn btn-warning">
                <FiPlus className="font-bold text-24" />
                <span>Generate page</span>
              </button>
            </Link>
          </div>
        </div>
      </div>

      {/* table content */}
      <div>
        {isLoadingBookPageAds ? <div className="w-full btn btn-outline btn-disabled bg-systemGrays04LabelQuaternary border-none loading h-[100px]" />

          : (
            <>
              {(bookPageAds?.edges?.length || 0) === 0 ? (
                <div className="flex items-center justify-center w-full h-[60vh] text-colorsBrandWarmBlack02LabelSecondary">Empty items</div>
              ) : (
                <BookPageAdListTablePresenter
                  adLists={bookPageAds?.edges.map(
                    ({ node }) =>
                    ({
                      _id: node._id,
                      createdDate: node.createdAt,
                      bookTitle: node.title,
                      bookId: node.bookId,
                      chapterTitleList: node.chapterPageAds.map(chapter => ({
                        id: chapter.chapterId,
                        title: chapter.title
                      })),
                      note: node?.note ?? '-'
                    } as IPropsAdListItem),
                  )}
                  currentPage={currentPage}
                  totalCount={bookPageAds?.totalCount}
                  onChangePage={handleChangePage}
                  onCopyAdLink={onCopyAdLink}
                />
              )}
            </>
          )}
      </div>
    </div>
  );
};

export default GenPageForAdsPage;
