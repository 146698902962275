/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const checkBadge = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path
      fill="#8C8C8C"
      d="M24 0l19 8.727v13.091C43 33.928 34.893 45.251 24 48 13.107 45.25 5 33.927 5 21.818V8.728L24 0zM10.998 25.619L20.173 35l16.83-17.177a2.065 2.065 0 00.006-2.867l-.9-.927a1.94 1.94 0 00-2.791-.012L20.147 27.462l-5.488-5.587a1.956 1.956 0 00-2.809.006l-.85.868a2.052 2.052 0 00-.002 2.87z"
    />
  </svg>
);

export default checkBadge;
