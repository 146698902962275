/* eslint-disable prettier/prettier */

import { useCallback, useEffect, useState } from 'react';

import UploadToolTablePresenter from '../../app/uploadTools/presenters/UploadToolTablePresenter';
import { EditEbook, EditEbookSortBy, SortType,  } from '../../core/graphql/types';
import useQueryRequestedEbookFiles from '../../hooks/useQueryRequestedEbookFiles';


const UploadToolsPage = () => {
  const { fetchRequestedEbookFiles, requestedEbookFiles, isLoadingRequestedEbookFiles } = useQueryRequestedEbookFiles();

  // const [searchText, setSearchText] = useState<string | undefined>(undefined);
  const [currentPage, setCurrentPage] = useState<number>(1);

  useEffect(() => {
      fetchRequestedEbookFiles({filter:{ sortBy: EditEbookSortBy.Updated, sortType: SortType.Asc }});
  }, [fetchRequestedEbookFiles]);

  const handleChangePage = useCallback(
    (page: number) => {
      setCurrentPage(page);
      const offset = (page - 1) * 10;
      fetchRequestedEbookFiles({ filter:{offset, sortBy: EditEbookSortBy.Updated, sortType: SortType.Asc }});
    },
    [fetchRequestedEbookFiles],
  );

  return (
    <div className="mx-auto space-y-24 divider-y">
      <div className="">
        {/* section: title */}
        <div className="flex justify-between py-5">
        <div className="flex space-x-8 items-baseline">
            <h1 className="font-dbh text-[28px]">Requested Ebook Files</h1>
            <h2 className="text-colorsBrandWarmBlack02LabelSecondary">Sort by: Updated time (old-new)</h2>
          </div>
        </div>
      </div>

      {/* table content */}
      <div>
        {isLoadingRequestedEbookFiles && <div className="w-full btn btn-outline btn-disabled bg-systemGrays04LabelQuaternary border-none loading h-[100px]" />}
        {!isLoadingRequestedEbookFiles && (requestedEbookFiles?.edges?.length || 0) === 0 && (
          <div className="flex items-center justify-center w-full h-[60vh] text-colorsBrandWarmBlack02LabelSecondary">No Requested Ebook Files</div>
        )}
        {!isLoadingRequestedEbookFiles && (requestedEbookFiles?.edges?.length || 0) > 0 && (
          <UploadToolTablePresenter
            totalCount={requestedEbookFiles?.totalCount || 0}
            pageLimit={10}
            currentPage={currentPage}
            onChangePage={handleChangePage}
            uploadToolItems={requestedEbookFiles?.edges?.map((edge) => edge?.node as EditEbook) || []}
          />
        )}
      </div>
    </div>
  );
};

export default UploadToolsPage;
