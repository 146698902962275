/* eslint-disable */
/* this file generate by raw-icons/build.js */

import React from 'react';

const textStrikeOut = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path
      fill="#8C8C8C"
      fillRule="evenodd"
      d="M10.6 30H8c-.545 0-1-.448-1-1v-3c0-.556.447-1 1-1h32c.545 0 1 .448 1 1v3c0 .556-.447 1-1 1h-2.452l3.358 8.638A1 1 0 0139.974 40h-6.8a1 1 0 01-.927-.624l-3.194-7.862h-10.72l-3.513 7.893a1 1 0 01-.914.593H8.028a1 1 0 01-.927-1.376L10.6 30zm4.056-10l4.636-11.43C19.755 7.575 20.665 7 21.787 7h4.931c1.118 0 2.028.573 2.497 1.57l.053.127L33.66 20h-7.706l-1.741-5-2.009 5h-7.55z"
    />
  </svg>
);

export default textStrikeOut;
