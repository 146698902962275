import { debounce } from 'lodash';
import { useCallback } from 'react';

import { useSearchTagsLazyQuery } from '../core/graphql/operation-hooks';
import { SearchTagsFilter } from '../core/graphql/types';

const INITIAL_LIMIT = 20;
const LOAD_MORE_LIMIT = 10;

const useSearchTags = () => {
  const [querySearchTags, { data, loading, variables, fetchMore }] = useSearchTagsLazyQuery({ fetchPolicy: 'network-only' });

  const fetchSearchTags = useCallback(
    (searchTag: string, filter?: SearchTagsFilter) => {
      querySearchTags({
        variables: {
          q: searchTag,
          filter: {
            ...filter,
            limit: INITIAL_LIMIT,
          },
        },
      });
    },
    [querySearchTags],
  );

  const endCursor = data?.searchTags.pageInfo.endCursor;
  const onLoadMoreSearchTags = debounce(
    useCallback(() => {
      if (loading) return;

      if (endCursor) {
        fetchMore?.({
          variables: {
            filter: {
              ...variables?.filter,
              afterCursor: endCursor,
              limit: LOAD_MORE_LIMIT,
            },
          },
        });
      }
    }, [loading, endCursor, fetchMore, variables?.filter]),
    1000,
  );

  return {
    fetchSearchTags,
    searchTags: data?.searchTags,
    onLoadMoreSearchTags,
    isLoadingSearchTags: loading,
  };
};

export default useSearchTags;
